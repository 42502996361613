import { Details } from "components";
import React from "react";

export const ProductPage = () => {
  return (
    <div>
      <Details />
    </div>
  );
};
