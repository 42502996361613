import { Product } from "components";
import React from "react";

export const ProductsLayout = () => {
  return (
    <div>
      <Product />
    </div>
  );
};
