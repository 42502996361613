function PrivacyPolicy(props) {
  return (
    <div class="container privacy-policy">
      <h1>iCart Marketing and Management LTD Privacy Policy</h1>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> [12/10/23]
      </p>

      <h2>Introduction</h2>
      <p>
        Welcome to [iCart Marketting and Management LTD] and the iCart app. At
        [iCart Marketting and Management LTD], we are dedicated to creating
        sustainable infrastructure solutions for the quick service restaurant
        (QSR) industry. Our mission is to empower QSR establishments with
        environmentally conscious alternatives, reducing their ecological
        footprint while maintaining operational excellence. We envision a world
        where every QSR operates with minimal environmental impact, utilizing
        cutting-edge technology and eco-friendly practices to serve their
        customers while preserving the planet.
      </p>

      <h2>Data Access, Collection, Use, and Sharing </h2>
      <p>
        Our privacy policy comprehensively discloses how the app accesses,
        collects, uses, and shares user data. We are committed to protecting
        your privacy and ensuring transparency. The following information
        outlines the types of personal and sensitive user data that are
        accessed, collected, used, and shared, as well as any parties with which
        such data may be shared:
      </p>

      <h2>Access and Collection </h2>
      <p>
        The iCart app accesses and collects the following types of personal
        information from our users: Name, Phone Number, Email Address, and
        Address. These details are collected through user input during
        registration and the use of our services. Additionally, automatic data
        collection through cookies and similar technologies may occur, as well
        as data provided by you or through third-party services that you grant
        access to.
      </p>

      <h2>Data Usage</h2>
      <p>
        The collected data is used for the following purposes: providing and
        personalizing our services, facilitating communication with users,
        processing orders and payments, and enhancing and optimizing our
        services. We may also analyze user behavior for business insights.
      </p>

      <h2>Third-Party Sharing</h2>
      <p>
        We do not share your personal information with third parties unless
        required by law or with your explicit consent.
      </p>

      <h2>Data Retention and Deletion Policy</h2>
      <p>
        We take data security and user privacy seriously. Our data retention and
        deletion policy is as follows:
      </p>
      <ol>
        <li>
          Data Retention: We retain your data for as long as it is necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required by law. The retention period may vary
          depending on the type of data and its specific purpose.
        </li>
        <li>
          Data Deletion: Upon your request or when your data is no longer
          required for the purposes specified in this Privacy Policy, we will
          promptly and securely delete your personal and sensitive user data.
          Please contact us through the provided contact information to request
          data deletion.
        </li>
      </ol>

      <h2>User Rights</h2>
      <p>You have the following rights regarding your data:</p>
      <ol>
        <li> Right to access your data.</li>
        <li> Right to correct your data.</li>
        <li> Right to delete your data.</li>
        <li> Right to restrict the processing of your data.</li>
        <li> Right to data portability.</li>
        <li> Right to object to the processing of your data.</li>
      </ol>

      <h2>Security Measures</h2>
      <p>
        We implement robust security measures to protect your data, including
        encryption, access controls, and regular security assessments.
      </p>

      <h2>Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar technologies to enhance user experiences. You
        can manage your cookie preferences in your browser settings.
      </p>

      <h2>Location Data</h2>
      <p>
        If our services use location data, we will request your permission and
        explain the purpose of this data usage.
      </p>

      <h2>Children's Privacy</h2>
      <p>
        Our services are not intended for children under the age of 18. We do
        not knowingly collect data from children under 18. If you believe we
        have inadvertently collected data from a child under 18, please contact
        us.
      </p>
      <h2>Changes to the Privacy Policy</h2>
      <p>
        This Privacy Policy may be updated to reflect changes in our practices.
        We will notify users of significant changes.
      </p>
      <h2>Contact Information</h2>
      <p>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at:{" "}
        <a
          href="mailto:info@geticart.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@geticart.com
        </a>
        ,{" "}
        <a
          href="tel+2349037920018

"
          target="_blank"
          rel="noopener noreferrer"
        >
          +234 903 792 0018
        </a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
