import { Work, Openings } from "components";
import React from "react";

export const JobPage = () => {
  return (
    <>
      <Work />
      <Openings />
    </>
  );
};
