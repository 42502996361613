import React from "react";
import "./Contact.scss";
import { contact_content } from "constants";
import useContact from "hooks/api/useContact";

const Input = ({ label, type, name, textarea, ...props }) => (
  <div className="form_control">
    <p>{label}</p>
    {textarea ? (
      <textarea type={type} name={name} {...props} required />
    ) : (
      <input type={type} name={name} {...props} required />
    )}
  </div>
);

export const Contact = () => {
  const { contact, isLoading } = useContact();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {
      email: formData.get("email"),
      firstName: formData.get("fname"),
      lastName: formData.get("lname"),
      phoneNumber: formData.get("number"),
      message: formData.get("message"),
    };
    contact(data);
  };

  return (
    <div className="contact_container" id="contact">
      <section>
        <div className="contact">
          <div className="content_header-2">
            <h2>Contact Us</h2>
            <p className="contact_desc">
              Get in touch with us for any enquiries and custom requests for a
              concept
            </p>
          </div>
          <div className="contact_cards">
            {contact_content.map((content, uid) => (
              <div className="contact_card-content" key={uid}>
                <div className="contact_card-icon">{content.icon}</div>
                <h4>
                  <span>{content.title[0]}</span>
                  {content.title[1]}
                </h4>
                <p className="justify">{content.content}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="contact_form">
          <div className="form">
            <svg
              className="contact_star star one"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-5)"
              />
            </svg>
            <svg
              className="contact_star star two"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-2)"
              />
            </svg>
            <svg
              className="contact_star star three"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-2)"
              />
            </svg>
            <svg
              className="contact_star star four"
              width="53"
              height="53"
              viewBox="0 0 53 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
                fill="var(--yellow-tint-3)"
              />
            </svg>
            <div className="content_header-2">
              <h2>Send Us a Message</h2>
              <p>
                Get in touch with us for any enquiries and custom requests for a
                concept
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="inputs_container">
                <Input
                  label="First name"
                  placeholder="Enter your first name"
                  type="text"
                  name="fname"
                />
                <Input
                  label="Last name"
                  placeholder="Enter your last name"
                  type="text"
                  name="lname"
                />
                <Input
                  label="Email"
                  placeholder="username@email.com"
                  type="email"
                  name="email"
                />
                <Input
                  label="Phone number"
                  placeholder="+234 801 234 5678"
                  type="text"
                  name="number"
                />
              </div>
              <Input
                label="Message"
                placeholder="Enter your message"
                type="text"
                name="message"
                textarea
              />
              <button disabled={isLoading} className="form_btn" type="submit">
                {isLoading ? "Loading..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
