import img1 from "../../assets/images/snipper/icart.911.jpg";
import img2 from "../../assets/images/snipper/icart.912.jpg";
import img3 from "../../assets/images/snipper/icart.926.jpg";
import img4 from "../../assets/images/snipper/icart.927.jpg";
import img5 from "../../assets/images/snipper/icart.929.jpg";
import img6 from "../../assets/images/snipper/icart.930.jpg";
import img7 from "../../assets/images/snipper/icart.931.jpg";
import img8 from "../../assets/images/snipper/logo.jpeg";

function Snipper(props) {
  return (
    <div className="container">
      <img style={{ width: 200, height: 200, margin: 50 }} src={img8} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img1} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img2} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img3} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img4} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img5} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img6} alt="" />
      <img style={{ width: 200, height: 200, margin: 50 }} src={img7} alt="" />
    </div>
  );
}

export default Snipper;
