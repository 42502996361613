import useAPI from "./useAPI";
import urls from "api/urls";
import { toast } from "react-toastify";

const useContact = () => {
  const { post, isLoading, errorMessage, successMessage } = useAPI();

  const subscribe = async (details) => {
    const data = await post(urls.news.baseUrl, details);
    if (!data) return;
    toast.success(data.message);
  };

  const contact = async (details) => {
    const data = await post(urls.contact.baseUrl, details);
    if (!data) return;
    toast.success(data.message);
  };

  return {
    isLoading,
    subscribe,
    contact,
    errorMessage,
    successMessage,
  };
};

export default useContact;
