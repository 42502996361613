import { useEffect, useState } from "react";
import "./Form.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country } from "country-state-city";

const cities = [
  "Abuja",
  "Kano",
  "Accra",
  "Nairobi",
  "Kaduna",
  "Rivers",
  "Lagos",
];

export const OperatorPage = () => {
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [phoneValue, setPhoneValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState(null);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  useEffect(() => {
    const countryData = Object.values(Country.getAllCountries());
    setCountries(countryData);
    setSelectedCountry(countryData[160].name);
  }, []);

  const inputs = [
    {
      label: "First Name",
      placeholder: "Enter your first name",
      type: "text",
    },
    {
      label: "Last Name",
      placeholder: "Enter your last name",
      type: "text",
    },
    {
      label: "Email",
      placeholder: "username@email.com",
      type: "email",
    },
    {
      label: "Phone number",
      phone: true,
    },
    {
      label: "City",
      placeholder: "City",
      type: "text",
      select: true,
      isCity: true,
    },
    {
      label: "Training Certificate",
      placeholder: "Certificate",
      type: "file",
    },
    // {
    //   label: "Country",
    //   select: true,
    // },
  ];

  return (
    <div className="form_container">
      <div className="form_container-header">
        <h2>Become an iCart Operator</h2>
        <p>Fill and send the file below and we will get in touch with you.</p>
      </div>
      <form>
        <div className="form_inputs">
          {inputs.map((value, _id) => (
            <div key={_id} className="form_control">
              <label>{value.label}</label>
              {value.phone ? (
                <PhoneInput
                  className="input_holder"
                  placeholder="+234 801 234 5678"
                  defaultCountry="NG"
                  value={phoneValue}
                  onChange={(e) => setPhoneValue(e)}
                />
              ) : value.select && countries.length > 0 ? (
                <select
                  className="input_holder"
                  value={value.isCity ? city : selectedCountry}
                  onChange={
                    value.isCity ? handleCityChange : handleCountryChange
                  }
                >
                  {(value.isCity ? cities : countries)?.map((country, _id) => (
                    <option
                      key={_id}
                      value={value.isCity ? country : country.name}
                    >
                      {value.isCity ? country : country.name}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="input_holder">
                  <input type={value.type} placeholder={value.placeholder} />
                </div>
              )}
            </div>
          ))}
        </div>
        <button>Send</button>
      </form>
    </div>
  );
};
