function TermsAndConditions(props) {
  return (
    <div class="container privacy-policy">
      <h2>iCart Marketting and Management LTD Terms & Conditions of Use</h2>
      <br />
      <br />
      <p>
        By downloading, installing, or using the iCart app provided by [iCart
        Marketting and Management LTD], you agree to comply with and be bound by
        the following terms and conditions:
      </p>
      <br />
      <br />
      <h3>1. Acceptance of Terms and Conditions</h3>
      <p>
        By using the iCart app, you acknowledge that you have read, understood,
        and agree to be bound by these Terms and Conditions. If you do not agree
        with any part of these Terms and Conditions, please do not download,
        install, or use the iCart app.
      </p>
      <br />
      <br />
      <h3>2. User Responsibilities</h3>
      <p>
        You are responsible for maintaining the confidentiality of your account
        information, including your username and password. You agree to notify
        us immediately of any unauthorized use of your account or any other
        breach of security. We will not be liable for any loss or damage arising
        from your failure to comply with this security obligation.
      </p>
      <br />
      <br />
      <h3>3. Intellectual Property Rights</h3>
      <p>
        The iCart app and all related content, including but not limited to
        text, graphics, logos, images, and software, are protected by copyright,
        trademark, and other intellectual property laws. You agree not to copy,
        modify, distribute, transmit, display, reproduce, publish, license,
        create derivative works from, transfer, or sell any information,
        software, products, or services obtained from the iCart app without our
        prior written permission.
      </p>
      <br />
      <br />

      <h3>4. Limitation of Liability</h3>
      <p>
        In no event shall [iCart Marketting and Management LTD] or its
        directors, officers, employees, or agents be liable for any direct,
        indirect, incidental, special, or consequential damages arising out of
        or in any way connected with the use of the iCart app or its services,
        even if [iCart Marketting and Management LTD] has been advised of the
        possibility of such damages.
      </p>
      <br />
      <br />
      <h3>5. Applicable Law and Jurisdiction</h3>
      <p>
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of [country/state]. Any dispute arising under
        or in connection with these Terms and Conditions shall be subject to the
        exclusive jurisdiction of the courts of [country/state].
      </p>
      <br />
      <br />

      <h3>6. Modifications to Terms and Conditions</h3>
      <p>
        [iCart Marketting and Management LTD] reserves the right to amend or
        modify these Terms and Conditions at any time. Any changes will be
        effective immediately upon posting within the iCart app. Your continued
        use of the iCart app after any changes shall constitute your consent to
        such changes.
      </p>
      <br />
      <br />
      <h3>7.User Feedback and Complaints</h3>
      <p>
        We value your feedback and complaints, as they help us to improve our
        app and services. If you have any feedback or complaints, please do not
        hesitate to contact us at info@geticart.com. We will do our best to
        respond to your feedback or complaint within a reasonable time frame.
      </p>
      <br />
      <br />
      <h3>8. Termination of User Account</h3>
      <p>
        We reserve the right to terminate your user account at any time, without
        notice, for any reason, including but not limited to: Violation of these
        Terms and Conditions; Fraud or other unlawful activity; Abuse of the
        iCart app or its services; Failure to pay any fees or charges associated
        with the iCart app or its services.
      </p>
    </div>
  );
}

export default TermsAndConditions;
