import "./Footer.scss";
import { useContext } from "react";
import { logoDark } from "assets";
import { socials, GALLERY, JOBS, ROOT } from "constants";
import { Link, NavLink } from "react-router-dom";
import useContact from "hooks/api/useContact";
import AppContext from "contexts/AppContext";
import { LEGAL } from "constants";

export const Footer = () => {
  const { subscribe, isLoading } = useContact();
  const { concepts } = useContext(AppContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = { email: formData.get("email") };
    subscribe(data);
  };

  const footer_link = [
    {
      path: ROOT,
      name: "About Us",
    },
    {
      path: GALLERY,
      name: "Gallery",
    },
    {
      path: JOBS,
      name: "Jobs",
    },
    {
      path: ROOT,
      name: "News",
    },
    {
      path: ROOT,
      name: "FAQs",
    },
    {
      path: ROOT,
      name: "Resources",
    },
  ];

  return (
    <footer>
      <form onSubmit={handleSubmit} className="newsletter_form">
        <section>
          <div className="newsletter_form-content">
            <div className="content_header-2">
              <h2>Subscribe to our Newsletter</h2>
              <p>
                Subscribe to our exclusive list and get email notifications of
                news and updates
              </p>
            </div>
          </div>

          <div className="newsletter_form-control">
            <input
              placeholder="Enter your email address"
              type="email"
              name="email"
              id="email"
              required
            />
            <button disabled={isLoading} type="submit">
              {isLoading ? "Loading..." : "Subscribe"}
            </button>
          </div>
        </section>
      </form>
      <div className="footer_bottom">
        <section>
          <div className="top_section">
            <div className="footer_section-left">
              <NavLink to={ROOT} className="logo">
                <img src={logoDark} alt="ICart logo" />
              </NavLink>
              <p className="justify">
                Building a greener tomorrow for quick-service
                restaurants industry
              </p>
            </div>
            <div className="footer_section-right">
              <div className="right_section-content">
                <p>Company</p>
                <ul>
                  {footer_link.map((link, uid) => (
                    <NavLink to={link.path} key={uid} className="link">
                      <p>{link.name}</p>
                    </NavLink>
                  ))}
                </ul>
              </div>
              <div className="right_section-content">
                <p>Products</p>
                <ul>
                  {concepts.map((concept, uid) => (
                    <div key={uid} className="link">
                      <p>{concept.name}</p>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="right_section-content">
                <p>Follow us on</p>
                <ul>
                  {socials.map((social, uid) => (
                    <NavLink to={social.path} key={uid} className="link">
                      <span>{social.icon}</span>
                      <p>{social.name}</p>
                    </NavLink>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="hr" />
          <div className="bottom_section">
            <p>All rights reserved - iCart Limited 2023</p>
            <div>
              <p>
                <Link to={LEGAL.TERMS_AND_CONDITIONS}>
                  Terms and Conditions
                </Link>
              </p>
              <p>
                <Link to={LEGAL.PRIVACY_POLICY}>Privacy Policy</Link>
              </p>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};
