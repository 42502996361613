/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import "./Form.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country } from "country-state-city";
import useBusiness from "hooks/api/useBusiness";
import { currencyFormatter, numberFormatter } from "utils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const operations = [
  {
    name: "--Management--",
  },
  {
    name: "Onsite management",
    value: "onsite",
  },
  {
    name: "Online management",
    value: "online",
  },
];

const cities = [
  {
    name: "Abuja",
    value: "abuja",
  },
  {
    name: "Kano",
    value: "kano",
  },
  {
    name: "Lagos",
    value: "lagos",
  },
  {
    name: "Port Harcourt",
    value: "port harcourt",
  },
];

const inputs = [
  {
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    name: "firstName",
  },
  {
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    name: "lastName",
  },
  {
    label: "Email",
    placeholder: "username@email.com",
    type: "email",
    name: "email",
  },
  {
    label: "Phone number",
    phone: true,
    name: "phoneNumber",
  },
  {
    label: "Country",
    select: true,
    name: "country",
  },
  {
    label: "Number of iCart",
    name: "icart",
    type: "number",
    default: 1,
  },
  // {
  //   label: "Select Concept",
  //   select: true,
  //   options: [
  //     {
  //       name: "--Select concept--",
  //     },
  //     ...products.map((p) => ({ name: p.title })),
  //   ],
  //   concept: true,
  // },
  {
    label: "Management",
    select: true,
    options: operations,
    management: true,
    name: "management",
  },
  {
    label: "City",
    city: true,
    select: true,
    options: cities,
    name: "city",
  },
];

export const PreorderPage = () => {
  const [selectedManagement, setSelectedManagement] = useState("online");
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [phoneValue, setPhoneValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [payUrl, setPayUrl] = useState(null);
  const [selectedCity, setCity] = useState("abuja");

  const formRef = useRef();

  const { isLoading, preorder, plans, errorMessage, getPreorderRate, rates } =
    useBusiness();

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const handleChangeCity = (e) => {
    setCity(e.target.value);
  };
  const handleChangePlan = (e) => {
    setSelectedPlan(e.target.value);
  };
  const handleManagementChange = (e) => {
    setSelectedManagement(e.target.value);
  };
  const handleConceptChange = (e) => {
    setSelectedConcept(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneValue) return toast.warn("Enter a valid phone number");
    if (!phoneValue) return toast.warn("Select a management type");
    const formData = new FormData(e.target);
    if (formData.get("password1") !== formData.get("password2"))
      return toast.warn("Passwords not matching");
    const payload = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      city: selectedCity,
      icart: formData.get("icart"),
      management: selectedManagement,
      phoneNumber: phoneValue,
      country: selectedCountry,
    };
    await preorder(payload, (url) => {
      formRef.current.reset();
      setSelectedCountry(null);
      setSelectedManagement(null);
      setPhoneValue(null);
      setPayUrl(url);
      window.open(url);
    });
  };

  useEffect(() => {
    getPreorderRate();
    const countryData = Object.values(Country.getAllCountries());
    setCountries(countryData);
    setSelectedCountry(countryData[160].name);
  }, []);

  useEffect(() => {
    if (errorMessage) alert(errorMessage);
  }, [errorMessage]);

  return (
    <div className="form_container">
      {payUrl ? (
        <a href={payUrl}>
          <button>Complete Payment</button>
        </a>
      ) : (
        <>
          <div className="form_container-header">
            <h2>
              Preorder your iCart for{" "}
              {numberFormatter(rates ? rates[selectedManagement] : 0)}{" "}
              {selectedManagement} per kiosk
            </h2>
            <p>
              Fill and send the file below and we will get in touch with you.
            </p>
          </div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="form_inputs">
              {inputs.map((value, _id) => (
                <div key={_id} className="form_control">
                  <label>{value.label}</label>
                  {value.phone ? (
                    <PhoneInput
                      className="input_holder"
                      placeholder="+234 801 234 5678"
                      defaultCountry="NG"
                      value={phoneValue}
                      onChange={(e) => setPhoneValue(e)}
                    />
                  ) : value.select && countries.length > 0 ? (
                    <select
                      className="input_holder"
                      value={
                        (value.plan
                          ? selectedPlan
                          : value.concept
                          ? selectedConcept
                          : value.management
                          ? selectedManagement
                          : value.city
                          ? selectedCity
                          : selectedCountry) || ""
                      }
                      onChange={
                        value.plan
                          ? handleChangePlan
                          : value.concept
                          ? handleConceptChange
                          : value.management
                          ? handleManagementChange
                          : value.city
                          ? handleChangeCity
                          : handleCountryChange
                      }
                    >
                      {(value.plan
                        ? plans.map((p) => ({
                            name: `₦${currencyFormatter(p.amount)} (${
                              p.refundable ? "Cashback" : ""
                            } Security deposite after ${
                              p.durationInDays === 365
                                ? "1 year"
                                : `${p.durationInDays} days`
                            })`,
                            value: p._id,
                          }))
                        : value.options || countries
                      )?.map((country, _id) => (
                        <option key={_id} value={country.value || country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="input_holder">
                      <input
                        name={value.name}
                        required
                        type={value.type}
                        placeholder={value.placeholder}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="accept">
              <input
                onClick={() => setAcceptTerms(!acceptTerms)}
                type="checkbox"
              />
              <span>
                you've read and accepeted our purchase{" "}
                <Link
                  className="terms-link"
                  to="/terms-and-conditions-purchase"
                >
                  terms and condtions
                </Link>
              </span>
            </div>
            <button
              className="disabled-btn"
              disabled={isLoading || !acceptTerms}
            >
              {isLoading ? "Loading..." : "Send"}
            </button>
          </form>
        </>
      )}
    </div>
  );
};
