import React from "react";
import "./Details.scss";
import { useNavigate, useParams } from "react-router-dom";
import { products } from "constants";

export const Details = () => {
  const navigate = useNavigate();

  const { slug } = useParams();
  const singleProduct = products.find((product) => product.slug === slug);

  const {
    title,
    subTitle,
    productImg1,
    productImg2,
    background,
    titleColor,
    banner,
    branding,
    stat,
    wws,
    wwsContent,
    theConcept,
    whyImg,
    whyContent,
    menuImg,
    menu,
    menuSec,
    machines,
  } = singleProduct;

  return (
    <div className="product_details">
      <section>
        <div className="back" onClick={() => navigate(-1)}>
          <svg width="3.5rem" height="2.8rem" viewBox="0 0 35 28" fill="none">
            <path
              d="M14.5244 27.4634C14.5272 25.672 14.1749 23.8976 13.4878 22.2418C12.8007 20.586 11.7922 19.0812 10.52 17.8136C9.24788 16.5459 7.73699 15.5402 6.07382 14.854C4.41064 14.1679 2.62782 13.8147 0.827327 13.8147"
              stroke="var(--black-main)"
              strokeWidth="3"
              strokeMiterlimit="10"
            />
            <path
              d="M0.827326 13.8149C2.62782 13.8149 4.41064 13.4618 6.07382 12.7756C7.73699 12.0894 9.24788 11.0837 10.52 9.81607C11.7922 8.54839 12.8007 7.04361 13.4878 5.3878C14.1749 3.73199 14.5272 1.95763 14.5244 0.166244"
              stroke="var(--black-main)"
              strokeWidth="3"
              strokeMiterlimit="10"
            />
            <path
              d="M0.827289 13.8149L34.9756 13.8149"
              stroke="var(--black-main)"
              strokeWidth="3"
              strokeMiterlimit="10"
            />
          </svg>
          <h4>Our Products & Services</h4>
        </div>

        <div style={{ backgroundColor: background }} className="details_banner">
          <h1 style={{ color: titleColor }}>{title}</h1>
          <p>{subTitle}</p>
          <button>Select Concept</button>
        </div>
        <img className="details_banner_img" src={banner} alt={title} />

        <div className="product_service-container">
          <div className="product_services">
            <div className="services_right img_sm mr">
              <img src={productImg1} alt="img" />
            </div>
            <div className="services_left">
              <div className="product_services-content">
                <h3>The Concept</h3>
                <br />
                {theConcept.map((c, idx) => (
                  <p key={idx}>{c}</p>
                ))}
              </div>
            </div>
          </div>
        </div>

        {stat && <img className="details_banner_img" src={stat} alt={title} />}
        {branding.colab && (
          <img
            className="details_banner_img"
            src={branding.colab}
            alt={title}
          />
        )}

        <div className="product_service-container">
          <div className="product_services">
            <div className="services_left">
              <div className="product_services-content">
                <h3>Why {title}?</h3>
                <br />
                {whyContent.map((c, idx) => (
                  <p key={idx}>{c}</p>
                ))}
              </div>
            </div>
            <div
              style={{ backgroundColor: "transparent" }}
              className="services_right img_sm ml"
            >
              <img src={whyImg} alt={title} />
            </div>
          </div>
        </div>
        <div className="product_service-container">
          <div className="product_services">
            <div className="services_right">
              <img src={wws} alt="img" />
            </div>
            <div className="services_left">
              <div className="product_services-content">
                <h3>What we serve</h3>
                <br />
                {wwsContent.title?.map((title, uid) => (
                  <p key={uid}>{title}</p>
                ))}
                {wwsContent.items.map(({ title, content }, idx) => (
                  <div key={idx} className="wws_list">
                    <h4>
                      {idx + 1}. {title}
                    </h4>
                    <p>{content}</p>
                  </div>
                ))}
                {wwsContent?.note?.map((note, uid) => (
                  <p key={uid}>{note}</p>
                ))}
              </div>
            </div>
          </div>

          <div className="img_show img_show_sm">
            <h3>Machinery used in kiosk</h3>
            <div className="list_sm">
              {machines.map(({ title, img }, idx) => (
                <div className="machines" key={idx}>
                  <div className="machines_img">
                    <img src={img} alt={title} />
                  </div>
                  <span>{title}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="product_services">
            <div className="services_right img_sm menu_img">
              <img className="" src={menuImg} alt="img" />
            </div>
            <div className="services_left">
              <div className="product_services-content">
                <h3>Menu</h3>
                <br />
                <p>Choose your size</p>
                {menu.map((m, idx) => (
                  <div key={idx} className="wws_list">
                    <h4>
                      {idx + 1}. {m}
                    </h4>
                    <br />
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="img_show img_show_sm">
          <h3>Menu: {menuSec.title}</h3>
          <div className="list_sm">
            {menuSec.items.map(({ title, img }, idx) => (
              <div key={idx}>
                <img src={img} alt={title} />
                <span>{title}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="img_show">
          <h3>Branding Materials</h3>
          <div className="list branding">
            {branding.items.map(({ title, img }, idx) => (
              <div key={idx} className="branding_materials">
                <div className="branding_img">
                  <img src={img} alt={title} />
                </div>
                <span>{title}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="product_details-img">
          <img src={productImg2} alt={title} />
        </div>
      </section>
    </div>
  );
};
