import React from "react";
import "./Team.scss";
import { teams } from "constants";

export const Team = () => {
  return (
    <div className="team_container">
      <section>
        <div className="content_header">
          <h2>Meet Our Team</h2>
          <p className="justify">
            Our team is a vast representation of creative skillsets with years
            of experience and expertise in budding industries.
          </p>
        </div>

        <div className="team_card">
          {teams.map((team, uid) => (
            <div key={uid} className="team">
              <div className="team_card-img">
                <img src={team.img} alt={team.name} />
              </div>
              <h3>{team.name}</h3>
              <p>{team.role}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
