import { useState, useContext } from "react";
import "./Showcase.scss";
import { products } from "constants";
// import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { NavLink } from "react-router-dom";
import { PURCHASE } from "constants";
import AppContext from "contexts/AppContext";
import urls from "api/urls";
import { capitalizeFirstLtter, currencyFormatter } from "utils";

export const Showcase = () => {
  const [activeThumbs, setActiveThumbs] = useState();
  const { concepts, plans } = useContext(AppContext);

  return (
    <div className="showcase_container">
      <section>
        <div className="showcase_filters">
          <h2>Our Products</h2>

          <Swiper
            slidesPerView={5}
            onSwiper={setActiveThumbs}
            modules={[Navigation, Thumbs, A11y]}
            className="filter_buttons"
          >
            {concepts.map((product, index) => (
              <SwiperSlide key={index} className="single_button">
                <p>{product.name}</p>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="drop_down">
            <div className="drop_down-top">
              {/* <p>{activeThumbs}</p> */}
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none">
                <path
                  d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z"
                  fill="var(--black-tint-3)"
                />
              </svg>
            </div>
          </div>
        </div>
      </section>
      <Swiper
        spaceBetween={24}
        navigation={true}
        modules={[Navigation, Thumbs, A11y]}
        grabCursor={true}
        thumbs={{ swiper: activeThumbs }}
        className="products"
      >
        {concepts.map((product, uid) => (
          <SwiperSlide key={uid}>
            <section className="slide">
              <div className="product_img">
                <img
                  crossOrigin="anonymous"
                  src={urls.baseUrl + urls.files.baseUrl + product.imageUrl}
                  alt={product.name}
                />
              </div>

              <div className="product_content">
                <h3 className="product_title">{product.name}</h3>
                <p className="justify">{product.description}</p>
                {/* <div className="product_price">
                  <h3>₦{currencyFormatter(plans[0]?.fee?.value)}</h3>
                  <p>
                    {capitalizeFirstLtter(plans[0]?.fee?.duration)} subscription
                    fee
                  </p>
                </div> */}
                <br />
                <div className="product_buttons">
                  <button className="product_select">
                    <NavLink
                      to={PURCHASE + `?concept=${product._id}`}
                      // className="product_select"
                    >
                      Select Concept
                    </NavLink>
                  </button>
                  {/* <NavLink to={PRODUCTS}>
                    <button>View More Details</button>
                  </NavLink> */}
                  <button
                    disabled={!product.imageUrl}
                    onClick={() =>
                      window.open(
                        urls.baseUrl + urls.files.baseUrl + product.imageUrl
                      )
                    }
                  >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
                        fill="var(--black-tint-3)"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </section>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
