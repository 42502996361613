import React from "react";
import "./HomePage.scss";
import {
  About,
  Banner,
  FAQ,
  // Partners,
  Team,
  Testimonials,
  Location,
  Contact,
  Blog,
  Showcase,
} from "components";

export const HomePage = () => {
  return (
    <div>
      <Banner />
      {/* <Partners /> */}
      <About />
      <Showcase />
      <Team />
      <Testimonials />
      <Blog />
      <FAQ />
      <Location />
      <Contact />
    </div>
  );
};
