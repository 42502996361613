import { Gallery } from "components";
import React from "react";

export const GalleryPage = () => {
  return (
    <>
      <Gallery />
    </>
  );
};
