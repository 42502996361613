const urls = {
  // baseUrl: "http://192.168.89.156:1818/api",
  baseUrl: "https://live-api-icart.onrender.com/api",
  // baseUrl: "http://localhost:1818/api",
  business: {
    plans: "/v1/business/subscriptions/plans",
    subscribe: "/v1/business/subscriptions/subscribe",
    preorder: "/v1/business/subscriptions/preorder",
    preorderRate: "/v1/business/subscriptions/preorder-rate",
    concepts: "/v1/business/concepts",
  },
  auth: {
    signup: "/v1/auth/signup",
  },
  contact: {
    baseUrl: "/v1/contact",
  },
  news: {
    baseUrl: "/v1/news/signup",
  },
  files: {
    baseUrl: "/v1/files/",
  },
};

// test

export default urls;
