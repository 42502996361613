import React from "react";
import "./FAQ.scss";
import { accordions } from "constants";

export const FAQ = () => {
  const [isSelected, setIsSelected] = React.useState(null);

  const handleShowAccordion = (index) => {
    if (isSelected === index) {
      return setIsSelected(null);
    }

    setIsSelected(index);
  };

  return (
    <div className="faq_container">
      <section>
        <div className="content_header">
          <h2>Frequently Asked Questions (FAQs)</h2>
          <p>Quick answers to questions you may have. </p>
        </div>

        <div className="accordion_container">
          {accordions.map((accordion, uid) => (
            <div className="accordion" key={uid}>
              <div className="accordion_top">
                <h4>
                  {accordion.question} {uid + 1}
                </h4>
                <div onClick={() => handleShowAccordion(uid)}>
                  {isSelected !== uid ? (
                    <span>
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M12.6667 20.5408H15.3334V15.2074H20.6667V12.5408H15.3334V7.20744H12.6667V12.5408H7.33335V15.2074H12.6667V20.5408ZM14 27.2074C12.1556 27.2074 10.4222 26.8574 8.80002 26.1574C7.1778 25.4574 5.76669 24.5074 4.56669 23.3074C3.36669 22.1074 2.41669 20.6963 1.71669 19.0741C1.01669 17.4519 0.666687 15.7185 0.666687 13.8741C0.666687 12.0297 1.01669 10.2963 1.71669 8.6741C2.41669 7.05188 3.36669 5.64077 4.56669 4.44077C5.76669 3.24077 7.1778 2.29077 8.80002 1.59077C10.4222 0.890771 12.1556 0.540771 14 0.540771C15.8445 0.540771 17.5778 0.890771 19.2 1.59077C20.8222 2.29077 22.2334 3.24077 23.4334 4.44077C24.6334 5.64077 25.5834 7.05188 26.2834 8.6741C26.9834 10.2963 27.3334 12.0297 27.3334 13.8741C27.3334 15.7185 26.9834 17.4519 26.2834 19.0741C25.5834 20.6963 24.6334 22.1074 23.4334 23.3074C22.2334 24.5074 20.8222 25.4574 19.2 26.1574C17.5778 26.8574 15.8445 27.2074 14 27.2074ZM14 24.5408C16.9778 24.5408 19.5 23.5074 21.5667 21.4408C23.6334 19.3741 24.6667 16.8519 24.6667 13.8741C24.6667 10.8963 23.6334 8.3741 21.5667 6.30744C19.5 4.24077 16.9778 3.20744 14 3.20744C11.0222 3.20744 8.50002 4.24077 6.43335 6.30744C4.36669 8.3741 3.33335 10.8963 3.33335 13.8741C3.33335 16.8519 4.36669 19.3741 6.43335 21.4408C8.50002 23.5074 11.0222 24.5408 14 24.5408Z"
                          fill="var(--yellow-main)"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span>
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M7.33335 15.2074H20.6667V12.5408H7.33335V15.2074ZM14 27.2074C12.1556 27.2074 10.4222 26.8574 8.80002 26.1574C7.1778 25.4574 5.76669 24.5074 4.56669 23.3074C3.36669 22.1074 2.41669 20.6963 1.71669 19.0741C1.01669 17.4519 0.666687 15.7185 0.666687 13.8741C0.666687 12.0297 1.01669 10.2963 1.71669 8.6741C2.41669 7.05188 3.36669 5.64077 4.56669 4.44077C5.76669 3.24077 7.1778 2.29077 8.80002 1.59077C10.4222 0.890771 12.1556 0.540771 14 0.540771C15.8445 0.540771 17.5778 0.890771 19.2 1.59077C20.8222 2.29077 22.2334 3.24077 23.4334 4.44077C24.6334 5.64077 25.5834 7.05188 26.2834 8.6741C26.9834 10.2963 27.3334 12.0297 27.3334 13.8741C27.3334 15.7185 26.9834 17.4519 26.2834 19.0741C25.5834 20.6963 24.6334 22.1074 23.4334 23.3074C22.2334 24.5074 20.8222 25.4574 19.2 26.1574C17.5778 26.8574 15.8445 27.2074 14 27.2074ZM14 24.5408C16.9778 24.5408 19.5 23.5074 21.5667 21.4408C23.6334 19.3741 24.6667 16.8519 24.6667 13.8741C24.6667 10.8963 23.6334 8.3741 21.5667 6.30744C19.5 4.24077 16.9778 3.20744 14 3.20744C11.0222 3.20744 8.50002 4.24077 6.43335 6.30744C4.36669 8.3741 3.33335 10.8963 3.33335 13.8741C3.33335 16.8519 4.36669 19.3741 6.43335 21.4408C8.50002 23.5074 11.0222 24.5408 14 24.5408Z"
                          fill="var(--yellow-main)"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
              <p
                className={
                  isSelected !== uid
                    ? "accordion_bottom justify showAccordion"
                    : "accordion_bottom justify"
                }
              >
                {accordion.answer}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
