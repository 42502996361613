function TermsAndConditions(props) {
  return (
    <div className="container privacy-policy purchase-terms">
      <h2>iCart Africa Pre Order Terms and Conditions</h2>
      <h4>1. Introduction</h4>
      <p>
        Welcome to the iCart Africa website! These Terms and Conditions
        ("Terms") govern the use of the website and the pre order purchase of
        iCarts. By using this website or ordering an iCart, you agree to be
        bound by these Terms.
      </p>
      <h4>2. About iCart Africa</h4>
      <ul>
        <li>
          iCart Africa is the owner and operator of the website.
          <li>
            They offer a pre-order for their innovative mobile, quick-service
            restaurant, the iCart.
          </li>
        </li>
      </ul>
      <h4>3. Product Description</h4>
      <ul>
        <li>
          The iCart is a mobile, quick-service restaurant without the attached
          machinery and utensils.
        </li>
        <li>
          It offers a unique opportunity for entrepreneurs or individuals to
          enter the food service industry.
        </li>
      </ul>
      <h4>4. Pricing and Payment</h4>
      <ul>
        <li> The base price of the iCart is ₦0.00.</li>
        <li> Payment methods are online only.</li>
        <li>
          {" "}
          Value Added Tax (VAT) will be applied: 5% for individuals, 7.5% for
          registered entities.
        </li>

        <li>
          {" "}
          The final price will be displayed during checkout, including
          applicable VAT.
        </li>
      </ul>
      <h4>5. Order Process</h4>
      <li>
        {" "}
        Pre-orders open on July 10th, 2024, and close oafter 300 iCarts are
        sold.
      </li>
      <li>
        {" "}
        Orders are placed through this platform after agreeing to the Terms.
      </li>
      <li> Payment validates the order.</li>
      <li> Cancellations and refunds are not allowed.</li>
      <h4>6. Delivery and Shipping</h4>
      <li> Preorder opens on July 10, 2024.</li>
      <li> Delivery begins on January 28, 2025.</li>
      <li> iCart Africa bears the shipping cost.</li>
      <li> Shipping cost is borne by iCart Africa.</li>
      <li> Insurance is optional and buyer responsibility.</li>
      <h4>7. Intellectual Property</h4>
      <li>
        All intellectual property related to the iCart brand, including
        trademarks, designs, and copyrights etc., is solely owned by the
        company.
      </li>
      <li>
        iCart Africa grants the buyer a non-exclusive, non-transferable license
        to use the iCart and iCart Africa's trademarks solely for the purpose of
        operating the iCart as a mobile restaurant.
      </li>
      <li>
        The buyer is prohibited from modifying, reproducing, or distributing
        iCart Africa's intellectual property without written permission.
      </li>
      <h4>8. Privacy and Data Protection</h4>
      <li>
        {" "}
        iCart Africa respects your privacy. We will collect and use your
        personal data in accordance with our Privacy Policy, which is available
        on this website.
      </li>
      <h4>9. Liability and Disclaimers</h4>
      <li>
        {" "}
        iCart Africa does not provide any warranty for the iCart and disclaims
        any implied warranties.
      </li>
      <li> The iCart is provided "as is" and with all faults.</li>
      <h4>10. Termination</h4>
      <li>
        iCart Africa reserves the right to terminate these Terms or user access
        to the website for any reason, at any time, without notice.
      </li>
      <h4>11. Governing Law and Jurisdiction</h4>
      <li>
        {" "}
        These Terms shall be governed by and construed in accordance with the
        laws of Nigeria.
      </li>
      <li>
        {" "}
        Any dispute arising out of this Agreement shall be first attempted to be
        resolved through mediation in Nigeria. If mediation fails, arbitration
        in Nigeria under the Arbitration and Conciliation Act will be final and
        binding.
      </li>
      <h4>12. Pre-Order Limitations</h4>
      <li>This pre-order is limited to 300 iCarts only.</li>
      <h4>13. Pre-Order Options</h4>
      <li>
        This pre-order offers two options: Online Management and Standalone
        Purchase.
      </li>
      <h4>A. Online Management</h4>
      <li>
        Concept: iCart Africa manages the iCart for you, including supply chain,
        operations, and concept selection upon arrival.
      </li>
      <li>
        Benefits:
        <ul>
          <li>
            Remote access to monitor and control your iCart's activity.
            <li>
              Access to iCart Africa's pre-developed mobile app for management.
            </li>
            <li>
              Pre-selected concepts for iCart use (details on available concepts
              to be provided).
            </li>
            <li>
              Access to features like surveillance cameras and location tracking
              (subject to additional fees).
            </li>
          </li>
        </ul>
      </li>
      <li>
        Requirements:
        <ul>
          <li>
            Two-year subscription to online management features is mandatory.
          </li>
          <li>
            Active management of your iCart for two years is required before
            opting out.
          </li>
          <li>
            Opting out restricts access to certain features (details specified
            in 14 below).
          </li>
          <li>
            A separate management agreement will be signed for online
            management.
          </li>
        </ul>
      </li>
      <h4>B. Standalone Purchase</h4>
      <li>
        Concept: You are purchasing the iCart itself. You are responsible for
        managing all aspects of the business, including concept selection,
        supply chain, and operations.
      </li>
      <li>
        Requirements:
        <ul>
          <li>
            You will receive a standalone iCart with no pre-selected concepts or
            management features.
          </li>
          <li>iCart Africa's intellectual property rights apply.</li>
        </ul>
      </li>
      <h4>14. Online Management</h4>
      <li>
        Online management allows remote control, management and monitoring of
        your iCart and its use after delivery.
      </li>
      <li>
        Upon the iCart’s arrival in Nigeria, purchasers opting for online
        management will receive an email with their iCart’s serial number.
      </li>
      <li>
        They must download the company’s app, add the serial number, and make
        necessary payments.
      </li>
      <li>
        Online management requires an active two-year subscription. Online
        management subscribers must actively manage their iCart for two years
        before opting out.
      </li>
      <li>
        Opting out restricts access to various features, including the supply
        chain, operators, concepts, mobile app, surveillance cameras, locations,
        and intellectual properties.
      </li>
      <li>A management registration fee applies.</li>
      <li>
        A separate management agreement will be signed for online management.
      </li>
      15. Concepts.
      <li>
        Online management users can choose concepts for iCart use upon arrival.
      </li>
      <h4>16. Insurance</h4>
      <li>Optional shipping insurance is available.</li>
      <li>Buyer is responsible for any damages if not insured.</li>
      <h4>17. Eligibility</h4>
      <li>Only adults above 18 years can purchase.</li>
      <h4>18. Assignment and Subcontracting</h4>
      <li>
        This agreement may not be assigned by the buyer without the prior
        written consent of iCart Africa.
      </li>
      <li>
        iCart Africa reserves the right to subcontract any part of the
        manufacturing process to a qualified third party.
      </li>
      <h4>19. Force Majeure</h4>
      <li>
        iCart Africa shall not be liable for delays in performance due to force
        majeure events, which include but are not limited to acts of God,
        natural disasters, strikes, wars, or government regulations that
        restrict manufacturing or transportation.
      </li>
      <li>
        In the event of a force majeure event, iCart Africa will notify the
        other party as soon as reasonably possible.
      </li>
      <li>
        Delivery deadlines will be extended by a period equal to the time
        performance was hindered by the force majeure event, or the contract may
        be terminated upon written notice by either party without liability.
      </li>
      <h4>20. Indemnification </h4>
      <li>
        The buyer agrees to indemnify and hold harmless iCart Africa from any
        and all claims, losses, liabilities, and expenses (including attorneys'
        fees) arising out of or relating to the buyer's use or misuse of the
        iCart, except for those claims arising from iCart Africa's intentional
        misconduct.
      </li>
      <h4>21. Signature and Agreement</h4>
      <li>
        By placing a pre-order for an iCart, you acknowledge that you have read,
        understood, and agree to be bound by these Terms and Conditions.
      </li>
      Electronic Acceptance: During the online checkout process, you will be
      required to check a box confirming your acceptance of these Terms and
      Conditions. Name (Print): Name (Electronic Signature)
    </div>
  );
}

export default TermsAndConditions;
