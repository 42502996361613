import React from "react";
import "./Location.scss";
import { operator } from "assets";
import { useNavigate } from "react-router-dom";
import { OPERATOR } from "constants";

export const Location = () => {
  const [tooltip1, setTooltip1] = React.useState(false);
  const [tooltip2, setTooltip2] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div className="location_container">
      <section>
        <div className="operator">
          <div className="operator_top">
            <h2>Become an iCart Operator?</h2>
            <button
              onClick={() => {
                navigate(OPERATOR);
              }}
              type="button"
              className="operator_top-btn"
            >
              Get started
            </button>
          </div>
          <div className="operator_bottom">
            <img src={operator} alt="operator" />
          </div>
        </div>
        <div className="location">
          <div className="content_header-2">
            <h2>Our Locations</h2>
            <p>
              Get in touch with us for any enquiries and custom requests for a
              concept
            </p>
          </div>
          <div className="location_map">
            <svg
              width="1200"
              height="584"
              viewBox="0 0 1200 584"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1140.3 542.433C1141.29 542.433 1142.09 541.63 1142.09 540.638C1142.09 539.647 1141.29 538.844 1140.3 538.844C1139.31 538.844 1138.51 539.647 1138.51 540.638C1138.51 541.63 1139.31 542.433 1140.3 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M1134.33 542.433C1135.32 542.433 1136.12 541.63 1136.12 540.638C1136.12 539.647 1135.32 538.844 1134.33 538.844C1133.34 538.844 1132.54 539.647 1132.54 540.638C1132.54 541.63 1133.34 542.433 1134.33 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M1128.36 542.433C1129.35 542.433 1130.15 541.63 1130.15 540.638C1130.15 539.647 1129.35 538.844 1128.36 538.844C1127.37 538.844 1126.57 539.647 1126.57 540.638C1126.57 541.63 1127.37 542.433 1128.36 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M1146.27 536.451C1147.26 536.451 1148.06 535.647 1148.06 534.656C1148.06 533.665 1147.26 532.861 1146.27 532.861C1145.28 532.861 1144.48 533.665 1144.48 534.656C1144.48 535.647 1145.28 536.451 1146.27 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M1140.3 536.451C1141.29 536.451 1142.09 535.647 1142.09 534.656C1142.09 533.665 1141.29 532.861 1140.3 532.861C1139.31 532.861 1138.51 533.665 1138.51 534.656C1138.51 535.647 1139.31 536.451 1140.3 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M1134.33 536.451C1135.32 536.451 1136.12 535.647 1136.12 534.656C1136.12 533.665 1135.32 532.861 1134.33 532.861C1133.34 532.861 1132.54 533.665 1132.54 534.656C1132.54 535.647 1133.34 536.451 1134.33 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M1152.24 530.468C1153.23 530.468 1154.03 529.665 1154.03 528.674C1154.03 527.682 1153.23 526.879 1152.24 526.879C1151.25 526.879 1150.45 527.682 1150.45 528.674C1150.45 529.665 1151.25 530.468 1152.24 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M1146.27 530.468C1147.26 530.468 1148.06 529.665 1148.06 528.674C1148.06 527.682 1147.26 526.879 1146.27 526.879C1145.28 526.879 1144.48 527.682 1144.48 528.674C1144.48 529.665 1145.28 530.468 1146.27 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 530.468C1063.68 530.468 1064.48 529.665 1064.48 528.674C1064.48 527.682 1063.68 526.879 1062.69 526.879C1061.7 526.879 1060.9 527.682 1060.9 528.674C1060.9 529.665 1061.7 530.468 1062.69 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 530.468C1057.71 530.468 1058.51 529.665 1058.51 528.674C1058.51 527.682 1057.71 526.879 1056.72 526.879C1055.73 526.879 1054.93 527.682 1054.93 528.674C1054.93 529.665 1055.73 530.468 1056.72 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M1158.21 524.486C1159.2 524.486 1160 523.683 1160 522.692C1160 521.7 1159.2 520.897 1158.21 520.897C1157.22 520.897 1156.42 521.7 1156.42 522.692C1156.42 523.683 1157.22 524.486 1158.21 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 524.486C1069.65 524.486 1070.45 523.683 1070.45 522.692C1070.45 521.7 1069.65 520.897 1068.66 520.897C1067.67 520.897 1066.87 521.7 1066.87 522.692C1066.87 523.683 1067.67 524.486 1068.66 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 524.486C1063.68 524.486 1064.48 523.683 1064.48 522.692C1064.48 521.7 1063.68 520.897 1062.69 520.897C1061.7 520.897 1060.9 521.7 1060.9 522.692C1060.9 523.683 1061.7 524.486 1062.69 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M1176.12 518.504C1177.11 518.504 1177.91 517.7 1177.91 516.709C1177.91 515.718 1177.11 514.915 1176.12 514.915C1175.13 514.915 1174.33 515.718 1174.33 516.709C1174.33 517.7 1175.13 518.504 1176.12 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M1170.15 518.504C1171.14 518.504 1171.94 517.7 1171.94 516.709C1171.94 515.718 1171.14 514.915 1170.15 514.915C1169.16 514.915 1168.36 515.718 1168.36 516.709C1168.36 517.7 1169.16 518.504 1170.15 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M1188.06 512.522C1189.05 512.522 1189.85 511.718 1189.85 510.727C1189.85 509.736 1189.05 508.932 1188.06 508.932C1187.07 508.932 1186.27 509.736 1186.27 510.727C1186.27 511.718 1187.07 512.522 1188.06 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M1182.09 512.522C1183.08 512.522 1183.88 511.718 1183.88 510.727C1183.88 509.736 1183.08 508.932 1182.09 508.932C1181.1 508.932 1180.3 509.736 1180.3 510.727C1180.3 511.718 1181.1 512.522 1182.09 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M1176.12 512.522C1177.11 512.522 1177.91 511.718 1177.91 510.727C1177.91 509.736 1177.11 508.932 1176.12 508.932C1175.13 508.932 1174.33 509.736 1174.33 510.727C1174.33 511.718 1175.13 512.522 1176.12 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 512.522C1069.65 512.522 1070.45 511.718 1070.45 510.727C1070.45 509.736 1069.65 508.932 1068.66 508.932C1067.67 508.932 1066.87 509.736 1066.87 510.727C1066.87 511.718 1067.67 512.522 1068.66 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M1182.09 506.54C1183.08 506.54 1183.88 505.736 1183.88 504.745C1183.88 503.754 1183.08 502.95 1182.09 502.95C1181.1 502.95 1180.3 503.754 1180.3 504.745C1180.3 505.736 1181.1 506.54 1182.09 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 506.54C1087.56 506.54 1088.36 505.736 1088.36 504.745C1088.36 503.754 1087.56 502.95 1086.57 502.95C1085.58 502.95 1084.78 503.754 1084.78 504.745C1084.78 505.736 1085.58 506.54 1086.57 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 506.54C1081.59 506.54 1082.39 505.736 1082.39 504.745C1082.39 503.754 1081.59 502.95 1080.6 502.95C1079.61 502.95 1078.81 503.754 1078.81 504.745C1078.81 505.736 1079.61 506.54 1080.6 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 506.54C1075.62 506.54 1076.42 505.736 1076.42 504.745C1076.42 503.754 1075.62 502.95 1074.63 502.95C1073.64 502.95 1072.84 503.754 1072.84 504.745C1072.84 505.736 1073.64 506.54 1074.63 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 506.54C1069.65 506.54 1070.45 505.736 1070.45 504.745C1070.45 503.754 1069.65 502.95 1068.66 502.95C1067.67 502.95 1066.87 503.754 1066.87 504.745C1066.87 505.736 1067.67 506.54 1068.66 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 506.54C1063.68 506.54 1064.48 505.736 1064.48 504.745C1064.48 503.754 1063.68 502.95 1062.69 502.95C1061.7 502.95 1060.9 503.754 1060.9 504.745C1060.9 505.736 1061.7 506.54 1062.69 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 506.54C1057.71 506.54 1058.51 505.736 1058.51 504.745C1058.51 503.754 1057.71 502.95 1056.72 502.95C1055.73 502.95 1054.93 503.754 1054.93 504.745C1054.93 505.736 1055.73 506.54 1056.72 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M1182.09 500.557C1183.08 500.557 1183.88 499.754 1183.88 498.762C1183.88 497.771 1183.08 496.968 1182.09 496.968C1181.1 496.968 1180.3 497.771 1180.3 498.762C1180.3 499.754 1181.1 500.557 1182.09 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 500.557C1087.56 500.557 1088.36 499.754 1088.36 498.762C1088.36 497.771 1087.56 496.968 1086.57 496.968C1085.58 496.968 1084.78 497.771 1084.78 498.762C1084.78 499.754 1085.58 500.557 1086.57 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 500.557C1081.59 500.557 1082.39 499.754 1082.39 498.762C1082.39 497.771 1081.59 496.968 1080.6 496.968C1079.61 496.968 1078.81 497.771 1078.81 498.762C1078.81 499.754 1079.61 500.557 1080.6 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 500.557C1075.62 500.557 1076.42 499.754 1076.42 498.762C1076.42 497.771 1075.62 496.968 1074.63 496.968C1073.64 496.968 1072.84 497.771 1072.84 498.762C1072.84 499.754 1073.64 500.557 1074.63 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 500.557C1069.65 500.557 1070.45 499.754 1070.45 498.762C1070.45 497.771 1069.65 496.968 1068.66 496.968C1067.67 496.968 1066.87 497.771 1066.87 498.762C1066.87 499.754 1067.67 500.557 1068.66 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 500.557C1063.68 500.557 1064.48 499.754 1064.48 498.762C1064.48 497.771 1063.68 496.968 1062.69 496.968C1061.7 496.968 1060.9 497.771 1060.9 498.762C1060.9 499.754 1061.7 500.557 1062.69 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 500.557C1057.71 500.557 1058.51 499.754 1058.51 498.762C1058.51 497.771 1057.71 496.968 1056.72 496.968C1055.73 496.968 1054.93 497.771 1054.93 498.762C1054.93 499.754 1055.73 500.557 1056.72 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 494.575C1093.53 494.575 1094.33 493.771 1094.33 492.78C1094.33 491.789 1093.53 490.985 1092.54 490.985C1091.55 490.985 1090.75 491.789 1090.75 492.78C1090.75 493.771 1091.55 494.575 1092.54 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 494.575C1087.56 494.575 1088.36 493.771 1088.36 492.78C1088.36 491.789 1087.56 490.985 1086.57 490.985C1085.58 490.985 1084.78 491.789 1084.78 492.78C1084.78 493.771 1085.58 494.575 1086.57 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 494.575C1081.59 494.575 1082.39 493.771 1082.39 492.78C1082.39 491.789 1081.59 490.985 1080.6 490.985C1079.61 490.985 1078.81 491.789 1078.81 492.78C1078.81 493.771 1079.61 494.575 1080.6 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 494.575C1075.62 494.575 1076.42 493.771 1076.42 492.78C1076.42 491.789 1075.62 490.985 1074.63 490.985C1073.64 490.985 1072.84 491.789 1072.84 492.78C1072.84 493.771 1073.64 494.575 1074.63 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 494.575C1069.65 494.575 1070.45 493.771 1070.45 492.78C1070.45 491.789 1069.65 490.985 1068.66 490.985C1067.67 490.985 1066.87 491.789 1066.87 492.78C1066.87 493.771 1067.67 494.575 1068.66 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 494.575C1063.68 494.575 1064.48 493.771 1064.48 492.78C1064.48 491.789 1063.68 490.985 1062.69 490.985C1061.7 490.985 1060.9 491.789 1060.9 492.78C1060.9 493.771 1061.7 494.575 1062.69 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 494.575C1057.71 494.575 1058.51 493.771 1058.51 492.78C1058.51 491.789 1057.71 490.985 1056.72 490.985C1055.73 490.985 1054.93 491.789 1054.93 492.78C1054.93 493.771 1055.73 494.575 1056.72 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 494.575C1051.74 494.575 1052.54 493.771 1052.54 492.78C1052.54 491.789 1051.74 490.985 1050.75 490.985C1049.76 490.985 1048.96 491.789 1048.96 492.78C1048.96 493.771 1049.76 494.575 1050.75 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 494.575C980.094 494.575 980.896 493.771 980.896 492.78C980.896 491.789 980.094 490.985 979.104 490.985C978.115 490.985 977.313 491.789 977.313 492.78C977.313 493.771 978.115 494.575 979.104 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 494.575C974.123 494.575 974.925 493.771 974.925 492.78C974.925 491.789 974.123 490.985 973.134 490.985C972.145 490.985 971.343 491.789 971.343 492.78C971.343 493.771 972.145 494.575 973.134 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 494.575C968.153 494.575 968.955 493.771 968.955 492.78C968.955 491.789 968.153 490.985 967.164 490.985C966.175 490.985 965.373 491.789 965.373 492.78C965.373 493.771 966.175 494.575 967.164 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 488.593C1099.5 488.593 1100.3 487.789 1100.3 486.798C1100.3 485.807 1099.5 485.003 1098.51 485.003C1097.52 485.003 1096.72 485.807 1096.72 486.798C1096.72 487.789 1097.52 488.593 1098.51 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 488.593C1093.53 488.593 1094.33 487.789 1094.33 486.798C1094.33 485.807 1093.53 485.003 1092.54 485.003C1091.55 485.003 1090.75 485.807 1090.75 486.798C1090.75 487.789 1091.55 488.593 1092.54 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 488.593C1087.56 488.593 1088.36 487.789 1088.36 486.798C1088.36 485.807 1087.56 485.003 1086.57 485.003C1085.58 485.003 1084.78 485.807 1084.78 486.798C1084.78 487.789 1085.58 488.593 1086.57 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 488.593C1081.59 488.593 1082.39 487.789 1082.39 486.798C1082.39 485.807 1081.59 485.003 1080.6 485.003C1079.61 485.003 1078.81 485.807 1078.81 486.798C1078.81 487.789 1079.61 488.593 1080.6 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 488.593C1075.62 488.593 1076.42 487.789 1076.42 486.798C1076.42 485.807 1075.62 485.003 1074.63 485.003C1073.64 485.003 1072.84 485.807 1072.84 486.798C1072.84 487.789 1073.64 488.593 1074.63 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 488.593C1069.65 488.593 1070.45 487.789 1070.45 486.798C1070.45 485.807 1069.65 485.003 1068.66 485.003C1067.67 485.003 1066.87 485.807 1066.87 486.798C1066.87 487.789 1067.67 488.593 1068.66 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 488.593C1063.68 488.593 1064.48 487.789 1064.48 486.798C1064.48 485.807 1063.68 485.003 1062.69 485.003C1061.7 485.003 1060.9 485.807 1060.9 486.798C1060.9 487.789 1061.7 488.593 1062.69 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 488.593C1057.71 488.593 1058.51 487.789 1058.51 486.798C1058.51 485.807 1057.71 485.003 1056.72 485.003C1055.73 485.003 1054.93 485.807 1054.93 486.798C1054.93 487.789 1055.73 488.593 1056.72 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 488.593C1051.74 488.593 1052.54 487.789 1052.54 486.798C1052.54 485.807 1051.74 485.003 1050.75 485.003C1049.76 485.003 1048.96 485.807 1048.96 486.798C1048.96 487.789 1049.76 488.593 1050.75 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 488.593C1045.77 488.593 1046.57 487.789 1046.57 486.798C1046.57 485.807 1045.77 485.003 1044.78 485.003C1043.79 485.003 1042.99 485.807 1042.99 486.798C1042.99 487.789 1043.79 488.593 1044.78 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 488.593C1039.8 488.593 1040.6 487.789 1040.6 486.798C1040.6 485.807 1039.8 485.003 1038.81 485.003C1037.82 485.003 1037.01 485.807 1037.01 486.798C1037.01 487.789 1037.82 488.593 1038.81 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 488.593C998.004 488.593 998.806 487.789 998.806 486.798C998.806 485.807 998.004 485.003 997.015 485.003C996.026 485.003 995.224 485.807 995.224 486.798C995.224 487.789 996.026 488.593 997.015 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 488.593C992.034 488.593 992.836 487.789 992.836 486.798C992.836 485.807 992.034 485.003 991.045 485.003C990.056 485.003 989.254 485.807 989.254 486.798C989.254 487.789 990.056 488.593 991.045 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 488.593C986.064 488.593 986.866 487.789 986.866 486.798C986.866 485.807 986.064 485.003 985.075 485.003C984.085 485.003 983.284 485.807 983.284 486.798C983.284 487.789 984.085 488.593 985.075 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 488.593C980.094 488.593 980.896 487.789 980.896 486.798C980.896 485.807 980.094 485.003 979.104 485.003C978.115 485.003 977.313 485.807 977.313 486.798C977.313 487.789 978.115 488.593 979.104 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 488.593C974.123 488.593 974.925 487.789 974.925 486.798C974.925 485.807 974.123 485.003 973.134 485.003C972.145 485.003 971.343 485.807 971.343 486.798C971.343 487.789 972.145 488.593 973.134 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 488.593C968.153 488.593 968.955 487.789 968.955 486.798C968.955 485.807 968.153 485.003 967.164 485.003C966.175 485.003 965.373 485.807 965.373 486.798C965.373 487.789 966.175 488.593 967.164 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 488.593C627.855 488.593 628.657 487.789 628.657 486.798C628.657 485.807 627.855 485.003 626.866 485.003C625.876 485.003 625.075 485.807 625.075 486.798C625.075 487.789 625.876 488.593 626.866 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 488.593C615.915 488.593 616.716 487.789 616.716 486.798C616.716 485.807 615.915 485.003 614.925 485.003C613.936 485.003 613.134 485.807 613.134 486.798C613.134 487.789 613.936 488.593 614.925 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 488.593C609.944 488.593 610.746 487.789 610.746 486.798C610.746 485.807 609.944 485.003 608.955 485.003C607.966 485.003 607.164 485.807 607.164 486.798C607.164 487.789 607.966 488.593 608.955 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 488.593C603.974 488.593 604.776 487.789 604.776 486.798C604.776 485.807 603.974 485.003 602.985 485.003C601.996 485.003 601.194 485.807 601.194 486.798C601.194 487.789 601.996 488.593 602.985 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 482.61C1111.44 482.61 1112.24 481.807 1112.24 480.816C1112.24 479.825 1111.44 479.021 1110.45 479.021C1109.46 479.021 1108.66 479.825 1108.66 480.816C1108.66 481.807 1109.46 482.61 1110.45 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 482.61C1105.47 482.61 1106.27 481.807 1106.27 480.816C1106.27 479.825 1105.47 479.021 1104.48 479.021C1103.49 479.021 1102.69 479.825 1102.69 480.816C1102.69 481.807 1103.49 482.61 1104.48 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 482.61C1099.5 482.61 1100.3 481.807 1100.3 480.816C1100.3 479.825 1099.5 479.021 1098.51 479.021C1097.52 479.021 1096.72 479.825 1096.72 480.816C1096.72 481.807 1097.52 482.61 1098.51 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 482.61C1093.53 482.61 1094.33 481.807 1094.33 480.816C1094.33 479.825 1093.53 479.021 1092.54 479.021C1091.55 479.021 1090.75 479.825 1090.75 480.816C1090.75 481.807 1091.55 482.61 1092.54 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 482.61C1087.56 482.61 1088.36 481.807 1088.36 480.816C1088.36 479.825 1087.56 479.021 1086.57 479.021C1085.58 479.021 1084.78 479.825 1084.78 480.816C1084.78 481.807 1085.58 482.61 1086.57 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 482.61C1081.59 482.61 1082.39 481.807 1082.39 480.816C1082.39 479.825 1081.59 479.021 1080.6 479.021C1079.61 479.021 1078.81 479.825 1078.81 480.816C1078.81 481.807 1079.61 482.61 1080.6 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 482.61C1075.62 482.61 1076.42 481.807 1076.42 480.816C1076.42 479.825 1075.62 479.021 1074.63 479.021C1073.64 479.021 1072.84 479.825 1072.84 480.816C1072.84 481.807 1073.64 482.61 1074.63 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 482.61C1069.65 482.61 1070.45 481.807 1070.45 480.816C1070.45 479.825 1069.65 479.021 1068.66 479.021C1067.67 479.021 1066.87 479.825 1066.87 480.816C1066.87 481.807 1067.67 482.61 1068.66 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 482.61C1063.68 482.61 1064.48 481.807 1064.48 480.816C1064.48 479.825 1063.68 479.021 1062.69 479.021C1061.7 479.021 1060.9 479.825 1060.9 480.816C1060.9 481.807 1061.7 482.61 1062.69 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 482.61C1057.71 482.61 1058.51 481.807 1058.51 480.816C1058.51 479.825 1057.71 479.021 1056.72 479.021C1055.73 479.021 1054.93 479.825 1054.93 480.816C1054.93 481.807 1055.73 482.61 1056.72 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 482.61C1051.74 482.61 1052.54 481.807 1052.54 480.816C1052.54 479.825 1051.74 479.021 1050.75 479.021C1049.76 479.021 1048.96 479.825 1048.96 480.816C1048.96 481.807 1049.76 482.61 1050.75 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 482.61C1045.77 482.61 1046.57 481.807 1046.57 480.816C1046.57 479.825 1045.77 479.021 1044.78 479.021C1043.79 479.021 1042.99 479.825 1042.99 480.816C1042.99 481.807 1043.79 482.61 1044.78 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 482.61C1039.8 482.61 1040.6 481.807 1040.6 480.816C1040.6 479.825 1039.8 479.021 1038.81 479.021C1037.82 479.021 1037.01 479.825 1037.01 480.816C1037.01 481.807 1037.82 482.61 1038.81 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 482.61C1033.82 482.61 1034.63 481.807 1034.63 480.816C1034.63 479.825 1033.82 479.021 1032.84 479.021C1031.85 479.021 1031.04 479.825 1031.04 480.816C1031.04 481.807 1031.85 482.61 1032.84 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 482.61C1021.88 482.61 1022.69 481.807 1022.69 480.816C1022.69 479.825 1021.88 479.021 1020.9 479.021C1019.91 479.021 1019.1 479.825 1019.1 480.816C1019.1 481.807 1019.91 482.61 1020.9 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 482.61C1015.91 482.61 1016.72 481.807 1016.72 480.816C1016.72 479.825 1015.91 479.021 1014.93 479.021C1013.94 479.021 1013.13 479.825 1013.13 480.816C1013.13 481.807 1013.94 482.61 1014.93 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 482.61C1009.94 482.61 1010.75 481.807 1010.75 480.816C1010.75 479.825 1009.94 479.021 1008.96 479.021C1007.97 479.021 1007.16 479.825 1007.16 480.816C1007.16 481.807 1007.97 482.61 1008.96 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 482.61C1003.97 482.61 1004.78 481.807 1004.78 480.816C1004.78 479.825 1003.97 479.021 1002.99 479.021C1002 479.021 1001.19 479.825 1001.19 480.816C1001.19 481.807 1002 482.61 1002.99 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 482.61C998.004 482.61 998.806 481.807 998.806 480.816C998.806 479.825 998.004 479.021 997.015 479.021C996.026 479.021 995.224 479.825 995.224 480.816C995.224 481.807 996.026 482.61 997.015 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 482.61C992.034 482.61 992.836 481.807 992.836 480.816C992.836 479.825 992.034 479.021 991.045 479.021C990.056 479.021 989.254 479.825 989.254 480.816C989.254 481.807 990.056 482.61 991.045 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 482.61C986.064 482.61 986.866 481.807 986.866 480.816C986.866 479.825 986.064 479.021 985.075 479.021C984.085 479.021 983.284 479.825 983.284 480.816C983.284 481.807 984.085 482.61 985.075 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 482.61C980.094 482.61 980.896 481.807 980.896 480.816C980.896 479.825 980.094 479.021 979.104 479.021C978.115 479.021 977.313 479.825 977.313 480.816C977.313 481.807 978.115 482.61 979.104 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 482.61C974.123 482.61 974.925 481.807 974.925 480.816C974.925 479.825 974.123 479.021 973.134 479.021C972.145 479.021 971.343 479.825 971.343 480.816C971.343 481.807 972.145 482.61 973.134 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 482.61C639.795 482.61 640.597 481.807 640.597 480.816C640.597 479.825 639.795 479.021 638.806 479.021C637.817 479.021 637.015 479.825 637.015 480.816C637.015 481.807 637.817 482.61 638.806 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 482.61C633.825 482.61 634.627 481.807 634.627 480.816C634.627 479.825 633.825 479.021 632.836 479.021C631.847 479.021 631.045 479.825 631.045 480.816C631.045 481.807 631.847 482.61 632.836 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 482.61C627.855 482.61 628.657 481.807 628.657 480.816C628.657 479.825 627.855 479.021 626.866 479.021C625.876 479.021 625.075 479.825 625.075 480.816C625.075 481.807 625.876 482.61 626.866 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 482.61C621.885 482.61 622.687 481.807 622.687 480.816C622.687 479.825 621.885 479.021 620.895 479.021C619.906 479.021 619.104 479.825 619.104 480.816C619.104 481.807 619.906 482.61 620.895 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 482.61C615.915 482.61 616.716 481.807 616.716 480.816C616.716 479.825 615.915 479.021 614.925 479.021C613.936 479.021 613.134 479.825 613.134 480.816C613.134 481.807 613.936 482.61 614.925 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 482.61C609.944 482.61 610.746 481.807 610.746 480.816C610.746 479.825 609.944 479.021 608.955 479.021C607.966 479.021 607.164 479.825 607.164 480.816C607.164 481.807 607.966 482.61 608.955 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 482.61C603.974 482.61 604.776 481.807 604.776 480.816C604.776 479.825 603.974 479.021 602.985 479.021C601.996 479.021 601.194 479.825 601.194 480.816C601.194 481.807 601.996 482.61 602.985 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 476.628C1111.44 476.628 1112.24 475.824 1112.24 474.833C1112.24 473.842 1111.44 473.039 1110.45 473.039C1109.46 473.039 1108.66 473.842 1108.66 474.833C1108.66 475.824 1109.46 476.628 1110.45 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 476.628C1105.47 476.628 1106.27 475.824 1106.27 474.833C1106.27 473.842 1105.47 473.039 1104.48 473.039C1103.49 473.039 1102.69 473.842 1102.69 474.833C1102.69 475.824 1103.49 476.628 1104.48 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 476.628C1099.5 476.628 1100.3 475.824 1100.3 474.833C1100.3 473.842 1099.5 473.039 1098.51 473.039C1097.52 473.039 1096.72 473.842 1096.72 474.833C1096.72 475.824 1097.52 476.628 1098.51 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 476.628C1093.53 476.628 1094.33 475.824 1094.33 474.833C1094.33 473.842 1093.53 473.039 1092.54 473.039C1091.55 473.039 1090.75 473.842 1090.75 474.833C1090.75 475.824 1091.55 476.628 1092.54 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 476.628C1087.56 476.628 1088.36 475.824 1088.36 474.833C1088.36 473.842 1087.56 473.039 1086.57 473.039C1085.58 473.039 1084.78 473.842 1084.78 474.833C1084.78 475.824 1085.58 476.628 1086.57 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 476.628C1081.59 476.628 1082.39 475.824 1082.39 474.833C1082.39 473.842 1081.59 473.039 1080.6 473.039C1079.61 473.039 1078.81 473.842 1078.81 474.833C1078.81 475.824 1079.61 476.628 1080.6 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 476.628C1075.62 476.628 1076.42 475.824 1076.42 474.833C1076.42 473.842 1075.62 473.039 1074.63 473.039C1073.64 473.039 1072.84 473.842 1072.84 474.833C1072.84 475.824 1073.64 476.628 1074.63 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 476.628C1069.65 476.628 1070.45 475.824 1070.45 474.833C1070.45 473.842 1069.65 473.039 1068.66 473.039C1067.67 473.039 1066.87 473.842 1066.87 474.833C1066.87 475.824 1067.67 476.628 1068.66 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 476.628C1063.68 476.628 1064.48 475.824 1064.48 474.833C1064.48 473.842 1063.68 473.039 1062.69 473.039C1061.7 473.039 1060.9 473.842 1060.9 474.833C1060.9 475.824 1061.7 476.628 1062.69 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 476.628C1057.71 476.628 1058.51 475.824 1058.51 474.833C1058.51 473.842 1057.71 473.039 1056.72 473.039C1055.73 473.039 1054.93 473.842 1054.93 474.833C1054.93 475.824 1055.73 476.628 1056.72 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 476.628C1051.74 476.628 1052.54 475.824 1052.54 474.833C1052.54 473.842 1051.74 473.039 1050.75 473.039C1049.76 473.039 1048.96 473.842 1048.96 474.833C1048.96 475.824 1049.76 476.628 1050.75 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 476.628C1045.77 476.628 1046.57 475.824 1046.57 474.833C1046.57 473.842 1045.77 473.039 1044.78 473.039C1043.79 473.039 1042.99 473.842 1042.99 474.833C1042.99 475.824 1043.79 476.628 1044.78 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 476.628C1039.8 476.628 1040.6 475.824 1040.6 474.833C1040.6 473.842 1039.8 473.039 1038.81 473.039C1037.82 473.039 1037.01 473.842 1037.01 474.833C1037.01 475.824 1037.82 476.628 1038.81 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 476.628C1033.82 476.628 1034.63 475.824 1034.63 474.833C1034.63 473.842 1033.82 473.039 1032.84 473.039C1031.85 473.039 1031.04 473.842 1031.04 474.833C1031.04 475.824 1031.85 476.628 1032.84 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 476.628C1027.85 476.628 1028.66 475.824 1028.66 474.833C1028.66 473.842 1027.85 473.039 1026.87 473.039C1025.88 473.039 1025.07 473.842 1025.07 474.833C1025.07 475.824 1025.88 476.628 1026.87 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 476.628C1021.88 476.628 1022.69 475.824 1022.69 474.833C1022.69 473.842 1021.88 473.039 1020.9 473.039C1019.91 473.039 1019.1 473.842 1019.1 474.833C1019.1 475.824 1019.91 476.628 1020.9 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 476.628C1015.91 476.628 1016.72 475.824 1016.72 474.833C1016.72 473.842 1015.91 473.039 1014.93 473.039C1013.94 473.039 1013.13 473.842 1013.13 474.833C1013.13 475.824 1013.94 476.628 1014.93 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 476.628C1009.94 476.628 1010.75 475.824 1010.75 474.833C1010.75 473.842 1009.94 473.039 1008.96 473.039C1007.97 473.039 1007.16 473.842 1007.16 474.833C1007.16 475.824 1007.97 476.628 1008.96 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 476.628C1003.97 476.628 1004.78 475.824 1004.78 474.833C1004.78 473.842 1003.97 473.039 1002.99 473.039C1002 473.039 1001.19 473.842 1001.19 474.833C1001.19 475.824 1002 476.628 1002.99 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 476.628C998.004 476.628 998.806 475.824 998.806 474.833C998.806 473.842 998.004 473.039 997.015 473.039C996.026 473.039 995.224 473.842 995.224 474.833C995.224 475.824 996.026 476.628 997.015 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 476.628C992.034 476.628 992.836 475.824 992.836 474.833C992.836 473.842 992.034 473.039 991.045 473.039C990.056 473.039 989.254 473.842 989.254 474.833C989.254 475.824 990.056 476.628 991.045 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 476.628C986.064 476.628 986.866 475.824 986.866 474.833C986.866 473.842 986.064 473.039 985.075 473.039C984.085 473.039 983.284 473.842 983.284 474.833C983.284 475.824 984.085 476.628 985.075 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 476.628C980.094 476.628 980.896 475.824 980.896 474.833C980.896 473.842 980.094 473.039 979.104 473.039C978.115 473.039 977.313 473.842 977.313 474.833C977.313 475.824 978.115 476.628 979.104 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 476.628C974.123 476.628 974.925 475.824 974.925 474.833C974.925 473.842 974.123 473.039 973.134 473.039C972.145 473.039 971.343 473.842 971.343 474.833C971.343 475.824 972.145 476.628 973.134 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 476.628C645.765 476.628 646.567 475.824 646.567 474.833C646.567 473.842 645.765 473.039 644.776 473.039C643.787 473.039 642.985 473.842 642.985 474.833C642.985 475.824 643.787 476.628 644.776 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 476.628C639.795 476.628 640.597 475.824 640.597 474.833C640.597 473.842 639.795 473.039 638.806 473.039C637.817 473.039 637.015 473.842 637.015 474.833C637.015 475.824 637.817 476.628 638.806 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 476.628C633.825 476.628 634.627 475.824 634.627 474.833C634.627 473.842 633.825 473.039 632.836 473.039C631.847 473.039 631.045 473.842 631.045 474.833C631.045 475.824 631.847 476.628 632.836 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 476.628C627.855 476.628 628.657 475.824 628.657 474.833C628.657 473.842 627.855 473.039 626.866 473.039C625.876 473.039 625.075 473.842 625.075 474.833C625.075 475.824 625.876 476.628 626.866 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 476.628C621.885 476.628 622.687 475.824 622.687 474.833C622.687 473.842 621.885 473.039 620.895 473.039C619.906 473.039 619.104 473.842 619.104 474.833C619.104 475.824 619.906 476.628 620.895 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 476.628C615.915 476.628 616.716 475.824 616.716 474.833C616.716 473.842 615.915 473.039 614.925 473.039C613.936 473.039 613.134 473.842 613.134 474.833C613.134 475.824 613.936 476.628 614.925 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 476.628C609.944 476.628 610.746 475.824 610.746 474.833C610.746 473.842 609.944 473.039 608.955 473.039C607.966 473.039 607.164 473.842 607.164 474.833C607.164 475.824 607.966 476.628 608.955 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 476.628C603.974 476.628 604.776 475.824 604.776 474.833C604.776 473.842 603.974 473.039 602.985 473.039C601.996 473.039 601.194 473.842 601.194 474.833C601.194 475.824 601.996 476.628 602.985 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M1116.42 470.646C1117.41 470.646 1118.21 469.842 1118.21 468.851C1118.21 467.86 1117.41 467.056 1116.42 467.056C1115.43 467.056 1114.63 467.86 1114.63 468.851C1114.63 469.842 1115.43 470.646 1116.42 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 470.646C1111.44 470.646 1112.24 469.842 1112.24 468.851C1112.24 467.86 1111.44 467.056 1110.45 467.056C1109.46 467.056 1108.66 467.86 1108.66 468.851C1108.66 469.842 1109.46 470.646 1110.45 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 470.646C1105.47 470.646 1106.27 469.842 1106.27 468.851C1106.27 467.86 1105.47 467.056 1104.48 467.056C1103.49 467.056 1102.69 467.86 1102.69 468.851C1102.69 469.842 1103.49 470.646 1104.48 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 470.646C1099.5 470.646 1100.3 469.842 1100.3 468.851C1100.3 467.86 1099.5 467.056 1098.51 467.056C1097.52 467.056 1096.72 467.86 1096.72 468.851C1096.72 469.842 1097.52 470.646 1098.51 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 470.646C1093.53 470.646 1094.33 469.842 1094.33 468.851C1094.33 467.86 1093.53 467.056 1092.54 467.056C1091.55 467.056 1090.75 467.86 1090.75 468.851C1090.75 469.842 1091.55 470.646 1092.54 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 470.646C1087.56 470.646 1088.36 469.842 1088.36 468.851C1088.36 467.86 1087.56 467.056 1086.57 467.056C1085.58 467.056 1084.78 467.86 1084.78 468.851C1084.78 469.842 1085.58 470.646 1086.57 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 470.646C1081.59 470.646 1082.39 469.842 1082.39 468.851C1082.39 467.86 1081.59 467.056 1080.6 467.056C1079.61 467.056 1078.81 467.86 1078.81 468.851C1078.81 469.842 1079.61 470.646 1080.6 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 470.646C1075.62 470.646 1076.42 469.842 1076.42 468.851C1076.42 467.86 1075.62 467.056 1074.63 467.056C1073.64 467.056 1072.84 467.86 1072.84 468.851C1072.84 469.842 1073.64 470.646 1074.63 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 470.646C1069.65 470.646 1070.45 469.842 1070.45 468.851C1070.45 467.86 1069.65 467.056 1068.66 467.056C1067.67 467.056 1066.87 467.86 1066.87 468.851C1066.87 469.842 1067.67 470.646 1068.66 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 470.646C1063.68 470.646 1064.48 469.842 1064.48 468.851C1064.48 467.86 1063.68 467.056 1062.69 467.056C1061.7 467.056 1060.9 467.86 1060.9 468.851C1060.9 469.842 1061.7 470.646 1062.69 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 470.646C1057.71 470.646 1058.51 469.842 1058.51 468.851C1058.51 467.86 1057.71 467.056 1056.72 467.056C1055.73 467.056 1054.93 467.86 1054.93 468.851C1054.93 469.842 1055.73 470.646 1056.72 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 470.646C1051.74 470.646 1052.54 469.842 1052.54 468.851C1052.54 467.86 1051.74 467.056 1050.75 467.056C1049.76 467.056 1048.96 467.86 1048.96 468.851C1048.96 469.842 1049.76 470.646 1050.75 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 470.646C1045.77 470.646 1046.57 469.842 1046.57 468.851C1046.57 467.86 1045.77 467.056 1044.78 467.056C1043.79 467.056 1042.99 467.86 1042.99 468.851C1042.99 469.842 1043.79 470.646 1044.78 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 470.646C1039.8 470.646 1040.6 469.842 1040.6 468.851C1040.6 467.86 1039.8 467.056 1038.81 467.056C1037.82 467.056 1037.01 467.86 1037.01 468.851C1037.01 469.842 1037.82 470.646 1038.81 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 470.646C1033.82 470.646 1034.63 469.842 1034.63 468.851C1034.63 467.86 1033.82 467.056 1032.84 467.056C1031.85 467.056 1031.04 467.86 1031.04 468.851C1031.04 469.842 1031.85 470.646 1032.84 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 470.646C1027.85 470.646 1028.66 469.842 1028.66 468.851C1028.66 467.86 1027.85 467.056 1026.87 467.056C1025.88 467.056 1025.07 467.86 1025.07 468.851C1025.07 469.842 1025.88 470.646 1026.87 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 470.646C1021.88 470.646 1022.69 469.842 1022.69 468.851C1022.69 467.86 1021.88 467.056 1020.9 467.056C1019.91 467.056 1019.1 467.86 1019.1 468.851C1019.1 469.842 1019.91 470.646 1020.9 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 470.646C1015.91 470.646 1016.72 469.842 1016.72 468.851C1016.72 467.86 1015.91 467.056 1014.93 467.056C1013.94 467.056 1013.13 467.86 1013.13 468.851C1013.13 469.842 1013.94 470.646 1014.93 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 470.646C1009.94 470.646 1010.75 469.842 1010.75 468.851C1010.75 467.86 1009.94 467.056 1008.96 467.056C1007.97 467.056 1007.16 467.86 1007.16 468.851C1007.16 469.842 1007.97 470.646 1008.96 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 470.646C1003.97 470.646 1004.78 469.842 1004.78 468.851C1004.78 467.86 1003.97 467.056 1002.99 467.056C1002 467.056 1001.19 467.86 1001.19 468.851C1001.19 469.842 1002 470.646 1002.99 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 470.646C998.004 470.646 998.806 469.842 998.806 468.851C998.806 467.86 998.004 467.056 997.015 467.056C996.026 467.056 995.224 467.86 995.224 468.851C995.224 469.842 996.026 470.646 997.015 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 470.646C992.034 470.646 992.836 469.842 992.836 468.851C992.836 467.86 992.034 467.056 991.045 467.056C990.056 467.056 989.254 467.86 989.254 468.851C989.254 469.842 990.056 470.646 991.045 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 470.646C986.064 470.646 986.866 469.842 986.866 468.851C986.866 467.86 986.064 467.056 985.075 467.056C984.085 467.056 983.284 467.86 983.284 468.851C983.284 469.842 984.085 470.646 985.075 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 470.646C980.094 470.646 980.896 469.842 980.896 468.851C980.896 467.86 980.094 467.056 979.104 467.056C978.115 467.056 977.313 467.86 977.313 468.851C977.313 469.842 978.115 470.646 979.104 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 470.646C974.123 470.646 974.925 469.842 974.925 468.851C974.925 467.86 974.123 467.056 973.134 467.056C972.145 467.056 971.343 467.86 971.343 468.851C971.343 469.842 972.145 470.646 973.134 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 470.646C651.735 470.646 652.537 469.842 652.537 468.851C652.537 467.86 651.735 467.056 650.746 467.056C649.757 467.056 648.955 467.86 648.955 468.851C648.955 469.842 649.757 470.646 650.746 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 470.646C645.765 470.646 646.567 469.842 646.567 468.851C646.567 467.86 645.765 467.056 644.776 467.056C643.787 467.056 642.985 467.86 642.985 468.851C642.985 469.842 643.787 470.646 644.776 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 470.646C639.795 470.646 640.597 469.842 640.597 468.851C640.597 467.86 639.795 467.056 638.806 467.056C637.817 467.056 637.015 467.86 637.015 468.851C637.015 469.842 637.817 470.646 638.806 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 470.646C633.825 470.646 634.627 469.842 634.627 468.851C634.627 467.86 633.825 467.056 632.836 467.056C631.847 467.056 631.045 467.86 631.045 468.851C631.045 469.842 631.847 470.646 632.836 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 470.646C627.855 470.646 628.657 469.842 628.657 468.851C628.657 467.86 627.855 467.056 626.866 467.056C625.876 467.056 625.075 467.86 625.075 468.851C625.075 469.842 625.876 470.646 626.866 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 470.646C621.885 470.646 622.687 469.842 622.687 468.851C622.687 467.86 621.885 467.056 620.895 467.056C619.906 467.056 619.104 467.86 619.104 468.851C619.104 469.842 619.906 470.646 620.895 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 470.646C615.915 470.646 616.716 469.842 616.716 468.851C616.716 467.86 615.915 467.056 614.925 467.056C613.936 467.056 613.134 467.86 613.134 468.851C613.134 469.842 613.936 470.646 614.925 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 470.646C609.944 470.646 610.746 469.842 610.746 468.851C610.746 467.86 609.944 467.056 608.955 467.056C607.966 467.056 607.164 467.86 607.164 468.851C607.164 469.842 607.966 470.646 608.955 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 470.646C603.974 470.646 604.776 469.842 604.776 468.851C604.776 467.86 603.974 467.056 602.985 467.056C601.996 467.056 601.194 467.86 601.194 468.851C601.194 469.842 601.996 470.646 602.985 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 470.646C598.004 470.646 598.806 469.842 598.806 468.851C598.806 467.86 598.004 467.056 597.015 467.056C596.026 467.056 595.224 467.86 595.224 468.851C595.224 469.842 596.026 470.646 597.015 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M1116.42 464.664C1117.41 464.664 1118.21 463.86 1118.21 462.869C1118.21 461.878 1117.41 461.074 1116.42 461.074C1115.43 461.074 1114.63 461.878 1114.63 462.869C1114.63 463.86 1115.43 464.664 1116.42 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 464.664C1111.44 464.664 1112.24 463.86 1112.24 462.869C1112.24 461.878 1111.44 461.074 1110.45 461.074C1109.46 461.074 1108.66 461.878 1108.66 462.869C1108.66 463.86 1109.46 464.664 1110.45 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 464.664C1105.47 464.664 1106.27 463.86 1106.27 462.869C1106.27 461.878 1105.47 461.074 1104.48 461.074C1103.49 461.074 1102.69 461.878 1102.69 462.869C1102.69 463.86 1103.49 464.664 1104.48 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 464.664C1099.5 464.664 1100.3 463.86 1100.3 462.869C1100.3 461.878 1099.5 461.074 1098.51 461.074C1097.52 461.074 1096.72 461.878 1096.72 462.869C1096.72 463.86 1097.52 464.664 1098.51 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 464.664C1093.53 464.664 1094.33 463.86 1094.33 462.869C1094.33 461.878 1093.53 461.074 1092.54 461.074C1091.55 461.074 1090.75 461.878 1090.75 462.869C1090.75 463.86 1091.55 464.664 1092.54 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 464.664C1087.56 464.664 1088.36 463.86 1088.36 462.869C1088.36 461.878 1087.56 461.074 1086.57 461.074C1085.58 461.074 1084.78 461.878 1084.78 462.869C1084.78 463.86 1085.58 464.664 1086.57 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 464.664C1081.59 464.664 1082.39 463.86 1082.39 462.869C1082.39 461.878 1081.59 461.074 1080.6 461.074C1079.61 461.074 1078.81 461.878 1078.81 462.869C1078.81 463.86 1079.61 464.664 1080.6 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 464.664C1075.62 464.664 1076.42 463.86 1076.42 462.869C1076.42 461.878 1075.62 461.074 1074.63 461.074C1073.64 461.074 1072.84 461.878 1072.84 462.869C1072.84 463.86 1073.64 464.664 1074.63 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 464.664C1069.65 464.664 1070.45 463.86 1070.45 462.869C1070.45 461.878 1069.65 461.074 1068.66 461.074C1067.67 461.074 1066.87 461.878 1066.87 462.869C1066.87 463.86 1067.67 464.664 1068.66 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 464.664C1063.68 464.664 1064.48 463.86 1064.48 462.869C1064.48 461.878 1063.68 461.074 1062.69 461.074C1061.7 461.074 1060.9 461.878 1060.9 462.869C1060.9 463.86 1061.7 464.664 1062.69 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 464.664C1057.71 464.664 1058.51 463.86 1058.51 462.869C1058.51 461.878 1057.71 461.074 1056.72 461.074C1055.73 461.074 1054.93 461.878 1054.93 462.869C1054.93 463.86 1055.73 464.664 1056.72 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 464.664C1051.74 464.664 1052.54 463.86 1052.54 462.869C1052.54 461.878 1051.74 461.074 1050.75 461.074C1049.76 461.074 1048.96 461.878 1048.96 462.869C1048.96 463.86 1049.76 464.664 1050.75 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 464.664C1045.77 464.664 1046.57 463.86 1046.57 462.869C1046.57 461.878 1045.77 461.074 1044.78 461.074C1043.79 461.074 1042.99 461.878 1042.99 462.869C1042.99 463.86 1043.79 464.664 1044.78 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 464.664C1039.8 464.664 1040.6 463.86 1040.6 462.869C1040.6 461.878 1039.8 461.074 1038.81 461.074C1037.82 461.074 1037.01 461.878 1037.01 462.869C1037.01 463.86 1037.82 464.664 1038.81 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 464.664C1033.82 464.664 1034.63 463.86 1034.63 462.869C1034.63 461.878 1033.82 461.074 1032.84 461.074C1031.85 461.074 1031.04 461.878 1031.04 462.869C1031.04 463.86 1031.85 464.664 1032.84 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 464.664C1027.85 464.664 1028.66 463.86 1028.66 462.869C1028.66 461.878 1027.85 461.074 1026.87 461.074C1025.88 461.074 1025.07 461.878 1025.07 462.869C1025.07 463.86 1025.88 464.664 1026.87 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 464.664C1021.88 464.664 1022.69 463.86 1022.69 462.869C1022.69 461.878 1021.88 461.074 1020.9 461.074C1019.91 461.074 1019.1 461.878 1019.1 462.869C1019.1 463.86 1019.91 464.664 1020.9 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 464.664C1015.91 464.664 1016.72 463.86 1016.72 462.869C1016.72 461.878 1015.91 461.074 1014.93 461.074C1013.94 461.074 1013.13 461.878 1013.13 462.869C1013.13 463.86 1013.94 464.664 1014.93 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 464.664C1009.94 464.664 1010.75 463.86 1010.75 462.869C1010.75 461.878 1009.94 461.074 1008.96 461.074C1007.97 461.074 1007.16 461.878 1007.16 462.869C1007.16 463.86 1007.97 464.664 1008.96 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 464.664C1003.97 464.664 1004.78 463.86 1004.78 462.869C1004.78 461.878 1003.97 461.074 1002.99 461.074C1002 461.074 1001.19 461.878 1001.19 462.869C1001.19 463.86 1002 464.664 1002.99 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 464.664C998.004 464.664 998.806 463.86 998.806 462.869C998.806 461.878 998.004 461.074 997.015 461.074C996.026 461.074 995.224 461.878 995.224 462.869C995.224 463.86 996.026 464.664 997.015 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 464.664C992.034 464.664 992.836 463.86 992.836 462.869C992.836 461.878 992.034 461.074 991.045 461.074C990.056 461.074 989.254 461.878 989.254 462.869C989.254 463.86 990.056 464.664 991.045 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 464.664C986.064 464.664 986.866 463.86 986.866 462.869C986.866 461.878 986.064 461.074 985.075 461.074C984.085 461.074 983.284 461.878 983.284 462.869C983.284 463.86 984.085 464.664 985.075 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 464.664C980.094 464.664 980.896 463.86 980.896 462.869C980.896 461.878 980.094 461.074 979.104 461.074C978.115 461.074 977.313 461.878 977.313 462.869C977.313 463.86 978.115 464.664 979.104 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 464.664C974.123 464.664 974.925 463.86 974.925 462.869C974.925 461.878 974.123 461.074 973.134 461.074C972.145 461.074 971.343 461.878 971.343 462.869C971.343 463.86 972.145 464.664 973.134 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 464.664C657.706 464.664 658.507 463.86 658.507 462.869C658.507 461.878 657.706 461.074 656.716 461.074C655.727 461.074 654.925 461.878 654.925 462.869C654.925 463.86 655.727 464.664 656.716 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 464.664C651.735 464.664 652.537 463.86 652.537 462.869C652.537 461.878 651.735 461.074 650.746 461.074C649.757 461.074 648.955 461.878 648.955 462.869C648.955 463.86 649.757 464.664 650.746 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 464.664C645.765 464.664 646.567 463.86 646.567 462.869C646.567 461.878 645.765 461.074 644.776 461.074C643.787 461.074 642.985 461.878 642.985 462.869C642.985 463.86 643.787 464.664 644.776 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 464.664C639.795 464.664 640.597 463.86 640.597 462.869C640.597 461.878 639.795 461.074 638.806 461.074C637.817 461.074 637.015 461.878 637.015 462.869C637.015 463.86 637.817 464.664 638.806 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 464.664C633.825 464.664 634.627 463.86 634.627 462.869C634.627 461.878 633.825 461.074 632.836 461.074C631.847 461.074 631.045 461.878 631.045 462.869C631.045 463.86 631.847 464.664 632.836 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 464.664C627.855 464.664 628.657 463.86 628.657 462.869C628.657 461.878 627.855 461.074 626.866 461.074C625.876 461.074 625.075 461.878 625.075 462.869C625.075 463.86 625.876 464.664 626.866 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 464.664C621.885 464.664 622.687 463.86 622.687 462.869C622.687 461.878 621.885 461.074 620.895 461.074C619.906 461.074 619.104 461.878 619.104 462.869C619.104 463.86 619.906 464.664 620.895 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 464.664C615.915 464.664 616.716 463.86 616.716 462.869C616.716 461.878 615.915 461.074 614.925 461.074C613.936 461.074 613.134 461.878 613.134 462.869C613.134 463.86 613.936 464.664 614.925 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 464.664C609.944 464.664 610.746 463.86 610.746 462.869C610.746 461.878 609.944 461.074 608.955 461.074C607.966 461.074 607.164 461.878 607.164 462.869C607.164 463.86 607.966 464.664 608.955 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 464.664C603.974 464.664 604.776 463.86 604.776 462.869C604.776 461.878 603.974 461.074 602.985 461.074C601.996 461.074 601.194 461.878 601.194 462.869C601.194 463.86 601.996 464.664 602.985 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 464.664C598.004 464.664 598.806 463.86 598.806 462.869C598.806 461.878 598.004 461.074 597.015 461.074C596.026 461.074 595.224 461.878 595.224 462.869C595.224 463.86 596.026 464.664 597.015 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 464.664C592.034 464.664 592.836 463.86 592.836 462.869C592.836 461.878 592.034 461.074 591.045 461.074C590.056 461.074 589.254 461.878 589.254 462.869C589.254 463.86 590.056 464.664 591.045 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M1116.42 458.681C1117.41 458.681 1118.21 457.878 1118.21 456.886C1118.21 455.895 1117.41 455.092 1116.42 455.092C1115.43 455.092 1114.63 455.895 1114.63 456.886C1114.63 457.878 1115.43 458.681 1116.42 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 458.681C1111.44 458.681 1112.24 457.878 1112.24 456.886C1112.24 455.895 1111.44 455.092 1110.45 455.092C1109.46 455.092 1108.66 455.895 1108.66 456.886C1108.66 457.878 1109.46 458.681 1110.45 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 458.681C1105.47 458.681 1106.27 457.878 1106.27 456.886C1106.27 455.895 1105.47 455.092 1104.48 455.092C1103.49 455.092 1102.69 455.895 1102.69 456.886C1102.69 457.878 1103.49 458.681 1104.48 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 458.681C1099.5 458.681 1100.3 457.878 1100.3 456.886C1100.3 455.895 1099.5 455.092 1098.51 455.092C1097.52 455.092 1096.72 455.895 1096.72 456.886C1096.72 457.878 1097.52 458.681 1098.51 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 458.681C1093.53 458.681 1094.33 457.878 1094.33 456.886C1094.33 455.895 1093.53 455.092 1092.54 455.092C1091.55 455.092 1090.75 455.895 1090.75 456.886C1090.75 457.878 1091.55 458.681 1092.54 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 458.681C1087.56 458.681 1088.36 457.878 1088.36 456.886C1088.36 455.895 1087.56 455.092 1086.57 455.092C1085.58 455.092 1084.78 455.895 1084.78 456.886C1084.78 457.878 1085.58 458.681 1086.57 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 458.681C1081.59 458.681 1082.39 457.878 1082.39 456.886C1082.39 455.895 1081.59 455.092 1080.6 455.092C1079.61 455.092 1078.81 455.895 1078.81 456.886C1078.81 457.878 1079.61 458.681 1080.6 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 458.681C1075.62 458.681 1076.42 457.878 1076.42 456.886C1076.42 455.895 1075.62 455.092 1074.63 455.092C1073.64 455.092 1072.84 455.895 1072.84 456.886C1072.84 457.878 1073.64 458.681 1074.63 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 458.681C1069.65 458.681 1070.45 457.878 1070.45 456.886C1070.45 455.895 1069.65 455.092 1068.66 455.092C1067.67 455.092 1066.87 455.895 1066.87 456.886C1066.87 457.878 1067.67 458.681 1068.66 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 458.681C1063.68 458.681 1064.48 457.878 1064.48 456.886C1064.48 455.895 1063.68 455.092 1062.69 455.092C1061.7 455.092 1060.9 455.895 1060.9 456.886C1060.9 457.878 1061.7 458.681 1062.69 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 458.681C1057.71 458.681 1058.51 457.878 1058.51 456.886C1058.51 455.895 1057.71 455.092 1056.72 455.092C1055.73 455.092 1054.93 455.895 1054.93 456.886C1054.93 457.878 1055.73 458.681 1056.72 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 458.681C1051.74 458.681 1052.54 457.878 1052.54 456.886C1052.54 455.895 1051.74 455.092 1050.75 455.092C1049.76 455.092 1048.96 455.895 1048.96 456.886C1048.96 457.878 1049.76 458.681 1050.75 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 458.681C1045.77 458.681 1046.57 457.878 1046.57 456.886C1046.57 455.895 1045.77 455.092 1044.78 455.092C1043.79 455.092 1042.99 455.895 1042.99 456.886C1042.99 457.878 1043.79 458.681 1044.78 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 458.681C1039.8 458.681 1040.6 457.878 1040.6 456.886C1040.6 455.895 1039.8 455.092 1038.81 455.092C1037.82 455.092 1037.01 455.895 1037.01 456.886C1037.01 457.878 1037.82 458.681 1038.81 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 458.681C1033.82 458.681 1034.63 457.878 1034.63 456.886C1034.63 455.895 1033.82 455.092 1032.84 455.092C1031.85 455.092 1031.04 455.895 1031.04 456.886C1031.04 457.878 1031.85 458.681 1032.84 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 458.681C1027.85 458.681 1028.66 457.878 1028.66 456.886C1028.66 455.895 1027.85 455.092 1026.87 455.092C1025.88 455.092 1025.07 455.895 1025.07 456.886C1025.07 457.878 1025.88 458.681 1026.87 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 458.681C1021.88 458.681 1022.69 457.878 1022.69 456.886C1022.69 455.895 1021.88 455.092 1020.9 455.092C1019.91 455.092 1019.1 455.895 1019.1 456.886C1019.1 457.878 1019.91 458.681 1020.9 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 458.681C1015.91 458.681 1016.72 457.878 1016.72 456.886C1016.72 455.895 1015.91 455.092 1014.93 455.092C1013.94 455.092 1013.13 455.895 1013.13 456.886C1013.13 457.878 1013.94 458.681 1014.93 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 458.681C1009.94 458.681 1010.75 457.878 1010.75 456.886C1010.75 455.895 1009.94 455.092 1008.96 455.092C1007.97 455.092 1007.16 455.895 1007.16 456.886C1007.16 457.878 1007.97 458.681 1008.96 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 458.681C1003.97 458.681 1004.78 457.878 1004.78 456.886C1004.78 455.895 1003.97 455.092 1002.99 455.092C1002 455.092 1001.19 455.895 1001.19 456.886C1001.19 457.878 1002 458.681 1002.99 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 458.681C998.004 458.681 998.806 457.878 998.806 456.886C998.806 455.895 998.004 455.092 997.015 455.092C996.026 455.092 995.224 455.895 995.224 456.886C995.224 457.878 996.026 458.681 997.015 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 458.681C992.034 458.681 992.836 457.878 992.836 456.886C992.836 455.895 992.034 455.092 991.045 455.092C990.056 455.092 989.254 455.895 989.254 456.886C989.254 457.878 990.056 458.681 991.045 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 458.681C986.064 458.681 986.866 457.878 986.866 456.886C986.866 455.895 986.064 455.092 985.075 455.092C984.085 455.092 983.284 455.895 983.284 456.886C983.284 457.878 984.085 458.681 985.075 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M979.105 458.681C980.094 458.681 980.896 457.878 980.896 456.886C980.896 455.895 980.094 455.092 979.105 455.092C978.115 455.092 977.313 455.895 977.313 456.886C977.313 457.878 978.115 458.681 979.105 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 458.681C974.123 458.681 974.925 457.878 974.925 456.886C974.925 455.895 974.123 455.092 973.134 455.092C972.145 455.092 971.343 455.895 971.343 456.886C971.343 457.878 972.145 458.681 973.134 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 458.681C657.706 458.681 658.507 457.878 658.507 456.886C658.507 455.895 657.706 455.092 656.716 455.092C655.727 455.092 654.925 455.895 654.925 456.886C654.925 457.878 655.727 458.681 656.716 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 458.681C651.735 458.681 652.537 457.878 652.537 456.886C652.537 455.895 651.735 455.092 650.746 455.092C649.757 455.092 648.955 455.895 648.955 456.886C648.955 457.878 649.757 458.681 650.746 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 458.681C645.765 458.681 646.567 457.878 646.567 456.886C646.567 455.895 645.765 455.092 644.776 455.092C643.787 455.092 642.985 455.895 642.985 456.886C642.985 457.878 643.787 458.681 644.776 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 458.681C633.825 458.681 634.627 457.878 634.627 456.886C634.627 455.895 633.825 455.092 632.836 455.092C631.847 455.092 631.045 455.895 631.045 456.886C631.045 457.878 631.847 458.681 632.836 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 458.681C627.855 458.681 628.657 457.878 628.657 456.886C628.657 455.895 627.855 455.092 626.866 455.092C625.876 455.092 625.075 455.895 625.075 456.886C625.075 457.878 625.876 458.681 626.866 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 458.681C621.885 458.681 622.687 457.878 622.687 456.886C622.687 455.895 621.885 455.092 620.895 455.092C619.906 455.092 619.104 455.895 619.104 456.886C619.104 457.878 619.906 458.681 620.895 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 458.681C615.915 458.681 616.716 457.878 616.716 456.886C616.716 455.895 615.915 455.092 614.925 455.092C613.936 455.092 613.134 455.895 613.134 456.886C613.134 457.878 613.936 458.681 614.925 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 458.681C609.944 458.681 610.746 457.878 610.746 456.886C610.746 455.895 609.944 455.092 608.955 455.092C607.966 455.092 607.164 455.895 607.164 456.886C607.164 457.878 607.966 458.681 608.955 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 458.681C603.974 458.681 604.776 457.878 604.776 456.886C604.776 455.895 603.974 455.092 602.985 455.092C601.996 455.092 601.194 455.895 601.194 456.886C601.194 457.878 601.996 458.681 602.985 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 458.681C598.004 458.681 598.806 457.878 598.806 456.886C598.806 455.895 598.004 455.092 597.015 455.092C596.026 455.092 595.224 455.895 595.224 456.886C595.224 457.878 596.026 458.681 597.015 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 458.681C592.034 458.681 592.836 457.878 592.836 456.886C592.836 455.895 592.034 455.092 591.045 455.092C590.056 455.092 589.254 455.895 589.254 456.886C589.254 457.878 590.056 458.681 591.045 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M1116.42 452.699C1117.41 452.699 1118.21 451.895 1118.21 450.904C1118.21 449.913 1117.41 449.109 1116.42 449.109C1115.43 449.109 1114.63 449.913 1114.63 450.904C1114.63 451.895 1115.43 452.699 1116.42 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 452.699C1111.44 452.699 1112.24 451.895 1112.24 450.904C1112.24 449.913 1111.44 449.109 1110.45 449.109C1109.46 449.109 1108.66 449.913 1108.66 450.904C1108.66 451.895 1109.46 452.699 1110.45 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 452.699C1105.47 452.699 1106.27 451.895 1106.27 450.904C1106.27 449.913 1105.47 449.109 1104.48 449.109C1103.49 449.109 1102.69 449.913 1102.69 450.904C1102.69 451.895 1103.49 452.699 1104.48 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 452.699C1099.5 452.699 1100.3 451.895 1100.3 450.904C1100.3 449.913 1099.5 449.109 1098.51 449.109C1097.52 449.109 1096.72 449.913 1096.72 450.904C1096.72 451.895 1097.52 452.699 1098.51 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 452.699C1093.53 452.699 1094.33 451.895 1094.33 450.904C1094.33 449.913 1093.53 449.109 1092.54 449.109C1091.55 449.109 1090.75 449.913 1090.75 450.904C1090.75 451.895 1091.55 452.699 1092.54 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 452.699C1087.56 452.699 1088.36 451.895 1088.36 450.904C1088.36 449.913 1087.56 449.109 1086.57 449.109C1085.58 449.109 1084.78 449.913 1084.78 450.904C1084.78 451.895 1085.58 452.699 1086.57 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 452.699C1081.59 452.699 1082.39 451.895 1082.39 450.904C1082.39 449.913 1081.59 449.109 1080.6 449.109C1079.61 449.109 1078.81 449.913 1078.81 450.904C1078.81 451.895 1079.61 452.699 1080.6 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 452.699C1075.62 452.699 1076.42 451.895 1076.42 450.904C1076.42 449.913 1075.62 449.109 1074.63 449.109C1073.64 449.109 1072.84 449.913 1072.84 450.904C1072.84 451.895 1073.64 452.699 1074.63 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 452.699C1069.65 452.699 1070.45 451.895 1070.45 450.904C1070.45 449.913 1069.65 449.109 1068.66 449.109C1067.67 449.109 1066.87 449.913 1066.87 450.904C1066.87 451.895 1067.67 452.699 1068.66 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 452.699C1063.68 452.699 1064.48 451.895 1064.48 450.904C1064.48 449.913 1063.68 449.109 1062.69 449.109C1061.7 449.109 1060.9 449.913 1060.9 450.904C1060.9 451.895 1061.7 452.699 1062.69 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 452.699C1057.71 452.699 1058.51 451.895 1058.51 450.904C1058.51 449.913 1057.71 449.109 1056.72 449.109C1055.73 449.109 1054.93 449.913 1054.93 450.904C1054.93 451.895 1055.73 452.699 1056.72 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 452.699C1051.74 452.699 1052.54 451.895 1052.54 450.904C1052.54 449.913 1051.74 449.109 1050.75 449.109C1049.76 449.109 1048.96 449.913 1048.96 450.904C1048.96 451.895 1049.76 452.699 1050.75 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 452.699C1045.77 452.699 1046.57 451.895 1046.57 450.904C1046.57 449.913 1045.77 449.109 1044.78 449.109C1043.79 449.109 1042.99 449.913 1042.99 450.904C1042.99 451.895 1043.79 452.699 1044.78 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 452.699C1039.8 452.699 1040.6 451.895 1040.6 450.904C1040.6 449.913 1039.8 449.109 1038.81 449.109C1037.82 449.109 1037.01 449.913 1037.01 450.904C1037.01 451.895 1037.82 452.699 1038.81 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 452.699C1033.82 452.699 1034.63 451.895 1034.63 450.904C1034.63 449.913 1033.82 449.109 1032.84 449.109C1031.85 449.109 1031.04 449.913 1031.04 450.904C1031.04 451.895 1031.85 452.699 1032.84 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 452.699C1027.85 452.699 1028.66 451.895 1028.66 450.904C1028.66 449.913 1027.85 449.109 1026.87 449.109C1025.88 449.109 1025.07 449.913 1025.07 450.904C1025.07 451.895 1025.88 452.699 1026.87 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 452.699C1021.88 452.699 1022.69 451.895 1022.69 450.904C1022.69 449.913 1021.88 449.109 1020.9 449.109C1019.91 449.109 1019.1 449.913 1019.1 450.904C1019.1 451.895 1019.91 452.699 1020.9 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 452.699C1015.91 452.699 1016.72 451.895 1016.72 450.904C1016.72 449.913 1015.91 449.109 1014.93 449.109C1013.94 449.109 1013.13 449.913 1013.13 450.904C1013.13 451.895 1013.94 452.699 1014.93 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 452.699C1009.94 452.699 1010.75 451.895 1010.75 450.904C1010.75 449.913 1009.94 449.109 1008.96 449.109C1007.97 449.109 1007.16 449.913 1007.16 450.904C1007.16 451.895 1007.97 452.699 1008.96 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 452.699C1003.97 452.699 1004.78 451.895 1004.78 450.904C1004.78 449.913 1003.97 449.109 1002.99 449.109C1002 449.109 1001.19 449.913 1001.19 450.904C1001.19 451.895 1002 452.699 1002.99 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 452.699C998.004 452.699 998.806 451.895 998.806 450.904C998.806 449.913 998.004 449.109 997.015 449.109C996.026 449.109 995.224 449.913 995.224 450.904C995.224 451.895 996.026 452.699 997.015 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 452.699C992.034 452.699 992.836 451.895 992.836 450.904C992.836 449.913 992.034 449.109 991.045 449.109C990.056 449.109 989.254 449.913 989.254 450.904C989.254 451.895 990.056 452.699 991.045 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 452.699C986.064 452.699 986.866 451.895 986.866 450.904C986.866 449.913 986.064 449.109 985.075 449.109C984.085 449.109 983.284 449.913 983.284 450.904C983.284 451.895 984.085 452.699 985.075 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 452.699C980.094 452.699 980.896 451.895 980.896 450.904C980.896 449.913 980.094 449.109 979.104 449.109C978.115 449.109 977.313 449.913 977.313 450.904C977.313 451.895 978.115 452.699 979.104 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 452.699C974.123 452.699 974.925 451.895 974.925 450.904C974.925 449.913 974.123 449.109 973.134 449.109C972.145 449.109 971.343 449.913 971.343 450.904C971.343 451.895 972.145 452.699 973.134 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 452.699C711.437 452.699 712.239 451.895 712.239 450.904C712.239 449.913 711.437 449.109 710.448 449.109C709.459 449.109 708.657 449.913 708.657 450.904C708.657 451.895 709.459 452.699 710.448 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 452.699C705.467 452.699 706.269 451.895 706.269 450.904C706.269 449.913 705.467 449.109 704.478 449.109C703.488 449.109 702.687 449.913 702.687 450.904C702.687 451.895 703.488 452.699 704.478 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 452.699C663.676 452.699 664.478 451.895 664.478 450.904C664.478 449.913 663.676 449.109 662.687 449.109C661.697 449.109 660.896 449.913 660.896 450.904C660.896 451.895 661.697 452.699 662.687 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 452.699C657.706 452.699 658.507 451.895 658.507 450.904C658.507 449.913 657.706 449.109 656.716 449.109C655.727 449.109 654.925 449.913 654.925 450.904C654.925 451.895 655.727 452.699 656.716 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 452.699C651.735 452.699 652.537 451.895 652.537 450.904C652.537 449.913 651.735 449.109 650.746 449.109C649.757 449.109 648.955 449.913 648.955 450.904C648.955 451.895 649.757 452.699 650.746 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 452.699C645.765 452.699 646.567 451.895 646.567 450.904C646.567 449.913 645.765 449.109 644.776 449.109C643.787 449.109 642.985 449.913 642.985 450.904C642.985 451.895 643.787 452.699 644.776 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 452.699C639.795 452.699 640.597 451.895 640.597 450.904C640.597 449.913 639.795 449.109 638.806 449.109C637.817 449.109 637.015 449.913 637.015 450.904C637.015 451.895 637.817 452.699 638.806 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 452.699C633.825 452.699 634.627 451.895 634.627 450.904C634.627 449.913 633.825 449.109 632.836 449.109C631.847 449.109 631.045 449.913 631.045 450.904C631.045 451.895 631.847 452.699 632.836 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 452.699C627.855 452.699 628.657 451.895 628.657 450.904C628.657 449.913 627.855 449.109 626.866 449.109C625.876 449.109 625.075 449.913 625.075 450.904C625.075 451.895 625.876 452.699 626.866 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 452.699C621.885 452.699 622.687 451.895 622.687 450.904C622.687 449.913 621.885 449.109 620.895 449.109C619.906 449.109 619.104 449.913 619.104 450.904C619.104 451.895 619.906 452.699 620.895 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 452.699C615.915 452.699 616.716 451.895 616.716 450.904C616.716 449.913 615.915 449.109 614.925 449.109C613.936 449.109 613.134 449.913 613.134 450.904C613.134 451.895 613.936 452.699 614.925 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 452.699C609.944 452.699 610.746 451.895 610.746 450.904C610.746 449.913 609.944 449.109 608.955 449.109C607.966 449.109 607.164 449.913 607.164 450.904C607.164 451.895 607.966 452.699 608.955 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 452.699C603.974 452.699 604.776 451.895 604.776 450.904C604.776 449.913 603.974 449.109 602.985 449.109C601.996 449.109 601.194 449.913 601.194 450.904C601.194 451.895 601.996 452.699 602.985 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 452.699C598.004 452.699 598.806 451.895 598.806 450.904C598.806 449.913 598.004 449.109 597.015 449.109C596.026 449.109 595.224 449.913 595.224 450.904C595.224 451.895 596.026 452.699 597.015 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 452.699C592.034 452.699 592.836 451.895 592.836 450.904C592.836 449.913 592.034 449.109 591.045 449.109C590.056 449.109 589.254 449.913 589.254 450.904C589.254 451.895 590.056 452.699 591.045 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 446.717C1111.44 446.717 1112.24 445.913 1112.24 444.922C1112.24 443.931 1111.44 443.127 1110.45 443.127C1109.46 443.127 1108.66 443.931 1108.66 444.922C1108.66 445.913 1109.46 446.717 1110.45 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 446.717C1105.47 446.717 1106.27 445.913 1106.27 444.922C1106.27 443.931 1105.47 443.127 1104.48 443.127C1103.49 443.127 1102.69 443.931 1102.69 444.922C1102.69 445.913 1103.49 446.717 1104.48 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 446.717C1099.5 446.717 1100.3 445.913 1100.3 444.922C1100.3 443.931 1099.5 443.127 1098.51 443.127C1097.52 443.127 1096.72 443.931 1096.72 444.922C1096.72 445.913 1097.52 446.717 1098.51 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 446.717C1093.53 446.717 1094.33 445.913 1094.33 444.922C1094.33 443.931 1093.53 443.127 1092.54 443.127C1091.55 443.127 1090.75 443.931 1090.75 444.922C1090.75 445.913 1091.55 446.717 1092.54 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 446.717C1087.56 446.717 1088.36 445.913 1088.36 444.922C1088.36 443.931 1087.56 443.127 1086.57 443.127C1085.58 443.127 1084.78 443.931 1084.78 444.922C1084.78 445.913 1085.58 446.717 1086.57 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 446.717C1081.59 446.717 1082.39 445.913 1082.39 444.922C1082.39 443.931 1081.59 443.127 1080.6 443.127C1079.61 443.127 1078.81 443.931 1078.81 444.922C1078.81 445.913 1079.61 446.717 1080.6 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 446.717C1075.62 446.717 1076.42 445.913 1076.42 444.922C1076.42 443.931 1075.62 443.127 1074.63 443.127C1073.64 443.127 1072.84 443.931 1072.84 444.922C1072.84 445.913 1073.64 446.717 1074.63 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 446.717C1069.65 446.717 1070.45 445.913 1070.45 444.922C1070.45 443.931 1069.65 443.127 1068.66 443.127C1067.67 443.127 1066.87 443.931 1066.87 444.922C1066.87 445.913 1067.67 446.717 1068.66 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 446.717C1063.68 446.717 1064.48 445.913 1064.48 444.922C1064.48 443.931 1063.68 443.127 1062.69 443.127C1061.7 443.127 1060.9 443.931 1060.9 444.922C1060.9 445.913 1061.7 446.717 1062.69 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 446.717C1057.71 446.717 1058.51 445.913 1058.51 444.922C1058.51 443.931 1057.71 443.127 1056.72 443.127C1055.73 443.127 1054.93 443.931 1054.93 444.922C1054.93 445.913 1055.73 446.717 1056.72 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 446.717C1051.74 446.717 1052.54 445.913 1052.54 444.922C1052.54 443.931 1051.74 443.127 1050.75 443.127C1049.76 443.127 1048.96 443.931 1048.96 444.922C1048.96 445.913 1049.76 446.717 1050.75 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 446.717C1045.77 446.717 1046.57 445.913 1046.57 444.922C1046.57 443.931 1045.77 443.127 1044.78 443.127C1043.79 443.127 1042.99 443.931 1042.99 444.922C1042.99 445.913 1043.79 446.717 1044.78 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 446.717C1039.8 446.717 1040.6 445.913 1040.6 444.922C1040.6 443.931 1039.8 443.127 1038.81 443.127C1037.82 443.127 1037.01 443.931 1037.01 444.922C1037.01 445.913 1037.82 446.717 1038.81 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 446.717C1033.82 446.717 1034.63 445.913 1034.63 444.922C1034.63 443.931 1033.82 443.127 1032.84 443.127C1031.85 443.127 1031.04 443.931 1031.04 444.922C1031.04 445.913 1031.85 446.717 1032.84 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 446.717C1027.85 446.717 1028.66 445.913 1028.66 444.922C1028.66 443.931 1027.85 443.127 1026.87 443.127C1025.88 443.127 1025.07 443.931 1025.07 444.922C1025.07 445.913 1025.88 446.717 1026.87 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 446.717C1021.88 446.717 1022.69 445.913 1022.69 444.922C1022.69 443.931 1021.88 443.127 1020.9 443.127C1019.91 443.127 1019.1 443.931 1019.1 444.922C1019.1 445.913 1019.91 446.717 1020.9 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 446.717C1015.91 446.717 1016.72 445.913 1016.72 444.922C1016.72 443.931 1015.91 443.127 1014.93 443.127C1013.94 443.127 1013.13 443.931 1013.13 444.922C1013.13 445.913 1013.94 446.717 1014.93 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 446.717C1009.94 446.717 1010.75 445.913 1010.75 444.922C1010.75 443.931 1009.94 443.127 1008.96 443.127C1007.97 443.127 1007.16 443.931 1007.16 444.922C1007.16 445.913 1007.97 446.717 1008.96 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 446.717C1003.97 446.717 1004.78 445.913 1004.78 444.922C1004.78 443.931 1003.97 443.127 1002.99 443.127C1002 443.127 1001.19 443.931 1001.19 444.922C1001.19 445.913 1002 446.717 1002.99 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 446.717C998.004 446.717 998.806 445.913 998.806 444.922C998.806 443.931 998.004 443.127 997.015 443.127C996.026 443.127 995.224 443.931 995.224 444.922C995.224 445.913 996.026 446.717 997.015 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 446.717C992.034 446.717 992.836 445.913 992.836 444.922C992.836 443.931 992.034 443.127 991.045 443.127C990.056 443.127 989.254 443.931 989.254 444.922C989.254 445.913 990.056 446.717 991.045 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 446.717C986.064 446.717 986.866 445.913 986.866 444.922C986.866 443.931 986.064 443.127 985.075 443.127C984.085 443.127 983.284 443.931 983.284 444.922C983.284 445.913 984.085 446.717 985.075 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 446.717C980.094 446.717 980.896 445.913 980.896 444.922C980.896 443.931 980.094 443.127 979.104 443.127C978.115 443.127 977.313 443.931 977.313 444.922C977.313 445.913 978.115 446.717 979.104 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 446.717C974.123 446.717 974.925 445.913 974.925 444.922C974.925 443.931 974.123 443.127 973.134 443.127C972.145 443.127 971.343 443.931 971.343 444.922C971.343 445.913 972.145 446.717 973.134 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 446.717C717.407 446.717 718.209 445.913 718.209 444.922C718.209 443.931 717.407 443.127 716.418 443.127C715.429 443.127 714.627 443.931 714.627 444.922C714.627 445.913 715.429 446.717 716.418 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 446.717C711.437 446.717 712.239 445.913 712.239 444.922C712.239 443.931 711.437 443.127 710.448 443.127C709.459 443.127 708.657 443.931 708.657 444.922C708.657 445.913 709.459 446.717 710.448 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 446.717C705.467 446.717 706.269 445.913 706.269 444.922C706.269 443.931 705.467 443.127 704.478 443.127C703.488 443.127 702.687 443.931 702.687 444.922C702.687 445.913 703.488 446.717 704.478 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 446.717C699.497 446.717 700.299 445.913 700.299 444.922C700.299 443.931 699.497 443.127 698.507 443.127C697.518 443.127 696.716 443.931 696.716 444.922C696.716 445.913 697.518 446.717 698.507 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 446.717C669.646 446.717 670.448 445.913 670.448 444.922C670.448 443.931 669.646 443.127 668.657 443.127C667.668 443.127 666.866 443.931 666.866 444.922C666.866 445.913 667.668 446.717 668.657 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 446.717C663.676 446.717 664.478 445.913 664.478 444.922C664.478 443.931 663.676 443.127 662.687 443.127C661.697 443.127 660.896 443.931 660.896 444.922C660.896 445.913 661.697 446.717 662.687 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 446.717C657.706 446.717 658.507 445.913 658.507 444.922C658.507 443.931 657.706 443.127 656.716 443.127C655.727 443.127 654.925 443.931 654.925 444.922C654.925 445.913 655.727 446.717 656.716 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 446.717C651.735 446.717 652.537 445.913 652.537 444.922C652.537 443.931 651.735 443.127 650.746 443.127C649.757 443.127 648.955 443.931 648.955 444.922C648.955 445.913 649.757 446.717 650.746 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 446.717C645.765 446.717 646.567 445.913 646.567 444.922C646.567 443.931 645.765 443.127 644.776 443.127C643.787 443.127 642.985 443.931 642.985 444.922C642.985 445.913 643.787 446.717 644.776 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 446.717C639.795 446.717 640.597 445.913 640.597 444.922C640.597 443.931 639.795 443.127 638.806 443.127C637.817 443.127 637.015 443.931 637.015 444.922C637.015 445.913 637.817 446.717 638.806 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 446.717C633.825 446.717 634.627 445.913 634.627 444.922C634.627 443.931 633.825 443.127 632.836 443.127C631.847 443.127 631.045 443.931 631.045 444.922C631.045 445.913 631.847 446.717 632.836 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 446.717C627.855 446.717 628.657 445.913 628.657 444.922C628.657 443.931 627.855 443.127 626.866 443.127C625.876 443.127 625.075 443.931 625.075 444.922C625.075 445.913 625.876 446.717 626.866 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 446.717C621.885 446.717 622.687 445.913 622.687 444.922C622.687 443.931 621.885 443.127 620.895 443.127C619.906 443.127 619.104 443.931 619.104 444.922C619.104 445.913 619.906 446.717 620.895 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 446.717C615.915 446.717 616.716 445.913 616.716 444.922C616.716 443.931 615.915 443.127 614.925 443.127C613.936 443.127 613.134 443.931 613.134 444.922C613.134 445.913 613.936 446.717 614.925 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 446.717C609.944 446.717 610.746 445.913 610.746 444.922C610.746 443.931 609.944 443.127 608.955 443.127C607.966 443.127 607.164 443.931 607.164 444.922C607.164 445.913 607.966 446.717 608.955 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 446.717C603.974 446.717 604.776 445.913 604.776 444.922C604.776 443.931 603.974 443.127 602.985 443.127C601.996 443.127 601.194 443.931 601.194 444.922C601.194 445.913 601.996 446.717 602.985 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 446.717C598.004 446.717 598.806 445.913 598.806 444.922C598.806 443.931 598.004 443.127 597.015 443.127C596.026 443.127 595.224 443.931 595.224 444.922C595.224 445.913 596.026 446.717 597.015 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 446.717C592.034 446.717 592.836 445.913 592.836 444.922C592.836 443.931 592.034 443.127 591.045 443.127C590.056 443.127 589.254 443.931 589.254 444.922C589.254 445.913 590.056 446.717 591.045 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M1176.12 440.734C1177.11 440.734 1177.91 439.931 1177.91 438.94C1177.91 437.949 1177.11 437.145 1176.12 437.145C1175.13 437.145 1174.33 437.949 1174.33 438.94C1174.33 439.931 1175.13 440.734 1176.12 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 440.734C1111.44 440.734 1112.24 439.931 1112.24 438.94C1112.24 437.949 1111.44 437.145 1110.45 437.145C1109.46 437.145 1108.66 437.949 1108.66 438.94C1108.66 439.931 1109.46 440.734 1110.45 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 440.734C1105.47 440.734 1106.27 439.931 1106.27 438.94C1106.27 437.949 1105.47 437.145 1104.48 437.145C1103.49 437.145 1102.69 437.949 1102.69 438.94C1102.69 439.931 1103.49 440.734 1104.48 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 440.734C1099.5 440.734 1100.3 439.931 1100.3 438.94C1100.3 437.949 1099.5 437.145 1098.51 437.145C1097.52 437.145 1096.72 437.949 1096.72 438.94C1096.72 439.931 1097.52 440.734 1098.51 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 440.734C1093.53 440.734 1094.33 439.931 1094.33 438.94C1094.33 437.949 1093.53 437.145 1092.54 437.145C1091.55 437.145 1090.75 437.949 1090.75 438.94C1090.75 439.931 1091.55 440.734 1092.54 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 440.734C1087.56 440.734 1088.36 439.931 1088.36 438.94C1088.36 437.949 1087.56 437.145 1086.57 437.145C1085.58 437.145 1084.78 437.949 1084.78 438.94C1084.78 439.931 1085.58 440.734 1086.57 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 440.734C1081.59 440.734 1082.39 439.931 1082.39 438.94C1082.39 437.949 1081.59 437.145 1080.6 437.145C1079.61 437.145 1078.81 437.949 1078.81 438.94C1078.81 439.931 1079.61 440.734 1080.6 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 440.734C1075.62 440.734 1076.42 439.931 1076.42 438.94C1076.42 437.949 1075.62 437.145 1074.63 437.145C1073.64 437.145 1072.84 437.949 1072.84 438.94C1072.84 439.931 1073.64 440.734 1074.63 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 440.734C1069.65 440.734 1070.45 439.931 1070.45 438.94C1070.45 437.949 1069.65 437.145 1068.66 437.145C1067.67 437.145 1066.87 437.949 1066.87 438.94C1066.87 439.931 1067.67 440.734 1068.66 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 440.734C1063.68 440.734 1064.48 439.931 1064.48 438.94C1064.48 437.949 1063.68 437.145 1062.69 437.145C1061.7 437.145 1060.9 437.949 1060.9 438.94C1060.9 439.931 1061.7 440.734 1062.69 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 440.734C1057.71 440.734 1058.51 439.931 1058.51 438.94C1058.51 437.949 1057.71 437.145 1056.72 437.145C1055.73 437.145 1054.93 437.949 1054.93 438.94C1054.93 439.931 1055.73 440.734 1056.72 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 440.734C1051.74 440.734 1052.54 439.931 1052.54 438.94C1052.54 437.949 1051.74 437.145 1050.75 437.145C1049.76 437.145 1048.96 437.949 1048.96 438.94C1048.96 439.931 1049.76 440.734 1050.75 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 440.734C1045.77 440.734 1046.57 439.931 1046.57 438.94C1046.57 437.949 1045.77 437.145 1044.78 437.145C1043.79 437.145 1042.99 437.949 1042.99 438.94C1042.99 439.931 1043.79 440.734 1044.78 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 440.734C1039.8 440.734 1040.6 439.931 1040.6 438.94C1040.6 437.949 1039.8 437.145 1038.81 437.145C1037.82 437.145 1037.01 437.949 1037.01 438.94C1037.01 439.931 1037.82 440.734 1038.81 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 440.734C1033.82 440.734 1034.63 439.931 1034.63 438.94C1034.63 437.949 1033.82 437.145 1032.84 437.145C1031.85 437.145 1031.04 437.949 1031.04 438.94C1031.04 439.931 1031.85 440.734 1032.84 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 440.734C1027.85 440.734 1028.66 439.931 1028.66 438.94C1028.66 437.949 1027.85 437.145 1026.87 437.145C1025.88 437.145 1025.07 437.949 1025.07 438.94C1025.07 439.931 1025.88 440.734 1026.87 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 440.734C1021.88 440.734 1022.69 439.931 1022.69 438.94C1022.69 437.949 1021.88 437.145 1020.9 437.145C1019.91 437.145 1019.1 437.949 1019.1 438.94C1019.1 439.931 1019.91 440.734 1020.9 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 440.734C1015.91 440.734 1016.72 439.931 1016.72 438.94C1016.72 437.949 1015.91 437.145 1014.93 437.145C1013.94 437.145 1013.13 437.949 1013.13 438.94C1013.13 439.931 1013.94 440.734 1014.93 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 440.734C1009.94 440.734 1010.75 439.931 1010.75 438.94C1010.75 437.949 1009.94 437.145 1008.96 437.145C1007.97 437.145 1007.16 437.949 1007.16 438.94C1007.16 439.931 1007.97 440.734 1008.96 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 440.734C1003.97 440.734 1004.78 439.931 1004.78 438.94C1004.78 437.949 1003.97 437.145 1002.99 437.145C1002 437.145 1001.19 437.949 1001.19 438.94C1001.19 439.931 1002 440.734 1002.99 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 440.734C998.004 440.734 998.806 439.931 998.806 438.94C998.806 437.949 998.004 437.145 997.015 437.145C996.026 437.145 995.224 437.949 995.224 438.94C995.224 439.931 996.026 440.734 997.015 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 440.734C992.034 440.734 992.836 439.931 992.836 438.94C992.836 437.949 992.034 437.145 991.045 437.145C990.056 437.145 989.254 437.949 989.254 438.94C989.254 439.931 990.056 440.734 991.045 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 440.734C986.064 440.734 986.866 439.931 986.866 438.94C986.866 437.949 986.064 437.145 985.075 437.145C984.085 437.145 983.284 437.949 983.284 438.94C983.284 439.931 984.085 440.734 985.075 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 440.734C980.094 440.734 980.896 439.931 980.896 438.94C980.896 437.949 980.094 437.145 979.104 437.145C978.115 437.145 977.313 437.949 977.313 438.94C977.313 439.931 978.115 440.734 979.104 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 440.734C717.407 440.734 718.209 439.931 718.209 438.94C718.209 437.949 717.407 437.145 716.418 437.145C715.429 437.145 714.627 437.949 714.627 438.94C714.627 439.931 715.429 440.734 716.418 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 440.734C711.437 440.734 712.239 439.931 712.239 438.94C712.239 437.949 711.437 437.145 710.448 437.145C709.459 437.145 708.657 437.949 708.657 438.94C708.657 439.931 709.459 440.734 710.448 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 440.734C705.467 440.734 706.269 439.931 706.269 438.94C706.269 437.949 705.467 437.145 704.478 437.145C703.488 437.145 702.687 437.949 702.687 438.94C702.687 439.931 703.488 440.734 704.478 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 440.734C699.497 440.734 700.299 439.931 700.299 438.94C700.299 437.949 699.497 437.145 698.507 437.145C697.518 437.145 696.716 437.949 696.716 438.94C696.716 439.931 697.518 440.734 698.507 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 440.734C669.646 440.734 670.448 439.931 670.448 438.94C670.448 437.949 669.646 437.145 668.657 437.145C667.668 437.145 666.866 437.949 666.866 438.94C666.866 439.931 667.668 440.734 668.657 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 440.734C663.676 440.734 664.478 439.931 664.478 438.94C664.478 437.949 663.676 437.145 662.687 437.145C661.697 437.145 660.896 437.949 660.896 438.94C660.896 439.931 661.697 440.734 662.687 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 440.734C657.706 440.734 658.507 439.931 658.507 438.94C658.507 437.949 657.706 437.145 656.716 437.145C655.727 437.145 654.925 437.949 654.925 438.94C654.925 439.931 655.727 440.734 656.716 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 440.734C651.735 440.734 652.537 439.931 652.537 438.94C652.537 437.949 651.735 437.145 650.746 437.145C649.757 437.145 648.955 437.949 648.955 438.94C648.955 439.931 649.757 440.734 650.746 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 440.734C645.765 440.734 646.567 439.931 646.567 438.94C646.567 437.949 645.765 437.145 644.776 437.145C643.787 437.145 642.985 437.949 642.985 438.94C642.985 439.931 643.787 440.734 644.776 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 440.734C639.795 440.734 640.597 439.931 640.597 438.94C640.597 437.949 639.795 437.145 638.806 437.145C637.817 437.145 637.015 437.949 637.015 438.94C637.015 439.931 637.817 440.734 638.806 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 440.734C633.825 440.734 634.627 439.931 634.627 438.94C634.627 437.949 633.825 437.145 632.836 437.145C631.847 437.145 631.045 437.949 631.045 438.94C631.045 439.931 631.847 440.734 632.836 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 440.734C627.855 440.734 628.657 439.931 628.657 438.94C628.657 437.949 627.855 437.145 626.866 437.145C625.876 437.145 625.075 437.949 625.075 438.94C625.075 439.931 625.876 440.734 626.866 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 440.734C621.885 440.734 622.687 439.931 622.687 438.94C622.687 437.949 621.885 437.145 620.895 437.145C619.906 437.145 619.104 437.949 619.104 438.94C619.104 439.931 619.906 440.734 620.895 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 440.734C615.915 440.734 616.716 439.931 616.716 438.94C616.716 437.949 615.915 437.145 614.925 437.145C613.936 437.145 613.134 437.949 613.134 438.94C613.134 439.931 613.936 440.734 614.925 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 440.734C609.944 440.734 610.746 439.931 610.746 438.94C610.746 437.949 609.944 437.145 608.955 437.145C607.966 437.145 607.164 437.949 607.164 438.94C607.164 439.931 607.966 440.734 608.955 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 440.734C603.974 440.734 604.776 439.931 604.776 438.94C604.776 437.949 603.974 437.145 602.985 437.145C601.996 437.145 601.194 437.949 601.194 438.94C601.194 439.931 601.996 440.734 602.985 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 440.734C598.004 440.734 598.806 439.931 598.806 438.94C598.806 437.949 598.004 437.145 597.015 437.145C596.026 437.145 595.224 437.949 595.224 438.94C595.224 439.931 596.026 440.734 597.015 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 440.734C592.034 440.734 592.836 439.931 592.836 438.94C592.836 437.949 592.034 437.145 591.045 437.145C590.056 437.145 589.254 437.949 589.254 438.94C589.254 439.931 590.056 440.734 591.045 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M1170.15 434.752C1171.14 434.752 1171.94 433.948 1171.94 432.957C1171.94 431.966 1171.14 431.163 1170.15 431.163C1169.16 431.163 1168.36 431.966 1168.36 432.957C1168.36 433.948 1169.16 434.752 1170.15 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 434.752C1105.47 434.752 1106.27 433.948 1106.27 432.957C1106.27 431.966 1105.47 431.163 1104.48 431.163C1103.49 431.163 1102.69 431.966 1102.69 432.957C1102.69 433.948 1103.49 434.752 1104.48 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 434.752C1099.5 434.752 1100.3 433.948 1100.3 432.957C1100.3 431.966 1099.5 431.163 1098.51 431.163C1097.52 431.163 1096.72 431.966 1096.72 432.957C1096.72 433.948 1097.52 434.752 1098.51 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 434.752C1093.53 434.752 1094.33 433.948 1094.33 432.957C1094.33 431.966 1093.53 431.163 1092.54 431.163C1091.55 431.163 1090.75 431.966 1090.75 432.957C1090.75 433.948 1091.55 434.752 1092.54 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 434.752C1087.56 434.752 1088.36 433.948 1088.36 432.957C1088.36 431.966 1087.56 431.163 1086.57 431.163C1085.58 431.163 1084.78 431.966 1084.78 432.957C1084.78 433.948 1085.58 434.752 1086.57 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 434.752C1081.59 434.752 1082.39 433.948 1082.39 432.957C1082.39 431.966 1081.59 431.163 1080.6 431.163C1079.61 431.163 1078.81 431.966 1078.81 432.957C1078.81 433.948 1079.61 434.752 1080.6 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 434.752C1075.62 434.752 1076.42 433.948 1076.42 432.957C1076.42 431.966 1075.62 431.163 1074.63 431.163C1073.64 431.163 1072.84 431.966 1072.84 432.957C1072.84 433.948 1073.64 434.752 1074.63 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 434.752C1069.65 434.752 1070.45 433.948 1070.45 432.957C1070.45 431.966 1069.65 431.163 1068.66 431.163C1067.67 431.163 1066.87 431.966 1066.87 432.957C1066.87 433.948 1067.67 434.752 1068.66 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 434.752C1063.68 434.752 1064.48 433.948 1064.48 432.957C1064.48 431.966 1063.68 431.163 1062.69 431.163C1061.7 431.163 1060.9 431.966 1060.9 432.957C1060.9 433.948 1061.7 434.752 1062.69 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 434.752C1057.71 434.752 1058.51 433.948 1058.51 432.957C1058.51 431.966 1057.71 431.163 1056.72 431.163C1055.73 431.163 1054.93 431.966 1054.93 432.957C1054.93 433.948 1055.73 434.752 1056.72 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 434.752C1051.74 434.752 1052.54 433.948 1052.54 432.957C1052.54 431.966 1051.74 431.163 1050.75 431.163C1049.76 431.163 1048.96 431.966 1048.96 432.957C1048.96 433.948 1049.76 434.752 1050.75 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 434.752C1045.77 434.752 1046.57 433.948 1046.57 432.957C1046.57 431.966 1045.77 431.163 1044.78 431.163C1043.79 431.163 1042.99 431.966 1042.99 432.957C1042.99 433.948 1043.79 434.752 1044.78 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 434.752C1039.8 434.752 1040.6 433.948 1040.6 432.957C1040.6 431.966 1039.8 431.163 1038.81 431.163C1037.82 431.163 1037.01 431.966 1037.01 432.957C1037.01 433.948 1037.82 434.752 1038.81 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 434.752C1033.82 434.752 1034.63 433.948 1034.63 432.957C1034.63 431.966 1033.82 431.163 1032.84 431.163C1031.85 431.163 1031.04 431.966 1031.04 432.957C1031.04 433.948 1031.85 434.752 1032.84 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 434.752C1027.85 434.752 1028.66 433.948 1028.66 432.957C1028.66 431.966 1027.85 431.163 1026.87 431.163C1025.88 431.163 1025.07 431.966 1025.07 432.957C1025.07 433.948 1025.88 434.752 1026.87 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 434.752C1021.88 434.752 1022.69 433.948 1022.69 432.957C1022.69 431.966 1021.88 431.163 1020.9 431.163C1019.91 431.163 1019.1 431.966 1019.1 432.957C1019.1 433.948 1019.91 434.752 1020.9 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 434.752C1015.91 434.752 1016.72 433.948 1016.72 432.957C1016.72 431.966 1015.91 431.163 1014.93 431.163C1013.94 431.163 1013.13 431.966 1013.13 432.957C1013.13 433.948 1013.94 434.752 1014.93 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 434.752C1009.94 434.752 1010.75 433.948 1010.75 432.957C1010.75 431.966 1009.94 431.163 1008.96 431.163C1007.97 431.163 1007.16 431.966 1007.16 432.957C1007.16 433.948 1007.97 434.752 1008.96 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 434.752C1003.97 434.752 1004.78 433.948 1004.78 432.957C1004.78 431.966 1003.97 431.163 1002.99 431.163C1002 431.163 1001.19 431.966 1001.19 432.957C1001.19 433.948 1002 434.752 1002.99 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 434.752C998.004 434.752 998.806 433.948 998.806 432.957C998.806 431.966 998.004 431.163 997.015 431.163C996.026 431.163 995.224 431.966 995.224 432.957C995.224 433.948 996.026 434.752 997.015 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 434.752C992.034 434.752 992.836 433.948 992.836 432.957C992.836 431.966 992.034 431.163 991.045 431.163C990.056 431.163 989.254 431.966 989.254 432.957C989.254 433.948 990.056 434.752 991.045 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 434.752C986.064 434.752 986.866 433.948 986.866 432.957C986.866 431.966 986.064 431.163 985.075 431.163C984.085 431.163 983.284 431.966 983.284 432.957C983.284 433.948 984.085 434.752 985.075 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 434.752C717.407 434.752 718.209 433.948 718.209 432.957C718.209 431.966 717.407 431.163 716.418 431.163C715.429 431.163 714.627 431.966 714.627 432.957C714.627 433.948 715.429 434.752 716.418 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 434.752C711.437 434.752 712.239 433.948 712.239 432.957C712.239 431.966 711.437 431.163 710.448 431.163C709.459 431.163 708.657 431.966 708.657 432.957C708.657 433.948 709.459 434.752 710.448 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 434.752C705.467 434.752 706.269 433.948 706.269 432.957C706.269 431.966 705.467 431.163 704.478 431.163C703.488 431.163 702.687 431.966 702.687 432.957C702.687 433.948 703.488 434.752 704.478 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 434.752C663.676 434.752 664.478 433.948 664.478 432.957C664.478 431.966 663.676 431.163 662.687 431.163C661.697 431.163 660.896 431.966 660.896 432.957C660.896 433.948 661.697 434.752 662.687 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 434.752C657.706 434.752 658.507 433.948 658.507 432.957C658.507 431.966 657.706 431.163 656.716 431.163C655.727 431.163 654.925 431.966 654.925 432.957C654.925 433.948 655.727 434.752 656.716 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 434.752C651.735 434.752 652.537 433.948 652.537 432.957C652.537 431.966 651.735 431.163 650.746 431.163C649.757 431.163 648.955 431.966 648.955 432.957C648.955 433.948 649.757 434.752 650.746 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 434.752C645.765 434.752 646.567 433.948 646.567 432.957C646.567 431.966 645.765 431.163 644.776 431.163C643.787 431.163 642.985 431.966 642.985 432.957C642.985 433.948 643.787 434.752 644.776 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 434.752C639.795 434.752 640.597 433.948 640.597 432.957C640.597 431.966 639.795 431.163 638.806 431.163C637.817 431.163 637.015 431.966 637.015 432.957C637.015 433.948 637.817 434.752 638.806 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 434.752C633.825 434.752 634.627 433.948 634.627 432.957C634.627 431.966 633.825 431.163 632.836 431.163C631.847 431.163 631.045 431.966 631.045 432.957C631.045 433.948 631.847 434.752 632.836 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 434.752C627.855 434.752 628.657 433.948 628.657 432.957C628.657 431.966 627.855 431.163 626.866 431.163C625.876 431.163 625.075 431.966 625.075 432.957C625.075 433.948 625.876 434.752 626.866 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 434.752C621.885 434.752 622.687 433.948 622.687 432.957C622.687 431.966 621.885 431.163 620.895 431.163C619.906 431.163 619.104 431.966 619.104 432.957C619.104 433.948 619.906 434.752 620.895 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 434.752C615.915 434.752 616.716 433.948 616.716 432.957C616.716 431.966 615.915 431.163 614.925 431.163C613.936 431.163 613.134 431.966 613.134 432.957C613.134 433.948 613.936 434.752 614.925 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 434.752C609.944 434.752 610.746 433.948 610.746 432.957C610.746 431.966 609.944 431.163 608.955 431.163C607.966 431.163 607.164 431.966 607.164 432.957C607.164 433.948 607.966 434.752 608.955 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 434.752C603.974 434.752 604.776 433.948 604.776 432.957C604.776 431.966 603.974 431.163 602.985 431.163C601.996 431.163 601.194 431.966 601.194 432.957C601.194 433.948 601.996 434.752 602.985 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 434.752C598.004 434.752 598.806 433.948 598.806 432.957C598.806 431.966 598.004 431.163 597.015 431.163C596.026 431.163 595.224 431.966 595.224 432.957C595.224 433.948 596.026 434.752 597.015 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 434.752C592.034 434.752 592.836 433.948 592.836 432.957C592.836 431.966 592.034 431.163 591.045 431.163C590.056 431.163 589.254 431.966 589.254 432.957C589.254 433.948 590.056 434.752 591.045 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 434.752C586.064 434.752 586.866 433.948 586.866 432.957C586.866 431.966 586.064 431.163 585.075 431.163C584.085 431.163 583.284 431.966 583.284 432.957C583.284 433.948 584.085 434.752 585.075 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M1170.15 428.77C1171.14 428.77 1171.94 427.967 1171.94 426.975C1171.94 425.984 1171.14 425.181 1170.15 425.181C1169.16 425.181 1168.36 425.984 1168.36 426.975C1168.36 427.967 1169.16 428.77 1170.15 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 428.77C1105.47 428.77 1106.27 427.967 1106.27 426.975C1106.27 425.984 1105.47 425.181 1104.48 425.181C1103.49 425.181 1102.69 425.984 1102.69 426.975C1102.69 427.967 1103.49 428.77 1104.48 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 428.77C1099.5 428.77 1100.3 427.967 1100.3 426.975C1100.3 425.984 1099.5 425.181 1098.51 425.181C1097.52 425.181 1096.72 425.984 1096.72 426.975C1096.72 427.967 1097.52 428.77 1098.51 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 428.77C1093.53 428.77 1094.33 427.967 1094.33 426.975C1094.33 425.984 1093.53 425.181 1092.54 425.181C1091.55 425.181 1090.75 425.984 1090.75 426.975C1090.75 427.967 1091.55 428.77 1092.54 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 428.77C1087.56 428.77 1088.36 427.967 1088.36 426.975C1088.36 425.984 1087.56 425.181 1086.57 425.181C1085.58 425.181 1084.78 425.984 1084.78 426.975C1084.78 427.967 1085.58 428.77 1086.57 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 428.77C1081.59 428.77 1082.39 427.967 1082.39 426.975C1082.39 425.984 1081.59 425.181 1080.6 425.181C1079.61 425.181 1078.81 425.984 1078.81 426.975C1078.81 427.967 1079.61 428.77 1080.6 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 428.77C1075.62 428.77 1076.42 427.967 1076.42 426.975C1076.42 425.984 1075.62 425.181 1074.63 425.181C1073.64 425.181 1072.84 425.984 1072.84 426.975C1072.84 427.967 1073.64 428.77 1074.63 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 428.77C1069.65 428.77 1070.45 427.967 1070.45 426.975C1070.45 425.984 1069.65 425.181 1068.66 425.181C1067.67 425.181 1066.87 425.984 1066.87 426.975C1066.87 427.967 1067.67 428.77 1068.66 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 428.77C1063.68 428.77 1064.48 427.967 1064.48 426.975C1064.48 425.984 1063.68 425.181 1062.69 425.181C1061.7 425.181 1060.9 425.984 1060.9 426.975C1060.9 427.967 1061.7 428.77 1062.69 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 428.77C1057.71 428.77 1058.51 427.967 1058.51 426.975C1058.51 425.984 1057.71 425.181 1056.72 425.181C1055.73 425.181 1054.93 425.984 1054.93 426.975C1054.93 427.967 1055.73 428.77 1056.72 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 428.77C1051.74 428.77 1052.54 427.967 1052.54 426.975C1052.54 425.984 1051.74 425.181 1050.75 425.181C1049.76 425.181 1048.96 425.984 1048.96 426.975C1048.96 427.967 1049.76 428.77 1050.75 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 428.77C1045.77 428.77 1046.57 427.967 1046.57 426.975C1046.57 425.984 1045.77 425.181 1044.78 425.181C1043.79 425.181 1042.99 425.984 1042.99 426.975C1042.99 427.967 1043.79 428.77 1044.78 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 428.77C1039.8 428.77 1040.6 427.967 1040.6 426.975C1040.6 425.984 1039.8 425.181 1038.81 425.181C1037.82 425.181 1037.01 425.984 1037.01 426.975C1037.01 427.967 1037.82 428.77 1038.81 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 428.77C1033.82 428.77 1034.63 427.967 1034.63 426.975C1034.63 425.984 1033.82 425.181 1032.84 425.181C1031.85 425.181 1031.04 425.984 1031.04 426.975C1031.04 427.967 1031.85 428.77 1032.84 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 428.77C1027.85 428.77 1028.66 427.967 1028.66 426.975C1028.66 425.984 1027.85 425.181 1026.87 425.181C1025.88 425.181 1025.07 425.984 1025.07 426.975C1025.07 427.967 1025.88 428.77 1026.87 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 428.77C1021.88 428.77 1022.69 427.967 1022.69 426.975C1022.69 425.984 1021.88 425.181 1020.9 425.181C1019.91 425.181 1019.1 425.984 1019.1 426.975C1019.1 427.967 1019.91 428.77 1020.9 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 428.77C1015.91 428.77 1016.72 427.967 1016.72 426.975C1016.72 425.984 1015.91 425.181 1014.93 425.181C1013.94 425.181 1013.13 425.984 1013.13 426.975C1013.13 427.967 1013.94 428.77 1014.93 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 428.77C1009.94 428.77 1010.75 427.967 1010.75 426.975C1010.75 425.984 1009.94 425.181 1008.96 425.181C1007.97 425.181 1007.16 425.984 1007.16 426.975C1007.16 427.967 1007.97 428.77 1008.96 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 428.77C1003.97 428.77 1004.78 427.967 1004.78 426.975C1004.78 425.984 1003.97 425.181 1002.99 425.181C1002 425.181 1001.19 425.984 1001.19 426.975C1001.19 427.967 1002 428.77 1002.99 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 428.77C717.407 428.77 718.209 427.967 718.209 426.975C718.209 425.984 717.407 425.181 716.418 425.181C715.429 425.181 714.627 425.984 714.627 426.975C714.627 427.967 715.429 428.77 716.418 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 428.77C711.437 428.77 712.239 427.967 712.239 426.975C712.239 425.984 711.437 425.181 710.448 425.181C709.459 425.181 708.657 425.984 708.657 426.975C708.657 427.967 709.459 428.77 710.448 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 428.77C705.467 428.77 706.269 427.967 706.269 426.975C706.269 425.984 705.467 425.181 704.478 425.181C703.488 425.181 702.687 425.984 702.687 426.975C702.687 427.967 703.488 428.77 704.478 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 428.77C669.646 428.77 670.448 427.967 670.448 426.975C670.448 425.984 669.646 425.181 668.657 425.181C667.668 425.181 666.866 425.984 666.866 426.975C666.866 427.967 667.668 428.77 668.657 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 428.77C663.676 428.77 664.478 427.967 664.478 426.975C664.478 425.984 663.676 425.181 662.687 425.181C661.697 425.181 660.896 425.984 660.896 426.975C660.896 427.967 661.697 428.77 662.687 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 428.77C657.706 428.77 658.507 427.967 658.507 426.975C658.507 425.984 657.706 425.181 656.716 425.181C655.727 425.181 654.925 425.984 654.925 426.975C654.925 427.967 655.727 428.77 656.716 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 428.77C651.735 428.77 652.537 427.967 652.537 426.975C652.537 425.984 651.735 425.181 650.746 425.181C649.757 425.181 648.955 425.984 648.955 426.975C648.955 427.967 649.757 428.77 650.746 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 428.77C645.765 428.77 646.567 427.967 646.567 426.975C646.567 425.984 645.765 425.181 644.776 425.181C643.787 425.181 642.985 425.984 642.985 426.975C642.985 427.967 643.787 428.77 644.776 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 428.77C639.795 428.77 640.597 427.967 640.597 426.975C640.597 425.984 639.795 425.181 638.806 425.181C637.817 425.181 637.015 425.984 637.015 426.975C637.015 427.967 637.817 428.77 638.806 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 428.77C633.825 428.77 634.627 427.967 634.627 426.975C634.627 425.984 633.825 425.181 632.836 425.181C631.847 425.181 631.045 425.984 631.045 426.975C631.045 427.967 631.847 428.77 632.836 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 428.77C627.855 428.77 628.657 427.967 628.657 426.975C628.657 425.984 627.855 425.181 626.866 425.181C625.876 425.181 625.075 425.984 625.075 426.975C625.075 427.967 625.876 428.77 626.866 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 428.77C621.885 428.77 622.687 427.967 622.687 426.975C622.687 425.984 621.885 425.181 620.895 425.181C619.906 425.181 619.104 425.984 619.104 426.975C619.104 427.967 619.906 428.77 620.895 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 428.77C615.915 428.77 616.716 427.967 616.716 426.975C616.716 425.984 615.915 425.181 614.925 425.181C613.936 425.181 613.134 425.984 613.134 426.975C613.134 427.967 613.936 428.77 614.925 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 428.77C609.944 428.77 610.746 427.967 610.746 426.975C610.746 425.984 609.944 425.181 608.955 425.181C607.966 425.181 607.164 425.984 607.164 426.975C607.164 427.967 607.966 428.77 608.955 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 428.77C603.974 428.77 604.776 427.967 604.776 426.975C604.776 425.984 603.974 425.181 602.985 425.181C601.996 425.181 601.194 425.984 601.194 426.975C601.194 427.967 601.996 428.77 602.985 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 428.77C598.004 428.77 598.806 427.967 598.806 426.975C598.806 425.984 598.004 425.181 597.015 425.181C596.026 425.181 595.224 425.984 595.224 426.975C595.224 427.967 596.026 428.77 597.015 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 428.77C592.034 428.77 592.836 427.967 592.836 426.975C592.836 425.984 592.034 425.181 591.045 425.181C590.056 425.181 589.254 425.984 589.254 426.975C589.254 427.967 590.056 428.77 591.045 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 428.77C586.064 428.77 586.866 427.967 586.866 426.975C586.866 425.984 586.064 425.181 585.075 425.181C584.085 425.181 583.284 425.984 583.284 426.975C583.284 427.967 584.085 428.77 585.075 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 422.788C1099.5 422.788 1100.3 421.984 1100.3 420.993C1100.3 420.002 1099.5 419.198 1098.51 419.198C1097.52 419.198 1096.72 420.002 1096.72 420.993C1096.72 421.984 1097.52 422.788 1098.51 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 422.788C1093.53 422.788 1094.33 421.984 1094.33 420.993C1094.33 420.002 1093.53 419.198 1092.54 419.198C1091.55 419.198 1090.75 420.002 1090.75 420.993C1090.75 421.984 1091.55 422.788 1092.54 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 422.788C1087.56 422.788 1088.36 421.984 1088.36 420.993C1088.36 420.002 1087.56 419.198 1086.57 419.198C1085.58 419.198 1084.78 420.002 1084.78 420.993C1084.78 421.984 1085.58 422.788 1086.57 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 422.788C1081.59 422.788 1082.39 421.984 1082.39 420.993C1082.39 420.002 1081.59 419.198 1080.6 419.198C1079.61 419.198 1078.81 420.002 1078.81 420.993C1078.81 421.984 1079.61 422.788 1080.6 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 422.788C1075.62 422.788 1076.42 421.984 1076.42 420.993C1076.42 420.002 1075.62 419.198 1074.63 419.198C1073.64 419.198 1072.84 420.002 1072.84 420.993C1072.84 421.984 1073.64 422.788 1074.63 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 422.788C1069.65 422.788 1070.45 421.984 1070.45 420.993C1070.45 420.002 1069.65 419.198 1068.66 419.198C1067.67 419.198 1066.87 420.002 1066.87 420.993C1066.87 421.984 1067.67 422.788 1068.66 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 422.788C1063.68 422.788 1064.48 421.984 1064.48 420.993C1064.48 420.002 1063.68 419.198 1062.69 419.198C1061.7 419.198 1060.9 420.002 1060.9 420.993C1060.9 421.984 1061.7 422.788 1062.69 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 422.788C1057.71 422.788 1058.51 421.984 1058.51 420.993C1058.51 420.002 1057.71 419.198 1056.72 419.198C1055.73 419.198 1054.93 420.002 1054.93 420.993C1054.93 421.984 1055.73 422.788 1056.72 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 422.788C1051.74 422.788 1052.54 421.984 1052.54 420.993C1052.54 420.002 1051.74 419.198 1050.75 419.198C1049.76 419.198 1048.96 420.002 1048.96 420.993C1048.96 421.984 1049.76 422.788 1050.75 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 422.788C1045.77 422.788 1046.57 421.984 1046.57 420.993C1046.57 420.002 1045.77 419.198 1044.78 419.198C1043.79 419.198 1042.99 420.002 1042.99 420.993C1042.99 421.984 1043.79 422.788 1044.78 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 422.788C1039.8 422.788 1040.6 421.984 1040.6 420.993C1040.6 420.002 1039.8 419.198 1038.81 419.198C1037.82 419.198 1037.01 420.002 1037.01 420.993C1037.01 421.984 1037.82 422.788 1038.81 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 422.788C1033.82 422.788 1034.63 421.984 1034.63 420.993C1034.63 420.002 1033.82 419.198 1032.84 419.198C1031.85 419.198 1031.04 420.002 1031.04 420.993C1031.04 421.984 1031.85 422.788 1032.84 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 422.788C1027.85 422.788 1028.66 421.984 1028.66 420.993C1028.66 420.002 1027.85 419.198 1026.87 419.198C1025.88 419.198 1025.07 420.002 1025.07 420.993C1025.07 421.984 1025.88 422.788 1026.87 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 422.788C1021.88 422.788 1022.69 421.984 1022.69 420.993C1022.69 420.002 1021.88 419.198 1020.9 419.198C1019.91 419.198 1019.1 420.002 1019.1 420.993C1019.1 421.984 1019.91 422.788 1020.9 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 422.788C1015.91 422.788 1016.72 421.984 1016.72 420.993C1016.72 420.002 1015.91 419.198 1014.93 419.198C1013.94 419.198 1013.13 420.002 1013.13 420.993C1013.13 421.984 1013.94 422.788 1014.93 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 422.788C1009.94 422.788 1010.75 421.984 1010.75 420.993C1010.75 420.002 1009.94 419.198 1008.96 419.198C1007.97 419.198 1007.16 420.002 1007.16 420.993C1007.16 421.984 1007.97 422.788 1008.96 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 422.788C723.377 422.788 724.179 421.984 724.179 420.993C724.179 420.002 723.377 419.198 722.388 419.198C721.399 419.198 720.597 420.002 720.597 420.993C720.597 421.984 721.399 422.788 722.388 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 422.788C717.407 422.788 718.209 421.984 718.209 420.993C718.209 420.002 717.407 419.198 716.418 419.198C715.429 419.198 714.627 420.002 714.627 420.993C714.627 421.984 715.429 422.788 716.418 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 422.788C711.437 422.788 712.239 421.984 712.239 420.993C712.239 420.002 711.437 419.198 710.448 419.198C709.459 419.198 708.657 420.002 708.657 420.993C708.657 421.984 709.459 422.788 710.448 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 422.788C705.467 422.788 706.269 421.984 706.269 420.993C706.269 420.002 705.467 419.198 704.478 419.198C703.488 419.198 702.687 420.002 702.687 420.993C702.687 421.984 703.488 422.788 704.478 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 422.788C669.646 422.788 670.448 421.984 670.448 420.993C670.448 420.002 669.646 419.198 668.657 419.198C667.668 419.198 666.866 420.002 666.866 420.993C666.866 421.984 667.668 422.788 668.657 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 422.788C663.676 422.788 664.478 421.984 664.478 420.993C664.478 420.002 663.676 419.198 662.687 419.198C661.697 419.198 660.896 420.002 660.896 420.993C660.896 421.984 661.697 422.788 662.687 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 422.788C657.706 422.788 658.507 421.984 658.507 420.993C658.507 420.002 657.706 419.198 656.716 419.198C655.727 419.198 654.925 420.002 654.925 420.993C654.925 421.984 655.727 422.788 656.716 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 422.788C651.735 422.788 652.537 421.984 652.537 420.993C652.537 420.002 651.735 419.198 650.746 419.198C649.757 419.198 648.955 420.002 648.955 420.993C648.955 421.984 649.757 422.788 650.746 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 422.788C645.765 422.788 646.567 421.984 646.567 420.993C646.567 420.002 645.765 419.198 644.776 419.198C643.787 419.198 642.985 420.002 642.985 420.993C642.985 421.984 643.787 422.788 644.776 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 422.788C639.795 422.788 640.597 421.984 640.597 420.993C640.597 420.002 639.795 419.198 638.806 419.198C637.817 419.198 637.015 420.002 637.015 420.993C637.015 421.984 637.817 422.788 638.806 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 422.788C633.825 422.788 634.627 421.984 634.627 420.993C634.627 420.002 633.825 419.198 632.836 419.198C631.847 419.198 631.045 420.002 631.045 420.993C631.045 421.984 631.847 422.788 632.836 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 422.788C627.855 422.788 628.657 421.984 628.657 420.993C628.657 420.002 627.855 419.198 626.866 419.198C625.876 419.198 625.075 420.002 625.075 420.993C625.075 421.984 625.876 422.788 626.866 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 422.788C621.885 422.788 622.687 421.984 622.687 420.993C622.687 420.002 621.885 419.198 620.895 419.198C619.906 419.198 619.104 420.002 619.104 420.993C619.104 421.984 619.906 422.788 620.895 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 422.788C615.915 422.788 616.716 421.984 616.716 420.993C616.716 420.002 615.915 419.198 614.925 419.198C613.936 419.198 613.134 420.002 613.134 420.993C613.134 421.984 613.936 422.788 614.925 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 422.788C609.944 422.788 610.746 421.984 610.746 420.993C610.746 420.002 609.944 419.198 608.955 419.198C607.966 419.198 607.164 420.002 607.164 420.993C607.164 421.984 607.966 422.788 608.955 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 422.788C603.974 422.788 604.776 421.984 604.776 420.993C604.776 420.002 603.974 419.198 602.985 419.198C601.996 419.198 601.194 420.002 601.194 420.993C601.194 421.984 601.996 422.788 602.985 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 422.788C598.004 422.788 598.806 421.984 598.806 420.993C598.806 420.002 598.004 419.198 597.015 419.198C596.026 419.198 595.224 420.002 595.224 420.993C595.224 421.984 596.026 422.788 597.015 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 422.788C592.034 422.788 592.836 421.984 592.836 420.993C592.836 420.002 592.034 419.198 591.045 419.198C590.056 419.198 589.254 420.002 589.254 420.993C589.254 421.984 590.056 422.788 591.045 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 422.788C586.064 422.788 586.866 421.984 586.866 420.993C586.866 420.002 586.064 419.198 585.075 419.198C584.085 419.198 583.284 420.002 583.284 420.993C583.284 421.984 584.085 422.788 585.075 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 422.788C580.094 422.788 580.896 421.984 580.896 420.993C580.896 420.002 580.094 419.198 579.104 419.198C578.115 419.198 577.313 420.002 577.313 420.993C577.313 421.984 578.115 422.788 579.104 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M1188.06 416.805C1189.05 416.805 1189.85 416.002 1189.85 415.011C1189.85 414.019 1189.05 413.216 1188.06 413.216C1187.07 413.216 1186.27 414.019 1186.27 415.011C1186.27 416.002 1187.07 416.805 1188.06 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 416.805C1099.5 416.805 1100.3 416.002 1100.3 415.011C1100.3 414.019 1099.5 413.216 1098.51 413.216C1097.52 413.216 1096.72 414.019 1096.72 415.011C1096.72 416.002 1097.52 416.805 1098.51 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 416.805C1093.53 416.805 1094.33 416.002 1094.33 415.011C1094.33 414.019 1093.53 413.216 1092.54 413.216C1091.55 413.216 1090.75 414.019 1090.75 415.011C1090.75 416.002 1091.55 416.805 1092.54 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 416.805C1087.56 416.805 1088.36 416.002 1088.36 415.011C1088.36 414.019 1087.56 413.216 1086.57 413.216C1085.58 413.216 1084.78 414.019 1084.78 415.011C1084.78 416.002 1085.58 416.805 1086.57 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 416.805C1069.65 416.805 1070.45 416.002 1070.45 415.011C1070.45 414.019 1069.65 413.216 1068.66 413.216C1067.67 413.216 1066.87 414.019 1066.87 415.011C1066.87 416.002 1067.67 416.805 1068.66 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 416.805C1063.68 416.805 1064.48 416.002 1064.48 415.011C1064.48 414.019 1063.68 413.216 1062.69 413.216C1061.7 413.216 1060.9 414.019 1060.9 415.011C1060.9 416.002 1061.7 416.805 1062.69 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 416.805C1057.71 416.805 1058.51 416.002 1058.51 415.011C1058.51 414.019 1057.71 413.216 1056.72 413.216C1055.73 413.216 1054.93 414.019 1054.93 415.011C1054.93 416.002 1055.73 416.805 1056.72 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 416.805C1051.74 416.805 1052.54 416.002 1052.54 415.011C1052.54 414.019 1051.74 413.216 1050.75 413.216C1049.76 413.216 1048.96 414.019 1048.96 415.011C1048.96 416.002 1049.76 416.805 1050.75 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 416.805C1045.77 416.805 1046.57 416.002 1046.57 415.011C1046.57 414.019 1045.77 413.216 1044.78 413.216C1043.79 413.216 1042.99 414.019 1042.99 415.011C1042.99 416.002 1043.79 416.805 1044.78 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 416.805C1039.8 416.805 1040.6 416.002 1040.6 415.011C1040.6 414.019 1039.8 413.216 1038.81 413.216C1037.82 413.216 1037.01 414.019 1037.01 415.011C1037.01 416.002 1037.82 416.805 1038.81 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 416.805C1033.82 416.805 1034.63 416.002 1034.63 415.011C1034.63 414.019 1033.82 413.216 1032.84 413.216C1031.85 413.216 1031.04 414.019 1031.04 415.011C1031.04 416.002 1031.85 416.805 1032.84 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 416.805C1027.85 416.805 1028.66 416.002 1028.66 415.011C1028.66 414.019 1027.85 413.216 1026.87 413.216C1025.88 413.216 1025.07 414.019 1025.07 415.011C1025.07 416.002 1025.88 416.805 1026.87 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 416.805C1021.88 416.805 1022.69 416.002 1022.69 415.011C1022.69 414.019 1021.88 413.216 1020.9 413.216C1019.91 413.216 1019.1 414.019 1019.1 415.011C1019.1 416.002 1019.91 416.805 1020.9 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 416.805C1015.91 416.805 1016.72 416.002 1016.72 415.011C1016.72 414.019 1015.91 413.216 1014.93 413.216C1013.94 413.216 1013.13 414.019 1013.13 415.011C1013.13 416.002 1013.94 416.805 1014.93 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 416.805C723.377 416.805 724.179 416.002 724.179 415.011C724.179 414.019 723.377 413.216 722.388 413.216C721.399 413.216 720.597 414.019 720.597 415.011C720.597 416.002 721.399 416.805 722.388 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 416.805C717.407 416.805 718.209 416.002 718.209 415.011C718.209 414.019 717.407 413.216 716.418 413.216C715.429 413.216 714.627 414.019 714.627 415.011C714.627 416.002 715.429 416.805 716.418 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 416.805C711.437 416.805 712.239 416.002 712.239 415.011C712.239 414.019 711.437 413.216 710.448 413.216C709.459 413.216 708.657 414.019 708.657 415.011C708.657 416.002 709.459 416.805 710.448 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 416.805C705.467 416.805 706.269 416.002 706.269 415.011C706.269 414.019 705.467 413.216 704.478 413.216C703.488 413.216 702.687 414.019 702.687 415.011C702.687 416.002 703.488 416.805 704.478 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 416.805C681.586 416.805 682.388 416.002 682.388 415.011C682.388 414.019 681.586 413.216 680.597 413.216C679.608 413.216 678.806 414.019 678.806 415.011C678.806 416.002 679.608 416.805 680.597 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 416.805C675.616 416.805 676.418 416.002 676.418 415.011C676.418 414.019 675.616 413.216 674.627 413.216C673.638 413.216 672.836 414.019 672.836 415.011C672.836 416.002 673.638 416.805 674.627 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 416.805C669.646 416.805 670.448 416.002 670.448 415.011C670.448 414.019 669.646 413.216 668.657 413.216C667.668 413.216 666.866 414.019 666.866 415.011C666.866 416.002 667.668 416.805 668.657 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 416.805C663.676 416.805 664.478 416.002 664.478 415.011C664.478 414.019 663.676 413.216 662.687 413.216C661.697 413.216 660.896 414.019 660.896 415.011C660.896 416.002 661.697 416.805 662.687 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 416.805C657.706 416.805 658.507 416.002 658.507 415.011C658.507 414.019 657.706 413.216 656.716 413.216C655.727 413.216 654.925 414.019 654.925 415.011C654.925 416.002 655.727 416.805 656.716 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 416.805C651.735 416.805 652.537 416.002 652.537 415.011C652.537 414.019 651.735 413.216 650.746 413.216C649.757 413.216 648.955 414.019 648.955 415.011C648.955 416.002 649.757 416.805 650.746 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 416.805C645.765 416.805 646.567 416.002 646.567 415.011C646.567 414.019 645.765 413.216 644.776 413.216C643.787 413.216 642.985 414.019 642.985 415.011C642.985 416.002 643.787 416.805 644.776 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 416.805C639.795 416.805 640.597 416.002 640.597 415.011C640.597 414.019 639.795 413.216 638.806 413.216C637.817 413.216 637.015 414.019 637.015 415.011C637.015 416.002 637.817 416.805 638.806 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 416.805C633.825 416.805 634.627 416.002 634.627 415.011C634.627 414.019 633.825 413.216 632.836 413.216C631.847 413.216 631.045 414.019 631.045 415.011C631.045 416.002 631.847 416.805 632.836 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 416.805C627.855 416.805 628.657 416.002 628.657 415.011C628.657 414.019 627.855 413.216 626.866 413.216C625.876 413.216 625.075 414.019 625.075 415.011C625.075 416.002 625.876 416.805 626.866 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 416.805C621.885 416.805 622.687 416.002 622.687 415.011C622.687 414.019 621.885 413.216 620.895 413.216C619.906 413.216 619.104 414.019 619.104 415.011C619.104 416.002 619.906 416.805 620.895 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 416.805C615.915 416.805 616.716 416.002 616.716 415.011C616.716 414.019 615.915 413.216 614.925 413.216C613.936 413.216 613.134 414.019 613.134 415.011C613.134 416.002 613.936 416.805 614.925 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 416.805C609.944 416.805 610.746 416.002 610.746 415.011C610.746 414.019 609.944 413.216 608.955 413.216C607.966 413.216 607.164 414.019 607.164 415.011C607.164 416.002 607.966 416.805 608.955 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 416.805C603.974 416.805 604.776 416.002 604.776 415.011C604.776 414.019 603.974 413.216 602.985 413.216C601.996 413.216 601.194 414.019 601.194 415.011C601.194 416.002 601.996 416.805 602.985 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 416.805C598.004 416.805 598.806 416.002 598.806 415.011C598.806 414.019 598.004 413.216 597.015 413.216C596.026 413.216 595.224 414.019 595.224 415.011C595.224 416.002 596.026 416.805 597.015 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 416.805C592.034 416.805 592.836 416.002 592.836 415.011C592.836 414.019 592.034 413.216 591.045 413.216C590.056 413.216 589.254 414.019 589.254 415.011C589.254 416.002 590.056 416.805 591.045 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 416.805C586.064 416.805 586.866 416.002 586.866 415.011C586.866 414.019 586.064 413.216 585.075 413.216C584.085 413.216 583.284 414.019 583.284 415.011C583.284 416.002 584.085 416.805 585.075 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 416.805C580.094 416.805 580.896 416.002 580.896 415.011C580.896 414.019 580.094 413.216 579.104 413.216C578.115 413.216 577.313 414.019 577.313 415.011C577.313 416.002 578.115 416.805 579.104 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 410.823C1099.5 410.823 1100.3 410.019 1100.3 409.028C1100.3 408.037 1099.5 407.233 1098.51 407.233C1097.52 407.233 1096.72 408.037 1096.72 409.028C1096.72 410.019 1097.52 410.823 1098.51 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 410.823C1093.53 410.823 1094.33 410.019 1094.33 409.028C1094.33 408.037 1093.53 407.233 1092.54 407.233C1091.55 407.233 1090.75 408.037 1090.75 409.028C1090.75 410.019 1091.55 410.823 1092.54 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 410.823C1087.56 410.823 1088.36 410.019 1088.36 409.028C1088.36 408.037 1087.56 407.233 1086.57 407.233C1085.58 407.233 1084.78 408.037 1084.78 409.028C1084.78 410.019 1085.58 410.823 1086.57 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 410.823C1063.68 410.823 1064.48 410.019 1064.48 409.028C1064.48 408.037 1063.68 407.233 1062.69 407.233C1061.7 407.233 1060.9 408.037 1060.9 409.028C1060.9 410.019 1061.7 410.823 1062.69 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 410.823C1057.71 410.823 1058.51 410.019 1058.51 409.028C1058.51 408.037 1057.71 407.233 1056.72 407.233C1055.73 407.233 1054.93 408.037 1054.93 409.028C1054.93 410.019 1055.73 410.823 1056.72 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 410.823C1051.74 410.823 1052.54 410.019 1052.54 409.028C1052.54 408.037 1051.74 407.233 1050.75 407.233C1049.76 407.233 1048.96 408.037 1048.96 409.028C1048.96 410.019 1049.76 410.823 1050.75 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 410.823C1045.77 410.823 1046.57 410.019 1046.57 409.028C1046.57 408.037 1045.77 407.233 1044.78 407.233C1043.79 407.233 1042.99 408.037 1042.99 409.028C1042.99 410.019 1043.79 410.823 1044.78 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 410.823C1039.8 410.823 1040.6 410.019 1040.6 409.028C1040.6 408.037 1039.8 407.233 1038.81 407.233C1037.82 407.233 1037.01 408.037 1037.01 409.028C1037.01 410.019 1037.82 410.823 1038.81 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 410.823C1033.82 410.823 1034.63 410.019 1034.63 409.028C1034.63 408.037 1033.82 407.233 1032.84 407.233C1031.85 407.233 1031.04 408.037 1031.04 409.028C1031.04 410.019 1031.85 410.823 1032.84 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 410.823C1027.85 410.823 1028.66 410.019 1028.66 409.028C1028.66 408.037 1027.85 407.233 1026.87 407.233C1025.88 407.233 1025.07 408.037 1025.07 409.028C1025.07 410.019 1025.88 410.823 1026.87 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 410.823C1021.88 410.823 1022.69 410.019 1022.69 409.028C1022.69 408.037 1021.88 407.233 1020.9 407.233C1019.91 407.233 1019.1 408.037 1019.1 409.028C1019.1 410.019 1019.91 410.823 1020.9 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 410.823C729.347 410.823 730.149 410.019 730.149 409.028C730.149 408.037 729.347 407.233 728.358 407.233C727.369 407.233 726.567 408.037 726.567 409.028C726.567 410.019 727.369 410.823 728.358 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 410.823C723.377 410.823 724.179 410.019 724.179 409.028C724.179 408.037 723.377 407.233 722.388 407.233C721.399 407.233 720.597 408.037 720.597 409.028C720.597 410.019 721.399 410.823 722.388 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 410.823C717.407 410.823 718.209 410.019 718.209 409.028C718.209 408.037 717.407 407.233 716.418 407.233C715.429 407.233 714.627 408.037 714.627 409.028C714.627 410.019 715.429 410.823 716.418 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 410.823C687.556 410.823 688.358 410.019 688.358 409.028C688.358 408.037 687.556 407.233 686.567 407.233C685.578 407.233 684.776 408.037 684.776 409.028C684.776 410.019 685.578 410.823 686.567 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 410.823C681.586 410.823 682.388 410.019 682.388 409.028C682.388 408.037 681.586 407.233 680.597 407.233C679.608 407.233 678.806 408.037 678.806 409.028C678.806 410.019 679.608 410.823 680.597 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 410.823C675.616 410.823 676.418 410.019 676.418 409.028C676.418 408.037 675.616 407.233 674.627 407.233C673.638 407.233 672.836 408.037 672.836 409.028C672.836 410.019 673.638 410.823 674.627 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 410.823C669.646 410.823 670.448 410.019 670.448 409.028C670.448 408.037 669.646 407.233 668.657 407.233C667.668 407.233 666.866 408.037 666.866 409.028C666.866 410.019 667.668 410.823 668.657 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 410.823C663.676 410.823 664.478 410.019 664.478 409.028C664.478 408.037 663.676 407.233 662.687 407.233C661.697 407.233 660.896 408.037 660.896 409.028C660.896 410.019 661.697 410.823 662.687 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 410.823C657.706 410.823 658.507 410.019 658.507 409.028C658.507 408.037 657.706 407.233 656.716 407.233C655.727 407.233 654.925 408.037 654.925 409.028C654.925 410.019 655.727 410.823 656.716 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 410.823C651.735 410.823 652.537 410.019 652.537 409.028C652.537 408.037 651.735 407.233 650.746 407.233C649.757 407.233 648.955 408.037 648.955 409.028C648.955 410.019 649.757 410.823 650.746 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 410.823C645.765 410.823 646.567 410.019 646.567 409.028C646.567 408.037 645.765 407.233 644.776 407.233C643.787 407.233 642.985 408.037 642.985 409.028C642.985 410.019 643.787 410.823 644.776 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 410.823C639.795 410.823 640.597 410.019 640.597 409.028C640.597 408.037 639.795 407.233 638.806 407.233C637.817 407.233 637.015 408.037 637.015 409.028C637.015 410.019 637.817 410.823 638.806 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 410.823C633.825 410.823 634.627 410.019 634.627 409.028C634.627 408.037 633.825 407.233 632.836 407.233C631.847 407.233 631.045 408.037 631.045 409.028C631.045 410.019 631.847 410.823 632.836 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 410.823C627.855 410.823 628.657 410.019 628.657 409.028C628.657 408.037 627.855 407.233 626.866 407.233C625.876 407.233 625.075 408.037 625.075 409.028C625.075 410.019 625.876 410.823 626.866 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 410.823C621.885 410.823 622.687 410.019 622.687 409.028C622.687 408.037 621.885 407.233 620.895 407.233C619.906 407.233 619.104 408.037 619.104 409.028C619.104 410.019 619.906 410.823 620.895 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 410.823C615.915 410.823 616.716 410.019 616.716 409.028C616.716 408.037 615.915 407.233 614.925 407.233C613.936 407.233 613.134 408.037 613.134 409.028C613.134 410.019 613.936 410.823 614.925 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 410.823C609.944 410.823 610.746 410.019 610.746 409.028C610.746 408.037 609.944 407.233 608.955 407.233C607.966 407.233 607.164 408.037 607.164 409.028C607.164 410.019 607.966 410.823 608.955 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 410.823C603.974 410.823 604.776 410.019 604.776 409.028C604.776 408.037 603.974 407.233 602.985 407.233C601.996 407.233 601.194 408.037 601.194 409.028C601.194 410.019 601.996 410.823 602.985 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 410.823C598.004 410.823 598.806 410.019 598.806 409.028C598.806 408.037 598.004 407.233 597.015 407.233C596.026 407.233 595.224 408.037 595.224 409.028C595.224 410.019 596.026 410.823 597.015 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 410.823C592.034 410.823 592.836 410.019 592.836 409.028C592.836 408.037 592.034 407.233 591.045 407.233C590.056 407.233 589.254 408.037 589.254 409.028C589.254 410.019 590.056 410.823 591.045 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 410.823C586.064 410.823 586.866 410.019 586.866 409.028C586.866 408.037 586.064 407.233 585.075 407.233C584.085 407.233 583.284 408.037 583.284 409.028C583.284 410.019 584.085 410.823 585.075 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 410.823C580.094 410.823 580.896 410.019 580.896 409.028C580.896 408.037 580.094 407.233 579.104 407.233C578.115 407.233 577.313 408.037 577.313 409.028C577.313 410.019 578.115 410.823 579.104 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 404.841C1099.5 404.841 1100.3 404.037 1100.3 403.046C1100.3 402.055 1099.5 401.251 1098.51 401.251C1097.52 401.251 1096.72 402.055 1096.72 403.046C1096.72 404.037 1097.52 404.841 1098.51 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 404.841C1093.53 404.841 1094.33 404.037 1094.33 403.046C1094.33 402.055 1093.53 401.251 1092.54 401.251C1091.55 401.251 1090.75 402.055 1090.75 403.046C1090.75 404.037 1091.55 404.841 1092.54 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 404.841C1087.56 404.841 1088.36 404.037 1088.36 403.046C1088.36 402.055 1087.56 401.251 1086.57 401.251C1085.58 401.251 1084.78 402.055 1084.78 403.046C1084.78 404.037 1085.58 404.841 1086.57 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 404.841C1063.68 404.841 1064.48 404.037 1064.48 403.046C1064.48 402.055 1063.68 401.251 1062.69 401.251C1061.7 401.251 1060.9 402.055 1060.9 403.046C1060.9 404.037 1061.7 404.841 1062.69 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 404.841C1057.71 404.841 1058.51 404.037 1058.51 403.046C1058.51 402.055 1057.71 401.251 1056.72 401.251C1055.73 401.251 1054.93 402.055 1054.93 403.046C1054.93 404.037 1055.73 404.841 1056.72 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 404.841C1051.74 404.841 1052.54 404.037 1052.54 403.046C1052.54 402.055 1051.74 401.251 1050.75 401.251C1049.76 401.251 1048.96 402.055 1048.96 403.046C1048.96 404.037 1049.76 404.841 1050.75 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 404.841C1045.77 404.841 1046.57 404.037 1046.57 403.046C1046.57 402.055 1045.77 401.251 1044.78 401.251C1043.79 401.251 1042.99 402.055 1042.99 403.046C1042.99 404.037 1043.79 404.841 1044.78 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 404.841C729.347 404.841 730.149 404.037 730.149 403.046C730.149 402.055 729.347 401.251 728.358 401.251C727.369 401.251 726.567 402.055 726.567 403.046C726.567 404.037 727.369 404.841 728.358 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 404.841C723.377 404.841 724.179 404.037 724.179 403.046C724.179 402.055 723.377 401.251 722.388 401.251C721.399 401.251 720.597 402.055 720.597 403.046C720.597 404.037 721.399 404.841 722.388 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 404.841C687.556 404.841 688.358 404.037 688.358 403.046C688.358 402.055 687.556 401.251 686.567 401.251C685.578 401.251 684.776 402.055 684.776 403.046C684.776 404.037 685.578 404.841 686.567 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 404.841C681.586 404.841 682.388 404.037 682.388 403.046C682.388 402.055 681.586 401.251 680.597 401.251C679.608 401.251 678.806 402.055 678.806 403.046C678.806 404.037 679.608 404.841 680.597 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 404.841C675.616 404.841 676.418 404.037 676.418 403.046C676.418 402.055 675.616 401.251 674.627 401.251C673.638 401.251 672.836 402.055 672.836 403.046C672.836 404.037 673.638 404.841 674.627 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 404.841C669.646 404.841 670.448 404.037 670.448 403.046C670.448 402.055 669.646 401.251 668.657 401.251C667.668 401.251 666.866 402.055 666.866 403.046C666.866 404.037 667.668 404.841 668.657 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 404.841C663.676 404.841 664.478 404.037 664.478 403.046C664.478 402.055 663.676 401.251 662.687 401.251C661.697 401.251 660.896 402.055 660.896 403.046C660.896 404.037 661.697 404.841 662.687 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 404.841C657.706 404.841 658.507 404.037 658.507 403.046C658.507 402.055 657.706 401.251 656.716 401.251C655.727 401.251 654.925 402.055 654.925 403.046C654.925 404.037 655.727 404.841 656.716 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 404.841C651.735 404.841 652.537 404.037 652.537 403.046C652.537 402.055 651.735 401.251 650.746 401.251C649.757 401.251 648.955 402.055 648.955 403.046C648.955 404.037 649.757 404.841 650.746 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 404.841C645.765 404.841 646.567 404.037 646.567 403.046C646.567 402.055 645.765 401.251 644.776 401.251C643.787 401.251 642.985 402.055 642.985 403.046C642.985 404.037 643.787 404.841 644.776 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 404.841C639.795 404.841 640.597 404.037 640.597 403.046C640.597 402.055 639.795 401.251 638.806 401.251C637.817 401.251 637.015 402.055 637.015 403.046C637.015 404.037 637.817 404.841 638.806 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 404.841C633.825 404.841 634.627 404.037 634.627 403.046C634.627 402.055 633.825 401.251 632.836 401.251C631.847 401.251 631.045 402.055 631.045 403.046C631.045 404.037 631.847 404.841 632.836 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 404.841C627.855 404.841 628.657 404.037 628.657 403.046C628.657 402.055 627.855 401.251 626.866 401.251C625.876 401.251 625.075 402.055 625.075 403.046C625.075 404.037 625.876 404.841 626.866 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 404.841C621.885 404.841 622.687 404.037 622.687 403.046C622.687 402.055 621.885 401.251 620.895 401.251C619.906 401.251 619.104 402.055 619.104 403.046C619.104 404.037 619.906 404.841 620.895 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 404.841C615.915 404.841 616.716 404.037 616.716 403.046C616.716 402.055 615.915 401.251 614.925 401.251C613.936 401.251 613.134 402.055 613.134 403.046C613.134 404.037 613.936 404.841 614.925 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 404.841C609.944 404.841 610.746 404.037 610.746 403.046C610.746 402.055 609.944 401.251 608.955 401.251C607.966 401.251 607.164 402.055 607.164 403.046C607.164 404.037 607.966 404.841 608.955 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 404.841C603.974 404.841 604.776 404.037 604.776 403.046C604.776 402.055 603.974 401.251 602.985 401.251C601.996 401.251 601.194 402.055 601.194 403.046C601.194 404.037 601.996 404.841 602.985 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 404.841C598.004 404.841 598.806 404.037 598.806 403.046C598.806 402.055 598.004 401.251 597.015 401.251C596.026 401.251 595.224 402.055 595.224 403.046C595.224 404.037 596.026 404.841 597.015 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 404.841C592.034 404.841 592.836 404.037 592.836 403.046C592.836 402.055 592.034 401.251 591.045 401.251C590.056 401.251 589.254 402.055 589.254 403.046C589.254 404.037 590.056 404.841 591.045 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 404.841C586.064 404.841 586.866 404.037 586.866 403.046C586.866 402.055 586.064 401.251 585.075 401.251C584.085 401.251 583.284 402.055 583.284 403.046C583.284 404.037 584.085 404.841 585.075 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 398.859C1093.53 398.859 1094.33 398.055 1094.33 397.064C1094.33 396.073 1093.53 395.27 1092.54 395.27C1091.55 395.27 1090.75 396.073 1090.75 397.064C1090.75 398.055 1091.55 398.859 1092.54 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 398.859C1063.68 398.859 1064.48 398.055 1064.48 397.064C1064.48 396.073 1063.68 395.27 1062.69 395.27C1061.7 395.27 1060.9 396.073 1060.9 397.064C1060.9 398.055 1061.7 398.859 1062.69 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 398.859C1057.71 398.859 1058.51 398.055 1058.51 397.064C1058.51 396.073 1057.71 395.27 1056.72 395.27C1055.73 395.27 1054.93 396.073 1054.93 397.064C1054.93 398.055 1055.73 398.859 1056.72 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 398.859C1051.74 398.859 1052.54 398.055 1052.54 397.064C1052.54 396.073 1051.74 395.27 1050.75 395.27C1049.76 395.27 1048.96 396.073 1048.96 397.064C1048.96 398.055 1049.76 398.859 1050.75 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 398.859C723.377 398.859 724.179 398.055 724.179 397.064C724.179 396.073 723.377 395.27 722.388 395.27C721.399 395.27 720.597 396.073 720.597 397.064C720.597 398.055 721.399 398.859 722.388 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 398.859C687.556 398.859 688.358 398.055 688.358 397.064C688.358 396.073 687.556 395.27 686.567 395.27C685.578 395.27 684.776 396.073 684.776 397.064C684.776 398.055 685.578 398.859 686.567 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 398.859C681.586 398.859 682.388 398.055 682.388 397.064C682.388 396.073 681.586 395.27 680.597 395.27C679.608 395.27 678.806 396.073 678.806 397.064C678.806 398.055 679.608 398.859 680.597 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 398.859C675.616 398.859 676.418 398.055 676.418 397.064C676.418 396.073 675.616 395.27 674.627 395.27C673.638 395.27 672.836 396.073 672.836 397.064C672.836 398.055 673.638 398.859 674.627 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 398.859C669.646 398.859 670.448 398.055 670.448 397.064C670.448 396.073 669.646 395.27 668.657 395.27C667.668 395.27 666.866 396.073 666.866 397.064C666.866 398.055 667.668 398.859 668.657 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 398.859C663.676 398.859 664.478 398.055 664.478 397.064C664.478 396.073 663.676 395.27 662.687 395.27C661.697 395.27 660.896 396.073 660.896 397.064C660.896 398.055 661.697 398.859 662.687 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 398.859C657.706 398.859 658.507 398.055 658.507 397.064C658.507 396.073 657.706 395.27 656.716 395.27C655.727 395.27 654.925 396.073 654.925 397.064C654.925 398.055 655.727 398.859 656.716 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 398.859C651.735 398.859 652.537 398.055 652.537 397.064C652.537 396.073 651.735 395.27 650.746 395.27C649.757 395.27 648.955 396.073 648.955 397.064C648.955 398.055 649.757 398.859 650.746 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 398.859C645.765 398.859 646.567 398.055 646.567 397.064C646.567 396.073 645.765 395.27 644.776 395.27C643.787 395.27 642.985 396.073 642.985 397.064C642.985 398.055 643.787 398.859 644.776 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 398.859C639.795 398.859 640.597 398.055 640.597 397.064C640.597 396.073 639.795 395.27 638.806 395.27C637.817 395.27 637.015 396.073 637.015 397.064C637.015 398.055 637.817 398.859 638.806 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 398.859C633.825 398.859 634.627 398.055 634.627 397.064C634.627 396.073 633.825 395.27 632.836 395.27C631.847 395.27 631.045 396.073 631.045 397.064C631.045 398.055 631.847 398.859 632.836 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 398.859C627.855 398.859 628.657 398.055 628.657 397.064C628.657 396.073 627.855 395.27 626.866 395.27C625.876 395.27 625.075 396.073 625.075 397.064C625.075 398.055 625.876 398.859 626.866 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 398.859C621.885 398.859 622.687 398.055 622.687 397.064C622.687 396.073 621.885 395.27 620.895 395.27C619.906 395.27 619.104 396.073 619.104 397.064C619.104 398.055 619.906 398.859 620.895 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 398.859C615.915 398.859 616.716 398.055 616.716 397.064C616.716 396.073 615.915 395.27 614.925 395.27C613.936 395.27 613.134 396.073 613.134 397.064C613.134 398.055 613.936 398.859 614.925 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 398.859C609.944 398.859 610.746 398.055 610.746 397.064C610.746 396.073 609.944 395.27 608.955 395.27C607.966 395.27 607.164 396.073 607.164 397.064C607.164 398.055 607.966 398.859 608.955 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 398.859C603.974 398.859 604.776 398.055 604.776 397.064C604.776 396.073 603.974 395.27 602.985 395.27C601.996 395.27 601.194 396.073 601.194 397.064C601.194 398.055 601.996 398.859 602.985 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 398.859C598.004 398.859 598.806 398.055 598.806 397.064C598.806 396.073 598.004 395.27 597.015 395.27C596.026 395.27 595.224 396.073 595.224 397.064C595.224 398.055 596.026 398.859 597.015 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 398.859C592.034 398.859 592.836 398.055 592.836 397.064C592.836 396.073 592.034 395.27 591.045 395.27C590.056 395.27 589.254 396.073 589.254 397.064C589.254 398.055 590.056 398.859 591.045 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 398.859C586.064 398.859 586.866 398.055 586.866 397.064C586.866 396.073 586.064 395.27 585.075 395.27C584.085 395.27 583.284 396.073 583.284 397.064C583.284 398.055 584.085 398.859 585.075 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M1164.18 392.876C1165.17 392.876 1165.97 392.072 1165.97 391.081C1165.97 390.09 1165.17 389.287 1164.18 389.287C1163.19 389.287 1162.39 390.09 1162.39 391.081C1162.39 392.072 1163.19 392.876 1164.18 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 392.876C687.556 392.876 688.358 392.072 688.358 391.081C688.358 390.09 687.556 389.287 686.567 389.287C685.578 389.287 684.776 390.09 684.776 391.081C684.776 392.072 685.578 392.876 686.567 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 392.876C681.586 392.876 682.388 392.072 682.388 391.081C682.388 390.09 681.586 389.287 680.597 389.287C679.608 389.287 678.806 390.09 678.806 391.081C678.806 392.072 679.608 392.876 680.597 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 392.876C675.616 392.876 676.418 392.072 676.418 391.081C676.418 390.09 675.616 389.287 674.627 389.287C673.638 389.287 672.836 390.09 672.836 391.081C672.836 392.072 673.638 392.876 674.627 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 392.876C669.646 392.876 670.448 392.072 670.448 391.081C670.448 390.09 669.646 389.287 668.657 389.287C667.668 389.287 666.866 390.09 666.866 391.081C666.866 392.072 667.668 392.876 668.657 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 392.876C663.676 392.876 664.478 392.072 664.478 391.081C664.478 390.09 663.676 389.287 662.687 389.287C661.697 389.287 660.896 390.09 660.896 391.081C660.896 392.072 661.697 392.876 662.687 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 392.876C657.706 392.876 658.507 392.072 658.507 391.081C658.507 390.09 657.706 389.287 656.716 389.287C655.727 389.287 654.925 390.09 654.925 391.081C654.925 392.072 655.727 392.876 656.716 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 392.876C651.735 392.876 652.537 392.072 652.537 391.081C652.537 390.09 651.735 389.287 650.746 389.287C649.757 389.287 648.955 390.09 648.955 391.081C648.955 392.072 649.757 392.876 650.746 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 392.876C645.765 392.876 646.567 392.072 646.567 391.081C646.567 390.09 645.765 389.287 644.776 389.287C643.787 389.287 642.985 390.09 642.985 391.081C642.985 392.072 643.787 392.876 644.776 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 392.876C639.795 392.876 640.597 392.072 640.597 391.081C640.597 390.09 639.795 389.287 638.806 389.287C637.817 389.287 637.015 390.09 637.015 391.081C637.015 392.072 637.817 392.876 638.806 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 392.876C633.825 392.876 634.627 392.072 634.627 391.081C634.627 390.09 633.825 389.287 632.836 389.287C631.847 389.287 631.045 390.09 631.045 391.081C631.045 392.072 631.847 392.876 632.836 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 392.876C627.855 392.876 628.657 392.072 628.657 391.081C628.657 390.09 627.855 389.287 626.866 389.287C625.876 389.287 625.075 390.09 625.075 391.081C625.075 392.072 625.876 392.876 626.866 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 392.876C621.885 392.876 622.687 392.072 622.687 391.081C622.687 390.09 621.885 389.287 620.895 389.287C619.906 389.287 619.104 390.09 619.104 391.081C619.104 392.072 619.906 392.876 620.895 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 392.876C615.915 392.876 616.716 392.072 616.716 391.081C616.716 390.09 615.915 389.287 614.925 389.287C613.936 389.287 613.134 390.09 613.134 391.081C613.134 392.072 613.936 392.876 614.925 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 392.876C609.944 392.876 610.746 392.072 610.746 391.081C610.746 390.09 609.944 389.287 608.955 389.287C607.966 389.287 607.164 390.09 607.164 391.081C607.164 392.072 607.966 392.876 608.955 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 392.876C603.974 392.876 604.776 392.072 604.776 391.081C604.776 390.09 603.974 389.287 602.985 389.287C601.996 389.287 601.194 390.09 601.194 391.081C601.194 392.072 601.996 392.876 602.985 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 392.876C598.004 392.876 598.806 392.072 598.806 391.081C598.806 390.09 598.004 389.287 597.015 389.287C596.026 389.287 595.224 390.09 595.224 391.081C595.224 392.072 596.026 392.876 597.015 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 392.876C592.034 392.876 592.836 392.072 592.836 391.081C592.836 390.09 592.034 389.287 591.045 389.287C590.056 389.287 589.254 390.09 589.254 391.081C589.254 392.072 590.056 392.876 591.045 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 392.876C586.064 392.876 586.866 392.072 586.866 391.081C586.866 390.09 586.064 389.287 585.075 389.287C584.085 389.287 583.284 390.09 583.284 391.081C583.284 392.072 584.085 392.876 585.075 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M1164.18 386.894C1165.17 386.894 1165.97 386.091 1165.97 385.099C1165.97 384.108 1165.17 383.305 1164.18 383.305C1163.19 383.305 1162.39 384.108 1162.39 385.099C1162.39 386.091 1163.19 386.894 1164.18 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M1158.21 386.894C1159.2 386.894 1160 386.091 1160 385.099C1160 384.108 1159.2 383.305 1158.21 383.305C1157.22 383.305 1156.42 384.108 1156.42 385.099C1156.42 386.091 1157.22 386.894 1158.21 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M1122.39 386.894C1123.38 386.894 1124.18 386.091 1124.18 385.099C1124.18 384.108 1123.38 383.305 1122.39 383.305C1121.4 383.305 1120.6 384.108 1120.6 385.099C1120.6 386.091 1121.4 386.894 1122.39 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M1116.42 386.894C1117.41 386.894 1118.21 386.091 1118.21 385.099C1118.21 384.108 1117.41 383.305 1116.42 383.305C1115.43 383.305 1114.63 384.108 1114.63 385.099C1114.63 386.091 1115.43 386.894 1116.42 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 386.894C1021.88 386.894 1022.69 386.091 1022.69 385.099C1022.69 384.108 1021.88 383.305 1020.9 383.305C1019.91 383.305 1019.1 384.108 1019.1 385.099C1019.1 386.091 1019.91 386.894 1020.9 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 386.894C1003.97 386.894 1004.78 386.091 1004.78 385.099C1004.78 384.108 1003.97 383.305 1002.99 383.305C1002 383.305 1001.19 384.108 1001.19 385.099C1001.19 386.091 1002 386.894 1002.99 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 386.894C753.228 386.894 754.03 386.091 754.03 385.099C754.03 384.108 753.228 383.305 752.239 383.305C751.25 383.305 750.448 384.108 750.448 385.099C750.448 386.091 751.25 386.894 752.239 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 386.894C687.556 386.894 688.358 386.091 688.358 385.099C688.358 384.108 687.556 383.305 686.567 383.305C685.578 383.305 684.776 384.108 684.776 385.099C684.776 386.091 685.578 386.894 686.567 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 386.894C681.586 386.894 682.388 386.091 682.388 385.099C682.388 384.108 681.586 383.305 680.597 383.305C679.608 383.305 678.806 384.108 678.806 385.099C678.806 386.091 679.608 386.894 680.597 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 386.894C675.616 386.894 676.418 386.091 676.418 385.099C676.418 384.108 675.616 383.305 674.627 383.305C673.638 383.305 672.836 384.108 672.836 385.099C672.836 386.091 673.638 386.894 674.627 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 386.894C669.646 386.894 670.448 386.091 670.448 385.099C670.448 384.108 669.646 383.305 668.657 383.305C667.668 383.305 666.866 384.108 666.866 385.099C666.866 386.091 667.668 386.894 668.657 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 386.894C663.676 386.894 664.478 386.091 664.478 385.099C664.478 384.108 663.676 383.305 662.687 383.305C661.697 383.305 660.896 384.108 660.896 385.099C660.896 386.091 661.697 386.894 662.687 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 386.894C657.706 386.894 658.507 386.091 658.507 385.099C658.507 384.108 657.706 383.305 656.716 383.305C655.727 383.305 654.925 384.108 654.925 385.099C654.925 386.091 655.727 386.894 656.716 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 386.894C651.735 386.894 652.537 386.091 652.537 385.099C652.537 384.108 651.735 383.305 650.746 383.305C649.757 383.305 648.955 384.108 648.955 385.099C648.955 386.091 649.757 386.894 650.746 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 386.894C645.765 386.894 646.567 386.091 646.567 385.099C646.567 384.108 645.765 383.305 644.776 383.305C643.787 383.305 642.985 384.108 642.985 385.099C642.985 386.091 643.787 386.894 644.776 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 386.894C639.795 386.894 640.597 386.091 640.597 385.099C640.597 384.108 639.795 383.305 638.806 383.305C637.817 383.305 637.015 384.108 637.015 385.099C637.015 386.091 637.817 386.894 638.806 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 386.894C633.825 386.894 634.627 386.091 634.627 385.099C634.627 384.108 633.825 383.305 632.836 383.305C631.847 383.305 631.045 384.108 631.045 385.099C631.045 386.091 631.847 386.894 632.836 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 386.894C627.855 386.894 628.657 386.091 628.657 385.099C628.657 384.108 627.855 383.305 626.866 383.305C625.876 383.305 625.075 384.108 625.075 385.099C625.075 386.091 625.876 386.894 626.866 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 386.894C621.885 386.894 622.687 386.091 622.687 385.099C622.687 384.108 621.885 383.305 620.895 383.305C619.906 383.305 619.104 384.108 619.104 385.099C619.104 386.091 619.906 386.894 620.895 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 386.894C615.915 386.894 616.716 386.091 616.716 385.099C616.716 384.108 615.915 383.305 614.925 383.305C613.936 383.305 613.134 384.108 613.134 385.099C613.134 386.091 613.936 386.894 614.925 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 386.894C609.944 386.894 610.746 386.091 610.746 385.099C610.746 384.108 609.944 383.305 608.955 383.305C607.966 383.305 607.164 384.108 607.164 385.099C607.164 386.091 607.966 386.894 608.955 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 386.894C603.974 386.894 604.776 386.091 604.776 385.099C604.776 384.108 603.974 383.305 602.985 383.305C601.996 383.305 601.194 384.108 601.194 385.099C601.194 386.091 601.996 386.894 602.985 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 386.894C598.004 386.894 598.806 386.091 598.806 385.099C598.806 384.108 598.004 383.305 597.015 383.305C596.026 383.305 595.224 384.108 595.224 385.099C595.224 386.091 596.026 386.894 597.015 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 386.894C592.034 386.894 592.836 386.091 592.836 385.099C592.836 384.108 592.034 383.305 591.045 383.305C590.056 383.305 589.254 384.108 589.254 385.099C589.254 386.091 590.056 386.894 591.045 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 386.894C586.064 386.894 586.866 386.091 586.866 385.099C586.866 384.108 586.064 383.305 585.075 383.305C584.085 383.305 583.284 384.108 583.284 385.099C583.284 386.091 584.085 386.894 585.075 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M1158.21 380.912C1159.2 380.912 1160 380.108 1160 379.117C1160 378.126 1159.2 377.322 1158.21 377.322C1157.22 377.322 1156.42 378.126 1156.42 379.117C1156.42 380.108 1157.22 380.912 1158.21 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1152.24 380.912C1153.23 380.912 1154.03 380.108 1154.03 379.117C1154.03 378.126 1153.23 377.322 1152.24 377.322C1151.25 377.322 1150.45 378.126 1150.45 379.117C1150.45 380.108 1151.25 380.912 1152.24 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1116.42 380.912C1117.41 380.912 1118.21 380.108 1118.21 379.117C1118.21 378.126 1117.41 377.322 1116.42 377.322C1115.43 377.322 1114.63 378.126 1114.63 379.117C1114.63 380.108 1115.43 380.912 1116.42 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 380.912C1111.44 380.912 1112.24 380.108 1112.24 379.117C1112.24 378.126 1111.44 377.322 1110.45 377.322C1109.46 377.322 1108.66 378.126 1108.66 379.117C1108.66 380.108 1109.46 380.912 1110.45 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 380.912C1099.5 380.912 1100.3 380.108 1100.3 379.117C1100.3 378.126 1099.5 377.322 1098.51 377.322C1097.52 377.322 1096.72 378.126 1096.72 379.117C1096.72 380.108 1097.52 380.912 1098.51 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 380.912C1093.53 380.912 1094.33 380.108 1094.33 379.117C1094.33 378.126 1093.53 377.322 1092.54 377.322C1091.55 377.322 1090.75 378.126 1090.75 379.117C1090.75 380.108 1091.55 380.912 1092.54 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 380.912C1087.56 380.912 1088.36 380.108 1088.36 379.117C1088.36 378.126 1087.56 377.322 1086.57 377.322C1085.58 377.322 1084.78 378.126 1084.78 379.117C1084.78 380.108 1085.58 380.912 1086.57 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 380.912C1081.59 380.912 1082.39 380.108 1082.39 379.117C1082.39 378.126 1081.59 377.322 1080.6 377.322C1079.61 377.322 1078.81 378.126 1078.81 379.117C1078.81 380.108 1079.61 380.912 1080.6 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 380.912C1027.85 380.912 1028.66 380.108 1028.66 379.117C1028.66 378.126 1027.85 377.322 1026.87 377.322C1025.88 377.322 1025.07 378.126 1025.07 379.117C1025.07 380.108 1025.88 380.912 1026.87 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 380.912C1009.94 380.912 1010.75 380.108 1010.75 379.117C1010.75 378.126 1009.94 377.322 1008.96 377.322C1007.97 377.322 1007.16 378.126 1007.16 379.117C1007.16 380.108 1007.97 380.912 1008.96 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 380.912C998.004 380.912 998.806 380.108 998.806 379.117C998.806 378.126 998.004 377.322 997.015 377.322C996.026 377.322 995.224 378.126 995.224 379.117C995.224 380.108 996.026 380.912 997.015 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 380.912C986.064 380.912 986.866 380.108 986.866 379.117C986.866 378.126 986.064 377.322 985.075 377.322C984.085 377.322 983.284 378.126 983.284 379.117C983.284 380.108 984.085 380.912 985.075 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 380.912C980.094 380.912 980.896 380.108 980.896 379.117C980.896 378.126 980.094 377.322 979.104 377.322C978.115 377.322 977.313 378.126 977.313 379.117C977.313 380.108 978.115 380.912 979.104 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 380.912C974.123 380.912 974.925 380.108 974.925 379.117C974.925 378.126 974.123 377.322 973.134 377.322C972.145 377.322 971.343 378.126 971.343 379.117C971.343 380.108 972.145 380.912 973.134 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 380.912C968.153 380.912 968.955 380.108 968.955 379.117C968.955 378.126 968.153 377.322 967.164 377.322C966.175 377.322 965.373 378.126 965.373 379.117C965.373 380.108 966.175 380.912 967.164 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 380.912C687.556 380.912 688.358 380.108 688.358 379.117C688.358 378.126 687.556 377.322 686.567 377.322C685.578 377.322 684.776 378.126 684.776 379.117C684.776 380.108 685.578 380.912 686.567 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 380.912C681.586 380.912 682.388 380.108 682.388 379.117C682.388 378.126 681.586 377.322 680.597 377.322C679.608 377.322 678.806 378.126 678.806 379.117C678.806 380.108 679.608 380.912 680.597 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 380.912C675.616 380.912 676.418 380.108 676.418 379.117C676.418 378.126 675.616 377.322 674.627 377.322C673.638 377.322 672.836 378.126 672.836 379.117C672.836 380.108 673.638 380.912 674.627 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 380.912C669.646 380.912 670.448 380.108 670.448 379.117C670.448 378.126 669.646 377.322 668.657 377.322C667.668 377.322 666.866 378.126 666.866 379.117C666.866 380.108 667.668 380.912 668.657 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 380.912C663.676 380.912 664.478 380.108 664.478 379.117C664.478 378.126 663.676 377.322 662.687 377.322C661.697 377.322 660.896 378.126 660.896 379.117C660.896 380.108 661.697 380.912 662.687 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 380.912C657.706 380.912 658.507 380.108 658.507 379.117C658.507 378.126 657.706 377.322 656.716 377.322C655.727 377.322 654.925 378.126 654.925 379.117C654.925 380.108 655.727 380.912 656.716 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 380.912C651.735 380.912 652.537 380.108 652.537 379.117C652.537 378.126 651.735 377.322 650.746 377.322C649.757 377.322 648.955 378.126 648.955 379.117C648.955 380.108 649.757 380.912 650.746 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 380.912C645.765 380.912 646.567 380.108 646.567 379.117C646.567 378.126 645.765 377.322 644.776 377.322C643.787 377.322 642.985 378.126 642.985 379.117C642.985 380.108 643.787 380.912 644.776 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 380.912C639.795 380.912 640.597 380.108 640.597 379.117C640.597 378.126 639.795 377.322 638.806 377.322C637.817 377.322 637.015 378.126 637.015 379.117C637.015 380.108 637.817 380.912 638.806 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 380.912C633.825 380.912 634.627 380.108 634.627 379.117C634.627 378.126 633.825 377.322 632.836 377.322C631.847 377.322 631.045 378.126 631.045 379.117C631.045 380.108 631.847 380.912 632.836 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 380.912C627.855 380.912 628.657 380.108 628.657 379.117C628.657 378.126 627.855 377.322 626.866 377.322C625.876 377.322 625.075 378.126 625.075 379.117C625.075 380.108 625.876 380.912 626.866 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 380.912C621.885 380.912 622.687 380.108 622.687 379.117C622.687 378.126 621.885 377.322 620.895 377.322C619.906 377.322 619.104 378.126 619.104 379.117C619.104 380.108 619.906 380.912 620.895 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 380.912C615.915 380.912 616.716 380.108 616.716 379.117C616.716 378.126 615.915 377.322 614.925 377.322C613.936 377.322 613.134 378.126 613.134 379.117C613.134 380.108 613.936 380.912 614.925 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 380.912C609.944 380.912 610.746 380.108 610.746 379.117C610.746 378.126 609.944 377.322 608.955 377.322C607.966 377.322 607.164 378.126 607.164 379.117C607.164 380.108 607.966 380.912 608.955 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 380.912C603.974 380.912 604.776 380.108 604.776 379.117C604.776 378.126 603.974 377.322 602.985 377.322C601.996 377.322 601.194 378.126 601.194 379.117C601.194 380.108 601.996 380.912 602.985 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 380.912C598.004 380.912 598.806 380.108 598.806 379.117C598.806 378.126 598.004 377.322 597.015 377.322C596.026 377.322 595.224 378.126 595.224 379.117C595.224 380.108 596.026 380.912 597.015 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 380.912C592.034 380.912 592.836 380.108 592.836 379.117C592.836 378.126 592.034 377.322 591.045 377.322C590.056 377.322 589.254 378.126 589.254 379.117C589.254 380.108 590.056 380.912 591.045 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 380.912C586.064 380.912 586.866 380.108 586.866 379.117C586.866 378.126 586.064 377.322 585.075 377.322C584.085 377.322 583.284 378.126 583.284 379.117C583.284 380.108 584.085 380.912 585.075 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M1146.27 374.929C1147.26 374.929 1148.06 374.126 1148.06 373.135C1148.06 372.143 1147.26 371.34 1146.27 371.34C1145.28 371.34 1144.48 372.143 1144.48 373.135C1144.48 374.126 1145.28 374.929 1146.27 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1140.3 374.929C1141.29 374.929 1142.09 374.126 1142.09 373.135C1142.09 372.143 1141.29 371.34 1140.3 371.34C1139.31 371.34 1138.51 372.143 1138.51 373.135C1138.51 374.126 1139.31 374.929 1140.3 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 374.929C1111.44 374.929 1112.24 374.126 1112.24 373.135C1112.24 372.143 1111.44 371.34 1110.45 371.34C1109.46 371.34 1108.66 372.143 1108.66 373.135C1108.66 374.126 1109.46 374.929 1110.45 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 374.929C1105.47 374.929 1106.27 374.126 1106.27 373.135C1106.27 372.143 1105.47 371.34 1104.48 371.34C1103.49 371.34 1102.69 372.143 1102.69 373.135C1102.69 374.126 1103.49 374.929 1104.48 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 374.929C1099.5 374.929 1100.3 374.126 1100.3 373.135C1100.3 372.143 1099.5 371.34 1098.51 371.34C1097.52 371.34 1096.72 372.143 1096.72 373.135C1096.72 374.126 1097.52 374.929 1098.51 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 374.929C1093.53 374.929 1094.33 374.126 1094.33 373.135C1094.33 372.143 1093.53 371.34 1092.54 371.34C1091.55 371.34 1090.75 372.143 1090.75 373.135C1090.75 374.126 1091.55 374.929 1092.54 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 374.929C1087.56 374.929 1088.36 374.126 1088.36 373.135C1088.36 372.143 1087.56 371.34 1086.57 371.34C1085.58 371.34 1084.78 372.143 1084.78 373.135C1084.78 374.126 1085.58 374.929 1086.57 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 374.929C1081.59 374.929 1082.39 374.126 1082.39 373.135C1082.39 372.143 1081.59 371.34 1080.6 371.34C1079.61 371.34 1078.81 372.143 1078.81 373.135C1078.81 374.126 1079.61 374.929 1080.6 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 374.929C974.123 374.929 974.925 374.126 974.925 373.135C974.925 372.143 974.123 371.34 973.134 371.34C972.145 371.34 971.343 372.143 971.343 373.135C971.343 374.126 972.145 374.929 973.134 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 374.929C968.153 374.929 968.955 374.126 968.955 373.135C968.955 372.143 968.153 371.34 967.164 371.34C966.175 371.34 965.373 372.143 965.373 373.135C965.373 374.126 966.175 374.929 967.164 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 374.929C962.183 374.929 962.985 374.126 962.985 373.135C962.985 372.143 962.183 371.34 961.194 371.34C960.205 371.34 959.403 372.143 959.403 373.135C959.403 374.126 960.205 374.929 961.194 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 374.929C956.213 374.929 957.015 374.126 957.015 373.135C957.015 372.143 956.213 371.34 955.224 371.34C954.235 371.34 953.433 372.143 953.433 373.135C953.433 374.126 954.235 374.929 955.224 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 374.929C950.243 374.929 951.045 374.126 951.045 373.135C951.045 372.143 950.243 371.34 949.254 371.34C948.265 371.34 947.463 372.143 947.463 373.135C947.463 374.126 948.265 374.929 949.254 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 374.929C681.586 374.929 682.388 374.126 682.388 373.135C682.388 372.143 681.586 371.34 680.597 371.34C679.608 371.34 678.806 372.143 678.806 373.135C678.806 374.126 679.608 374.929 680.597 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 374.929C675.616 374.929 676.418 374.126 676.418 373.135C676.418 372.143 675.616 371.34 674.627 371.34C673.638 371.34 672.836 372.143 672.836 373.135C672.836 374.126 673.638 374.929 674.627 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 374.929C669.646 374.929 670.448 374.126 670.448 373.135C670.448 372.143 669.646 371.34 668.657 371.34C667.668 371.34 666.866 372.143 666.866 373.135C666.866 374.126 667.668 374.929 668.657 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 374.929C663.676 374.929 664.478 374.126 664.478 373.135C664.478 372.143 663.676 371.34 662.687 371.34C661.697 371.34 660.896 372.143 660.896 373.135C660.896 374.126 661.697 374.929 662.687 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 374.929C657.706 374.929 658.507 374.126 658.507 373.135C658.507 372.143 657.706 371.34 656.716 371.34C655.727 371.34 654.925 372.143 654.925 373.135C654.925 374.126 655.727 374.929 656.716 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 374.929C651.735 374.929 652.537 374.126 652.537 373.135C652.537 372.143 651.735 371.34 650.746 371.34C649.757 371.34 648.955 372.143 648.955 373.135C648.955 374.126 649.757 374.929 650.746 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 374.929C645.765 374.929 646.567 374.126 646.567 373.135C646.567 372.143 645.765 371.34 644.776 371.34C643.787 371.34 642.985 372.143 642.985 373.135C642.985 374.126 643.787 374.929 644.776 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 374.929C639.795 374.929 640.597 374.126 640.597 373.135C640.597 372.143 639.795 371.34 638.806 371.34C637.817 371.34 637.015 372.143 637.015 373.135C637.015 374.126 637.817 374.929 638.806 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 374.929C633.825 374.929 634.627 374.126 634.627 373.135C634.627 372.143 633.825 371.34 632.836 371.34C631.847 371.34 631.045 372.143 631.045 373.135C631.045 374.126 631.847 374.929 632.836 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 374.929C627.855 374.929 628.657 374.126 628.657 373.135C628.657 372.143 627.855 371.34 626.866 371.34C625.876 371.34 625.075 372.143 625.075 373.135C625.075 374.126 625.876 374.929 626.866 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 374.929C621.885 374.929 622.687 374.126 622.687 373.135C622.687 372.143 621.885 371.34 620.895 371.34C619.906 371.34 619.104 372.143 619.104 373.135C619.104 374.126 619.906 374.929 620.895 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 374.929C615.915 374.929 616.716 374.126 616.716 373.135C616.716 372.143 615.915 371.34 614.925 371.34C613.936 371.34 613.134 372.143 613.134 373.135C613.134 374.126 613.936 374.929 614.925 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 374.929C609.944 374.929 610.746 374.126 610.746 373.135C610.746 372.143 609.944 371.34 608.955 371.34C607.966 371.34 607.164 372.143 607.164 373.135C607.164 374.126 607.966 374.929 608.955 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 374.929C603.974 374.929 604.776 374.126 604.776 373.135C604.776 372.143 603.974 371.34 602.985 371.34C601.996 371.34 601.194 372.143 601.194 373.135C601.194 374.126 601.996 374.929 602.985 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 374.929C598.004 374.929 598.806 374.126 598.806 373.135C598.806 372.143 598.004 371.34 597.015 371.34C596.026 371.34 595.224 372.143 595.224 373.135C595.224 374.126 596.026 374.929 597.015 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 374.929C592.034 374.929 592.836 374.126 592.836 373.135C592.836 372.143 592.034 371.34 591.045 371.34C590.056 371.34 589.254 372.143 589.254 373.135C589.254 374.126 590.056 374.929 591.045 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 374.929C586.064 374.929 586.866 374.126 586.866 373.135C586.866 372.143 586.064 371.34 585.075 371.34C584.085 371.34 583.284 372.143 583.284 373.135C583.284 374.126 584.085 374.929 585.075 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M1140.3 368.947C1141.29 368.947 1142.09 368.144 1142.09 367.153C1142.09 366.161 1141.29 365.358 1140.3 365.358C1139.31 365.358 1138.51 366.161 1138.51 367.153C1138.51 368.144 1139.31 368.947 1140.3 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1128.36 368.947C1129.35 368.947 1130.15 368.144 1130.15 367.153C1130.15 366.161 1129.35 365.358 1128.36 365.358C1127.37 365.358 1126.57 366.161 1126.57 367.153C1126.57 368.144 1127.37 368.947 1128.36 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1122.39 368.947C1123.38 368.947 1124.18 368.144 1124.18 367.153C1124.18 366.161 1123.38 365.358 1122.39 365.358C1121.4 365.358 1120.6 366.161 1120.6 367.153C1120.6 368.144 1121.4 368.947 1122.39 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1110.45 368.947C1111.44 368.947 1112.24 368.144 1112.24 367.153C1112.24 366.161 1111.44 365.358 1110.45 365.358C1109.46 365.358 1108.66 366.161 1108.66 367.153C1108.66 368.144 1109.46 368.947 1110.45 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 368.947C1105.47 368.947 1106.27 368.144 1106.27 367.153C1106.27 366.161 1105.47 365.358 1104.48 365.358C1103.49 365.358 1102.69 366.161 1102.69 367.153C1102.69 368.144 1103.49 368.947 1104.48 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 368.947C1099.5 368.947 1100.3 368.144 1100.3 367.153C1100.3 366.161 1099.5 365.358 1098.51 365.358C1097.52 365.358 1096.72 366.161 1096.72 367.153C1096.72 368.144 1097.52 368.947 1098.51 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 368.947C1093.53 368.947 1094.33 368.144 1094.33 367.153C1094.33 366.161 1093.53 365.358 1092.54 365.358C1091.55 365.358 1090.75 366.161 1090.75 367.153C1090.75 368.144 1091.55 368.947 1092.54 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 368.947C1087.56 368.947 1088.36 368.144 1088.36 367.153C1088.36 366.161 1087.56 365.358 1086.57 365.358C1085.58 365.358 1084.78 366.161 1084.78 367.153C1084.78 368.144 1085.58 368.947 1086.57 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 368.947C1081.59 368.947 1082.39 368.144 1082.39 367.153C1082.39 366.161 1081.59 365.358 1080.6 365.358C1079.61 365.358 1078.81 366.161 1078.81 367.153C1078.81 368.144 1079.61 368.947 1080.6 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 368.947C1075.62 368.947 1076.42 368.144 1076.42 367.153C1076.42 366.161 1075.62 365.358 1074.63 365.358C1073.64 365.358 1072.84 366.161 1072.84 367.153C1072.84 368.144 1073.64 368.947 1074.63 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 368.947C1003.97 368.947 1004.78 368.144 1004.78 367.153C1004.78 366.161 1003.97 365.358 1002.99 365.358C1002 365.358 1001.19 366.161 1001.19 367.153C1001.19 368.144 1002 368.947 1002.99 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 368.947C950.243 368.947 951.045 368.144 951.045 367.153C951.045 366.161 950.243 365.358 949.254 365.358C948.265 365.358 947.463 366.161 947.463 367.153C947.463 368.144 948.265 368.947 949.254 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 368.947C944.273 368.947 945.075 368.144 945.075 367.153C945.075 366.161 944.273 365.358 943.284 365.358C942.294 365.358 941.492 366.161 941.492 367.153C941.492 368.144 942.294 368.947 943.284 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 368.947C681.586 368.947 682.388 368.144 682.388 367.153C682.388 366.161 681.586 365.358 680.597 365.358C679.608 365.358 678.806 366.161 678.806 367.153C678.806 368.144 679.608 368.947 680.597 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 368.947C675.616 368.947 676.418 368.144 676.418 367.153C676.418 366.161 675.616 365.358 674.627 365.358C673.638 365.358 672.836 366.161 672.836 367.153C672.836 368.144 673.638 368.947 674.627 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 368.947C669.646 368.947 670.448 368.144 670.448 367.153C670.448 366.161 669.646 365.358 668.657 365.358C667.668 365.358 666.866 366.161 666.866 367.153C666.866 368.144 667.668 368.947 668.657 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 368.947C663.676 368.947 664.478 368.144 664.478 367.153C664.478 366.161 663.676 365.358 662.687 365.358C661.697 365.358 660.896 366.161 660.896 367.153C660.896 368.144 661.697 368.947 662.687 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 368.947C657.706 368.947 658.507 368.144 658.507 367.153C658.507 366.161 657.706 365.358 656.716 365.358C655.727 365.358 654.925 366.161 654.925 367.153C654.925 368.144 655.727 368.947 656.716 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 368.947C651.735 368.947 652.537 368.144 652.537 367.153C652.537 366.161 651.735 365.358 650.746 365.358C649.757 365.358 648.955 366.161 648.955 367.153C648.955 368.144 649.757 368.947 650.746 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 368.947C645.765 368.947 646.567 368.144 646.567 367.153C646.567 366.161 645.765 365.358 644.776 365.358C643.787 365.358 642.985 366.161 642.985 367.153C642.985 368.144 643.787 368.947 644.776 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 368.947C639.795 368.947 640.597 368.144 640.597 367.153C640.597 366.161 639.795 365.358 638.806 365.358C637.817 365.358 637.015 366.161 637.015 367.153C637.015 368.144 637.817 368.947 638.806 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 368.947C633.825 368.947 634.627 368.144 634.627 367.153C634.627 366.161 633.825 365.358 632.836 365.358C631.847 365.358 631.045 366.161 631.045 367.153C631.045 368.144 631.847 368.947 632.836 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 368.947C627.855 368.947 628.657 368.144 628.657 367.153C628.657 366.161 627.855 365.358 626.866 365.358C625.876 365.358 625.075 366.161 625.075 367.153C625.075 368.144 625.876 368.947 626.866 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 368.947C621.885 368.947 622.687 368.144 622.687 367.153C622.687 366.161 621.885 365.358 620.895 365.358C619.906 365.358 619.104 366.161 619.104 367.153C619.104 368.144 619.906 368.947 620.895 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 368.947C615.915 368.947 616.716 368.144 616.716 367.153C616.716 366.161 615.915 365.358 614.925 365.358C613.936 365.358 613.134 366.161 613.134 367.153C613.134 368.144 613.936 368.947 614.925 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 368.947C609.944 368.947 610.746 368.144 610.746 367.153C610.746 366.161 609.944 365.358 608.955 365.358C607.966 365.358 607.164 366.161 607.164 367.153C607.164 368.144 607.966 368.947 608.955 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 368.947C603.974 368.947 604.776 368.144 604.776 367.153C604.776 366.161 603.974 365.358 602.985 365.358C601.996 365.358 601.194 366.161 601.194 367.153C601.194 368.144 601.996 368.947 602.985 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 368.947C598.004 368.947 598.806 368.144 598.806 367.153C598.806 366.161 598.004 365.358 597.015 365.358C596.026 365.358 595.224 366.161 595.224 367.153C595.224 368.144 596.026 368.947 597.015 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 368.947C592.034 368.947 592.836 368.144 592.836 367.153C592.836 366.161 592.034 365.358 591.045 365.358C590.056 365.358 589.254 366.161 589.254 367.153C589.254 368.144 590.056 368.947 591.045 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 368.947C586.064 368.947 586.866 368.144 586.866 367.153C586.866 366.161 586.064 365.358 585.075 365.358C584.085 365.358 583.284 366.161 583.284 367.153C583.284 368.144 584.085 368.947 585.075 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 368.947C580.094 368.947 580.896 368.144 580.896 367.153C580.896 366.161 580.094 365.358 579.104 365.358C578.115 365.358 577.313 366.161 577.313 367.153C577.313 368.144 578.115 368.947 579.104 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M1134.33 362.965C1135.32 362.965 1136.12 362.161 1136.12 361.17C1136.12 360.179 1135.32 359.375 1134.33 359.375C1133.34 359.375 1132.54 360.179 1132.54 361.17C1132.54 362.161 1133.34 362.965 1134.33 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 362.965C1099.5 362.965 1100.3 362.161 1100.3 361.17C1100.3 360.179 1099.5 359.375 1098.51 359.375C1097.52 359.375 1096.72 360.179 1096.72 361.17C1096.72 362.161 1097.52 362.965 1098.51 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 362.965C1093.53 362.965 1094.33 362.161 1094.33 361.17C1094.33 360.179 1093.53 359.375 1092.54 359.375C1091.55 359.375 1090.75 360.179 1090.75 361.17C1090.75 362.161 1091.55 362.965 1092.54 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 362.965C1087.56 362.965 1088.36 362.161 1088.36 361.17C1088.36 360.179 1087.56 359.375 1086.57 359.375C1085.58 359.375 1084.78 360.179 1084.78 361.17C1084.78 362.161 1085.58 362.965 1086.57 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 362.965C1081.59 362.965 1082.39 362.161 1082.39 361.17C1082.39 360.179 1081.59 359.375 1080.6 359.375C1079.61 359.375 1078.81 360.179 1078.81 361.17C1078.81 362.161 1079.61 362.965 1080.6 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 362.965C1075.62 362.965 1076.42 362.161 1076.42 361.17C1076.42 360.179 1075.62 359.375 1074.63 359.375C1073.64 359.375 1072.84 360.179 1072.84 361.17C1072.84 362.161 1073.64 362.965 1074.63 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 362.965C1069.65 362.965 1070.45 362.161 1070.45 361.17C1070.45 360.179 1069.65 359.375 1068.66 359.375C1067.67 359.375 1066.87 360.179 1066.87 361.17C1066.87 362.161 1067.67 362.965 1068.66 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 362.965C1063.68 362.965 1064.48 362.161 1064.48 361.17C1064.48 360.179 1063.68 359.375 1062.69 359.375C1061.7 359.375 1060.9 360.179 1060.9 361.17C1060.9 362.161 1061.7 362.965 1062.69 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 362.965C1015.91 362.965 1016.72 362.161 1016.72 361.17C1016.72 360.179 1015.91 359.375 1014.93 359.375C1013.94 359.375 1013.13 360.179 1013.13 361.17C1013.13 362.161 1013.94 362.965 1014.93 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 362.965C1003.97 362.965 1004.78 362.161 1004.78 361.17C1004.78 360.179 1003.97 359.375 1002.99 359.375C1002 359.375 1001.19 360.179 1001.19 361.17C1001.19 362.161 1002 362.965 1002.99 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 362.965C950.243 362.965 951.045 362.161 951.045 361.17C951.045 360.179 950.243 359.375 949.254 359.375C948.265 359.375 947.463 360.179 947.463 361.17C947.463 362.161 948.265 362.965 949.254 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 362.965C944.273 362.965 945.075 362.161 945.075 361.17C945.075 360.179 944.273 359.375 943.284 359.375C942.294 359.375 941.492 360.179 941.492 361.17C941.492 362.161 942.294 362.965 943.284 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 362.965C938.303 362.965 939.105 362.161 939.105 361.17C939.105 360.179 938.303 359.375 937.313 359.375C936.324 359.375 935.522 360.179 935.522 361.17C935.522 362.161 936.324 362.965 937.313 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 362.965C687.556 362.965 688.358 362.161 688.358 361.17C688.358 360.179 687.556 359.375 686.567 359.375C685.578 359.375 684.776 360.179 684.776 361.17C684.776 362.161 685.578 362.965 686.567 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 362.965C681.586 362.965 682.388 362.161 682.388 361.17C682.388 360.179 681.586 359.375 680.597 359.375C679.608 359.375 678.806 360.179 678.806 361.17C678.806 362.161 679.608 362.965 680.597 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 362.965C675.616 362.965 676.418 362.161 676.418 361.17C676.418 360.179 675.616 359.375 674.627 359.375C673.638 359.375 672.836 360.179 672.836 361.17C672.836 362.161 673.638 362.965 674.627 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 362.965C669.646 362.965 670.448 362.161 670.448 361.17C670.448 360.179 669.646 359.375 668.657 359.375C667.668 359.375 666.866 360.179 666.866 361.17C666.866 362.161 667.668 362.965 668.657 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 362.965C663.676 362.965 664.478 362.161 664.478 361.17C664.478 360.179 663.676 359.375 662.687 359.375C661.697 359.375 660.896 360.179 660.896 361.17C660.896 362.161 661.697 362.965 662.687 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 362.965C657.706 362.965 658.507 362.161 658.507 361.17C658.507 360.179 657.706 359.375 656.716 359.375C655.727 359.375 654.925 360.179 654.925 361.17C654.925 362.161 655.727 362.965 656.716 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 362.965C651.735 362.965 652.537 362.161 652.537 361.17C652.537 360.179 651.735 359.375 650.746 359.375C649.757 359.375 648.955 360.179 648.955 361.17C648.955 362.161 649.757 362.965 650.746 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 362.965C645.765 362.965 646.567 362.161 646.567 361.17C646.567 360.179 645.765 359.375 644.776 359.375C643.787 359.375 642.985 360.179 642.985 361.17C642.985 362.161 643.787 362.965 644.776 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 362.965C639.795 362.965 640.597 362.161 640.597 361.17C640.597 360.179 639.795 359.375 638.806 359.375C637.817 359.375 637.015 360.179 637.015 361.17C637.015 362.161 637.817 362.965 638.806 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 362.965C633.825 362.965 634.627 362.161 634.627 361.17C634.627 360.179 633.825 359.375 632.836 359.375C631.847 359.375 631.045 360.179 631.045 361.17C631.045 362.161 631.847 362.965 632.836 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 362.965C627.855 362.965 628.657 362.161 628.657 361.17C628.657 360.179 627.855 359.375 626.866 359.375C625.876 359.375 625.075 360.179 625.075 361.17C625.075 362.161 625.876 362.965 626.866 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 362.965C621.885 362.965 622.687 362.161 622.687 361.17C622.687 360.179 621.885 359.375 620.895 359.375C619.906 359.375 619.104 360.179 619.104 361.17C619.104 362.161 619.906 362.965 620.895 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 362.965C615.915 362.965 616.716 362.161 616.716 361.17C616.716 360.179 615.915 359.375 614.925 359.375C613.936 359.375 613.134 360.179 613.134 361.17C613.134 362.161 613.936 362.965 614.925 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 362.965C609.944 362.965 610.746 362.161 610.746 361.17C610.746 360.179 609.944 359.375 608.955 359.375C607.966 359.375 607.164 360.179 607.164 361.17C607.164 362.161 607.966 362.965 608.955 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 362.965C603.974 362.965 604.776 362.161 604.776 361.17C604.776 360.179 603.974 359.375 602.985 359.375C601.996 359.375 601.194 360.179 601.194 361.17C601.194 362.161 601.996 362.965 602.985 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 362.965C598.004 362.965 598.806 362.161 598.806 361.17C598.806 360.179 598.004 359.375 597.015 359.375C596.026 359.375 595.224 360.179 595.224 361.17C595.224 362.161 596.026 362.965 597.015 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 362.965C592.034 362.965 592.836 362.161 592.836 361.17C592.836 360.179 592.034 359.375 591.045 359.375C590.056 359.375 589.254 360.179 589.254 361.17C589.254 362.161 590.056 362.965 591.045 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 362.965C586.064 362.965 586.866 362.161 586.866 361.17C586.866 360.179 586.064 359.375 585.075 359.375C584.085 359.375 583.284 360.179 583.284 361.17C583.284 362.161 584.085 362.965 585.075 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 362.965C580.094 362.965 580.896 362.161 580.896 361.17C580.896 360.179 580.094 359.375 579.104 359.375C578.115 359.375 577.313 360.179 577.313 361.17C577.313 362.161 578.115 362.965 579.104 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M1128.36 356.982C1129.35 356.982 1130.15 356.179 1130.15 355.188C1130.15 354.197 1129.35 353.393 1128.36 353.393C1127.37 353.393 1126.57 354.197 1126.57 355.188C1126.57 356.179 1127.37 356.982 1128.36 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 356.982C1087.56 356.982 1088.36 356.179 1088.36 355.188C1088.36 354.197 1087.56 353.393 1086.57 353.393C1085.58 353.393 1084.78 354.197 1084.78 355.188C1084.78 356.179 1085.58 356.982 1086.57 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 356.982C1081.59 356.982 1082.39 356.179 1082.39 355.188C1082.39 354.197 1081.59 353.393 1080.6 353.393C1079.61 353.393 1078.81 354.197 1078.81 355.188C1078.81 356.179 1079.61 356.982 1080.6 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 356.982C1075.62 356.982 1076.42 356.179 1076.42 355.188C1076.42 354.197 1075.62 353.393 1074.63 353.393C1073.64 353.393 1072.84 354.197 1072.84 355.188C1072.84 356.179 1073.64 356.982 1074.63 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 356.982C1063.68 356.982 1064.48 356.179 1064.48 355.188C1064.48 354.197 1063.68 353.393 1062.69 353.393C1061.7 353.393 1060.9 354.197 1060.9 355.188C1060.9 356.179 1061.7 356.982 1062.69 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 356.982C1057.71 356.982 1058.51 356.179 1058.51 355.188C1058.51 354.197 1057.71 353.393 1056.72 353.393C1055.73 353.393 1054.93 354.197 1054.93 355.188C1054.93 356.179 1055.73 356.982 1056.72 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 356.982C1039.8 356.982 1040.6 356.179 1040.6 355.188C1040.6 354.197 1039.8 353.393 1038.81 353.393C1037.82 353.393 1037.01 354.197 1037.01 355.188C1037.01 356.179 1037.82 356.982 1038.81 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 356.982C1015.91 356.982 1016.72 356.179 1016.72 355.188C1016.72 354.197 1015.91 353.393 1014.93 353.393C1013.94 353.393 1013.13 354.197 1013.13 355.188C1013.13 356.179 1013.94 356.982 1014.93 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 356.982C1009.94 356.982 1010.75 356.179 1010.75 355.188C1010.75 354.197 1009.94 353.393 1008.96 353.393C1007.97 353.393 1007.16 354.197 1007.16 355.188C1007.16 356.179 1007.97 356.982 1008.96 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 356.982C1003.97 356.982 1004.78 356.179 1004.78 355.188C1004.78 354.197 1003.97 353.393 1002.99 353.393C1002 353.393 1001.19 354.197 1001.19 355.188C1001.19 356.179 1002 356.982 1002.99 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 356.982C992.034 356.982 992.836 356.179 992.836 355.188C992.836 354.197 992.034 353.393 991.045 353.393C990.056 353.393 989.254 354.197 989.254 355.188C989.254 356.179 990.056 356.982 991.045 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 356.982C986.064 356.982 986.866 356.179 986.866 355.188C986.866 354.197 986.064 353.393 985.075 353.393C984.085 353.393 983.284 354.197 983.284 355.188C983.284 356.179 984.085 356.982 985.075 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 356.982C980.094 356.982 980.896 356.179 980.896 355.188C980.896 354.197 980.094 353.393 979.104 353.393C978.115 353.393 977.313 354.197 977.313 355.188C977.313 356.179 978.115 356.982 979.104 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 356.982C974.123 356.982 974.925 356.179 974.925 355.188C974.925 354.197 974.123 353.393 973.134 353.393C972.145 353.393 971.343 354.197 971.343 355.188C971.343 356.179 972.145 356.982 973.134 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 356.982C968.153 356.982 968.955 356.179 968.955 355.188C968.955 354.197 968.153 353.393 967.164 353.393C966.175 353.393 965.373 354.197 965.373 355.188C965.373 356.179 966.175 356.982 967.164 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 356.982C950.243 356.982 951.045 356.179 951.045 355.188C951.045 354.197 950.243 353.393 949.254 353.393C948.265 353.393 947.463 354.197 947.463 355.188C947.463 356.179 948.265 356.982 949.254 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 356.982C944.273 356.982 945.075 356.179 945.075 355.188C945.075 354.197 944.273 353.393 943.284 353.393C942.294 353.393 941.492 354.197 941.492 355.188C941.492 356.179 942.294 356.982 943.284 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 356.982C938.303 356.982 939.105 356.179 939.105 355.188C939.105 354.197 938.303 353.393 937.313 353.393C936.324 353.393 935.522 354.197 935.522 355.188C935.522 356.179 936.324 356.982 937.313 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 356.982C932.332 356.982 933.134 356.179 933.134 355.188C933.134 354.197 932.332 353.393 931.343 353.393C930.354 353.393 929.552 354.197 929.552 355.188C929.552 356.179 930.354 356.982 931.343 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 356.982C687.556 356.982 688.358 356.179 688.358 355.188C688.358 354.197 687.556 353.393 686.567 353.393C685.578 353.393 684.776 354.197 684.776 355.188C684.776 356.179 685.578 356.982 686.567 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 356.982C681.586 356.982 682.388 356.179 682.388 355.188C682.388 354.197 681.586 353.393 680.597 353.393C679.608 353.393 678.806 354.197 678.806 355.188C678.806 356.179 679.608 356.982 680.597 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 356.982C675.616 356.982 676.418 356.179 676.418 355.188C676.418 354.197 675.616 353.393 674.627 353.393C673.638 353.393 672.836 354.197 672.836 355.188C672.836 356.179 673.638 356.982 674.627 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 356.982C669.646 356.982 670.448 356.179 670.448 355.188C670.448 354.197 669.646 353.393 668.657 353.393C667.668 353.393 666.866 354.197 666.866 355.188C666.866 356.179 667.668 356.982 668.657 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 356.982C663.676 356.982 664.478 356.179 664.478 355.188C664.478 354.197 663.676 353.393 662.687 353.393C661.697 353.393 660.896 354.197 660.896 355.188C660.896 356.179 661.697 356.982 662.687 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 356.982C657.706 356.982 658.507 356.179 658.507 355.188C658.507 354.197 657.706 353.393 656.716 353.393C655.727 353.393 654.925 354.197 654.925 355.188C654.925 356.179 655.727 356.982 656.716 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 356.982C651.735 356.982 652.537 356.179 652.537 355.188C652.537 354.197 651.735 353.393 650.746 353.393C649.757 353.393 648.955 354.197 648.955 355.188C648.955 356.179 649.757 356.982 650.746 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 356.982C645.765 356.982 646.567 356.179 646.567 355.188C646.567 354.197 645.765 353.393 644.776 353.393C643.787 353.393 642.985 354.197 642.985 355.188C642.985 356.179 643.787 356.982 644.776 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 356.982C639.795 356.982 640.597 356.179 640.597 355.188C640.597 354.197 639.795 353.393 638.806 353.393C637.817 353.393 637.015 354.197 637.015 355.188C637.015 356.179 637.817 356.982 638.806 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 356.982C633.825 356.982 634.627 356.179 634.627 355.188C634.627 354.197 633.825 353.393 632.836 353.393C631.847 353.393 631.045 354.197 631.045 355.188C631.045 356.179 631.847 356.982 632.836 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 356.982C627.855 356.982 628.657 356.179 628.657 355.188C628.657 354.197 627.855 353.393 626.866 353.393C625.876 353.393 625.075 354.197 625.075 355.188C625.075 356.179 625.876 356.982 626.866 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 356.982C621.885 356.982 622.687 356.179 622.687 355.188C622.687 354.197 621.885 353.393 620.895 353.393C619.906 353.393 619.104 354.197 619.104 355.188C619.104 356.179 619.906 356.982 620.895 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 356.982C615.915 356.982 616.716 356.179 616.716 355.188C616.716 354.197 615.915 353.393 614.925 353.393C613.936 353.393 613.134 354.197 613.134 355.188C613.134 356.179 613.936 356.982 614.925 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 356.982C609.944 356.982 610.746 356.179 610.746 355.188C610.746 354.197 609.944 353.393 608.955 353.393C607.966 353.393 607.164 354.197 607.164 355.188C607.164 356.179 607.966 356.982 608.955 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 356.982C603.974 356.982 604.776 356.179 604.776 355.188C604.776 354.197 603.974 353.393 602.985 353.393C601.996 353.393 601.194 354.197 601.194 355.188C601.194 356.179 601.996 356.982 602.985 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 356.982C598.004 356.982 598.806 356.179 598.806 355.188C598.806 354.197 598.004 353.393 597.015 353.393C596.026 353.393 595.224 354.197 595.224 355.188C595.224 356.179 596.026 356.982 597.015 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 356.982C592.034 356.982 592.836 356.179 592.836 355.188C592.836 354.197 592.034 353.393 591.045 353.393C590.056 353.393 589.254 354.197 589.254 355.188C589.254 356.179 590.056 356.982 591.045 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 356.982C586.064 356.982 586.866 356.179 586.866 355.188C586.866 354.197 586.064 353.393 585.075 353.393C584.085 353.393 583.284 354.197 583.284 355.188C583.284 356.179 584.085 356.982 585.075 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 356.982C580.094 356.982 580.896 356.179 580.896 355.188C580.896 354.197 580.094 353.393 579.104 353.393C578.115 353.393 577.313 354.197 577.313 355.188C577.313 356.179 578.115 356.982 579.104 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 356.982C574.123 356.982 574.925 356.179 574.925 355.188C574.925 354.197 574.123 353.393 573.134 353.393C572.145 353.393 571.343 354.197 571.343 355.188C571.343 356.179 572.145 356.982 573.134 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 351C1057.71 351 1058.51 350.196 1058.51 349.205C1058.51 348.214 1057.71 347.411 1056.72 347.411C1055.73 347.411 1054.93 348.214 1054.93 349.205C1054.93 350.196 1055.73 351 1056.72 351Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 351C1051.74 351 1052.54 350.196 1052.54 349.205C1052.54 348.214 1051.74 347.411 1050.75 347.411C1049.76 347.411 1048.96 348.214 1048.96 349.205C1048.96 350.196 1049.76 351 1050.75 351Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 351C1015.91 351 1016.72 350.196 1016.72 349.205C1016.72 348.214 1015.91 347.411 1014.93 347.411C1013.94 347.411 1013.13 348.214 1013.13 349.205C1013.13 350.196 1013.94 351 1014.93 351Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 351C1009.94 351 1010.75 350.196 1010.75 349.205C1010.75 348.214 1009.94 347.411 1008.96 347.411C1007.97 347.411 1007.16 348.214 1007.16 349.205C1007.16 350.196 1007.97 351 1008.96 351Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 351C1003.97 351 1004.78 350.196 1004.78 349.205C1004.78 348.214 1003.97 347.411 1002.99 347.411C1002 347.411 1001.19 348.214 1001.19 349.205C1001.19 350.196 1002 351 1002.99 351Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 351C992.034 351 992.836 350.196 992.836 349.205C992.836 348.214 992.034 347.411 991.045 347.411C990.056 347.411 989.254 348.214 989.254 349.205C989.254 350.196 990.056 351 991.045 351Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 351C986.064 351 986.866 350.196 986.866 349.205C986.866 348.214 986.064 347.411 985.075 347.411C984.085 347.411 983.284 348.214 983.284 349.205C983.284 350.196 984.085 351 985.075 351Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 351C980.094 351 980.896 350.196 980.896 349.205C980.896 348.214 980.094 347.411 979.104 347.411C978.115 347.411 977.313 348.214 977.313 349.205C977.313 350.196 978.115 351 979.104 351Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 351C974.123 351 974.925 350.196 974.925 349.205C974.925 348.214 974.123 347.411 973.134 347.411C972.145 347.411 971.343 348.214 971.343 349.205C971.343 350.196 972.145 351 973.134 351Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 351C968.153 351 968.955 350.196 968.955 349.205C968.955 348.214 968.153 347.411 967.164 347.411C966.175 347.411 965.373 348.214 965.373 349.205C965.373 350.196 966.175 351 967.164 351Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 351C944.273 351 945.075 350.196 945.075 349.205C945.075 348.214 944.273 347.411 943.284 347.411C942.294 347.411 941.492 348.214 941.492 349.205C941.492 350.196 942.294 351 943.284 351Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 351C938.303 351 939.105 350.196 939.105 349.205C939.105 348.214 938.303 347.411 937.313 347.411C936.324 347.411 935.522 348.214 935.522 349.205C935.522 350.196 936.324 351 937.313 351Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 351C932.332 351 933.134 350.196 933.134 349.205C933.134 348.214 932.332 347.411 931.343 347.411C930.354 347.411 929.552 348.214 929.552 349.205C929.552 350.196 930.354 351 931.343 351Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 351C693.526 351 694.328 350.196 694.328 349.205C694.328 348.214 693.526 347.411 692.537 347.411C691.548 347.411 690.746 348.214 690.746 349.205C690.746 350.196 691.548 351 692.537 351Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 351C687.556 351 688.358 350.196 688.358 349.205C688.358 348.214 687.556 347.411 686.567 347.411C685.578 347.411 684.776 348.214 684.776 349.205C684.776 350.196 685.578 351 686.567 351Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 351C681.586 351 682.388 350.196 682.388 349.205C682.388 348.214 681.586 347.411 680.597 347.411C679.608 347.411 678.806 348.214 678.806 349.205C678.806 350.196 679.608 351 680.597 351Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 351C675.616 351 676.418 350.196 676.418 349.205C676.418 348.214 675.616 347.411 674.627 347.411C673.638 347.411 672.836 348.214 672.836 349.205C672.836 350.196 673.638 351 674.627 351Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 351C669.646 351 670.448 350.196 670.448 349.205C670.448 348.214 669.646 347.411 668.657 347.411C667.668 347.411 666.866 348.214 666.866 349.205C666.866 350.196 667.668 351 668.657 351Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 351C663.676 351 664.478 350.196 664.478 349.205C664.478 348.214 663.676 347.411 662.687 347.411C661.697 347.411 660.896 348.214 660.896 349.205C660.896 350.196 661.697 351 662.687 351Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 351C657.706 351 658.507 350.196 658.507 349.205C658.507 348.214 657.706 347.411 656.716 347.411C655.727 347.411 654.925 348.214 654.925 349.205C654.925 350.196 655.727 351 656.716 351Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 351C651.735 351 652.537 350.196 652.537 349.205C652.537 348.214 651.735 347.411 650.746 347.411C649.757 347.411 648.955 348.214 648.955 349.205C648.955 350.196 649.757 351 650.746 351Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 351C645.765 351 646.567 350.196 646.567 349.205C646.567 348.214 645.765 347.411 644.776 347.411C643.787 347.411 642.985 348.214 642.985 349.205C642.985 350.196 643.787 351 644.776 351Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 351C639.795 351 640.597 350.196 640.597 349.205C640.597 348.214 639.795 347.411 638.806 347.411C637.817 347.411 637.015 348.214 637.015 349.205C637.015 350.196 637.817 351 638.806 351Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 351C633.825 351 634.627 350.196 634.627 349.205C634.627 348.214 633.825 347.411 632.836 347.411C631.847 347.411 631.045 348.214 631.045 349.205C631.045 350.196 631.847 351 632.836 351Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 351C627.855 351 628.657 350.196 628.657 349.205C628.657 348.214 627.855 347.411 626.866 347.411C625.876 347.411 625.075 348.214 625.075 349.205C625.075 350.196 625.876 351 626.866 351Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 351C621.885 351 622.687 350.196 622.687 349.205C622.687 348.214 621.885 347.411 620.895 347.411C619.906 347.411 619.104 348.214 619.104 349.205C619.104 350.196 619.906 351 620.895 351Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 351C615.915 351 616.716 350.196 616.716 349.205C616.716 348.214 615.915 347.411 614.925 347.411C613.936 347.411 613.134 348.214 613.134 349.205C613.134 350.196 613.936 351 614.925 351Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 351C609.944 351 610.746 350.196 610.746 349.205C610.746 348.214 609.944 347.411 608.955 347.411C607.966 347.411 607.164 348.214 607.164 349.205C607.164 350.196 607.966 351 608.955 351Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 351C603.974 351 604.776 350.196 604.776 349.205C604.776 348.214 603.974 347.411 602.985 347.411C601.996 347.411 601.194 348.214 601.194 349.205C601.194 350.196 601.996 351 602.985 351Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 351C598.004 351 598.806 350.196 598.806 349.205C598.806 348.214 598.004 347.411 597.015 347.411C596.026 347.411 595.224 348.214 595.224 349.205C595.224 350.196 596.026 351 597.015 351Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 351C592.034 351 592.836 350.196 592.836 349.205C592.836 348.214 592.034 347.411 591.045 347.411C590.056 347.411 589.254 348.214 589.254 349.205C589.254 350.196 590.056 351 591.045 351Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 351C586.064 351 586.866 350.196 586.866 349.205C586.866 348.214 586.064 347.411 585.075 347.411C584.085 347.411 583.284 348.214 583.284 349.205C583.284 350.196 584.085 351 585.075 351Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 351C580.094 351 580.896 350.196 580.896 349.205C580.896 348.214 580.094 347.411 579.104 347.411C578.115 347.411 577.313 348.214 577.313 349.205C577.313 350.196 578.115 351 579.104 351Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 351C574.123 351 574.925 350.196 574.925 349.205C574.925 348.214 574.123 347.411 573.134 347.411C572.145 347.411 571.343 348.214 571.343 349.205C571.343 350.196 572.145 351 573.134 351Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 351C568.153 351 568.955 350.196 568.955 349.205C568.955 348.214 568.153 347.411 567.164 347.411C566.175 347.411 565.373 348.214 565.373 349.205C565.373 350.196 566.175 351 567.164 351Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 345.018C1039.8 345.018 1040.6 344.215 1040.6 343.223C1040.6 342.232 1039.8 341.429 1038.81 341.429C1037.82 341.429 1037.01 342.232 1037.01 343.223C1037.01 344.215 1037.82 345.018 1038.81 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 345.018C1021.88 345.018 1022.69 344.215 1022.69 343.223C1022.69 342.232 1021.88 341.429 1020.9 341.429C1019.91 341.429 1019.1 342.232 1019.1 343.223C1019.1 344.215 1019.91 345.018 1020.9 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 345.018C1009.94 345.018 1010.75 344.215 1010.75 343.223C1010.75 342.232 1009.94 341.429 1008.96 341.429C1007.97 341.429 1007.16 342.232 1007.16 343.223C1007.16 344.215 1007.97 345.018 1008.96 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 345.018C998.004 345.018 998.806 344.215 998.806 343.223C998.806 342.232 998.004 341.429 997.015 341.429C996.026 341.429 995.224 342.232 995.224 343.223C995.224 344.215 996.026 345.018 997.015 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 345.018C992.034 345.018 992.836 344.215 992.836 343.223C992.836 342.232 992.034 341.429 991.045 341.429C990.056 341.429 989.254 342.232 989.254 343.223C989.254 344.215 990.056 345.018 991.045 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 345.018C986.064 345.018 986.866 344.215 986.866 343.223C986.866 342.232 986.064 341.429 985.075 341.429C984.085 341.429 983.284 342.232 983.284 343.223C983.284 344.215 984.085 345.018 985.075 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 345.018C980.094 345.018 980.896 344.215 980.896 343.223C980.896 342.232 980.094 341.429 979.104 341.429C978.115 341.429 977.313 342.232 977.313 343.223C977.313 344.215 978.115 345.018 979.104 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 345.018C974.123 345.018 974.925 344.215 974.925 343.223C974.925 342.232 974.123 341.429 973.134 341.429C972.145 341.429 971.343 342.232 971.343 343.223C971.343 344.215 972.145 345.018 973.134 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 345.018C968.153 345.018 968.955 344.215 968.955 343.223C968.955 342.232 968.153 341.429 967.164 341.429C966.175 341.429 965.373 342.232 965.373 343.223C965.373 344.215 966.175 345.018 967.164 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 345.018C938.303 345.018 939.105 344.215 939.105 343.223C939.105 342.232 938.303 341.429 937.313 341.429C936.324 341.429 935.522 342.232 935.522 343.223C935.522 344.215 936.324 345.018 937.313 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 345.018C932.332 345.018 933.134 344.215 933.134 343.223C933.134 342.232 932.332 341.429 931.343 341.429C930.354 341.429 929.552 342.232 929.552 343.223C929.552 344.215 930.354 345.018 931.343 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 345.018C926.362 345.018 927.164 344.215 927.164 343.223C927.164 342.232 926.362 341.429 925.373 341.429C924.384 341.429 923.582 342.232 923.582 343.223C923.582 344.215 924.384 345.018 925.373 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 345.018C699.497 345.018 700.299 344.215 700.299 343.223C700.299 342.232 699.497 341.429 698.507 341.429C697.518 341.429 696.716 342.232 696.716 343.223C696.716 344.215 697.518 345.018 698.507 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 345.018C693.526 345.018 694.328 344.215 694.328 343.223C694.328 342.232 693.526 341.429 692.537 341.429C691.548 341.429 690.746 342.232 690.746 343.223C690.746 344.215 691.548 345.018 692.537 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 345.018C687.556 345.018 688.358 344.215 688.358 343.223C688.358 342.232 687.556 341.429 686.567 341.429C685.578 341.429 684.776 342.232 684.776 343.223C684.776 344.215 685.578 345.018 686.567 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 345.018C681.586 345.018 682.388 344.215 682.388 343.223C682.388 342.232 681.586 341.429 680.597 341.429C679.608 341.429 678.806 342.232 678.806 343.223C678.806 344.215 679.608 345.018 680.597 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 345.018C675.616 345.018 676.418 344.215 676.418 343.223C676.418 342.232 675.616 341.429 674.627 341.429C673.638 341.429 672.836 342.232 672.836 343.223C672.836 344.215 673.638 345.018 674.627 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 345.018C669.646 345.018 670.448 344.215 670.448 343.223C670.448 342.232 669.646 341.429 668.657 341.429C667.668 341.429 666.866 342.232 666.866 343.223C666.866 344.215 667.668 345.018 668.657 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 345.018C663.676 345.018 664.478 344.215 664.478 343.223C664.478 342.232 663.676 341.429 662.687 341.429C661.697 341.429 660.896 342.232 660.896 343.223C660.896 344.215 661.697 345.018 662.687 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 345.018C657.706 345.018 658.507 344.215 658.507 343.223C658.507 342.232 657.706 341.429 656.716 341.429C655.727 341.429 654.925 342.232 654.925 343.223C654.925 344.215 655.727 345.018 656.716 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 345.018C651.735 345.018 652.537 344.215 652.537 343.223C652.537 342.232 651.735 341.429 650.746 341.429C649.757 341.429 648.955 342.232 648.955 343.223C648.955 344.215 649.757 345.018 650.746 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 345.018C645.765 345.018 646.567 344.215 646.567 343.223C646.567 342.232 645.765 341.429 644.776 341.429C643.787 341.429 642.985 342.232 642.985 343.223C642.985 344.215 643.787 345.018 644.776 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 345.018C639.795 345.018 640.597 344.215 640.597 343.223C640.597 342.232 639.795 341.429 638.806 341.429C637.817 341.429 637.015 342.232 637.015 343.223C637.015 344.215 637.817 345.018 638.806 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 345.018C633.825 345.018 634.627 344.215 634.627 343.223C634.627 342.232 633.825 341.429 632.836 341.429C631.847 341.429 631.045 342.232 631.045 343.223C631.045 344.215 631.847 345.018 632.836 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 345.018C627.855 345.018 628.657 344.215 628.657 343.223C628.657 342.232 627.855 341.429 626.866 341.429C625.876 341.429 625.075 342.232 625.075 343.223C625.075 344.215 625.876 345.018 626.866 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 345.018C621.885 345.018 622.687 344.215 622.687 343.223C622.687 342.232 621.885 341.429 620.895 341.429C619.906 341.429 619.104 342.232 619.104 343.223C619.104 344.215 619.906 345.018 620.895 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 345.018C615.915 345.018 616.716 344.215 616.716 343.223C616.716 342.232 615.915 341.429 614.925 341.429C613.936 341.429 613.134 342.232 613.134 343.223C613.134 344.215 613.936 345.018 614.925 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 345.018C609.944 345.018 610.746 344.215 610.746 343.223C610.746 342.232 609.944 341.429 608.955 341.429C607.966 341.429 607.164 342.232 607.164 343.223C607.164 344.215 607.966 345.018 608.955 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 345.018C603.974 345.018 604.776 344.215 604.776 343.223C604.776 342.232 603.974 341.429 602.985 341.429C601.996 341.429 601.194 342.232 601.194 343.223C601.194 344.215 601.996 345.018 602.985 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 345.018C598.004 345.018 598.806 344.215 598.806 343.223C598.806 342.232 598.004 341.429 597.015 341.429C596.026 341.429 595.224 342.232 595.224 343.223C595.224 344.215 596.026 345.018 597.015 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 345.018C592.034 345.018 592.836 344.215 592.836 343.223C592.836 342.232 592.034 341.429 591.045 341.429C590.056 341.429 589.254 342.232 589.254 343.223C589.254 344.215 590.056 345.018 591.045 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 345.018C586.064 345.018 586.866 344.215 586.866 343.223C586.866 342.232 586.064 341.429 585.075 341.429C584.085 341.429 583.284 342.232 583.284 343.223C583.284 344.215 584.085 345.018 585.075 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 345.018C580.094 345.018 580.896 344.215 580.896 343.223C580.896 342.232 580.094 341.429 579.104 341.429C578.115 341.429 577.313 342.232 577.313 343.223C577.313 344.215 578.115 345.018 579.104 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 345.018C574.123 345.018 574.925 344.215 574.925 343.223C574.925 342.232 574.123 341.429 573.134 341.429C572.145 341.429 571.343 342.232 571.343 343.223C571.343 344.215 572.145 345.018 573.134 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 345.018C568.153 345.018 568.955 344.215 568.955 343.223C568.955 342.232 568.153 341.429 567.164 341.429C566.175 341.429 565.373 342.232 565.373 343.223C565.373 344.215 566.175 345.018 567.164 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 339.036C1039.8 339.036 1040.6 338.232 1040.6 337.241C1040.6 336.25 1039.8 335.446 1038.81 335.446C1037.82 335.446 1037.01 336.25 1037.01 337.241C1037.01 338.232 1037.82 339.036 1038.81 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 339.036C1027.85 339.036 1028.66 338.232 1028.66 337.241C1028.66 336.25 1027.85 335.446 1026.87 335.446C1025.88 335.446 1025.07 336.25 1025.07 337.241C1025.07 338.232 1025.88 339.036 1026.87 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 339.036C1015.91 339.036 1016.72 338.232 1016.72 337.241C1016.72 336.25 1015.91 335.446 1014.93 335.446C1013.94 335.446 1013.13 336.25 1013.13 337.241C1013.13 338.232 1013.94 339.036 1014.93 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 339.036C1009.94 339.036 1010.75 338.232 1010.75 337.241C1010.75 336.25 1009.94 335.446 1008.96 335.446C1007.97 335.446 1007.16 336.25 1007.16 337.241C1007.16 338.232 1007.97 339.036 1008.96 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 339.036C998.004 339.036 998.806 338.232 998.806 337.241C998.806 336.25 998.004 335.446 997.015 335.446C996.026 335.446 995.224 336.25 995.224 337.241C995.224 338.232 996.026 339.036 997.015 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 339.036C992.034 339.036 992.836 338.232 992.836 337.241C992.836 336.25 992.034 335.446 991.045 335.446C990.056 335.446 989.254 336.25 989.254 337.241C989.254 338.232 990.056 339.036 991.045 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 339.036C986.064 339.036 986.866 338.232 986.866 337.241C986.866 336.25 986.064 335.446 985.075 335.446C984.085 335.446 983.284 336.25 983.284 337.241C983.284 338.232 984.085 339.036 985.075 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 339.036C980.094 339.036 980.896 338.232 980.896 337.241C980.896 336.25 980.094 335.446 979.104 335.446C978.115 335.446 977.313 336.25 977.313 337.241C977.313 338.232 978.115 339.036 979.104 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 339.036C974.123 339.036 974.925 338.232 974.925 337.241C974.925 336.25 974.123 335.446 973.134 335.446C972.145 335.446 971.343 336.25 971.343 337.241C971.343 338.232 972.145 339.036 973.134 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 339.036C968.153 339.036 968.955 338.232 968.955 337.241C968.955 336.25 968.153 335.446 967.164 335.446C966.175 335.446 965.373 336.25 965.373 337.241C965.373 338.232 966.175 339.036 967.164 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 339.036C932.332 339.036 933.134 338.232 933.134 337.241C933.134 336.25 932.332 335.446 931.343 335.446C930.354 335.446 929.552 336.25 929.552 337.241C929.552 338.232 930.354 339.036 931.343 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 339.036C926.362 339.036 927.164 338.232 927.164 337.241C927.164 336.25 926.362 335.446 925.373 335.446C924.384 335.446 923.582 336.25 923.582 337.241C923.582 338.232 924.384 339.036 925.373 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 339.036C705.467 339.036 706.269 338.232 706.269 337.241C706.269 336.25 705.467 335.446 704.478 335.446C703.488 335.446 702.687 336.25 702.687 337.241C702.687 338.232 703.488 339.036 704.478 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 339.036C699.497 339.036 700.299 338.232 700.299 337.241C700.299 336.25 699.497 335.446 698.507 335.446C697.518 335.446 696.716 336.25 696.716 337.241C696.716 338.232 697.518 339.036 698.507 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 339.036C693.526 339.036 694.328 338.232 694.328 337.241C694.328 336.25 693.526 335.446 692.537 335.446C691.548 335.446 690.746 336.25 690.746 337.241C690.746 338.232 691.548 339.036 692.537 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 339.036C687.556 339.036 688.358 338.232 688.358 337.241C688.358 336.25 687.556 335.446 686.567 335.446C685.578 335.446 684.776 336.25 684.776 337.241C684.776 338.232 685.578 339.036 686.567 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 339.036C681.586 339.036 682.388 338.232 682.388 337.241C682.388 336.25 681.586 335.446 680.597 335.446C679.608 335.446 678.806 336.25 678.806 337.241C678.806 338.232 679.608 339.036 680.597 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 339.036C675.616 339.036 676.418 338.232 676.418 337.241C676.418 336.25 675.616 335.446 674.627 335.446C673.638 335.446 672.836 336.25 672.836 337.241C672.836 338.232 673.638 339.036 674.627 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 339.036C669.646 339.036 670.448 338.232 670.448 337.241C670.448 336.25 669.646 335.446 668.657 335.446C667.668 335.446 666.866 336.25 666.866 337.241C666.866 338.232 667.668 339.036 668.657 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 339.036C663.676 339.036 664.478 338.232 664.478 337.241C664.478 336.25 663.676 335.446 662.687 335.446C661.697 335.446 660.896 336.25 660.896 337.241C660.896 338.232 661.697 339.036 662.687 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 339.036C657.706 339.036 658.507 338.232 658.507 337.241C658.507 336.25 657.706 335.446 656.716 335.446C655.727 335.446 654.925 336.25 654.925 337.241C654.925 338.232 655.727 339.036 656.716 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 339.036C651.735 339.036 652.537 338.232 652.537 337.241C652.537 336.25 651.735 335.446 650.746 335.446C649.757 335.446 648.955 336.25 648.955 337.241C648.955 338.232 649.757 339.036 650.746 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 339.036C645.765 339.036 646.567 338.232 646.567 337.241C646.567 336.25 645.765 335.446 644.776 335.446C643.787 335.446 642.985 336.25 642.985 337.241C642.985 338.232 643.787 339.036 644.776 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 339.036C639.795 339.036 640.597 338.232 640.597 337.241C640.597 336.25 639.795 335.446 638.806 335.446C637.817 335.446 637.015 336.25 637.015 337.241C637.015 338.232 637.817 339.036 638.806 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 339.036C633.825 339.036 634.627 338.232 634.627 337.241C634.627 336.25 633.825 335.446 632.836 335.446C631.847 335.446 631.045 336.25 631.045 337.241C631.045 338.232 631.847 339.036 632.836 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 339.036C627.855 339.036 628.657 338.232 628.657 337.241C628.657 336.25 627.855 335.446 626.866 335.446C625.876 335.446 625.075 336.25 625.075 337.241C625.075 338.232 625.876 339.036 626.866 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 339.036C621.885 339.036 622.687 338.232 622.687 337.241C622.687 336.25 621.885 335.446 620.895 335.446C619.906 335.446 619.104 336.25 619.104 337.241C619.104 338.232 619.906 339.036 620.895 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 339.036C615.915 339.036 616.716 338.232 616.716 337.241C616.716 336.25 615.915 335.446 614.925 335.446C613.936 335.446 613.134 336.25 613.134 337.241C613.134 338.232 613.936 339.036 614.925 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 339.036C609.944 339.036 610.746 338.232 610.746 337.241C610.746 336.25 609.944 335.446 608.955 335.446C607.966 335.446 607.164 336.25 607.164 337.241C607.164 338.232 607.966 339.036 608.955 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 339.036C603.974 339.036 604.776 338.232 604.776 337.241C604.776 336.25 603.974 335.446 602.985 335.446C601.996 335.446 601.194 336.25 601.194 337.241C601.194 338.232 601.996 339.036 602.985 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 339.036C598.004 339.036 598.806 338.232 598.806 337.241C598.806 336.25 598.004 335.446 597.015 335.446C596.026 335.446 595.224 336.25 595.224 337.241C595.224 338.232 596.026 339.036 597.015 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 339.036C592.034 339.036 592.836 338.232 592.836 337.241C592.836 336.25 592.034 335.446 591.045 335.446C590.056 335.446 589.254 336.25 589.254 337.241C589.254 338.232 590.056 339.036 591.045 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 339.036C586.064 339.036 586.866 338.232 586.866 337.241C586.866 336.25 586.064 335.446 585.075 335.446C584.085 335.446 583.284 336.25 583.284 337.241C583.284 338.232 584.085 339.036 585.075 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 339.036C580.094 339.036 580.896 338.232 580.896 337.241C580.896 336.25 580.094 335.446 579.104 335.446C578.115 335.446 577.313 336.25 577.313 337.241C577.313 338.232 578.115 339.036 579.104 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 339.036C574.123 339.036 574.925 338.232 574.925 337.241C574.925 336.25 574.123 335.446 573.134 335.446C572.145 335.446 571.343 336.25 571.343 337.241C571.343 338.232 572.145 339.036 573.134 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 333.053C992.034 333.053 992.836 332.25 992.836 331.259C992.836 330.267 992.034 329.464 991.045 329.464C990.056 329.464 989.254 330.267 989.254 331.259C989.254 332.25 990.056 333.053 991.045 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 333.053C986.064 333.053 986.866 332.25 986.866 331.259C986.866 330.267 986.064 329.464 985.075 329.464C984.085 329.464 983.284 330.267 983.284 331.259C983.284 332.25 984.085 333.053 985.075 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 333.053C980.094 333.053 980.896 332.25 980.896 331.259C980.896 330.267 980.094 329.464 979.104 329.464C978.115 329.464 977.313 330.267 977.313 331.259C977.313 332.25 978.115 333.053 979.104 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 333.053C926.362 333.053 927.164 332.25 927.164 331.259C927.164 330.267 926.362 329.464 925.373 329.464C924.384 329.464 923.582 330.267 923.582 331.259C923.582 332.25 924.384 333.053 925.373 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 333.053C920.392 333.053 921.194 332.25 921.194 331.259C921.194 330.267 920.392 329.464 919.403 329.464C918.414 329.464 917.612 330.267 917.612 331.259C917.612 332.25 918.414 333.053 919.403 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 333.053C711.437 333.053 712.239 332.25 712.239 331.259C712.239 330.267 711.437 329.464 710.448 329.464C709.459 329.464 708.657 330.267 708.657 331.259C708.657 332.25 709.459 333.053 710.448 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 333.053C705.467 333.053 706.269 332.25 706.269 331.259C706.269 330.267 705.467 329.464 704.478 329.464C703.488 329.464 702.687 330.267 702.687 331.259C702.687 332.25 703.488 333.053 704.478 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 333.053C699.497 333.053 700.299 332.25 700.299 331.259C700.299 330.267 699.497 329.464 698.507 329.464C697.518 329.464 696.716 330.267 696.716 331.259C696.716 332.25 697.518 333.053 698.507 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 333.053C693.526 333.053 694.328 332.25 694.328 331.259C694.328 330.267 693.526 329.464 692.537 329.464C691.548 329.464 690.746 330.267 690.746 331.259C690.746 332.25 691.548 333.053 692.537 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 333.053C687.556 333.053 688.358 332.25 688.358 331.259C688.358 330.267 687.556 329.464 686.567 329.464C685.578 329.464 684.776 330.267 684.776 331.259C684.776 332.25 685.578 333.053 686.567 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 333.053C681.586 333.053 682.388 332.25 682.388 331.259C682.388 330.267 681.586 329.464 680.597 329.464C679.608 329.464 678.806 330.267 678.806 331.259C678.806 332.25 679.608 333.053 680.597 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 333.053C675.616 333.053 676.418 332.25 676.418 331.259C676.418 330.267 675.616 329.464 674.627 329.464C673.638 329.464 672.836 330.267 672.836 331.259C672.836 332.25 673.638 333.053 674.627 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 333.053C669.646 333.053 670.448 332.25 670.448 331.259C670.448 330.267 669.646 329.464 668.657 329.464C667.668 329.464 666.866 330.267 666.866 331.259C666.866 332.25 667.668 333.053 668.657 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 333.053C663.676 333.053 664.478 332.25 664.478 331.259C664.478 330.267 663.676 329.464 662.687 329.464C661.697 329.464 660.896 330.267 660.896 331.259C660.896 332.25 661.697 333.053 662.687 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 333.053C657.706 333.053 658.507 332.25 658.507 331.259C658.507 330.267 657.706 329.464 656.716 329.464C655.727 329.464 654.925 330.267 654.925 331.259C654.925 332.25 655.727 333.053 656.716 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 333.053C651.735 333.053 652.537 332.25 652.537 331.259C652.537 330.267 651.735 329.464 650.746 329.464C649.757 329.464 648.955 330.267 648.955 331.259C648.955 332.25 649.757 333.053 650.746 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 333.053C645.765 333.053 646.567 332.25 646.567 331.259C646.567 330.267 645.765 329.464 644.776 329.464C643.787 329.464 642.985 330.267 642.985 331.259C642.985 332.25 643.787 333.053 644.776 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 333.053C639.795 333.053 640.597 332.25 640.597 331.259C640.597 330.267 639.795 329.464 638.806 329.464C637.817 329.464 637.015 330.267 637.015 331.259C637.015 332.25 637.817 333.053 638.806 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 333.053C633.825 333.053 634.627 332.25 634.627 331.259C634.627 330.267 633.825 329.464 632.836 329.464C631.847 329.464 631.045 330.267 631.045 331.259C631.045 332.25 631.847 333.053 632.836 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 333.053C627.855 333.053 628.657 332.25 628.657 331.259C628.657 330.267 627.855 329.464 626.866 329.464C625.876 329.464 625.075 330.267 625.075 331.259C625.075 332.25 625.876 333.053 626.866 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 333.053C621.885 333.053 622.687 332.25 622.687 331.259C622.687 330.267 621.885 329.464 620.895 329.464C619.906 329.464 619.104 330.267 619.104 331.259C619.104 332.25 619.906 333.053 620.895 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 333.053C615.915 333.053 616.716 332.25 616.716 331.259C616.716 330.267 615.915 329.464 614.925 329.464C613.936 329.464 613.134 330.267 613.134 331.259C613.134 332.25 613.936 333.053 614.925 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 333.053C609.944 333.053 610.746 332.25 610.746 331.259C610.746 330.267 609.944 329.464 608.955 329.464C607.966 329.464 607.164 330.267 607.164 331.259C607.164 332.25 607.966 333.053 608.955 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 333.053C603.974 333.053 604.776 332.25 604.776 331.259C604.776 330.267 603.974 329.464 602.985 329.464C601.996 329.464 601.194 330.267 601.194 331.259C601.194 332.25 601.996 333.053 602.985 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 333.053C598.004 333.053 598.806 332.25 598.806 331.259C598.806 330.267 598.004 329.464 597.015 329.464C596.026 329.464 595.224 330.267 595.224 331.259C595.224 332.25 596.026 333.053 597.015 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 333.053C592.034 333.053 592.836 332.25 592.836 331.259C592.836 330.267 592.034 329.464 591.045 329.464C590.056 329.464 589.254 330.267 589.254 331.259C589.254 332.25 590.056 333.053 591.045 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 333.053C586.064 333.053 586.866 332.25 586.866 331.259C586.866 330.267 586.064 329.464 585.075 329.464C584.085 329.464 583.284 330.267 583.284 331.259C583.284 332.25 584.085 333.053 585.075 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 333.053C580.094 333.053 580.896 332.25 580.896 331.259C580.896 330.267 580.094 329.464 579.104 329.464C578.115 329.464 577.313 330.267 577.313 331.259C577.313 332.25 578.115 333.053 579.104 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 333.053C574.123 333.053 574.925 332.25 574.925 331.259C574.925 330.267 574.123 329.464 573.134 329.464C572.145 329.464 571.343 330.267 571.343 331.259C571.343 332.25 572.145 333.053 573.134 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 327.071C998.004 327.071 998.806 326.268 998.806 325.277C998.806 324.285 998.004 323.482 997.015 323.482C996.026 323.482 995.224 324.285 995.224 325.277C995.224 326.268 996.026 327.071 997.015 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 327.071C992.034 327.071 992.836 326.268 992.836 325.277C992.836 324.285 992.034 323.482 991.045 323.482C990.056 323.482 989.254 324.285 989.254 325.277C989.254 326.268 990.056 327.071 991.045 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 327.071C986.064 327.071 986.866 326.268 986.866 325.277C986.866 324.285 986.064 323.482 985.075 323.482C984.085 323.482 983.284 324.285 983.284 325.277C983.284 326.268 984.085 327.071 985.075 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 327.071C938.303 327.071 939.105 326.268 939.105 325.277C939.105 324.285 938.303 323.482 937.313 323.482C936.324 323.482 935.522 324.285 935.522 325.277C935.522 326.268 936.324 327.071 937.313 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 327.071C920.392 327.071 921.194 326.268 921.194 325.277C921.194 324.285 920.392 323.482 919.403 323.482C918.414 323.482 917.612 324.285 917.612 325.277C917.612 326.268 918.414 327.071 919.403 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 327.071C914.422 327.071 915.224 326.268 915.224 325.277C915.224 324.285 914.422 323.482 913.433 323.482C912.444 323.482 911.642 324.285 911.642 325.277C911.642 326.268 912.444 327.071 913.433 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 327.071C717.407 327.071 718.209 326.268 718.209 325.277C718.209 324.285 717.407 323.482 716.418 323.482C715.429 323.482 714.627 324.285 714.627 325.277C714.627 326.268 715.429 327.071 716.418 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 327.071C711.437 327.071 712.239 326.268 712.239 325.277C712.239 324.285 711.437 323.482 710.448 323.482C709.459 323.482 708.657 324.285 708.657 325.277C708.657 326.268 709.459 327.071 710.448 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 327.071C705.467 327.071 706.269 326.268 706.269 325.277C706.269 324.285 705.467 323.482 704.478 323.482C703.488 323.482 702.687 324.285 702.687 325.277C702.687 326.268 703.488 327.071 704.478 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 327.071C699.497 327.071 700.299 326.268 700.299 325.277C700.299 324.285 699.497 323.482 698.507 323.482C697.518 323.482 696.716 324.285 696.716 325.277C696.716 326.268 697.518 327.071 698.507 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 327.071C693.526 327.071 694.328 326.268 694.328 325.277C694.328 324.285 693.526 323.482 692.537 323.482C691.548 323.482 690.746 324.285 690.746 325.277C690.746 326.268 691.548 327.071 692.537 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 327.071C687.556 327.071 688.358 326.268 688.358 325.277C688.358 324.285 687.556 323.482 686.567 323.482C685.578 323.482 684.776 324.285 684.776 325.277C684.776 326.268 685.578 327.071 686.567 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 327.071C681.586 327.071 682.388 326.268 682.388 325.277C682.388 324.285 681.586 323.482 680.597 323.482C679.608 323.482 678.806 324.285 678.806 325.277C678.806 326.268 679.608 327.071 680.597 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 327.071C675.616 327.071 676.418 326.268 676.418 325.277C676.418 324.285 675.616 323.482 674.627 323.482C673.638 323.482 672.836 324.285 672.836 325.277C672.836 326.268 673.638 327.071 674.627 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 327.071C669.646 327.071 670.448 326.268 670.448 325.277C670.448 324.285 669.646 323.482 668.657 323.482C667.668 323.482 666.866 324.285 666.866 325.277C666.866 326.268 667.668 327.071 668.657 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 327.071C663.676 327.071 664.478 326.268 664.478 325.277C664.478 324.285 663.676 323.482 662.687 323.482C661.697 323.482 660.896 324.285 660.896 325.277C660.896 326.268 661.697 327.071 662.687 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 327.071C657.706 327.071 658.507 326.268 658.507 325.277C658.507 324.285 657.706 323.482 656.716 323.482C655.727 323.482 654.925 324.285 654.925 325.277C654.925 326.268 655.727 327.071 656.716 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 327.071C651.735 327.071 652.537 326.268 652.537 325.277C652.537 324.285 651.735 323.482 650.746 323.482C649.757 323.482 648.955 324.285 648.955 325.277C648.955 326.268 649.757 327.071 650.746 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 327.071C645.765 327.071 646.567 326.268 646.567 325.277C646.567 324.285 645.765 323.482 644.776 323.482C643.787 323.482 642.985 324.285 642.985 325.277C642.985 326.268 643.787 327.071 644.776 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 327.071C639.795 327.071 640.597 326.268 640.597 325.277C640.597 324.285 639.795 323.482 638.806 323.482C637.817 323.482 637.015 324.285 637.015 325.277C637.015 326.268 637.817 327.071 638.806 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 327.071C633.825 327.071 634.627 326.268 634.627 325.277C634.627 324.285 633.825 323.482 632.836 323.482C631.847 323.482 631.045 324.285 631.045 325.277C631.045 326.268 631.847 327.071 632.836 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 327.071C627.855 327.071 628.657 326.268 628.657 325.277C628.657 324.285 627.855 323.482 626.866 323.482C625.876 323.482 625.075 324.285 625.075 325.277C625.075 326.268 625.876 327.071 626.866 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 327.071C621.885 327.071 622.687 326.268 622.687 325.277C622.687 324.285 621.885 323.482 620.895 323.482C619.906 323.482 619.104 324.285 619.104 325.277C619.104 326.268 619.906 327.071 620.895 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 327.071C615.915 327.071 616.716 326.268 616.716 325.277C616.716 324.285 615.915 323.482 614.925 323.482C613.936 323.482 613.134 324.285 613.134 325.277C613.134 326.268 613.936 327.071 614.925 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 327.071C609.944 327.071 610.746 326.268 610.746 325.277C610.746 324.285 609.944 323.482 608.955 323.482C607.966 323.482 607.164 324.285 607.164 325.277C607.164 326.268 607.966 327.071 608.955 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 327.071C603.974 327.071 604.776 326.268 604.776 325.277C604.776 324.285 603.974 323.482 602.985 323.482C601.996 323.482 601.194 324.285 601.194 325.277C601.194 326.268 601.996 327.071 602.985 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 327.071C598.004 327.071 598.806 326.268 598.806 325.277C598.806 324.285 598.004 323.482 597.015 323.482C596.026 323.482 595.224 324.285 595.224 325.277C595.224 326.268 596.026 327.071 597.015 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 327.071C592.034 327.071 592.836 326.268 592.836 325.277C592.836 324.285 592.034 323.482 591.045 323.482C590.056 323.482 589.254 324.285 589.254 325.277C589.254 326.268 590.056 327.071 591.045 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 327.071C586.064 327.071 586.866 326.268 586.866 325.277C586.866 324.285 586.064 323.482 585.075 323.482C584.085 323.482 583.284 324.285 583.284 325.277C583.284 326.268 584.085 327.071 585.075 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 327.071C580.094 327.071 580.896 326.268 580.896 325.277C580.896 324.285 580.094 323.482 579.104 323.482C578.115 323.482 577.313 324.285 577.313 325.277C577.313 326.268 578.115 327.071 579.104 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 327.071C574.123 327.071 574.925 326.268 574.925 325.277C574.925 324.285 574.123 323.482 573.134 323.482C572.145 323.482 571.343 324.285 571.343 325.277C571.343 326.268 572.145 327.071 573.134 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 327.071C568.153 327.071 568.955 326.268 568.955 325.277C568.955 324.285 568.153 323.482 567.164 323.482C566.175 323.482 565.373 324.285 565.373 325.277C565.373 326.268 566.175 327.071 567.164 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 321.089C998.004 321.089 998.806 320.285 998.806 319.294C998.806 318.303 998.004 317.5 997.015 317.5C996.026 317.5 995.224 318.303 995.224 319.294C995.224 320.285 996.026 321.089 997.015 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 321.089C992.034 321.089 992.836 320.285 992.836 319.294C992.836 318.303 992.034 317.5 991.045 317.5C990.056 317.5 989.254 318.303 989.254 319.294C989.254 320.285 990.056 321.089 991.045 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 321.089C938.303 321.089 939.105 320.285 939.105 319.294C939.105 318.303 938.303 317.5 937.313 317.5C936.324 317.5 935.522 318.303 935.522 319.294C935.522 320.285 936.324 321.089 937.313 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 321.089C932.332 321.089 933.134 320.285 933.134 319.294C933.134 318.303 932.332 317.5 931.343 317.5C930.354 317.5 929.552 318.303 929.552 319.294C929.552 320.285 930.354 321.089 931.343 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 321.089C723.377 321.089 724.179 320.285 724.179 319.294C724.179 318.303 723.377 317.5 722.388 317.5C721.399 317.5 720.597 318.303 720.597 319.294C720.597 320.285 721.399 321.089 722.388 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 321.089C717.407 321.089 718.209 320.285 718.209 319.294C718.209 318.303 717.407 317.5 716.418 317.5C715.429 317.5 714.627 318.303 714.627 319.294C714.627 320.285 715.429 321.089 716.418 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 321.089C711.437 321.089 712.239 320.285 712.239 319.294C712.239 318.303 711.437 317.5 710.448 317.5C709.459 317.5 708.657 318.303 708.657 319.294C708.657 320.285 709.459 321.089 710.448 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 321.089C705.467 321.089 706.269 320.285 706.269 319.294C706.269 318.303 705.467 317.5 704.478 317.5C703.488 317.5 702.687 318.303 702.687 319.294C702.687 320.285 703.488 321.089 704.478 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 321.089C699.497 321.089 700.299 320.285 700.299 319.294C700.299 318.303 699.497 317.5 698.507 317.5C697.518 317.5 696.716 318.303 696.716 319.294C696.716 320.285 697.518 321.089 698.507 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 321.089C693.526 321.089 694.328 320.285 694.328 319.294C694.328 318.303 693.526 317.5 692.537 317.5C691.548 317.5 690.746 318.303 690.746 319.294C690.746 320.285 691.548 321.089 692.537 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 321.089C687.556 321.089 688.358 320.285 688.358 319.294C688.358 318.303 687.556 317.5 686.567 317.5C685.578 317.5 684.776 318.303 684.776 319.294C684.776 320.285 685.578 321.089 686.567 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 321.089C681.586 321.089 682.388 320.285 682.388 319.294C682.388 318.303 681.586 317.5 680.597 317.5C679.608 317.5 678.806 318.303 678.806 319.294C678.806 320.285 679.608 321.089 680.597 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 321.089C675.616 321.089 676.418 320.285 676.418 319.294C676.418 318.303 675.616 317.5 674.627 317.5C673.638 317.5 672.836 318.303 672.836 319.294C672.836 320.285 673.638 321.089 674.627 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 321.089C669.646 321.089 670.448 320.285 670.448 319.294C670.448 318.303 669.646 317.5 668.657 317.5C667.668 317.5 666.866 318.303 666.866 319.294C666.866 320.285 667.668 321.089 668.657 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 321.089C663.676 321.089 664.478 320.285 664.478 319.294C664.478 318.303 663.676 317.5 662.687 317.5C661.697 317.5 660.896 318.303 660.896 319.294C660.896 320.285 661.697 321.089 662.687 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 321.089C657.706 321.089 658.507 320.285 658.507 319.294C658.507 318.303 657.706 317.5 656.716 317.5C655.727 317.5 654.925 318.303 654.925 319.294C654.925 320.285 655.727 321.089 656.716 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 321.089C651.735 321.089 652.537 320.285 652.537 319.294C652.537 318.303 651.735 317.5 650.746 317.5C649.757 317.5 648.955 318.303 648.955 319.294C648.955 320.285 649.757 321.089 650.746 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 321.089C645.765 321.089 646.567 320.285 646.567 319.294C646.567 318.303 645.765 317.5 644.776 317.5C643.787 317.5 642.985 318.303 642.985 319.294C642.985 320.285 643.787 321.089 644.776 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 321.089C639.795 321.089 640.597 320.285 640.597 319.294C640.597 318.303 639.795 317.5 638.806 317.5C637.817 317.5 637.015 318.303 637.015 319.294C637.015 320.285 637.817 321.089 638.806 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 321.089C633.825 321.089 634.627 320.285 634.627 319.294C634.627 318.303 633.825 317.5 632.836 317.5C631.847 317.5 631.045 318.303 631.045 319.294C631.045 320.285 631.847 321.089 632.836 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 321.089C627.855 321.089 628.657 320.285 628.657 319.294C628.657 318.303 627.855 317.5 626.866 317.5C625.876 317.5 625.075 318.303 625.075 319.294C625.075 320.285 625.876 321.089 626.866 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 321.089C621.885 321.089 622.687 320.285 622.687 319.294C622.687 318.303 621.885 317.5 620.895 317.5C619.906 317.5 619.104 318.303 619.104 319.294C619.104 320.285 619.906 321.089 620.895 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 321.089C615.915 321.089 616.716 320.285 616.716 319.294C616.716 318.303 615.915 317.5 614.925 317.5C613.936 317.5 613.134 318.303 613.134 319.294C613.134 320.285 613.936 321.089 614.925 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 321.089C609.944 321.089 610.746 320.285 610.746 319.294C610.746 318.303 609.944 317.5 608.955 317.5C607.966 317.5 607.164 318.303 607.164 319.294C607.164 320.285 607.966 321.089 608.955 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 321.089C603.974 321.089 604.776 320.285 604.776 319.294C604.776 318.303 603.974 317.5 602.985 317.5C601.996 317.5 601.194 318.303 601.194 319.294C601.194 320.285 601.996 321.089 602.985 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 321.089C598.004 321.089 598.806 320.285 598.806 319.294C598.806 318.303 598.004 317.5 597.015 317.5C596.026 317.5 595.224 318.303 595.224 319.294C595.224 320.285 596.026 321.089 597.015 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 321.089C592.034 321.089 592.836 320.285 592.836 319.294C592.836 318.303 592.034 317.5 591.045 317.5C590.056 317.5 589.254 318.303 589.254 319.294C589.254 320.285 590.056 321.089 591.045 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 321.089C586.064 321.089 586.866 320.285 586.866 319.294C586.866 318.303 586.064 317.5 585.075 317.5C584.085 317.5 583.284 318.303 583.284 319.294C583.284 320.285 584.085 321.089 585.075 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 321.089C580.094 321.089 580.896 320.285 580.896 319.294C580.896 318.303 580.094 317.5 579.104 317.5C578.115 317.5 577.313 318.303 577.313 319.294C577.313 320.285 578.115 321.089 579.104 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 321.089C574.123 321.089 574.925 320.285 574.925 319.294C574.925 318.303 574.123 317.5 573.134 317.5C572.145 317.5 571.343 318.303 571.343 319.294C571.343 320.285 572.145 321.089 573.134 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 321.089C568.153 321.089 568.955 320.285 568.955 319.294C568.955 318.303 568.153 317.5 567.164 317.5C566.175 317.5 565.373 318.303 565.373 319.294C565.373 320.285 566.175 321.089 567.164 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 321.089C562.183 321.089 562.985 320.285 562.985 319.294C562.985 318.303 562.183 317.5 561.194 317.5C560.205 317.5 559.403 318.303 559.403 319.294C559.403 320.285 560.205 321.089 561.194 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 321.089C556.213 321.089 557.015 320.285 557.015 319.294C557.015 318.303 556.213 317.5 555.224 317.5C554.235 317.5 553.433 318.303 553.433 319.294C553.433 320.285 554.235 321.089 555.224 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 321.089C532.332 321.089 533.134 320.285 533.134 319.294C533.134 318.303 532.332 317.5 531.343 317.5C530.354 317.5 529.552 318.303 529.552 319.294C529.552 320.285 530.354 321.089 531.343 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 321.089C526.362 321.089 527.164 320.285 527.164 319.294C527.164 318.303 526.362 317.5 525.373 317.5C524.384 317.5 523.582 318.303 523.582 319.294C523.582 320.285 524.384 321.089 525.373 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 321.089C520.392 321.089 521.194 320.285 521.194 319.294C521.194 318.303 520.392 317.5 519.403 317.5C518.414 317.5 517.612 318.303 517.612 319.294C517.612 320.285 518.414 321.089 519.403 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 321.089C514.422 321.089 515.224 320.285 515.224 319.294C515.224 318.303 514.422 317.5 513.433 317.5C512.444 317.5 511.642 318.303 511.642 319.294C511.642 320.285 512.444 321.089 513.433 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 321.089C508.452 321.089 509.254 320.285 509.254 319.294C509.254 318.303 508.452 317.5 507.463 317.5C506.474 317.5 505.672 318.303 505.672 319.294C505.672 320.285 506.474 321.089 507.463 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 321.089C502.482 321.089 503.284 320.285 503.284 319.294C503.284 318.303 502.482 317.5 501.493 317.5C500.503 317.5 499.702 318.303 499.702 319.294C499.702 320.285 500.503 321.089 501.493 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 321.089C496.512 321.089 497.313 320.285 497.313 319.294C497.313 318.303 496.512 317.5 495.522 317.5C494.533 317.5 493.731 318.303 493.731 319.294C493.731 320.285 494.533 321.089 495.522 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 315.106C1027.85 315.106 1028.66 314.303 1028.66 313.312C1028.66 312.321 1027.85 311.517 1026.87 311.517C1025.88 311.517 1025.07 312.321 1025.07 313.312C1025.07 314.303 1025.88 315.106 1026.87 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 315.106C1021.88 315.106 1022.69 314.303 1022.69 313.312C1022.69 312.321 1021.88 311.517 1020.9 311.517C1019.91 311.517 1019.1 312.321 1019.1 313.312C1019.1 314.303 1019.91 315.106 1020.9 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 315.106C932.332 315.106 933.134 314.303 933.134 313.312C933.134 312.321 932.332 311.517 931.343 311.517C930.354 311.517 929.552 312.321 929.552 313.312C929.552 314.303 930.354 315.106 931.343 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 315.106C926.362 315.106 927.164 314.303 927.164 313.312C927.164 312.321 926.362 311.517 925.373 311.517C924.384 311.517 923.582 312.321 923.582 313.312C923.582 314.303 924.384 315.106 925.373 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 315.106C854.721 315.106 855.522 314.303 855.522 313.312C855.522 312.321 854.721 311.517 853.731 311.517C852.742 311.517 851.94 312.321 851.94 313.312C851.94 314.303 852.742 315.106 853.731 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 315.106C848.75 315.106 849.552 314.303 849.552 313.312C849.552 312.321 848.75 311.517 847.761 311.517C846.772 311.517 845.97 312.321 845.97 313.312C845.97 314.303 846.772 315.106 847.761 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 315.106C723.377 315.106 724.179 314.303 724.179 313.312C724.179 312.321 723.377 311.517 722.388 311.517C721.399 311.517 720.597 312.321 720.597 313.312C720.597 314.303 721.399 315.106 722.388 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 315.106C717.407 315.106 718.209 314.303 718.209 313.312C718.209 312.321 717.407 311.517 716.418 311.517C715.429 311.517 714.627 312.321 714.627 313.312C714.627 314.303 715.429 315.106 716.418 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 315.106C711.437 315.106 712.239 314.303 712.239 313.312C712.239 312.321 711.437 311.517 710.448 311.517C709.459 311.517 708.657 312.321 708.657 313.312C708.657 314.303 709.459 315.106 710.448 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 315.106C705.467 315.106 706.269 314.303 706.269 313.312C706.269 312.321 705.467 311.517 704.478 311.517C703.488 311.517 702.687 312.321 702.687 313.312C702.687 314.303 703.488 315.106 704.478 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 315.106C699.497 315.106 700.299 314.303 700.299 313.312C700.299 312.321 699.497 311.517 698.507 311.517C697.518 311.517 696.716 312.321 696.716 313.312C696.716 314.303 697.518 315.106 698.507 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 315.106C693.526 315.106 694.328 314.303 694.328 313.312C694.328 312.321 693.526 311.517 692.537 311.517C691.548 311.517 690.746 312.321 690.746 313.312C690.746 314.303 691.548 315.106 692.537 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 315.106C687.556 315.106 688.358 314.303 688.358 313.312C688.358 312.321 687.556 311.517 686.567 311.517C685.578 311.517 684.776 312.321 684.776 313.312C684.776 314.303 685.578 315.106 686.567 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 315.106C681.586 315.106 682.388 314.303 682.388 313.312C682.388 312.321 681.586 311.517 680.597 311.517C679.608 311.517 678.806 312.321 678.806 313.312C678.806 314.303 679.608 315.106 680.597 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 315.106C675.616 315.106 676.418 314.303 676.418 313.312C676.418 312.321 675.616 311.517 674.627 311.517C673.638 311.517 672.836 312.321 672.836 313.312C672.836 314.303 673.638 315.106 674.627 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 315.106C669.646 315.106 670.448 314.303 670.448 313.312C670.448 312.321 669.646 311.517 668.657 311.517C667.668 311.517 666.866 312.321 666.866 313.312C666.866 314.303 667.668 315.106 668.657 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 315.106C663.676 315.106 664.478 314.303 664.478 313.312C664.478 312.321 663.676 311.517 662.687 311.517C661.697 311.517 660.896 312.321 660.896 313.312C660.896 314.303 661.697 315.106 662.687 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 315.106C657.706 315.106 658.507 314.303 658.507 313.312C658.507 312.321 657.706 311.517 656.716 311.517C655.727 311.517 654.925 312.321 654.925 313.312C654.925 314.303 655.727 315.106 656.716 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 315.106C651.735 315.106 652.537 314.303 652.537 313.312C652.537 312.321 651.735 311.517 650.746 311.517C649.757 311.517 648.955 312.321 648.955 313.312C648.955 314.303 649.757 315.106 650.746 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 315.106C645.765 315.106 646.567 314.303 646.567 313.312C646.567 312.321 645.765 311.517 644.776 311.517C643.787 311.517 642.985 312.321 642.985 313.312C642.985 314.303 643.787 315.106 644.776 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 315.106C639.795 315.106 640.597 314.303 640.597 313.312C640.597 312.321 639.795 311.517 638.806 311.517C637.817 311.517 637.015 312.321 637.015 313.312C637.015 314.303 637.817 315.106 638.806 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 315.106C633.825 315.106 634.627 314.303 634.627 313.312C634.627 312.321 633.825 311.517 632.836 311.517C631.847 311.517 631.045 312.321 631.045 313.312C631.045 314.303 631.847 315.106 632.836 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 315.106C627.855 315.106 628.657 314.303 628.657 313.312C628.657 312.321 627.855 311.517 626.866 311.517C625.876 311.517 625.075 312.321 625.075 313.312C625.075 314.303 625.876 315.106 626.866 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 315.106C621.885 315.106 622.687 314.303 622.687 313.312C622.687 312.321 621.885 311.517 620.895 311.517C619.906 311.517 619.104 312.321 619.104 313.312C619.104 314.303 619.906 315.106 620.895 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 315.106C615.915 315.106 616.716 314.303 616.716 313.312C616.716 312.321 615.915 311.517 614.925 311.517C613.936 311.517 613.134 312.321 613.134 313.312C613.134 314.303 613.936 315.106 614.925 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 315.106C609.944 315.106 610.746 314.303 610.746 313.312C610.746 312.321 609.944 311.517 608.955 311.517C607.966 311.517 607.164 312.321 607.164 313.312C607.164 314.303 607.966 315.106 608.955 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 315.106C603.974 315.106 604.776 314.303 604.776 313.312C604.776 312.321 603.974 311.517 602.985 311.517C601.996 311.517 601.194 312.321 601.194 313.312C601.194 314.303 601.996 315.106 602.985 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 315.106C598.004 315.106 598.806 314.303 598.806 313.312C598.806 312.321 598.004 311.517 597.015 311.517C596.026 311.517 595.224 312.321 595.224 313.312C595.224 314.303 596.026 315.106 597.015 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 315.106C592.034 315.106 592.836 314.303 592.836 313.312C592.836 312.321 592.034 311.517 591.045 311.517C590.056 311.517 589.254 312.321 589.254 313.312C589.254 314.303 590.056 315.106 591.045 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 315.106C586.064 315.106 586.866 314.303 586.866 313.312C586.866 312.321 586.064 311.517 585.075 311.517C584.085 311.517 583.284 312.321 583.284 313.312C583.284 314.303 584.085 315.106 585.075 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 315.106C580.094 315.106 580.896 314.303 580.896 313.312C580.896 312.321 580.094 311.517 579.104 311.517C578.115 311.517 577.313 312.321 577.313 313.312C577.313 314.303 578.115 315.106 579.104 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 315.106C574.123 315.106 574.925 314.303 574.925 313.312C574.925 312.321 574.123 311.517 573.134 311.517C572.145 311.517 571.343 312.321 571.343 313.312C571.343 314.303 572.145 315.106 573.134 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 315.106C568.153 315.106 568.955 314.303 568.955 313.312C568.955 312.321 568.153 311.517 567.164 311.517C566.175 311.517 565.373 312.321 565.373 313.312C565.373 314.303 566.175 315.106 567.164 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 315.106C562.183 315.106 562.985 314.303 562.985 313.312C562.985 312.321 562.183 311.517 561.194 311.517C560.205 311.517 559.403 312.321 559.403 313.312C559.403 314.303 560.205 315.106 561.194 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 315.106C556.213 315.106 557.015 314.303 557.015 313.312C557.015 312.321 556.213 311.517 555.224 311.517C554.235 311.517 553.433 312.321 553.433 313.312C553.433 314.303 554.235 315.106 555.224 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 315.106C550.243 315.106 551.045 314.303 551.045 313.312C551.045 312.321 550.243 311.517 549.254 311.517C548.265 311.517 547.463 312.321 547.463 313.312C547.463 314.303 548.265 315.106 549.254 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 315.106C544.273 315.106 545.075 314.303 545.075 313.312C545.075 312.321 544.273 311.517 543.284 311.517C542.294 311.517 541.492 312.321 541.492 313.312C541.492 314.303 542.294 315.106 543.284 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 315.106C538.303 315.106 539.104 314.303 539.104 313.312C539.104 312.321 538.303 311.517 537.313 311.517C536.324 311.517 535.522 312.321 535.522 313.312C535.522 314.303 536.324 315.106 537.313 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 315.106C532.332 315.106 533.134 314.303 533.134 313.312C533.134 312.321 532.332 311.517 531.343 311.517C530.354 311.517 529.552 312.321 529.552 313.312C529.552 314.303 530.354 315.106 531.343 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 315.106C526.362 315.106 527.164 314.303 527.164 313.312C527.164 312.321 526.362 311.517 525.373 311.517C524.384 311.517 523.582 312.321 523.582 313.312C523.582 314.303 524.384 315.106 525.373 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 315.106C520.392 315.106 521.194 314.303 521.194 313.312C521.194 312.321 520.392 311.517 519.403 311.517C518.414 311.517 517.612 312.321 517.612 313.312C517.612 314.303 518.414 315.106 519.403 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 315.106C514.422 315.106 515.224 314.303 515.224 313.312C515.224 312.321 514.422 311.517 513.433 311.517C512.444 311.517 511.642 312.321 511.642 313.312C511.642 314.303 512.444 315.106 513.433 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 315.106C508.452 315.106 509.254 314.303 509.254 313.312C509.254 312.321 508.452 311.517 507.463 311.517C506.474 311.517 505.672 312.321 505.672 313.312C505.672 314.303 506.474 315.106 507.463 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 315.106C502.482 315.106 503.284 314.303 503.284 313.312C503.284 312.321 502.482 311.517 501.493 311.517C500.503 311.517 499.702 312.321 499.702 313.312C499.702 314.303 500.503 315.106 501.493 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 315.106C496.512 315.106 497.313 314.303 497.313 313.312C497.313 312.321 496.512 311.517 495.522 311.517C494.533 311.517 493.731 312.321 493.731 313.312C493.731 314.303 494.533 315.106 495.522 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 315.106C490.541 315.106 491.343 314.303 491.343 313.312C491.343 312.321 490.541 311.517 489.552 311.517C488.563 311.517 487.761 312.321 487.761 313.312C487.761 314.303 488.563 315.106 489.552 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 315.106C484.571 315.106 485.373 314.303 485.373 313.312C485.373 312.321 484.571 311.517 483.582 311.517C482.593 311.517 481.791 312.321 481.791 313.312C481.791 314.303 482.593 315.106 483.582 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 309.125C1027.85 309.125 1028.66 308.321 1028.66 307.33C1028.66 306.339 1027.85 305.535 1026.87 305.535C1025.88 305.535 1025.07 306.339 1025.07 307.33C1025.07 308.321 1025.88 309.125 1026.87 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 309.125C1021.88 309.125 1022.69 308.321 1022.69 307.33C1022.69 306.339 1021.88 305.535 1020.9 305.535C1019.91 305.535 1019.1 306.339 1019.1 307.33C1019.1 308.321 1019.91 309.125 1020.9 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 309.125C1015.91 309.125 1016.72 308.321 1016.72 307.33C1016.72 306.339 1015.91 305.535 1014.93 305.535C1013.94 305.535 1013.13 306.339 1013.13 307.33C1013.13 308.321 1013.94 309.125 1014.93 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 309.125C926.362 309.125 927.164 308.321 927.164 307.33C927.164 306.339 926.362 305.535 925.373 305.535C924.384 305.535 923.582 306.339 923.582 307.33C923.582 308.321 924.384 309.125 925.373 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 309.125C920.392 309.125 921.194 308.321 921.194 307.33C921.194 306.339 920.392 305.535 919.403 305.535C918.414 305.535 917.612 306.339 917.612 307.33C917.612 308.321 918.414 309.125 919.403 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 309.125C848.75 309.125 849.552 308.321 849.552 307.33C849.552 306.339 848.75 305.535 847.761 305.535C846.772 305.535 845.97 306.339 845.97 307.33C845.97 308.321 846.772 309.125 847.761 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 309.125C836.81 309.125 837.612 308.321 837.612 307.33C837.612 306.339 836.81 305.535 835.821 305.535C834.832 305.535 834.03 306.339 834.03 307.33C834.03 308.321 834.832 309.125 835.821 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 309.125C729.347 309.125 730.149 308.321 730.149 307.33C730.149 306.339 729.347 305.535 728.358 305.535C727.369 305.535 726.567 306.339 726.567 307.33C726.567 308.321 727.369 309.125 728.358 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 309.125C723.377 309.125 724.179 308.321 724.179 307.33C724.179 306.339 723.377 305.535 722.388 305.535C721.399 305.535 720.597 306.339 720.597 307.33C720.597 308.321 721.399 309.125 722.388 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 309.125C717.407 309.125 718.209 308.321 718.209 307.33C718.209 306.339 717.407 305.535 716.418 305.535C715.429 305.535 714.627 306.339 714.627 307.33C714.627 308.321 715.429 309.125 716.418 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 309.125C711.437 309.125 712.239 308.321 712.239 307.33C712.239 306.339 711.437 305.535 710.448 305.535C709.459 305.535 708.657 306.339 708.657 307.33C708.657 308.321 709.459 309.125 710.448 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 309.125C705.467 309.125 706.269 308.321 706.269 307.33C706.269 306.339 705.467 305.535 704.478 305.535C703.488 305.535 702.687 306.339 702.687 307.33C702.687 308.321 703.488 309.125 704.478 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 309.125C699.497 309.125 700.299 308.321 700.299 307.33C700.299 306.339 699.497 305.535 698.507 305.535C697.518 305.535 696.716 306.339 696.716 307.33C696.716 308.321 697.518 309.125 698.507 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 309.125C693.526 309.125 694.328 308.321 694.328 307.33C694.328 306.339 693.526 305.535 692.537 305.535C691.548 305.535 690.746 306.339 690.746 307.33C690.746 308.321 691.548 309.125 692.537 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 309.125C687.556 309.125 688.358 308.321 688.358 307.33C688.358 306.339 687.556 305.535 686.567 305.535C685.578 305.535 684.776 306.339 684.776 307.33C684.776 308.321 685.578 309.125 686.567 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 309.125C681.586 309.125 682.388 308.321 682.388 307.33C682.388 306.339 681.586 305.535 680.597 305.535C679.608 305.535 678.806 306.339 678.806 307.33C678.806 308.321 679.608 309.125 680.597 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 309.125C675.616 309.125 676.418 308.321 676.418 307.33C676.418 306.339 675.616 305.535 674.627 305.535C673.638 305.535 672.836 306.339 672.836 307.33C672.836 308.321 673.638 309.125 674.627 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 309.125C669.646 309.125 670.448 308.321 670.448 307.33C670.448 306.339 669.646 305.535 668.657 305.535C667.668 305.535 666.866 306.339 666.866 307.33C666.866 308.321 667.668 309.125 668.657 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 309.125C663.676 309.125 664.478 308.321 664.478 307.33C664.478 306.339 663.676 305.535 662.687 305.535C661.697 305.535 660.896 306.339 660.896 307.33C660.896 308.321 661.697 309.125 662.687 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 309.125C657.706 309.125 658.507 308.321 658.507 307.33C658.507 306.339 657.706 305.535 656.716 305.535C655.727 305.535 654.925 306.339 654.925 307.33C654.925 308.321 655.727 309.125 656.716 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 309.125C651.735 309.125 652.537 308.321 652.537 307.33C652.537 306.339 651.735 305.535 650.746 305.535C649.757 305.535 648.955 306.339 648.955 307.33C648.955 308.321 649.757 309.125 650.746 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 309.125C645.765 309.125 646.567 308.321 646.567 307.33C646.567 306.339 645.765 305.535 644.776 305.535C643.787 305.535 642.985 306.339 642.985 307.33C642.985 308.321 643.787 309.125 644.776 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 309.125C639.795 309.125 640.597 308.321 640.597 307.33C640.597 306.339 639.795 305.535 638.806 305.535C637.817 305.535 637.015 306.339 637.015 307.33C637.015 308.321 637.817 309.125 638.806 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 309.125C633.825 309.125 634.627 308.321 634.627 307.33C634.627 306.339 633.825 305.535 632.836 305.535C631.847 305.535 631.045 306.339 631.045 307.33C631.045 308.321 631.847 309.125 632.836 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 309.125C627.855 309.125 628.657 308.321 628.657 307.33C628.657 306.339 627.855 305.535 626.866 305.535C625.876 305.535 625.075 306.339 625.075 307.33C625.075 308.321 625.876 309.125 626.866 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 309.125C621.885 309.125 622.687 308.321 622.687 307.33C622.687 306.339 621.885 305.535 620.895 305.535C619.906 305.535 619.104 306.339 619.104 307.33C619.104 308.321 619.906 309.125 620.895 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 309.125C615.915 309.125 616.716 308.321 616.716 307.33C616.716 306.339 615.915 305.535 614.925 305.535C613.936 305.535 613.134 306.339 613.134 307.33C613.134 308.321 613.936 309.125 614.925 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 309.125C609.944 309.125 610.746 308.321 610.746 307.33C610.746 306.339 609.944 305.535 608.955 305.535C607.966 305.535 607.164 306.339 607.164 307.33C607.164 308.321 607.966 309.125 608.955 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 309.125C603.974 309.125 604.776 308.321 604.776 307.33C604.776 306.339 603.974 305.535 602.985 305.535C601.996 305.535 601.194 306.339 601.194 307.33C601.194 308.321 601.996 309.125 602.985 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 309.125C598.004 309.125 598.806 308.321 598.806 307.33C598.806 306.339 598.004 305.535 597.015 305.535C596.026 305.535 595.224 306.339 595.224 307.33C595.224 308.321 596.026 309.125 597.015 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 309.125C592.034 309.125 592.836 308.321 592.836 307.33C592.836 306.339 592.034 305.535 591.045 305.535C590.056 305.535 589.254 306.339 589.254 307.33C589.254 308.321 590.056 309.125 591.045 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 309.125C586.064 309.125 586.866 308.321 586.866 307.33C586.866 306.339 586.064 305.535 585.075 305.535C584.085 305.535 583.284 306.339 583.284 307.33C583.284 308.321 584.085 309.125 585.075 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 309.125C580.094 309.125 580.896 308.321 580.896 307.33C580.896 306.339 580.094 305.535 579.104 305.535C578.115 305.535 577.313 306.339 577.313 307.33C577.313 308.321 578.115 309.125 579.104 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 309.125C574.123 309.125 574.925 308.321 574.925 307.33C574.925 306.339 574.123 305.535 573.134 305.535C572.145 305.535 571.343 306.339 571.343 307.33C571.343 308.321 572.145 309.125 573.134 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 309.125C568.153 309.125 568.955 308.321 568.955 307.33C568.955 306.339 568.153 305.535 567.164 305.535C566.175 305.535 565.373 306.339 565.373 307.33C565.373 308.321 566.175 309.125 567.164 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 309.125C562.183 309.125 562.985 308.321 562.985 307.33C562.985 306.339 562.183 305.535 561.194 305.535C560.205 305.535 559.403 306.339 559.403 307.33C559.403 308.321 560.205 309.125 561.194 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 309.125C556.213 309.125 557.015 308.321 557.015 307.33C557.015 306.339 556.213 305.535 555.224 305.535C554.235 305.535 553.433 306.339 553.433 307.33C553.433 308.321 554.235 309.125 555.224 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 309.125C550.243 309.125 551.045 308.321 551.045 307.33C551.045 306.339 550.243 305.535 549.254 305.535C548.265 305.535 547.463 306.339 547.463 307.33C547.463 308.321 548.265 309.125 549.254 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 309.125C544.273 309.125 545.075 308.321 545.075 307.33C545.075 306.339 544.273 305.535 543.284 305.535C542.294 305.535 541.492 306.339 541.492 307.33C541.492 308.321 542.294 309.125 543.284 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 309.125C538.303 309.125 539.104 308.321 539.104 307.33C539.104 306.339 538.303 305.535 537.313 305.535C536.324 305.535 535.522 306.339 535.522 307.33C535.522 308.321 536.324 309.125 537.313 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 309.125C532.332 309.125 533.134 308.321 533.134 307.33C533.134 306.339 532.332 305.535 531.343 305.535C530.354 305.535 529.552 306.339 529.552 307.33C529.552 308.321 530.354 309.125 531.343 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 309.125C526.362 309.125 527.164 308.321 527.164 307.33C527.164 306.339 526.362 305.535 525.373 305.535C524.384 305.535 523.582 306.339 523.582 307.33C523.582 308.321 524.384 309.125 525.373 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 309.125C520.392 309.125 521.194 308.321 521.194 307.33C521.194 306.339 520.392 305.535 519.403 305.535C518.414 305.535 517.612 306.339 517.612 307.33C517.612 308.321 518.414 309.125 519.403 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 309.125C514.422 309.125 515.224 308.321 515.224 307.33C515.224 306.339 514.422 305.535 513.433 305.535C512.444 305.535 511.642 306.339 511.642 307.33C511.642 308.321 512.444 309.125 513.433 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 309.125C508.452 309.125 509.254 308.321 509.254 307.33C509.254 306.339 508.452 305.535 507.463 305.535C506.474 305.535 505.672 306.339 505.672 307.33C505.672 308.321 506.474 309.125 507.463 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 309.125C502.482 309.125 503.284 308.321 503.284 307.33C503.284 306.339 502.482 305.535 501.493 305.535C500.503 305.535 499.702 306.339 499.702 307.33C499.702 308.321 500.503 309.125 501.493 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 309.125C496.512 309.125 497.313 308.321 497.313 307.33C497.313 306.339 496.512 305.535 495.522 305.535C494.533 305.535 493.731 306.339 493.731 307.33C493.731 308.321 494.533 309.125 495.522 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 309.125C490.541 309.125 491.343 308.321 491.343 307.33C491.343 306.339 490.541 305.535 489.552 305.535C488.563 305.535 487.761 306.339 487.761 307.33C487.761 308.321 488.563 309.125 489.552 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 309.125C484.571 309.125 485.373 308.321 485.373 307.33C485.373 306.339 484.571 305.535 483.582 305.535C482.593 305.535 481.791 306.339 481.791 307.33C481.791 308.321 482.593 309.125 483.582 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 303.142C1027.85 303.142 1028.66 302.339 1028.66 301.347C1028.66 300.356 1027.85 299.553 1026.87 299.553C1025.88 299.553 1025.07 300.356 1025.07 301.347C1025.07 302.339 1025.88 303.142 1026.87 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 303.142C1015.91 303.142 1016.72 302.339 1016.72 301.347C1016.72 300.356 1015.91 299.553 1014.93 299.553C1013.94 299.553 1013.13 300.356 1013.13 301.347C1013.13 302.339 1013.94 303.142 1014.93 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 303.142C998.004 303.142 998.806 302.339 998.806 301.347C998.806 300.356 998.004 299.553 997.015 299.553C996.026 299.553 995.224 300.356 995.224 301.347C995.224 302.339 996.026 303.142 997.015 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 303.142C950.243 303.142 951.045 302.339 951.045 301.347C951.045 300.356 950.243 299.553 949.254 299.553C948.265 299.553 947.463 300.356 947.463 301.347C947.463 302.339 948.265 303.142 949.254 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 303.142C944.273 303.142 945.075 302.339 945.075 301.347C945.075 300.356 944.273 299.553 943.284 299.553C942.294 299.553 941.492 300.356 941.492 301.347C941.492 302.339 942.294 303.142 943.284 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 303.142C920.392 303.142 921.194 302.339 921.194 301.347C921.194 300.356 920.392 299.553 919.403 299.553C918.414 299.553 917.612 300.356 917.612 301.347C917.612 302.339 918.414 303.142 919.403 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 303.142C842.78 303.142 843.582 302.339 843.582 301.347C843.582 300.356 842.78 299.553 841.791 299.553C840.802 299.553 840 300.356 840 301.347C840 302.339 840.802 303.142 841.791 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 303.142C836.81 303.142 837.612 302.339 837.612 301.347C837.612 300.356 836.81 299.553 835.821 299.553C834.832 299.553 834.03 300.356 834.03 301.347C834.03 302.339 834.832 303.142 835.821 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 303.142C729.347 303.142 730.149 302.339 730.149 301.347C730.149 300.356 729.347 299.553 728.358 299.553C727.369 299.553 726.567 300.356 726.567 301.347C726.567 302.339 727.369 303.142 728.358 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 303.142C723.377 303.142 724.179 302.339 724.179 301.347C724.179 300.356 723.377 299.553 722.388 299.553C721.399 299.553 720.597 300.356 720.597 301.347C720.597 302.339 721.399 303.142 722.388 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 303.142C717.407 303.142 718.209 302.339 718.209 301.347C718.209 300.356 717.407 299.553 716.418 299.553C715.429 299.553 714.627 300.356 714.627 301.347C714.627 302.339 715.429 303.142 716.418 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 303.142C711.437 303.142 712.239 302.339 712.239 301.347C712.239 300.356 711.437 299.553 710.448 299.553C709.459 299.553 708.657 300.356 708.657 301.347C708.657 302.339 709.459 303.142 710.448 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 303.142C705.467 303.142 706.269 302.339 706.269 301.347C706.269 300.356 705.467 299.553 704.478 299.553C703.488 299.553 702.687 300.356 702.687 301.347C702.687 302.339 703.488 303.142 704.478 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 303.142C699.497 303.142 700.299 302.339 700.299 301.347C700.299 300.356 699.497 299.553 698.507 299.553C697.518 299.553 696.716 300.356 696.716 301.347C696.716 302.339 697.518 303.142 698.507 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 303.142C693.526 303.142 694.328 302.339 694.328 301.347C694.328 300.356 693.526 299.553 692.537 299.553C691.548 299.553 690.746 300.356 690.746 301.347C690.746 302.339 691.548 303.142 692.537 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 303.142C687.556 303.142 688.358 302.339 688.358 301.347C688.358 300.356 687.556 299.553 686.567 299.553C685.578 299.553 684.776 300.356 684.776 301.347C684.776 302.339 685.578 303.142 686.567 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 303.142C681.586 303.142 682.388 302.339 682.388 301.347C682.388 300.356 681.586 299.553 680.597 299.553C679.608 299.553 678.806 300.356 678.806 301.347C678.806 302.339 679.608 303.142 680.597 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 303.142C675.616 303.142 676.418 302.339 676.418 301.347C676.418 300.356 675.616 299.553 674.627 299.553C673.638 299.553 672.836 300.356 672.836 301.347C672.836 302.339 673.638 303.142 674.627 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 303.142C669.646 303.142 670.448 302.339 670.448 301.347C670.448 300.356 669.646 299.553 668.657 299.553C667.668 299.553 666.866 300.356 666.866 301.347C666.866 302.339 667.668 303.142 668.657 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 303.142C663.676 303.142 664.478 302.339 664.478 301.347C664.478 300.356 663.676 299.553 662.687 299.553C661.697 299.553 660.896 300.356 660.896 301.347C660.896 302.339 661.697 303.142 662.687 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 303.142C657.706 303.142 658.507 302.339 658.507 301.347C658.507 300.356 657.706 299.553 656.716 299.553C655.727 299.553 654.925 300.356 654.925 301.347C654.925 302.339 655.727 303.142 656.716 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 303.142C651.735 303.142 652.537 302.339 652.537 301.347C652.537 300.356 651.735 299.553 650.746 299.553C649.757 299.553 648.955 300.356 648.955 301.347C648.955 302.339 649.757 303.142 650.746 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 303.142C645.765 303.142 646.567 302.339 646.567 301.347C646.567 300.356 645.765 299.553 644.776 299.553C643.787 299.553 642.985 300.356 642.985 301.347C642.985 302.339 643.787 303.142 644.776 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 303.142C639.795 303.142 640.597 302.339 640.597 301.347C640.597 300.356 639.795 299.553 638.806 299.553C637.817 299.553 637.015 300.356 637.015 301.347C637.015 302.339 637.817 303.142 638.806 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 303.142C633.825 303.142 634.627 302.339 634.627 301.347C634.627 300.356 633.825 299.553 632.836 299.553C631.847 299.553 631.045 300.356 631.045 301.347C631.045 302.339 631.847 303.142 632.836 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 303.142C627.855 303.142 628.657 302.339 628.657 301.347C628.657 300.356 627.855 299.553 626.866 299.553C625.876 299.553 625.075 300.356 625.075 301.347C625.075 302.339 625.876 303.142 626.866 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 303.142C621.885 303.142 622.687 302.339 622.687 301.347C622.687 300.356 621.885 299.553 620.895 299.553C619.906 299.553 619.104 300.356 619.104 301.347C619.104 302.339 619.906 303.142 620.895 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 303.142C615.915 303.142 616.716 302.339 616.716 301.347C616.716 300.356 615.915 299.553 614.925 299.553C613.936 299.553 613.134 300.356 613.134 301.347C613.134 302.339 613.936 303.142 614.925 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 303.142C609.944 303.142 610.746 302.339 610.746 301.347C610.746 300.356 609.944 299.553 608.955 299.553C607.966 299.553 607.164 300.356 607.164 301.347C607.164 302.339 607.966 303.142 608.955 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 303.142C603.974 303.142 604.776 302.339 604.776 301.347C604.776 300.356 603.974 299.553 602.985 299.553C601.996 299.553 601.194 300.356 601.194 301.347C601.194 302.339 601.996 303.142 602.985 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 303.142C598.004 303.142 598.806 302.339 598.806 301.347C598.806 300.356 598.004 299.553 597.015 299.553C596.026 299.553 595.224 300.356 595.224 301.347C595.224 302.339 596.026 303.142 597.015 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 303.142C592.034 303.142 592.836 302.339 592.836 301.347C592.836 300.356 592.034 299.553 591.045 299.553C590.056 299.553 589.254 300.356 589.254 301.347C589.254 302.339 590.056 303.142 591.045 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 303.142C586.064 303.142 586.866 302.339 586.866 301.347C586.866 300.356 586.064 299.553 585.075 299.553C584.085 299.553 583.284 300.356 583.284 301.347C583.284 302.339 584.085 303.142 585.075 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 303.142C580.094 303.142 580.896 302.339 580.896 301.347C580.896 300.356 580.094 299.553 579.104 299.553C578.115 299.553 577.313 300.356 577.313 301.347C577.313 302.339 578.115 303.142 579.104 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 303.142C574.123 303.142 574.925 302.339 574.925 301.347C574.925 300.356 574.123 299.553 573.134 299.553C572.145 299.553 571.343 300.356 571.343 301.347C571.343 302.339 572.145 303.142 573.134 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 303.142C568.153 303.142 568.955 302.339 568.955 301.347C568.955 300.356 568.153 299.553 567.164 299.553C566.175 299.553 565.373 300.356 565.373 301.347C565.373 302.339 566.175 303.142 567.164 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 303.142C562.183 303.142 562.985 302.339 562.985 301.347C562.985 300.356 562.183 299.553 561.194 299.553C560.205 299.553 559.403 300.356 559.403 301.347C559.403 302.339 560.205 303.142 561.194 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 303.142C556.213 303.142 557.015 302.339 557.015 301.347C557.015 300.356 556.213 299.553 555.224 299.553C554.235 299.553 553.433 300.356 553.433 301.347C553.433 302.339 554.235 303.142 555.224 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 303.142C550.243 303.142 551.045 302.339 551.045 301.347C551.045 300.356 550.243 299.553 549.254 299.553C548.265 299.553 547.463 300.356 547.463 301.347C547.463 302.339 548.265 303.142 549.254 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 303.142C544.273 303.142 545.075 302.339 545.075 301.347C545.075 300.356 544.273 299.553 543.284 299.553C542.294 299.553 541.492 300.356 541.492 301.347C541.492 302.339 542.294 303.142 543.284 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 303.142C538.303 303.142 539.104 302.339 539.104 301.347C539.104 300.356 538.303 299.553 537.313 299.553C536.324 299.553 535.522 300.356 535.522 301.347C535.522 302.339 536.324 303.142 537.313 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 303.142C532.332 303.142 533.134 302.339 533.134 301.347C533.134 300.356 532.332 299.553 531.343 299.553C530.354 299.553 529.552 300.356 529.552 301.347C529.552 302.339 530.354 303.142 531.343 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 303.142C526.362 303.142 527.164 302.339 527.164 301.347C527.164 300.356 526.362 299.553 525.373 299.553C524.384 299.553 523.582 300.356 523.582 301.347C523.582 302.339 524.384 303.142 525.373 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 303.142C520.392 303.142 521.194 302.339 521.194 301.347C521.194 300.356 520.392 299.553 519.403 299.553C518.414 299.553 517.612 300.356 517.612 301.347C517.612 302.339 518.414 303.142 519.403 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 303.142C514.422 303.142 515.224 302.339 515.224 301.347C515.224 300.356 514.422 299.553 513.433 299.553C512.444 299.553 511.642 300.356 511.642 301.347C511.642 302.339 512.444 303.142 513.433 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 303.142C508.452 303.142 509.254 302.339 509.254 301.347C509.254 300.356 508.452 299.553 507.463 299.553C506.474 299.553 505.672 300.356 505.672 301.347C505.672 302.339 506.474 303.142 507.463 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 303.142C502.482 303.142 503.284 302.339 503.284 301.347C503.284 300.356 502.482 299.553 501.493 299.553C500.503 299.553 499.702 300.356 499.702 301.347C499.702 302.339 500.503 303.142 501.493 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 303.142C496.512 303.142 497.313 302.339 497.313 301.347C497.313 300.356 496.512 299.553 495.522 299.553C494.533 299.553 493.731 300.356 493.731 301.347C493.731 302.339 494.533 303.142 495.522 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 303.142C490.541 303.142 491.343 302.339 491.343 301.347C491.343 300.356 490.541 299.553 489.552 299.553C488.563 299.553 487.761 300.356 487.761 301.347C487.761 302.339 488.563 303.142 489.552 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 303.142C484.571 303.142 485.373 302.339 485.373 301.347C485.373 300.356 484.571 299.553 483.582 299.553C482.593 299.553 481.791 300.356 481.791 301.347C481.791 302.339 482.593 303.142 483.582 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 303.142C478.601 303.142 479.403 302.339 479.403 301.347C479.403 300.356 478.601 299.553 477.612 299.553C476.623 299.553 475.821 300.356 475.821 301.347C475.821 302.339 476.623 303.142 477.612 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 297.16C1027.85 297.16 1028.66 296.356 1028.66 295.365C1028.66 294.374 1027.85 293.57 1026.87 293.57C1025.88 293.57 1025.07 294.374 1025.07 295.365C1025.07 296.356 1025.88 297.16 1026.87 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 297.16C1015.91 297.16 1016.72 296.356 1016.72 295.365C1016.72 294.374 1015.91 293.57 1014.93 293.57C1013.94 293.57 1013.13 294.374 1013.13 295.365C1013.13 296.356 1013.94 297.16 1014.93 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 297.16C1003.97 297.16 1004.78 296.356 1004.78 295.365C1004.78 294.374 1003.97 293.57 1002.99 293.57C1002 293.57 1001.19 294.374 1001.19 295.365C1001.19 296.356 1002 297.16 1002.99 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 297.16C956.213 297.16 957.015 296.356 957.015 295.365C957.015 294.374 956.213 293.57 955.224 293.57C954.235 293.57 953.433 294.374 953.433 295.365C953.433 296.356 954.235 297.16 955.224 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 297.16C950.243 297.16 951.045 296.356 951.045 295.365C951.045 294.374 950.243 293.57 949.254 293.57C948.265 293.57 947.463 294.374 947.463 295.365C947.463 296.356 948.265 297.16 949.254 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 297.16C944.273 297.16 945.075 296.356 945.075 295.365C945.075 294.374 944.273 293.57 943.284 293.57C942.294 293.57 941.492 294.374 941.492 295.365C941.492 296.356 942.294 297.16 943.284 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 297.16C938.303 297.16 939.105 296.356 939.105 295.365C939.105 294.374 938.303 293.57 937.313 293.57C936.324 293.57 935.522 294.374 935.522 295.365C935.522 296.356 936.324 297.16 937.313 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 297.16C920.392 297.16 921.194 296.356 921.194 295.365C921.194 294.374 920.392 293.57 919.403 293.57C918.414 293.57 917.612 294.374 917.612 295.365C917.612 296.356 918.414 297.16 919.403 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 297.16C842.78 297.16 843.582 296.356 843.582 295.365C843.582 294.374 842.78 293.57 841.791 293.57C840.802 293.57 840 294.374 840 295.365C840 296.356 840.802 297.16 841.791 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 297.16C836.81 297.16 837.612 296.356 837.612 295.365C837.612 294.374 836.81 293.57 835.821 293.57C834.832 293.57 834.03 294.374 834.03 295.365C834.03 296.356 834.832 297.16 835.821 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 297.16C830.84 297.16 831.642 296.356 831.642 295.365C831.642 294.374 830.84 293.57 829.851 293.57C828.862 293.57 828.06 294.374 828.06 295.365C828.06 296.356 828.862 297.16 829.851 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 297.16C729.347 297.16 730.149 296.356 730.149 295.365C730.149 294.374 729.347 293.57 728.358 293.57C727.369 293.57 726.567 294.374 726.567 295.365C726.567 296.356 727.369 297.16 728.358 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 297.16C723.377 297.16 724.179 296.356 724.179 295.365C724.179 294.374 723.377 293.57 722.388 293.57C721.399 293.57 720.597 294.374 720.597 295.365C720.597 296.356 721.399 297.16 722.388 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 297.16C699.497 297.16 700.299 296.356 700.299 295.365C700.299 294.374 699.497 293.57 698.507 293.57C697.518 293.57 696.716 294.374 696.716 295.365C696.716 296.356 697.518 297.16 698.507 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 297.16C693.526 297.16 694.328 296.356 694.328 295.365C694.328 294.374 693.526 293.57 692.537 293.57C691.548 293.57 690.746 294.374 690.746 295.365C690.746 296.356 691.548 297.16 692.537 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 297.16C687.556 297.16 688.358 296.356 688.358 295.365C688.358 294.374 687.556 293.57 686.567 293.57C685.578 293.57 684.776 294.374 684.776 295.365C684.776 296.356 685.578 297.16 686.567 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 297.16C681.586 297.16 682.388 296.356 682.388 295.365C682.388 294.374 681.586 293.57 680.597 293.57C679.608 293.57 678.806 294.374 678.806 295.365C678.806 296.356 679.608 297.16 680.597 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 297.16C675.616 297.16 676.418 296.356 676.418 295.365C676.418 294.374 675.616 293.57 674.627 293.57C673.638 293.57 672.836 294.374 672.836 295.365C672.836 296.356 673.638 297.16 674.627 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 297.16C669.646 297.16 670.448 296.356 670.448 295.365C670.448 294.374 669.646 293.57 668.657 293.57C667.668 293.57 666.866 294.374 666.866 295.365C666.866 296.356 667.668 297.16 668.657 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 297.16C663.676 297.16 664.478 296.356 664.478 295.365C664.478 294.374 663.676 293.57 662.687 293.57C661.697 293.57 660.896 294.374 660.896 295.365C660.896 296.356 661.697 297.16 662.687 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 297.16C657.706 297.16 658.507 296.356 658.507 295.365C658.507 294.374 657.706 293.57 656.716 293.57C655.727 293.57 654.925 294.374 654.925 295.365C654.925 296.356 655.727 297.16 656.716 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 297.16C651.735 297.16 652.537 296.356 652.537 295.365C652.537 294.374 651.735 293.57 650.746 293.57C649.757 293.57 648.955 294.374 648.955 295.365C648.955 296.356 649.757 297.16 650.746 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 297.16C645.765 297.16 646.567 296.356 646.567 295.365C646.567 294.374 645.765 293.57 644.776 293.57C643.787 293.57 642.985 294.374 642.985 295.365C642.985 296.356 643.787 297.16 644.776 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 297.16C639.795 297.16 640.597 296.356 640.597 295.365C640.597 294.374 639.795 293.57 638.806 293.57C637.817 293.57 637.015 294.374 637.015 295.365C637.015 296.356 637.817 297.16 638.806 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 297.16C633.825 297.16 634.627 296.356 634.627 295.365C634.627 294.374 633.825 293.57 632.836 293.57C631.847 293.57 631.045 294.374 631.045 295.365C631.045 296.356 631.847 297.16 632.836 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 297.16C627.855 297.16 628.657 296.356 628.657 295.365C628.657 294.374 627.855 293.57 626.866 293.57C625.876 293.57 625.075 294.374 625.075 295.365C625.075 296.356 625.876 297.16 626.866 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 297.16C621.885 297.16 622.687 296.356 622.687 295.365C622.687 294.374 621.885 293.57 620.895 293.57C619.906 293.57 619.104 294.374 619.104 295.365C619.104 296.356 619.906 297.16 620.895 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 297.16C615.915 297.16 616.716 296.356 616.716 295.365C616.716 294.374 615.915 293.57 614.925 293.57C613.936 293.57 613.134 294.374 613.134 295.365C613.134 296.356 613.936 297.16 614.925 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 297.16C609.944 297.16 610.746 296.356 610.746 295.365C610.746 294.374 609.944 293.57 608.955 293.57C607.966 293.57 607.164 294.374 607.164 295.365C607.164 296.356 607.966 297.16 608.955 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 297.16C603.974 297.16 604.776 296.356 604.776 295.365C604.776 294.374 603.974 293.57 602.985 293.57C601.996 293.57 601.194 294.374 601.194 295.365C601.194 296.356 601.996 297.16 602.985 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 297.16C598.004 297.16 598.806 296.356 598.806 295.365C598.806 294.374 598.004 293.57 597.015 293.57C596.026 293.57 595.224 294.374 595.224 295.365C595.224 296.356 596.026 297.16 597.015 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 297.16C592.034 297.16 592.836 296.356 592.836 295.365C592.836 294.374 592.034 293.57 591.045 293.57C590.056 293.57 589.254 294.374 589.254 295.365C589.254 296.356 590.056 297.16 591.045 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 297.16C586.064 297.16 586.866 296.356 586.866 295.365C586.866 294.374 586.064 293.57 585.075 293.57C584.085 293.57 583.284 294.374 583.284 295.365C583.284 296.356 584.085 297.16 585.075 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 297.16C580.094 297.16 580.896 296.356 580.896 295.365C580.896 294.374 580.094 293.57 579.104 293.57C578.115 293.57 577.313 294.374 577.313 295.365C577.313 296.356 578.115 297.16 579.104 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 297.16C574.123 297.16 574.925 296.356 574.925 295.365C574.925 294.374 574.123 293.57 573.134 293.57C572.145 293.57 571.343 294.374 571.343 295.365C571.343 296.356 572.145 297.16 573.134 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 297.16C568.153 297.16 568.955 296.356 568.955 295.365C568.955 294.374 568.153 293.57 567.164 293.57C566.175 293.57 565.373 294.374 565.373 295.365C565.373 296.356 566.175 297.16 567.164 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 297.16C562.183 297.16 562.985 296.356 562.985 295.365C562.985 294.374 562.183 293.57 561.194 293.57C560.205 293.57 559.403 294.374 559.403 295.365C559.403 296.356 560.205 297.16 561.194 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 297.16C556.213 297.16 557.015 296.356 557.015 295.365C557.015 294.374 556.213 293.57 555.224 293.57C554.235 293.57 553.433 294.374 553.433 295.365C553.433 296.356 554.235 297.16 555.224 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 297.16C550.243 297.16 551.045 296.356 551.045 295.365C551.045 294.374 550.243 293.57 549.254 293.57C548.265 293.57 547.463 294.374 547.463 295.365C547.463 296.356 548.265 297.16 549.254 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 297.16C544.273 297.16 545.075 296.356 545.075 295.365C545.075 294.374 544.273 293.57 543.284 293.57C542.294 293.57 541.492 294.374 541.492 295.365C541.492 296.356 542.294 297.16 543.284 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 297.16C538.303 297.16 539.104 296.356 539.104 295.365C539.104 294.374 538.303 293.57 537.313 293.57C536.324 293.57 535.522 294.374 535.522 295.365C535.522 296.356 536.324 297.16 537.313 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 297.16C532.332 297.16 533.134 296.356 533.134 295.365C533.134 294.374 532.332 293.57 531.343 293.57C530.354 293.57 529.552 294.374 529.552 295.365C529.552 296.356 530.354 297.16 531.343 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 297.16C526.362 297.16 527.164 296.356 527.164 295.365C527.164 294.374 526.362 293.57 525.373 293.57C524.384 293.57 523.582 294.374 523.582 295.365C523.582 296.356 524.384 297.16 525.373 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 297.16C520.392 297.16 521.194 296.356 521.194 295.365C521.194 294.374 520.392 293.57 519.403 293.57C518.414 293.57 517.612 294.374 517.612 295.365C517.612 296.356 518.414 297.16 519.403 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 297.16C514.422 297.16 515.224 296.356 515.224 295.365C515.224 294.374 514.422 293.57 513.433 293.57C512.444 293.57 511.642 294.374 511.642 295.365C511.642 296.356 512.444 297.16 513.433 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 297.16C508.452 297.16 509.254 296.356 509.254 295.365C509.254 294.374 508.452 293.57 507.463 293.57C506.474 293.57 505.672 294.374 505.672 295.365C505.672 296.356 506.474 297.16 507.463 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 297.16C502.482 297.16 503.284 296.356 503.284 295.365C503.284 294.374 502.482 293.57 501.493 293.57C500.503 293.57 499.702 294.374 499.702 295.365C499.702 296.356 500.503 297.16 501.493 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 297.16C496.512 297.16 497.313 296.356 497.313 295.365C497.313 294.374 496.512 293.57 495.522 293.57C494.533 293.57 493.731 294.374 493.731 295.365C493.731 296.356 494.533 297.16 495.522 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 297.16C490.541 297.16 491.343 296.356 491.343 295.365C491.343 294.374 490.541 293.57 489.552 293.57C488.563 293.57 487.761 294.374 487.761 295.365C487.761 296.356 488.563 297.16 489.552 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 297.16C484.571 297.16 485.373 296.356 485.373 295.365C485.373 294.374 484.571 293.57 483.582 293.57C482.593 293.57 481.791 294.374 481.791 295.365C481.791 296.356 482.593 297.16 483.582 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 297.16C478.601 297.16 479.403 296.356 479.403 295.365C479.403 294.374 478.601 293.57 477.612 293.57C476.623 293.57 475.821 294.374 475.821 295.365C475.821 296.356 476.623 297.16 477.612 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 297.16C472.631 297.16 473.433 296.356 473.433 295.365C473.433 294.374 472.631 293.57 471.642 293.57C470.653 293.57 469.851 294.374 469.851 295.365C469.851 296.356 470.653 297.16 471.642 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 291.177C1009.94 291.177 1010.75 290.374 1010.75 289.383C1010.75 288.391 1009.94 287.588 1008.96 287.588C1007.97 287.588 1007.16 288.391 1007.16 289.383C1007.16 290.374 1007.97 291.177 1008.96 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 291.177C962.183 291.177 962.985 290.374 962.985 289.383C962.985 288.391 962.183 287.588 961.194 287.588C960.205 287.588 959.403 288.391 959.403 289.383C959.403 290.374 960.205 291.177 961.194 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 291.177C956.213 291.177 957.015 290.374 957.015 289.383C957.015 288.391 956.213 287.588 955.224 287.588C954.235 287.588 953.433 288.391 953.433 289.383C953.433 290.374 954.235 291.177 955.224 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 291.177C950.243 291.177 951.045 290.374 951.045 289.383C951.045 288.391 950.243 287.588 949.254 287.588C948.265 287.588 947.463 288.391 947.463 289.383C947.463 290.374 948.265 291.177 949.254 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 291.177C944.273 291.177 945.075 290.374 945.075 289.383C945.075 288.391 944.273 287.588 943.284 287.588C942.294 287.588 941.492 288.391 941.492 289.383C941.492 290.374 942.294 291.177 943.284 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 291.177C938.303 291.177 939.105 290.374 939.105 289.383C939.105 288.391 938.303 287.588 937.313 287.588C936.324 287.588 935.522 288.391 935.522 289.383C935.522 290.374 936.324 291.177 937.313 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 291.177C932.332 291.177 933.134 290.374 933.134 289.383C933.134 288.391 932.332 287.588 931.343 287.588C930.354 287.588 929.552 288.391 929.552 289.383C929.552 290.374 930.354 291.177 931.343 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 291.177C920.392 291.177 921.194 290.374 921.194 289.383C921.194 288.391 920.392 287.588 919.403 287.588C918.414 287.588 917.612 288.391 917.612 289.383C917.612 290.374 918.414 291.177 919.403 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 291.177C830.84 291.177 831.642 290.374 831.642 289.383C831.642 288.391 830.84 287.588 829.851 287.588C828.862 287.588 828.06 288.391 828.06 289.383C828.06 290.374 828.862 291.177 829.851 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 291.177C699.497 291.177 700.299 290.374 700.299 289.383C700.299 288.391 699.497 287.588 698.507 287.588C697.518 287.588 696.716 288.391 696.716 289.383C696.716 290.374 697.518 291.177 698.507 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 291.177C693.526 291.177 694.328 290.374 694.328 289.383C694.328 288.391 693.526 287.588 692.537 287.588C691.548 287.588 690.746 288.391 690.746 289.383C690.746 290.374 691.548 291.177 692.537 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 291.177C687.556 291.177 688.358 290.374 688.358 289.383C688.358 288.391 687.556 287.588 686.567 287.588C685.578 287.588 684.776 288.391 684.776 289.383C684.776 290.374 685.578 291.177 686.567 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 291.177C681.586 291.177 682.388 290.374 682.388 289.383C682.388 288.391 681.586 287.588 680.597 287.588C679.608 287.588 678.806 288.391 678.806 289.383C678.806 290.374 679.608 291.177 680.597 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 291.177C675.616 291.177 676.418 290.374 676.418 289.383C676.418 288.391 675.616 287.588 674.627 287.588C673.638 287.588 672.836 288.391 672.836 289.383C672.836 290.374 673.638 291.177 674.627 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 291.177C669.646 291.177 670.448 290.374 670.448 289.383C670.448 288.391 669.646 287.588 668.657 287.588C667.668 287.588 666.866 288.391 666.866 289.383C666.866 290.374 667.668 291.177 668.657 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 291.177C663.676 291.177 664.478 290.374 664.478 289.383C664.478 288.391 663.676 287.588 662.687 287.588C661.697 287.588 660.896 288.391 660.896 289.383C660.896 290.374 661.697 291.177 662.687 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 291.177C657.706 291.177 658.507 290.374 658.507 289.383C658.507 288.391 657.706 287.588 656.716 287.588C655.727 287.588 654.925 288.391 654.925 289.383C654.925 290.374 655.727 291.177 656.716 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 291.177C651.735 291.177 652.537 290.374 652.537 289.383C652.537 288.391 651.735 287.588 650.746 287.588C649.757 287.588 648.955 288.391 648.955 289.383C648.955 290.374 649.757 291.177 650.746 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 291.177C645.765 291.177 646.567 290.374 646.567 289.383C646.567 288.391 645.765 287.588 644.776 287.588C643.787 287.588 642.985 288.391 642.985 289.383C642.985 290.374 643.787 291.177 644.776 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 291.177C639.795 291.177 640.597 290.374 640.597 289.383C640.597 288.391 639.795 287.588 638.806 287.588C637.817 287.588 637.015 288.391 637.015 289.383C637.015 290.374 637.817 291.177 638.806 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 291.177C633.825 291.177 634.627 290.374 634.627 289.383C634.627 288.391 633.825 287.588 632.836 287.588C631.847 287.588 631.045 288.391 631.045 289.383C631.045 290.374 631.847 291.177 632.836 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 291.177C627.855 291.177 628.657 290.374 628.657 289.383C628.657 288.391 627.855 287.588 626.866 287.588C625.876 287.588 625.075 288.391 625.075 289.383C625.075 290.374 625.876 291.177 626.866 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 291.177C621.885 291.177 622.687 290.374 622.687 289.383C622.687 288.391 621.885 287.588 620.895 287.588C619.906 287.588 619.104 288.391 619.104 289.383C619.104 290.374 619.906 291.177 620.895 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 291.177C615.915 291.177 616.716 290.374 616.716 289.383C616.716 288.391 615.915 287.588 614.925 287.588C613.936 287.588 613.134 288.391 613.134 289.383C613.134 290.374 613.936 291.177 614.925 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 291.177C609.944 291.177 610.746 290.374 610.746 289.383C610.746 288.391 609.944 287.588 608.955 287.588C607.966 287.588 607.164 288.391 607.164 289.383C607.164 290.374 607.966 291.177 608.955 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 291.177C603.974 291.177 604.776 290.374 604.776 289.383C604.776 288.391 603.974 287.588 602.985 287.588C601.996 287.588 601.194 288.391 601.194 289.383C601.194 290.374 601.996 291.177 602.985 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 291.177C598.004 291.177 598.806 290.374 598.806 289.383C598.806 288.391 598.004 287.588 597.015 287.588C596.026 287.588 595.224 288.391 595.224 289.383C595.224 290.374 596.026 291.177 597.015 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 291.177C592.034 291.177 592.836 290.374 592.836 289.383C592.836 288.391 592.034 287.588 591.045 287.588C590.056 287.588 589.254 288.391 589.254 289.383C589.254 290.374 590.056 291.177 591.045 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 291.177C586.064 291.177 586.866 290.374 586.866 289.383C586.866 288.391 586.064 287.588 585.075 287.588C584.085 287.588 583.284 288.391 583.284 289.383C583.284 290.374 584.085 291.177 585.075 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 291.177C580.094 291.177 580.896 290.374 580.896 289.383C580.896 288.391 580.094 287.588 579.104 287.588C578.115 287.588 577.313 288.391 577.313 289.383C577.313 290.374 578.115 291.177 579.104 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 291.177C574.123 291.177 574.925 290.374 574.925 289.383C574.925 288.391 574.123 287.588 573.134 287.588C572.145 287.588 571.343 288.391 571.343 289.383C571.343 290.374 572.145 291.177 573.134 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 291.177C568.153 291.177 568.955 290.374 568.955 289.383C568.955 288.391 568.153 287.588 567.164 287.588C566.175 287.588 565.373 288.391 565.373 289.383C565.373 290.374 566.175 291.177 567.164 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 291.177C562.183 291.177 562.985 290.374 562.985 289.383C562.985 288.391 562.183 287.588 561.194 287.588C560.205 287.588 559.403 288.391 559.403 289.383C559.403 290.374 560.205 291.177 561.194 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 291.177C556.213 291.177 557.015 290.374 557.015 289.383C557.015 288.391 556.213 287.588 555.224 287.588C554.235 287.588 553.433 288.391 553.433 289.383C553.433 290.374 554.235 291.177 555.224 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 291.177C550.243 291.177 551.045 290.374 551.045 289.383C551.045 288.391 550.243 287.588 549.254 287.588C548.265 287.588 547.463 288.391 547.463 289.383C547.463 290.374 548.265 291.177 549.254 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 291.177C544.273 291.177 545.075 290.374 545.075 289.383C545.075 288.391 544.273 287.588 543.284 287.588C542.294 287.588 541.492 288.391 541.492 289.383C541.492 290.374 542.294 291.177 543.284 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 291.177C538.303 291.177 539.104 290.374 539.104 289.383C539.104 288.391 538.303 287.588 537.313 287.588C536.324 287.588 535.522 288.391 535.522 289.383C535.522 290.374 536.324 291.177 537.313 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 291.177C532.332 291.177 533.134 290.374 533.134 289.383C533.134 288.391 532.332 287.588 531.343 287.588C530.354 287.588 529.552 288.391 529.552 289.383C529.552 290.374 530.354 291.177 531.343 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 291.177C526.362 291.177 527.164 290.374 527.164 289.383C527.164 288.391 526.362 287.588 525.373 287.588C524.384 287.588 523.582 288.391 523.582 289.383C523.582 290.374 524.384 291.177 525.373 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 291.177C520.392 291.177 521.194 290.374 521.194 289.383C521.194 288.391 520.392 287.588 519.403 287.588C518.414 287.588 517.612 288.391 517.612 289.383C517.612 290.374 518.414 291.177 519.403 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 291.177C514.422 291.177 515.224 290.374 515.224 289.383C515.224 288.391 514.422 287.588 513.433 287.588C512.444 287.588 511.642 288.391 511.642 289.383C511.642 290.374 512.444 291.177 513.433 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 291.177C508.452 291.177 509.254 290.374 509.254 289.383C509.254 288.391 508.452 287.588 507.463 287.588C506.474 287.588 505.672 288.391 505.672 289.383C505.672 290.374 506.474 291.177 507.463 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 291.177C502.482 291.177 503.284 290.374 503.284 289.383C503.284 288.391 502.482 287.588 501.493 287.588C500.503 287.588 499.702 288.391 499.702 289.383C499.702 290.374 500.503 291.177 501.493 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 291.177C496.512 291.177 497.313 290.374 497.313 289.383C497.313 288.391 496.512 287.588 495.522 287.588C494.533 287.588 493.731 288.391 493.731 289.383C493.731 290.374 494.533 291.177 495.522 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 291.177C490.541 291.177 491.343 290.374 491.343 289.383C491.343 288.391 490.541 287.588 489.552 287.588C488.563 287.588 487.761 288.391 487.761 289.383C487.761 290.374 488.563 291.177 489.552 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 291.177C484.571 291.177 485.373 290.374 485.373 289.383C485.373 288.391 484.571 287.588 483.582 287.588C482.593 287.588 481.791 288.391 481.791 289.383C481.791 290.374 482.593 291.177 483.582 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 291.177C478.601 291.177 479.403 290.374 479.403 289.383C479.403 288.391 478.601 287.588 477.612 287.588C476.623 287.588 475.821 288.391 475.821 289.383C475.821 290.374 476.623 291.177 477.612 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 291.177C472.631 291.177 473.433 290.374 473.433 289.383C473.433 288.391 472.631 287.588 471.642 287.588C470.653 287.588 469.851 288.391 469.851 289.383C469.851 290.374 470.653 291.177 471.642 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 285.195C1015.91 285.195 1016.72 284.392 1016.72 283.401C1016.72 282.409 1015.91 281.606 1014.93 281.606C1013.94 281.606 1013.13 282.409 1013.13 283.401C1013.13 284.392 1013.94 285.195 1014.93 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 285.195C1009.94 285.195 1010.75 284.392 1010.75 283.401C1010.75 282.409 1009.94 281.606 1008.96 281.606C1007.97 281.606 1007.16 282.409 1007.16 283.401C1007.16 284.392 1007.97 285.195 1008.96 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 285.195C956.213 285.195 957.015 284.392 957.015 283.401C957.015 282.409 956.213 281.606 955.224 281.606C954.235 281.606 953.433 282.409 953.433 283.401C953.433 284.392 954.235 285.195 955.224 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 285.195C950.243 285.195 951.045 284.392 951.045 283.401C951.045 282.409 950.243 281.606 949.254 281.606C948.265 281.606 947.463 282.409 947.463 283.401C947.463 284.392 948.265 285.195 949.254 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 285.195C944.273 285.195 945.075 284.392 945.075 283.401C945.075 282.409 944.273 281.606 943.284 281.606C942.294 281.606 941.492 282.409 941.492 283.401C941.492 284.392 942.294 285.195 943.284 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 285.195C938.303 285.195 939.105 284.392 939.105 283.401C939.105 282.409 938.303 281.606 937.313 281.606C936.324 281.606 935.522 282.409 935.522 283.401C935.522 284.392 936.324 285.195 937.313 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 285.195C932.332 285.195 933.134 284.392 933.134 283.401C933.134 282.409 932.332 281.606 931.343 281.606C930.354 281.606 929.552 282.409 929.552 283.401C929.552 284.392 930.354 285.195 931.343 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 285.195C920.392 285.195 921.194 284.392 921.194 283.401C921.194 282.409 920.392 281.606 919.403 281.606C918.414 281.606 917.612 282.409 917.612 283.401C917.612 284.392 918.414 285.195 919.403 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 285.195C842.78 285.195 843.582 284.392 843.582 283.401C843.582 282.409 842.78 281.606 841.791 281.606C840.802 281.606 840 282.409 840 283.401C840 284.392 840.802 285.195 841.791 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 285.195C836.81 285.195 837.612 284.392 837.612 283.401C837.612 282.409 836.81 281.606 835.821 281.606C834.832 281.606 834.03 282.409 834.03 283.401C834.03 284.392 834.832 285.195 835.821 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 285.195C830.84 285.195 831.642 284.392 831.642 283.401C831.642 282.409 830.84 281.606 829.851 281.606C828.862 281.606 828.06 282.409 828.06 283.401C828.06 284.392 828.862 285.195 829.851 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 285.195C824.87 285.195 825.672 284.392 825.672 283.401C825.672 282.409 824.87 281.606 823.881 281.606C822.891 281.606 822.09 282.409 822.09 283.401C822.09 284.392 822.891 285.195 823.881 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 285.195C717.407 285.195 718.209 284.392 718.209 283.401C718.209 282.409 717.407 281.606 716.418 281.606C715.429 281.606 714.627 282.409 714.627 283.401C714.627 284.392 715.429 285.195 716.418 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 285.195C711.437 285.195 712.239 284.392 712.239 283.401C712.239 282.409 711.437 281.606 710.448 281.606C709.459 281.606 708.657 282.409 708.657 283.401C708.657 284.392 709.459 285.195 710.448 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 285.195C705.467 285.195 706.269 284.392 706.269 283.401C706.269 282.409 705.467 281.606 704.478 281.606C703.488 281.606 702.687 282.409 702.687 283.401C702.687 284.392 703.488 285.195 704.478 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 285.195C693.526 285.195 694.328 284.392 694.328 283.401C694.328 282.409 693.526 281.606 692.537 281.606C691.548 281.606 690.746 282.409 690.746 283.401C690.746 284.392 691.548 285.195 692.537 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 285.195C687.556 285.195 688.358 284.392 688.358 283.401C688.358 282.409 687.556 281.606 686.567 281.606C685.578 281.606 684.776 282.409 684.776 283.401C684.776 284.392 685.578 285.195 686.567 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 285.195C681.586 285.195 682.388 284.392 682.388 283.401C682.388 282.409 681.586 281.606 680.597 281.606C679.608 281.606 678.806 282.409 678.806 283.401C678.806 284.392 679.608 285.195 680.597 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 285.195C675.616 285.195 676.418 284.392 676.418 283.401C676.418 282.409 675.616 281.606 674.627 281.606C673.638 281.606 672.836 282.409 672.836 283.401C672.836 284.392 673.638 285.195 674.627 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 285.195C669.646 285.195 670.448 284.392 670.448 283.401C670.448 282.409 669.646 281.606 668.657 281.606C667.668 281.606 666.866 282.409 666.866 283.401C666.866 284.392 667.668 285.195 668.657 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 285.195C663.676 285.195 664.478 284.392 664.478 283.401C664.478 282.409 663.676 281.606 662.687 281.606C661.697 281.606 660.896 282.409 660.896 283.401C660.896 284.392 661.697 285.195 662.687 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 285.195C657.706 285.195 658.507 284.392 658.507 283.401C658.507 282.409 657.706 281.606 656.716 281.606C655.727 281.606 654.925 282.409 654.925 283.401C654.925 284.392 655.727 285.195 656.716 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 285.195C651.735 285.195 652.537 284.392 652.537 283.401C652.537 282.409 651.735 281.606 650.746 281.606C649.757 281.606 648.955 282.409 648.955 283.401C648.955 284.392 649.757 285.195 650.746 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 285.195C645.765 285.195 646.567 284.392 646.567 283.401C646.567 282.409 645.765 281.606 644.776 281.606C643.787 281.606 642.985 282.409 642.985 283.401C642.985 284.392 643.787 285.195 644.776 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 285.195C639.795 285.195 640.597 284.392 640.597 283.401C640.597 282.409 639.795 281.606 638.806 281.606C637.817 281.606 637.015 282.409 637.015 283.401C637.015 284.392 637.817 285.195 638.806 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 285.195C633.825 285.195 634.627 284.392 634.627 283.401C634.627 282.409 633.825 281.606 632.836 281.606C631.847 281.606 631.045 282.409 631.045 283.401C631.045 284.392 631.847 285.195 632.836 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 285.195C627.855 285.195 628.657 284.392 628.657 283.401C628.657 282.409 627.855 281.606 626.866 281.606C625.876 281.606 625.075 282.409 625.075 283.401C625.075 284.392 625.876 285.195 626.866 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 285.195C621.885 285.195 622.687 284.392 622.687 283.401C622.687 282.409 621.885 281.606 620.895 281.606C619.906 281.606 619.104 282.409 619.104 283.401C619.104 284.392 619.906 285.195 620.895 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 285.195C615.915 285.195 616.716 284.392 616.716 283.401C616.716 282.409 615.915 281.606 614.925 281.606C613.936 281.606 613.134 282.409 613.134 283.401C613.134 284.392 613.936 285.195 614.925 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 285.195C609.944 285.195 610.746 284.392 610.746 283.401C610.746 282.409 609.944 281.606 608.955 281.606C607.966 281.606 607.164 282.409 607.164 283.401C607.164 284.392 607.966 285.195 608.955 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 285.195C603.974 285.195 604.776 284.392 604.776 283.401C604.776 282.409 603.974 281.606 602.985 281.606C601.996 281.606 601.194 282.409 601.194 283.401C601.194 284.392 601.996 285.195 602.985 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 285.195C598.004 285.195 598.806 284.392 598.806 283.401C598.806 282.409 598.004 281.606 597.015 281.606C596.026 281.606 595.224 282.409 595.224 283.401C595.224 284.392 596.026 285.195 597.015 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 285.195C592.034 285.195 592.836 284.392 592.836 283.401C592.836 282.409 592.034 281.606 591.045 281.606C590.056 281.606 589.254 282.409 589.254 283.401C589.254 284.392 590.056 285.195 591.045 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 285.195C586.064 285.195 586.866 284.392 586.866 283.401C586.866 282.409 586.064 281.606 585.075 281.606C584.085 281.606 583.284 282.409 583.284 283.401C583.284 284.392 584.085 285.195 585.075 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 285.195C580.094 285.195 580.896 284.392 580.896 283.401C580.896 282.409 580.094 281.606 579.104 281.606C578.115 281.606 577.313 282.409 577.313 283.401C577.313 284.392 578.115 285.195 579.104 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 285.195C574.123 285.195 574.925 284.392 574.925 283.401C574.925 282.409 574.123 281.606 573.134 281.606C572.145 281.606 571.343 282.409 571.343 283.401C571.343 284.392 572.145 285.195 573.134 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 285.195C568.153 285.195 568.955 284.392 568.955 283.401C568.955 282.409 568.153 281.606 567.164 281.606C566.175 281.606 565.373 282.409 565.373 283.401C565.373 284.392 566.175 285.195 567.164 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 285.195C562.183 285.195 562.985 284.392 562.985 283.401C562.985 282.409 562.183 281.606 561.194 281.606C560.205 281.606 559.403 282.409 559.403 283.401C559.403 284.392 560.205 285.195 561.194 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 285.195C556.213 285.195 557.015 284.392 557.015 283.401C557.015 282.409 556.213 281.606 555.224 281.606C554.235 281.606 553.433 282.409 553.433 283.401C553.433 284.392 554.235 285.195 555.224 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 285.195C550.243 285.195 551.045 284.392 551.045 283.401C551.045 282.409 550.243 281.606 549.254 281.606C548.265 281.606 547.463 282.409 547.463 283.401C547.463 284.392 548.265 285.195 549.254 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 285.195C544.273 285.195 545.075 284.392 545.075 283.401C545.075 282.409 544.273 281.606 543.284 281.606C542.294 281.606 541.492 282.409 541.492 283.401C541.492 284.392 542.294 285.195 543.284 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 285.195C538.303 285.195 539.104 284.392 539.104 283.401C539.104 282.409 538.303 281.606 537.313 281.606C536.324 281.606 535.522 282.409 535.522 283.401C535.522 284.392 536.324 285.195 537.313 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 285.195C532.332 285.195 533.134 284.392 533.134 283.401C533.134 282.409 532.332 281.606 531.343 281.606C530.354 281.606 529.552 282.409 529.552 283.401C529.552 284.392 530.354 285.195 531.343 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 285.195C526.362 285.195 527.164 284.392 527.164 283.401C527.164 282.409 526.362 281.606 525.373 281.606C524.384 281.606 523.582 282.409 523.582 283.401C523.582 284.392 524.384 285.195 525.373 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 285.195C520.392 285.195 521.194 284.392 521.194 283.401C521.194 282.409 520.392 281.606 519.403 281.606C518.414 281.606 517.612 282.409 517.612 283.401C517.612 284.392 518.414 285.195 519.403 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 285.195C514.422 285.195 515.224 284.392 515.224 283.401C515.224 282.409 514.422 281.606 513.433 281.606C512.444 281.606 511.642 282.409 511.642 283.401C511.642 284.392 512.444 285.195 513.433 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 285.195C508.452 285.195 509.254 284.392 509.254 283.401C509.254 282.409 508.452 281.606 507.463 281.606C506.474 281.606 505.672 282.409 505.672 283.401C505.672 284.392 506.474 285.195 507.463 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 285.195C502.482 285.195 503.284 284.392 503.284 283.401C503.284 282.409 502.482 281.606 501.493 281.606C500.503 281.606 499.702 282.409 499.702 283.401C499.702 284.392 500.503 285.195 501.493 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 285.195C496.512 285.195 497.313 284.392 497.313 283.401C497.313 282.409 496.512 281.606 495.522 281.606C494.533 281.606 493.731 282.409 493.731 283.401C493.731 284.392 494.533 285.195 495.522 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 285.195C490.541 285.195 491.343 284.392 491.343 283.401C491.343 282.409 490.541 281.606 489.552 281.606C488.563 281.606 487.761 282.409 487.761 283.401C487.761 284.392 488.563 285.195 489.552 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 285.195C484.571 285.195 485.373 284.392 485.373 283.401C485.373 282.409 484.571 281.606 483.582 281.606C482.593 281.606 481.791 282.409 481.791 283.401C481.791 284.392 482.593 285.195 483.582 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 285.195C478.601 285.195 479.403 284.392 479.403 283.401C479.403 282.409 478.601 281.606 477.612 281.606C476.623 281.606 475.821 282.409 475.821 283.401C475.821 284.392 476.623 285.195 477.612 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 285.195C472.631 285.195 473.433 284.392 473.433 283.401C473.433 282.409 472.631 281.606 471.642 281.606C470.653 281.606 469.851 282.409 469.851 283.401C469.851 284.392 470.653 285.195 471.642 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 285.195C466.661 285.195 467.463 284.392 467.463 283.401C467.463 282.409 466.661 281.606 465.672 281.606C464.682 281.606 463.881 282.409 463.881 283.401C463.881 284.392 464.682 285.195 465.672 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 279.213C1009.94 279.213 1010.75 278.409 1010.75 277.418C1010.75 276.427 1009.94 275.624 1008.96 275.624C1007.97 275.624 1007.16 276.427 1007.16 277.418C1007.16 278.409 1007.97 279.213 1008.96 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 279.213C1003.97 279.213 1004.78 278.409 1004.78 277.418C1004.78 276.427 1003.97 275.624 1002.99 275.624C1002 275.624 1001.19 276.427 1001.19 277.418C1001.19 278.409 1002 279.213 1002.99 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 279.213C956.213 279.213 957.015 278.409 957.015 277.418C957.015 276.427 956.213 275.624 955.224 275.624C954.235 275.624 953.433 276.427 953.433 277.418C953.433 278.409 954.235 279.213 955.224 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 279.213C950.243 279.213 951.045 278.409 951.045 277.418C951.045 276.427 950.243 275.624 949.254 275.624C948.265 275.624 947.463 276.427 947.463 277.418C947.463 278.409 948.265 279.213 949.254 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 279.213C944.273 279.213 945.075 278.409 945.075 277.418C945.075 276.427 944.273 275.624 943.284 275.624C942.294 275.624 941.492 276.427 941.492 277.418C941.492 278.409 942.294 279.213 943.284 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 279.213C938.303 279.213 939.105 278.409 939.105 277.418C939.105 276.427 938.303 275.624 937.313 275.624C936.324 275.624 935.522 276.427 935.522 277.418C935.522 278.409 936.324 279.213 937.313 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 279.213C932.332 279.213 933.134 278.409 933.134 277.418C933.134 276.427 932.332 275.624 931.343 275.624C930.354 275.624 929.552 276.427 929.552 277.418C929.552 278.409 930.354 279.213 931.343 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 279.213C926.362 279.213 927.164 278.409 927.164 277.418C927.164 276.427 926.362 275.624 925.373 275.624C924.384 275.624 923.582 276.427 923.582 277.418C923.582 278.409 924.384 279.213 925.373 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 279.213C920.392 279.213 921.194 278.409 921.194 277.418C921.194 276.427 920.392 275.624 919.403 275.624C918.414 275.624 917.612 276.427 917.612 277.418C917.612 278.409 918.414 279.213 919.403 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 279.213C914.422 279.213 915.224 278.409 915.224 277.418C915.224 276.427 914.422 275.624 913.433 275.624C912.444 275.624 911.642 276.427 911.642 277.418C911.642 278.409 912.444 279.213 913.433 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 279.213C842.78 279.213 843.582 278.409 843.582 277.418C843.582 276.427 842.78 275.624 841.791 275.624C840.802 275.624 840 276.427 840 277.418C840 278.409 840.802 279.213 841.791 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 279.213C836.81 279.213 837.612 278.409 837.612 277.418C837.612 276.427 836.81 275.624 835.821 275.624C834.832 275.624 834.03 276.427 834.03 277.418C834.03 278.409 834.832 279.213 835.821 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 279.213C830.84 279.213 831.642 278.409 831.642 277.418C831.642 276.427 830.84 275.624 829.851 275.624C828.862 275.624 828.06 276.427 828.06 277.418C828.06 278.409 828.862 279.213 829.851 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 279.213C824.87 279.213 825.672 278.409 825.672 277.418C825.672 276.427 824.87 275.624 823.881 275.624C822.891 275.624 822.09 276.427 822.09 277.418C822.09 278.409 822.891 279.213 823.881 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 279.213C729.347 279.213 730.149 278.409 730.149 277.418C730.149 276.427 729.347 275.624 728.358 275.624C727.369 275.624 726.567 276.427 726.567 277.418C726.567 278.409 727.369 279.213 728.358 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 279.213C723.377 279.213 724.179 278.409 724.179 277.418C724.179 276.427 723.377 275.624 722.388 275.624C721.399 275.624 720.597 276.427 720.597 277.418C720.597 278.409 721.399 279.213 722.388 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 279.213C717.407 279.213 718.209 278.409 718.209 277.418C718.209 276.427 717.407 275.624 716.418 275.624C715.429 275.624 714.627 276.427 714.627 277.418C714.627 278.409 715.429 279.213 716.418 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 279.213C711.437 279.213 712.239 278.409 712.239 277.418C712.239 276.427 711.437 275.624 710.448 275.624C709.459 275.624 708.657 276.427 708.657 277.418C708.657 278.409 709.459 279.213 710.448 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 279.213C705.467 279.213 706.269 278.409 706.269 277.418C706.269 276.427 705.467 275.624 704.478 275.624C703.488 275.624 702.687 276.427 702.687 277.418C702.687 278.409 703.488 279.213 704.478 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 279.213C687.556 279.213 688.358 278.409 688.358 277.418C688.358 276.427 687.556 275.624 686.567 275.624C685.578 275.624 684.776 276.427 684.776 277.418C684.776 278.409 685.578 279.213 686.567 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 279.213C681.586 279.213 682.388 278.409 682.388 277.418C682.388 276.427 681.586 275.624 680.597 275.624C679.608 275.624 678.806 276.427 678.806 277.418C678.806 278.409 679.608 279.213 680.597 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 279.213C675.616 279.213 676.418 278.409 676.418 277.418C676.418 276.427 675.616 275.624 674.627 275.624C673.638 275.624 672.836 276.427 672.836 277.418C672.836 278.409 673.638 279.213 674.627 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 279.213C669.646 279.213 670.448 278.409 670.448 277.418C670.448 276.427 669.646 275.624 668.657 275.624C667.668 275.624 666.866 276.427 666.866 277.418C666.866 278.409 667.668 279.213 668.657 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 279.213C663.676 279.213 664.478 278.409 664.478 277.418C664.478 276.427 663.676 275.624 662.687 275.624C661.697 275.624 660.896 276.427 660.896 277.418C660.896 278.409 661.697 279.213 662.687 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 279.213C657.706 279.213 658.507 278.409 658.507 277.418C658.507 276.427 657.706 275.624 656.716 275.624C655.727 275.624 654.925 276.427 654.925 277.418C654.925 278.409 655.727 279.213 656.716 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 279.213C651.735 279.213 652.537 278.409 652.537 277.418C652.537 276.427 651.735 275.624 650.746 275.624C649.757 275.624 648.955 276.427 648.955 277.418C648.955 278.409 649.757 279.213 650.746 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 279.213C645.765 279.213 646.567 278.409 646.567 277.418C646.567 276.427 645.765 275.624 644.776 275.624C643.787 275.624 642.985 276.427 642.985 277.418C642.985 278.409 643.787 279.213 644.776 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 279.213C639.795 279.213 640.597 278.409 640.597 277.418C640.597 276.427 639.795 275.624 638.806 275.624C637.817 275.624 637.015 276.427 637.015 277.418C637.015 278.409 637.817 279.213 638.806 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 279.213C633.825 279.213 634.627 278.409 634.627 277.418C634.627 276.427 633.825 275.624 632.836 275.624C631.847 275.624 631.045 276.427 631.045 277.418C631.045 278.409 631.847 279.213 632.836 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 279.213C627.855 279.213 628.657 278.409 628.657 277.418C628.657 276.427 627.855 275.624 626.866 275.624C625.876 275.624 625.075 276.427 625.075 277.418C625.075 278.409 625.876 279.213 626.866 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 279.213C621.885 279.213 622.687 278.409 622.687 277.418C622.687 276.427 621.885 275.624 620.895 275.624C619.906 275.624 619.104 276.427 619.104 277.418C619.104 278.409 619.906 279.213 620.895 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 279.213C615.915 279.213 616.716 278.409 616.716 277.418C616.716 276.427 615.915 275.624 614.925 275.624C613.936 275.624 613.134 276.427 613.134 277.418C613.134 278.409 613.936 279.213 614.925 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 279.213C609.944 279.213 610.746 278.409 610.746 277.418C610.746 276.427 609.944 275.624 608.955 275.624C607.966 275.624 607.164 276.427 607.164 277.418C607.164 278.409 607.966 279.213 608.955 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 279.213C603.974 279.213 604.776 278.409 604.776 277.418C604.776 276.427 603.974 275.624 602.985 275.624C601.996 275.624 601.194 276.427 601.194 277.418C601.194 278.409 601.996 279.213 602.985 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 279.213C598.004 279.213 598.806 278.409 598.806 277.418C598.806 276.427 598.004 275.624 597.015 275.624C596.026 275.624 595.224 276.427 595.224 277.418C595.224 278.409 596.026 279.213 597.015 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 279.213C592.034 279.213 592.836 278.409 592.836 277.418C592.836 276.427 592.034 275.624 591.045 275.624C590.056 275.624 589.254 276.427 589.254 277.418C589.254 278.409 590.056 279.213 591.045 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 279.213C586.064 279.213 586.866 278.409 586.866 277.418C586.866 276.427 586.064 275.624 585.075 275.624C584.085 275.624 583.284 276.427 583.284 277.418C583.284 278.409 584.085 279.213 585.075 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 279.213C580.094 279.213 580.896 278.409 580.896 277.418C580.896 276.427 580.094 275.624 579.104 275.624C578.115 275.624 577.313 276.427 577.313 277.418C577.313 278.409 578.115 279.213 579.104 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 279.213C574.123 279.213 574.925 278.409 574.925 277.418C574.925 276.427 574.123 275.624 573.134 275.624C572.145 275.624 571.343 276.427 571.343 277.418C571.343 278.409 572.145 279.213 573.134 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 279.213C568.153 279.213 568.955 278.409 568.955 277.418C568.955 276.427 568.153 275.624 567.164 275.624C566.175 275.624 565.373 276.427 565.373 277.418C565.373 278.409 566.175 279.213 567.164 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 279.213C562.183 279.213 562.985 278.409 562.985 277.418C562.985 276.427 562.183 275.624 561.194 275.624C560.205 275.624 559.403 276.427 559.403 277.418C559.403 278.409 560.205 279.213 561.194 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 279.213C556.213 279.213 557.015 278.409 557.015 277.418C557.015 276.427 556.213 275.624 555.224 275.624C554.235 275.624 553.433 276.427 553.433 277.418C553.433 278.409 554.235 279.213 555.224 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 279.213C550.243 279.213 551.045 278.409 551.045 277.418C551.045 276.427 550.243 275.624 549.254 275.624C548.265 275.624 547.463 276.427 547.463 277.418C547.463 278.409 548.265 279.213 549.254 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 279.213C544.273 279.213 545.075 278.409 545.075 277.418C545.075 276.427 544.273 275.624 543.284 275.624C542.294 275.624 541.492 276.427 541.492 277.418C541.492 278.409 542.294 279.213 543.284 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 279.213C538.303 279.213 539.104 278.409 539.104 277.418C539.104 276.427 538.303 275.624 537.313 275.624C536.324 275.624 535.522 276.427 535.522 277.418C535.522 278.409 536.324 279.213 537.313 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 279.213C532.332 279.213 533.134 278.409 533.134 277.418C533.134 276.427 532.332 275.624 531.343 275.624C530.354 275.624 529.552 276.427 529.552 277.418C529.552 278.409 530.354 279.213 531.343 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 279.213C526.362 279.213 527.164 278.409 527.164 277.418C527.164 276.427 526.362 275.624 525.373 275.624C524.384 275.624 523.582 276.427 523.582 277.418C523.582 278.409 524.384 279.213 525.373 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 279.213C520.392 279.213 521.194 278.409 521.194 277.418C521.194 276.427 520.392 275.624 519.403 275.624C518.414 275.624 517.612 276.427 517.612 277.418C517.612 278.409 518.414 279.213 519.403 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 279.213C514.422 279.213 515.224 278.409 515.224 277.418C515.224 276.427 514.422 275.624 513.433 275.624C512.444 275.624 511.642 276.427 511.642 277.418C511.642 278.409 512.444 279.213 513.433 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 279.213C508.452 279.213 509.254 278.409 509.254 277.418C509.254 276.427 508.452 275.624 507.463 275.624C506.474 275.624 505.672 276.427 505.672 277.418C505.672 278.409 506.474 279.213 507.463 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 279.213C502.482 279.213 503.284 278.409 503.284 277.418C503.284 276.427 502.482 275.624 501.493 275.624C500.503 275.624 499.702 276.427 499.702 277.418C499.702 278.409 500.503 279.213 501.493 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 279.213C496.512 279.213 497.313 278.409 497.313 277.418C497.313 276.427 496.512 275.624 495.522 275.624C494.533 275.624 493.731 276.427 493.731 277.418C493.731 278.409 494.533 279.213 495.522 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 279.213C490.541 279.213 491.343 278.409 491.343 277.418C491.343 276.427 490.541 275.624 489.552 275.624C488.563 275.624 487.761 276.427 487.761 277.418C487.761 278.409 488.563 279.213 489.552 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 279.213C484.571 279.213 485.373 278.409 485.373 277.418C485.373 276.427 484.571 275.624 483.582 275.624C482.593 275.624 481.791 276.427 481.791 277.418C481.791 278.409 482.593 279.213 483.582 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 279.213C478.601 279.213 479.403 278.409 479.403 277.418C479.403 276.427 478.601 275.624 477.612 275.624C476.623 275.624 475.821 276.427 475.821 277.418C475.821 278.409 476.623 279.213 477.612 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 279.213C472.631 279.213 473.433 278.409 473.433 277.418C473.433 276.427 472.631 275.624 471.642 275.624C470.653 275.624 469.851 276.427 469.851 277.418C469.851 278.409 470.653 279.213 471.642 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 279.213C466.661 279.213 467.463 278.409 467.463 277.418C467.463 276.427 466.661 275.624 465.672 275.624C464.682 275.624 463.881 276.427 463.881 277.418C463.881 278.409 464.682 279.213 465.672 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 273.23C1009.94 273.23 1010.75 272.427 1010.75 271.436C1010.75 270.445 1009.94 269.641 1008.96 269.641C1007.97 269.641 1007.16 270.445 1007.16 271.436C1007.16 272.427 1007.97 273.23 1008.96 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 273.23C1003.97 273.23 1004.78 272.427 1004.78 271.436C1004.78 270.445 1003.97 269.641 1002.99 269.641C1002 269.641 1001.19 270.445 1001.19 271.436C1001.19 272.427 1002 273.23 1002.99 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 273.23C950.243 273.23 951.045 272.427 951.045 271.436C951.045 270.445 950.243 269.641 949.254 269.641C948.265 269.641 947.463 270.445 947.463 271.436C947.463 272.427 948.265 273.23 949.254 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 273.23C944.273 273.23 945.075 272.427 945.075 271.436C945.075 270.445 944.273 269.641 943.284 269.641C942.294 269.641 941.492 270.445 941.492 271.436C941.492 272.427 942.294 273.23 943.284 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 273.23C938.303 273.23 939.105 272.427 939.105 271.436C939.105 270.445 938.303 269.641 937.313 269.641C936.324 269.641 935.522 270.445 935.522 271.436C935.522 272.427 936.324 273.23 937.313 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 273.23C932.332 273.23 933.134 272.427 933.134 271.436C933.134 270.445 932.332 269.641 931.343 269.641C930.354 269.641 929.552 270.445 929.552 271.436C929.552 272.427 930.354 273.23 931.343 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 273.23C926.362 273.23 927.164 272.427 927.164 271.436C927.164 270.445 926.362 269.641 925.373 269.641C924.384 269.641 923.582 270.445 923.582 271.436C923.582 272.427 924.384 273.23 925.373 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 273.23C920.392 273.23 921.194 272.427 921.194 271.436C921.194 270.445 920.392 269.641 919.403 269.641C918.414 269.641 917.612 270.445 917.612 271.436C917.612 272.427 918.414 273.23 919.403 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 273.23C914.422 273.23 915.224 272.427 915.224 271.436C915.224 270.445 914.422 269.641 913.433 269.641C912.444 269.641 911.642 270.445 911.642 271.436C911.642 272.427 912.444 273.23 913.433 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 273.23C908.452 273.23 909.254 272.427 909.254 271.436C909.254 270.445 908.452 269.641 907.463 269.641C906.474 269.641 905.672 270.445 905.672 271.436C905.672 272.427 906.474 273.23 907.463 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 273.23C902.482 273.23 903.284 272.427 903.284 271.436C903.284 270.445 902.482 269.641 901.492 269.641C900.503 269.641 899.701 270.445 899.701 271.436C899.701 272.427 900.503 273.23 901.492 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 273.23C848.75 273.23 849.552 272.427 849.552 271.436C849.552 270.445 848.75 269.641 847.761 269.641C846.772 269.641 845.97 270.445 845.97 271.436C845.97 272.427 846.772 273.23 847.761 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 273.23C842.78 273.23 843.582 272.427 843.582 271.436C843.582 270.445 842.78 269.641 841.791 269.641C840.802 269.641 840 270.445 840 271.436C840 272.427 840.802 273.23 841.791 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 273.23C836.81 273.23 837.612 272.427 837.612 271.436C837.612 270.445 836.81 269.641 835.821 269.641C834.832 269.641 834.03 270.445 834.03 271.436C834.03 272.427 834.832 273.23 835.821 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 273.23C830.84 273.23 831.642 272.427 831.642 271.436C831.642 270.445 830.84 269.641 829.851 269.641C828.862 269.641 828.06 270.445 828.06 271.436C828.06 272.427 828.862 273.23 829.851 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 273.23C824.87 273.23 825.672 272.427 825.672 271.436C825.672 270.445 824.87 269.641 823.881 269.641C822.891 269.641 822.09 270.445 822.09 271.436C822.09 272.427 822.891 273.23 823.881 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 273.23C818.9 273.23 819.701 272.427 819.701 271.436C819.701 270.445 818.9 269.641 817.91 269.641C816.921 269.641 816.119 270.445 816.119 271.436C816.119 272.427 816.921 273.23 817.91 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 273.23C735.318 273.23 736.119 272.427 736.119 271.436C736.119 270.445 735.318 269.641 734.328 269.641C733.339 269.641 732.537 270.445 732.537 271.436C732.537 272.427 733.339 273.23 734.328 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 273.23C729.347 273.23 730.149 272.427 730.149 271.436C730.149 270.445 729.347 269.641 728.358 269.641C727.369 269.641 726.567 270.445 726.567 271.436C726.567 272.427 727.369 273.23 728.358 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 273.23C723.377 273.23 724.179 272.427 724.179 271.436C724.179 270.445 723.377 269.641 722.388 269.641C721.399 269.641 720.597 270.445 720.597 271.436C720.597 272.427 721.399 273.23 722.388 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 273.23C717.407 273.23 718.209 272.427 718.209 271.436C718.209 270.445 717.407 269.641 716.418 269.641C715.429 269.641 714.627 270.445 714.627 271.436C714.627 272.427 715.429 273.23 716.418 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 273.23C711.437 273.23 712.239 272.427 712.239 271.436C712.239 270.445 711.437 269.641 710.448 269.641C709.459 269.641 708.657 270.445 708.657 271.436C708.657 272.427 709.459 273.23 710.448 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 273.23C705.467 273.23 706.269 272.427 706.269 271.436C706.269 270.445 705.467 269.641 704.478 269.641C703.488 269.641 702.687 270.445 702.687 271.436C702.687 272.427 703.488 273.23 704.478 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 273.23C699.497 273.23 700.299 272.427 700.299 271.436C700.299 270.445 699.497 269.641 698.507 269.641C697.518 269.641 696.716 270.445 696.716 271.436C696.716 272.427 697.518 273.23 698.507 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 273.23C681.586 273.23 682.388 272.427 682.388 271.436C682.388 270.445 681.586 269.641 680.597 269.641C679.608 269.641 678.806 270.445 678.806 271.436C678.806 272.427 679.608 273.23 680.597 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 273.23C675.616 273.23 676.418 272.427 676.418 271.436C676.418 270.445 675.616 269.641 674.627 269.641C673.638 269.641 672.836 270.445 672.836 271.436C672.836 272.427 673.638 273.23 674.627 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 273.23C669.646 273.23 670.448 272.427 670.448 271.436C670.448 270.445 669.646 269.641 668.657 269.641C667.668 269.641 666.866 270.445 666.866 271.436C666.866 272.427 667.668 273.23 668.657 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 273.23C663.676 273.23 664.478 272.427 664.478 271.436C664.478 270.445 663.676 269.641 662.687 269.641C661.697 269.641 660.896 270.445 660.896 271.436C660.896 272.427 661.697 273.23 662.687 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 273.23C657.706 273.23 658.507 272.427 658.507 271.436C658.507 270.445 657.706 269.641 656.716 269.641C655.727 269.641 654.925 270.445 654.925 271.436C654.925 272.427 655.727 273.23 656.716 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 273.23C651.735 273.23 652.537 272.427 652.537 271.436C652.537 270.445 651.735 269.641 650.746 269.641C649.757 269.641 648.955 270.445 648.955 271.436C648.955 272.427 649.757 273.23 650.746 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 273.23C645.765 273.23 646.567 272.427 646.567 271.436C646.567 270.445 645.765 269.641 644.776 269.641C643.787 269.641 642.985 270.445 642.985 271.436C642.985 272.427 643.787 273.23 644.776 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 273.23C639.795 273.23 640.597 272.427 640.597 271.436C640.597 270.445 639.795 269.641 638.806 269.641C637.817 269.641 637.015 270.445 637.015 271.436C637.015 272.427 637.817 273.23 638.806 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 273.23C633.825 273.23 634.627 272.427 634.627 271.436C634.627 270.445 633.825 269.641 632.836 269.641C631.847 269.641 631.045 270.445 631.045 271.436C631.045 272.427 631.847 273.23 632.836 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 273.23C627.855 273.23 628.657 272.427 628.657 271.436C628.657 270.445 627.855 269.641 626.866 269.641C625.876 269.641 625.075 270.445 625.075 271.436C625.075 272.427 625.876 273.23 626.866 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 273.23C621.885 273.23 622.687 272.427 622.687 271.436C622.687 270.445 621.885 269.641 620.895 269.641C619.906 269.641 619.104 270.445 619.104 271.436C619.104 272.427 619.906 273.23 620.895 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 273.23C615.915 273.23 616.716 272.427 616.716 271.436C616.716 270.445 615.915 269.641 614.925 269.641C613.936 269.641 613.134 270.445 613.134 271.436C613.134 272.427 613.936 273.23 614.925 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 273.23C609.944 273.23 610.746 272.427 610.746 271.436C610.746 270.445 609.944 269.641 608.955 269.641C607.966 269.641 607.164 270.445 607.164 271.436C607.164 272.427 607.966 273.23 608.955 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 273.23C603.974 273.23 604.776 272.427 604.776 271.436C604.776 270.445 603.974 269.641 602.985 269.641C601.996 269.641 601.194 270.445 601.194 271.436C601.194 272.427 601.996 273.23 602.985 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 273.23C598.004 273.23 598.806 272.427 598.806 271.436C598.806 270.445 598.004 269.641 597.015 269.641C596.026 269.641 595.224 270.445 595.224 271.436C595.224 272.427 596.026 273.23 597.015 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 273.23C592.034 273.23 592.836 272.427 592.836 271.436C592.836 270.445 592.034 269.641 591.045 269.641C590.056 269.641 589.254 270.445 589.254 271.436C589.254 272.427 590.056 273.23 591.045 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 273.23C586.064 273.23 586.866 272.427 586.866 271.436C586.866 270.445 586.064 269.641 585.075 269.641C584.085 269.641 583.284 270.445 583.284 271.436C583.284 272.427 584.085 273.23 585.075 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 273.23C580.094 273.23 580.896 272.427 580.896 271.436C580.896 270.445 580.094 269.641 579.104 269.641C578.115 269.641 577.313 270.445 577.313 271.436C577.313 272.427 578.115 273.23 579.104 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 273.23C574.123 273.23 574.925 272.427 574.925 271.436C574.925 270.445 574.123 269.641 573.134 269.641C572.145 269.641 571.343 270.445 571.343 271.436C571.343 272.427 572.145 273.23 573.134 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 273.23C568.153 273.23 568.955 272.427 568.955 271.436C568.955 270.445 568.153 269.641 567.164 269.641C566.175 269.641 565.373 270.445 565.373 271.436C565.373 272.427 566.175 273.23 567.164 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 273.23C562.183 273.23 562.985 272.427 562.985 271.436C562.985 270.445 562.183 269.641 561.194 269.641C560.205 269.641 559.403 270.445 559.403 271.436C559.403 272.427 560.205 273.23 561.194 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 273.23C556.213 273.23 557.015 272.427 557.015 271.436C557.015 270.445 556.213 269.641 555.224 269.641C554.235 269.641 553.433 270.445 553.433 271.436C553.433 272.427 554.235 273.23 555.224 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 273.23C550.243 273.23 551.045 272.427 551.045 271.436C551.045 270.445 550.243 269.641 549.254 269.641C548.265 269.641 547.463 270.445 547.463 271.436C547.463 272.427 548.265 273.23 549.254 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 273.23C544.273 273.23 545.075 272.427 545.075 271.436C545.075 270.445 544.273 269.641 543.284 269.641C542.294 269.641 541.492 270.445 541.492 271.436C541.492 272.427 542.294 273.23 543.284 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 273.23C538.303 273.23 539.104 272.427 539.104 271.436C539.104 270.445 538.303 269.641 537.313 269.641C536.324 269.641 535.522 270.445 535.522 271.436C535.522 272.427 536.324 273.23 537.313 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 273.23C532.332 273.23 533.134 272.427 533.134 271.436C533.134 270.445 532.332 269.641 531.343 269.641C530.354 269.641 529.552 270.445 529.552 271.436C529.552 272.427 530.354 273.23 531.343 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 273.23C526.362 273.23 527.164 272.427 527.164 271.436C527.164 270.445 526.362 269.641 525.373 269.641C524.384 269.641 523.582 270.445 523.582 271.436C523.582 272.427 524.384 273.23 525.373 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 273.23C520.392 273.23 521.194 272.427 521.194 271.436C521.194 270.445 520.392 269.641 519.403 269.641C518.414 269.641 517.612 270.445 517.612 271.436C517.612 272.427 518.414 273.23 519.403 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 273.23C514.422 273.23 515.224 272.427 515.224 271.436C515.224 270.445 514.422 269.641 513.433 269.641C512.444 269.641 511.642 270.445 511.642 271.436C511.642 272.427 512.444 273.23 513.433 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 273.23C508.452 273.23 509.254 272.427 509.254 271.436C509.254 270.445 508.452 269.641 507.463 269.641C506.474 269.641 505.672 270.445 505.672 271.436C505.672 272.427 506.474 273.23 507.463 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 273.23C502.482 273.23 503.284 272.427 503.284 271.436C503.284 270.445 502.482 269.641 501.493 269.641C500.503 269.641 499.702 270.445 499.702 271.436C499.702 272.427 500.503 273.23 501.493 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 273.23C496.512 273.23 497.313 272.427 497.313 271.436C497.313 270.445 496.512 269.641 495.522 269.641C494.533 269.641 493.731 270.445 493.731 271.436C493.731 272.427 494.533 273.23 495.522 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 273.23C490.541 273.23 491.343 272.427 491.343 271.436C491.343 270.445 490.541 269.641 489.552 269.641C488.563 269.641 487.761 270.445 487.761 271.436C487.761 272.427 488.563 273.23 489.552 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 273.23C484.571 273.23 485.373 272.427 485.373 271.436C485.373 270.445 484.571 269.641 483.582 269.641C482.593 269.641 481.791 270.445 481.791 271.436C481.791 272.427 482.593 273.23 483.582 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 273.23C478.601 273.23 479.403 272.427 479.403 271.436C479.403 270.445 478.601 269.641 477.612 269.641C476.623 269.641 475.821 270.445 475.821 271.436C475.821 272.427 476.623 273.23 477.612 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 273.23C472.631 273.23 473.433 272.427 473.433 271.436C473.433 270.445 472.631 269.641 471.642 269.641C470.653 269.641 469.851 270.445 469.851 271.436C469.851 272.427 470.653 273.23 471.642 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 267.249C1003.97 267.249 1004.78 266.445 1004.78 265.454C1004.78 264.463 1003.97 263.659 1002.99 263.659C1002 263.659 1001.19 264.463 1001.19 265.454C1001.19 266.445 1002 267.249 1002.99 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 267.249C962.183 267.249 962.985 266.445 962.985 265.454C962.985 264.463 962.183 263.659 961.194 263.659C960.205 263.659 959.403 264.463 959.403 265.454C959.403 266.445 960.205 267.249 961.194 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 267.249C944.273 267.249 945.075 266.445 945.075 265.454C945.075 264.463 944.273 263.659 943.284 263.659C942.294 263.659 941.492 264.463 941.492 265.454C941.492 266.445 942.294 267.249 943.284 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 267.249C938.303 267.249 939.105 266.445 939.105 265.454C939.105 264.463 938.303 263.659 937.313 263.659C936.324 263.659 935.522 264.463 935.522 265.454C935.522 266.445 936.324 267.249 937.313 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 267.249C932.332 267.249 933.134 266.445 933.134 265.454C933.134 264.463 932.332 263.659 931.343 263.659C930.354 263.659 929.552 264.463 929.552 265.454C929.552 266.445 930.354 267.249 931.343 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 267.249C926.362 267.249 927.164 266.445 927.164 265.454C927.164 264.463 926.362 263.659 925.373 263.659C924.384 263.659 923.582 264.463 923.582 265.454C923.582 266.445 924.384 267.249 925.373 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 267.249C920.392 267.249 921.194 266.445 921.194 265.454C921.194 264.463 920.392 263.659 919.403 263.659C918.414 263.659 917.612 264.463 917.612 265.454C917.612 266.445 918.414 267.249 919.403 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 267.249C914.422 267.249 915.224 266.445 915.224 265.454C915.224 264.463 914.422 263.659 913.433 263.659C912.444 263.659 911.642 264.463 911.642 265.454C911.642 266.445 912.444 267.249 913.433 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 267.249C908.452 267.249 909.254 266.445 909.254 265.454C909.254 264.463 908.452 263.659 907.463 263.659C906.474 263.659 905.672 264.463 905.672 265.454C905.672 266.445 906.474 267.249 907.463 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 267.249C902.482 267.249 903.284 266.445 903.284 265.454C903.284 264.463 902.482 263.659 901.492 263.659C900.503 263.659 899.701 264.463 899.701 265.454C899.701 266.445 900.503 267.249 901.492 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 267.249C854.721 267.249 855.522 266.445 855.522 265.454C855.522 264.463 854.721 263.659 853.731 263.659C852.742 263.659 851.94 264.463 851.94 265.454C851.94 266.445 852.742 267.249 853.731 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 267.249C848.75 267.249 849.552 266.445 849.552 265.454C849.552 264.463 848.75 263.659 847.761 263.659C846.772 263.659 845.97 264.463 845.97 265.454C845.97 266.445 846.772 267.249 847.761 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 267.249C842.78 267.249 843.582 266.445 843.582 265.454C843.582 264.463 842.78 263.659 841.791 263.659C840.802 263.659 840 264.463 840 265.454C840 266.445 840.802 267.249 841.791 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 267.249C830.84 267.249 831.642 266.445 831.642 265.454C831.642 264.463 830.84 263.659 829.851 263.659C828.862 263.659 828.06 264.463 828.06 265.454C828.06 266.445 828.862 267.249 829.851 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 267.249C824.87 267.249 825.672 266.445 825.672 265.454C825.672 264.463 824.87 263.659 823.881 263.659C822.891 263.659 822.09 264.463 822.09 265.454C822.09 266.445 822.891 267.249 823.881 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 267.249C818.9 267.249 819.701 266.445 819.701 265.454C819.701 264.463 818.9 263.659 817.91 263.659C816.921 263.659 816.119 264.463 816.119 265.454C816.119 266.445 816.921 267.249 817.91 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 267.249C747.258 267.249 748.06 266.445 748.06 265.454C748.06 264.463 747.258 263.659 746.269 263.659C745.279 263.659 744.478 264.463 744.478 265.454C744.478 266.445 745.279 267.249 746.269 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 267.249C741.288 267.249 742.09 266.445 742.09 265.454C742.09 264.463 741.288 263.659 740.299 263.659C739.309 263.659 738.507 264.463 738.507 265.454C738.507 266.445 739.309 267.249 740.299 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 267.249C735.318 267.249 736.119 266.445 736.119 265.454C736.119 264.463 735.318 263.659 734.328 263.659C733.339 263.659 732.537 264.463 732.537 265.454C732.537 266.445 733.339 267.249 734.328 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 267.249C729.347 267.249 730.149 266.445 730.149 265.454C730.149 264.463 729.347 263.659 728.358 263.659C727.369 263.659 726.567 264.463 726.567 265.454C726.567 266.445 727.369 267.249 728.358 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 267.249C723.377 267.249 724.179 266.445 724.179 265.454C724.179 264.463 723.377 263.659 722.388 263.659C721.399 263.659 720.597 264.463 720.597 265.454C720.597 266.445 721.399 267.249 722.388 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 267.249C717.407 267.249 718.209 266.445 718.209 265.454C718.209 264.463 717.407 263.659 716.418 263.659C715.429 263.659 714.627 264.463 714.627 265.454C714.627 266.445 715.429 267.249 716.418 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 267.249C711.437 267.249 712.239 266.445 712.239 265.454C712.239 264.463 711.437 263.659 710.448 263.659C709.459 263.659 708.657 264.463 708.657 265.454C708.657 266.445 709.459 267.249 710.448 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 267.249C705.467 267.249 706.269 266.445 706.269 265.454C706.269 264.463 705.467 263.659 704.478 263.659C703.488 263.659 702.687 264.463 702.687 265.454C702.687 266.445 703.488 267.249 704.478 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 267.249C699.497 267.249 700.299 266.445 700.299 265.454C700.299 264.463 699.497 263.659 698.507 263.659C697.518 263.659 696.716 264.463 696.716 265.454C696.716 266.445 697.518 267.249 698.507 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 267.249C681.586 267.249 682.388 266.445 682.388 265.454C682.388 264.463 681.586 263.659 680.597 263.659C679.608 263.659 678.806 264.463 678.806 265.454C678.806 266.445 679.608 267.249 680.597 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 267.249C675.616 267.249 676.418 266.445 676.418 265.454C676.418 264.463 675.616 263.659 674.627 263.659C673.638 263.659 672.836 264.463 672.836 265.454C672.836 266.445 673.638 267.249 674.627 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 267.249C669.646 267.249 670.448 266.445 670.448 265.454C670.448 264.463 669.646 263.659 668.657 263.659C667.668 263.659 666.866 264.463 666.866 265.454C666.866 266.445 667.668 267.249 668.657 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 267.249C663.676 267.249 664.478 266.445 664.478 265.454C664.478 264.463 663.676 263.659 662.687 263.659C661.697 263.659 660.896 264.463 660.896 265.454C660.896 266.445 661.697 267.249 662.687 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 267.249C657.706 267.249 658.507 266.445 658.507 265.454C658.507 264.463 657.706 263.659 656.716 263.659C655.727 263.659 654.925 264.463 654.925 265.454C654.925 266.445 655.727 267.249 656.716 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 267.249C651.735 267.249 652.537 266.445 652.537 265.454C652.537 264.463 651.735 263.659 650.746 263.659C649.757 263.659 648.955 264.463 648.955 265.454C648.955 266.445 649.757 267.249 650.746 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 267.249C645.765 267.249 646.567 266.445 646.567 265.454C646.567 264.463 645.765 263.659 644.776 263.659C643.787 263.659 642.985 264.463 642.985 265.454C642.985 266.445 643.787 267.249 644.776 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 267.249C639.795 267.249 640.597 266.445 640.597 265.454C640.597 264.463 639.795 263.659 638.806 263.659C637.817 263.659 637.015 264.463 637.015 265.454C637.015 266.445 637.817 267.249 638.806 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 267.249C633.825 267.249 634.627 266.445 634.627 265.454C634.627 264.463 633.825 263.659 632.836 263.659C631.847 263.659 631.045 264.463 631.045 265.454C631.045 266.445 631.847 267.249 632.836 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 267.249C627.855 267.249 628.657 266.445 628.657 265.454C628.657 264.463 627.855 263.659 626.866 263.659C625.876 263.659 625.075 264.463 625.075 265.454C625.075 266.445 625.876 267.249 626.866 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 267.249C621.885 267.249 622.687 266.445 622.687 265.454C622.687 264.463 621.885 263.659 620.895 263.659C619.906 263.659 619.104 264.463 619.104 265.454C619.104 266.445 619.906 267.249 620.895 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 267.249C615.915 267.249 616.716 266.445 616.716 265.454C616.716 264.463 615.915 263.659 614.925 263.659C613.936 263.659 613.134 264.463 613.134 265.454C613.134 266.445 613.936 267.249 614.925 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 267.249C609.944 267.249 610.746 266.445 610.746 265.454C610.746 264.463 609.944 263.659 608.955 263.659C607.966 263.659 607.164 264.463 607.164 265.454C607.164 266.445 607.966 267.249 608.955 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 267.249C603.974 267.249 604.776 266.445 604.776 265.454C604.776 264.463 603.974 263.659 602.985 263.659C601.996 263.659 601.194 264.463 601.194 265.454C601.194 266.445 601.996 267.249 602.985 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 267.249C598.004 267.249 598.806 266.445 598.806 265.454C598.806 264.463 598.004 263.659 597.015 263.659C596.026 263.659 595.224 264.463 595.224 265.454C595.224 266.445 596.026 267.249 597.015 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 267.249C592.034 267.249 592.836 266.445 592.836 265.454C592.836 264.463 592.034 263.659 591.045 263.659C590.056 263.659 589.254 264.463 589.254 265.454C589.254 266.445 590.056 267.249 591.045 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 267.249C586.064 267.249 586.866 266.445 586.866 265.454C586.866 264.463 586.064 263.659 585.075 263.659C584.085 263.659 583.284 264.463 583.284 265.454C583.284 266.445 584.085 267.249 585.075 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 267.249C580.094 267.249 580.896 266.445 580.896 265.454C580.896 264.463 580.094 263.659 579.104 263.659C578.115 263.659 577.313 264.463 577.313 265.454C577.313 266.445 578.115 267.249 579.104 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 267.249C574.123 267.249 574.925 266.445 574.925 265.454C574.925 264.463 574.123 263.659 573.134 263.659C572.145 263.659 571.343 264.463 571.343 265.454C571.343 266.445 572.145 267.249 573.134 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 267.249C568.153 267.249 568.955 266.445 568.955 265.454C568.955 264.463 568.153 263.659 567.164 263.659C566.175 263.659 565.373 264.463 565.373 265.454C565.373 266.445 566.175 267.249 567.164 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 267.249C562.183 267.249 562.985 266.445 562.985 265.454C562.985 264.463 562.183 263.659 561.194 263.659C560.205 263.659 559.403 264.463 559.403 265.454C559.403 266.445 560.205 267.249 561.194 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 267.249C556.213 267.249 557.015 266.445 557.015 265.454C557.015 264.463 556.213 263.659 555.224 263.659C554.235 263.659 553.433 264.463 553.433 265.454C553.433 266.445 554.235 267.249 555.224 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 267.249C550.243 267.249 551.045 266.445 551.045 265.454C551.045 264.463 550.243 263.659 549.254 263.659C548.265 263.659 547.463 264.463 547.463 265.454C547.463 266.445 548.265 267.249 549.254 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 267.249C544.273 267.249 545.075 266.445 545.075 265.454C545.075 264.463 544.273 263.659 543.284 263.659C542.294 263.659 541.492 264.463 541.492 265.454C541.492 266.445 542.294 267.249 543.284 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 267.249C538.303 267.249 539.104 266.445 539.104 265.454C539.104 264.463 538.303 263.659 537.313 263.659C536.324 263.659 535.522 264.463 535.522 265.454C535.522 266.445 536.324 267.249 537.313 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 267.249C532.332 267.249 533.134 266.445 533.134 265.454C533.134 264.463 532.332 263.659 531.343 263.659C530.354 263.659 529.552 264.463 529.552 265.454C529.552 266.445 530.354 267.249 531.343 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 267.249C526.362 267.249 527.164 266.445 527.164 265.454C527.164 264.463 526.362 263.659 525.373 263.659C524.384 263.659 523.582 264.463 523.582 265.454C523.582 266.445 524.384 267.249 525.373 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 267.249C520.392 267.249 521.194 266.445 521.194 265.454C521.194 264.463 520.392 263.659 519.403 263.659C518.414 263.659 517.612 264.463 517.612 265.454C517.612 266.445 518.414 267.249 519.403 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 267.249C514.422 267.249 515.224 266.445 515.224 265.454C515.224 264.463 514.422 263.659 513.433 263.659C512.444 263.659 511.642 264.463 511.642 265.454C511.642 266.445 512.444 267.249 513.433 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 267.249C508.452 267.249 509.254 266.445 509.254 265.454C509.254 264.463 508.452 263.659 507.463 263.659C506.474 263.659 505.672 264.463 505.672 265.454C505.672 266.445 506.474 267.249 507.463 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 267.249C502.482 267.249 503.284 266.445 503.284 265.454C503.284 264.463 502.482 263.659 501.493 263.659C500.503 263.659 499.702 264.463 499.702 265.454C499.702 266.445 500.503 267.249 501.493 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 267.249C496.512 267.249 497.313 266.445 497.313 265.454C497.313 264.463 496.512 263.659 495.522 263.659C494.533 263.659 493.731 264.463 493.731 265.454C493.731 266.445 494.533 267.249 495.522 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 267.249C490.541 267.249 491.343 266.445 491.343 265.454C491.343 264.463 490.541 263.659 489.552 263.659C488.563 263.659 487.761 264.463 487.761 265.454C487.761 266.445 488.563 267.249 489.552 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 267.249C484.571 267.249 485.373 266.445 485.373 265.454C485.373 264.463 484.571 263.659 483.582 263.659C482.593 263.659 481.791 264.463 481.791 265.454C481.791 266.445 482.593 267.249 483.582 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 267.249C478.601 267.249 479.403 266.445 479.403 265.454C479.403 264.463 478.601 263.659 477.612 263.659C476.623 263.659 475.821 264.463 475.821 265.454C475.821 266.445 476.623 267.249 477.612 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 267.249C472.631 267.249 473.433 266.445 473.433 265.454C473.433 264.463 472.631 263.659 471.642 263.659C470.653 263.659 469.851 264.463 469.851 265.454C469.851 266.445 470.653 267.249 471.642 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 261.266C944.273 261.266 945.075 260.463 945.075 259.471C945.075 258.48 944.273 257.677 943.284 257.677C942.294 257.677 941.492 258.48 941.492 259.471C941.492 260.463 942.294 261.266 943.284 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 261.266C938.303 261.266 939.105 260.463 939.105 259.471C939.105 258.48 938.303 257.677 937.313 257.677C936.324 257.677 935.522 258.48 935.522 259.471C935.522 260.463 936.324 261.266 937.313 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 261.266C932.332 261.266 933.134 260.463 933.134 259.471C933.134 258.48 932.332 257.677 931.343 257.677C930.354 257.677 929.552 258.48 929.552 259.471C929.552 260.463 930.354 261.266 931.343 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 261.266C926.362 261.266 927.164 260.463 927.164 259.471C927.164 258.48 926.362 257.677 925.373 257.677C924.384 257.677 923.582 258.48 923.582 259.471C923.582 260.463 924.384 261.266 925.373 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 261.266C920.392 261.266 921.194 260.463 921.194 259.471C921.194 258.48 920.392 257.677 919.403 257.677C918.414 257.677 917.612 258.48 917.612 259.471C917.612 260.463 918.414 261.266 919.403 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 261.266C914.422 261.266 915.224 260.463 915.224 259.471C915.224 258.48 914.422 257.677 913.433 257.677C912.444 257.677 911.642 258.48 911.642 259.471C911.642 260.463 912.444 261.266 913.433 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 261.266C908.452 261.266 909.254 260.463 909.254 259.471C909.254 258.48 908.452 257.677 907.463 257.677C906.474 257.677 905.672 258.48 905.672 259.471C905.672 260.463 906.474 261.266 907.463 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 261.266C902.482 261.266 903.284 260.463 903.284 259.471C903.284 258.48 902.482 257.677 901.492 257.677C900.503 257.677 899.701 258.48 899.701 259.471C899.701 260.463 900.503 261.266 901.492 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 261.266C896.512 261.266 897.313 260.463 897.313 259.471C897.313 258.48 896.512 257.677 895.522 257.677C894.533 257.677 893.731 258.48 893.731 259.471C893.731 260.463 894.533 261.266 895.522 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 261.266C860.691 261.266 861.493 260.463 861.493 259.471C861.493 258.48 860.691 257.677 859.701 257.677C858.712 257.677 857.91 258.48 857.91 259.471C857.91 260.463 858.712 261.266 859.701 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 261.266C854.721 261.266 855.522 260.463 855.522 259.471C855.522 258.48 854.721 257.677 853.731 257.677C852.742 257.677 851.94 258.48 851.94 259.471C851.94 260.463 852.742 261.266 853.731 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 261.266C848.75 261.266 849.552 260.463 849.552 259.471C849.552 258.48 848.75 257.677 847.761 257.677C846.772 257.677 845.97 258.48 845.97 259.471C845.97 260.463 846.772 261.266 847.761 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 261.266C842.78 261.266 843.582 260.463 843.582 259.471C843.582 258.48 842.78 257.677 841.791 257.677C840.802 257.677 840 258.48 840 259.471C840 260.463 840.802 261.266 841.791 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 261.266C836.81 261.266 837.612 260.463 837.612 259.471C837.612 258.48 836.81 257.677 835.821 257.677C834.832 257.677 834.03 258.48 834.03 259.471C834.03 260.463 834.832 261.266 835.821 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 261.266C830.84 261.266 831.642 260.463 831.642 259.471C831.642 258.48 830.84 257.677 829.851 257.677C828.862 257.677 828.06 258.48 828.06 259.471C828.06 260.463 828.862 261.266 829.851 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 261.266C824.87 261.266 825.672 260.463 825.672 259.471C825.672 258.48 824.87 257.677 823.881 257.677C822.891 257.677 822.09 258.48 822.09 259.471C822.09 260.463 822.891 261.266 823.881 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 261.266C753.228 261.266 754.03 260.463 754.03 259.471C754.03 258.48 753.228 257.677 752.239 257.677C751.25 257.677 750.448 258.48 750.448 259.471C750.448 260.463 751.25 261.266 752.239 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 261.266C747.258 261.266 748.06 260.463 748.06 259.471C748.06 258.48 747.258 257.677 746.269 257.677C745.279 257.677 744.478 258.48 744.478 259.471C744.478 260.463 745.279 261.266 746.269 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 261.266C741.288 261.266 742.09 260.463 742.09 259.471C742.09 258.48 741.288 257.677 740.299 257.677C739.309 257.677 738.507 258.48 738.507 259.471C738.507 260.463 739.309 261.266 740.299 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 261.266C735.318 261.266 736.119 260.463 736.119 259.471C736.119 258.48 735.318 257.677 734.328 257.677C733.339 257.677 732.537 258.48 732.537 259.471C732.537 260.463 733.339 261.266 734.328 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 261.266C729.347 261.266 730.149 260.463 730.149 259.471C730.149 258.48 729.347 257.677 728.358 257.677C727.369 257.677 726.567 258.48 726.567 259.471C726.567 260.463 727.369 261.266 728.358 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 261.266C723.377 261.266 724.179 260.463 724.179 259.471C724.179 258.48 723.377 257.677 722.388 257.677C721.399 257.677 720.597 258.48 720.597 259.471C720.597 260.463 721.399 261.266 722.388 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 261.266C717.407 261.266 718.209 260.463 718.209 259.471C718.209 258.48 717.407 257.677 716.418 257.677C715.429 257.677 714.627 258.48 714.627 259.471C714.627 260.463 715.429 261.266 716.418 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 261.266C711.437 261.266 712.239 260.463 712.239 259.471C712.239 258.48 711.437 257.677 710.448 257.677C709.459 257.677 708.657 258.48 708.657 259.471C708.657 260.463 709.459 261.266 710.448 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 261.266C705.467 261.266 706.269 260.463 706.269 259.471C706.269 258.48 705.467 257.677 704.478 257.677C703.488 257.677 702.687 258.48 702.687 259.471C702.687 260.463 703.488 261.266 704.478 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 261.266C699.497 261.266 700.299 260.463 700.299 259.471C700.299 258.48 699.497 257.677 698.507 257.677C697.518 257.677 696.716 258.48 696.716 259.471C696.716 260.463 697.518 261.266 698.507 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 261.266C693.526 261.266 694.328 260.463 694.328 259.471C694.328 258.48 693.526 257.677 692.537 257.677C691.548 257.677 690.746 258.48 690.746 259.471C690.746 260.463 691.548 261.266 692.537 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 261.266C675.616 261.266 676.418 260.463 676.418 259.471C676.418 258.48 675.616 257.677 674.627 257.677C673.638 257.677 672.836 258.48 672.836 259.471C672.836 260.463 673.638 261.266 674.627 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 261.266C669.646 261.266 670.448 260.463 670.448 259.471C670.448 258.48 669.646 257.677 668.657 257.677C667.668 257.677 666.866 258.48 666.866 259.471C666.866 260.463 667.668 261.266 668.657 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 261.266C663.676 261.266 664.478 260.463 664.478 259.471C664.478 258.48 663.676 257.677 662.687 257.677C661.697 257.677 660.896 258.48 660.896 259.471C660.896 260.463 661.697 261.266 662.687 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 261.266C657.706 261.266 658.507 260.463 658.507 259.471C658.507 258.48 657.706 257.677 656.716 257.677C655.727 257.677 654.925 258.48 654.925 259.471C654.925 260.463 655.727 261.266 656.716 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 261.266C651.735 261.266 652.537 260.463 652.537 259.471C652.537 258.48 651.735 257.677 650.746 257.677C649.757 257.677 648.955 258.48 648.955 259.471C648.955 260.463 649.757 261.266 650.746 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 261.266C645.765 261.266 646.567 260.463 646.567 259.471C646.567 258.48 645.765 257.677 644.776 257.677C643.787 257.677 642.985 258.48 642.985 259.471C642.985 260.463 643.787 261.266 644.776 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 261.266C639.795 261.266 640.597 260.463 640.597 259.471C640.597 258.48 639.795 257.677 638.806 257.677C637.817 257.677 637.015 258.48 637.015 259.471C637.015 260.463 637.817 261.266 638.806 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 261.266C633.825 261.266 634.627 260.463 634.627 259.471C634.627 258.48 633.825 257.677 632.836 257.677C631.847 257.677 631.045 258.48 631.045 259.471C631.045 260.463 631.847 261.266 632.836 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 261.266C627.855 261.266 628.657 260.463 628.657 259.471C628.657 258.48 627.855 257.677 626.866 257.677C625.876 257.677 625.075 258.48 625.075 259.471C625.075 260.463 625.876 261.266 626.866 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 261.266C621.885 261.266 622.687 260.463 622.687 259.471C622.687 258.48 621.885 257.677 620.895 257.677C619.906 257.677 619.104 258.48 619.104 259.471C619.104 260.463 619.906 261.266 620.895 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 261.266C615.915 261.266 616.716 260.463 616.716 259.471C616.716 258.48 615.915 257.677 614.925 257.677C613.936 257.677 613.134 258.48 613.134 259.471C613.134 260.463 613.936 261.266 614.925 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 261.266C609.944 261.266 610.746 260.463 610.746 259.471C610.746 258.48 609.944 257.677 608.955 257.677C607.966 257.677 607.164 258.48 607.164 259.471C607.164 260.463 607.966 261.266 608.955 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 261.266C603.974 261.266 604.776 260.463 604.776 259.471C604.776 258.48 603.974 257.677 602.985 257.677C601.996 257.677 601.194 258.48 601.194 259.471C601.194 260.463 601.996 261.266 602.985 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 261.266C598.004 261.266 598.806 260.463 598.806 259.471C598.806 258.48 598.004 257.677 597.015 257.677C596.026 257.677 595.224 258.48 595.224 259.471C595.224 260.463 596.026 261.266 597.015 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 261.266C592.034 261.266 592.836 260.463 592.836 259.471C592.836 258.48 592.034 257.677 591.045 257.677C590.056 257.677 589.254 258.48 589.254 259.471C589.254 260.463 590.056 261.266 591.045 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 261.266C586.064 261.266 586.866 260.463 586.866 259.471C586.866 258.48 586.064 257.677 585.075 257.677C584.085 257.677 583.284 258.48 583.284 259.471C583.284 260.463 584.085 261.266 585.075 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 261.266C580.094 261.266 580.896 260.463 580.896 259.471C580.896 258.48 580.094 257.677 579.104 257.677C578.115 257.677 577.313 258.48 577.313 259.471C577.313 260.463 578.115 261.266 579.104 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 261.266C574.123 261.266 574.925 260.463 574.925 259.471C574.925 258.48 574.123 257.677 573.134 257.677C572.145 257.677 571.343 258.48 571.343 259.471C571.343 260.463 572.145 261.266 573.134 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 261.266C568.153 261.266 568.955 260.463 568.955 259.471C568.955 258.48 568.153 257.677 567.164 257.677C566.175 257.677 565.373 258.48 565.373 259.471C565.373 260.463 566.175 261.266 567.164 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 261.266C562.183 261.266 562.985 260.463 562.985 259.471C562.985 258.48 562.183 257.677 561.194 257.677C560.205 257.677 559.403 258.48 559.403 259.471C559.403 260.463 560.205 261.266 561.194 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 261.266C556.213 261.266 557.015 260.463 557.015 259.471C557.015 258.48 556.213 257.677 555.224 257.677C554.235 257.677 553.433 258.48 553.433 259.471C553.433 260.463 554.235 261.266 555.224 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 261.266C550.243 261.266 551.045 260.463 551.045 259.471C551.045 258.48 550.243 257.677 549.254 257.677C548.265 257.677 547.463 258.48 547.463 259.471C547.463 260.463 548.265 261.266 549.254 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 261.266C544.273 261.266 545.075 260.463 545.075 259.471C545.075 258.48 544.273 257.677 543.284 257.677C542.294 257.677 541.492 258.48 541.492 259.471C541.492 260.463 542.294 261.266 543.284 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 261.266C538.303 261.266 539.104 260.463 539.104 259.471C539.104 258.48 538.303 257.677 537.313 257.677C536.324 257.677 535.522 258.48 535.522 259.471C535.522 260.463 536.324 261.266 537.313 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 261.266C532.332 261.266 533.134 260.463 533.134 259.471C533.134 258.48 532.332 257.677 531.343 257.677C530.354 257.677 529.552 258.48 529.552 259.471C529.552 260.463 530.354 261.266 531.343 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 261.266C526.362 261.266 527.164 260.463 527.164 259.471C527.164 258.48 526.362 257.677 525.373 257.677C524.384 257.677 523.582 258.48 523.582 259.471C523.582 260.463 524.384 261.266 525.373 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 261.266C520.392 261.266 521.194 260.463 521.194 259.471C521.194 258.48 520.392 257.677 519.403 257.677C518.414 257.677 517.612 258.48 517.612 259.471C517.612 260.463 518.414 261.266 519.403 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 261.266C514.422 261.266 515.224 260.463 515.224 259.471C515.224 258.48 514.422 257.677 513.433 257.677C512.444 257.677 511.642 258.48 511.642 259.471C511.642 260.463 512.444 261.266 513.433 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 261.266C508.452 261.266 509.254 260.463 509.254 259.471C509.254 258.48 508.452 257.677 507.463 257.677C506.474 257.677 505.672 258.48 505.672 259.471C505.672 260.463 506.474 261.266 507.463 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 261.266C502.482 261.266 503.284 260.463 503.284 259.471C503.284 258.48 502.482 257.677 501.493 257.677C500.503 257.677 499.702 258.48 499.702 259.471C499.702 260.463 500.503 261.266 501.493 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 261.266C496.512 261.266 497.313 260.463 497.313 259.471C497.313 258.48 496.512 257.677 495.522 257.677C494.533 257.677 493.731 258.48 493.731 259.471C493.731 260.463 494.533 261.266 495.522 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 261.266C490.541 261.266 491.343 260.463 491.343 259.471C491.343 258.48 490.541 257.677 489.552 257.677C488.563 257.677 487.761 258.48 487.761 259.471C487.761 260.463 488.563 261.266 489.552 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 261.266C484.571 261.266 485.373 260.463 485.373 259.471C485.373 258.48 484.571 257.677 483.582 257.677C482.593 257.677 481.791 258.48 481.791 259.471C481.791 260.463 482.593 261.266 483.582 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 261.266C478.601 261.266 479.403 260.463 479.403 259.471C479.403 258.48 478.601 257.677 477.612 257.677C476.623 257.677 475.821 258.48 475.821 259.471C475.821 260.463 476.623 261.266 477.612 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 261.266C472.631 261.266 473.433 260.463 473.433 259.471C473.433 258.48 472.631 257.677 471.642 257.677C470.653 257.677 469.851 258.48 469.851 259.471C469.851 260.463 470.653 261.266 471.642 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 255.284C962.183 255.284 962.985 254.48 962.985 253.489C962.985 252.498 962.183 251.694 961.194 251.694C960.205 251.694 959.403 252.498 959.403 253.489C959.403 254.48 960.205 255.284 961.194 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 255.284C944.273 255.284 945.075 254.48 945.075 253.489C945.075 252.498 944.273 251.694 943.284 251.694C942.294 251.694 941.492 252.498 941.492 253.489C941.492 254.48 942.294 255.284 943.284 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 255.284C938.303 255.284 939.105 254.48 939.105 253.489C939.105 252.498 938.303 251.694 937.313 251.694C936.324 251.694 935.522 252.498 935.522 253.489C935.522 254.48 936.324 255.284 937.313 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 255.284C932.332 255.284 933.134 254.48 933.134 253.489C933.134 252.498 932.332 251.694 931.343 251.694C930.354 251.694 929.552 252.498 929.552 253.489C929.552 254.48 930.354 255.284 931.343 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 255.284C926.362 255.284 927.164 254.48 927.164 253.489C927.164 252.498 926.362 251.694 925.373 251.694C924.384 251.694 923.582 252.498 923.582 253.489C923.582 254.48 924.384 255.284 925.373 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 255.284C920.392 255.284 921.194 254.48 921.194 253.489C921.194 252.498 920.392 251.694 919.403 251.694C918.414 251.694 917.612 252.498 917.612 253.489C917.612 254.48 918.414 255.284 919.403 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 255.284C914.422 255.284 915.224 254.48 915.224 253.489C915.224 252.498 914.422 251.694 913.433 251.694C912.444 251.694 911.642 252.498 911.642 253.489C911.642 254.48 912.444 255.284 913.433 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 255.284C908.452 255.284 909.254 254.48 909.254 253.489C909.254 252.498 908.452 251.694 907.463 251.694C906.474 251.694 905.672 252.498 905.672 253.489C905.672 254.48 906.474 255.284 907.463 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 255.284C902.482 255.284 903.284 254.48 903.284 253.489C903.284 252.498 902.482 251.694 901.492 251.694C900.503 251.694 899.701 252.498 899.701 253.489C899.701 254.48 900.503 255.284 901.492 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 255.284C896.512 255.284 897.313 254.48 897.313 253.489C897.313 252.498 896.512 251.694 895.522 251.694C894.533 251.694 893.731 252.498 893.731 253.489C893.731 254.48 894.533 255.284 895.522 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 255.284C890.541 255.284 891.343 254.48 891.343 253.489C891.343 252.498 890.541 251.694 889.552 251.694C888.563 251.694 887.761 252.498 887.761 253.489C887.761 254.48 888.563 255.284 889.552 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 255.284C866.661 255.284 867.463 254.48 867.463 253.489C867.463 252.498 866.661 251.694 865.672 251.694C864.683 251.694 863.881 252.498 863.881 253.489C863.881 254.48 864.683 255.284 865.672 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 255.284C860.691 255.284 861.493 254.48 861.493 253.489C861.493 252.498 860.691 251.694 859.701 251.694C858.712 251.694 857.91 252.498 857.91 253.489C857.91 254.48 858.712 255.284 859.701 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 255.284C854.721 255.284 855.522 254.48 855.522 253.489C855.522 252.498 854.721 251.694 853.731 251.694C852.742 251.694 851.94 252.498 851.94 253.489C851.94 254.48 852.742 255.284 853.731 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 255.284C848.75 255.284 849.552 254.48 849.552 253.489C849.552 252.498 848.75 251.694 847.761 251.694C846.772 251.694 845.97 252.498 845.97 253.489C845.97 254.48 846.772 255.284 847.761 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 255.284C842.78 255.284 843.582 254.48 843.582 253.489C843.582 252.498 842.78 251.694 841.791 251.694C840.802 251.694 840 252.498 840 253.489C840 254.48 840.802 255.284 841.791 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 255.284C836.81 255.284 837.612 254.48 837.612 253.489C837.612 252.498 836.81 251.694 835.821 251.694C834.832 251.694 834.03 252.498 834.03 253.489C834.03 254.48 834.832 255.284 835.821 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 255.284C830.84 255.284 831.642 254.48 831.642 253.489C831.642 252.498 830.84 251.694 829.851 251.694C828.862 251.694 828.06 252.498 828.06 253.489C828.06 254.48 828.862 255.284 829.851 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 255.284C824.87 255.284 825.672 254.48 825.672 253.489C825.672 252.498 824.87 251.694 823.881 251.694C822.891 251.694 822.09 252.498 822.09 253.489C822.09 254.48 822.891 255.284 823.881 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 255.284C818.9 255.284 819.701 254.48 819.701 253.489C819.701 252.498 818.9 251.694 817.91 251.694C816.921 251.694 816.119 252.498 816.119 253.489C816.119 254.48 816.921 255.284 817.91 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 255.284C806.959 255.284 807.761 254.48 807.761 253.489C807.761 252.498 806.959 251.694 805.97 251.694C804.981 251.694 804.179 252.498 804.179 253.489C804.179 254.48 804.981 255.284 805.97 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 255.284C759.198 255.284 760 254.48 760 253.489C760 252.498 759.198 251.694 758.209 251.694C757.22 251.694 756.418 252.498 756.418 253.489C756.418 254.48 757.22 255.284 758.209 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 255.284C753.228 255.284 754.03 254.48 754.03 253.489C754.03 252.498 753.228 251.694 752.239 251.694C751.25 251.694 750.448 252.498 750.448 253.489C750.448 254.48 751.25 255.284 752.239 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 255.284C747.258 255.284 748.06 254.48 748.06 253.489C748.06 252.498 747.258 251.694 746.269 251.694C745.279 251.694 744.478 252.498 744.478 253.489C744.478 254.48 745.279 255.284 746.269 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 255.284C741.288 255.284 742.09 254.48 742.09 253.489C742.09 252.498 741.288 251.694 740.299 251.694C739.309 251.694 738.507 252.498 738.507 253.489C738.507 254.48 739.309 255.284 740.299 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 255.284C735.318 255.284 736.119 254.48 736.119 253.489C736.119 252.498 735.318 251.694 734.328 251.694C733.339 251.694 732.537 252.498 732.537 253.489C732.537 254.48 733.339 255.284 734.328 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 255.284C729.347 255.284 730.149 254.48 730.149 253.489C730.149 252.498 729.347 251.694 728.358 251.694C727.369 251.694 726.567 252.498 726.567 253.489C726.567 254.48 727.369 255.284 728.358 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 255.284C723.377 255.284 724.179 254.48 724.179 253.489C724.179 252.498 723.377 251.694 722.388 251.694C721.399 251.694 720.597 252.498 720.597 253.489C720.597 254.48 721.399 255.284 722.388 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 255.284C717.407 255.284 718.209 254.48 718.209 253.489C718.209 252.498 717.407 251.694 716.418 251.694C715.429 251.694 714.627 252.498 714.627 253.489C714.627 254.48 715.429 255.284 716.418 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 255.284C711.437 255.284 712.239 254.48 712.239 253.489C712.239 252.498 711.437 251.694 710.448 251.694C709.459 251.694 708.657 252.498 708.657 253.489C708.657 254.48 709.459 255.284 710.448 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 255.284C705.467 255.284 706.269 254.48 706.269 253.489C706.269 252.498 705.467 251.694 704.478 251.694C703.488 251.694 702.687 252.498 702.687 253.489C702.687 254.48 703.488 255.284 704.478 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 255.284C699.497 255.284 700.299 254.48 700.299 253.489C700.299 252.498 699.497 251.694 698.507 251.694C697.518 251.694 696.716 252.498 696.716 253.489C696.716 254.48 697.518 255.284 698.507 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 255.284C693.526 255.284 694.328 254.48 694.328 253.489C694.328 252.498 693.526 251.694 692.537 251.694C691.548 251.694 690.746 252.498 690.746 253.489C690.746 254.48 691.548 255.284 692.537 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 255.284C687.556 255.284 688.358 254.48 688.358 253.489C688.358 252.498 687.556 251.694 686.567 251.694C685.578 251.694 684.776 252.498 684.776 253.489C684.776 254.48 685.578 255.284 686.567 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 255.284C675.616 255.284 676.418 254.48 676.418 253.489C676.418 252.498 675.616 251.694 674.627 251.694C673.638 251.694 672.836 252.498 672.836 253.489C672.836 254.48 673.638 255.284 674.627 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 255.284C669.646 255.284 670.448 254.48 670.448 253.489C670.448 252.498 669.646 251.694 668.657 251.694C667.668 251.694 666.866 252.498 666.866 253.489C666.866 254.48 667.668 255.284 668.657 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 255.284C663.676 255.284 664.478 254.48 664.478 253.489C664.478 252.498 663.676 251.694 662.687 251.694C661.697 251.694 660.896 252.498 660.896 253.489C660.896 254.48 661.697 255.284 662.687 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 255.284C657.706 255.284 658.507 254.48 658.507 253.489C658.507 252.498 657.706 251.694 656.716 251.694C655.727 251.694 654.925 252.498 654.925 253.489C654.925 254.48 655.727 255.284 656.716 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 255.284C651.735 255.284 652.537 254.48 652.537 253.489C652.537 252.498 651.735 251.694 650.746 251.694C649.757 251.694 648.955 252.498 648.955 253.489C648.955 254.48 649.757 255.284 650.746 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 255.284C645.765 255.284 646.567 254.48 646.567 253.489C646.567 252.498 645.765 251.694 644.776 251.694C643.787 251.694 642.985 252.498 642.985 253.489C642.985 254.48 643.787 255.284 644.776 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 255.284C639.795 255.284 640.597 254.48 640.597 253.489C640.597 252.498 639.795 251.694 638.806 251.694C637.817 251.694 637.015 252.498 637.015 253.489C637.015 254.48 637.817 255.284 638.806 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 255.284C633.825 255.284 634.627 254.48 634.627 253.489C634.627 252.498 633.825 251.694 632.836 251.694C631.847 251.694 631.045 252.498 631.045 253.489C631.045 254.48 631.847 255.284 632.836 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 255.284C627.855 255.284 628.657 254.48 628.657 253.489C628.657 252.498 627.855 251.694 626.866 251.694C625.876 251.694 625.075 252.498 625.075 253.489C625.075 254.48 625.876 255.284 626.866 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 255.284C621.885 255.284 622.687 254.48 622.687 253.489C622.687 252.498 621.885 251.694 620.895 251.694C619.906 251.694 619.104 252.498 619.104 253.489C619.104 254.48 619.906 255.284 620.895 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 255.284C615.915 255.284 616.716 254.48 616.716 253.489C616.716 252.498 615.915 251.694 614.925 251.694C613.936 251.694 613.134 252.498 613.134 253.489C613.134 254.48 613.936 255.284 614.925 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 255.284C609.944 255.284 610.746 254.48 610.746 253.489C610.746 252.498 609.944 251.694 608.955 251.694C607.966 251.694 607.164 252.498 607.164 253.489C607.164 254.48 607.966 255.284 608.955 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 255.284C603.974 255.284 604.776 254.48 604.776 253.489C604.776 252.498 603.974 251.694 602.985 251.694C601.996 251.694 601.194 252.498 601.194 253.489C601.194 254.48 601.996 255.284 602.985 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 255.284C598.004 255.284 598.806 254.48 598.806 253.489C598.806 252.498 598.004 251.694 597.015 251.694C596.026 251.694 595.224 252.498 595.224 253.489C595.224 254.48 596.026 255.284 597.015 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 255.284C592.034 255.284 592.836 254.48 592.836 253.489C592.836 252.498 592.034 251.694 591.045 251.694C590.056 251.694 589.254 252.498 589.254 253.489C589.254 254.48 590.056 255.284 591.045 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 255.284C586.064 255.284 586.866 254.48 586.866 253.489C586.866 252.498 586.064 251.694 585.075 251.694C584.085 251.694 583.284 252.498 583.284 253.489C583.284 254.48 584.085 255.284 585.075 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 255.284C580.094 255.284 580.896 254.48 580.896 253.489C580.896 252.498 580.094 251.694 579.104 251.694C578.115 251.694 577.313 252.498 577.313 253.489C577.313 254.48 578.115 255.284 579.104 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 255.284C574.123 255.284 574.925 254.48 574.925 253.489C574.925 252.498 574.123 251.694 573.134 251.694C572.145 251.694 571.343 252.498 571.343 253.489C571.343 254.48 572.145 255.284 573.134 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 255.284C568.153 255.284 568.955 254.48 568.955 253.489C568.955 252.498 568.153 251.694 567.164 251.694C566.175 251.694 565.373 252.498 565.373 253.489C565.373 254.48 566.175 255.284 567.164 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 255.284C562.183 255.284 562.985 254.48 562.985 253.489C562.985 252.498 562.183 251.694 561.194 251.694C560.205 251.694 559.403 252.498 559.403 253.489C559.403 254.48 560.205 255.284 561.194 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 255.284C556.213 255.284 557.015 254.48 557.015 253.489C557.015 252.498 556.213 251.694 555.224 251.694C554.235 251.694 553.433 252.498 553.433 253.489C553.433 254.48 554.235 255.284 555.224 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 255.284C550.243 255.284 551.045 254.48 551.045 253.489C551.045 252.498 550.243 251.694 549.254 251.694C548.265 251.694 547.463 252.498 547.463 253.489C547.463 254.48 548.265 255.284 549.254 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 255.284C544.273 255.284 545.075 254.48 545.075 253.489C545.075 252.498 544.273 251.694 543.284 251.694C542.294 251.694 541.492 252.498 541.492 253.489C541.492 254.48 542.294 255.284 543.284 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 255.284C538.303 255.284 539.104 254.48 539.104 253.489C539.104 252.498 538.303 251.694 537.313 251.694C536.324 251.694 535.522 252.498 535.522 253.489C535.522 254.48 536.324 255.284 537.313 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 255.284C532.332 255.284 533.134 254.48 533.134 253.489C533.134 252.498 532.332 251.694 531.343 251.694C530.354 251.694 529.552 252.498 529.552 253.489C529.552 254.48 530.354 255.284 531.343 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 255.284C526.362 255.284 527.164 254.48 527.164 253.489C527.164 252.498 526.362 251.694 525.373 251.694C524.384 251.694 523.582 252.498 523.582 253.489C523.582 254.48 524.384 255.284 525.373 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 255.284C520.392 255.284 521.194 254.48 521.194 253.489C521.194 252.498 520.392 251.694 519.403 251.694C518.414 251.694 517.612 252.498 517.612 253.489C517.612 254.48 518.414 255.284 519.403 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 255.284C514.422 255.284 515.224 254.48 515.224 253.489C515.224 252.498 514.422 251.694 513.433 251.694C512.444 251.694 511.642 252.498 511.642 253.489C511.642 254.48 512.444 255.284 513.433 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 255.284C508.452 255.284 509.254 254.48 509.254 253.489C509.254 252.498 508.452 251.694 507.463 251.694C506.474 251.694 505.672 252.498 505.672 253.489C505.672 254.48 506.474 255.284 507.463 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 255.284C502.482 255.284 503.284 254.48 503.284 253.489C503.284 252.498 502.482 251.694 501.493 251.694C500.503 251.694 499.702 252.498 499.702 253.489C499.702 254.48 500.503 255.284 501.493 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 255.284C496.512 255.284 497.313 254.48 497.313 253.489C497.313 252.498 496.512 251.694 495.522 251.694C494.533 251.694 493.731 252.498 493.731 253.489C493.731 254.48 494.533 255.284 495.522 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 255.284C490.541 255.284 491.343 254.48 491.343 253.489C491.343 252.498 490.541 251.694 489.552 251.694C488.563 251.694 487.761 252.498 487.761 253.489C487.761 254.48 488.563 255.284 489.552 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 255.284C484.571 255.284 485.373 254.48 485.373 253.489C485.373 252.498 484.571 251.694 483.582 251.694C482.593 251.694 481.791 252.498 481.791 253.489C481.791 254.48 482.593 255.284 483.582 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 255.284C478.601 255.284 479.403 254.48 479.403 253.489C479.403 252.498 478.601 251.694 477.612 251.694C476.623 251.694 475.821 252.498 475.821 253.489C475.821 254.48 476.623 255.284 477.612 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 255.284C472.631 255.284 473.433 254.48 473.433 253.489C473.433 252.498 472.631 251.694 471.642 251.694C470.653 251.694 469.851 252.498 469.851 253.489C469.851 254.48 470.653 255.284 471.642 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 255.284C466.661 255.284 467.463 254.48 467.463 253.489C467.463 252.498 466.661 251.694 465.672 251.694C464.682 251.694 463.881 252.498 463.881 253.489C463.881 254.48 464.682 255.284 465.672 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 249.302C998.004 249.302 998.806 248.498 998.806 247.507C998.806 246.516 998.004 245.712 997.015 245.712C996.026 245.712 995.224 246.516 995.224 247.507C995.224 248.498 996.026 249.302 997.015 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 249.302C968.153 249.302 968.955 248.498 968.955 247.507C968.955 246.516 968.153 245.712 967.164 245.712C966.175 245.712 965.373 246.516 965.373 247.507C965.373 248.498 966.175 249.302 967.164 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 249.302C962.183 249.302 962.985 248.498 962.985 247.507C962.985 246.516 962.183 245.712 961.194 245.712C960.205 245.712 959.403 246.516 959.403 247.507C959.403 248.498 960.205 249.302 961.194 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 249.302C956.213 249.302 957.015 248.498 957.015 247.507C957.015 246.516 956.213 245.712 955.224 245.712C954.235 245.712 953.433 246.516 953.433 247.507C953.433 248.498 954.235 249.302 955.224 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 249.302C950.243 249.302 951.045 248.498 951.045 247.507C951.045 246.516 950.243 245.712 949.254 245.712C948.265 245.712 947.463 246.516 947.463 247.507C947.463 248.498 948.265 249.302 949.254 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 249.302C944.273 249.302 945.075 248.498 945.075 247.507C945.075 246.516 944.273 245.712 943.284 245.712C942.294 245.712 941.492 246.516 941.492 247.507C941.492 248.498 942.294 249.302 943.284 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 249.302C938.303 249.302 939.105 248.498 939.105 247.507C939.105 246.516 938.303 245.712 937.313 245.712C936.324 245.712 935.522 246.516 935.522 247.507C935.522 248.498 936.324 249.302 937.313 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 249.302C932.332 249.302 933.134 248.498 933.134 247.507C933.134 246.516 932.332 245.712 931.343 245.712C930.354 245.712 929.552 246.516 929.552 247.507C929.552 248.498 930.354 249.302 931.343 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 249.302C926.362 249.302 927.164 248.498 927.164 247.507C927.164 246.516 926.362 245.712 925.373 245.712C924.384 245.712 923.582 246.516 923.582 247.507C923.582 248.498 924.384 249.302 925.373 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 249.302C920.392 249.302 921.194 248.498 921.194 247.507C921.194 246.516 920.392 245.712 919.403 245.712C918.414 245.712 917.612 246.516 917.612 247.507C917.612 248.498 918.414 249.302 919.403 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 249.302C914.422 249.302 915.224 248.498 915.224 247.507C915.224 246.516 914.422 245.712 913.433 245.712C912.444 245.712 911.642 246.516 911.642 247.507C911.642 248.498 912.444 249.302 913.433 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 249.302C908.452 249.302 909.254 248.498 909.254 247.507C909.254 246.516 908.452 245.712 907.463 245.712C906.474 245.712 905.672 246.516 905.672 247.507C905.672 248.498 906.474 249.302 907.463 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 249.302C902.482 249.302 903.284 248.498 903.284 247.507C903.284 246.516 902.482 245.712 901.492 245.712C900.503 245.712 899.701 246.516 899.701 247.507C899.701 248.498 900.503 249.302 901.492 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 249.302C896.512 249.302 897.313 248.498 897.313 247.507C897.313 246.516 896.512 245.712 895.522 245.712C894.533 245.712 893.731 246.516 893.731 247.507C893.731 248.498 894.533 249.302 895.522 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 249.302C890.541 249.302 891.343 248.498 891.343 247.507C891.343 246.516 890.541 245.712 889.552 245.712C888.563 245.712 887.761 246.516 887.761 247.507C887.761 248.498 888.563 249.302 889.552 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 249.302C884.571 249.302 885.373 248.498 885.373 247.507C885.373 246.516 884.571 245.712 883.582 245.712C882.593 245.712 881.791 246.516 881.791 247.507C881.791 248.498 882.593 249.302 883.582 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 249.302C878.601 249.302 879.403 248.498 879.403 247.507C879.403 246.516 878.601 245.712 877.612 245.712C876.623 245.712 875.821 246.516 875.821 247.507C875.821 248.498 876.623 249.302 877.612 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 249.302C872.631 249.302 873.433 248.498 873.433 247.507C873.433 246.516 872.631 245.712 871.642 245.712C870.653 245.712 869.851 246.516 869.851 247.507C869.851 248.498 870.653 249.302 871.642 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 249.302C866.661 249.302 867.463 248.498 867.463 247.507C867.463 246.516 866.661 245.712 865.672 245.712C864.683 245.712 863.881 246.516 863.881 247.507C863.881 248.498 864.683 249.302 865.672 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 249.302C860.691 249.302 861.493 248.498 861.493 247.507C861.493 246.516 860.691 245.712 859.701 245.712C858.712 245.712 857.91 246.516 857.91 247.507C857.91 248.498 858.712 249.302 859.701 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 249.302C854.721 249.302 855.522 248.498 855.522 247.507C855.522 246.516 854.721 245.712 853.731 245.712C852.742 245.712 851.94 246.516 851.94 247.507C851.94 248.498 852.742 249.302 853.731 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 249.302C848.75 249.302 849.552 248.498 849.552 247.507C849.552 246.516 848.75 245.712 847.761 245.712C846.772 245.712 845.97 246.516 845.97 247.507C845.97 248.498 846.772 249.302 847.761 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 249.302C842.78 249.302 843.582 248.498 843.582 247.507C843.582 246.516 842.78 245.712 841.791 245.712C840.802 245.712 840 246.516 840 247.507C840 248.498 840.802 249.302 841.791 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 249.302C836.81 249.302 837.612 248.498 837.612 247.507C837.612 246.516 836.81 245.712 835.821 245.712C834.832 245.712 834.03 246.516 834.03 247.507C834.03 248.498 834.832 249.302 835.821 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 249.302C830.84 249.302 831.642 248.498 831.642 247.507C831.642 246.516 830.84 245.712 829.851 245.712C828.862 245.712 828.06 246.516 828.06 247.507C828.06 248.498 828.862 249.302 829.851 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 249.302C824.87 249.302 825.672 248.498 825.672 247.507C825.672 246.516 824.87 245.712 823.881 245.712C822.891 245.712 822.09 246.516 822.09 247.507C822.09 248.498 822.891 249.302 823.881 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 249.302C818.9 249.302 819.701 248.498 819.701 247.507C819.701 246.516 818.9 245.712 817.91 245.712C816.921 245.712 816.119 246.516 816.119 247.507C816.119 248.498 816.921 249.302 817.91 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 249.302C812.929 249.302 813.731 248.498 813.731 247.507C813.731 246.516 812.929 245.712 811.94 245.712C810.951 245.712 810.149 246.516 810.149 247.507C810.149 248.498 810.951 249.302 811.94 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 249.302C806.959 249.302 807.761 248.498 807.761 247.507C807.761 246.516 806.959 245.712 805.97 245.712C804.981 245.712 804.179 246.516 804.179 247.507C804.179 248.498 804.981 249.302 805.97 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M800 249.302C800.989 249.302 801.791 248.498 801.791 247.507C801.791 246.516 800.989 245.712 800 245.712C799.011 245.712 798.209 246.516 798.209 247.507C798.209 248.498 799.011 249.302 800 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 249.302C759.198 249.302 760 248.498 760 247.507C760 246.516 759.198 245.712 758.209 245.712C757.22 245.712 756.418 246.516 756.418 247.507C756.418 248.498 757.22 249.302 758.209 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 249.302C753.228 249.302 754.03 248.498 754.03 247.507C754.03 246.516 753.228 245.712 752.239 245.712C751.25 245.712 750.448 246.516 750.448 247.507C750.448 248.498 751.25 249.302 752.239 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 249.302C747.258 249.302 748.06 248.498 748.06 247.507C748.06 246.516 747.258 245.712 746.269 245.712C745.279 245.712 744.478 246.516 744.478 247.507C744.478 248.498 745.279 249.302 746.269 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 249.302C741.288 249.302 742.09 248.498 742.09 247.507C742.09 246.516 741.288 245.712 740.299 245.712C739.309 245.712 738.507 246.516 738.507 247.507C738.507 248.498 739.309 249.302 740.299 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 249.302C735.318 249.302 736.119 248.498 736.119 247.507C736.119 246.516 735.318 245.712 734.328 245.712C733.339 245.712 732.537 246.516 732.537 247.507C732.537 248.498 733.339 249.302 734.328 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 249.302C729.347 249.302 730.149 248.498 730.149 247.507C730.149 246.516 729.347 245.712 728.358 245.712C727.369 245.712 726.567 246.516 726.567 247.507C726.567 248.498 727.369 249.302 728.358 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 249.302C723.377 249.302 724.179 248.498 724.179 247.507C724.179 246.516 723.377 245.712 722.388 245.712C721.399 245.712 720.597 246.516 720.597 247.507C720.597 248.498 721.399 249.302 722.388 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 249.302C717.407 249.302 718.209 248.498 718.209 247.507C718.209 246.516 717.407 245.712 716.418 245.712C715.429 245.712 714.627 246.516 714.627 247.507C714.627 248.498 715.429 249.302 716.418 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 249.302C711.437 249.302 712.239 248.498 712.239 247.507C712.239 246.516 711.437 245.712 710.448 245.712C709.459 245.712 708.657 246.516 708.657 247.507C708.657 248.498 709.459 249.302 710.448 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 249.302C705.467 249.302 706.269 248.498 706.269 247.507C706.269 246.516 705.467 245.712 704.478 245.712C703.488 245.712 702.687 246.516 702.687 247.507C702.687 248.498 703.488 249.302 704.478 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 249.302C699.497 249.302 700.299 248.498 700.299 247.507C700.299 246.516 699.497 245.712 698.507 245.712C697.518 245.712 696.716 246.516 696.716 247.507C696.716 248.498 697.518 249.302 698.507 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 249.302C693.526 249.302 694.328 248.498 694.328 247.507C694.328 246.516 693.526 245.712 692.537 245.712C691.548 245.712 690.746 246.516 690.746 247.507C690.746 248.498 691.548 249.302 692.537 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 249.302C687.556 249.302 688.358 248.498 688.358 247.507C688.358 246.516 687.556 245.712 686.567 245.712C685.578 245.712 684.776 246.516 684.776 247.507C684.776 248.498 685.578 249.302 686.567 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 249.302C669.646 249.302 670.448 248.498 670.448 247.507C670.448 246.516 669.646 245.712 668.657 245.712C667.668 245.712 666.866 246.516 666.866 247.507C666.866 248.498 667.668 249.302 668.657 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 249.302C663.676 249.302 664.478 248.498 664.478 247.507C664.478 246.516 663.676 245.712 662.687 245.712C661.697 245.712 660.896 246.516 660.896 247.507C660.896 248.498 661.697 249.302 662.687 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 249.302C657.706 249.302 658.507 248.498 658.507 247.507C658.507 246.516 657.706 245.712 656.716 245.712C655.727 245.712 654.925 246.516 654.925 247.507C654.925 248.498 655.727 249.302 656.716 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 249.302C651.735 249.302 652.537 248.498 652.537 247.507C652.537 246.516 651.735 245.712 650.746 245.712C649.757 245.712 648.955 246.516 648.955 247.507C648.955 248.498 649.757 249.302 650.746 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 249.302C645.765 249.302 646.567 248.498 646.567 247.507C646.567 246.516 645.765 245.712 644.776 245.712C643.787 245.712 642.985 246.516 642.985 247.507C642.985 248.498 643.787 249.302 644.776 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 249.302C639.795 249.302 640.597 248.498 640.597 247.507C640.597 246.516 639.795 245.712 638.806 245.712C637.817 245.712 637.015 246.516 637.015 247.507C637.015 248.498 637.817 249.302 638.806 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 249.302C633.825 249.302 634.627 248.498 634.627 247.507C634.627 246.516 633.825 245.712 632.836 245.712C631.847 245.712 631.045 246.516 631.045 247.507C631.045 248.498 631.847 249.302 632.836 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 249.302C627.855 249.302 628.657 248.498 628.657 247.507C628.657 246.516 627.855 245.712 626.866 245.712C625.876 245.712 625.075 246.516 625.075 247.507C625.075 248.498 625.876 249.302 626.866 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 249.302C621.885 249.302 622.687 248.498 622.687 247.507C622.687 246.516 621.885 245.712 620.895 245.712C619.906 245.712 619.104 246.516 619.104 247.507C619.104 248.498 619.906 249.302 620.895 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 249.302C615.915 249.302 616.716 248.498 616.716 247.507C616.716 246.516 615.915 245.712 614.925 245.712C613.936 245.712 613.134 246.516 613.134 247.507C613.134 248.498 613.936 249.302 614.925 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 249.302C609.944 249.302 610.746 248.498 610.746 247.507C610.746 246.516 609.944 245.712 608.955 245.712C607.966 245.712 607.164 246.516 607.164 247.507C607.164 248.498 607.966 249.302 608.955 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 249.302C603.974 249.302 604.776 248.498 604.776 247.507C604.776 246.516 603.974 245.712 602.985 245.712C601.996 245.712 601.194 246.516 601.194 247.507C601.194 248.498 601.996 249.302 602.985 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 249.302C598.004 249.302 598.806 248.498 598.806 247.507C598.806 246.516 598.004 245.712 597.015 245.712C596.026 245.712 595.224 246.516 595.224 247.507C595.224 248.498 596.026 249.302 597.015 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 249.302C592.034 249.302 592.836 248.498 592.836 247.507C592.836 246.516 592.034 245.712 591.045 245.712C590.056 245.712 589.254 246.516 589.254 247.507C589.254 248.498 590.056 249.302 591.045 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 249.302C586.064 249.302 586.866 248.498 586.866 247.507C586.866 246.516 586.064 245.712 585.075 245.712C584.085 245.712 583.284 246.516 583.284 247.507C583.284 248.498 584.085 249.302 585.075 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 249.302C580.094 249.302 580.896 248.498 580.896 247.507C580.896 246.516 580.094 245.712 579.104 245.712C578.115 245.712 577.313 246.516 577.313 247.507C577.313 248.498 578.115 249.302 579.104 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 249.302C574.123 249.302 574.925 248.498 574.925 247.507C574.925 246.516 574.123 245.712 573.134 245.712C572.145 245.712 571.343 246.516 571.343 247.507C571.343 248.498 572.145 249.302 573.134 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 249.302C568.153 249.302 568.955 248.498 568.955 247.507C568.955 246.516 568.153 245.712 567.164 245.712C566.175 245.712 565.373 246.516 565.373 247.507C565.373 248.498 566.175 249.302 567.164 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 249.302C562.183 249.302 562.985 248.498 562.985 247.507C562.985 246.516 562.183 245.712 561.194 245.712C560.205 245.712 559.403 246.516 559.403 247.507C559.403 248.498 560.205 249.302 561.194 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 249.302C556.213 249.302 557.015 248.498 557.015 247.507C557.015 246.516 556.213 245.712 555.224 245.712C554.235 245.712 553.433 246.516 553.433 247.507C553.433 248.498 554.235 249.302 555.224 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 249.302C550.243 249.302 551.045 248.498 551.045 247.507C551.045 246.516 550.243 245.712 549.254 245.712C548.265 245.712 547.463 246.516 547.463 247.507C547.463 248.498 548.265 249.302 549.254 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 249.302C544.273 249.302 545.075 248.498 545.075 247.507C545.075 246.516 544.273 245.712 543.284 245.712C542.294 245.712 541.492 246.516 541.492 247.507C541.492 248.498 542.294 249.302 543.284 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 249.302C538.303 249.302 539.104 248.498 539.104 247.507C539.104 246.516 538.303 245.712 537.313 245.712C536.324 245.712 535.522 246.516 535.522 247.507C535.522 248.498 536.324 249.302 537.313 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 249.302C532.332 249.302 533.134 248.498 533.134 247.507C533.134 246.516 532.332 245.712 531.343 245.712C530.354 245.712 529.552 246.516 529.552 247.507C529.552 248.498 530.354 249.302 531.343 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 249.302C526.362 249.302 527.164 248.498 527.164 247.507C527.164 246.516 526.362 245.712 525.373 245.712C524.384 245.712 523.582 246.516 523.582 247.507C523.582 248.498 524.384 249.302 525.373 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 249.302C520.392 249.302 521.194 248.498 521.194 247.507C521.194 246.516 520.392 245.712 519.403 245.712C518.414 245.712 517.612 246.516 517.612 247.507C517.612 248.498 518.414 249.302 519.403 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 249.302C514.422 249.302 515.224 248.498 515.224 247.507C515.224 246.516 514.422 245.712 513.433 245.712C512.444 245.712 511.642 246.516 511.642 247.507C511.642 248.498 512.444 249.302 513.433 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 249.302C508.452 249.302 509.254 248.498 509.254 247.507C509.254 246.516 508.452 245.712 507.463 245.712C506.474 245.712 505.672 246.516 505.672 247.507C505.672 248.498 506.474 249.302 507.463 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 249.302C502.482 249.302 503.284 248.498 503.284 247.507C503.284 246.516 502.482 245.712 501.493 245.712C500.503 245.712 499.702 246.516 499.702 247.507C499.702 248.498 500.503 249.302 501.493 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 249.302C496.512 249.302 497.313 248.498 497.313 247.507C497.313 246.516 496.512 245.712 495.522 245.712C494.533 245.712 493.731 246.516 493.731 247.507C493.731 248.498 494.533 249.302 495.522 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 249.302C490.541 249.302 491.343 248.498 491.343 247.507C491.343 246.516 490.541 245.712 489.552 245.712C488.563 245.712 487.761 246.516 487.761 247.507C487.761 248.498 488.563 249.302 489.552 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 249.302C484.571 249.302 485.373 248.498 485.373 247.507C485.373 246.516 484.571 245.712 483.582 245.712C482.593 245.712 481.791 246.516 481.791 247.507C481.791 248.498 482.593 249.302 483.582 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 249.302C478.601 249.302 479.403 248.498 479.403 247.507C479.403 246.516 478.601 245.712 477.612 245.712C476.623 245.712 475.821 246.516 475.821 247.507C475.821 248.498 476.623 249.302 477.612 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 249.302C472.631 249.302 473.433 248.498 473.433 247.507C473.433 246.516 472.631 245.712 471.642 245.712C470.653 245.712 469.851 246.516 469.851 247.507C469.851 248.498 470.653 249.302 471.642 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 243.319C1003.97 243.319 1004.78 242.516 1004.78 241.525C1004.78 240.533 1003.97 239.73 1002.99 239.73C1002 239.73 1001.19 240.533 1001.19 241.525C1001.19 242.516 1002 243.319 1002.99 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 243.319C998.004 243.319 998.806 242.516 998.806 241.525C998.806 240.533 998.004 239.73 997.015 239.73C996.026 239.73 995.224 240.533 995.224 241.525C995.224 242.516 996.026 243.319 997.015 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 243.319C986.064 243.319 986.866 242.516 986.866 241.525C986.866 240.533 986.064 239.73 985.075 239.73C984.085 239.73 983.284 240.533 983.284 241.525C983.284 242.516 984.085 243.319 985.075 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 243.319C980.094 243.319 980.896 242.516 980.896 241.525C980.896 240.533 980.094 239.73 979.104 239.73C978.115 239.73 977.313 240.533 977.313 241.525C977.313 242.516 978.115 243.319 979.104 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 243.319C974.123 243.319 974.925 242.516 974.925 241.525C974.925 240.533 974.123 239.73 973.134 239.73C972.145 239.73 971.343 240.533 971.343 241.525C971.343 242.516 972.145 243.319 973.134 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 243.319C968.153 243.319 968.955 242.516 968.955 241.525C968.955 240.533 968.153 239.73 967.164 239.73C966.175 239.73 965.373 240.533 965.373 241.525C965.373 242.516 966.175 243.319 967.164 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 243.319C962.183 243.319 962.985 242.516 962.985 241.525C962.985 240.533 962.183 239.73 961.194 239.73C960.205 239.73 959.403 240.533 959.403 241.525C959.403 242.516 960.205 243.319 961.194 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 243.319C956.213 243.319 957.015 242.516 957.015 241.525C957.015 240.533 956.213 239.73 955.224 239.73C954.235 239.73 953.433 240.533 953.433 241.525C953.433 242.516 954.235 243.319 955.224 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 243.319C950.243 243.319 951.045 242.516 951.045 241.525C951.045 240.533 950.243 239.73 949.254 239.73C948.265 239.73 947.463 240.533 947.463 241.525C947.463 242.516 948.265 243.319 949.254 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 243.319C944.273 243.319 945.075 242.516 945.075 241.525C945.075 240.533 944.273 239.73 943.284 239.73C942.294 239.73 941.492 240.533 941.492 241.525C941.492 242.516 942.294 243.319 943.284 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 243.319C938.303 243.319 939.105 242.516 939.105 241.525C939.105 240.533 938.303 239.73 937.313 239.73C936.324 239.73 935.522 240.533 935.522 241.525C935.522 242.516 936.324 243.319 937.313 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 243.319C932.332 243.319 933.134 242.516 933.134 241.525C933.134 240.533 932.332 239.73 931.343 239.73C930.354 239.73 929.552 240.533 929.552 241.525C929.552 242.516 930.354 243.319 931.343 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 243.319C926.362 243.319 927.164 242.516 927.164 241.525C927.164 240.533 926.362 239.73 925.373 239.73C924.384 239.73 923.582 240.533 923.582 241.525C923.582 242.516 924.384 243.319 925.373 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 243.319C920.392 243.319 921.194 242.516 921.194 241.525C921.194 240.533 920.392 239.73 919.403 239.73C918.414 239.73 917.612 240.533 917.612 241.525C917.612 242.516 918.414 243.319 919.403 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 243.319C914.422 243.319 915.224 242.516 915.224 241.525C915.224 240.533 914.422 239.73 913.433 239.73C912.444 239.73 911.642 240.533 911.642 241.525C911.642 242.516 912.444 243.319 913.433 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 243.319C908.452 243.319 909.254 242.516 909.254 241.525C909.254 240.533 908.452 239.73 907.463 239.73C906.474 239.73 905.672 240.533 905.672 241.525C905.672 242.516 906.474 243.319 907.463 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 243.319C902.482 243.319 903.284 242.516 903.284 241.525C903.284 240.533 902.482 239.73 901.492 239.73C900.503 239.73 899.701 240.533 899.701 241.525C899.701 242.516 900.503 243.319 901.492 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 243.319C896.512 243.319 897.313 242.516 897.313 241.525C897.313 240.533 896.512 239.73 895.522 239.73C894.533 239.73 893.731 240.533 893.731 241.525C893.731 242.516 894.533 243.319 895.522 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 243.319C890.541 243.319 891.343 242.516 891.343 241.525C891.343 240.533 890.541 239.73 889.552 239.73C888.563 239.73 887.761 240.533 887.761 241.525C887.761 242.516 888.563 243.319 889.552 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 243.319C884.571 243.319 885.373 242.516 885.373 241.525C885.373 240.533 884.571 239.73 883.582 239.73C882.593 239.73 881.791 240.533 881.791 241.525C881.791 242.516 882.593 243.319 883.582 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 243.319C878.601 243.319 879.403 242.516 879.403 241.525C879.403 240.533 878.601 239.73 877.612 239.73C876.623 239.73 875.821 240.533 875.821 241.525C875.821 242.516 876.623 243.319 877.612 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 243.319C872.631 243.319 873.433 242.516 873.433 241.525C873.433 240.533 872.631 239.73 871.642 239.73C870.653 239.73 869.851 240.533 869.851 241.525C869.851 242.516 870.653 243.319 871.642 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 243.319C866.661 243.319 867.463 242.516 867.463 241.525C867.463 240.533 866.661 239.73 865.672 239.73C864.683 239.73 863.881 240.533 863.881 241.525C863.881 242.516 864.683 243.319 865.672 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 243.319C860.691 243.319 861.493 242.516 861.493 241.525C861.493 240.533 860.691 239.73 859.701 239.73C858.712 239.73 857.91 240.533 857.91 241.525C857.91 242.516 858.712 243.319 859.701 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 243.319C854.721 243.319 855.522 242.516 855.522 241.525C855.522 240.533 854.721 239.73 853.731 239.73C852.742 239.73 851.94 240.533 851.94 241.525C851.94 242.516 852.742 243.319 853.731 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 243.319C848.75 243.319 849.552 242.516 849.552 241.525C849.552 240.533 848.75 239.73 847.761 239.73C846.772 239.73 845.97 240.533 845.97 241.525C845.97 242.516 846.772 243.319 847.761 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 243.319C842.78 243.319 843.582 242.516 843.582 241.525C843.582 240.533 842.78 239.73 841.791 239.73C840.802 239.73 840 240.533 840 241.525C840 242.516 840.802 243.319 841.791 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 243.319C836.81 243.319 837.612 242.516 837.612 241.525C837.612 240.533 836.81 239.73 835.821 239.73C834.832 239.73 834.03 240.533 834.03 241.525C834.03 242.516 834.832 243.319 835.821 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 243.319C830.84 243.319 831.642 242.516 831.642 241.525C831.642 240.533 830.84 239.73 829.851 239.73C828.862 239.73 828.06 240.533 828.06 241.525C828.06 242.516 828.862 243.319 829.851 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 243.319C824.87 243.319 825.672 242.516 825.672 241.525C825.672 240.533 824.87 239.73 823.881 239.73C822.891 239.73 822.09 240.533 822.09 241.525C822.09 242.516 822.891 243.319 823.881 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 243.319C818.9 243.319 819.701 242.516 819.701 241.525C819.701 240.533 818.9 239.73 817.91 239.73C816.921 239.73 816.119 240.533 816.119 241.525C816.119 242.516 816.921 243.319 817.91 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 243.319C812.929 243.319 813.731 242.516 813.731 241.525C813.731 240.533 812.929 239.73 811.94 239.73C810.951 239.73 810.149 240.533 810.149 241.525C810.149 242.516 810.951 243.319 811.94 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 243.319C806.959 243.319 807.761 242.516 807.761 241.525C807.761 240.533 806.959 239.73 805.97 239.73C804.981 239.73 804.179 240.533 804.179 241.525C804.179 242.516 804.981 243.319 805.97 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M800 243.319C800.989 243.319 801.791 242.516 801.791 241.525C801.791 240.533 800.989 239.73 800 239.73C799.011 239.73 798.209 240.533 798.209 241.525C798.209 242.516 799.011 243.319 800 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 243.319C753.228 243.319 754.03 242.516 754.03 241.525C754.03 240.533 753.228 239.73 752.239 239.73C751.25 239.73 750.448 240.533 750.448 241.525C750.448 242.516 751.25 243.319 752.239 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 243.319C747.258 243.319 748.06 242.516 748.06 241.525C748.06 240.533 747.258 239.73 746.269 239.73C745.279 239.73 744.478 240.533 744.478 241.525C744.478 242.516 745.279 243.319 746.269 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 243.319C741.288 243.319 742.09 242.516 742.09 241.525C742.09 240.533 741.288 239.73 740.299 239.73C739.309 239.73 738.507 240.533 738.507 241.525C738.507 242.516 739.309 243.319 740.299 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 243.319C735.318 243.319 736.119 242.516 736.119 241.525C736.119 240.533 735.318 239.73 734.328 239.73C733.339 239.73 732.537 240.533 732.537 241.525C732.537 242.516 733.339 243.319 734.328 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 243.319C729.347 243.319 730.149 242.516 730.149 241.525C730.149 240.533 729.347 239.73 728.358 239.73C727.369 239.73 726.567 240.533 726.567 241.525C726.567 242.516 727.369 243.319 728.358 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 243.319C723.377 243.319 724.179 242.516 724.179 241.525C724.179 240.533 723.377 239.73 722.388 239.73C721.399 239.73 720.597 240.533 720.597 241.525C720.597 242.516 721.399 243.319 722.388 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 243.319C717.407 243.319 718.209 242.516 718.209 241.525C718.209 240.533 717.407 239.73 716.418 239.73C715.429 239.73 714.627 240.533 714.627 241.525C714.627 242.516 715.429 243.319 716.418 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 243.319C711.437 243.319 712.239 242.516 712.239 241.525C712.239 240.533 711.437 239.73 710.448 239.73C709.459 239.73 708.657 240.533 708.657 241.525C708.657 242.516 709.459 243.319 710.448 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 243.319C705.467 243.319 706.269 242.516 706.269 241.525C706.269 240.533 705.467 239.73 704.478 239.73C703.488 239.73 702.687 240.533 702.687 241.525C702.687 242.516 703.488 243.319 704.478 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 243.319C699.497 243.319 700.299 242.516 700.299 241.525C700.299 240.533 699.497 239.73 698.507 239.73C697.518 239.73 696.716 240.533 696.716 241.525C696.716 242.516 697.518 243.319 698.507 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 243.319C693.526 243.319 694.328 242.516 694.328 241.525C694.328 240.533 693.526 239.73 692.537 239.73C691.548 239.73 690.746 240.533 690.746 241.525C690.746 242.516 691.548 243.319 692.537 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 243.319C687.556 243.319 688.358 242.516 688.358 241.525C688.358 240.533 687.556 239.73 686.567 239.73C685.578 239.73 684.776 240.533 684.776 241.525C684.776 242.516 685.578 243.319 686.567 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 243.319C681.586 243.319 682.388 242.516 682.388 241.525C682.388 240.533 681.586 239.73 680.597 239.73C679.608 239.73 678.806 240.533 678.806 241.525C678.806 242.516 679.608 243.319 680.597 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 243.319C669.646 243.319 670.448 242.516 670.448 241.525C670.448 240.533 669.646 239.73 668.657 239.73C667.668 239.73 666.866 240.533 666.866 241.525C666.866 242.516 667.668 243.319 668.657 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 243.319C663.676 243.319 664.478 242.516 664.478 241.525C664.478 240.533 663.676 239.73 662.687 239.73C661.697 239.73 660.896 240.533 660.896 241.525C660.896 242.516 661.697 243.319 662.687 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 243.319C657.706 243.319 658.507 242.516 658.507 241.525C658.507 240.533 657.706 239.73 656.716 239.73C655.727 239.73 654.925 240.533 654.925 241.525C654.925 242.516 655.727 243.319 656.716 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 243.319C651.735 243.319 652.537 242.516 652.537 241.525C652.537 240.533 651.735 239.73 650.746 239.73C649.757 239.73 648.955 240.533 648.955 241.525C648.955 242.516 649.757 243.319 650.746 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 243.319C645.765 243.319 646.567 242.516 646.567 241.525C646.567 240.533 645.765 239.73 644.776 239.73C643.787 239.73 642.985 240.533 642.985 241.525C642.985 242.516 643.787 243.319 644.776 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 243.319C639.795 243.319 640.597 242.516 640.597 241.525C640.597 240.533 639.795 239.73 638.806 239.73C637.817 239.73 637.015 240.533 637.015 241.525C637.015 242.516 637.817 243.319 638.806 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 243.319C633.825 243.319 634.627 242.516 634.627 241.525C634.627 240.533 633.825 239.73 632.836 239.73C631.847 239.73 631.045 240.533 631.045 241.525C631.045 242.516 631.847 243.319 632.836 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 243.319C627.855 243.319 628.657 242.516 628.657 241.525C628.657 240.533 627.855 239.73 626.866 239.73C625.876 239.73 625.075 240.533 625.075 241.525C625.075 242.516 625.876 243.319 626.866 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 243.319C621.885 243.319 622.687 242.516 622.687 241.525C622.687 240.533 621.885 239.73 620.895 239.73C619.906 239.73 619.104 240.533 619.104 241.525C619.104 242.516 619.906 243.319 620.895 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 243.319C615.915 243.319 616.716 242.516 616.716 241.525C616.716 240.533 615.915 239.73 614.925 239.73C613.936 239.73 613.134 240.533 613.134 241.525C613.134 242.516 613.936 243.319 614.925 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 243.319C609.944 243.319 610.746 242.516 610.746 241.525C610.746 240.533 609.944 239.73 608.955 239.73C607.966 239.73 607.164 240.533 607.164 241.525C607.164 242.516 607.966 243.319 608.955 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 243.319C603.974 243.319 604.776 242.516 604.776 241.525C604.776 240.533 603.974 239.73 602.985 239.73C601.996 239.73 601.194 240.533 601.194 241.525C601.194 242.516 601.996 243.319 602.985 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 243.319C598.004 243.319 598.806 242.516 598.806 241.525C598.806 240.533 598.004 239.73 597.015 239.73C596.026 239.73 595.224 240.533 595.224 241.525C595.224 242.516 596.026 243.319 597.015 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 243.319C592.034 243.319 592.836 242.516 592.836 241.525C592.836 240.533 592.034 239.73 591.045 239.73C590.056 239.73 589.254 240.533 589.254 241.525C589.254 242.516 590.056 243.319 591.045 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 243.319C586.064 243.319 586.866 242.516 586.866 241.525C586.866 240.533 586.064 239.73 585.075 239.73C584.085 239.73 583.284 240.533 583.284 241.525C583.284 242.516 584.085 243.319 585.075 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 243.319C580.094 243.319 580.896 242.516 580.896 241.525C580.896 240.533 580.094 239.73 579.104 239.73C578.115 239.73 577.313 240.533 577.313 241.525C577.313 242.516 578.115 243.319 579.104 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 243.319C574.123 243.319 574.925 242.516 574.925 241.525C574.925 240.533 574.123 239.73 573.134 239.73C572.145 239.73 571.343 240.533 571.343 241.525C571.343 242.516 572.145 243.319 573.134 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 243.319C568.153 243.319 568.955 242.516 568.955 241.525C568.955 240.533 568.153 239.73 567.164 239.73C566.175 239.73 565.373 240.533 565.373 241.525C565.373 242.516 566.175 243.319 567.164 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 243.319C562.183 243.319 562.985 242.516 562.985 241.525C562.985 240.533 562.183 239.73 561.194 239.73C560.205 239.73 559.403 240.533 559.403 241.525C559.403 242.516 560.205 243.319 561.194 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 243.319C556.213 243.319 557.015 242.516 557.015 241.525C557.015 240.533 556.213 239.73 555.224 239.73C554.235 239.73 553.433 240.533 553.433 241.525C553.433 242.516 554.235 243.319 555.224 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 243.319C550.243 243.319 551.045 242.516 551.045 241.525C551.045 240.533 550.243 239.73 549.254 239.73C548.265 239.73 547.463 240.533 547.463 241.525C547.463 242.516 548.265 243.319 549.254 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 243.319C544.273 243.319 545.075 242.516 545.075 241.525C545.075 240.533 544.273 239.73 543.284 239.73C542.294 239.73 541.492 240.533 541.492 241.525C541.492 242.516 542.294 243.319 543.284 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 243.319C538.303 243.319 539.104 242.516 539.104 241.525C539.104 240.533 538.303 239.73 537.313 239.73C536.324 239.73 535.522 240.533 535.522 241.525C535.522 242.516 536.324 243.319 537.313 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 243.319C532.332 243.319 533.134 242.516 533.134 241.525C533.134 240.533 532.332 239.73 531.343 239.73C530.354 239.73 529.552 240.533 529.552 241.525C529.552 242.516 530.354 243.319 531.343 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 243.319C526.362 243.319 527.164 242.516 527.164 241.525C527.164 240.533 526.362 239.73 525.373 239.73C524.384 239.73 523.582 240.533 523.582 241.525C523.582 242.516 524.384 243.319 525.373 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 243.319C520.392 243.319 521.194 242.516 521.194 241.525C521.194 240.533 520.392 239.73 519.403 239.73C518.414 239.73 517.612 240.533 517.612 241.525C517.612 242.516 518.414 243.319 519.403 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 243.319C514.422 243.319 515.224 242.516 515.224 241.525C515.224 240.533 514.422 239.73 513.433 239.73C512.444 239.73 511.642 240.533 511.642 241.525C511.642 242.516 512.444 243.319 513.433 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 243.319C508.452 243.319 509.254 242.516 509.254 241.525C509.254 240.533 508.452 239.73 507.463 239.73C506.474 239.73 505.672 240.533 505.672 241.525C505.672 242.516 506.474 243.319 507.463 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 243.319C502.482 243.319 503.284 242.516 503.284 241.525C503.284 240.533 502.482 239.73 501.493 239.73C500.503 239.73 499.702 240.533 499.702 241.525C499.702 242.516 500.503 243.319 501.493 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 243.319C496.512 243.319 497.313 242.516 497.313 241.525C497.313 240.533 496.512 239.73 495.522 239.73C494.533 239.73 493.731 240.533 493.731 241.525C493.731 242.516 494.533 243.319 495.522 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 243.319C490.541 243.319 491.343 242.516 491.343 241.525C491.343 240.533 490.541 239.73 489.552 239.73C488.563 239.73 487.761 240.533 487.761 241.525C487.761 242.516 488.563 243.319 489.552 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 243.319C484.571 243.319 485.373 242.516 485.373 241.525C485.373 240.533 484.571 239.73 483.582 239.73C482.593 239.73 481.791 240.533 481.791 241.525C481.791 242.516 482.593 243.319 483.582 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 243.319C478.601 243.319 479.403 242.516 479.403 241.525C479.403 240.533 478.601 239.73 477.612 239.73C476.623 239.73 475.821 240.533 475.821 241.525C475.821 242.516 476.623 243.319 477.612 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 243.319C472.631 243.319 473.433 242.516 473.433 241.525C473.433 240.533 472.631 239.73 471.642 239.73C470.653 239.73 469.851 240.533 469.851 241.525C469.851 242.516 470.653 243.319 471.642 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 237.337C1003.97 237.337 1004.78 236.533 1004.78 235.542C1004.78 234.551 1003.97 233.748 1002.99 233.748C1002 233.748 1001.19 234.551 1001.19 235.542C1001.19 236.533 1002 237.337 1002.99 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 237.337C992.034 237.337 992.836 236.533 992.836 235.542C992.836 234.551 992.034 233.748 991.045 233.748C990.056 233.748 989.254 234.551 989.254 235.542C989.254 236.533 990.056 237.337 991.045 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 237.337C986.064 237.337 986.866 236.533 986.866 235.542C986.866 234.551 986.064 233.748 985.075 233.748C984.085 233.748 983.284 234.551 983.284 235.542C983.284 236.533 984.085 237.337 985.075 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 237.337C980.094 237.337 980.896 236.533 980.896 235.542C980.896 234.551 980.094 233.748 979.104 233.748C978.115 233.748 977.313 234.551 977.313 235.542C977.313 236.533 978.115 237.337 979.104 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 237.337C974.123 237.337 974.925 236.533 974.925 235.542C974.925 234.551 974.123 233.748 973.134 233.748C972.145 233.748 971.343 234.551 971.343 235.542C971.343 236.533 972.145 237.337 973.134 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 237.337C968.153 237.337 968.955 236.533 968.955 235.542C968.955 234.551 968.153 233.748 967.164 233.748C966.175 233.748 965.373 234.551 965.373 235.542C965.373 236.533 966.175 237.337 967.164 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 237.337C962.183 237.337 962.985 236.533 962.985 235.542C962.985 234.551 962.183 233.748 961.194 233.748C960.205 233.748 959.403 234.551 959.403 235.542C959.403 236.533 960.205 237.337 961.194 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 237.337C956.213 237.337 957.015 236.533 957.015 235.542C957.015 234.551 956.213 233.748 955.224 233.748C954.235 233.748 953.433 234.551 953.433 235.542C953.433 236.533 954.235 237.337 955.224 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 237.337C950.243 237.337 951.045 236.533 951.045 235.542C951.045 234.551 950.243 233.748 949.254 233.748C948.265 233.748 947.463 234.551 947.463 235.542C947.463 236.533 948.265 237.337 949.254 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 237.337C944.273 237.337 945.075 236.533 945.075 235.542C945.075 234.551 944.273 233.748 943.284 233.748C942.294 233.748 941.492 234.551 941.492 235.542C941.492 236.533 942.294 237.337 943.284 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 237.337C938.303 237.337 939.105 236.533 939.105 235.542C939.105 234.551 938.303 233.748 937.313 233.748C936.324 233.748 935.522 234.551 935.522 235.542C935.522 236.533 936.324 237.337 937.313 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 237.337C932.332 237.337 933.134 236.533 933.134 235.542C933.134 234.551 932.332 233.748 931.343 233.748C930.354 233.748 929.552 234.551 929.552 235.542C929.552 236.533 930.354 237.337 931.343 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 237.337C926.362 237.337 927.164 236.533 927.164 235.542C927.164 234.551 926.362 233.748 925.373 233.748C924.384 233.748 923.582 234.551 923.582 235.542C923.582 236.533 924.384 237.337 925.373 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 237.337C920.392 237.337 921.194 236.533 921.194 235.542C921.194 234.551 920.392 233.748 919.403 233.748C918.414 233.748 917.612 234.551 917.612 235.542C917.612 236.533 918.414 237.337 919.403 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 237.337C914.422 237.337 915.224 236.533 915.224 235.542C915.224 234.551 914.422 233.748 913.433 233.748C912.444 233.748 911.642 234.551 911.642 235.542C911.642 236.533 912.444 237.337 913.433 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 237.337C908.452 237.337 909.254 236.533 909.254 235.542C909.254 234.551 908.452 233.748 907.463 233.748C906.474 233.748 905.672 234.551 905.672 235.542C905.672 236.533 906.474 237.337 907.463 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 237.337C902.482 237.337 903.284 236.533 903.284 235.542C903.284 234.551 902.482 233.748 901.492 233.748C900.503 233.748 899.701 234.551 899.701 235.542C899.701 236.533 900.503 237.337 901.492 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 237.337C896.512 237.337 897.313 236.533 897.313 235.542C897.313 234.551 896.512 233.748 895.522 233.748C894.533 233.748 893.731 234.551 893.731 235.542C893.731 236.533 894.533 237.337 895.522 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 237.337C890.541 237.337 891.343 236.533 891.343 235.542C891.343 234.551 890.541 233.748 889.552 233.748C888.563 233.748 887.761 234.551 887.761 235.542C887.761 236.533 888.563 237.337 889.552 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 237.337C884.571 237.337 885.373 236.533 885.373 235.542C885.373 234.551 884.571 233.748 883.582 233.748C882.593 233.748 881.791 234.551 881.791 235.542C881.791 236.533 882.593 237.337 883.582 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 237.337C878.601 237.337 879.403 236.533 879.403 235.542C879.403 234.551 878.601 233.748 877.612 233.748C876.623 233.748 875.821 234.551 875.821 235.542C875.821 236.533 876.623 237.337 877.612 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 237.337C872.631 237.337 873.433 236.533 873.433 235.542C873.433 234.551 872.631 233.748 871.642 233.748C870.653 233.748 869.851 234.551 869.851 235.542C869.851 236.533 870.653 237.337 871.642 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 237.337C866.661 237.337 867.463 236.533 867.463 235.542C867.463 234.551 866.661 233.748 865.672 233.748C864.683 233.748 863.881 234.551 863.881 235.542C863.881 236.533 864.683 237.337 865.672 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 237.337C860.691 237.337 861.493 236.533 861.493 235.542C861.493 234.551 860.691 233.748 859.701 233.748C858.712 233.748 857.91 234.551 857.91 235.542C857.91 236.533 858.712 237.337 859.701 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 237.337C854.721 237.337 855.522 236.533 855.522 235.542C855.522 234.551 854.721 233.748 853.731 233.748C852.742 233.748 851.94 234.551 851.94 235.542C851.94 236.533 852.742 237.337 853.731 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 237.337C848.75 237.337 849.552 236.533 849.552 235.542C849.552 234.551 848.75 233.748 847.761 233.748C846.772 233.748 845.97 234.551 845.97 235.542C845.97 236.533 846.772 237.337 847.761 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 237.337C842.78 237.337 843.582 236.533 843.582 235.542C843.582 234.551 842.78 233.748 841.791 233.748C840.802 233.748 840 234.551 840 235.542C840 236.533 840.802 237.337 841.791 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 237.337C836.81 237.337 837.612 236.533 837.612 235.542C837.612 234.551 836.81 233.748 835.821 233.748C834.832 233.748 834.03 234.551 834.03 235.542C834.03 236.533 834.832 237.337 835.821 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 237.337C830.84 237.337 831.642 236.533 831.642 235.542C831.642 234.551 830.84 233.748 829.851 233.748C828.862 233.748 828.06 234.551 828.06 235.542C828.06 236.533 828.862 237.337 829.851 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 237.337C824.87 237.337 825.672 236.533 825.672 235.542C825.672 234.551 824.87 233.748 823.881 233.748C822.891 233.748 822.09 234.551 822.09 235.542C822.09 236.533 822.891 237.337 823.881 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 237.337C818.9 237.337 819.701 236.533 819.701 235.542C819.701 234.551 818.9 233.748 817.91 233.748C816.921 233.748 816.119 234.551 816.119 235.542C816.119 236.533 816.921 237.337 817.91 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 237.337C812.929 237.337 813.731 236.533 813.731 235.542C813.731 234.551 812.929 233.748 811.94 233.748C810.951 233.748 810.149 234.551 810.149 235.542C810.149 236.533 810.951 237.337 811.94 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 237.337C806.959 237.337 807.761 236.533 807.761 235.542C807.761 234.551 806.959 233.748 805.97 233.748C804.981 233.748 804.179 234.551 804.179 235.542C804.179 236.533 804.981 237.337 805.97 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M800 237.337C800.989 237.337 801.791 236.533 801.791 235.542C801.791 234.551 800.989 233.748 800 233.748C799.011 233.748 798.209 234.551 798.209 235.542C798.209 236.533 799.011 237.337 800 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 237.337C795.019 237.337 795.821 236.533 795.821 235.542C795.821 234.551 795.019 233.748 794.03 233.748C793.041 233.748 792.239 234.551 792.239 235.542C792.239 236.533 793.041 237.337 794.03 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 237.337C729.347 237.337 730.149 236.533 730.149 235.542C730.149 234.551 729.347 233.748 728.358 233.748C727.369 233.748 726.567 234.551 726.567 235.542C726.567 236.533 727.369 237.337 728.358 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 237.337C723.377 237.337 724.179 236.533 724.179 235.542C724.179 234.551 723.377 233.748 722.388 233.748C721.399 233.748 720.597 234.551 720.597 235.542C720.597 236.533 721.399 237.337 722.388 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 237.337C717.407 237.337 718.209 236.533 718.209 235.542C718.209 234.551 717.407 233.748 716.418 233.748C715.429 233.748 714.627 234.551 714.627 235.542C714.627 236.533 715.429 237.337 716.418 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 237.337C711.437 237.337 712.239 236.533 712.239 235.542C712.239 234.551 711.437 233.748 710.448 233.748C709.459 233.748 708.657 234.551 708.657 235.542C708.657 236.533 709.459 237.337 710.448 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 237.337C705.467 237.337 706.269 236.533 706.269 235.542C706.269 234.551 705.467 233.748 704.478 233.748C703.488 233.748 702.687 234.551 702.687 235.542C702.687 236.533 703.488 237.337 704.478 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 237.337C699.497 237.337 700.299 236.533 700.299 235.542C700.299 234.551 699.497 233.748 698.507 233.748C697.518 233.748 696.716 234.551 696.716 235.542C696.716 236.533 697.518 237.337 698.507 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 237.337C693.526 237.337 694.328 236.533 694.328 235.542C694.328 234.551 693.526 233.748 692.537 233.748C691.548 233.748 690.746 234.551 690.746 235.542C690.746 236.533 691.548 237.337 692.537 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 237.337C687.556 237.337 688.358 236.533 688.358 235.542C688.358 234.551 687.556 233.748 686.567 233.748C685.578 233.748 684.776 234.551 684.776 235.542C684.776 236.533 685.578 237.337 686.567 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 237.337C681.586 237.337 682.388 236.533 682.388 235.542C682.388 234.551 681.586 233.748 680.597 233.748C679.608 233.748 678.806 234.551 678.806 235.542C678.806 236.533 679.608 237.337 680.597 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 237.337C663.676 237.337 664.478 236.533 664.478 235.542C664.478 234.551 663.676 233.748 662.687 233.748C661.697 233.748 660.896 234.551 660.896 235.542C660.896 236.533 661.697 237.337 662.687 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 237.337C657.706 237.337 658.507 236.533 658.507 235.542C658.507 234.551 657.706 233.748 656.716 233.748C655.727 233.748 654.925 234.551 654.925 235.542C654.925 236.533 655.727 237.337 656.716 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 237.337C651.735 237.337 652.537 236.533 652.537 235.542C652.537 234.551 651.735 233.748 650.746 233.748C649.757 233.748 648.955 234.551 648.955 235.542C648.955 236.533 649.757 237.337 650.746 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 237.337C645.765 237.337 646.567 236.533 646.567 235.542C646.567 234.551 645.765 233.748 644.776 233.748C643.787 233.748 642.985 234.551 642.985 235.542C642.985 236.533 643.787 237.337 644.776 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 237.337C639.795 237.337 640.597 236.533 640.597 235.542C640.597 234.551 639.795 233.748 638.806 233.748C637.817 233.748 637.015 234.551 637.015 235.542C637.015 236.533 637.817 237.337 638.806 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 237.337C633.825 237.337 634.627 236.533 634.627 235.542C634.627 234.551 633.825 233.748 632.836 233.748C631.847 233.748 631.045 234.551 631.045 235.542C631.045 236.533 631.847 237.337 632.836 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 237.337C627.855 237.337 628.657 236.533 628.657 235.542C628.657 234.551 627.855 233.748 626.866 233.748C625.876 233.748 625.075 234.551 625.075 235.542C625.075 236.533 625.876 237.337 626.866 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 237.337C621.885 237.337 622.687 236.533 622.687 235.542C622.687 234.551 621.885 233.748 620.895 233.748C619.906 233.748 619.104 234.551 619.104 235.542C619.104 236.533 619.906 237.337 620.895 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 237.337C615.915 237.337 616.716 236.533 616.716 235.542C616.716 234.551 615.915 233.748 614.925 233.748C613.936 233.748 613.134 234.551 613.134 235.542C613.134 236.533 613.936 237.337 614.925 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 237.337C609.944 237.337 610.746 236.533 610.746 235.542C610.746 234.551 609.944 233.748 608.955 233.748C607.966 233.748 607.164 234.551 607.164 235.542C607.164 236.533 607.966 237.337 608.955 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 237.337C603.974 237.337 604.776 236.533 604.776 235.542C604.776 234.551 603.974 233.748 602.985 233.748C601.996 233.748 601.194 234.551 601.194 235.542C601.194 236.533 601.996 237.337 602.985 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 237.337C598.004 237.337 598.806 236.533 598.806 235.542C598.806 234.551 598.004 233.748 597.015 233.748C596.026 233.748 595.224 234.551 595.224 235.542C595.224 236.533 596.026 237.337 597.015 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 237.337C592.034 237.337 592.836 236.533 592.836 235.542C592.836 234.551 592.034 233.748 591.045 233.748C590.056 233.748 589.254 234.551 589.254 235.542C589.254 236.533 590.056 237.337 591.045 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 237.337C586.064 237.337 586.866 236.533 586.866 235.542C586.866 234.551 586.064 233.748 585.075 233.748C584.085 233.748 583.284 234.551 583.284 235.542C583.284 236.533 584.085 237.337 585.075 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 237.337C580.094 237.337 580.896 236.533 580.896 235.542C580.896 234.551 580.094 233.748 579.104 233.748C578.115 233.748 577.313 234.551 577.313 235.542C577.313 236.533 578.115 237.337 579.104 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 237.337C574.123 237.337 574.925 236.533 574.925 235.542C574.925 234.551 574.123 233.748 573.134 233.748C572.145 233.748 571.343 234.551 571.343 235.542C571.343 236.533 572.145 237.337 573.134 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 237.337C568.153 237.337 568.955 236.533 568.955 235.542C568.955 234.551 568.153 233.748 567.164 233.748C566.175 233.748 565.373 234.551 565.373 235.542C565.373 236.533 566.175 237.337 567.164 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 237.337C562.183 237.337 562.985 236.533 562.985 235.542C562.985 234.551 562.183 233.748 561.194 233.748C560.205 233.748 559.403 234.551 559.403 235.542C559.403 236.533 560.205 237.337 561.194 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 237.337C556.213 237.337 557.015 236.533 557.015 235.542C557.015 234.551 556.213 233.748 555.224 233.748C554.235 233.748 553.433 234.551 553.433 235.542C553.433 236.533 554.235 237.337 555.224 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 237.337C550.243 237.337 551.045 236.533 551.045 235.542C551.045 234.551 550.243 233.748 549.254 233.748C548.265 233.748 547.463 234.551 547.463 235.542C547.463 236.533 548.265 237.337 549.254 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 237.337C544.273 237.337 545.075 236.533 545.075 235.542C545.075 234.551 544.273 233.748 543.284 233.748C542.294 233.748 541.492 234.551 541.492 235.542C541.492 236.533 542.294 237.337 543.284 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 237.337C538.303 237.337 539.104 236.533 539.104 235.542C539.104 234.551 538.303 233.748 537.313 233.748C536.324 233.748 535.522 234.551 535.522 235.542C535.522 236.533 536.324 237.337 537.313 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 237.337C532.332 237.337 533.134 236.533 533.134 235.542C533.134 234.551 532.332 233.748 531.343 233.748C530.354 233.748 529.552 234.551 529.552 235.542C529.552 236.533 530.354 237.337 531.343 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 237.337C526.362 237.337 527.164 236.533 527.164 235.542C527.164 234.551 526.362 233.748 525.373 233.748C524.384 233.748 523.582 234.551 523.582 235.542C523.582 236.533 524.384 237.337 525.373 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 237.337C520.392 237.337 521.194 236.533 521.194 235.542C521.194 234.551 520.392 233.748 519.403 233.748C518.414 233.748 517.612 234.551 517.612 235.542C517.612 236.533 518.414 237.337 519.403 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 237.337C514.422 237.337 515.224 236.533 515.224 235.542C515.224 234.551 514.422 233.748 513.433 233.748C512.444 233.748 511.642 234.551 511.642 235.542C511.642 236.533 512.444 237.337 513.433 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 237.337C508.452 237.337 509.254 236.533 509.254 235.542C509.254 234.551 508.452 233.748 507.463 233.748C506.474 233.748 505.672 234.551 505.672 235.542C505.672 236.533 506.474 237.337 507.463 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 237.337C502.482 237.337 503.284 236.533 503.284 235.542C503.284 234.551 502.482 233.748 501.493 233.748C500.503 233.748 499.702 234.551 499.702 235.542C499.702 236.533 500.503 237.337 501.493 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 237.337C496.512 237.337 497.313 236.533 497.313 235.542C497.313 234.551 496.512 233.748 495.522 233.748C494.533 233.748 493.731 234.551 493.731 235.542C493.731 236.533 494.533 237.337 495.522 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 237.337C490.541 237.337 491.343 236.533 491.343 235.542C491.343 234.551 490.541 233.748 489.552 233.748C488.563 233.748 487.761 234.551 487.761 235.542C487.761 236.533 488.563 237.337 489.552 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 237.337C484.571 237.337 485.373 236.533 485.373 235.542C485.373 234.551 484.571 233.748 483.582 233.748C482.593 233.748 481.791 234.551 481.791 235.542C481.791 236.533 482.593 237.337 483.582 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 237.337C478.601 237.337 479.403 236.533 479.403 235.542C479.403 234.551 478.601 233.748 477.612 233.748C476.623 233.748 475.821 234.551 475.821 235.542C475.821 236.533 476.623 237.337 477.612 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 231.354C992.034 231.354 992.836 230.551 992.836 229.56C992.836 228.569 992.034 227.765 991.045 227.765C990.056 227.765 989.254 228.569 989.254 229.56C989.254 230.551 990.056 231.354 991.045 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 231.354C986.064 231.354 986.866 230.551 986.866 229.56C986.866 228.569 986.064 227.765 985.075 227.765C984.085 227.765 983.284 228.569 983.284 229.56C983.284 230.551 984.085 231.354 985.075 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 231.354C980.094 231.354 980.896 230.551 980.896 229.56C980.896 228.569 980.094 227.765 979.104 227.765C978.115 227.765 977.313 228.569 977.313 229.56C977.313 230.551 978.115 231.354 979.104 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 231.354C974.123 231.354 974.925 230.551 974.925 229.56C974.925 228.569 974.123 227.765 973.134 227.765C972.145 227.765 971.343 228.569 971.343 229.56C971.343 230.551 972.145 231.354 973.134 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 231.354C968.153 231.354 968.955 230.551 968.955 229.56C968.955 228.569 968.153 227.765 967.164 227.765C966.175 227.765 965.373 228.569 965.373 229.56C965.373 230.551 966.175 231.354 967.164 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 231.354C962.183 231.354 962.985 230.551 962.985 229.56C962.985 228.569 962.183 227.765 961.194 227.765C960.205 227.765 959.403 228.569 959.403 229.56C959.403 230.551 960.205 231.354 961.194 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 231.354C956.213 231.354 957.015 230.551 957.015 229.56C957.015 228.569 956.213 227.765 955.224 227.765C954.235 227.765 953.433 228.569 953.433 229.56C953.433 230.551 954.235 231.354 955.224 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 231.354C950.243 231.354 951.045 230.551 951.045 229.56C951.045 228.569 950.243 227.765 949.254 227.765C948.265 227.765 947.463 228.569 947.463 229.56C947.463 230.551 948.265 231.354 949.254 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 231.354C944.273 231.354 945.075 230.551 945.075 229.56C945.075 228.569 944.273 227.765 943.284 227.765C942.294 227.765 941.492 228.569 941.492 229.56C941.492 230.551 942.294 231.354 943.284 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 231.354C938.303 231.354 939.105 230.551 939.105 229.56C939.105 228.569 938.303 227.765 937.313 227.765C936.324 227.765 935.522 228.569 935.522 229.56C935.522 230.551 936.324 231.354 937.313 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 231.354C932.332 231.354 933.134 230.551 933.134 229.56C933.134 228.569 932.332 227.765 931.343 227.765C930.354 227.765 929.552 228.569 929.552 229.56C929.552 230.551 930.354 231.354 931.343 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 231.354C926.362 231.354 927.164 230.551 927.164 229.56C927.164 228.569 926.362 227.765 925.373 227.765C924.384 227.765 923.582 228.569 923.582 229.56C923.582 230.551 924.384 231.354 925.373 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 231.354C920.392 231.354 921.194 230.551 921.194 229.56C921.194 228.569 920.392 227.765 919.403 227.765C918.414 227.765 917.612 228.569 917.612 229.56C917.612 230.551 918.414 231.354 919.403 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 231.354C914.422 231.354 915.224 230.551 915.224 229.56C915.224 228.569 914.422 227.765 913.433 227.765C912.444 227.765 911.642 228.569 911.642 229.56C911.642 230.551 912.444 231.354 913.433 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 231.354C908.452 231.354 909.254 230.551 909.254 229.56C909.254 228.569 908.452 227.765 907.463 227.765C906.474 227.765 905.672 228.569 905.672 229.56C905.672 230.551 906.474 231.354 907.463 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 231.354C902.482 231.354 903.284 230.551 903.284 229.56C903.284 228.569 902.482 227.765 901.492 227.765C900.503 227.765 899.701 228.569 899.701 229.56C899.701 230.551 900.503 231.354 901.492 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 231.354C896.512 231.354 897.313 230.551 897.313 229.56C897.313 228.569 896.512 227.765 895.522 227.765C894.533 227.765 893.731 228.569 893.731 229.56C893.731 230.551 894.533 231.354 895.522 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 231.354C890.541 231.354 891.343 230.551 891.343 229.56C891.343 228.569 890.541 227.765 889.552 227.765C888.563 227.765 887.761 228.569 887.761 229.56C887.761 230.551 888.563 231.354 889.552 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 231.354C884.571 231.354 885.373 230.551 885.373 229.56C885.373 228.569 884.571 227.765 883.582 227.765C882.593 227.765 881.791 228.569 881.791 229.56C881.791 230.551 882.593 231.354 883.582 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 231.354C878.601 231.354 879.403 230.551 879.403 229.56C879.403 228.569 878.601 227.765 877.612 227.765C876.623 227.765 875.821 228.569 875.821 229.56C875.821 230.551 876.623 231.354 877.612 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 231.354C872.631 231.354 873.433 230.551 873.433 229.56C873.433 228.569 872.631 227.765 871.642 227.765C870.653 227.765 869.851 228.569 869.851 229.56C869.851 230.551 870.653 231.354 871.642 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 231.354C866.661 231.354 867.463 230.551 867.463 229.56C867.463 228.569 866.661 227.765 865.672 227.765C864.683 227.765 863.881 228.569 863.881 229.56C863.881 230.551 864.683 231.354 865.672 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 231.354C860.691 231.354 861.493 230.551 861.493 229.56C861.493 228.569 860.691 227.765 859.701 227.765C858.712 227.765 857.91 228.569 857.91 229.56C857.91 230.551 858.712 231.354 859.701 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 231.354C854.721 231.354 855.522 230.551 855.522 229.56C855.522 228.569 854.721 227.765 853.731 227.765C852.742 227.765 851.94 228.569 851.94 229.56C851.94 230.551 852.742 231.354 853.731 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 231.354C848.75 231.354 849.552 230.551 849.552 229.56C849.552 228.569 848.75 227.765 847.761 227.765C846.772 227.765 845.97 228.569 845.97 229.56C845.97 230.551 846.772 231.354 847.761 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 231.354C842.78 231.354 843.582 230.551 843.582 229.56C843.582 228.569 842.78 227.765 841.791 227.765C840.802 227.765 840 228.569 840 229.56C840 230.551 840.802 231.354 841.791 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 231.354C836.81 231.354 837.612 230.551 837.612 229.56C837.612 228.569 836.81 227.765 835.821 227.765C834.832 227.765 834.03 228.569 834.03 229.56C834.03 230.551 834.832 231.354 835.821 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 231.354C830.84 231.354 831.642 230.551 831.642 229.56C831.642 228.569 830.84 227.765 829.851 227.765C828.862 227.765 828.06 228.569 828.06 229.56C828.06 230.551 828.862 231.354 829.851 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 231.354C824.87 231.354 825.672 230.551 825.672 229.56C825.672 228.569 824.87 227.765 823.881 227.765C822.891 227.765 822.09 228.569 822.09 229.56C822.09 230.551 822.891 231.354 823.881 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 231.354C818.9 231.354 819.701 230.551 819.701 229.56C819.701 228.569 818.9 227.765 817.91 227.765C816.921 227.765 816.119 228.569 816.119 229.56C816.119 230.551 816.921 231.354 817.91 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 231.354C812.929 231.354 813.731 230.551 813.731 229.56C813.731 228.569 812.929 227.765 811.94 227.765C810.951 227.765 810.149 228.569 810.149 229.56C810.149 230.551 810.951 231.354 811.94 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 231.354C806.959 231.354 807.761 230.551 807.761 229.56C807.761 228.569 806.959 227.765 805.97 227.765C804.981 227.765 804.179 228.569 804.179 229.56C804.179 230.551 804.981 231.354 805.97 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M800 231.354C800.989 231.354 801.791 230.551 801.791 229.56C801.791 228.569 800.989 227.765 800 227.765C799.011 227.765 798.209 228.569 798.209 229.56C798.209 230.551 799.011 231.354 800 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 231.354C795.019 231.354 795.821 230.551 795.821 229.56C795.821 228.569 795.019 227.765 794.03 227.765C793.041 227.765 792.239 228.569 792.239 229.56C792.239 230.551 793.041 231.354 794.03 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 231.354C789.049 231.354 789.851 230.551 789.851 229.56C789.851 228.569 789.049 227.765 788.06 227.765C787.071 227.765 786.269 228.569 786.269 229.56C786.269 230.551 787.071 231.354 788.06 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 231.354C783.079 231.354 783.881 230.551 783.881 229.56C783.881 228.569 783.079 227.765 782.09 227.765C781.1 227.765 780.299 228.569 780.299 229.56C780.299 230.551 781.1 231.354 782.09 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 231.354C777.109 231.354 777.91 230.551 777.91 229.56C777.91 228.569 777.109 227.765 776.119 227.765C775.13 227.765 774.328 228.569 774.328 229.56C774.328 230.551 775.13 231.354 776.119 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 231.354C771.138 231.354 771.94 230.551 771.94 229.56C771.94 228.569 771.138 227.765 770.149 227.765C769.16 227.765 768.358 228.569 768.358 229.56C768.358 230.551 769.16 231.354 770.149 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 231.354C765.168 231.354 765.97 230.551 765.97 229.56C765.97 228.569 765.168 227.765 764.179 227.765C763.19 227.765 762.388 228.569 762.388 229.56C762.388 230.551 763.19 231.354 764.179 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 231.354C759.198 231.354 760 230.551 760 229.56C760 228.569 759.198 227.765 758.209 227.765C757.22 227.765 756.418 228.569 756.418 229.56C756.418 230.551 757.22 231.354 758.209 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 231.354C753.228 231.354 754.03 230.551 754.03 229.56C754.03 228.569 753.228 227.765 752.239 227.765C751.25 227.765 750.448 228.569 750.448 229.56C750.448 230.551 751.25 231.354 752.239 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 231.354C723.377 231.354 724.179 230.551 724.179 229.56C724.179 228.569 723.377 227.765 722.388 227.765C721.399 227.765 720.597 228.569 720.597 229.56C720.597 230.551 721.399 231.354 722.388 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 231.354C717.407 231.354 718.209 230.551 718.209 229.56C718.209 228.569 717.407 227.765 716.418 227.765C715.429 227.765 714.627 228.569 714.627 229.56C714.627 230.551 715.429 231.354 716.418 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 231.354C711.437 231.354 712.239 230.551 712.239 229.56C712.239 228.569 711.437 227.765 710.448 227.765C709.459 227.765 708.657 228.569 708.657 229.56C708.657 230.551 709.459 231.354 710.448 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 231.354C705.467 231.354 706.269 230.551 706.269 229.56C706.269 228.569 705.467 227.765 704.478 227.765C703.488 227.765 702.687 228.569 702.687 229.56C702.687 230.551 703.488 231.354 704.478 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 231.354C699.497 231.354 700.299 230.551 700.299 229.56C700.299 228.569 699.497 227.765 698.507 227.765C697.518 227.765 696.716 228.569 696.716 229.56C696.716 230.551 697.518 231.354 698.507 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 231.354C693.526 231.354 694.328 230.551 694.328 229.56C694.328 228.569 693.526 227.765 692.537 227.765C691.548 227.765 690.746 228.569 690.746 229.56C690.746 230.551 691.548 231.354 692.537 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 231.354C687.556 231.354 688.358 230.551 688.358 229.56C688.358 228.569 687.556 227.765 686.567 227.765C685.578 227.765 684.776 228.569 684.776 229.56C684.776 230.551 685.578 231.354 686.567 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 231.354C681.586 231.354 682.388 230.551 682.388 229.56C682.388 228.569 681.586 227.765 680.597 227.765C679.608 227.765 678.806 228.569 678.806 229.56C678.806 230.551 679.608 231.354 680.597 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 231.354C675.616 231.354 676.418 230.551 676.418 229.56C676.418 228.569 675.616 227.765 674.627 227.765C673.638 227.765 672.836 228.569 672.836 229.56C672.836 230.551 673.638 231.354 674.627 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 231.354C663.676 231.354 664.478 230.551 664.478 229.56C664.478 228.569 663.676 227.765 662.687 227.765C661.697 227.765 660.896 228.569 660.896 229.56C660.896 230.551 661.697 231.354 662.687 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 231.354C657.706 231.354 658.507 230.551 658.507 229.56C658.507 228.569 657.706 227.765 656.716 227.765C655.727 227.765 654.925 228.569 654.925 229.56C654.925 230.551 655.727 231.354 656.716 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 231.354C651.735 231.354 652.537 230.551 652.537 229.56C652.537 228.569 651.735 227.765 650.746 227.765C649.757 227.765 648.955 228.569 648.955 229.56C648.955 230.551 649.757 231.354 650.746 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 231.354C645.765 231.354 646.567 230.551 646.567 229.56C646.567 228.569 645.765 227.765 644.776 227.765C643.787 227.765 642.985 228.569 642.985 229.56C642.985 230.551 643.787 231.354 644.776 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 231.354C639.795 231.354 640.597 230.551 640.597 229.56C640.597 228.569 639.795 227.765 638.806 227.765C637.817 227.765 637.015 228.569 637.015 229.56C637.015 230.551 637.817 231.354 638.806 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 231.354C633.825 231.354 634.627 230.551 634.627 229.56C634.627 228.569 633.825 227.765 632.836 227.765C631.847 227.765 631.045 228.569 631.045 229.56C631.045 230.551 631.847 231.354 632.836 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 231.354C627.855 231.354 628.657 230.551 628.657 229.56C628.657 228.569 627.855 227.765 626.866 227.765C625.876 227.765 625.075 228.569 625.075 229.56C625.075 230.551 625.876 231.354 626.866 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 231.354C621.885 231.354 622.687 230.551 622.687 229.56C622.687 228.569 621.885 227.765 620.895 227.765C619.906 227.765 619.104 228.569 619.104 229.56C619.104 230.551 619.906 231.354 620.895 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 231.354C615.915 231.354 616.716 230.551 616.716 229.56C616.716 228.569 615.915 227.765 614.925 227.765C613.936 227.765 613.134 228.569 613.134 229.56C613.134 230.551 613.936 231.354 614.925 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 231.354C609.944 231.354 610.746 230.551 610.746 229.56C610.746 228.569 609.944 227.765 608.955 227.765C607.966 227.765 607.164 228.569 607.164 229.56C607.164 230.551 607.966 231.354 608.955 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 231.354C603.974 231.354 604.776 230.551 604.776 229.56C604.776 228.569 603.974 227.765 602.985 227.765C601.996 227.765 601.194 228.569 601.194 229.56C601.194 230.551 601.996 231.354 602.985 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 231.354C598.004 231.354 598.806 230.551 598.806 229.56C598.806 228.569 598.004 227.765 597.015 227.765C596.026 227.765 595.224 228.569 595.224 229.56C595.224 230.551 596.026 231.354 597.015 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 231.354C592.034 231.354 592.836 230.551 592.836 229.56C592.836 228.569 592.034 227.765 591.045 227.765C590.056 227.765 589.254 228.569 589.254 229.56C589.254 230.551 590.056 231.354 591.045 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 231.354C586.064 231.354 586.866 230.551 586.866 229.56C586.866 228.569 586.064 227.765 585.075 227.765C584.085 227.765 583.284 228.569 583.284 229.56C583.284 230.551 584.085 231.354 585.075 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 231.354C580.094 231.354 580.896 230.551 580.896 229.56C580.896 228.569 580.094 227.765 579.104 227.765C578.115 227.765 577.313 228.569 577.313 229.56C577.313 230.551 578.115 231.354 579.104 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 231.354C574.123 231.354 574.925 230.551 574.925 229.56C574.925 228.569 574.123 227.765 573.134 227.765C572.145 227.765 571.343 228.569 571.343 229.56C571.343 230.551 572.145 231.354 573.134 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 231.354C568.153 231.354 568.955 230.551 568.955 229.56C568.955 228.569 568.153 227.765 567.164 227.765C566.175 227.765 565.373 228.569 565.373 229.56C565.373 230.551 566.175 231.354 567.164 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 231.354C562.183 231.354 562.985 230.551 562.985 229.56C562.985 228.569 562.183 227.765 561.194 227.765C560.205 227.765 559.403 228.569 559.403 229.56C559.403 230.551 560.205 231.354 561.194 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 231.354C556.213 231.354 557.015 230.551 557.015 229.56C557.015 228.569 556.213 227.765 555.224 227.765C554.235 227.765 553.433 228.569 553.433 229.56C553.433 230.551 554.235 231.354 555.224 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 231.354C550.243 231.354 551.045 230.551 551.045 229.56C551.045 228.569 550.243 227.765 549.254 227.765C548.265 227.765 547.463 228.569 547.463 229.56C547.463 230.551 548.265 231.354 549.254 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 231.354C544.273 231.354 545.075 230.551 545.075 229.56C545.075 228.569 544.273 227.765 543.284 227.765C542.294 227.765 541.492 228.569 541.492 229.56C541.492 230.551 542.294 231.354 543.284 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 231.354C538.303 231.354 539.104 230.551 539.104 229.56C539.104 228.569 538.303 227.765 537.313 227.765C536.324 227.765 535.522 228.569 535.522 229.56C535.522 230.551 536.324 231.354 537.313 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 231.354C532.332 231.354 533.134 230.551 533.134 229.56C533.134 228.569 532.332 227.765 531.343 227.765C530.354 227.765 529.552 228.569 529.552 229.56C529.552 230.551 530.354 231.354 531.343 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 231.354C526.362 231.354 527.164 230.551 527.164 229.56C527.164 228.569 526.362 227.765 525.373 227.765C524.384 227.765 523.582 228.569 523.582 229.56C523.582 230.551 524.384 231.354 525.373 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 231.354C520.392 231.354 521.194 230.551 521.194 229.56C521.194 228.569 520.392 227.765 519.403 227.765C518.414 227.765 517.612 228.569 517.612 229.56C517.612 230.551 518.414 231.354 519.403 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 231.354C514.422 231.354 515.224 230.551 515.224 229.56C515.224 228.569 514.422 227.765 513.433 227.765C512.444 227.765 511.642 228.569 511.642 229.56C511.642 230.551 512.444 231.354 513.433 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 231.354C508.452 231.354 509.254 230.551 509.254 229.56C509.254 228.569 508.452 227.765 507.463 227.765C506.474 227.765 505.672 228.569 505.672 229.56C505.672 230.551 506.474 231.354 507.463 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 231.354C502.482 231.354 503.284 230.551 503.284 229.56C503.284 228.569 502.482 227.765 501.493 227.765C500.503 227.765 499.702 228.569 499.702 229.56C499.702 230.551 500.503 231.354 501.493 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 231.354C496.512 231.354 497.313 230.551 497.313 229.56C497.313 228.569 496.512 227.765 495.522 227.765C494.533 227.765 493.731 228.569 493.731 229.56C493.731 230.551 494.533 231.354 495.522 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 231.354C490.541 231.354 491.343 230.551 491.343 229.56C491.343 228.569 490.541 227.765 489.552 227.765C488.563 227.765 487.761 228.569 487.761 229.56C487.761 230.551 488.563 231.354 489.552 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 231.354C484.571 231.354 485.373 230.551 485.373 229.56C485.373 228.569 484.571 227.765 483.582 227.765C482.593 227.765 481.791 228.569 481.791 229.56C481.791 230.551 482.593 231.354 483.582 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 231.354C478.601 231.354 479.403 230.551 479.403 229.56C479.403 228.569 478.601 227.765 477.612 227.765C476.623 227.765 475.821 228.569 475.821 229.56C475.821 230.551 476.623 231.354 477.612 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 225.373C992.034 225.373 992.836 224.569 992.836 223.578C992.836 222.587 992.034 221.783 991.045 221.783C990.056 221.783 989.254 222.587 989.254 223.578C989.254 224.569 990.056 225.373 991.045 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 225.373C986.064 225.373 986.866 224.569 986.866 223.578C986.866 222.587 986.064 221.783 985.075 221.783C984.085 221.783 983.284 222.587 983.284 223.578C983.284 224.569 984.085 225.373 985.075 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 225.373C980.094 225.373 980.896 224.569 980.896 223.578C980.896 222.587 980.094 221.783 979.104 221.783C978.115 221.783 977.313 222.587 977.313 223.578C977.313 224.569 978.115 225.373 979.104 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 225.373C974.123 225.373 974.925 224.569 974.925 223.578C974.925 222.587 974.123 221.783 973.134 221.783C972.145 221.783 971.343 222.587 971.343 223.578C971.343 224.569 972.145 225.373 973.134 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 225.373C968.153 225.373 968.955 224.569 968.955 223.578C968.955 222.587 968.153 221.783 967.164 221.783C966.175 221.783 965.373 222.587 965.373 223.578C965.373 224.569 966.175 225.373 967.164 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 225.373C962.183 225.373 962.985 224.569 962.985 223.578C962.985 222.587 962.183 221.783 961.194 221.783C960.205 221.783 959.403 222.587 959.403 223.578C959.403 224.569 960.205 225.373 961.194 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 225.373C956.213 225.373 957.015 224.569 957.015 223.578C957.015 222.587 956.213 221.783 955.224 221.783C954.235 221.783 953.433 222.587 953.433 223.578C953.433 224.569 954.235 225.373 955.224 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 225.373C950.243 225.373 951.045 224.569 951.045 223.578C951.045 222.587 950.243 221.783 949.254 221.783C948.265 221.783 947.463 222.587 947.463 223.578C947.463 224.569 948.265 225.373 949.254 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 225.373C944.273 225.373 945.075 224.569 945.075 223.578C945.075 222.587 944.273 221.783 943.284 221.783C942.294 221.783 941.492 222.587 941.492 223.578C941.492 224.569 942.294 225.373 943.284 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 225.373C938.303 225.373 939.105 224.569 939.105 223.578C939.105 222.587 938.303 221.783 937.313 221.783C936.324 221.783 935.522 222.587 935.522 223.578C935.522 224.569 936.324 225.373 937.313 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 225.373C932.332 225.373 933.134 224.569 933.134 223.578C933.134 222.587 932.332 221.783 931.343 221.783C930.354 221.783 929.552 222.587 929.552 223.578C929.552 224.569 930.354 225.373 931.343 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 225.373C926.362 225.373 927.164 224.569 927.164 223.578C927.164 222.587 926.362 221.783 925.373 221.783C924.384 221.783 923.582 222.587 923.582 223.578C923.582 224.569 924.384 225.373 925.373 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 225.373C920.392 225.373 921.194 224.569 921.194 223.578C921.194 222.587 920.392 221.783 919.403 221.783C918.414 221.783 917.612 222.587 917.612 223.578C917.612 224.569 918.414 225.373 919.403 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 225.373C914.422 225.373 915.224 224.569 915.224 223.578C915.224 222.587 914.422 221.783 913.433 221.783C912.444 221.783 911.642 222.587 911.642 223.578C911.642 224.569 912.444 225.373 913.433 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 225.373C908.452 225.373 909.254 224.569 909.254 223.578C909.254 222.587 908.452 221.783 907.463 221.783C906.474 221.783 905.672 222.587 905.672 223.578C905.672 224.569 906.474 225.373 907.463 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 225.373C902.482 225.373 903.284 224.569 903.284 223.578C903.284 222.587 902.482 221.783 901.492 221.783C900.503 221.783 899.701 222.587 899.701 223.578C899.701 224.569 900.503 225.373 901.492 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 225.373C896.512 225.373 897.313 224.569 897.313 223.578C897.313 222.587 896.512 221.783 895.522 221.783C894.533 221.783 893.731 222.587 893.731 223.578C893.731 224.569 894.533 225.373 895.522 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 225.373C890.541 225.373 891.343 224.569 891.343 223.578C891.343 222.587 890.541 221.783 889.552 221.783C888.563 221.783 887.761 222.587 887.761 223.578C887.761 224.569 888.563 225.373 889.552 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 225.373C884.571 225.373 885.373 224.569 885.373 223.578C885.373 222.587 884.571 221.783 883.582 221.783C882.593 221.783 881.791 222.587 881.791 223.578C881.791 224.569 882.593 225.373 883.582 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 225.373C878.601 225.373 879.403 224.569 879.403 223.578C879.403 222.587 878.601 221.783 877.612 221.783C876.623 221.783 875.821 222.587 875.821 223.578C875.821 224.569 876.623 225.373 877.612 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 225.373C872.631 225.373 873.433 224.569 873.433 223.578C873.433 222.587 872.631 221.783 871.642 221.783C870.653 221.783 869.851 222.587 869.851 223.578C869.851 224.569 870.653 225.373 871.642 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 225.373C866.661 225.373 867.463 224.569 867.463 223.578C867.463 222.587 866.661 221.783 865.672 221.783C864.683 221.783 863.881 222.587 863.881 223.578C863.881 224.569 864.683 225.373 865.672 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 225.373C860.691 225.373 861.493 224.569 861.493 223.578C861.493 222.587 860.691 221.783 859.701 221.783C858.712 221.783 857.91 222.587 857.91 223.578C857.91 224.569 858.712 225.373 859.701 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 225.373C854.721 225.373 855.522 224.569 855.522 223.578C855.522 222.587 854.721 221.783 853.731 221.783C852.742 221.783 851.94 222.587 851.94 223.578C851.94 224.569 852.742 225.373 853.731 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 225.373C848.75 225.373 849.552 224.569 849.552 223.578C849.552 222.587 848.75 221.783 847.761 221.783C846.772 221.783 845.97 222.587 845.97 223.578C845.97 224.569 846.772 225.373 847.761 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 225.373C842.78 225.373 843.582 224.569 843.582 223.578C843.582 222.587 842.78 221.783 841.791 221.783C840.802 221.783 840 222.587 840 223.578C840 224.569 840.802 225.373 841.791 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 225.373C836.81 225.373 837.612 224.569 837.612 223.578C837.612 222.587 836.81 221.783 835.821 221.783C834.832 221.783 834.03 222.587 834.03 223.578C834.03 224.569 834.832 225.373 835.821 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 225.373C830.84 225.373 831.642 224.569 831.642 223.578C831.642 222.587 830.84 221.783 829.851 221.783C828.862 221.783 828.06 222.587 828.06 223.578C828.06 224.569 828.862 225.373 829.851 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 225.373C824.87 225.373 825.672 224.569 825.672 223.578C825.672 222.587 824.87 221.783 823.881 221.783C822.891 221.783 822.09 222.587 822.09 223.578C822.09 224.569 822.891 225.373 823.881 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 225.373C818.9 225.373 819.701 224.569 819.701 223.578C819.701 222.587 818.9 221.783 817.91 221.783C816.921 221.783 816.119 222.587 816.119 223.578C816.119 224.569 816.921 225.373 817.91 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 225.373C812.929 225.373 813.731 224.569 813.731 223.578C813.731 222.587 812.929 221.783 811.94 221.783C810.951 221.783 810.149 222.587 810.149 223.578C810.149 224.569 810.951 225.373 811.94 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 225.373C806.959 225.373 807.761 224.569 807.761 223.578C807.761 222.587 806.959 221.783 805.97 221.783C804.981 221.783 804.179 222.587 804.179 223.578C804.179 224.569 804.981 225.373 805.97 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M800 225.373C800.989 225.373 801.791 224.569 801.791 223.578C801.791 222.587 800.989 221.783 800 221.783C799.011 221.783 798.209 222.587 798.209 223.578C798.209 224.569 799.011 225.373 800 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 225.373C795.019 225.373 795.821 224.569 795.821 223.578C795.821 222.587 795.019 221.783 794.03 221.783C793.041 221.783 792.239 222.587 792.239 223.578C792.239 224.569 793.041 225.373 794.03 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 225.373C789.049 225.373 789.851 224.569 789.851 223.578C789.851 222.587 789.049 221.783 788.06 221.783C787.071 221.783 786.269 222.587 786.269 223.578C786.269 224.569 787.071 225.373 788.06 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 225.373C783.079 225.373 783.881 224.569 783.881 223.578C783.881 222.587 783.079 221.783 782.09 221.783C781.1 221.783 780.299 222.587 780.299 223.578C780.299 224.569 781.1 225.373 782.09 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 225.373C777.109 225.373 777.91 224.569 777.91 223.578C777.91 222.587 777.109 221.783 776.119 221.783C775.13 221.783 774.328 222.587 774.328 223.578C774.328 224.569 775.13 225.373 776.119 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 225.373C771.138 225.373 771.94 224.569 771.94 223.578C771.94 222.587 771.138 221.783 770.149 221.783C769.16 221.783 768.358 222.587 768.358 223.578C768.358 224.569 769.16 225.373 770.149 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 225.373C765.168 225.373 765.97 224.569 765.97 223.578C765.97 222.587 765.168 221.783 764.179 221.783C763.19 221.783 762.388 222.587 762.388 223.578C762.388 224.569 763.19 225.373 764.179 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 225.373C759.198 225.373 760 224.569 760 223.578C760 222.587 759.198 221.783 758.209 221.783C757.22 221.783 756.418 222.587 756.418 223.578C756.418 224.569 757.22 225.373 758.209 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 225.373C753.228 225.373 754.03 224.569 754.03 223.578C754.03 222.587 753.228 221.783 752.239 221.783C751.25 221.783 750.448 222.587 750.448 223.578C750.448 224.569 751.25 225.373 752.239 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 225.373C747.258 225.373 748.06 224.569 748.06 223.578C748.06 222.587 747.258 221.783 746.269 221.783C745.279 221.783 744.478 222.587 744.478 223.578C744.478 224.569 745.279 225.373 746.269 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 225.373C741.288 225.373 742.09 224.569 742.09 223.578C742.09 222.587 741.288 221.783 740.299 221.783C739.309 221.783 738.507 222.587 738.507 223.578C738.507 224.569 739.309 225.373 740.299 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 225.373C735.318 225.373 736.119 224.569 736.119 223.578C736.119 222.587 735.318 221.783 734.328 221.783C733.339 221.783 732.537 222.587 732.537 223.578C732.537 224.569 733.339 225.373 734.328 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 225.373C717.407 225.373 718.209 224.569 718.209 223.578C718.209 222.587 717.407 221.783 716.418 221.783C715.429 221.783 714.627 222.587 714.627 223.578C714.627 224.569 715.429 225.373 716.418 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 225.373C711.437 225.373 712.239 224.569 712.239 223.578C712.239 222.587 711.437 221.783 710.448 221.783C709.459 221.783 708.657 222.587 708.657 223.578C708.657 224.569 709.459 225.373 710.448 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 225.373C705.467 225.373 706.269 224.569 706.269 223.578C706.269 222.587 705.467 221.783 704.478 221.783C703.488 221.783 702.687 222.587 702.687 223.578C702.687 224.569 703.488 225.373 704.478 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 225.373C699.497 225.373 700.299 224.569 700.299 223.578C700.299 222.587 699.497 221.783 698.507 221.783C697.518 221.783 696.716 222.587 696.716 223.578C696.716 224.569 697.518 225.373 698.507 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 225.373C693.526 225.373 694.328 224.569 694.328 223.578C694.328 222.587 693.526 221.783 692.537 221.783C691.548 221.783 690.746 222.587 690.746 223.578C690.746 224.569 691.548 225.373 692.537 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 225.373C687.556 225.373 688.358 224.569 688.358 223.578C688.358 222.587 687.556 221.783 686.567 221.783C685.578 221.783 684.776 222.587 684.776 223.578C684.776 224.569 685.578 225.373 686.567 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 225.373C681.586 225.373 682.388 224.569 682.388 223.578C682.388 222.587 681.586 221.783 680.597 221.783C679.608 221.783 678.806 222.587 678.806 223.578C678.806 224.569 679.608 225.373 680.597 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 225.373C675.616 225.373 676.418 224.569 676.418 223.578C676.418 222.587 675.616 221.783 674.627 221.783C673.638 221.783 672.836 222.587 672.836 223.578C672.836 224.569 673.638 225.373 674.627 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 225.373C669.646 225.373 670.448 224.569 670.448 223.578C670.448 222.587 669.646 221.783 668.657 221.783C667.668 221.783 666.866 222.587 666.866 223.578C666.866 224.569 667.668 225.373 668.657 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 225.373C657.706 225.373 658.507 224.569 658.507 223.578C658.507 222.587 657.706 221.783 656.716 221.783C655.727 221.783 654.925 222.587 654.925 223.578C654.925 224.569 655.727 225.373 656.716 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 225.373C651.735 225.373 652.537 224.569 652.537 223.578C652.537 222.587 651.735 221.783 650.746 221.783C649.757 221.783 648.955 222.587 648.955 223.578C648.955 224.569 649.757 225.373 650.746 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 225.373C645.765 225.373 646.567 224.569 646.567 223.578C646.567 222.587 645.765 221.783 644.776 221.783C643.787 221.783 642.985 222.587 642.985 223.578C642.985 224.569 643.787 225.373 644.776 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 225.373C639.795 225.373 640.597 224.569 640.597 223.578C640.597 222.587 639.795 221.783 638.806 221.783C637.817 221.783 637.015 222.587 637.015 223.578C637.015 224.569 637.817 225.373 638.806 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 225.373C633.825 225.373 634.627 224.569 634.627 223.578C634.627 222.587 633.825 221.783 632.836 221.783C631.847 221.783 631.045 222.587 631.045 223.578C631.045 224.569 631.847 225.373 632.836 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 225.373C627.855 225.373 628.657 224.569 628.657 223.578C628.657 222.587 627.855 221.783 626.866 221.783C625.876 221.783 625.075 222.587 625.075 223.578C625.075 224.569 625.876 225.373 626.866 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 225.373C621.885 225.373 622.687 224.569 622.687 223.578C622.687 222.587 621.885 221.783 620.895 221.783C619.906 221.783 619.104 222.587 619.104 223.578C619.104 224.569 619.906 225.373 620.895 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 225.373C615.915 225.373 616.716 224.569 616.716 223.578C616.716 222.587 615.915 221.783 614.925 221.783C613.936 221.783 613.134 222.587 613.134 223.578C613.134 224.569 613.936 225.373 614.925 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 225.373C609.944 225.373 610.746 224.569 610.746 223.578C610.746 222.587 609.944 221.783 608.955 221.783C607.966 221.783 607.164 222.587 607.164 223.578C607.164 224.569 607.966 225.373 608.955 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 225.373C603.974 225.373 604.776 224.569 604.776 223.578C604.776 222.587 603.974 221.783 602.985 221.783C601.996 221.783 601.194 222.587 601.194 223.578C601.194 224.569 601.996 225.373 602.985 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 225.373C598.004 225.373 598.806 224.569 598.806 223.578C598.806 222.587 598.004 221.783 597.015 221.783C596.026 221.783 595.224 222.587 595.224 223.578C595.224 224.569 596.026 225.373 597.015 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 225.373C592.034 225.373 592.836 224.569 592.836 223.578C592.836 222.587 592.034 221.783 591.045 221.783C590.056 221.783 589.254 222.587 589.254 223.578C589.254 224.569 590.056 225.373 591.045 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 225.373C586.064 225.373 586.866 224.569 586.866 223.578C586.866 222.587 586.064 221.783 585.075 221.783C584.085 221.783 583.284 222.587 583.284 223.578C583.284 224.569 584.085 225.373 585.075 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 225.373C580.094 225.373 580.896 224.569 580.896 223.578C580.896 222.587 580.094 221.783 579.104 221.783C578.115 221.783 577.313 222.587 577.313 223.578C577.313 224.569 578.115 225.373 579.104 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 225.373C574.123 225.373 574.925 224.569 574.925 223.578C574.925 222.587 574.123 221.783 573.134 221.783C572.145 221.783 571.343 222.587 571.343 223.578C571.343 224.569 572.145 225.373 573.134 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 225.373C568.153 225.373 568.955 224.569 568.955 223.578C568.955 222.587 568.153 221.783 567.164 221.783C566.175 221.783 565.373 222.587 565.373 223.578C565.373 224.569 566.175 225.373 567.164 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 225.373C562.183 225.373 562.985 224.569 562.985 223.578C562.985 222.587 562.183 221.783 561.194 221.783C560.205 221.783 559.403 222.587 559.403 223.578C559.403 224.569 560.205 225.373 561.194 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 225.373C556.213 225.373 557.015 224.569 557.015 223.578C557.015 222.587 556.213 221.783 555.224 221.783C554.235 221.783 553.433 222.587 553.433 223.578C553.433 224.569 554.235 225.373 555.224 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 225.373C550.243 225.373 551.045 224.569 551.045 223.578C551.045 222.587 550.243 221.783 549.254 221.783C548.265 221.783 547.463 222.587 547.463 223.578C547.463 224.569 548.265 225.373 549.254 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 225.373C544.273 225.373 545.075 224.569 545.075 223.578C545.075 222.587 544.273 221.783 543.284 221.783C542.294 221.783 541.492 222.587 541.492 223.578C541.492 224.569 542.294 225.373 543.284 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 225.373C538.303 225.373 539.104 224.569 539.104 223.578C539.104 222.587 538.303 221.783 537.313 221.783C536.324 221.783 535.522 222.587 535.522 223.578C535.522 224.569 536.324 225.373 537.313 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 225.373C532.332 225.373 533.134 224.569 533.134 223.578C533.134 222.587 532.332 221.783 531.343 221.783C530.354 221.783 529.552 222.587 529.552 223.578C529.552 224.569 530.354 225.373 531.343 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 225.373C526.362 225.373 527.164 224.569 527.164 223.578C527.164 222.587 526.362 221.783 525.373 221.783C524.384 221.783 523.582 222.587 523.582 223.578C523.582 224.569 524.384 225.373 525.373 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 225.373C520.392 225.373 521.194 224.569 521.194 223.578C521.194 222.587 520.392 221.783 519.403 221.783C518.414 221.783 517.612 222.587 517.612 223.578C517.612 224.569 518.414 225.373 519.403 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 225.373C514.422 225.373 515.224 224.569 515.224 223.578C515.224 222.587 514.422 221.783 513.433 221.783C512.444 221.783 511.642 222.587 511.642 223.578C511.642 224.569 512.444 225.373 513.433 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 225.373C508.452 225.373 509.254 224.569 509.254 223.578C509.254 222.587 508.452 221.783 507.463 221.783C506.474 221.783 505.672 222.587 505.672 223.578C505.672 224.569 506.474 225.373 507.463 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 225.373C502.482 225.373 503.284 224.569 503.284 223.578C503.284 222.587 502.482 221.783 501.493 221.783C500.503 221.783 499.702 222.587 499.702 223.578C499.702 224.569 500.503 225.373 501.493 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 225.373C496.512 225.373 497.313 224.569 497.313 223.578C497.313 222.587 496.512 221.783 495.522 221.783C494.533 221.783 493.731 222.587 493.731 223.578C493.731 224.569 494.533 225.373 495.522 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 225.373C490.541 225.373 491.343 224.569 491.343 223.578C491.343 222.587 490.541 221.783 489.552 221.783C488.563 221.783 487.761 222.587 487.761 223.578C487.761 224.569 488.563 225.373 489.552 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 225.373C484.571 225.373 485.373 224.569 485.373 223.578C485.373 222.587 484.571 221.783 483.582 221.783C482.593 221.783 481.791 222.587 481.791 223.578C481.791 224.569 482.593 225.373 483.582 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 219.39C992.034 219.39 992.836 218.587 992.836 217.595C992.836 216.604 992.034 215.801 991.045 215.801C990.056 215.801 989.254 216.604 989.254 217.595C989.254 218.587 990.056 219.39 991.045 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 219.39C986.064 219.39 986.866 218.587 986.866 217.595C986.866 216.604 986.064 215.801 985.075 215.801C984.085 215.801 983.284 216.604 983.284 217.595C983.284 218.587 984.085 219.39 985.075 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 219.39C980.094 219.39 980.896 218.587 980.896 217.595C980.896 216.604 980.094 215.801 979.104 215.801C978.115 215.801 977.313 216.604 977.313 217.595C977.313 218.587 978.115 219.39 979.104 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 219.39C974.123 219.39 974.925 218.587 974.925 217.595C974.925 216.604 974.123 215.801 973.134 215.801C972.145 215.801 971.343 216.604 971.343 217.595C971.343 218.587 972.145 219.39 973.134 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 219.39C968.153 219.39 968.955 218.587 968.955 217.595C968.955 216.604 968.153 215.801 967.164 215.801C966.175 215.801 965.373 216.604 965.373 217.595C965.373 218.587 966.175 219.39 967.164 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 219.39C962.183 219.39 962.985 218.587 962.985 217.595C962.985 216.604 962.183 215.801 961.194 215.801C960.205 215.801 959.403 216.604 959.403 217.595C959.403 218.587 960.205 219.39 961.194 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 219.39C956.213 219.39 957.015 218.587 957.015 217.595C957.015 216.604 956.213 215.801 955.224 215.801C954.235 215.801 953.433 216.604 953.433 217.595C953.433 218.587 954.235 219.39 955.224 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 219.39C950.243 219.39 951.045 218.587 951.045 217.595C951.045 216.604 950.243 215.801 949.254 215.801C948.265 215.801 947.463 216.604 947.463 217.595C947.463 218.587 948.265 219.39 949.254 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 219.39C944.273 219.39 945.075 218.587 945.075 217.595C945.075 216.604 944.273 215.801 943.284 215.801C942.294 215.801 941.492 216.604 941.492 217.595C941.492 218.587 942.294 219.39 943.284 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 219.39C938.303 219.39 939.105 218.587 939.105 217.595C939.105 216.604 938.303 215.801 937.313 215.801C936.324 215.801 935.522 216.604 935.522 217.595C935.522 218.587 936.324 219.39 937.313 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 219.39C932.332 219.39 933.134 218.587 933.134 217.595C933.134 216.604 932.332 215.801 931.343 215.801C930.354 215.801 929.552 216.604 929.552 217.595C929.552 218.587 930.354 219.39 931.343 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 219.39C926.362 219.39 927.164 218.587 927.164 217.595C927.164 216.604 926.362 215.801 925.373 215.801C924.384 215.801 923.582 216.604 923.582 217.595C923.582 218.587 924.384 219.39 925.373 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 219.39C920.392 219.39 921.194 218.587 921.194 217.595C921.194 216.604 920.392 215.801 919.403 215.801C918.414 215.801 917.612 216.604 917.612 217.595C917.612 218.587 918.414 219.39 919.403 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 219.39C914.422 219.39 915.224 218.587 915.224 217.595C915.224 216.604 914.422 215.801 913.433 215.801C912.444 215.801 911.642 216.604 911.642 217.595C911.642 218.587 912.444 219.39 913.433 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 219.39C908.452 219.39 909.254 218.587 909.254 217.595C909.254 216.604 908.452 215.801 907.463 215.801C906.474 215.801 905.672 216.604 905.672 217.595C905.672 218.587 906.474 219.39 907.463 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 219.39C902.482 219.39 903.284 218.587 903.284 217.595C903.284 216.604 902.482 215.801 901.492 215.801C900.503 215.801 899.701 216.604 899.701 217.595C899.701 218.587 900.503 219.39 901.492 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 219.39C896.512 219.39 897.313 218.587 897.313 217.595C897.313 216.604 896.512 215.801 895.522 215.801C894.533 215.801 893.731 216.604 893.731 217.595C893.731 218.587 894.533 219.39 895.522 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 219.39C890.541 219.39 891.343 218.587 891.343 217.595C891.343 216.604 890.541 215.801 889.552 215.801C888.563 215.801 887.761 216.604 887.761 217.595C887.761 218.587 888.563 219.39 889.552 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 219.39C884.571 219.39 885.373 218.587 885.373 217.595C885.373 216.604 884.571 215.801 883.582 215.801C882.593 215.801 881.791 216.604 881.791 217.595C881.791 218.587 882.593 219.39 883.582 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 219.39C878.601 219.39 879.403 218.587 879.403 217.595C879.403 216.604 878.601 215.801 877.612 215.801C876.623 215.801 875.821 216.604 875.821 217.595C875.821 218.587 876.623 219.39 877.612 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 219.39C872.631 219.39 873.433 218.587 873.433 217.595C873.433 216.604 872.631 215.801 871.642 215.801C870.653 215.801 869.851 216.604 869.851 217.595C869.851 218.587 870.653 219.39 871.642 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 219.39C866.661 219.39 867.463 218.587 867.463 217.595C867.463 216.604 866.661 215.801 865.672 215.801C864.683 215.801 863.881 216.604 863.881 217.595C863.881 218.587 864.683 219.39 865.672 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 219.39C860.691 219.39 861.493 218.587 861.493 217.595C861.493 216.604 860.691 215.801 859.701 215.801C858.712 215.801 857.91 216.604 857.91 217.595C857.91 218.587 858.712 219.39 859.701 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 219.39C854.721 219.39 855.522 218.587 855.522 217.595C855.522 216.604 854.721 215.801 853.731 215.801C852.742 215.801 851.94 216.604 851.94 217.595C851.94 218.587 852.742 219.39 853.731 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 219.39C848.75 219.39 849.552 218.587 849.552 217.595C849.552 216.604 848.75 215.801 847.761 215.801C846.772 215.801 845.97 216.604 845.97 217.595C845.97 218.587 846.772 219.39 847.761 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 219.39C842.78 219.39 843.582 218.587 843.582 217.595C843.582 216.604 842.78 215.801 841.791 215.801C840.802 215.801 840 216.604 840 217.595C840 218.587 840.802 219.39 841.791 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 219.39C836.81 219.39 837.612 218.587 837.612 217.595C837.612 216.604 836.81 215.801 835.821 215.801C834.832 215.801 834.03 216.604 834.03 217.595C834.03 218.587 834.832 219.39 835.821 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 219.39C824.87 219.39 825.672 218.587 825.672 217.595C825.672 216.604 824.87 215.801 823.881 215.801C822.891 215.801 822.09 216.604 822.09 217.595C822.09 218.587 822.891 219.39 823.881 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 219.39C818.9 219.39 819.701 218.587 819.701 217.595C819.701 216.604 818.9 215.801 817.91 215.801C816.921 215.801 816.119 216.604 816.119 217.595C816.119 218.587 816.921 219.39 817.91 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 219.39C812.929 219.39 813.731 218.587 813.731 217.595C813.731 216.604 812.929 215.801 811.94 215.801C810.951 215.801 810.149 216.604 810.149 217.595C810.149 218.587 810.951 219.39 811.94 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 219.39C806.959 219.39 807.761 218.587 807.761 217.595C807.761 216.604 806.959 215.801 805.97 215.801C804.981 215.801 804.179 216.604 804.179 217.595C804.179 218.587 804.981 219.39 805.97 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M800 219.39C800.989 219.39 801.791 218.587 801.791 217.595C801.791 216.604 800.989 215.801 800 215.801C799.011 215.801 798.209 216.604 798.209 217.595C798.209 218.587 799.011 219.39 800 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 219.39C795.019 219.39 795.821 218.587 795.821 217.595C795.821 216.604 795.019 215.801 794.03 215.801C793.041 215.801 792.239 216.604 792.239 217.595C792.239 218.587 793.041 219.39 794.03 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 219.39C789.049 219.39 789.851 218.587 789.851 217.595C789.851 216.604 789.049 215.801 788.06 215.801C787.071 215.801 786.269 216.604 786.269 217.595C786.269 218.587 787.071 219.39 788.06 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 219.39C783.079 219.39 783.881 218.587 783.881 217.595C783.881 216.604 783.079 215.801 782.09 215.801C781.1 215.801 780.299 216.604 780.299 217.595C780.299 218.587 781.1 219.39 782.09 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 219.39C777.109 219.39 777.91 218.587 777.91 217.595C777.91 216.604 777.109 215.801 776.119 215.801C775.13 215.801 774.328 216.604 774.328 217.595C774.328 218.587 775.13 219.39 776.119 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 219.39C771.138 219.39 771.94 218.587 771.94 217.595C771.94 216.604 771.138 215.801 770.149 215.801C769.16 215.801 768.358 216.604 768.358 217.595C768.358 218.587 769.16 219.39 770.149 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 219.39C765.168 219.39 765.97 218.587 765.97 217.595C765.97 216.604 765.168 215.801 764.179 215.801C763.19 215.801 762.388 216.604 762.388 217.595C762.388 218.587 763.19 219.39 764.179 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 219.39C759.198 219.39 760 218.587 760 217.595C760 216.604 759.198 215.801 758.209 215.801C757.22 215.801 756.418 216.604 756.418 217.595C756.418 218.587 757.22 219.39 758.209 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 219.39C753.228 219.39 754.03 218.587 754.03 217.595C754.03 216.604 753.228 215.801 752.239 215.801C751.25 215.801 750.448 216.604 750.448 217.595C750.448 218.587 751.25 219.39 752.239 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 219.39C747.258 219.39 748.06 218.587 748.06 217.595C748.06 216.604 747.258 215.801 746.269 215.801C745.279 215.801 744.478 216.604 744.478 217.595C744.478 218.587 745.279 219.39 746.269 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 219.39C741.288 219.39 742.09 218.587 742.09 217.595C742.09 216.604 741.288 215.801 740.299 215.801C739.309 215.801 738.507 216.604 738.507 217.595C738.507 218.587 739.309 219.39 740.299 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 219.39C735.318 219.39 736.119 218.587 736.119 217.595C736.119 216.604 735.318 215.801 734.328 215.801C733.339 215.801 732.537 216.604 732.537 217.595C732.537 218.587 733.339 219.39 734.328 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 219.39C729.347 219.39 730.149 218.587 730.149 217.595C730.149 216.604 729.347 215.801 728.358 215.801C727.369 215.801 726.567 216.604 726.567 217.595C726.567 218.587 727.369 219.39 728.358 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 219.39C717.407 219.39 718.209 218.587 718.209 217.595C718.209 216.604 717.407 215.801 716.418 215.801C715.429 215.801 714.627 216.604 714.627 217.595C714.627 218.587 715.429 219.39 716.418 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 219.39C711.437 219.39 712.239 218.587 712.239 217.595C712.239 216.604 711.437 215.801 710.448 215.801C709.459 215.801 708.657 216.604 708.657 217.595C708.657 218.587 709.459 219.39 710.448 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 219.39C705.467 219.39 706.269 218.587 706.269 217.595C706.269 216.604 705.467 215.801 704.478 215.801C703.488 215.801 702.687 216.604 702.687 217.595C702.687 218.587 703.488 219.39 704.478 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 219.39C699.497 219.39 700.299 218.587 700.299 217.595C700.299 216.604 699.497 215.801 698.507 215.801C697.518 215.801 696.716 216.604 696.716 217.595C696.716 218.587 697.518 219.39 698.507 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 219.39C693.526 219.39 694.328 218.587 694.328 217.595C694.328 216.604 693.526 215.801 692.537 215.801C691.548 215.801 690.746 216.604 690.746 217.595C690.746 218.587 691.548 219.39 692.537 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 219.39C687.556 219.39 688.358 218.587 688.358 217.595C688.358 216.604 687.556 215.801 686.567 215.801C685.578 215.801 684.776 216.604 684.776 217.595C684.776 218.587 685.578 219.39 686.567 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 219.39C681.586 219.39 682.388 218.587 682.388 217.595C682.388 216.604 681.586 215.801 680.597 215.801C679.608 215.801 678.806 216.604 678.806 217.595C678.806 218.587 679.608 219.39 680.597 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 219.39C675.616 219.39 676.418 218.587 676.418 217.595C676.418 216.604 675.616 215.801 674.627 215.801C673.638 215.801 672.836 216.604 672.836 217.595C672.836 218.587 673.638 219.39 674.627 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 219.39C669.646 219.39 670.448 218.587 670.448 217.595C670.448 216.604 669.646 215.801 668.657 215.801C667.668 215.801 666.866 216.604 666.866 217.595C666.866 218.587 667.668 219.39 668.657 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 219.39C663.676 219.39 664.478 218.587 664.478 217.595C664.478 216.604 663.676 215.801 662.687 215.801C661.697 215.801 660.896 216.604 660.896 217.595C660.896 218.587 661.697 219.39 662.687 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 219.39C657.706 219.39 658.507 218.587 658.507 217.595C658.507 216.604 657.706 215.801 656.716 215.801C655.727 215.801 654.925 216.604 654.925 217.595C654.925 218.587 655.727 219.39 656.716 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 219.39C651.735 219.39 652.537 218.587 652.537 217.595C652.537 216.604 651.735 215.801 650.746 215.801C649.757 215.801 648.955 216.604 648.955 217.595C648.955 218.587 649.757 219.39 650.746 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 219.39C645.765 219.39 646.567 218.587 646.567 217.595C646.567 216.604 645.765 215.801 644.776 215.801C643.787 215.801 642.985 216.604 642.985 217.595C642.985 218.587 643.787 219.39 644.776 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 219.39C639.795 219.39 640.597 218.587 640.597 217.595C640.597 216.604 639.795 215.801 638.806 215.801C637.817 215.801 637.015 216.604 637.015 217.595C637.015 218.587 637.817 219.39 638.806 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 219.39C633.825 219.39 634.627 218.587 634.627 217.595C634.627 216.604 633.825 215.801 632.836 215.801C631.847 215.801 631.045 216.604 631.045 217.595C631.045 218.587 631.847 219.39 632.836 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 219.39C627.855 219.39 628.657 218.587 628.657 217.595C628.657 216.604 627.855 215.801 626.866 215.801C625.876 215.801 625.075 216.604 625.075 217.595C625.075 218.587 625.876 219.39 626.866 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 219.39C621.885 219.39 622.687 218.587 622.687 217.595C622.687 216.604 621.885 215.801 620.895 215.801C619.906 215.801 619.104 216.604 619.104 217.595C619.104 218.587 619.906 219.39 620.895 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 219.39C615.915 219.39 616.716 218.587 616.716 217.595C616.716 216.604 615.915 215.801 614.925 215.801C613.936 215.801 613.134 216.604 613.134 217.595C613.134 218.587 613.936 219.39 614.925 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 219.39C609.944 219.39 610.746 218.587 610.746 217.595C610.746 216.604 609.944 215.801 608.955 215.801C607.966 215.801 607.164 216.604 607.164 217.595C607.164 218.587 607.966 219.39 608.955 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 219.39C603.974 219.39 604.776 218.587 604.776 217.595C604.776 216.604 603.974 215.801 602.985 215.801C601.996 215.801 601.194 216.604 601.194 217.595C601.194 218.587 601.996 219.39 602.985 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 219.39C598.004 219.39 598.806 218.587 598.806 217.595C598.806 216.604 598.004 215.801 597.015 215.801C596.026 215.801 595.224 216.604 595.224 217.595C595.224 218.587 596.026 219.39 597.015 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 219.39C592.034 219.39 592.836 218.587 592.836 217.595C592.836 216.604 592.034 215.801 591.045 215.801C590.056 215.801 589.254 216.604 589.254 217.595C589.254 218.587 590.056 219.39 591.045 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 219.39C586.064 219.39 586.866 218.587 586.866 217.595C586.866 216.604 586.064 215.801 585.075 215.801C584.085 215.801 583.284 216.604 583.284 217.595C583.284 218.587 584.085 219.39 585.075 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 219.39C580.094 219.39 580.896 218.587 580.896 217.595C580.896 216.604 580.094 215.801 579.104 215.801C578.115 215.801 577.313 216.604 577.313 217.595C577.313 218.587 578.115 219.39 579.104 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 219.39C574.123 219.39 574.925 218.587 574.925 217.595C574.925 216.604 574.123 215.801 573.134 215.801C572.145 215.801 571.343 216.604 571.343 217.595C571.343 218.587 572.145 219.39 573.134 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 219.39C568.153 219.39 568.955 218.587 568.955 217.595C568.955 216.604 568.153 215.801 567.164 215.801C566.175 215.801 565.373 216.604 565.373 217.595C565.373 218.587 566.175 219.39 567.164 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 219.39C562.183 219.39 562.985 218.587 562.985 217.595C562.985 216.604 562.183 215.801 561.194 215.801C560.205 215.801 559.403 216.604 559.403 217.595C559.403 218.587 560.205 219.39 561.194 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 219.39C556.213 219.39 557.015 218.587 557.015 217.595C557.015 216.604 556.213 215.801 555.224 215.801C554.235 215.801 553.433 216.604 553.433 217.595C553.433 218.587 554.235 219.39 555.224 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 219.39C550.243 219.39 551.045 218.587 551.045 217.595C551.045 216.604 550.243 215.801 549.254 215.801C548.265 215.801 547.463 216.604 547.463 217.595C547.463 218.587 548.265 219.39 549.254 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 219.39C544.273 219.39 545.075 218.587 545.075 217.595C545.075 216.604 544.273 215.801 543.284 215.801C542.294 215.801 541.492 216.604 541.492 217.595C541.492 218.587 542.294 219.39 543.284 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 219.39C538.303 219.39 539.104 218.587 539.104 217.595C539.104 216.604 538.303 215.801 537.313 215.801C536.324 215.801 535.522 216.604 535.522 217.595C535.522 218.587 536.324 219.39 537.313 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 219.39C532.332 219.39 533.134 218.587 533.134 217.595C533.134 216.604 532.332 215.801 531.343 215.801C530.354 215.801 529.552 216.604 529.552 217.595C529.552 218.587 530.354 219.39 531.343 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 219.39C526.362 219.39 527.164 218.587 527.164 217.595C527.164 216.604 526.362 215.801 525.373 215.801C524.384 215.801 523.582 216.604 523.582 217.595C523.582 218.587 524.384 219.39 525.373 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 219.39C520.392 219.39 521.194 218.587 521.194 217.595C521.194 216.604 520.392 215.801 519.403 215.801C518.414 215.801 517.612 216.604 517.612 217.595C517.612 218.587 518.414 219.39 519.403 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 219.39C514.422 219.39 515.224 218.587 515.224 217.595C515.224 216.604 514.422 215.801 513.433 215.801C512.444 215.801 511.642 216.604 511.642 217.595C511.642 218.587 512.444 219.39 513.433 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 219.39C508.452 219.39 509.254 218.587 509.254 217.595C509.254 216.604 508.452 215.801 507.463 215.801C506.474 215.801 505.672 216.604 505.672 217.595C505.672 218.587 506.474 219.39 507.463 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 219.39C502.482 219.39 503.284 218.587 503.284 217.595C503.284 216.604 502.482 215.801 501.493 215.801C500.503 215.801 499.702 216.604 499.702 217.595C499.702 218.587 500.503 219.39 501.493 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 219.39C496.512 219.39 497.313 218.587 497.313 217.595C497.313 216.604 496.512 215.801 495.522 215.801C494.533 215.801 493.731 216.604 493.731 217.595C493.731 218.587 494.533 219.39 495.522 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 213.408C986.064 213.408 986.866 212.604 986.866 211.613C986.866 210.622 986.064 209.818 985.075 209.818C984.085 209.818 983.284 210.622 983.284 211.613C983.284 212.604 984.085 213.408 985.075 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 213.408C980.094 213.408 980.896 212.604 980.896 211.613C980.896 210.622 980.094 209.818 979.104 209.818C978.115 209.818 977.313 210.622 977.313 211.613C977.313 212.604 978.115 213.408 979.104 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 213.408C974.123 213.408 974.925 212.604 974.925 211.613C974.925 210.622 974.123 209.818 973.134 209.818C972.145 209.818 971.343 210.622 971.343 211.613C971.343 212.604 972.145 213.408 973.134 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 213.408C968.153 213.408 968.955 212.604 968.955 211.613C968.955 210.622 968.153 209.818 967.164 209.818C966.175 209.818 965.373 210.622 965.373 211.613C965.373 212.604 966.175 213.408 967.164 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 213.408C962.183 213.408 962.985 212.604 962.985 211.613C962.985 210.622 962.183 209.818 961.194 209.818C960.205 209.818 959.403 210.622 959.403 211.613C959.403 212.604 960.205 213.408 961.194 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 213.408C956.213 213.408 957.015 212.604 957.015 211.613C957.015 210.622 956.213 209.818 955.224 209.818C954.235 209.818 953.433 210.622 953.433 211.613C953.433 212.604 954.235 213.408 955.224 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 213.408C950.243 213.408 951.045 212.604 951.045 211.613C951.045 210.622 950.243 209.818 949.254 209.818C948.265 209.818 947.463 210.622 947.463 211.613C947.463 212.604 948.265 213.408 949.254 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 213.408C944.273 213.408 945.075 212.604 945.075 211.613C945.075 210.622 944.273 209.818 943.284 209.818C942.294 209.818 941.492 210.622 941.492 211.613C941.492 212.604 942.294 213.408 943.284 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 213.408C938.303 213.408 939.105 212.604 939.105 211.613C939.105 210.622 938.303 209.818 937.313 209.818C936.324 209.818 935.522 210.622 935.522 211.613C935.522 212.604 936.324 213.408 937.313 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 213.408C932.332 213.408 933.134 212.604 933.134 211.613C933.134 210.622 932.332 209.818 931.343 209.818C930.354 209.818 929.552 210.622 929.552 211.613C929.552 212.604 930.354 213.408 931.343 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 213.408C926.362 213.408 927.164 212.604 927.164 211.613C927.164 210.622 926.362 209.818 925.373 209.818C924.384 209.818 923.582 210.622 923.582 211.613C923.582 212.604 924.384 213.408 925.373 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 213.408C920.392 213.408 921.194 212.604 921.194 211.613C921.194 210.622 920.392 209.818 919.403 209.818C918.414 209.818 917.612 210.622 917.612 211.613C917.612 212.604 918.414 213.408 919.403 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 213.408C914.422 213.408 915.224 212.604 915.224 211.613C915.224 210.622 914.422 209.818 913.433 209.818C912.444 209.818 911.642 210.622 911.642 211.613C911.642 212.604 912.444 213.408 913.433 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 213.408C908.452 213.408 909.254 212.604 909.254 211.613C909.254 210.622 908.452 209.818 907.463 209.818C906.474 209.818 905.672 210.622 905.672 211.613C905.672 212.604 906.474 213.408 907.463 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 213.408C902.482 213.408 903.284 212.604 903.284 211.613C903.284 210.622 902.482 209.818 901.492 209.818C900.503 209.818 899.701 210.622 899.701 211.613C899.701 212.604 900.503 213.408 901.492 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 213.408C896.512 213.408 897.313 212.604 897.313 211.613C897.313 210.622 896.512 209.818 895.522 209.818C894.533 209.818 893.731 210.622 893.731 211.613C893.731 212.604 894.533 213.408 895.522 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 213.408C890.541 213.408 891.343 212.604 891.343 211.613C891.343 210.622 890.541 209.818 889.552 209.818C888.563 209.818 887.761 210.622 887.761 211.613C887.761 212.604 888.563 213.408 889.552 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 213.408C884.571 213.408 885.373 212.604 885.373 211.613C885.373 210.622 884.571 209.818 883.582 209.818C882.593 209.818 881.791 210.622 881.791 211.613C881.791 212.604 882.593 213.408 883.582 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 213.408C878.601 213.408 879.403 212.604 879.403 211.613C879.403 210.622 878.601 209.818 877.612 209.818C876.623 209.818 875.821 210.622 875.821 211.613C875.821 212.604 876.623 213.408 877.612 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 213.408C872.631 213.408 873.433 212.604 873.433 211.613C873.433 210.622 872.631 209.818 871.642 209.818C870.653 209.818 869.851 210.622 869.851 211.613C869.851 212.604 870.653 213.408 871.642 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 213.408C866.661 213.408 867.463 212.604 867.463 211.613C867.463 210.622 866.661 209.818 865.672 209.818C864.683 209.818 863.881 210.622 863.881 211.613C863.881 212.604 864.683 213.408 865.672 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 213.408C860.691 213.408 861.493 212.604 861.493 211.613C861.493 210.622 860.691 209.818 859.701 209.818C858.712 209.818 857.91 210.622 857.91 211.613C857.91 212.604 858.712 213.408 859.701 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 213.408C854.721 213.408 855.522 212.604 855.522 211.613C855.522 210.622 854.721 209.818 853.731 209.818C852.742 209.818 851.94 210.622 851.94 211.613C851.94 212.604 852.742 213.408 853.731 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 213.408C848.75 213.408 849.552 212.604 849.552 211.613C849.552 210.622 848.75 209.818 847.761 209.818C846.772 209.818 845.97 210.622 845.97 211.613C845.97 212.604 846.772 213.408 847.761 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 213.408C842.78 213.408 843.582 212.604 843.582 211.613C843.582 210.622 842.78 209.818 841.791 209.818C840.802 209.818 840 210.622 840 211.613C840 212.604 840.802 213.408 841.791 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 213.408C836.81 213.408 837.612 212.604 837.612 211.613C837.612 210.622 836.81 209.818 835.821 209.818C834.832 209.818 834.03 210.622 834.03 211.613C834.03 212.604 834.832 213.408 835.821 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 213.408C830.84 213.408 831.642 212.604 831.642 211.613C831.642 210.622 830.84 209.818 829.851 209.818C828.862 209.818 828.06 210.622 828.06 211.613C828.06 212.604 828.862 213.408 829.851 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 213.408C824.87 213.408 825.672 212.604 825.672 211.613C825.672 210.622 824.87 209.818 823.881 209.818C822.891 209.818 822.09 210.622 822.09 211.613C822.09 212.604 822.891 213.408 823.881 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 213.408C818.9 213.408 819.701 212.604 819.701 211.613C819.701 210.622 818.9 209.818 817.91 209.818C816.921 209.818 816.119 210.622 816.119 211.613C816.119 212.604 816.921 213.408 817.91 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 213.408C812.929 213.408 813.731 212.604 813.731 211.613C813.731 210.622 812.929 209.818 811.94 209.818C810.951 209.818 810.149 210.622 810.149 211.613C810.149 212.604 810.951 213.408 811.94 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 213.408C806.959 213.408 807.761 212.604 807.761 211.613C807.761 210.622 806.959 209.818 805.97 209.818C804.981 209.818 804.179 210.622 804.179 211.613C804.179 212.604 804.981 213.408 805.97 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M800 213.408C800.989 213.408 801.791 212.604 801.791 211.613C801.791 210.622 800.989 209.818 800 209.818C799.011 209.818 798.209 210.622 798.209 211.613C798.209 212.604 799.011 213.408 800 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 213.408C795.019 213.408 795.821 212.604 795.821 211.613C795.821 210.622 795.019 209.818 794.03 209.818C793.041 209.818 792.239 210.622 792.239 211.613C792.239 212.604 793.041 213.408 794.03 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 213.408C789.049 213.408 789.851 212.604 789.851 211.613C789.851 210.622 789.049 209.818 788.06 209.818C787.071 209.818 786.269 210.622 786.269 211.613C786.269 212.604 787.071 213.408 788.06 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 213.408C783.079 213.408 783.881 212.604 783.881 211.613C783.881 210.622 783.079 209.818 782.09 209.818C781.1 209.818 780.299 210.622 780.299 211.613C780.299 212.604 781.1 213.408 782.09 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 213.408C777.109 213.408 777.91 212.604 777.91 211.613C777.91 210.622 777.109 209.818 776.119 209.818C775.13 209.818 774.328 210.622 774.328 211.613C774.328 212.604 775.13 213.408 776.119 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 213.408C771.138 213.408 771.94 212.604 771.94 211.613C771.94 210.622 771.138 209.818 770.149 209.818C769.16 209.818 768.358 210.622 768.358 211.613C768.358 212.604 769.16 213.408 770.149 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 213.408C765.168 213.408 765.97 212.604 765.97 211.613C765.97 210.622 765.168 209.818 764.179 209.818C763.19 209.818 762.388 210.622 762.388 211.613C762.388 212.604 763.19 213.408 764.179 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 213.408C759.198 213.408 760 212.604 760 211.613C760 210.622 759.198 209.818 758.209 209.818C757.22 209.818 756.418 210.622 756.418 211.613C756.418 212.604 757.22 213.408 758.209 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 213.408C753.228 213.408 754.03 212.604 754.03 211.613C754.03 210.622 753.228 209.818 752.239 209.818C751.25 209.818 750.448 210.622 750.448 211.613C750.448 212.604 751.25 213.408 752.239 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 213.408C747.258 213.408 748.06 212.604 748.06 211.613C748.06 210.622 747.258 209.818 746.269 209.818C745.279 209.818 744.478 210.622 744.478 211.613C744.478 212.604 745.279 213.408 746.269 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 213.408C741.288 213.408 742.09 212.604 742.09 211.613C742.09 210.622 741.288 209.818 740.299 209.818C739.309 209.818 738.507 210.622 738.507 211.613C738.507 212.604 739.309 213.408 740.299 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 213.408C735.318 213.408 736.119 212.604 736.119 211.613C736.119 210.622 735.318 209.818 734.328 209.818C733.339 209.818 732.537 210.622 732.537 211.613C732.537 212.604 733.339 213.408 734.328 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 213.408C729.347 213.408 730.149 212.604 730.149 211.613C730.149 210.622 729.347 209.818 728.358 209.818C727.369 209.818 726.567 210.622 726.567 211.613C726.567 212.604 727.369 213.408 728.358 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 213.408C723.377 213.408 724.179 212.604 724.179 211.613C724.179 210.622 723.377 209.818 722.388 209.818C721.399 209.818 720.597 210.622 720.597 211.613C720.597 212.604 721.399 213.408 722.388 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 213.408C717.407 213.408 718.209 212.604 718.209 211.613C718.209 210.622 717.407 209.818 716.418 209.818C715.429 209.818 714.627 210.622 714.627 211.613C714.627 212.604 715.429 213.408 716.418 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 213.408C711.437 213.408 712.239 212.604 712.239 211.613C712.239 210.622 711.437 209.818 710.448 209.818C709.459 209.818 708.657 210.622 708.657 211.613C708.657 212.604 709.459 213.408 710.448 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 213.408C705.467 213.408 706.269 212.604 706.269 211.613C706.269 210.622 705.467 209.818 704.478 209.818C703.488 209.818 702.687 210.622 702.687 211.613C702.687 212.604 703.488 213.408 704.478 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 213.408C699.497 213.408 700.299 212.604 700.299 211.613C700.299 210.622 699.497 209.818 698.507 209.818C697.518 209.818 696.716 210.622 696.716 211.613C696.716 212.604 697.518 213.408 698.507 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 213.408C693.526 213.408 694.328 212.604 694.328 211.613C694.328 210.622 693.526 209.818 692.537 209.818C691.548 209.818 690.746 210.622 690.746 211.613C690.746 212.604 691.548 213.408 692.537 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 213.408C687.556 213.408 688.358 212.604 688.358 211.613C688.358 210.622 687.556 209.818 686.567 209.818C685.578 209.818 684.776 210.622 684.776 211.613C684.776 212.604 685.578 213.408 686.567 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 213.408C681.586 213.408 682.388 212.604 682.388 211.613C682.388 210.622 681.586 209.818 680.597 209.818C679.608 209.818 678.806 210.622 678.806 211.613C678.806 212.604 679.608 213.408 680.597 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 213.408C675.616 213.408 676.418 212.604 676.418 211.613C676.418 210.622 675.616 209.818 674.627 209.818C673.638 209.818 672.836 210.622 672.836 211.613C672.836 212.604 673.638 213.408 674.627 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 213.408C669.646 213.408 670.448 212.604 670.448 211.613C670.448 210.622 669.646 209.818 668.657 209.818C667.668 209.818 666.866 210.622 666.866 211.613C666.866 212.604 667.668 213.408 668.657 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 213.408C663.676 213.408 664.478 212.604 664.478 211.613C664.478 210.622 663.676 209.818 662.687 209.818C661.697 209.818 660.896 210.622 660.896 211.613C660.896 212.604 661.697 213.408 662.687 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 213.408C657.706 213.408 658.507 212.604 658.507 211.613C658.507 210.622 657.706 209.818 656.716 209.818C655.727 209.818 654.925 210.622 654.925 211.613C654.925 212.604 655.727 213.408 656.716 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 213.408C651.735 213.408 652.537 212.604 652.537 211.613C652.537 210.622 651.735 209.818 650.746 209.818C649.757 209.818 648.955 210.622 648.955 211.613C648.955 212.604 649.757 213.408 650.746 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 213.408C645.765 213.408 646.567 212.604 646.567 211.613C646.567 210.622 645.765 209.818 644.776 209.818C643.787 209.818 642.985 210.622 642.985 211.613C642.985 212.604 643.787 213.408 644.776 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 213.408C639.795 213.408 640.597 212.604 640.597 211.613C640.597 210.622 639.795 209.818 638.806 209.818C637.817 209.818 637.015 210.622 637.015 211.613C637.015 212.604 637.817 213.408 638.806 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 213.408C633.825 213.408 634.627 212.604 634.627 211.613C634.627 210.622 633.825 209.818 632.836 209.818C631.847 209.818 631.045 210.622 631.045 211.613C631.045 212.604 631.847 213.408 632.836 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 213.408C627.855 213.408 628.657 212.604 628.657 211.613C628.657 210.622 627.855 209.818 626.866 209.818C625.876 209.818 625.075 210.622 625.075 211.613C625.075 212.604 625.876 213.408 626.866 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 213.408C621.885 213.408 622.687 212.604 622.687 211.613C622.687 210.622 621.885 209.818 620.895 209.818C619.906 209.818 619.104 210.622 619.104 211.613C619.104 212.604 619.906 213.408 620.895 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 213.408C615.915 213.408 616.716 212.604 616.716 211.613C616.716 210.622 615.915 209.818 614.925 209.818C613.936 209.818 613.134 210.622 613.134 211.613C613.134 212.604 613.936 213.408 614.925 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 213.408C609.944 213.408 610.746 212.604 610.746 211.613C610.746 210.622 609.944 209.818 608.955 209.818C607.966 209.818 607.164 210.622 607.164 211.613C607.164 212.604 607.966 213.408 608.955 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 213.408C603.974 213.408 604.776 212.604 604.776 211.613C604.776 210.622 603.974 209.818 602.985 209.818C601.996 209.818 601.194 210.622 601.194 211.613C601.194 212.604 601.996 213.408 602.985 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 213.408C598.004 213.408 598.806 212.604 598.806 211.613C598.806 210.622 598.004 209.818 597.015 209.818C596.026 209.818 595.224 210.622 595.224 211.613C595.224 212.604 596.026 213.408 597.015 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 213.408C592.034 213.408 592.836 212.604 592.836 211.613C592.836 210.622 592.034 209.818 591.045 209.818C590.056 209.818 589.254 210.622 589.254 211.613C589.254 212.604 590.056 213.408 591.045 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 213.408C586.064 213.408 586.866 212.604 586.866 211.613C586.866 210.622 586.064 209.818 585.075 209.818C584.085 209.818 583.284 210.622 583.284 211.613C583.284 212.604 584.085 213.408 585.075 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 213.408C580.094 213.408 580.896 212.604 580.896 211.613C580.896 210.622 580.094 209.818 579.104 209.818C578.115 209.818 577.313 210.622 577.313 211.613C577.313 212.604 578.115 213.408 579.104 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 213.408C574.123 213.408 574.925 212.604 574.925 211.613C574.925 210.622 574.123 209.818 573.134 209.818C572.145 209.818 571.343 210.622 571.343 211.613C571.343 212.604 572.145 213.408 573.134 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 213.408C568.153 213.408 568.955 212.604 568.955 211.613C568.955 210.622 568.153 209.818 567.164 209.818C566.175 209.818 565.373 210.622 565.373 211.613C565.373 212.604 566.175 213.408 567.164 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 213.408C562.183 213.408 562.985 212.604 562.985 211.613C562.985 210.622 562.183 209.818 561.194 209.818C560.205 209.818 559.403 210.622 559.403 211.613C559.403 212.604 560.205 213.408 561.194 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 213.408C556.213 213.408 557.015 212.604 557.015 211.613C557.015 210.622 556.213 209.818 555.224 209.818C554.235 209.818 553.433 210.622 553.433 211.613C553.433 212.604 554.235 213.408 555.224 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 213.408C550.243 213.408 551.045 212.604 551.045 211.613C551.045 210.622 550.243 209.818 549.254 209.818C548.265 209.818 547.463 210.622 547.463 211.613C547.463 212.604 548.265 213.408 549.254 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 213.408C544.273 213.408 545.075 212.604 545.075 211.613C545.075 210.622 544.273 209.818 543.284 209.818C542.294 209.818 541.492 210.622 541.492 211.613C541.492 212.604 542.294 213.408 543.284 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 213.408C538.303 213.408 539.104 212.604 539.104 211.613C539.104 210.622 538.303 209.818 537.313 209.818C536.324 209.818 535.522 210.622 535.522 211.613C535.522 212.604 536.324 213.408 537.313 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 213.408C532.332 213.408 533.134 212.604 533.134 211.613C533.134 210.622 532.332 209.818 531.343 209.818C530.354 209.818 529.552 210.622 529.552 211.613C529.552 212.604 530.354 213.408 531.343 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 213.408C526.362 213.408 527.164 212.604 527.164 211.613C527.164 210.622 526.362 209.818 525.373 209.818C524.384 209.818 523.582 210.622 523.582 211.613C523.582 212.604 524.384 213.408 525.373 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 213.408C520.392 213.408 521.194 212.604 521.194 211.613C521.194 210.622 520.392 209.818 519.403 209.818C518.414 209.818 517.612 210.622 517.612 211.613C517.612 212.604 518.414 213.408 519.403 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 213.408C514.422 213.408 515.224 212.604 515.224 211.613C515.224 210.622 514.422 209.818 513.433 209.818C512.444 209.818 511.642 210.622 511.642 211.613C511.642 212.604 512.444 213.408 513.433 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 213.408C508.452 213.408 509.254 212.604 509.254 211.613C509.254 210.622 508.452 209.818 507.463 209.818C506.474 209.818 505.672 210.622 505.672 211.613C505.672 212.604 506.474 213.408 507.463 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 213.408C502.482 213.408 503.284 212.604 503.284 211.613C503.284 210.622 502.482 209.818 501.493 209.818C500.503 209.818 499.702 210.622 499.702 211.613C499.702 212.604 500.503 213.408 501.493 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 213.408C496.512 213.408 497.313 212.604 497.313 211.613C497.313 210.622 496.512 209.818 495.522 209.818C494.533 209.818 493.731 210.622 493.731 211.613C493.731 212.604 494.533 213.408 495.522 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 207.426C1027.85 207.426 1028.66 206.622 1028.66 205.631C1028.66 204.64 1027.85 203.836 1026.87 203.836C1025.88 203.836 1025.07 204.64 1025.07 205.631C1025.07 206.622 1025.88 207.426 1026.87 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 207.426C986.064 207.426 986.866 206.622 986.866 205.631C986.866 204.64 986.064 203.836 985.075 203.836C984.085 203.836 983.284 204.64 983.284 205.631C983.284 206.622 984.085 207.426 985.075 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 207.426C980.094 207.426 980.896 206.622 980.896 205.631C980.896 204.64 980.094 203.836 979.104 203.836C978.115 203.836 977.313 204.64 977.313 205.631C977.313 206.622 978.115 207.426 979.104 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 207.426C974.123 207.426 974.925 206.622 974.925 205.631C974.925 204.64 974.123 203.836 973.134 203.836C972.145 203.836 971.343 204.64 971.343 205.631C971.343 206.622 972.145 207.426 973.134 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 207.426C968.153 207.426 968.955 206.622 968.955 205.631C968.955 204.64 968.153 203.836 967.164 203.836C966.175 203.836 965.373 204.64 965.373 205.631C965.373 206.622 966.175 207.426 967.164 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 207.426C962.183 207.426 962.985 206.622 962.985 205.631C962.985 204.64 962.183 203.836 961.194 203.836C960.205 203.836 959.403 204.64 959.403 205.631C959.403 206.622 960.205 207.426 961.194 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 207.426C956.213 207.426 957.015 206.622 957.015 205.631C957.015 204.64 956.213 203.836 955.224 203.836C954.235 203.836 953.433 204.64 953.433 205.631C953.433 206.622 954.235 207.426 955.224 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 207.426C950.243 207.426 951.045 206.622 951.045 205.631C951.045 204.64 950.243 203.836 949.254 203.836C948.265 203.836 947.463 204.64 947.463 205.631C947.463 206.622 948.265 207.426 949.254 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 207.426C944.273 207.426 945.075 206.622 945.075 205.631C945.075 204.64 944.273 203.836 943.284 203.836C942.294 203.836 941.492 204.64 941.492 205.631C941.492 206.622 942.294 207.426 943.284 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 207.426C938.303 207.426 939.105 206.622 939.105 205.631C939.105 204.64 938.303 203.836 937.313 203.836C936.324 203.836 935.522 204.64 935.522 205.631C935.522 206.622 936.324 207.426 937.313 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 207.426C932.332 207.426 933.134 206.622 933.134 205.631C933.134 204.64 932.332 203.836 931.343 203.836C930.354 203.836 929.552 204.64 929.552 205.631C929.552 206.622 930.354 207.426 931.343 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 207.426C926.362 207.426 927.164 206.622 927.164 205.631C927.164 204.64 926.362 203.836 925.373 203.836C924.384 203.836 923.582 204.64 923.582 205.631C923.582 206.622 924.384 207.426 925.373 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 207.426C920.392 207.426 921.194 206.622 921.194 205.631C921.194 204.64 920.392 203.836 919.403 203.836C918.414 203.836 917.612 204.64 917.612 205.631C917.612 206.622 918.414 207.426 919.403 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 207.426C914.422 207.426 915.224 206.622 915.224 205.631C915.224 204.64 914.422 203.836 913.433 203.836C912.444 203.836 911.642 204.64 911.642 205.631C911.642 206.622 912.444 207.426 913.433 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 207.426C908.452 207.426 909.254 206.622 909.254 205.631C909.254 204.64 908.452 203.836 907.463 203.836C906.474 203.836 905.672 204.64 905.672 205.631C905.672 206.622 906.474 207.426 907.463 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 207.426C902.482 207.426 903.284 206.622 903.284 205.631C903.284 204.64 902.482 203.836 901.492 203.836C900.503 203.836 899.701 204.64 899.701 205.631C899.701 206.622 900.503 207.426 901.492 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 207.426C896.512 207.426 897.313 206.622 897.313 205.631C897.313 204.64 896.512 203.836 895.522 203.836C894.533 203.836 893.731 204.64 893.731 205.631C893.731 206.622 894.533 207.426 895.522 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 207.426C890.541 207.426 891.343 206.622 891.343 205.631C891.343 204.64 890.541 203.836 889.552 203.836C888.563 203.836 887.761 204.64 887.761 205.631C887.761 206.622 888.563 207.426 889.552 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 207.426C884.571 207.426 885.373 206.622 885.373 205.631C885.373 204.64 884.571 203.836 883.582 203.836C882.593 203.836 881.791 204.64 881.791 205.631C881.791 206.622 882.593 207.426 883.582 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 207.426C878.601 207.426 879.403 206.622 879.403 205.631C879.403 204.64 878.601 203.836 877.612 203.836C876.623 203.836 875.821 204.64 875.821 205.631C875.821 206.622 876.623 207.426 877.612 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 207.426C872.631 207.426 873.433 206.622 873.433 205.631C873.433 204.64 872.631 203.836 871.642 203.836C870.653 203.836 869.851 204.64 869.851 205.631C869.851 206.622 870.653 207.426 871.642 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 207.426C866.661 207.426 867.463 206.622 867.463 205.631C867.463 204.64 866.661 203.836 865.672 203.836C864.683 203.836 863.881 204.64 863.881 205.631C863.881 206.622 864.683 207.426 865.672 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 207.426C860.691 207.426 861.493 206.622 861.493 205.631C861.493 204.64 860.691 203.836 859.701 203.836C858.712 203.836 857.91 204.64 857.91 205.631C857.91 206.622 858.712 207.426 859.701 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 207.426C854.721 207.426 855.522 206.622 855.522 205.631C855.522 204.64 854.721 203.836 853.731 203.836C852.742 203.836 851.94 204.64 851.94 205.631C851.94 206.622 852.742 207.426 853.731 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 207.426C848.75 207.426 849.552 206.622 849.552 205.631C849.552 204.64 848.75 203.836 847.761 203.836C846.772 203.836 845.97 204.64 845.97 205.631C845.97 206.622 846.772 207.426 847.761 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 207.426C842.78 207.426 843.582 206.622 843.582 205.631C843.582 204.64 842.78 203.836 841.791 203.836C840.802 203.836 840 204.64 840 205.631C840 206.622 840.802 207.426 841.791 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 207.426C836.81 207.426 837.612 206.622 837.612 205.631C837.612 204.64 836.81 203.836 835.821 203.836C834.832 203.836 834.03 204.64 834.03 205.631C834.03 206.622 834.832 207.426 835.821 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 207.426C830.84 207.426 831.642 206.622 831.642 205.631C831.642 204.64 830.84 203.836 829.851 203.836C828.862 203.836 828.06 204.64 828.06 205.631C828.06 206.622 828.862 207.426 829.851 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 207.426C824.87 207.426 825.672 206.622 825.672 205.631C825.672 204.64 824.87 203.836 823.881 203.836C822.891 203.836 822.09 204.64 822.09 205.631C822.09 206.622 822.891 207.426 823.881 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 207.426C818.9 207.426 819.701 206.622 819.701 205.631C819.701 204.64 818.9 203.836 817.91 203.836C816.921 203.836 816.119 204.64 816.119 205.631C816.119 206.622 816.921 207.426 817.91 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 207.426C812.929 207.426 813.731 206.622 813.731 205.631C813.731 204.64 812.929 203.836 811.94 203.836C810.951 203.836 810.149 204.64 810.149 205.631C810.149 206.622 810.951 207.426 811.94 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 207.426C806.959 207.426 807.761 206.622 807.761 205.631C807.761 204.64 806.959 203.836 805.97 203.836C804.981 203.836 804.179 204.64 804.179 205.631C804.179 206.622 804.981 207.426 805.97 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M800 207.426C800.989 207.426 801.791 206.622 801.791 205.631C801.791 204.64 800.989 203.836 800 203.836C799.011 203.836 798.209 204.64 798.209 205.631C798.209 206.622 799.011 207.426 800 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 207.426C795.019 207.426 795.821 206.622 795.821 205.631C795.821 204.64 795.019 203.836 794.03 203.836C793.041 203.836 792.239 204.64 792.239 205.631C792.239 206.622 793.041 207.426 794.03 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 207.426C789.049 207.426 789.851 206.622 789.851 205.631C789.851 204.64 789.049 203.836 788.06 203.836C787.071 203.836 786.269 204.64 786.269 205.631C786.269 206.622 787.071 207.426 788.06 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 207.426C783.079 207.426 783.881 206.622 783.881 205.631C783.881 204.64 783.079 203.836 782.09 203.836C781.1 203.836 780.299 204.64 780.299 205.631C780.299 206.622 781.1 207.426 782.09 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 207.426C777.109 207.426 777.91 206.622 777.91 205.631C777.91 204.64 777.109 203.836 776.119 203.836C775.13 203.836 774.328 204.64 774.328 205.631C774.328 206.622 775.13 207.426 776.119 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 207.426C771.138 207.426 771.94 206.622 771.94 205.631C771.94 204.64 771.138 203.836 770.149 203.836C769.16 203.836 768.358 204.64 768.358 205.631C768.358 206.622 769.16 207.426 770.149 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 207.426C765.168 207.426 765.97 206.622 765.97 205.631C765.97 204.64 765.168 203.836 764.179 203.836C763.19 203.836 762.388 204.64 762.388 205.631C762.388 206.622 763.19 207.426 764.179 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 207.426C759.198 207.426 760 206.622 760 205.631C760 204.64 759.198 203.836 758.209 203.836C757.22 203.836 756.418 204.64 756.418 205.631C756.418 206.622 757.22 207.426 758.209 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 207.426C753.228 207.426 754.03 206.622 754.03 205.631C754.03 204.64 753.228 203.836 752.239 203.836C751.25 203.836 750.448 204.64 750.448 205.631C750.448 206.622 751.25 207.426 752.239 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 207.426C747.258 207.426 748.06 206.622 748.06 205.631C748.06 204.64 747.258 203.836 746.269 203.836C745.279 203.836 744.478 204.64 744.478 205.631C744.478 206.622 745.279 207.426 746.269 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 207.426C741.288 207.426 742.09 206.622 742.09 205.631C742.09 204.64 741.288 203.836 740.299 203.836C739.309 203.836 738.507 204.64 738.507 205.631C738.507 206.622 739.309 207.426 740.299 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 207.426C735.318 207.426 736.119 206.622 736.119 205.631C736.119 204.64 735.318 203.836 734.328 203.836C733.339 203.836 732.537 204.64 732.537 205.631C732.537 206.622 733.339 207.426 734.328 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 207.426C729.347 207.426 730.149 206.622 730.149 205.631C730.149 204.64 729.347 203.836 728.358 203.836C727.369 203.836 726.567 204.64 726.567 205.631C726.567 206.622 727.369 207.426 728.358 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 207.426C723.377 207.426 724.179 206.622 724.179 205.631C724.179 204.64 723.377 203.836 722.388 203.836C721.399 203.836 720.597 204.64 720.597 205.631C720.597 206.622 721.399 207.426 722.388 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 207.426C717.407 207.426 718.209 206.622 718.209 205.631C718.209 204.64 717.407 203.836 716.418 203.836C715.429 203.836 714.627 204.64 714.627 205.631C714.627 206.622 715.429 207.426 716.418 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 207.426C711.437 207.426 712.239 206.622 712.239 205.631C712.239 204.64 711.437 203.836 710.448 203.836C709.459 203.836 708.657 204.64 708.657 205.631C708.657 206.622 709.459 207.426 710.448 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 207.426C705.467 207.426 706.269 206.622 706.269 205.631C706.269 204.64 705.467 203.836 704.478 203.836C703.488 203.836 702.687 204.64 702.687 205.631C702.687 206.622 703.488 207.426 704.478 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 207.426C699.497 207.426 700.299 206.622 700.299 205.631C700.299 204.64 699.497 203.836 698.507 203.836C697.518 203.836 696.716 204.64 696.716 205.631C696.716 206.622 697.518 207.426 698.507 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 207.426C693.526 207.426 694.328 206.622 694.328 205.631C694.328 204.64 693.526 203.836 692.537 203.836C691.548 203.836 690.746 204.64 690.746 205.631C690.746 206.622 691.548 207.426 692.537 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 207.426C687.556 207.426 688.358 206.622 688.358 205.631C688.358 204.64 687.556 203.836 686.567 203.836C685.578 203.836 684.776 204.64 684.776 205.631C684.776 206.622 685.578 207.426 686.567 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 207.426C681.586 207.426 682.388 206.622 682.388 205.631C682.388 204.64 681.586 203.836 680.597 203.836C679.608 203.836 678.806 204.64 678.806 205.631C678.806 206.622 679.608 207.426 680.597 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 207.426C675.616 207.426 676.418 206.622 676.418 205.631C676.418 204.64 675.616 203.836 674.627 203.836C673.638 203.836 672.836 204.64 672.836 205.631C672.836 206.622 673.638 207.426 674.627 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 207.426C621.885 207.426 622.687 206.622 622.687 205.631C622.687 204.64 621.885 203.836 620.895 203.836C619.906 203.836 619.104 204.64 619.104 205.631C619.104 206.622 619.906 207.426 620.895 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 207.426C615.915 207.426 616.716 206.622 616.716 205.631C616.716 204.64 615.915 203.836 614.925 203.836C613.936 203.836 613.134 204.64 613.134 205.631C613.134 206.622 613.936 207.426 614.925 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 207.426C609.944 207.426 610.746 206.622 610.746 205.631C610.746 204.64 609.944 203.836 608.955 203.836C607.966 203.836 607.164 204.64 607.164 205.631C607.164 206.622 607.966 207.426 608.955 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 207.426C592.034 207.426 592.836 206.622 592.836 205.631C592.836 204.64 592.034 203.836 591.045 203.836C590.056 203.836 589.254 204.64 589.254 205.631C589.254 206.622 590.056 207.426 591.045 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 207.426C586.064 207.426 586.866 206.622 586.866 205.631C586.866 204.64 586.064 203.836 585.075 203.836C584.085 203.836 583.284 204.64 583.284 205.631C583.284 206.622 584.085 207.426 585.075 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 207.426C580.094 207.426 580.896 206.622 580.896 205.631C580.896 204.64 580.094 203.836 579.104 203.836C578.115 203.836 577.313 204.64 577.313 205.631C577.313 206.622 578.115 207.426 579.104 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 207.426C574.123 207.426 574.925 206.622 574.925 205.631C574.925 204.64 574.123 203.836 573.134 203.836C572.145 203.836 571.343 204.64 571.343 205.631C571.343 206.622 572.145 207.426 573.134 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 207.426C568.153 207.426 568.955 206.622 568.955 205.631C568.955 204.64 568.153 203.836 567.164 203.836C566.175 203.836 565.373 204.64 565.373 205.631C565.373 206.622 566.175 207.426 567.164 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 207.426C562.183 207.426 562.985 206.622 562.985 205.631C562.985 204.64 562.183 203.836 561.194 203.836C560.205 203.836 559.403 204.64 559.403 205.631C559.403 206.622 560.205 207.426 561.194 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 207.426C556.213 207.426 557.015 206.622 557.015 205.631C557.015 204.64 556.213 203.836 555.224 203.836C554.235 203.836 553.433 204.64 553.433 205.631C553.433 206.622 554.235 207.426 555.224 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 207.426C550.243 207.426 551.045 206.622 551.045 205.631C551.045 204.64 550.243 203.836 549.254 203.836C548.265 203.836 547.463 204.64 547.463 205.631C547.463 206.622 548.265 207.426 549.254 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 207.426C544.273 207.426 545.075 206.622 545.075 205.631C545.075 204.64 544.273 203.836 543.284 203.836C542.294 203.836 541.492 204.64 541.492 205.631C541.492 206.622 542.294 207.426 543.284 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 207.426C538.303 207.426 539.104 206.622 539.104 205.631C539.104 204.64 538.303 203.836 537.313 203.836C536.324 203.836 535.522 204.64 535.522 205.631C535.522 206.622 536.324 207.426 537.313 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 207.426C532.332 207.426 533.134 206.622 533.134 205.631C533.134 204.64 532.332 203.836 531.343 203.836C530.354 203.836 529.552 204.64 529.552 205.631C529.552 206.622 530.354 207.426 531.343 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 207.426C526.362 207.426 527.164 206.622 527.164 205.631C527.164 204.64 526.362 203.836 525.373 203.836C524.384 203.836 523.582 204.64 523.582 205.631C523.582 206.622 524.384 207.426 525.373 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 207.426C520.392 207.426 521.194 206.622 521.194 205.631C521.194 204.64 520.392 203.836 519.403 203.836C518.414 203.836 517.612 204.64 517.612 205.631C517.612 206.622 518.414 207.426 519.403 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 207.426C514.422 207.426 515.224 206.622 515.224 205.631C515.224 204.64 514.422 203.836 513.433 203.836C512.444 203.836 511.642 204.64 511.642 205.631C511.642 206.622 512.444 207.426 513.433 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 207.426C508.452 207.426 509.254 206.622 509.254 205.631C509.254 204.64 508.452 203.836 507.463 203.836C506.474 203.836 505.672 204.64 505.672 205.631C505.672 206.622 506.474 207.426 507.463 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 207.426C502.482 207.426 503.284 206.622 503.284 205.631C503.284 204.64 502.482 203.836 501.493 203.836C500.503 203.836 499.702 204.64 499.702 205.631C499.702 206.622 500.503 207.426 501.493 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 207.426C496.512 207.426 497.313 206.622 497.313 205.631C497.313 204.64 496.512 203.836 495.522 203.836C494.533 203.836 493.731 204.64 493.731 205.631C493.731 206.622 494.533 207.426 495.522 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 201.443C1033.82 201.443 1034.63 200.64 1034.63 199.649C1034.63 198.658 1033.82 197.854 1032.84 197.854C1031.85 197.854 1031.04 198.658 1031.04 199.649C1031.04 200.64 1031.85 201.443 1032.84 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 201.443C1027.85 201.443 1028.66 200.64 1028.66 199.649C1028.66 198.658 1027.85 197.854 1026.87 197.854C1025.88 197.854 1025.07 198.658 1025.07 199.649C1025.07 200.64 1025.88 201.443 1026.87 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 201.443C986.064 201.443 986.866 200.64 986.866 199.649C986.866 198.658 986.064 197.854 985.075 197.854C984.085 197.854 983.284 198.658 983.284 199.649C983.284 200.64 984.085 201.443 985.075 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 201.443C980.094 201.443 980.896 200.64 980.896 199.649C980.896 198.658 980.094 197.854 979.104 197.854C978.115 197.854 977.313 198.658 977.313 199.649C977.313 200.64 978.115 201.443 979.104 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 201.443C974.123 201.443 974.925 200.64 974.925 199.649C974.925 198.658 974.123 197.854 973.134 197.854C972.145 197.854 971.343 198.658 971.343 199.649C971.343 200.64 972.145 201.443 973.134 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 201.443C968.153 201.443 968.955 200.64 968.955 199.649C968.955 198.658 968.153 197.854 967.164 197.854C966.175 197.854 965.373 198.658 965.373 199.649C965.373 200.64 966.175 201.443 967.164 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 201.443C962.183 201.443 962.985 200.64 962.985 199.649C962.985 198.658 962.183 197.854 961.194 197.854C960.205 197.854 959.403 198.658 959.403 199.649C959.403 200.64 960.205 201.443 961.194 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 201.443C956.213 201.443 957.015 200.64 957.015 199.649C957.015 198.658 956.213 197.854 955.224 197.854C954.235 197.854 953.433 198.658 953.433 199.649C953.433 200.64 954.235 201.443 955.224 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 201.443C950.243 201.443 951.045 200.64 951.045 199.649C951.045 198.658 950.243 197.854 949.254 197.854C948.265 197.854 947.463 198.658 947.463 199.649C947.463 200.64 948.265 201.443 949.254 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 201.443C944.273 201.443 945.075 200.64 945.075 199.649C945.075 198.658 944.273 197.854 943.284 197.854C942.294 197.854 941.492 198.658 941.492 199.649C941.492 200.64 942.294 201.443 943.284 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 201.443C938.303 201.443 939.105 200.64 939.105 199.649C939.105 198.658 938.303 197.854 937.313 197.854C936.324 197.854 935.522 198.658 935.522 199.649C935.522 200.64 936.324 201.443 937.313 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 201.443C932.332 201.443 933.134 200.64 933.134 199.649C933.134 198.658 932.332 197.854 931.343 197.854C930.354 197.854 929.552 198.658 929.552 199.649C929.552 200.64 930.354 201.443 931.343 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 201.443C926.362 201.443 927.164 200.64 927.164 199.649C927.164 198.658 926.362 197.854 925.373 197.854C924.384 197.854 923.582 198.658 923.582 199.649C923.582 200.64 924.384 201.443 925.373 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 201.443C920.392 201.443 921.194 200.64 921.194 199.649C921.194 198.658 920.392 197.854 919.403 197.854C918.414 197.854 917.612 198.658 917.612 199.649C917.612 200.64 918.414 201.443 919.403 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 201.443C914.422 201.443 915.224 200.64 915.224 199.649C915.224 198.658 914.422 197.854 913.433 197.854C912.444 197.854 911.642 198.658 911.642 199.649C911.642 200.64 912.444 201.443 913.433 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 201.443C908.452 201.443 909.254 200.64 909.254 199.649C909.254 198.658 908.452 197.854 907.463 197.854C906.474 197.854 905.672 198.658 905.672 199.649C905.672 200.64 906.474 201.443 907.463 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 201.443C902.482 201.443 903.284 200.64 903.284 199.649C903.284 198.658 902.482 197.854 901.492 197.854C900.503 197.854 899.701 198.658 899.701 199.649C899.701 200.64 900.503 201.443 901.492 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 201.443C896.512 201.443 897.313 200.64 897.313 199.649C897.313 198.658 896.512 197.854 895.522 197.854C894.533 197.854 893.731 198.658 893.731 199.649C893.731 200.64 894.533 201.443 895.522 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 201.443C890.541 201.443 891.343 200.64 891.343 199.649C891.343 198.658 890.541 197.854 889.552 197.854C888.563 197.854 887.761 198.658 887.761 199.649C887.761 200.64 888.563 201.443 889.552 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 201.443C884.571 201.443 885.373 200.64 885.373 199.649C885.373 198.658 884.571 197.854 883.582 197.854C882.593 197.854 881.791 198.658 881.791 199.649C881.791 200.64 882.593 201.443 883.582 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 201.443C878.601 201.443 879.403 200.64 879.403 199.649C879.403 198.658 878.601 197.854 877.612 197.854C876.623 197.854 875.821 198.658 875.821 199.649C875.821 200.64 876.623 201.443 877.612 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 201.443C872.631 201.443 873.433 200.64 873.433 199.649C873.433 198.658 872.631 197.854 871.642 197.854C870.653 197.854 869.851 198.658 869.851 199.649C869.851 200.64 870.653 201.443 871.642 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 201.443C866.661 201.443 867.463 200.64 867.463 199.649C867.463 198.658 866.661 197.854 865.672 197.854C864.683 197.854 863.881 198.658 863.881 199.649C863.881 200.64 864.683 201.443 865.672 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 201.443C860.691 201.443 861.493 200.64 861.493 199.649C861.493 198.658 860.691 197.854 859.701 197.854C858.712 197.854 857.91 198.658 857.91 199.649C857.91 200.64 858.712 201.443 859.701 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 201.443C854.721 201.443 855.522 200.64 855.522 199.649C855.522 198.658 854.721 197.854 853.731 197.854C852.742 197.854 851.94 198.658 851.94 199.649C851.94 200.64 852.742 201.443 853.731 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 201.443C848.75 201.443 849.552 200.64 849.552 199.649C849.552 198.658 848.75 197.854 847.761 197.854C846.772 197.854 845.97 198.658 845.97 199.649C845.97 200.64 846.772 201.443 847.761 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 201.443C842.78 201.443 843.582 200.64 843.582 199.649C843.582 198.658 842.78 197.854 841.791 197.854C840.802 197.854 840 198.658 840 199.649C840 200.64 840.802 201.443 841.791 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 201.443C836.81 201.443 837.612 200.64 837.612 199.649C837.612 198.658 836.81 197.854 835.821 197.854C834.832 197.854 834.03 198.658 834.03 199.649C834.03 200.64 834.832 201.443 835.821 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 201.443C830.84 201.443 831.642 200.64 831.642 199.649C831.642 198.658 830.84 197.854 829.851 197.854C828.862 197.854 828.06 198.658 828.06 199.649C828.06 200.64 828.862 201.443 829.851 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 201.443C824.87 201.443 825.672 200.64 825.672 199.649C825.672 198.658 824.87 197.854 823.881 197.854C822.891 197.854 822.09 198.658 822.09 199.649C822.09 200.64 822.891 201.443 823.881 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 201.443C818.9 201.443 819.701 200.64 819.701 199.649C819.701 198.658 818.9 197.854 817.91 197.854C816.921 197.854 816.119 198.658 816.119 199.649C816.119 200.64 816.921 201.443 817.91 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 201.443C812.929 201.443 813.731 200.64 813.731 199.649C813.731 198.658 812.929 197.854 811.94 197.854C810.951 197.854 810.149 198.658 810.149 199.649C810.149 200.64 810.951 201.443 811.94 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 201.443C806.959 201.443 807.761 200.64 807.761 199.649C807.761 198.658 806.959 197.854 805.97 197.854C804.981 197.854 804.179 198.658 804.179 199.649C804.179 200.64 804.981 201.443 805.97 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M800 201.443C800.989 201.443 801.791 200.64 801.791 199.649C801.791 198.658 800.989 197.854 800 197.854C799.011 197.854 798.209 198.658 798.209 199.649C798.209 200.64 799.011 201.443 800 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 201.443C795.019 201.443 795.821 200.64 795.821 199.649C795.821 198.658 795.019 197.854 794.03 197.854C793.041 197.854 792.239 198.658 792.239 199.649C792.239 200.64 793.041 201.443 794.03 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 201.443C789.049 201.443 789.851 200.64 789.851 199.649C789.851 198.658 789.049 197.854 788.06 197.854C787.071 197.854 786.269 198.658 786.269 199.649C786.269 200.64 787.071 201.443 788.06 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 201.443C783.079 201.443 783.881 200.64 783.881 199.649C783.881 198.658 783.079 197.854 782.09 197.854C781.1 197.854 780.299 198.658 780.299 199.649C780.299 200.64 781.1 201.443 782.09 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 201.443C777.109 201.443 777.91 200.64 777.91 199.649C777.91 198.658 777.109 197.854 776.119 197.854C775.13 197.854 774.328 198.658 774.328 199.649C774.328 200.64 775.13 201.443 776.119 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 201.443C771.138 201.443 771.94 200.64 771.94 199.649C771.94 198.658 771.138 197.854 770.149 197.854C769.16 197.854 768.358 198.658 768.358 199.649C768.358 200.64 769.16 201.443 770.149 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 201.443C765.168 201.443 765.97 200.64 765.97 199.649C765.97 198.658 765.168 197.854 764.179 197.854C763.19 197.854 762.388 198.658 762.388 199.649C762.388 200.64 763.19 201.443 764.179 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 201.443C759.198 201.443 760 200.64 760 199.649C760 198.658 759.198 197.854 758.209 197.854C757.22 197.854 756.418 198.658 756.418 199.649C756.418 200.64 757.22 201.443 758.209 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 201.443C753.228 201.443 754.03 200.64 754.03 199.649C754.03 198.658 753.228 197.854 752.239 197.854C751.25 197.854 750.448 198.658 750.448 199.649C750.448 200.64 751.25 201.443 752.239 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 201.443C747.258 201.443 748.06 200.64 748.06 199.649C748.06 198.658 747.258 197.854 746.269 197.854C745.279 197.854 744.478 198.658 744.478 199.649C744.478 200.64 745.279 201.443 746.269 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 201.443C741.288 201.443 742.09 200.64 742.09 199.649C742.09 198.658 741.288 197.854 740.299 197.854C739.309 197.854 738.507 198.658 738.507 199.649C738.507 200.64 739.309 201.443 740.299 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 201.443C735.318 201.443 736.119 200.64 736.119 199.649C736.119 198.658 735.318 197.854 734.328 197.854C733.339 197.854 732.537 198.658 732.537 199.649C732.537 200.64 733.339 201.443 734.328 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 201.443C729.347 201.443 730.149 200.64 730.149 199.649C730.149 198.658 729.347 197.854 728.358 197.854C727.369 197.854 726.567 198.658 726.567 199.649C726.567 200.64 727.369 201.443 728.358 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 201.443C723.377 201.443 724.179 200.64 724.179 199.649C724.179 198.658 723.377 197.854 722.388 197.854C721.399 197.854 720.597 198.658 720.597 199.649C720.597 200.64 721.399 201.443 722.388 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 201.443C717.407 201.443 718.209 200.64 718.209 199.649C718.209 198.658 717.407 197.854 716.418 197.854C715.429 197.854 714.627 198.658 714.627 199.649C714.627 200.64 715.429 201.443 716.418 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 201.443C711.437 201.443 712.239 200.64 712.239 199.649C712.239 198.658 711.437 197.854 710.448 197.854C709.459 197.854 708.657 198.658 708.657 199.649C708.657 200.64 709.459 201.443 710.448 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 201.443C705.467 201.443 706.269 200.64 706.269 199.649C706.269 198.658 705.467 197.854 704.478 197.854C703.488 197.854 702.687 198.658 702.687 199.649C702.687 200.64 703.488 201.443 704.478 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 201.443C699.497 201.443 700.299 200.64 700.299 199.649C700.299 198.658 699.497 197.854 698.507 197.854C697.518 197.854 696.716 198.658 696.716 199.649C696.716 200.64 697.518 201.443 698.507 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 201.443C693.526 201.443 694.328 200.64 694.328 199.649C694.328 198.658 693.526 197.854 692.537 197.854C691.548 197.854 690.746 198.658 690.746 199.649C690.746 200.64 691.548 201.443 692.537 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 201.443C687.556 201.443 688.358 200.64 688.358 199.649C688.358 198.658 687.556 197.854 686.567 197.854C685.578 197.854 684.776 198.658 684.776 199.649C684.776 200.64 685.578 201.443 686.567 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 201.443C681.586 201.443 682.388 200.64 682.388 199.649C682.388 198.658 681.586 197.854 680.597 197.854C679.608 197.854 678.806 198.658 678.806 199.649C678.806 200.64 679.608 201.443 680.597 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 201.443C675.616 201.443 676.418 200.64 676.418 199.649C676.418 198.658 675.616 197.854 674.627 197.854C673.638 197.854 672.836 198.658 672.836 199.649C672.836 200.64 673.638 201.443 674.627 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 201.443C669.646 201.443 670.448 200.64 670.448 199.649C670.448 198.658 669.646 197.854 668.657 197.854C667.668 197.854 666.866 198.658 666.866 199.649C666.866 200.64 667.668 201.443 668.657 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 201.443C574.123 201.443 574.925 200.64 574.925 199.649C574.925 198.658 574.123 197.854 573.134 197.854C572.145 197.854 571.343 198.658 571.343 199.649C571.343 200.64 572.145 201.443 573.134 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 201.443C568.153 201.443 568.955 200.64 568.955 199.649C568.955 198.658 568.153 197.854 567.164 197.854C566.175 197.854 565.373 198.658 565.373 199.649C565.373 200.64 566.175 201.443 567.164 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 201.443C562.183 201.443 562.985 200.64 562.985 199.649C562.985 198.658 562.183 197.854 561.194 197.854C560.205 197.854 559.403 198.658 559.403 199.649C559.403 200.64 560.205 201.443 561.194 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 201.443C556.213 201.443 557.015 200.64 557.015 199.649C557.015 198.658 556.213 197.854 555.224 197.854C554.235 197.854 553.433 198.658 553.433 199.649C553.433 200.64 554.235 201.443 555.224 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 201.443C550.243 201.443 551.045 200.64 551.045 199.649C551.045 198.658 550.243 197.854 549.254 197.854C548.265 197.854 547.463 198.658 547.463 199.649C547.463 200.64 548.265 201.443 549.254 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 201.443C544.273 201.443 545.075 200.64 545.075 199.649C545.075 198.658 544.273 197.854 543.284 197.854C542.294 197.854 541.492 198.658 541.492 199.649C541.492 200.64 542.294 201.443 543.284 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 201.443C538.303 201.443 539.104 200.64 539.104 199.649C539.104 198.658 538.303 197.854 537.313 197.854C536.324 197.854 535.522 198.658 535.522 199.649C535.522 200.64 536.324 201.443 537.313 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 201.443C532.332 201.443 533.134 200.64 533.134 199.649C533.134 198.658 532.332 197.854 531.343 197.854C530.354 197.854 529.552 198.658 529.552 199.649C529.552 200.64 530.354 201.443 531.343 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 201.443C526.362 201.443 527.164 200.64 527.164 199.649C527.164 198.658 526.362 197.854 525.373 197.854C524.384 197.854 523.582 198.658 523.582 199.649C523.582 200.64 524.384 201.443 525.373 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 201.443C520.392 201.443 521.194 200.64 521.194 199.649C521.194 198.658 520.392 197.854 519.403 197.854C518.414 197.854 517.612 198.658 517.612 199.649C517.612 200.64 518.414 201.443 519.403 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 201.443C514.422 201.443 515.224 200.64 515.224 199.649C515.224 198.658 514.422 197.854 513.433 197.854C512.444 197.854 511.642 198.658 511.642 199.649C511.642 200.64 512.444 201.443 513.433 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 201.443C508.452 201.443 509.254 200.64 509.254 199.649C509.254 198.658 508.452 197.854 507.463 197.854C506.474 197.854 505.672 198.658 505.672 199.649C505.672 200.64 506.474 201.443 507.463 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 201.443C502.482 201.443 503.284 200.64 503.284 199.649C503.284 198.658 502.482 197.854 501.493 197.854C500.503 197.854 499.702 198.658 499.702 199.649C499.702 200.64 500.503 201.443 501.493 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 195.461C1051.74 195.461 1052.54 194.657 1052.54 193.666C1052.54 192.675 1051.74 191.872 1050.75 191.872C1049.76 191.872 1048.96 192.675 1048.96 193.666C1048.96 194.657 1049.76 195.461 1050.75 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 195.461C1033.82 195.461 1034.63 194.657 1034.63 193.666C1034.63 192.675 1033.82 191.872 1032.84 191.872C1031.85 191.872 1031.04 192.675 1031.04 193.666C1031.04 194.657 1031.85 195.461 1032.84 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 195.461C1027.85 195.461 1028.66 194.657 1028.66 193.666C1028.66 192.675 1027.85 191.872 1026.87 191.872C1025.88 191.872 1025.07 192.675 1025.07 193.666C1025.07 194.657 1025.88 195.461 1026.87 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 195.461C1009.94 195.461 1010.75 194.657 1010.75 193.666C1010.75 192.675 1009.94 191.872 1008.96 191.872C1007.97 191.872 1007.16 192.675 1007.16 193.666C1007.16 194.657 1007.97 195.461 1008.96 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 195.461C980.094 195.461 980.896 194.657 980.896 193.666C980.896 192.675 980.094 191.872 979.104 191.872C978.115 191.872 977.313 192.675 977.313 193.666C977.313 194.657 978.115 195.461 979.104 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 195.461C974.123 195.461 974.925 194.657 974.925 193.666C974.925 192.675 974.123 191.872 973.134 191.872C972.145 191.872 971.343 192.675 971.343 193.666C971.343 194.657 972.145 195.461 973.134 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 195.461C968.153 195.461 968.955 194.657 968.955 193.666C968.955 192.675 968.153 191.872 967.164 191.872C966.175 191.872 965.373 192.675 965.373 193.666C965.373 194.657 966.175 195.461 967.164 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 195.461C962.183 195.461 962.985 194.657 962.985 193.666C962.985 192.675 962.183 191.872 961.194 191.872C960.205 191.872 959.403 192.675 959.403 193.666C959.403 194.657 960.205 195.461 961.194 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 195.461C956.213 195.461 957.015 194.657 957.015 193.666C957.015 192.675 956.213 191.872 955.224 191.872C954.235 191.872 953.433 192.675 953.433 193.666C953.433 194.657 954.235 195.461 955.224 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 195.461C950.243 195.461 951.045 194.657 951.045 193.666C951.045 192.675 950.243 191.872 949.254 191.872C948.265 191.872 947.463 192.675 947.463 193.666C947.463 194.657 948.265 195.461 949.254 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 195.461C944.273 195.461 945.075 194.657 945.075 193.666C945.075 192.675 944.273 191.872 943.284 191.872C942.294 191.872 941.492 192.675 941.492 193.666C941.492 194.657 942.294 195.461 943.284 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 195.461C938.303 195.461 939.105 194.657 939.105 193.666C939.105 192.675 938.303 191.872 937.313 191.872C936.324 191.872 935.522 192.675 935.522 193.666C935.522 194.657 936.324 195.461 937.313 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 195.461C932.332 195.461 933.134 194.657 933.134 193.666C933.134 192.675 932.332 191.872 931.343 191.872C930.354 191.872 929.552 192.675 929.552 193.666C929.552 194.657 930.354 195.461 931.343 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 195.461C926.362 195.461 927.164 194.657 927.164 193.666C927.164 192.675 926.362 191.872 925.373 191.872C924.384 191.872 923.582 192.675 923.582 193.666C923.582 194.657 924.384 195.461 925.373 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 195.461C920.392 195.461 921.194 194.657 921.194 193.666C921.194 192.675 920.392 191.872 919.403 191.872C918.414 191.872 917.612 192.675 917.612 193.666C917.612 194.657 918.414 195.461 919.403 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 195.461C914.422 195.461 915.224 194.657 915.224 193.666C915.224 192.675 914.422 191.872 913.433 191.872C912.444 191.872 911.642 192.675 911.642 193.666C911.642 194.657 912.444 195.461 913.433 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 195.461C908.452 195.461 909.254 194.657 909.254 193.666C909.254 192.675 908.452 191.872 907.463 191.872C906.474 191.872 905.672 192.675 905.672 193.666C905.672 194.657 906.474 195.461 907.463 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 195.461C902.482 195.461 903.284 194.657 903.284 193.666C903.284 192.675 902.482 191.872 901.492 191.872C900.503 191.872 899.701 192.675 899.701 193.666C899.701 194.657 900.503 195.461 901.492 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 195.461C896.512 195.461 897.313 194.657 897.313 193.666C897.313 192.675 896.512 191.872 895.522 191.872C894.533 191.872 893.731 192.675 893.731 193.666C893.731 194.657 894.533 195.461 895.522 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 195.461C890.541 195.461 891.343 194.657 891.343 193.666C891.343 192.675 890.541 191.872 889.552 191.872C888.563 191.872 887.761 192.675 887.761 193.666C887.761 194.657 888.563 195.461 889.552 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 195.461C884.571 195.461 885.373 194.657 885.373 193.666C885.373 192.675 884.571 191.872 883.582 191.872C882.593 191.872 881.791 192.675 881.791 193.666C881.791 194.657 882.593 195.461 883.582 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 195.461C878.601 195.461 879.403 194.657 879.403 193.666C879.403 192.675 878.601 191.872 877.612 191.872C876.623 191.872 875.821 192.675 875.821 193.666C875.821 194.657 876.623 195.461 877.612 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 195.461C872.631 195.461 873.433 194.657 873.433 193.666C873.433 192.675 872.631 191.872 871.642 191.872C870.653 191.872 869.851 192.675 869.851 193.666C869.851 194.657 870.653 195.461 871.642 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 195.461C866.661 195.461 867.463 194.657 867.463 193.666C867.463 192.675 866.661 191.872 865.672 191.872C864.683 191.872 863.881 192.675 863.881 193.666C863.881 194.657 864.683 195.461 865.672 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 195.461C860.691 195.461 861.493 194.657 861.493 193.666C861.493 192.675 860.691 191.872 859.701 191.872C858.712 191.872 857.91 192.675 857.91 193.666C857.91 194.657 858.712 195.461 859.701 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 195.461C854.721 195.461 855.522 194.657 855.522 193.666C855.522 192.675 854.721 191.872 853.731 191.872C852.742 191.872 851.94 192.675 851.94 193.666C851.94 194.657 852.742 195.461 853.731 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 195.461C848.75 195.461 849.552 194.657 849.552 193.666C849.552 192.675 848.75 191.872 847.761 191.872C846.772 191.872 845.97 192.675 845.97 193.666C845.97 194.657 846.772 195.461 847.761 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 195.461C842.78 195.461 843.582 194.657 843.582 193.666C843.582 192.675 842.78 191.872 841.791 191.872C840.802 191.872 840 192.675 840 193.666C840 194.657 840.802 195.461 841.791 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 195.461C836.81 195.461 837.612 194.657 837.612 193.666C837.612 192.675 836.81 191.872 835.821 191.872C834.832 191.872 834.03 192.675 834.03 193.666C834.03 194.657 834.832 195.461 835.821 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 195.461C830.84 195.461 831.642 194.657 831.642 193.666C831.642 192.675 830.84 191.872 829.851 191.872C828.862 191.872 828.06 192.675 828.06 193.666C828.06 194.657 828.862 195.461 829.851 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 195.461C824.87 195.461 825.672 194.657 825.672 193.666C825.672 192.675 824.87 191.872 823.881 191.872C822.891 191.872 822.09 192.675 822.09 193.666C822.09 194.657 822.891 195.461 823.881 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 195.461C818.9 195.461 819.701 194.657 819.701 193.666C819.701 192.675 818.9 191.872 817.91 191.872C816.921 191.872 816.119 192.675 816.119 193.666C816.119 194.657 816.921 195.461 817.91 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 195.461C812.929 195.461 813.731 194.657 813.731 193.666C813.731 192.675 812.929 191.872 811.94 191.872C810.951 191.872 810.149 192.675 810.149 193.666C810.149 194.657 810.951 195.461 811.94 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 195.461C806.959 195.461 807.761 194.657 807.761 193.666C807.761 192.675 806.959 191.872 805.97 191.872C804.981 191.872 804.179 192.675 804.179 193.666C804.179 194.657 804.981 195.461 805.97 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M800 195.461C800.989 195.461 801.791 194.657 801.791 193.666C801.791 192.675 800.989 191.872 800 191.872C799.011 191.872 798.209 192.675 798.209 193.666C798.209 194.657 799.011 195.461 800 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 195.461C795.019 195.461 795.821 194.657 795.821 193.666C795.821 192.675 795.019 191.872 794.03 191.872C793.041 191.872 792.239 192.675 792.239 193.666C792.239 194.657 793.041 195.461 794.03 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 195.461C789.049 195.461 789.851 194.657 789.851 193.666C789.851 192.675 789.049 191.872 788.06 191.872C787.071 191.872 786.269 192.675 786.269 193.666C786.269 194.657 787.071 195.461 788.06 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 195.461C783.079 195.461 783.881 194.657 783.881 193.666C783.881 192.675 783.079 191.872 782.09 191.872C781.1 191.872 780.299 192.675 780.299 193.666C780.299 194.657 781.1 195.461 782.09 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 195.461C777.109 195.461 777.91 194.657 777.91 193.666C777.91 192.675 777.109 191.872 776.119 191.872C775.13 191.872 774.328 192.675 774.328 193.666C774.328 194.657 775.13 195.461 776.119 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 195.461C771.138 195.461 771.94 194.657 771.94 193.666C771.94 192.675 771.138 191.872 770.149 191.872C769.16 191.872 768.358 192.675 768.358 193.666C768.358 194.657 769.16 195.461 770.149 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 195.461C765.168 195.461 765.97 194.657 765.97 193.666C765.97 192.675 765.168 191.872 764.179 191.872C763.19 191.872 762.388 192.675 762.388 193.666C762.388 194.657 763.19 195.461 764.179 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 195.461C759.198 195.461 760 194.657 760 193.666C760 192.675 759.198 191.872 758.209 191.872C757.22 191.872 756.418 192.675 756.418 193.666C756.418 194.657 757.22 195.461 758.209 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 195.461C753.228 195.461 754.03 194.657 754.03 193.666C754.03 192.675 753.228 191.872 752.239 191.872C751.25 191.872 750.448 192.675 750.448 193.666C750.448 194.657 751.25 195.461 752.239 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 195.461C747.258 195.461 748.06 194.657 748.06 193.666C748.06 192.675 747.258 191.872 746.269 191.872C745.279 191.872 744.478 192.675 744.478 193.666C744.478 194.657 745.279 195.461 746.269 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 195.461C741.288 195.461 742.09 194.657 742.09 193.666C742.09 192.675 741.288 191.872 740.299 191.872C739.309 191.872 738.507 192.675 738.507 193.666C738.507 194.657 739.309 195.461 740.299 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 195.461C735.318 195.461 736.119 194.657 736.119 193.666C736.119 192.675 735.318 191.872 734.328 191.872C733.339 191.872 732.537 192.675 732.537 193.666C732.537 194.657 733.339 195.461 734.328 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 195.461C729.347 195.461 730.149 194.657 730.149 193.666C730.149 192.675 729.347 191.872 728.358 191.872C727.369 191.872 726.567 192.675 726.567 193.666C726.567 194.657 727.369 195.461 728.358 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 195.461C723.377 195.461 724.179 194.657 724.179 193.666C724.179 192.675 723.377 191.872 722.388 191.872C721.399 191.872 720.597 192.675 720.597 193.666C720.597 194.657 721.399 195.461 722.388 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 195.461C717.407 195.461 718.209 194.657 718.209 193.666C718.209 192.675 717.407 191.872 716.418 191.872C715.429 191.872 714.627 192.675 714.627 193.666C714.627 194.657 715.429 195.461 716.418 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 195.461C711.437 195.461 712.239 194.657 712.239 193.666C712.239 192.675 711.437 191.872 710.448 191.872C709.459 191.872 708.657 192.675 708.657 193.666C708.657 194.657 709.459 195.461 710.448 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 195.461C705.467 195.461 706.269 194.657 706.269 193.666C706.269 192.675 705.467 191.872 704.478 191.872C703.488 191.872 702.687 192.675 702.687 193.666C702.687 194.657 703.488 195.461 704.478 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 195.461C699.497 195.461 700.299 194.657 700.299 193.666C700.299 192.675 699.497 191.872 698.507 191.872C697.518 191.872 696.716 192.675 696.716 193.666C696.716 194.657 697.518 195.461 698.507 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 195.461C693.526 195.461 694.328 194.657 694.328 193.666C694.328 192.675 693.526 191.872 692.537 191.872C691.548 191.872 690.746 192.675 690.746 193.666C690.746 194.657 691.548 195.461 692.537 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 195.461C687.556 195.461 688.358 194.657 688.358 193.666C688.358 192.675 687.556 191.872 686.567 191.872C685.578 191.872 684.776 192.675 684.776 193.666C684.776 194.657 685.578 195.461 686.567 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 195.461C681.586 195.461 682.388 194.657 682.388 193.666C682.388 192.675 681.586 191.872 680.597 191.872C679.608 191.872 678.806 192.675 678.806 193.666C678.806 194.657 679.608 195.461 680.597 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 195.461C675.616 195.461 676.418 194.657 676.418 193.666C676.418 192.675 675.616 191.872 674.627 191.872C673.638 191.872 672.836 192.675 672.836 193.666C672.836 194.657 673.638 195.461 674.627 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 195.461C669.646 195.461 670.448 194.657 670.448 193.666C670.448 192.675 669.646 191.872 668.657 191.872C667.668 191.872 666.866 192.675 666.866 193.666C666.866 194.657 667.668 195.461 668.657 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 195.461C657.706 195.461 658.507 194.657 658.507 193.666C658.507 192.675 657.706 191.872 656.716 191.872C655.727 191.872 654.925 192.675 654.925 193.666C654.925 194.657 655.727 195.461 656.716 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 195.461C633.825 195.461 634.627 194.657 634.627 193.666C634.627 192.675 633.825 191.872 632.836 191.872C631.847 191.872 631.045 192.675 631.045 193.666C631.045 194.657 631.847 195.461 632.836 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 195.461C627.855 195.461 628.657 194.657 628.657 193.666C628.657 192.675 627.855 191.872 626.866 191.872C625.876 191.872 625.075 192.675 625.075 193.666C625.075 194.657 625.876 195.461 626.866 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 195.461C574.123 195.461 574.925 194.657 574.925 193.666C574.925 192.675 574.123 191.872 573.134 191.872C572.145 191.872 571.343 192.675 571.343 193.666C571.343 194.657 572.145 195.461 573.134 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 195.461C568.153 195.461 568.955 194.657 568.955 193.666C568.955 192.675 568.153 191.872 567.164 191.872C566.175 191.872 565.373 192.675 565.373 193.666C565.373 194.657 566.175 195.461 567.164 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 195.461C562.183 195.461 562.985 194.657 562.985 193.666C562.985 192.675 562.183 191.872 561.194 191.872C560.205 191.872 559.403 192.675 559.403 193.666C559.403 194.657 560.205 195.461 561.194 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 195.461C556.213 195.461 557.015 194.657 557.015 193.666C557.015 192.675 556.213 191.872 555.224 191.872C554.235 191.872 553.433 192.675 553.433 193.666C553.433 194.657 554.235 195.461 555.224 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 195.461C550.243 195.461 551.045 194.657 551.045 193.666C551.045 192.675 550.243 191.872 549.254 191.872C548.265 191.872 547.463 192.675 547.463 193.666C547.463 194.657 548.265 195.461 549.254 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 195.461C544.273 195.461 545.075 194.657 545.075 193.666C545.075 192.675 544.273 191.872 543.284 191.872C542.294 191.872 541.492 192.675 541.492 193.666C541.492 194.657 542.294 195.461 543.284 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 195.461C538.303 195.461 539.104 194.657 539.104 193.666C539.104 192.675 538.303 191.872 537.313 191.872C536.324 191.872 535.522 192.675 535.522 193.666C535.522 194.657 536.324 195.461 537.313 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 195.461C532.332 195.461 533.134 194.657 533.134 193.666C533.134 192.675 532.332 191.872 531.343 191.872C530.354 191.872 529.552 192.675 529.552 193.666C529.552 194.657 530.354 195.461 531.343 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 195.461C526.362 195.461 527.164 194.657 527.164 193.666C527.164 192.675 526.362 191.872 525.373 191.872C524.384 191.872 523.582 192.675 523.582 193.666C523.582 194.657 524.384 195.461 525.373 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 195.461C520.392 195.461 521.194 194.657 521.194 193.666C521.194 192.675 520.392 191.872 519.403 191.872C518.414 191.872 517.612 192.675 517.612 193.666C517.612 194.657 518.414 195.461 519.403 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 195.461C514.422 195.461 515.224 194.657 515.224 193.666C515.224 192.675 514.422 191.872 513.433 191.872C512.444 191.872 511.642 192.675 511.642 193.666C511.642 194.657 512.444 195.461 513.433 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 189.479C1051.74 189.479 1052.54 188.675 1052.54 187.684C1052.54 186.693 1051.74 185.889 1050.75 185.889C1049.76 185.889 1048.96 186.693 1048.96 187.684C1048.96 188.675 1049.76 189.479 1050.75 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 189.479C1045.77 189.479 1046.57 188.675 1046.57 187.684C1046.57 186.693 1045.77 185.889 1044.78 185.889C1043.79 185.889 1042.99 186.693 1042.99 187.684C1042.99 188.675 1043.79 189.479 1044.78 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 189.479C1039.8 189.479 1040.6 188.675 1040.6 187.684C1040.6 186.693 1039.8 185.889 1038.81 185.889C1037.82 185.889 1037.01 186.693 1037.01 187.684C1037.01 188.675 1037.82 189.479 1038.81 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 189.479C1015.91 189.479 1016.72 188.675 1016.72 187.684C1016.72 186.693 1015.91 185.889 1014.93 185.889C1013.94 185.889 1013.13 186.693 1013.13 187.684C1013.13 188.675 1013.94 189.479 1014.93 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 189.479C1009.94 189.479 1010.75 188.675 1010.75 187.684C1010.75 186.693 1009.94 185.889 1008.96 185.889C1007.97 185.889 1007.16 186.693 1007.16 187.684C1007.16 188.675 1007.97 189.479 1008.96 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 189.479C1003.97 189.479 1004.78 188.675 1004.78 187.684C1004.78 186.693 1003.97 185.889 1002.99 185.889C1002 185.889 1001.19 186.693 1001.19 187.684C1001.19 188.675 1002 189.479 1002.99 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 189.479C980.094 189.479 980.896 188.675 980.896 187.684C980.896 186.693 980.094 185.889 979.104 185.889C978.115 185.889 977.313 186.693 977.313 187.684C977.313 188.675 978.115 189.479 979.104 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 189.479C974.123 189.479 974.925 188.675 974.925 187.684C974.925 186.693 974.123 185.889 973.134 185.889C972.145 185.889 971.343 186.693 971.343 187.684C971.343 188.675 972.145 189.479 973.134 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 189.479C968.153 189.479 968.955 188.675 968.955 187.684C968.955 186.693 968.153 185.889 967.164 185.889C966.175 185.889 965.373 186.693 965.373 187.684C965.373 188.675 966.175 189.479 967.164 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 189.479C962.183 189.479 962.985 188.675 962.985 187.684C962.985 186.693 962.183 185.889 961.194 185.889C960.205 185.889 959.403 186.693 959.403 187.684C959.403 188.675 960.205 189.479 961.194 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 189.479C956.213 189.479 957.015 188.675 957.015 187.684C957.015 186.693 956.213 185.889 955.224 185.889C954.235 185.889 953.433 186.693 953.433 187.684C953.433 188.675 954.235 189.479 955.224 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 189.479C950.243 189.479 951.045 188.675 951.045 187.684C951.045 186.693 950.243 185.889 949.254 185.889C948.265 185.889 947.463 186.693 947.463 187.684C947.463 188.675 948.265 189.479 949.254 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 189.479C944.273 189.479 945.075 188.675 945.075 187.684C945.075 186.693 944.273 185.889 943.284 185.889C942.294 185.889 941.492 186.693 941.492 187.684C941.492 188.675 942.294 189.479 943.284 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 189.479C938.303 189.479 939.105 188.675 939.105 187.684C939.105 186.693 938.303 185.889 937.313 185.889C936.324 185.889 935.522 186.693 935.522 187.684C935.522 188.675 936.324 189.479 937.313 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 189.479C932.332 189.479 933.134 188.675 933.134 187.684C933.134 186.693 932.332 185.889 931.343 185.889C930.354 185.889 929.552 186.693 929.552 187.684C929.552 188.675 930.354 189.479 931.343 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 189.479C926.362 189.479 927.164 188.675 927.164 187.684C927.164 186.693 926.362 185.889 925.373 185.889C924.384 185.889 923.582 186.693 923.582 187.684C923.582 188.675 924.384 189.479 925.373 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 189.479C920.392 189.479 921.194 188.675 921.194 187.684C921.194 186.693 920.392 185.889 919.403 185.889C918.414 185.889 917.612 186.693 917.612 187.684C917.612 188.675 918.414 189.479 919.403 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 189.479C914.422 189.479 915.224 188.675 915.224 187.684C915.224 186.693 914.422 185.889 913.433 185.889C912.444 185.889 911.642 186.693 911.642 187.684C911.642 188.675 912.444 189.479 913.433 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 189.479C908.452 189.479 909.254 188.675 909.254 187.684C909.254 186.693 908.452 185.889 907.463 185.889C906.474 185.889 905.672 186.693 905.672 187.684C905.672 188.675 906.474 189.479 907.463 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 189.479C902.482 189.479 903.284 188.675 903.284 187.684C903.284 186.693 902.482 185.889 901.492 185.889C900.503 185.889 899.701 186.693 899.701 187.684C899.701 188.675 900.503 189.479 901.492 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 189.479C896.512 189.479 897.313 188.675 897.313 187.684C897.313 186.693 896.512 185.889 895.522 185.889C894.533 185.889 893.731 186.693 893.731 187.684C893.731 188.675 894.533 189.479 895.522 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 189.479C890.541 189.479 891.343 188.675 891.343 187.684C891.343 186.693 890.541 185.889 889.552 185.889C888.563 185.889 887.761 186.693 887.761 187.684C887.761 188.675 888.563 189.479 889.552 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 189.479C884.571 189.479 885.373 188.675 885.373 187.684C885.373 186.693 884.571 185.889 883.582 185.889C882.593 185.889 881.791 186.693 881.791 187.684C881.791 188.675 882.593 189.479 883.582 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 189.479C878.601 189.479 879.403 188.675 879.403 187.684C879.403 186.693 878.601 185.889 877.612 185.889C876.623 185.889 875.821 186.693 875.821 187.684C875.821 188.675 876.623 189.479 877.612 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 189.479C872.631 189.479 873.433 188.675 873.433 187.684C873.433 186.693 872.631 185.889 871.642 185.889C870.653 185.889 869.851 186.693 869.851 187.684C869.851 188.675 870.653 189.479 871.642 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 189.479C866.661 189.479 867.463 188.675 867.463 187.684C867.463 186.693 866.661 185.889 865.672 185.889C864.683 185.889 863.881 186.693 863.881 187.684C863.881 188.675 864.683 189.479 865.672 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 189.479C860.691 189.479 861.493 188.675 861.493 187.684C861.493 186.693 860.691 185.889 859.701 185.889C858.712 185.889 857.91 186.693 857.91 187.684C857.91 188.675 858.712 189.479 859.701 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 189.479C854.721 189.479 855.522 188.675 855.522 187.684C855.522 186.693 854.721 185.889 853.731 185.889C852.742 185.889 851.94 186.693 851.94 187.684C851.94 188.675 852.742 189.479 853.731 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 189.479C848.75 189.479 849.552 188.675 849.552 187.684C849.552 186.693 848.75 185.889 847.761 185.889C846.772 185.889 845.97 186.693 845.97 187.684C845.97 188.675 846.772 189.479 847.761 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 189.479C842.78 189.479 843.582 188.675 843.582 187.684C843.582 186.693 842.78 185.889 841.791 185.889C840.802 185.889 840 186.693 840 187.684C840 188.675 840.802 189.479 841.791 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 189.479C836.81 189.479 837.612 188.675 837.612 187.684C837.612 186.693 836.81 185.889 835.821 185.889C834.832 185.889 834.03 186.693 834.03 187.684C834.03 188.675 834.832 189.479 835.821 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 189.479C830.84 189.479 831.642 188.675 831.642 187.684C831.642 186.693 830.84 185.889 829.851 185.889C828.862 185.889 828.06 186.693 828.06 187.684C828.06 188.675 828.862 189.479 829.851 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 189.479C824.87 189.479 825.672 188.675 825.672 187.684C825.672 186.693 824.87 185.889 823.881 185.889C822.891 185.889 822.09 186.693 822.09 187.684C822.09 188.675 822.891 189.479 823.881 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 189.479C818.9 189.479 819.701 188.675 819.701 187.684C819.701 186.693 818.9 185.889 817.91 185.889C816.921 185.889 816.119 186.693 816.119 187.684C816.119 188.675 816.921 189.479 817.91 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 189.479C812.929 189.479 813.731 188.675 813.731 187.684C813.731 186.693 812.929 185.889 811.94 185.889C810.951 185.889 810.149 186.693 810.149 187.684C810.149 188.675 810.951 189.479 811.94 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 189.479C806.959 189.479 807.761 188.675 807.761 187.684C807.761 186.693 806.959 185.889 805.97 185.889C804.981 185.889 804.179 186.693 804.179 187.684C804.179 188.675 804.981 189.479 805.97 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M800 189.479C800.989 189.479 801.791 188.675 801.791 187.684C801.791 186.693 800.989 185.889 800 185.889C799.011 185.889 798.209 186.693 798.209 187.684C798.209 188.675 799.011 189.479 800 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 189.479C795.019 189.479 795.821 188.675 795.821 187.684C795.821 186.693 795.019 185.889 794.03 185.889C793.041 185.889 792.239 186.693 792.239 187.684C792.239 188.675 793.041 189.479 794.03 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 189.479C789.049 189.479 789.851 188.675 789.851 187.684C789.851 186.693 789.049 185.889 788.06 185.889C787.071 185.889 786.269 186.693 786.269 187.684C786.269 188.675 787.071 189.479 788.06 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 189.479C783.079 189.479 783.881 188.675 783.881 187.684C783.881 186.693 783.079 185.889 782.09 185.889C781.1 185.889 780.299 186.693 780.299 187.684C780.299 188.675 781.1 189.479 782.09 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 189.479C777.109 189.479 777.91 188.675 777.91 187.684C777.91 186.693 777.109 185.889 776.119 185.889C775.13 185.889 774.328 186.693 774.328 187.684C774.328 188.675 775.13 189.479 776.119 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 189.479C771.138 189.479 771.94 188.675 771.94 187.684C771.94 186.693 771.138 185.889 770.149 185.889C769.16 185.889 768.358 186.693 768.358 187.684C768.358 188.675 769.16 189.479 770.149 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 189.479C765.168 189.479 765.97 188.675 765.97 187.684C765.97 186.693 765.168 185.889 764.179 185.889C763.19 185.889 762.388 186.693 762.388 187.684C762.388 188.675 763.19 189.479 764.179 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 189.479C759.198 189.479 760 188.675 760 187.684C760 186.693 759.198 185.889 758.209 185.889C757.22 185.889 756.418 186.693 756.418 187.684C756.418 188.675 757.22 189.479 758.209 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 189.479C753.228 189.479 754.03 188.675 754.03 187.684C754.03 186.693 753.228 185.889 752.239 185.889C751.25 185.889 750.448 186.693 750.448 187.684C750.448 188.675 751.25 189.479 752.239 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 189.479C747.258 189.479 748.06 188.675 748.06 187.684C748.06 186.693 747.258 185.889 746.269 185.889C745.279 185.889 744.478 186.693 744.478 187.684C744.478 188.675 745.279 189.479 746.269 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 189.479C741.288 189.479 742.09 188.675 742.09 187.684C742.09 186.693 741.288 185.889 740.299 185.889C739.309 185.889 738.507 186.693 738.507 187.684C738.507 188.675 739.309 189.479 740.299 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 189.479C735.318 189.479 736.119 188.675 736.119 187.684C736.119 186.693 735.318 185.889 734.328 185.889C733.339 185.889 732.537 186.693 732.537 187.684C732.537 188.675 733.339 189.479 734.328 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 189.479C729.347 189.479 730.149 188.675 730.149 187.684C730.149 186.693 729.347 185.889 728.358 185.889C727.369 185.889 726.567 186.693 726.567 187.684C726.567 188.675 727.369 189.479 728.358 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 189.479C723.377 189.479 724.179 188.675 724.179 187.684C724.179 186.693 723.377 185.889 722.388 185.889C721.399 185.889 720.597 186.693 720.597 187.684C720.597 188.675 721.399 189.479 722.388 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 189.479C717.407 189.479 718.209 188.675 718.209 187.684C718.209 186.693 717.407 185.889 716.418 185.889C715.429 185.889 714.627 186.693 714.627 187.684C714.627 188.675 715.429 189.479 716.418 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 189.479C711.437 189.479 712.239 188.675 712.239 187.684C712.239 186.693 711.437 185.889 710.448 185.889C709.459 185.889 708.657 186.693 708.657 187.684C708.657 188.675 709.459 189.479 710.448 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 189.479C705.467 189.479 706.269 188.675 706.269 187.684C706.269 186.693 705.467 185.889 704.478 185.889C703.488 185.889 702.687 186.693 702.687 187.684C702.687 188.675 703.488 189.479 704.478 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 189.479C699.497 189.479 700.299 188.675 700.299 187.684C700.299 186.693 699.497 185.889 698.507 185.889C697.518 185.889 696.716 186.693 696.716 187.684C696.716 188.675 697.518 189.479 698.507 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 189.479C693.526 189.479 694.328 188.675 694.328 187.684C694.328 186.693 693.526 185.889 692.537 185.889C691.548 185.889 690.746 186.693 690.746 187.684C690.746 188.675 691.548 189.479 692.537 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 189.479C687.556 189.479 688.358 188.675 688.358 187.684C688.358 186.693 687.556 185.889 686.567 185.889C685.578 185.889 684.776 186.693 684.776 187.684C684.776 188.675 685.578 189.479 686.567 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 189.479C681.586 189.479 682.388 188.675 682.388 187.684C682.388 186.693 681.586 185.889 680.597 185.889C679.608 185.889 678.806 186.693 678.806 187.684C678.806 188.675 679.608 189.479 680.597 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 189.479C675.616 189.479 676.418 188.675 676.418 187.684C676.418 186.693 675.616 185.889 674.627 185.889C673.638 185.889 672.836 186.693 672.836 187.684C672.836 188.675 673.638 189.479 674.627 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 189.479C669.646 189.479 670.448 188.675 670.448 187.684C670.448 186.693 669.646 185.889 668.657 185.889C667.668 185.889 666.866 186.693 666.866 187.684C666.866 188.675 667.668 189.479 668.657 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 189.479C657.706 189.479 658.507 188.675 658.507 187.684C658.507 186.693 657.706 185.889 656.716 185.889C655.727 185.889 654.925 186.693 654.925 187.684C654.925 188.675 655.727 189.479 656.716 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 189.479C574.123 189.479 574.925 188.675 574.925 187.684C574.925 186.693 574.123 185.889 573.134 185.889C572.145 185.889 571.343 186.693 571.343 187.684C571.343 188.675 572.145 189.479 573.134 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 189.479C568.153 189.479 568.955 188.675 568.955 187.684C568.955 186.693 568.153 185.889 567.164 185.889C566.175 185.889 565.373 186.693 565.373 187.684C565.373 188.675 566.175 189.479 567.164 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 189.479C562.183 189.479 562.985 188.675 562.985 187.684C562.985 186.693 562.183 185.889 561.194 185.889C560.205 185.889 559.403 186.693 559.403 187.684C559.403 188.675 560.205 189.479 561.194 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 189.479C556.213 189.479 557.015 188.675 557.015 187.684C557.015 186.693 556.213 185.889 555.224 185.889C554.235 185.889 553.433 186.693 553.433 187.684C553.433 188.675 554.235 189.479 555.224 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 189.479C550.243 189.479 551.045 188.675 551.045 187.684C551.045 186.693 550.243 185.889 549.254 185.889C548.265 185.889 547.463 186.693 547.463 187.684C547.463 188.675 548.265 189.479 549.254 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 189.479C544.273 189.479 545.075 188.675 545.075 187.684C545.075 186.693 544.273 185.889 543.284 185.889C542.294 185.889 541.492 186.693 541.492 187.684C541.492 188.675 542.294 189.479 543.284 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 189.479C538.303 189.479 539.104 188.675 539.104 187.684C539.104 186.693 538.303 185.889 537.313 185.889C536.324 185.889 535.522 186.693 535.522 187.684C535.522 188.675 536.324 189.479 537.313 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 183.497C1051.74 183.497 1052.54 182.693 1052.54 181.702C1052.54 180.711 1051.74 179.907 1050.75 179.907C1049.76 179.907 1048.96 180.711 1048.96 181.702C1048.96 182.693 1049.76 183.497 1050.75 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 183.497C1045.77 183.497 1046.57 182.693 1046.57 181.702C1046.57 180.711 1045.77 179.907 1044.78 179.907C1043.79 179.907 1042.99 180.711 1042.99 181.702C1042.99 182.693 1043.79 183.497 1044.78 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 183.497C1009.94 183.497 1010.75 182.693 1010.75 181.702C1010.75 180.711 1009.94 179.907 1008.96 179.907C1007.97 179.907 1007.16 180.711 1007.16 181.702C1007.16 182.693 1007.97 183.497 1008.96 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 183.497C986.064 183.497 986.866 182.693 986.866 181.702C986.866 180.711 986.064 179.907 985.075 179.907C984.085 179.907 983.284 180.711 983.284 181.702C983.284 182.693 984.085 183.497 985.075 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 183.497C980.094 183.497 980.896 182.693 980.896 181.702C980.896 180.711 980.094 179.907 979.104 179.907C978.115 179.907 977.313 180.711 977.313 181.702C977.313 182.693 978.115 183.497 979.104 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 183.497C974.123 183.497 974.925 182.693 974.925 181.702C974.925 180.711 974.123 179.907 973.134 179.907C972.145 179.907 971.343 180.711 971.343 181.702C971.343 182.693 972.145 183.497 973.134 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 183.497C968.153 183.497 968.955 182.693 968.955 181.702C968.955 180.711 968.153 179.907 967.164 179.907C966.175 179.907 965.373 180.711 965.373 181.702C965.373 182.693 966.175 183.497 967.164 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 183.497C962.183 183.497 962.985 182.693 962.985 181.702C962.985 180.711 962.183 179.907 961.194 179.907C960.205 179.907 959.403 180.711 959.403 181.702C959.403 182.693 960.205 183.497 961.194 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 183.497C956.213 183.497 957.015 182.693 957.015 181.702C957.015 180.711 956.213 179.907 955.224 179.907C954.235 179.907 953.433 180.711 953.433 181.702C953.433 182.693 954.235 183.497 955.224 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 183.497C950.243 183.497 951.045 182.693 951.045 181.702C951.045 180.711 950.243 179.907 949.254 179.907C948.265 179.907 947.463 180.711 947.463 181.702C947.463 182.693 948.265 183.497 949.254 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 183.497C944.273 183.497 945.075 182.693 945.075 181.702C945.075 180.711 944.273 179.907 943.284 179.907C942.294 179.907 941.492 180.711 941.492 181.702C941.492 182.693 942.294 183.497 943.284 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 183.497C938.303 183.497 939.105 182.693 939.105 181.702C939.105 180.711 938.303 179.907 937.313 179.907C936.324 179.907 935.522 180.711 935.522 181.702C935.522 182.693 936.324 183.497 937.313 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 183.497C932.332 183.497 933.134 182.693 933.134 181.702C933.134 180.711 932.332 179.907 931.343 179.907C930.354 179.907 929.552 180.711 929.552 181.702C929.552 182.693 930.354 183.497 931.343 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 183.497C926.362 183.497 927.164 182.693 927.164 181.702C927.164 180.711 926.362 179.907 925.373 179.907C924.384 179.907 923.582 180.711 923.582 181.702C923.582 182.693 924.384 183.497 925.373 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 183.497C920.392 183.497 921.194 182.693 921.194 181.702C921.194 180.711 920.392 179.907 919.403 179.907C918.414 179.907 917.612 180.711 917.612 181.702C917.612 182.693 918.414 183.497 919.403 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 183.497C914.422 183.497 915.224 182.693 915.224 181.702C915.224 180.711 914.422 179.907 913.433 179.907C912.444 179.907 911.642 180.711 911.642 181.702C911.642 182.693 912.444 183.497 913.433 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 183.497C908.452 183.497 909.254 182.693 909.254 181.702C909.254 180.711 908.452 179.907 907.463 179.907C906.474 179.907 905.672 180.711 905.672 181.702C905.672 182.693 906.474 183.497 907.463 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 183.497C902.482 183.497 903.284 182.693 903.284 181.702C903.284 180.711 902.482 179.907 901.492 179.907C900.503 179.907 899.701 180.711 899.701 181.702C899.701 182.693 900.503 183.497 901.492 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 183.497C896.512 183.497 897.313 182.693 897.313 181.702C897.313 180.711 896.512 179.907 895.522 179.907C894.533 179.907 893.731 180.711 893.731 181.702C893.731 182.693 894.533 183.497 895.522 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 183.497C890.541 183.497 891.343 182.693 891.343 181.702C891.343 180.711 890.541 179.907 889.552 179.907C888.563 179.907 887.761 180.711 887.761 181.702C887.761 182.693 888.563 183.497 889.552 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 183.497C884.571 183.497 885.373 182.693 885.373 181.702C885.373 180.711 884.571 179.907 883.582 179.907C882.593 179.907 881.791 180.711 881.791 181.702C881.791 182.693 882.593 183.497 883.582 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 183.497C878.601 183.497 879.403 182.693 879.403 181.702C879.403 180.711 878.601 179.907 877.612 179.907C876.623 179.907 875.821 180.711 875.821 181.702C875.821 182.693 876.623 183.497 877.612 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 183.497C872.631 183.497 873.433 182.693 873.433 181.702C873.433 180.711 872.631 179.907 871.642 179.907C870.653 179.907 869.851 180.711 869.851 181.702C869.851 182.693 870.653 183.497 871.642 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 183.497C866.661 183.497 867.463 182.693 867.463 181.702C867.463 180.711 866.661 179.907 865.672 179.907C864.683 179.907 863.881 180.711 863.881 181.702C863.881 182.693 864.683 183.497 865.672 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 183.497C860.691 183.497 861.493 182.693 861.493 181.702C861.493 180.711 860.691 179.907 859.701 179.907C858.712 179.907 857.91 180.711 857.91 181.702C857.91 182.693 858.712 183.497 859.701 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 183.497C854.721 183.497 855.522 182.693 855.522 181.702C855.522 180.711 854.721 179.907 853.731 179.907C852.742 179.907 851.94 180.711 851.94 181.702C851.94 182.693 852.742 183.497 853.731 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 183.497C848.75 183.497 849.552 182.693 849.552 181.702C849.552 180.711 848.75 179.907 847.761 179.907C846.772 179.907 845.97 180.711 845.97 181.702C845.97 182.693 846.772 183.497 847.761 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 183.497C842.78 183.497 843.582 182.693 843.582 181.702C843.582 180.711 842.78 179.907 841.791 179.907C840.802 179.907 840 180.711 840 181.702C840 182.693 840.802 183.497 841.791 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 183.497C836.81 183.497 837.612 182.693 837.612 181.702C837.612 180.711 836.81 179.907 835.821 179.907C834.832 179.907 834.03 180.711 834.03 181.702C834.03 182.693 834.832 183.497 835.821 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 183.497C830.84 183.497 831.642 182.693 831.642 181.702C831.642 180.711 830.84 179.907 829.851 179.907C828.862 179.907 828.06 180.711 828.06 181.702C828.06 182.693 828.862 183.497 829.851 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 183.497C824.87 183.497 825.672 182.693 825.672 181.702C825.672 180.711 824.87 179.907 823.881 179.907C822.891 179.907 822.09 180.711 822.09 181.702C822.09 182.693 822.891 183.497 823.881 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 183.497C818.9 183.497 819.701 182.693 819.701 181.702C819.701 180.711 818.9 179.907 817.91 179.907C816.921 179.907 816.119 180.711 816.119 181.702C816.119 182.693 816.921 183.497 817.91 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 183.497C812.929 183.497 813.731 182.693 813.731 181.702C813.731 180.711 812.929 179.907 811.94 179.907C810.951 179.907 810.149 180.711 810.149 181.702C810.149 182.693 810.951 183.497 811.94 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 183.497C806.959 183.497 807.761 182.693 807.761 181.702C807.761 180.711 806.959 179.907 805.97 179.907C804.981 179.907 804.179 180.711 804.179 181.702C804.179 182.693 804.981 183.497 805.97 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M800 183.497C800.989 183.497 801.791 182.693 801.791 181.702C801.791 180.711 800.989 179.907 800 179.907C799.011 179.907 798.209 180.711 798.209 181.702C798.209 182.693 799.011 183.497 800 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 183.497C795.019 183.497 795.821 182.693 795.821 181.702C795.821 180.711 795.019 179.907 794.03 179.907C793.041 179.907 792.239 180.711 792.239 181.702C792.239 182.693 793.041 183.497 794.03 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 183.497C789.049 183.497 789.851 182.693 789.851 181.702C789.851 180.711 789.049 179.907 788.06 179.907C787.071 179.907 786.269 180.711 786.269 181.702C786.269 182.693 787.071 183.497 788.06 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 183.497C783.079 183.497 783.881 182.693 783.881 181.702C783.881 180.711 783.079 179.907 782.09 179.907C781.1 179.907 780.299 180.711 780.299 181.702C780.299 182.693 781.1 183.497 782.09 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 183.497C777.109 183.497 777.91 182.693 777.91 181.702C777.91 180.711 777.109 179.907 776.119 179.907C775.13 179.907 774.328 180.711 774.328 181.702C774.328 182.693 775.13 183.497 776.119 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 183.497C771.138 183.497 771.94 182.693 771.94 181.702C771.94 180.711 771.138 179.907 770.149 179.907C769.16 179.907 768.358 180.711 768.358 181.702C768.358 182.693 769.16 183.497 770.149 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 183.497C765.168 183.497 765.97 182.693 765.97 181.702C765.97 180.711 765.168 179.907 764.179 179.907C763.19 179.907 762.388 180.711 762.388 181.702C762.388 182.693 763.19 183.497 764.179 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 183.497C759.198 183.497 760 182.693 760 181.702C760 180.711 759.198 179.907 758.209 179.907C757.22 179.907 756.418 180.711 756.418 181.702C756.418 182.693 757.22 183.497 758.209 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 183.497C753.228 183.497 754.03 182.693 754.03 181.702C754.03 180.711 753.228 179.907 752.239 179.907C751.25 179.907 750.448 180.711 750.448 181.702C750.448 182.693 751.25 183.497 752.239 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 183.497C747.258 183.497 748.06 182.693 748.06 181.702C748.06 180.711 747.258 179.907 746.269 179.907C745.279 179.907 744.478 180.711 744.478 181.702C744.478 182.693 745.279 183.497 746.269 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 183.497C741.288 183.497 742.09 182.693 742.09 181.702C742.09 180.711 741.288 179.907 740.299 179.907C739.309 179.907 738.507 180.711 738.507 181.702C738.507 182.693 739.309 183.497 740.299 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 183.497C735.318 183.497 736.119 182.693 736.119 181.702C736.119 180.711 735.318 179.907 734.328 179.907C733.339 179.907 732.537 180.711 732.537 181.702C732.537 182.693 733.339 183.497 734.328 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 183.497C717.407 183.497 718.209 182.693 718.209 181.702C718.209 180.711 717.407 179.907 716.418 179.907C715.429 179.907 714.627 180.711 714.627 181.702C714.627 182.693 715.429 183.497 716.418 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 183.497C711.437 183.497 712.239 182.693 712.239 181.702C712.239 180.711 711.437 179.907 710.448 179.907C709.459 179.907 708.657 180.711 708.657 181.702C708.657 182.693 709.459 183.497 710.448 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 183.497C705.467 183.497 706.269 182.693 706.269 181.702C706.269 180.711 705.467 179.907 704.478 179.907C703.488 179.907 702.687 180.711 702.687 181.702C702.687 182.693 703.488 183.497 704.478 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 183.497C699.497 183.497 700.299 182.693 700.299 181.702C700.299 180.711 699.497 179.907 698.507 179.907C697.518 179.907 696.716 180.711 696.716 181.702C696.716 182.693 697.518 183.497 698.507 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 183.497C693.526 183.497 694.328 182.693 694.328 181.702C694.328 180.711 693.526 179.907 692.537 179.907C691.548 179.907 690.746 180.711 690.746 181.702C690.746 182.693 691.548 183.497 692.537 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 183.497C687.556 183.497 688.358 182.693 688.358 181.702C688.358 180.711 687.556 179.907 686.567 179.907C685.578 179.907 684.776 180.711 684.776 181.702C684.776 182.693 685.578 183.497 686.567 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 183.497C681.586 183.497 682.388 182.693 682.388 181.702C682.388 180.711 681.586 179.907 680.597 179.907C679.608 179.907 678.806 180.711 678.806 181.702C678.806 182.693 679.608 183.497 680.597 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 183.497C675.616 183.497 676.418 182.693 676.418 181.702C676.418 180.711 675.616 179.907 674.627 179.907C673.638 179.907 672.836 180.711 672.836 181.702C672.836 182.693 673.638 183.497 674.627 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 183.497C669.646 183.497 670.448 182.693 670.448 181.702C670.448 180.711 669.646 179.907 668.657 179.907C667.668 179.907 666.866 180.711 666.866 181.702C666.866 182.693 667.668 183.497 668.657 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 183.497C663.676 183.497 664.478 182.693 664.478 181.702C664.478 180.711 663.676 179.907 662.687 179.907C661.697 179.907 660.896 180.711 660.896 181.702C660.896 182.693 661.697 183.497 662.687 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 183.497C657.706 183.497 658.507 182.693 658.507 181.702C658.507 180.711 657.706 179.907 656.716 179.907C655.727 179.907 654.925 180.711 654.925 181.702C654.925 182.693 655.727 183.497 656.716 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 183.497C651.735 183.497 652.537 182.693 652.537 181.702C652.537 180.711 651.735 179.907 650.746 179.907C649.757 179.907 648.955 180.711 648.955 181.702C648.955 182.693 649.757 183.497 650.746 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 183.497C645.765 183.497 646.567 182.693 646.567 181.702C646.567 180.711 645.765 179.907 644.776 179.907C643.787 179.907 642.985 180.711 642.985 181.702C642.985 182.693 643.787 183.497 644.776 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 183.497C639.795 183.497 640.597 182.693 640.597 181.702C640.597 180.711 639.795 179.907 638.806 179.907C637.817 179.907 637.015 180.711 637.015 181.702C637.015 182.693 637.817 183.497 638.806 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 183.497C615.915 183.497 616.716 182.693 616.716 181.702C616.716 180.711 615.915 179.907 614.925 179.907C613.936 179.907 613.134 180.711 613.134 181.702C613.134 182.693 613.936 183.497 614.925 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 183.497C592.034 183.497 592.836 182.693 592.836 181.702C592.836 180.711 592.034 179.907 591.045 179.907C590.056 179.907 589.254 180.711 589.254 181.702C589.254 182.693 590.056 183.497 591.045 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 183.497C586.064 183.497 586.866 182.693 586.866 181.702C586.866 180.711 586.064 179.907 585.075 179.907C584.085 179.907 583.284 180.711 583.284 181.702C583.284 182.693 584.085 183.497 585.075 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 183.497C526.362 183.497 527.164 182.693 527.164 181.702C527.164 180.711 526.362 179.907 525.373 179.907C524.384 179.907 523.582 180.711 523.582 181.702C523.582 182.693 524.384 183.497 525.373 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 183.497C520.392 183.497 521.194 182.693 521.194 181.702C521.194 180.711 520.392 179.907 519.403 179.907C518.414 179.907 517.612 180.711 517.612 181.702C517.612 182.693 518.414 183.497 519.403 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 183.497C514.422 183.497 515.224 182.693 515.224 181.702C515.224 180.711 514.422 179.907 513.433 179.907C512.444 179.907 511.642 180.711 511.642 181.702C511.642 182.693 512.444 183.497 513.433 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 183.497C508.452 183.497 509.254 182.693 509.254 181.702C509.254 180.711 508.452 179.907 507.463 179.907C506.474 179.907 505.672 180.711 505.672 181.702C505.672 182.693 506.474 183.497 507.463 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 183.497C502.482 183.497 503.284 182.693 503.284 181.702C503.284 180.711 502.482 179.907 501.493 179.907C500.503 179.907 499.702 180.711 499.702 181.702C499.702 182.693 500.503 183.497 501.493 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 177.514C1051.74 177.514 1052.54 176.711 1052.54 175.719C1052.54 174.728 1051.74 173.925 1050.75 173.925C1049.76 173.925 1048.96 174.728 1048.96 175.719C1048.96 176.711 1049.76 177.514 1050.75 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 177.514C1003.97 177.514 1004.78 176.711 1004.78 175.719C1004.78 174.728 1003.97 173.925 1002.99 173.925C1002 173.925 1001.19 174.728 1001.19 175.719C1001.19 176.711 1002 177.514 1002.99 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 177.514C998.004 177.514 998.806 176.711 998.806 175.719C998.806 174.728 998.004 173.925 997.015 173.925C996.026 173.925 995.224 174.728 995.224 175.719C995.224 176.711 996.026 177.514 997.015 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 177.514C968.153 177.514 968.955 176.711 968.955 175.719C968.955 174.728 968.153 173.925 967.164 173.925C966.175 173.925 965.373 174.728 965.373 175.719C965.373 176.711 966.175 177.514 967.164 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 177.514C962.183 177.514 962.985 176.711 962.985 175.719C962.985 174.728 962.183 173.925 961.194 173.925C960.205 173.925 959.403 174.728 959.403 175.719C959.403 176.711 960.205 177.514 961.194 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 177.514C956.213 177.514 957.015 176.711 957.015 175.719C957.015 174.728 956.213 173.925 955.224 173.925C954.235 173.925 953.433 174.728 953.433 175.719C953.433 176.711 954.235 177.514 955.224 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 177.514C950.243 177.514 951.045 176.711 951.045 175.719C951.045 174.728 950.243 173.925 949.254 173.925C948.265 173.925 947.463 174.728 947.463 175.719C947.463 176.711 948.265 177.514 949.254 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 177.514C944.273 177.514 945.075 176.711 945.075 175.719C945.075 174.728 944.273 173.925 943.284 173.925C942.294 173.925 941.492 174.728 941.492 175.719C941.492 176.711 942.294 177.514 943.284 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 177.514C938.303 177.514 939.105 176.711 939.105 175.719C939.105 174.728 938.303 173.925 937.313 173.925C936.324 173.925 935.522 174.728 935.522 175.719C935.522 176.711 936.324 177.514 937.313 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 177.514C932.332 177.514 933.134 176.711 933.134 175.719C933.134 174.728 932.332 173.925 931.343 173.925C930.354 173.925 929.552 174.728 929.552 175.719C929.552 176.711 930.354 177.514 931.343 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 177.514C926.362 177.514 927.164 176.711 927.164 175.719C927.164 174.728 926.362 173.925 925.373 173.925C924.384 173.925 923.582 174.728 923.582 175.719C923.582 176.711 924.384 177.514 925.373 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 177.514C920.392 177.514 921.194 176.711 921.194 175.719C921.194 174.728 920.392 173.925 919.403 173.925C918.414 173.925 917.612 174.728 917.612 175.719C917.612 176.711 918.414 177.514 919.403 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 177.514C914.422 177.514 915.224 176.711 915.224 175.719C915.224 174.728 914.422 173.925 913.433 173.925C912.444 173.925 911.642 174.728 911.642 175.719C911.642 176.711 912.444 177.514 913.433 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 177.514C908.452 177.514 909.254 176.711 909.254 175.719C909.254 174.728 908.452 173.925 907.463 173.925C906.474 173.925 905.672 174.728 905.672 175.719C905.672 176.711 906.474 177.514 907.463 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 177.514C902.482 177.514 903.284 176.711 903.284 175.719C903.284 174.728 902.482 173.925 901.492 173.925C900.503 173.925 899.701 174.728 899.701 175.719C899.701 176.711 900.503 177.514 901.492 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 177.514C896.512 177.514 897.313 176.711 897.313 175.719C897.313 174.728 896.512 173.925 895.522 173.925C894.533 173.925 893.731 174.728 893.731 175.719C893.731 176.711 894.533 177.514 895.522 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 177.514C890.541 177.514 891.343 176.711 891.343 175.719C891.343 174.728 890.541 173.925 889.552 173.925C888.563 173.925 887.761 174.728 887.761 175.719C887.761 176.711 888.563 177.514 889.552 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 177.514C884.571 177.514 885.373 176.711 885.373 175.719C885.373 174.728 884.571 173.925 883.582 173.925C882.593 173.925 881.791 174.728 881.791 175.719C881.791 176.711 882.593 177.514 883.582 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 177.514C878.601 177.514 879.403 176.711 879.403 175.719C879.403 174.728 878.601 173.925 877.612 173.925C876.623 173.925 875.821 174.728 875.821 175.719C875.821 176.711 876.623 177.514 877.612 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 177.514C872.631 177.514 873.433 176.711 873.433 175.719C873.433 174.728 872.631 173.925 871.642 173.925C870.653 173.925 869.851 174.728 869.851 175.719C869.851 176.711 870.653 177.514 871.642 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 177.514C866.661 177.514 867.463 176.711 867.463 175.719C867.463 174.728 866.661 173.925 865.672 173.925C864.683 173.925 863.881 174.728 863.881 175.719C863.881 176.711 864.683 177.514 865.672 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 177.514C860.691 177.514 861.493 176.711 861.493 175.719C861.493 174.728 860.691 173.925 859.701 173.925C858.712 173.925 857.91 174.728 857.91 175.719C857.91 176.711 858.712 177.514 859.701 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 177.514C854.721 177.514 855.522 176.711 855.522 175.719C855.522 174.728 854.721 173.925 853.731 173.925C852.742 173.925 851.94 174.728 851.94 175.719C851.94 176.711 852.742 177.514 853.731 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 177.514C848.75 177.514 849.552 176.711 849.552 175.719C849.552 174.728 848.75 173.925 847.761 173.925C846.772 173.925 845.97 174.728 845.97 175.719C845.97 176.711 846.772 177.514 847.761 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 177.514C842.78 177.514 843.582 176.711 843.582 175.719C843.582 174.728 842.78 173.925 841.791 173.925C840.802 173.925 840 174.728 840 175.719C840 176.711 840.802 177.514 841.791 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 177.514C836.81 177.514 837.612 176.711 837.612 175.719C837.612 174.728 836.81 173.925 835.821 173.925C834.832 173.925 834.03 174.728 834.03 175.719C834.03 176.711 834.832 177.514 835.821 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 177.514C830.84 177.514 831.642 176.711 831.642 175.719C831.642 174.728 830.84 173.925 829.851 173.925C828.862 173.925 828.06 174.728 828.06 175.719C828.06 176.711 828.862 177.514 829.851 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 177.514C824.87 177.514 825.672 176.711 825.672 175.719C825.672 174.728 824.87 173.925 823.881 173.925C822.891 173.925 822.09 174.728 822.09 175.719C822.09 176.711 822.891 177.514 823.881 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 177.514C818.9 177.514 819.701 176.711 819.701 175.719C819.701 174.728 818.9 173.925 817.91 173.925C816.921 173.925 816.119 174.728 816.119 175.719C816.119 176.711 816.921 177.514 817.91 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 177.514C812.929 177.514 813.731 176.711 813.731 175.719C813.731 174.728 812.929 173.925 811.94 173.925C810.951 173.925 810.149 174.728 810.149 175.719C810.149 176.711 810.951 177.514 811.94 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 177.514C806.959 177.514 807.761 176.711 807.761 175.719C807.761 174.728 806.959 173.925 805.97 173.925C804.981 173.925 804.179 174.728 804.179 175.719C804.179 176.711 804.981 177.514 805.97 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M800 177.514C800.989 177.514 801.791 176.711 801.791 175.719C801.791 174.728 800.989 173.925 800 173.925C799.011 173.925 798.209 174.728 798.209 175.719C798.209 176.711 799.011 177.514 800 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 177.514C795.019 177.514 795.821 176.711 795.821 175.719C795.821 174.728 795.019 173.925 794.03 173.925C793.041 173.925 792.239 174.728 792.239 175.719C792.239 176.711 793.041 177.514 794.03 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 177.514C789.049 177.514 789.851 176.711 789.851 175.719C789.851 174.728 789.049 173.925 788.06 173.925C787.071 173.925 786.269 174.728 786.269 175.719C786.269 176.711 787.071 177.514 788.06 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 177.514C783.079 177.514 783.881 176.711 783.881 175.719C783.881 174.728 783.079 173.925 782.09 173.925C781.1 173.925 780.299 174.728 780.299 175.719C780.299 176.711 781.1 177.514 782.09 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 177.514C777.109 177.514 777.91 176.711 777.91 175.719C777.91 174.728 777.109 173.925 776.119 173.925C775.13 173.925 774.328 174.728 774.328 175.719C774.328 176.711 775.13 177.514 776.119 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 177.514C771.138 177.514 771.94 176.711 771.94 175.719C771.94 174.728 771.138 173.925 770.149 173.925C769.16 173.925 768.358 174.728 768.358 175.719C768.358 176.711 769.16 177.514 770.149 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 177.514C765.168 177.514 765.97 176.711 765.97 175.719C765.97 174.728 765.168 173.925 764.179 173.925C763.19 173.925 762.388 174.728 762.388 175.719C762.388 176.711 763.19 177.514 764.179 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 177.514C759.198 177.514 760 176.711 760 175.719C760 174.728 759.198 173.925 758.209 173.925C757.22 173.925 756.418 174.728 756.418 175.719C756.418 176.711 757.22 177.514 758.209 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 177.514C753.228 177.514 754.03 176.711 754.03 175.719C754.03 174.728 753.228 173.925 752.239 173.925C751.25 173.925 750.448 174.728 750.448 175.719C750.448 176.711 751.25 177.514 752.239 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 177.514C747.258 177.514 748.06 176.711 748.06 175.719C748.06 174.728 747.258 173.925 746.269 173.925C745.279 173.925 744.478 174.728 744.478 175.719C744.478 176.711 745.279 177.514 746.269 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 177.514C741.288 177.514 742.09 176.711 742.09 175.719C742.09 174.728 741.288 173.925 740.299 173.925C739.309 173.925 738.507 174.728 738.507 175.719C738.507 176.711 739.309 177.514 740.299 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 177.514C735.318 177.514 736.119 176.711 736.119 175.719C736.119 174.728 735.318 173.925 734.328 173.925C733.339 173.925 732.537 174.728 732.537 175.719C732.537 176.711 733.339 177.514 734.328 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 177.514C711.437 177.514 712.239 176.711 712.239 175.719C712.239 174.728 711.437 173.925 710.448 173.925C709.459 173.925 708.657 174.728 708.657 175.719C708.657 176.711 709.459 177.514 710.448 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 177.514C705.467 177.514 706.269 176.711 706.269 175.719C706.269 174.728 705.467 173.925 704.478 173.925C703.488 173.925 702.687 174.728 702.687 175.719C702.687 176.711 703.488 177.514 704.478 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 177.514C699.497 177.514 700.299 176.711 700.299 175.719C700.299 174.728 699.497 173.925 698.507 173.925C697.518 173.925 696.716 174.728 696.716 175.719C696.716 176.711 697.518 177.514 698.507 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 177.514C693.526 177.514 694.328 176.711 694.328 175.719C694.328 174.728 693.526 173.925 692.537 173.925C691.548 173.925 690.746 174.728 690.746 175.719C690.746 176.711 691.548 177.514 692.537 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 177.514C687.556 177.514 688.358 176.711 688.358 175.719C688.358 174.728 687.556 173.925 686.567 173.925C685.578 173.925 684.776 174.728 684.776 175.719C684.776 176.711 685.578 177.514 686.567 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 177.514C681.586 177.514 682.388 176.711 682.388 175.719C682.388 174.728 681.586 173.925 680.597 173.925C679.608 173.925 678.806 174.728 678.806 175.719C678.806 176.711 679.608 177.514 680.597 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 177.514C675.616 177.514 676.418 176.711 676.418 175.719C676.418 174.728 675.616 173.925 674.627 173.925C673.638 173.925 672.836 174.728 672.836 175.719C672.836 176.711 673.638 177.514 674.627 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 177.514C669.646 177.514 670.448 176.711 670.448 175.719C670.448 174.728 669.646 173.925 668.657 173.925C667.668 173.925 666.866 174.728 666.866 175.719C666.866 176.711 667.668 177.514 668.657 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 177.514C663.676 177.514 664.478 176.711 664.478 175.719C664.478 174.728 663.676 173.925 662.687 173.925C661.697 173.925 660.896 174.728 660.896 175.719C660.896 176.711 661.697 177.514 662.687 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 177.514C657.706 177.514 658.507 176.711 658.507 175.719C658.507 174.728 657.706 173.925 656.716 173.925C655.727 173.925 654.925 174.728 654.925 175.719C654.925 176.711 655.727 177.514 656.716 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 177.514C651.735 177.514 652.537 176.711 652.537 175.719C652.537 174.728 651.735 173.925 650.746 173.925C649.757 173.925 648.955 174.728 648.955 175.719C648.955 176.711 649.757 177.514 650.746 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 177.514C645.765 177.514 646.567 176.711 646.567 175.719C646.567 174.728 645.765 173.925 644.776 173.925C643.787 173.925 642.985 174.728 642.985 175.719C642.985 176.711 643.787 177.514 644.776 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 177.514C639.795 177.514 640.597 176.711 640.597 175.719C640.597 174.728 639.795 173.925 638.806 173.925C637.817 173.925 637.015 174.728 637.015 175.719C637.015 176.711 637.817 177.514 638.806 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 177.514C633.825 177.514 634.627 176.711 634.627 175.719C634.627 174.728 633.825 173.925 632.836 173.925C631.847 173.925 631.045 174.728 631.045 175.719C631.045 176.711 631.847 177.514 632.836 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 177.514C615.915 177.514 616.716 176.711 616.716 175.719C616.716 174.728 615.915 173.925 614.925 173.925C613.936 173.925 613.134 174.728 613.134 175.719C613.134 176.711 613.936 177.514 614.925 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 177.514C609.944 177.514 610.746 176.711 610.746 175.719C610.746 174.728 609.944 173.925 608.955 173.925C607.966 173.925 607.164 174.728 607.164 175.719C607.164 176.711 607.966 177.514 608.955 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 177.514C598.004 177.514 598.806 176.711 598.806 175.719C598.806 174.728 598.004 173.925 597.015 173.925C596.026 173.925 595.224 174.728 595.224 175.719C595.224 176.711 596.026 177.514 597.015 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 177.514C592.034 177.514 592.836 176.711 592.836 175.719C592.836 174.728 592.034 173.925 591.045 173.925C590.056 173.925 589.254 174.728 589.254 175.719C589.254 176.711 590.056 177.514 591.045 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 177.514C568.153 177.514 568.955 176.711 568.955 175.719C568.955 174.728 568.153 173.925 567.164 173.925C566.175 173.925 565.373 174.728 565.373 175.719C565.373 176.711 566.175 177.514 567.164 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 177.514C544.273 177.514 545.075 176.711 545.075 175.719C545.075 174.728 544.273 173.925 543.284 173.925C542.294 173.925 541.492 174.728 541.492 175.719C541.492 176.711 542.294 177.514 543.284 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 177.514C532.332 177.514 533.134 176.711 533.134 175.719C533.134 174.728 532.332 173.925 531.343 173.925C530.354 173.925 529.552 174.728 529.552 175.719C529.552 176.711 530.354 177.514 531.343 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 177.514C526.362 177.514 527.164 176.711 527.164 175.719C527.164 174.728 526.362 173.925 525.373 173.925C524.384 173.925 523.582 174.728 523.582 175.719C523.582 176.711 524.384 177.514 525.373 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 177.514C520.392 177.514 521.194 176.711 521.194 175.719C521.194 174.728 520.392 173.925 519.403 173.925C518.414 173.925 517.612 174.728 517.612 175.719C517.612 176.711 518.414 177.514 519.403 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 177.514C514.422 177.514 515.224 176.711 515.224 175.719C515.224 174.728 514.422 173.925 513.433 173.925C512.444 173.925 511.642 174.728 511.642 175.719C511.642 176.711 512.444 177.514 513.433 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 177.514C508.452 177.514 509.254 176.711 509.254 175.719C509.254 174.728 508.452 173.925 507.463 173.925C506.474 173.925 505.672 174.728 505.672 175.719C505.672 176.711 506.474 177.514 507.463 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 171.532C1051.74 171.532 1052.54 170.728 1052.54 169.737C1052.54 168.746 1051.74 167.943 1050.75 167.943C1049.76 167.943 1048.96 168.746 1048.96 169.737C1048.96 170.728 1049.76 171.532 1050.75 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 171.532C1003.97 171.532 1004.78 170.728 1004.78 169.737C1004.78 168.746 1003.97 167.943 1002.99 167.943C1002 167.943 1001.19 168.746 1001.19 169.737C1001.19 170.728 1002 171.532 1002.99 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 171.532C998.004 171.532 998.806 170.728 998.806 169.737C998.806 168.746 998.004 167.943 997.015 167.943C996.026 167.943 995.224 168.746 995.224 169.737C995.224 170.728 996.026 171.532 997.015 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 171.532C992.034 171.532 992.836 170.728 992.836 169.737C992.836 168.746 992.034 167.943 991.045 167.943C990.056 167.943 989.254 168.746 989.254 169.737C989.254 170.728 990.056 171.532 991.045 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 171.532C986.064 171.532 986.866 170.728 986.866 169.737C986.866 168.746 986.064 167.943 985.075 167.943C984.085 167.943 983.284 168.746 983.284 169.737C983.284 170.728 984.085 171.532 985.075 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 171.532C980.094 171.532 980.896 170.728 980.896 169.737C980.896 168.746 980.094 167.943 979.104 167.943C978.115 167.943 977.313 168.746 977.313 169.737C977.313 170.728 978.115 171.532 979.104 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 171.532C974.123 171.532 974.925 170.728 974.925 169.737C974.925 168.746 974.123 167.943 973.134 167.943C972.145 167.943 971.343 168.746 971.343 169.737C971.343 170.728 972.145 171.532 973.134 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 171.532C968.153 171.532 968.955 170.728 968.955 169.737C968.955 168.746 968.153 167.943 967.164 167.943C966.175 167.943 965.373 168.746 965.373 169.737C965.373 170.728 966.175 171.532 967.164 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 171.532C962.183 171.532 962.985 170.728 962.985 169.737C962.985 168.746 962.183 167.943 961.194 167.943C960.205 167.943 959.403 168.746 959.403 169.737C959.403 170.728 960.205 171.532 961.194 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 171.532C956.213 171.532 957.015 170.728 957.015 169.737C957.015 168.746 956.213 167.943 955.224 167.943C954.235 167.943 953.433 168.746 953.433 169.737C953.433 170.728 954.235 171.532 955.224 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 171.532C950.243 171.532 951.045 170.728 951.045 169.737C951.045 168.746 950.243 167.943 949.254 167.943C948.265 167.943 947.463 168.746 947.463 169.737C947.463 170.728 948.265 171.532 949.254 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 171.532C944.273 171.532 945.075 170.728 945.075 169.737C945.075 168.746 944.273 167.943 943.284 167.943C942.294 167.943 941.492 168.746 941.492 169.737C941.492 170.728 942.294 171.532 943.284 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 171.532C938.303 171.532 939.105 170.728 939.105 169.737C939.105 168.746 938.303 167.943 937.313 167.943C936.324 167.943 935.522 168.746 935.522 169.737C935.522 170.728 936.324 171.532 937.313 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 171.532C932.332 171.532 933.134 170.728 933.134 169.737C933.134 168.746 932.332 167.943 931.343 167.943C930.354 167.943 929.552 168.746 929.552 169.737C929.552 170.728 930.354 171.532 931.343 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 171.532C926.362 171.532 927.164 170.728 927.164 169.737C927.164 168.746 926.362 167.943 925.373 167.943C924.384 167.943 923.582 168.746 923.582 169.737C923.582 170.728 924.384 171.532 925.373 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 171.532C920.392 171.532 921.194 170.728 921.194 169.737C921.194 168.746 920.392 167.943 919.403 167.943C918.414 167.943 917.612 168.746 917.612 169.737C917.612 170.728 918.414 171.532 919.403 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 171.532C914.422 171.532 915.224 170.728 915.224 169.737C915.224 168.746 914.422 167.943 913.433 167.943C912.444 167.943 911.642 168.746 911.642 169.737C911.642 170.728 912.444 171.532 913.433 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 171.532C908.452 171.532 909.254 170.728 909.254 169.737C909.254 168.746 908.452 167.943 907.463 167.943C906.474 167.943 905.672 168.746 905.672 169.737C905.672 170.728 906.474 171.532 907.463 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 171.532C902.482 171.532 903.284 170.728 903.284 169.737C903.284 168.746 902.482 167.943 901.492 167.943C900.503 167.943 899.701 168.746 899.701 169.737C899.701 170.728 900.503 171.532 901.492 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 171.532C896.512 171.532 897.313 170.728 897.313 169.737C897.313 168.746 896.512 167.943 895.522 167.943C894.533 167.943 893.731 168.746 893.731 169.737C893.731 170.728 894.533 171.532 895.522 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 171.532C890.541 171.532 891.343 170.728 891.343 169.737C891.343 168.746 890.541 167.943 889.552 167.943C888.563 167.943 887.761 168.746 887.761 169.737C887.761 170.728 888.563 171.532 889.552 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 171.532C884.571 171.532 885.373 170.728 885.373 169.737C885.373 168.746 884.571 167.943 883.582 167.943C882.593 167.943 881.791 168.746 881.791 169.737C881.791 170.728 882.593 171.532 883.582 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 171.532C878.601 171.532 879.403 170.728 879.403 169.737C879.403 168.746 878.601 167.943 877.612 167.943C876.623 167.943 875.821 168.746 875.821 169.737C875.821 170.728 876.623 171.532 877.612 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 171.532C872.631 171.532 873.433 170.728 873.433 169.737C873.433 168.746 872.631 167.943 871.642 167.943C870.653 167.943 869.851 168.746 869.851 169.737C869.851 170.728 870.653 171.532 871.642 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 171.532C866.661 171.532 867.463 170.728 867.463 169.737C867.463 168.746 866.661 167.943 865.672 167.943C864.683 167.943 863.881 168.746 863.881 169.737C863.881 170.728 864.683 171.532 865.672 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 171.532C860.691 171.532 861.493 170.728 861.493 169.737C861.493 168.746 860.691 167.943 859.701 167.943C858.712 167.943 857.91 168.746 857.91 169.737C857.91 170.728 858.712 171.532 859.701 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 171.532C854.721 171.532 855.522 170.728 855.522 169.737C855.522 168.746 854.721 167.943 853.731 167.943C852.742 167.943 851.94 168.746 851.94 169.737C851.94 170.728 852.742 171.532 853.731 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 171.532C848.75 171.532 849.552 170.728 849.552 169.737C849.552 168.746 848.75 167.943 847.761 167.943C846.772 167.943 845.97 168.746 845.97 169.737C845.97 170.728 846.772 171.532 847.761 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 171.532C842.78 171.532 843.582 170.728 843.582 169.737C843.582 168.746 842.78 167.943 841.791 167.943C840.802 167.943 840 168.746 840 169.737C840 170.728 840.802 171.532 841.791 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 171.532C836.81 171.532 837.612 170.728 837.612 169.737C837.612 168.746 836.81 167.943 835.821 167.943C834.832 167.943 834.03 168.746 834.03 169.737C834.03 170.728 834.832 171.532 835.821 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 171.532C830.84 171.532 831.642 170.728 831.642 169.737C831.642 168.746 830.84 167.943 829.851 167.943C828.862 167.943 828.06 168.746 828.06 169.737C828.06 170.728 828.862 171.532 829.851 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 171.532C824.87 171.532 825.672 170.728 825.672 169.737C825.672 168.746 824.87 167.943 823.881 167.943C822.891 167.943 822.09 168.746 822.09 169.737C822.09 170.728 822.891 171.532 823.881 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 171.532C818.9 171.532 819.701 170.728 819.701 169.737C819.701 168.746 818.9 167.943 817.91 167.943C816.921 167.943 816.119 168.746 816.119 169.737C816.119 170.728 816.921 171.532 817.91 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 171.532C812.929 171.532 813.731 170.728 813.731 169.737C813.731 168.746 812.929 167.943 811.94 167.943C810.951 167.943 810.149 168.746 810.149 169.737C810.149 170.728 810.951 171.532 811.94 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 171.532C806.959 171.532 807.761 170.728 807.761 169.737C807.761 168.746 806.959 167.943 805.97 167.943C804.981 167.943 804.179 168.746 804.179 169.737C804.179 170.728 804.981 171.532 805.97 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M800 171.532C800.989 171.532 801.791 170.728 801.791 169.737C801.791 168.746 800.989 167.943 800 167.943C799.011 167.943 798.209 168.746 798.209 169.737C798.209 170.728 799.011 171.532 800 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 171.532C795.019 171.532 795.821 170.728 795.821 169.737C795.821 168.746 795.019 167.943 794.03 167.943C793.041 167.943 792.239 168.746 792.239 169.737C792.239 170.728 793.041 171.532 794.03 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 171.532C789.049 171.532 789.851 170.728 789.851 169.737C789.851 168.746 789.049 167.943 788.06 167.943C787.071 167.943 786.269 168.746 786.269 169.737C786.269 170.728 787.071 171.532 788.06 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 171.532C783.079 171.532 783.881 170.728 783.881 169.737C783.881 168.746 783.079 167.943 782.09 167.943C781.1 167.943 780.299 168.746 780.299 169.737C780.299 170.728 781.1 171.532 782.09 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 171.532C777.109 171.532 777.91 170.728 777.91 169.737C777.91 168.746 777.109 167.943 776.119 167.943C775.13 167.943 774.328 168.746 774.328 169.737C774.328 170.728 775.13 171.532 776.119 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 171.532C771.138 171.532 771.94 170.728 771.94 169.737C771.94 168.746 771.138 167.943 770.149 167.943C769.16 167.943 768.358 168.746 768.358 169.737C768.358 170.728 769.16 171.532 770.149 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 171.532C765.168 171.532 765.97 170.728 765.97 169.737C765.97 168.746 765.168 167.943 764.179 167.943C763.19 167.943 762.388 168.746 762.388 169.737C762.388 170.728 763.19 171.532 764.179 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 171.532C759.198 171.532 760 170.728 760 169.737C760 168.746 759.198 167.943 758.209 167.943C757.22 167.943 756.418 168.746 756.418 169.737C756.418 170.728 757.22 171.532 758.209 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 171.532C753.228 171.532 754.03 170.728 754.03 169.737C754.03 168.746 753.228 167.943 752.239 167.943C751.25 167.943 750.448 168.746 750.448 169.737C750.448 170.728 751.25 171.532 752.239 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 171.532C747.258 171.532 748.06 170.728 748.06 169.737C748.06 168.746 747.258 167.943 746.269 167.943C745.279 167.943 744.478 168.746 744.478 169.737C744.478 170.728 745.279 171.532 746.269 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 171.532C741.288 171.532 742.09 170.728 742.09 169.737C742.09 168.746 741.288 167.943 740.299 167.943C739.309 167.943 738.507 168.746 738.507 169.737C738.507 170.728 739.309 171.532 740.299 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 171.532C735.318 171.532 736.119 170.728 736.119 169.737C736.119 168.746 735.318 167.943 734.328 167.943C733.339 167.943 732.537 168.746 732.537 169.737C732.537 170.728 733.339 171.532 734.328 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 171.532C729.347 171.532 730.149 170.728 730.149 169.737C730.149 168.746 729.347 167.943 728.358 167.943C727.369 167.943 726.567 168.746 726.567 169.737C726.567 170.728 727.369 171.532 728.358 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 171.532C711.437 171.532 712.239 170.728 712.239 169.737C712.239 168.746 711.437 167.943 710.448 167.943C709.459 167.943 708.657 168.746 708.657 169.737C708.657 170.728 709.459 171.532 710.448 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 171.532C705.467 171.532 706.269 170.728 706.269 169.737C706.269 168.746 705.467 167.943 704.478 167.943C703.488 167.943 702.687 168.746 702.687 169.737C702.687 170.728 703.488 171.532 704.478 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 171.532C699.497 171.532 700.299 170.728 700.299 169.737C700.299 168.746 699.497 167.943 698.507 167.943C697.518 167.943 696.716 168.746 696.716 169.737C696.716 170.728 697.518 171.532 698.507 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 171.532C693.526 171.532 694.328 170.728 694.328 169.737C694.328 168.746 693.526 167.943 692.537 167.943C691.548 167.943 690.746 168.746 690.746 169.737C690.746 170.728 691.548 171.532 692.537 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 171.532C687.556 171.532 688.358 170.728 688.358 169.737C688.358 168.746 687.556 167.943 686.567 167.943C685.578 167.943 684.776 168.746 684.776 169.737C684.776 170.728 685.578 171.532 686.567 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 171.532C681.586 171.532 682.388 170.728 682.388 169.737C682.388 168.746 681.586 167.943 680.597 167.943C679.608 167.943 678.806 168.746 678.806 169.737C678.806 170.728 679.608 171.532 680.597 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 171.532C675.616 171.532 676.418 170.728 676.418 169.737C676.418 168.746 675.616 167.943 674.627 167.943C673.638 167.943 672.836 168.746 672.836 169.737C672.836 170.728 673.638 171.532 674.627 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 171.532C669.646 171.532 670.448 170.728 670.448 169.737C670.448 168.746 669.646 167.943 668.657 167.943C667.668 167.943 666.866 168.746 666.866 169.737C666.866 170.728 667.668 171.532 668.657 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 171.532C663.676 171.532 664.478 170.728 664.478 169.737C664.478 168.746 663.676 167.943 662.687 167.943C661.697 167.943 660.896 168.746 660.896 169.737C660.896 170.728 661.697 171.532 662.687 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 171.532C657.706 171.532 658.507 170.728 658.507 169.737C658.507 168.746 657.706 167.943 656.716 167.943C655.727 167.943 654.925 168.746 654.925 169.737C654.925 170.728 655.727 171.532 656.716 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 171.532C651.735 171.532 652.537 170.728 652.537 169.737C652.537 168.746 651.735 167.943 650.746 167.943C649.757 167.943 648.955 168.746 648.955 169.737C648.955 170.728 649.757 171.532 650.746 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 171.532C645.765 171.532 646.567 170.728 646.567 169.737C646.567 168.746 645.765 167.943 644.776 167.943C643.787 167.943 642.985 168.746 642.985 169.737C642.985 170.728 643.787 171.532 644.776 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 171.532C633.825 171.532 634.627 170.728 634.627 169.737C634.627 168.746 633.825 167.943 632.836 167.943C631.847 167.943 631.045 168.746 631.045 169.737C631.045 170.728 631.847 171.532 632.836 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 171.532C621.885 171.532 622.687 170.728 622.687 169.737C622.687 168.746 621.885 167.943 620.895 167.943C619.906 167.943 619.104 168.746 619.104 169.737C619.104 170.728 619.906 171.532 620.895 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 171.532C615.915 171.532 616.716 170.728 616.716 169.737C616.716 168.746 615.915 167.943 614.925 167.943C613.936 167.943 613.134 168.746 613.134 169.737C613.134 170.728 613.936 171.532 614.925 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 171.532C609.944 171.532 610.746 170.728 610.746 169.737C610.746 168.746 609.944 167.943 608.955 167.943C607.966 167.943 607.164 168.746 607.164 169.737C607.164 170.728 607.966 171.532 608.955 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 171.532C598.004 171.532 598.806 170.728 598.806 169.737C598.806 168.746 598.004 167.943 597.015 167.943C596.026 167.943 595.224 168.746 595.224 169.737C595.224 170.728 596.026 171.532 597.015 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 171.532C592.034 171.532 592.836 170.728 592.836 169.737C592.836 168.746 592.034 167.943 591.045 167.943C590.056 167.943 589.254 168.746 589.254 169.737C589.254 170.728 590.056 171.532 591.045 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 171.532C532.332 171.532 533.134 170.728 533.134 169.737C533.134 168.746 532.332 167.943 531.343 167.943C530.354 167.943 529.552 168.746 529.552 169.737C529.552 170.728 530.354 171.532 531.343 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 171.532C526.362 171.532 527.164 170.728 527.164 169.737C527.164 168.746 526.362 167.943 525.373 167.943C524.384 167.943 523.582 168.746 523.582 169.737C523.582 170.728 524.384 171.532 525.373 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 171.532C514.422 171.532 515.224 170.728 515.224 169.737C515.224 168.746 514.422 167.943 513.433 167.943C512.444 167.943 511.642 168.746 511.642 169.737C511.642 170.728 512.444 171.532 513.433 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 171.532C508.452 171.532 509.254 170.728 509.254 169.737C509.254 168.746 508.452 167.943 507.463 167.943C506.474 167.943 505.672 168.746 505.672 169.737C505.672 170.728 506.474 171.532 507.463 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 171.532C502.482 171.532 503.284 170.728 503.284 169.737C503.284 168.746 502.482 167.943 501.493 167.943C500.503 167.943 499.702 168.746 499.702 169.737C499.702 170.728 500.503 171.532 501.493 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 165.55C1039.8 165.55 1040.6 164.746 1040.6 163.755C1040.6 162.764 1039.8 161.96 1038.81 161.96C1037.82 161.96 1037.01 162.764 1037.01 163.755C1037.01 164.746 1037.82 165.55 1038.81 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 165.55C1003.97 165.55 1004.78 164.746 1004.78 163.755C1004.78 162.764 1003.97 161.96 1002.99 161.96C1002 161.96 1001.19 162.764 1001.19 163.755C1001.19 164.746 1002 165.55 1002.99 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 165.55C998.004 165.55 998.806 164.746 998.806 163.755C998.806 162.764 998.004 161.96 997.015 161.96C996.026 161.96 995.224 162.764 995.224 163.755C995.224 164.746 996.026 165.55 997.015 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 165.55C992.034 165.55 992.836 164.746 992.836 163.755C992.836 162.764 992.034 161.96 991.045 161.96C990.056 161.96 989.254 162.764 989.254 163.755C989.254 164.746 990.056 165.55 991.045 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 165.55C986.064 165.55 986.866 164.746 986.866 163.755C986.866 162.764 986.064 161.96 985.075 161.96C984.085 161.96 983.284 162.764 983.284 163.755C983.284 164.746 984.085 165.55 985.075 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 165.55C980.094 165.55 980.896 164.746 980.896 163.755C980.896 162.764 980.094 161.96 979.104 161.96C978.115 161.96 977.313 162.764 977.313 163.755C977.313 164.746 978.115 165.55 979.104 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 165.55C974.123 165.55 974.925 164.746 974.925 163.755C974.925 162.764 974.123 161.96 973.134 161.96C972.145 161.96 971.343 162.764 971.343 163.755C971.343 164.746 972.145 165.55 973.134 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 165.55C968.153 165.55 968.955 164.746 968.955 163.755C968.955 162.764 968.153 161.96 967.164 161.96C966.175 161.96 965.373 162.764 965.373 163.755C965.373 164.746 966.175 165.55 967.164 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 165.55C962.183 165.55 962.985 164.746 962.985 163.755C962.985 162.764 962.183 161.96 961.194 161.96C960.205 161.96 959.403 162.764 959.403 163.755C959.403 164.746 960.205 165.55 961.194 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 165.55C956.213 165.55 957.015 164.746 957.015 163.755C957.015 162.764 956.213 161.96 955.224 161.96C954.235 161.96 953.433 162.764 953.433 163.755C953.433 164.746 954.235 165.55 955.224 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 165.55C950.243 165.55 951.045 164.746 951.045 163.755C951.045 162.764 950.243 161.96 949.254 161.96C948.265 161.96 947.463 162.764 947.463 163.755C947.463 164.746 948.265 165.55 949.254 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 165.55C944.273 165.55 945.075 164.746 945.075 163.755C945.075 162.764 944.273 161.96 943.284 161.96C942.294 161.96 941.492 162.764 941.492 163.755C941.492 164.746 942.294 165.55 943.284 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 165.55C938.303 165.55 939.105 164.746 939.105 163.755C939.105 162.764 938.303 161.96 937.313 161.96C936.324 161.96 935.522 162.764 935.522 163.755C935.522 164.746 936.324 165.55 937.313 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 165.55C932.332 165.55 933.134 164.746 933.134 163.755C933.134 162.764 932.332 161.96 931.343 161.96C930.354 161.96 929.552 162.764 929.552 163.755C929.552 164.746 930.354 165.55 931.343 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 165.55C926.362 165.55 927.164 164.746 927.164 163.755C927.164 162.764 926.362 161.96 925.373 161.96C924.384 161.96 923.582 162.764 923.582 163.755C923.582 164.746 924.384 165.55 925.373 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 165.55C920.392 165.55 921.194 164.746 921.194 163.755C921.194 162.764 920.392 161.96 919.403 161.96C918.414 161.96 917.612 162.764 917.612 163.755C917.612 164.746 918.414 165.55 919.403 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 165.55C914.422 165.55 915.224 164.746 915.224 163.755C915.224 162.764 914.422 161.96 913.433 161.96C912.444 161.96 911.642 162.764 911.642 163.755C911.642 164.746 912.444 165.55 913.433 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 165.55C908.452 165.55 909.254 164.746 909.254 163.755C909.254 162.764 908.452 161.96 907.463 161.96C906.474 161.96 905.672 162.764 905.672 163.755C905.672 164.746 906.474 165.55 907.463 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 165.55C902.482 165.55 903.284 164.746 903.284 163.755C903.284 162.764 902.482 161.96 901.492 161.96C900.503 161.96 899.701 162.764 899.701 163.755C899.701 164.746 900.503 165.55 901.492 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 165.55C896.512 165.55 897.313 164.746 897.313 163.755C897.313 162.764 896.512 161.96 895.522 161.96C894.533 161.96 893.731 162.764 893.731 163.755C893.731 164.746 894.533 165.55 895.522 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 165.55C890.541 165.55 891.343 164.746 891.343 163.755C891.343 162.764 890.541 161.96 889.552 161.96C888.563 161.96 887.761 162.764 887.761 163.755C887.761 164.746 888.563 165.55 889.552 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 165.55C884.571 165.55 885.373 164.746 885.373 163.755C885.373 162.764 884.571 161.96 883.582 161.96C882.593 161.96 881.791 162.764 881.791 163.755C881.791 164.746 882.593 165.55 883.582 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 165.55C878.601 165.55 879.403 164.746 879.403 163.755C879.403 162.764 878.601 161.96 877.612 161.96C876.623 161.96 875.821 162.764 875.821 163.755C875.821 164.746 876.623 165.55 877.612 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 165.55C872.631 165.55 873.433 164.746 873.433 163.755C873.433 162.764 872.631 161.96 871.642 161.96C870.653 161.96 869.851 162.764 869.851 163.755C869.851 164.746 870.653 165.55 871.642 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 165.55C866.661 165.55 867.463 164.746 867.463 163.755C867.463 162.764 866.661 161.96 865.672 161.96C864.683 161.96 863.881 162.764 863.881 163.755C863.881 164.746 864.683 165.55 865.672 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 165.55C860.691 165.55 861.493 164.746 861.493 163.755C861.493 162.764 860.691 161.96 859.701 161.96C858.712 161.96 857.91 162.764 857.91 163.755C857.91 164.746 858.712 165.55 859.701 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 165.55C854.721 165.55 855.522 164.746 855.522 163.755C855.522 162.764 854.721 161.96 853.731 161.96C852.742 161.96 851.94 162.764 851.94 163.755C851.94 164.746 852.742 165.55 853.731 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 165.55C848.75 165.55 849.552 164.746 849.552 163.755C849.552 162.764 848.75 161.96 847.761 161.96C846.772 161.96 845.97 162.764 845.97 163.755C845.97 164.746 846.772 165.55 847.761 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 165.55C842.78 165.55 843.582 164.746 843.582 163.755C843.582 162.764 842.78 161.96 841.791 161.96C840.802 161.96 840 162.764 840 163.755C840 164.746 840.802 165.55 841.791 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 165.55C836.81 165.55 837.612 164.746 837.612 163.755C837.612 162.764 836.81 161.96 835.821 161.96C834.832 161.96 834.03 162.764 834.03 163.755C834.03 164.746 834.832 165.55 835.821 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 165.55C830.84 165.55 831.642 164.746 831.642 163.755C831.642 162.764 830.84 161.96 829.851 161.96C828.862 161.96 828.06 162.764 828.06 163.755C828.06 164.746 828.862 165.55 829.851 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 165.55C824.87 165.55 825.672 164.746 825.672 163.755C825.672 162.764 824.87 161.96 823.881 161.96C822.891 161.96 822.09 162.764 822.09 163.755C822.09 164.746 822.891 165.55 823.881 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 165.55C818.9 165.55 819.701 164.746 819.701 163.755C819.701 162.764 818.9 161.96 817.91 161.96C816.921 161.96 816.119 162.764 816.119 163.755C816.119 164.746 816.921 165.55 817.91 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 165.55C812.929 165.55 813.731 164.746 813.731 163.755C813.731 162.764 812.929 161.96 811.94 161.96C810.951 161.96 810.149 162.764 810.149 163.755C810.149 164.746 810.951 165.55 811.94 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 165.55C806.959 165.55 807.761 164.746 807.761 163.755C807.761 162.764 806.959 161.96 805.97 161.96C804.981 161.96 804.179 162.764 804.179 163.755C804.179 164.746 804.981 165.55 805.97 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M800 165.55C800.989 165.55 801.791 164.746 801.791 163.755C801.791 162.764 800.989 161.96 800 161.96C799.011 161.96 798.209 162.764 798.209 163.755C798.209 164.746 799.011 165.55 800 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 165.55C795.019 165.55 795.821 164.746 795.821 163.755C795.821 162.764 795.019 161.96 794.03 161.96C793.041 161.96 792.239 162.764 792.239 163.755C792.239 164.746 793.041 165.55 794.03 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 165.55C789.049 165.55 789.851 164.746 789.851 163.755C789.851 162.764 789.049 161.96 788.06 161.96C787.071 161.96 786.269 162.764 786.269 163.755C786.269 164.746 787.071 165.55 788.06 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 165.55C783.079 165.55 783.881 164.746 783.881 163.755C783.881 162.764 783.079 161.96 782.09 161.96C781.1 161.96 780.299 162.764 780.299 163.755C780.299 164.746 781.1 165.55 782.09 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 165.55C777.109 165.55 777.91 164.746 777.91 163.755C777.91 162.764 777.109 161.96 776.119 161.96C775.13 161.96 774.328 162.764 774.328 163.755C774.328 164.746 775.13 165.55 776.119 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 165.55C771.138 165.55 771.94 164.746 771.94 163.755C771.94 162.764 771.138 161.96 770.149 161.96C769.16 161.96 768.358 162.764 768.358 163.755C768.358 164.746 769.16 165.55 770.149 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 165.55C765.168 165.55 765.97 164.746 765.97 163.755C765.97 162.764 765.168 161.96 764.179 161.96C763.19 161.96 762.388 162.764 762.388 163.755C762.388 164.746 763.19 165.55 764.179 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 165.55C759.198 165.55 760 164.746 760 163.755C760 162.764 759.198 161.96 758.209 161.96C757.22 161.96 756.418 162.764 756.418 163.755C756.418 164.746 757.22 165.55 758.209 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 165.55C753.228 165.55 754.03 164.746 754.03 163.755C754.03 162.764 753.228 161.96 752.239 161.96C751.25 161.96 750.448 162.764 750.448 163.755C750.448 164.746 751.25 165.55 752.239 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 165.55C747.258 165.55 748.06 164.746 748.06 163.755C748.06 162.764 747.258 161.96 746.269 161.96C745.279 161.96 744.478 162.764 744.478 163.755C744.478 164.746 745.279 165.55 746.269 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 165.55C741.288 165.55 742.09 164.746 742.09 163.755C742.09 162.764 741.288 161.96 740.299 161.96C739.309 161.96 738.507 162.764 738.507 163.755C738.507 164.746 739.309 165.55 740.299 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 165.55C735.318 165.55 736.119 164.746 736.119 163.755C736.119 162.764 735.318 161.96 734.328 161.96C733.339 161.96 732.537 162.764 732.537 163.755C732.537 164.746 733.339 165.55 734.328 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 165.55C711.437 165.55 712.239 164.746 712.239 163.755C712.239 162.764 711.437 161.96 710.448 161.96C709.459 161.96 708.657 162.764 708.657 163.755C708.657 164.746 709.459 165.55 710.448 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 165.55C705.467 165.55 706.269 164.746 706.269 163.755C706.269 162.764 705.467 161.96 704.478 161.96C703.488 161.96 702.687 162.764 702.687 163.755C702.687 164.746 703.488 165.55 704.478 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 165.55C699.497 165.55 700.299 164.746 700.299 163.755C700.299 162.764 699.497 161.96 698.507 161.96C697.518 161.96 696.716 162.764 696.716 163.755C696.716 164.746 697.518 165.55 698.507 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 165.55C693.526 165.55 694.328 164.746 694.328 163.755C694.328 162.764 693.526 161.96 692.537 161.96C691.548 161.96 690.746 162.764 690.746 163.755C690.746 164.746 691.548 165.55 692.537 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 165.55C687.556 165.55 688.358 164.746 688.358 163.755C688.358 162.764 687.556 161.96 686.567 161.96C685.578 161.96 684.776 162.764 684.776 163.755C684.776 164.746 685.578 165.55 686.567 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 165.55C663.676 165.55 664.478 164.746 664.478 163.755C664.478 162.764 663.676 161.96 662.687 161.96C661.697 161.96 660.896 162.764 660.896 163.755C660.896 164.746 661.697 165.55 662.687 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 165.55C657.706 165.55 658.507 164.746 658.507 163.755C658.507 162.764 657.706 161.96 656.716 161.96C655.727 161.96 654.925 162.764 654.925 163.755C654.925 164.746 655.727 165.55 656.716 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 165.55C651.735 165.55 652.537 164.746 652.537 163.755C652.537 162.764 651.735 161.96 650.746 161.96C649.757 161.96 648.955 162.764 648.955 163.755C648.955 164.746 649.757 165.55 650.746 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 165.55C633.825 165.55 634.627 164.746 634.627 163.755C634.627 162.764 633.825 161.96 632.836 161.96C631.847 161.96 631.045 162.764 631.045 163.755C631.045 164.746 631.847 165.55 632.836 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 165.55C627.855 165.55 628.657 164.746 628.657 163.755C628.657 162.764 627.855 161.96 626.866 161.96C625.876 161.96 625.075 162.764 625.075 163.755C625.075 164.746 625.876 165.55 626.866 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 165.55C621.885 165.55 622.687 164.746 622.687 163.755C622.687 162.764 621.885 161.96 620.895 161.96C619.906 161.96 619.104 162.764 619.104 163.755C619.104 164.746 619.906 165.55 620.895 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 165.55C615.915 165.55 616.716 164.746 616.716 163.755C616.716 162.764 615.915 161.96 614.925 161.96C613.936 161.96 613.134 162.764 613.134 163.755C613.134 164.746 613.936 165.55 614.925 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 165.55C609.944 165.55 610.746 164.746 610.746 163.755C610.746 162.764 609.944 161.96 608.955 161.96C607.966 161.96 607.164 162.764 607.164 163.755C607.164 164.746 607.966 165.55 608.955 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 165.55C603.974 165.55 604.776 164.746 604.776 163.755C604.776 162.764 603.974 161.96 602.985 161.96C601.996 161.96 601.194 162.764 601.194 163.755C601.194 164.746 601.996 165.55 602.985 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 165.55C592.034 165.55 592.836 164.746 592.836 163.755C592.836 162.764 592.034 161.96 591.045 161.96C590.056 161.96 589.254 162.764 589.254 163.755C589.254 164.746 590.056 165.55 591.045 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 165.55C586.064 165.55 586.866 164.746 586.866 163.755C586.866 162.764 586.064 161.96 585.075 161.96C584.085 161.96 583.284 162.764 583.284 163.755C583.284 164.746 584.085 165.55 585.075 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 165.55C568.153 165.55 568.955 164.746 568.955 163.755C568.955 162.764 568.153 161.96 567.164 161.96C566.175 161.96 565.373 162.764 565.373 163.755C565.373 164.746 566.175 165.55 567.164 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 165.55C532.332 165.55 533.134 164.746 533.134 163.755C533.134 162.764 532.332 161.96 531.343 161.96C530.354 161.96 529.552 162.764 529.552 163.755C529.552 164.746 530.354 165.55 531.343 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 165.55C526.362 165.55 527.164 164.746 527.164 163.755C527.164 162.764 526.362 161.96 525.373 161.96C524.384 161.96 523.582 162.764 523.582 163.755C523.582 164.746 524.384 165.55 525.373 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 165.55C520.392 165.55 521.194 164.746 521.194 163.755C521.194 162.764 520.392 161.96 519.403 161.96C518.414 161.96 517.612 162.764 517.612 163.755C517.612 164.746 518.414 165.55 519.403 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 165.55C514.422 165.55 515.224 164.746 515.224 163.755C515.224 162.764 514.422 161.96 513.433 161.96C512.444 161.96 511.642 162.764 511.642 163.755C511.642 164.746 512.444 165.55 513.433 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 159.567C1051.74 159.567 1052.54 158.764 1052.54 157.773C1052.54 156.782 1051.74 155.978 1050.75 155.978C1049.76 155.978 1048.96 156.782 1048.96 157.773C1048.96 158.764 1049.76 159.567 1050.75 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 159.567C1045.77 159.567 1046.57 158.764 1046.57 157.773C1046.57 156.782 1045.77 155.978 1044.78 155.978C1043.79 155.978 1042.99 156.782 1042.99 157.773C1042.99 158.764 1043.79 159.567 1044.78 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 159.567C1039.8 159.567 1040.6 158.764 1040.6 157.773C1040.6 156.782 1039.8 155.978 1038.81 155.978C1037.82 155.978 1037.01 156.782 1037.01 157.773C1037.01 158.764 1037.82 159.567 1038.81 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 159.567C1015.91 159.567 1016.72 158.764 1016.72 157.773C1016.72 156.782 1015.91 155.978 1014.93 155.978C1013.94 155.978 1013.13 156.782 1013.13 157.773C1013.13 158.764 1013.94 159.567 1014.93 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 159.567C1009.94 159.567 1010.75 158.764 1010.75 157.773C1010.75 156.782 1009.94 155.978 1008.96 155.978C1007.97 155.978 1007.16 156.782 1007.16 157.773C1007.16 158.764 1007.97 159.567 1008.96 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 159.567C1003.97 159.567 1004.78 158.764 1004.78 157.773C1004.78 156.782 1003.97 155.978 1002.99 155.978C1002 155.978 1001.19 156.782 1001.19 157.773C1001.19 158.764 1002 159.567 1002.99 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 159.567C998.004 159.567 998.806 158.764 998.806 157.773C998.806 156.782 998.004 155.978 997.015 155.978C996.026 155.978 995.224 156.782 995.224 157.773C995.224 158.764 996.026 159.567 997.015 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 159.567C992.034 159.567 992.836 158.764 992.836 157.773C992.836 156.782 992.034 155.978 991.045 155.978C990.056 155.978 989.254 156.782 989.254 157.773C989.254 158.764 990.056 159.567 991.045 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 159.567C986.064 159.567 986.866 158.764 986.866 157.773C986.866 156.782 986.064 155.978 985.075 155.978C984.085 155.978 983.284 156.782 983.284 157.773C983.284 158.764 984.085 159.567 985.075 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 159.567C980.094 159.567 980.896 158.764 980.896 157.773C980.896 156.782 980.094 155.978 979.104 155.978C978.115 155.978 977.313 156.782 977.313 157.773C977.313 158.764 978.115 159.567 979.104 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 159.567C974.123 159.567 974.925 158.764 974.925 157.773C974.925 156.782 974.123 155.978 973.134 155.978C972.145 155.978 971.343 156.782 971.343 157.773C971.343 158.764 972.145 159.567 973.134 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 159.567C968.153 159.567 968.955 158.764 968.955 157.773C968.955 156.782 968.153 155.978 967.164 155.978C966.175 155.978 965.373 156.782 965.373 157.773C965.373 158.764 966.175 159.567 967.164 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 159.567C962.183 159.567 962.985 158.764 962.985 157.773C962.985 156.782 962.183 155.978 961.194 155.978C960.205 155.978 959.403 156.782 959.403 157.773C959.403 158.764 960.205 159.567 961.194 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 159.567C956.213 159.567 957.015 158.764 957.015 157.773C957.015 156.782 956.213 155.978 955.224 155.978C954.235 155.978 953.433 156.782 953.433 157.773C953.433 158.764 954.235 159.567 955.224 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 159.567C950.243 159.567 951.045 158.764 951.045 157.773C951.045 156.782 950.243 155.978 949.254 155.978C948.265 155.978 947.463 156.782 947.463 157.773C947.463 158.764 948.265 159.567 949.254 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 159.567C944.273 159.567 945.075 158.764 945.075 157.773C945.075 156.782 944.273 155.978 943.284 155.978C942.294 155.978 941.492 156.782 941.492 157.773C941.492 158.764 942.294 159.567 943.284 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 159.567C938.303 159.567 939.105 158.764 939.105 157.773C939.105 156.782 938.303 155.978 937.313 155.978C936.324 155.978 935.522 156.782 935.522 157.773C935.522 158.764 936.324 159.567 937.313 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 159.567C932.332 159.567 933.134 158.764 933.134 157.773C933.134 156.782 932.332 155.978 931.343 155.978C930.354 155.978 929.552 156.782 929.552 157.773C929.552 158.764 930.354 159.567 931.343 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 159.567C926.362 159.567 927.164 158.764 927.164 157.773C927.164 156.782 926.362 155.978 925.373 155.978C924.384 155.978 923.582 156.782 923.582 157.773C923.582 158.764 924.384 159.567 925.373 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 159.567C920.392 159.567 921.194 158.764 921.194 157.773C921.194 156.782 920.392 155.978 919.403 155.978C918.414 155.978 917.612 156.782 917.612 157.773C917.612 158.764 918.414 159.567 919.403 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 159.567C914.422 159.567 915.224 158.764 915.224 157.773C915.224 156.782 914.422 155.978 913.433 155.978C912.444 155.978 911.642 156.782 911.642 157.773C911.642 158.764 912.444 159.567 913.433 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 159.567C908.452 159.567 909.254 158.764 909.254 157.773C909.254 156.782 908.452 155.978 907.463 155.978C906.474 155.978 905.672 156.782 905.672 157.773C905.672 158.764 906.474 159.567 907.463 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 159.567C902.482 159.567 903.284 158.764 903.284 157.773C903.284 156.782 902.482 155.978 901.492 155.978C900.503 155.978 899.701 156.782 899.701 157.773C899.701 158.764 900.503 159.567 901.492 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 159.567C896.512 159.567 897.313 158.764 897.313 157.773C897.313 156.782 896.512 155.978 895.522 155.978C894.533 155.978 893.731 156.782 893.731 157.773C893.731 158.764 894.533 159.567 895.522 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 159.567C890.541 159.567 891.343 158.764 891.343 157.773C891.343 156.782 890.541 155.978 889.552 155.978C888.563 155.978 887.761 156.782 887.761 157.773C887.761 158.764 888.563 159.567 889.552 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 159.567C884.571 159.567 885.373 158.764 885.373 157.773C885.373 156.782 884.571 155.978 883.582 155.978C882.593 155.978 881.791 156.782 881.791 157.773C881.791 158.764 882.593 159.567 883.582 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 159.567C878.601 159.567 879.403 158.764 879.403 157.773C879.403 156.782 878.601 155.978 877.612 155.978C876.623 155.978 875.821 156.782 875.821 157.773C875.821 158.764 876.623 159.567 877.612 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 159.567C872.631 159.567 873.433 158.764 873.433 157.773C873.433 156.782 872.631 155.978 871.642 155.978C870.653 155.978 869.851 156.782 869.851 157.773C869.851 158.764 870.653 159.567 871.642 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 159.567C866.661 159.567 867.463 158.764 867.463 157.773C867.463 156.782 866.661 155.978 865.672 155.978C864.683 155.978 863.881 156.782 863.881 157.773C863.881 158.764 864.683 159.567 865.672 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 159.567C860.691 159.567 861.493 158.764 861.493 157.773C861.493 156.782 860.691 155.978 859.701 155.978C858.712 155.978 857.91 156.782 857.91 157.773C857.91 158.764 858.712 159.567 859.701 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 159.567C854.721 159.567 855.522 158.764 855.522 157.773C855.522 156.782 854.721 155.978 853.731 155.978C852.742 155.978 851.94 156.782 851.94 157.773C851.94 158.764 852.742 159.567 853.731 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 159.567C848.75 159.567 849.552 158.764 849.552 157.773C849.552 156.782 848.75 155.978 847.761 155.978C846.772 155.978 845.97 156.782 845.97 157.773C845.97 158.764 846.772 159.567 847.761 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 159.567C842.78 159.567 843.582 158.764 843.582 157.773C843.582 156.782 842.78 155.978 841.791 155.978C840.802 155.978 840 156.782 840 157.773C840 158.764 840.802 159.567 841.791 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 159.567C836.81 159.567 837.612 158.764 837.612 157.773C837.612 156.782 836.81 155.978 835.821 155.978C834.832 155.978 834.03 156.782 834.03 157.773C834.03 158.764 834.832 159.567 835.821 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 159.567C830.84 159.567 831.642 158.764 831.642 157.773C831.642 156.782 830.84 155.978 829.851 155.978C828.862 155.978 828.06 156.782 828.06 157.773C828.06 158.764 828.862 159.567 829.851 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 159.567C824.87 159.567 825.672 158.764 825.672 157.773C825.672 156.782 824.87 155.978 823.881 155.978C822.891 155.978 822.09 156.782 822.09 157.773C822.09 158.764 822.891 159.567 823.881 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 159.567C818.9 159.567 819.701 158.764 819.701 157.773C819.701 156.782 818.9 155.978 817.91 155.978C816.921 155.978 816.119 156.782 816.119 157.773C816.119 158.764 816.921 159.567 817.91 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 159.567C812.929 159.567 813.731 158.764 813.731 157.773C813.731 156.782 812.929 155.978 811.94 155.978C810.951 155.978 810.149 156.782 810.149 157.773C810.149 158.764 810.951 159.567 811.94 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 159.567C806.959 159.567 807.761 158.764 807.761 157.773C807.761 156.782 806.959 155.978 805.97 155.978C804.981 155.978 804.179 156.782 804.179 157.773C804.179 158.764 804.981 159.567 805.97 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M800 159.567C800.989 159.567 801.791 158.764 801.791 157.773C801.791 156.782 800.989 155.978 800 155.978C799.011 155.978 798.209 156.782 798.209 157.773C798.209 158.764 799.011 159.567 800 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 159.567C795.019 159.567 795.821 158.764 795.821 157.773C795.821 156.782 795.019 155.978 794.03 155.978C793.041 155.978 792.239 156.782 792.239 157.773C792.239 158.764 793.041 159.567 794.03 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 159.567C789.049 159.567 789.851 158.764 789.851 157.773C789.851 156.782 789.049 155.978 788.06 155.978C787.071 155.978 786.269 156.782 786.269 157.773C786.269 158.764 787.071 159.567 788.06 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 159.567C783.079 159.567 783.881 158.764 783.881 157.773C783.881 156.782 783.079 155.978 782.09 155.978C781.1 155.978 780.299 156.782 780.299 157.773C780.299 158.764 781.1 159.567 782.09 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 159.567C777.109 159.567 777.91 158.764 777.91 157.773C777.91 156.782 777.109 155.978 776.119 155.978C775.13 155.978 774.328 156.782 774.328 157.773C774.328 158.764 775.13 159.567 776.119 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 159.567C771.138 159.567 771.94 158.764 771.94 157.773C771.94 156.782 771.138 155.978 770.149 155.978C769.16 155.978 768.358 156.782 768.358 157.773C768.358 158.764 769.16 159.567 770.149 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 159.567C765.168 159.567 765.97 158.764 765.97 157.773C765.97 156.782 765.168 155.978 764.179 155.978C763.19 155.978 762.388 156.782 762.388 157.773C762.388 158.764 763.19 159.567 764.179 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 159.567C759.198 159.567 760 158.764 760 157.773C760 156.782 759.198 155.978 758.209 155.978C757.22 155.978 756.418 156.782 756.418 157.773C756.418 158.764 757.22 159.567 758.209 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 159.567C753.228 159.567 754.03 158.764 754.03 157.773C754.03 156.782 753.228 155.978 752.239 155.978C751.25 155.978 750.448 156.782 750.448 157.773C750.448 158.764 751.25 159.567 752.239 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 159.567C747.258 159.567 748.06 158.764 748.06 157.773C748.06 156.782 747.258 155.978 746.269 155.978C745.279 155.978 744.478 156.782 744.478 157.773C744.478 158.764 745.279 159.567 746.269 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 159.567C741.288 159.567 742.09 158.764 742.09 157.773C742.09 156.782 741.288 155.978 740.299 155.978C739.309 155.978 738.507 156.782 738.507 157.773C738.507 158.764 739.309 159.567 740.299 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 159.567C735.318 159.567 736.119 158.764 736.119 157.773C736.119 156.782 735.318 155.978 734.328 155.978C733.339 155.978 732.537 156.782 732.537 157.773C732.537 158.764 733.339 159.567 734.328 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 159.567C729.347 159.567 730.149 158.764 730.149 157.773C730.149 156.782 729.347 155.978 728.358 155.978C727.369 155.978 726.567 156.782 726.567 157.773C726.567 158.764 727.369 159.567 728.358 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 159.567C723.377 159.567 724.179 158.764 724.179 157.773C724.179 156.782 723.377 155.978 722.388 155.978C721.399 155.978 720.597 156.782 720.597 157.773C720.597 158.764 721.399 159.567 722.388 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 159.567C705.467 159.567 706.269 158.764 706.269 157.773C706.269 156.782 705.467 155.978 704.478 155.978C703.488 155.978 702.687 156.782 702.687 157.773C702.687 158.764 703.488 159.567 704.478 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 159.567C699.497 159.567 700.299 158.764 700.299 157.773C700.299 156.782 699.497 155.978 698.507 155.978C697.518 155.978 696.716 156.782 696.716 157.773C696.716 158.764 697.518 159.567 698.507 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 159.567C693.526 159.567 694.328 158.764 694.328 157.773C694.328 156.782 693.526 155.978 692.537 155.978C691.548 155.978 690.746 156.782 690.746 157.773C690.746 158.764 691.548 159.567 692.537 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 159.567C687.556 159.567 688.358 158.764 688.358 157.773C688.358 156.782 687.556 155.978 686.567 155.978C685.578 155.978 684.776 156.782 684.776 157.773C684.776 158.764 685.578 159.567 686.567 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 159.567C681.586 159.567 682.388 158.764 682.388 157.773C682.388 156.782 681.586 155.978 680.597 155.978C679.608 155.978 678.806 156.782 678.806 157.773C678.806 158.764 679.608 159.567 680.597 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 159.567C633.825 159.567 634.627 158.764 634.627 157.773C634.627 156.782 633.825 155.978 632.836 155.978C631.847 155.978 631.045 156.782 631.045 157.773C631.045 158.764 631.847 159.567 632.836 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 159.567C627.855 159.567 628.657 158.764 628.657 157.773C628.657 156.782 627.855 155.978 626.866 155.978C625.876 155.978 625.075 156.782 625.075 157.773C625.075 158.764 625.876 159.567 626.866 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 159.567C621.885 159.567 622.687 158.764 622.687 157.773C622.687 156.782 621.885 155.978 620.895 155.978C619.906 155.978 619.104 156.782 619.104 157.773C619.104 158.764 619.906 159.567 620.895 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 159.567C615.915 159.567 616.716 158.764 616.716 157.773C616.716 156.782 615.915 155.978 614.925 155.978C613.936 155.978 613.134 156.782 613.134 157.773C613.134 158.764 613.936 159.567 614.925 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 159.567C609.944 159.567 610.746 158.764 610.746 157.773C610.746 156.782 609.944 155.978 608.955 155.978C607.966 155.978 607.164 156.782 607.164 157.773C607.164 158.764 607.966 159.567 608.955 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 159.567C603.974 159.567 604.776 158.764 604.776 157.773C604.776 156.782 603.974 155.978 602.985 155.978C601.996 155.978 601.194 156.782 601.194 157.773C601.194 158.764 601.996 159.567 602.985 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 159.567C598.004 159.567 598.806 158.764 598.806 157.773C598.806 156.782 598.004 155.978 597.015 155.978C596.026 155.978 595.224 156.782 595.224 157.773C595.224 158.764 596.026 159.567 597.015 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 159.567C586.064 159.567 586.866 158.764 586.866 157.773C586.866 156.782 586.064 155.978 585.075 155.978C584.085 155.978 583.284 156.782 583.284 157.773C583.284 158.764 584.085 159.567 585.075 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 159.567C580.094 159.567 580.896 158.764 580.896 157.773C580.896 156.782 580.094 155.978 579.104 155.978C578.115 155.978 577.313 156.782 577.313 157.773C577.313 158.764 578.115 159.567 579.104 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 159.567C556.213 159.567 557.015 158.764 557.015 157.773C557.015 156.782 556.213 155.978 555.224 155.978C554.235 155.978 553.433 156.782 553.433 157.773C553.433 158.764 554.235 159.567 555.224 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 159.567C544.273 159.567 545.075 158.764 545.075 157.773C545.075 156.782 544.273 155.978 543.284 155.978C542.294 155.978 541.492 156.782 541.492 157.773C541.492 158.764 542.294 159.567 543.284 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 159.567C538.303 159.567 539.104 158.764 539.104 157.773C539.104 156.782 538.303 155.978 537.313 155.978C536.324 155.978 535.522 156.782 535.522 157.773C535.522 158.764 536.324 159.567 537.313 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 159.567C532.332 159.567 533.134 158.764 533.134 157.773C533.134 156.782 532.332 155.978 531.343 155.978C530.354 155.978 529.552 156.782 529.552 157.773C529.552 158.764 530.354 159.567 531.343 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 159.567C526.362 159.567 527.164 158.764 527.164 157.773C527.164 156.782 526.362 155.978 525.373 155.978C524.384 155.978 523.582 156.782 523.582 157.773C523.582 158.764 524.384 159.567 525.373 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 159.567C520.392 159.567 521.194 158.764 521.194 157.773C521.194 156.782 520.392 155.978 519.403 155.978C518.414 155.978 517.612 156.782 517.612 157.773C517.612 158.764 518.414 159.567 519.403 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 159.567C514.422 159.567 515.224 158.764 515.224 157.773C515.224 156.782 514.422 155.978 513.433 155.978C512.444 155.978 511.642 156.782 511.642 157.773C511.642 158.764 512.444 159.567 513.433 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 159.567C508.452 159.567 509.254 158.764 509.254 157.773C509.254 156.782 508.452 155.978 507.463 155.978C506.474 155.978 505.672 156.782 505.672 157.773C505.672 158.764 506.474 159.567 507.463 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 153.585C1039.8 153.585 1040.6 152.782 1040.6 151.791C1040.6 150.799 1039.8 149.996 1038.81 149.996C1037.82 149.996 1037.01 150.799 1037.01 151.791C1037.01 152.782 1037.82 153.585 1038.81 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 153.585C1015.91 153.585 1016.72 152.782 1016.72 151.791C1016.72 150.799 1015.91 149.996 1014.93 149.996C1013.94 149.996 1013.13 150.799 1013.13 151.791C1013.13 152.782 1013.94 153.585 1014.93 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 153.585C1009.94 153.585 1010.75 152.782 1010.75 151.791C1010.75 150.799 1009.94 149.996 1008.96 149.996C1007.97 149.996 1007.16 150.799 1007.16 151.791C1007.16 152.782 1007.97 153.585 1008.96 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 153.585C1003.97 153.585 1004.78 152.782 1004.78 151.791C1004.78 150.799 1003.97 149.996 1002.99 149.996C1002 149.996 1001.19 150.799 1001.19 151.791C1001.19 152.782 1002 153.585 1002.99 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 153.585C998.004 153.585 998.806 152.782 998.806 151.791C998.806 150.799 998.004 149.996 997.015 149.996C996.026 149.996 995.224 150.799 995.224 151.791C995.224 152.782 996.026 153.585 997.015 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 153.585C992.034 153.585 992.836 152.782 992.836 151.791C992.836 150.799 992.034 149.996 991.045 149.996C990.056 149.996 989.254 150.799 989.254 151.791C989.254 152.782 990.056 153.585 991.045 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 153.585C986.064 153.585 986.866 152.782 986.866 151.791C986.866 150.799 986.064 149.996 985.075 149.996C984.085 149.996 983.284 150.799 983.284 151.791C983.284 152.782 984.085 153.585 985.075 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 153.585C980.094 153.585 980.896 152.782 980.896 151.791C980.896 150.799 980.094 149.996 979.104 149.996C978.115 149.996 977.313 150.799 977.313 151.791C977.313 152.782 978.115 153.585 979.104 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 153.585C974.123 153.585 974.925 152.782 974.925 151.791C974.925 150.799 974.123 149.996 973.134 149.996C972.145 149.996 971.343 150.799 971.343 151.791C971.343 152.782 972.145 153.585 973.134 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 153.585C968.153 153.585 968.955 152.782 968.955 151.791C968.955 150.799 968.153 149.996 967.164 149.996C966.175 149.996 965.373 150.799 965.373 151.791C965.373 152.782 966.175 153.585 967.164 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 153.585C962.183 153.585 962.985 152.782 962.985 151.791C962.985 150.799 962.183 149.996 961.194 149.996C960.205 149.996 959.403 150.799 959.403 151.791C959.403 152.782 960.205 153.585 961.194 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 153.585C956.213 153.585 957.015 152.782 957.015 151.791C957.015 150.799 956.213 149.996 955.224 149.996C954.235 149.996 953.433 150.799 953.433 151.791C953.433 152.782 954.235 153.585 955.224 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 153.585C950.243 153.585 951.045 152.782 951.045 151.791C951.045 150.799 950.243 149.996 949.254 149.996C948.265 149.996 947.463 150.799 947.463 151.791C947.463 152.782 948.265 153.585 949.254 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 153.585C944.273 153.585 945.075 152.782 945.075 151.791C945.075 150.799 944.273 149.996 943.284 149.996C942.294 149.996 941.492 150.799 941.492 151.791C941.492 152.782 942.294 153.585 943.284 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 153.585C938.303 153.585 939.105 152.782 939.105 151.791C939.105 150.799 938.303 149.996 937.313 149.996C936.324 149.996 935.522 150.799 935.522 151.791C935.522 152.782 936.324 153.585 937.313 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 153.585C932.332 153.585 933.134 152.782 933.134 151.791C933.134 150.799 932.332 149.996 931.343 149.996C930.354 149.996 929.552 150.799 929.552 151.791C929.552 152.782 930.354 153.585 931.343 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 153.585C926.362 153.585 927.164 152.782 927.164 151.791C927.164 150.799 926.362 149.996 925.373 149.996C924.384 149.996 923.582 150.799 923.582 151.791C923.582 152.782 924.384 153.585 925.373 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 153.585C920.392 153.585 921.194 152.782 921.194 151.791C921.194 150.799 920.392 149.996 919.403 149.996C918.414 149.996 917.612 150.799 917.612 151.791C917.612 152.782 918.414 153.585 919.403 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 153.585C914.422 153.585 915.224 152.782 915.224 151.791C915.224 150.799 914.422 149.996 913.433 149.996C912.444 149.996 911.642 150.799 911.642 151.791C911.642 152.782 912.444 153.585 913.433 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 153.585C908.452 153.585 909.254 152.782 909.254 151.791C909.254 150.799 908.452 149.996 907.463 149.996C906.474 149.996 905.672 150.799 905.672 151.791C905.672 152.782 906.474 153.585 907.463 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 153.585C902.482 153.585 903.284 152.782 903.284 151.791C903.284 150.799 902.482 149.996 901.492 149.996C900.503 149.996 899.701 150.799 899.701 151.791C899.701 152.782 900.503 153.585 901.492 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 153.585C896.512 153.585 897.313 152.782 897.313 151.791C897.313 150.799 896.512 149.996 895.522 149.996C894.533 149.996 893.731 150.799 893.731 151.791C893.731 152.782 894.533 153.585 895.522 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 153.585C890.541 153.585 891.343 152.782 891.343 151.791C891.343 150.799 890.541 149.996 889.552 149.996C888.563 149.996 887.761 150.799 887.761 151.791C887.761 152.782 888.563 153.585 889.552 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 153.585C884.571 153.585 885.373 152.782 885.373 151.791C885.373 150.799 884.571 149.996 883.582 149.996C882.593 149.996 881.791 150.799 881.791 151.791C881.791 152.782 882.593 153.585 883.582 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 153.585C878.601 153.585 879.403 152.782 879.403 151.791C879.403 150.799 878.601 149.996 877.612 149.996C876.623 149.996 875.821 150.799 875.821 151.791C875.821 152.782 876.623 153.585 877.612 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 153.585C872.631 153.585 873.433 152.782 873.433 151.791C873.433 150.799 872.631 149.996 871.642 149.996C870.653 149.996 869.851 150.799 869.851 151.791C869.851 152.782 870.653 153.585 871.642 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 153.585C866.661 153.585 867.463 152.782 867.463 151.791C867.463 150.799 866.661 149.996 865.672 149.996C864.683 149.996 863.881 150.799 863.881 151.791C863.881 152.782 864.683 153.585 865.672 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 153.585C860.691 153.585 861.493 152.782 861.493 151.791C861.493 150.799 860.691 149.996 859.701 149.996C858.712 149.996 857.91 150.799 857.91 151.791C857.91 152.782 858.712 153.585 859.701 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 153.585C854.721 153.585 855.522 152.782 855.522 151.791C855.522 150.799 854.721 149.996 853.731 149.996C852.742 149.996 851.94 150.799 851.94 151.791C851.94 152.782 852.742 153.585 853.731 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 153.585C848.75 153.585 849.552 152.782 849.552 151.791C849.552 150.799 848.75 149.996 847.761 149.996C846.772 149.996 845.97 150.799 845.97 151.791C845.97 152.782 846.772 153.585 847.761 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 153.585C842.78 153.585 843.582 152.782 843.582 151.791C843.582 150.799 842.78 149.996 841.791 149.996C840.802 149.996 840 150.799 840 151.791C840 152.782 840.802 153.585 841.791 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 153.585C836.81 153.585 837.612 152.782 837.612 151.791C837.612 150.799 836.81 149.996 835.821 149.996C834.832 149.996 834.03 150.799 834.03 151.791C834.03 152.782 834.832 153.585 835.821 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 153.585C830.84 153.585 831.642 152.782 831.642 151.791C831.642 150.799 830.84 149.996 829.851 149.996C828.862 149.996 828.06 150.799 828.06 151.791C828.06 152.782 828.862 153.585 829.851 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 153.585C824.87 153.585 825.672 152.782 825.672 151.791C825.672 150.799 824.87 149.996 823.881 149.996C822.891 149.996 822.09 150.799 822.09 151.791C822.09 152.782 822.891 153.585 823.881 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 153.585C812.929 153.585 813.731 152.782 813.731 151.791C813.731 150.799 812.929 149.996 811.94 149.996C810.951 149.996 810.149 150.799 810.149 151.791C810.149 152.782 810.951 153.585 811.94 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 153.585C806.959 153.585 807.761 152.782 807.761 151.791C807.761 150.799 806.959 149.996 805.97 149.996C804.981 149.996 804.179 150.799 804.179 151.791C804.179 152.782 804.981 153.585 805.97 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M800 153.585C800.989 153.585 801.791 152.782 801.791 151.791C801.791 150.799 800.989 149.996 800 149.996C799.011 149.996 798.209 150.799 798.209 151.791C798.209 152.782 799.011 153.585 800 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 153.585C795.019 153.585 795.821 152.782 795.821 151.791C795.821 150.799 795.019 149.996 794.03 149.996C793.041 149.996 792.239 150.799 792.239 151.791C792.239 152.782 793.041 153.585 794.03 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 153.585C789.049 153.585 789.851 152.782 789.851 151.791C789.851 150.799 789.049 149.996 788.06 149.996C787.071 149.996 786.269 150.799 786.269 151.791C786.269 152.782 787.071 153.585 788.06 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 153.585C783.079 153.585 783.881 152.782 783.881 151.791C783.881 150.799 783.079 149.996 782.09 149.996C781.1 149.996 780.299 150.799 780.299 151.791C780.299 152.782 781.1 153.585 782.09 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 153.585C777.109 153.585 777.91 152.782 777.91 151.791C777.91 150.799 777.109 149.996 776.119 149.996C775.13 149.996 774.328 150.799 774.328 151.791C774.328 152.782 775.13 153.585 776.119 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 153.585C771.138 153.585 771.94 152.782 771.94 151.791C771.94 150.799 771.138 149.996 770.149 149.996C769.16 149.996 768.358 150.799 768.358 151.791C768.358 152.782 769.16 153.585 770.149 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 153.585C765.168 153.585 765.97 152.782 765.97 151.791C765.97 150.799 765.168 149.996 764.179 149.996C763.19 149.996 762.388 150.799 762.388 151.791C762.388 152.782 763.19 153.585 764.179 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 153.585C759.198 153.585 760 152.782 760 151.791C760 150.799 759.198 149.996 758.209 149.996C757.22 149.996 756.418 150.799 756.418 151.791C756.418 152.782 757.22 153.585 758.209 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 153.585C753.228 153.585 754.03 152.782 754.03 151.791C754.03 150.799 753.228 149.996 752.239 149.996C751.25 149.996 750.448 150.799 750.448 151.791C750.448 152.782 751.25 153.585 752.239 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 153.585C747.258 153.585 748.06 152.782 748.06 151.791C748.06 150.799 747.258 149.996 746.269 149.996C745.279 149.996 744.478 150.799 744.478 151.791C744.478 152.782 745.279 153.585 746.269 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 153.585C741.288 153.585 742.09 152.782 742.09 151.791C742.09 150.799 741.288 149.996 740.299 149.996C739.309 149.996 738.507 150.799 738.507 151.791C738.507 152.782 739.309 153.585 740.299 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 153.585C735.318 153.585 736.119 152.782 736.119 151.791C736.119 150.799 735.318 149.996 734.328 149.996C733.339 149.996 732.537 150.799 732.537 151.791C732.537 152.782 733.339 153.585 734.328 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 153.585C729.347 153.585 730.149 152.782 730.149 151.791C730.149 150.799 729.347 149.996 728.358 149.996C727.369 149.996 726.567 150.799 726.567 151.791C726.567 152.782 727.369 153.585 728.358 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 153.585C723.377 153.585 724.179 152.782 724.179 151.791C724.179 150.799 723.377 149.996 722.388 149.996C721.399 149.996 720.597 150.799 720.597 151.791C720.597 152.782 721.399 153.585 722.388 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 153.585C717.407 153.585 718.209 152.782 718.209 151.791C718.209 150.799 717.407 149.996 716.418 149.996C715.429 149.996 714.627 150.799 714.627 151.791C714.627 152.782 715.429 153.585 716.418 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 153.585C699.497 153.585 700.299 152.782 700.299 151.791C700.299 150.799 699.497 149.996 698.507 149.996C697.518 149.996 696.716 150.799 696.716 151.791C696.716 152.782 697.518 153.585 698.507 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 153.585C693.526 153.585 694.328 152.782 694.328 151.791C694.328 150.799 693.526 149.996 692.537 149.996C691.548 149.996 690.746 150.799 690.746 151.791C690.746 152.782 691.548 153.585 692.537 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 153.585C687.556 153.585 688.358 152.782 688.358 151.791C688.358 150.799 687.556 149.996 686.567 149.996C685.578 149.996 684.776 150.799 684.776 151.791C684.776 152.782 685.578 153.585 686.567 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 153.585C681.586 153.585 682.388 152.782 682.388 151.791C682.388 150.799 681.586 149.996 680.597 149.996C679.608 149.996 678.806 150.799 678.806 151.791C678.806 152.782 679.608 153.585 680.597 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 153.585C675.616 153.585 676.418 152.782 676.418 151.791C676.418 150.799 675.616 149.996 674.627 149.996C673.638 149.996 672.836 150.799 672.836 151.791C672.836 152.782 673.638 153.585 674.627 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 153.585C657.706 153.585 658.507 152.782 658.507 151.791C658.507 150.799 657.706 149.996 656.716 149.996C655.727 149.996 654.925 150.799 654.925 151.791C654.925 152.782 655.727 153.585 656.716 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 153.585C639.795 153.585 640.597 152.782 640.597 151.791C640.597 150.799 639.795 149.996 638.806 149.996C637.817 149.996 637.015 150.799 637.015 151.791C637.015 152.782 637.817 153.585 638.806 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 153.585C633.825 153.585 634.627 152.782 634.627 151.791C634.627 150.799 633.825 149.996 632.836 149.996C631.847 149.996 631.045 150.799 631.045 151.791C631.045 152.782 631.847 153.585 632.836 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 153.585C627.855 153.585 628.657 152.782 628.657 151.791C628.657 150.799 627.855 149.996 626.866 149.996C625.876 149.996 625.075 150.799 625.075 151.791C625.075 152.782 625.876 153.585 626.866 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 153.585C621.885 153.585 622.687 152.782 622.687 151.791C622.687 150.799 621.885 149.996 620.895 149.996C619.906 149.996 619.104 150.799 619.104 151.791C619.104 152.782 619.906 153.585 620.895 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 153.585C615.915 153.585 616.716 152.782 616.716 151.791C616.716 150.799 615.915 149.996 614.925 149.996C613.936 149.996 613.134 150.799 613.134 151.791C613.134 152.782 613.936 153.585 614.925 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 153.585C609.944 153.585 610.746 152.782 610.746 151.791C610.746 150.799 609.944 149.996 608.955 149.996C607.966 149.996 607.164 150.799 607.164 151.791C607.164 152.782 607.966 153.585 608.955 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 153.585C603.974 153.585 604.776 152.782 604.776 151.791C604.776 150.799 603.974 149.996 602.985 149.996C601.996 149.996 601.194 150.799 601.194 151.791C601.194 152.782 601.996 153.585 602.985 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 153.585C598.004 153.585 598.806 152.782 598.806 151.791C598.806 150.799 598.004 149.996 597.015 149.996C596.026 149.996 595.224 150.799 595.224 151.791C595.224 152.782 596.026 153.585 597.015 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 153.585C592.034 153.585 592.836 152.782 592.836 151.791C592.836 150.799 592.034 149.996 591.045 149.996C590.056 149.996 589.254 150.799 589.254 151.791C589.254 152.782 590.056 153.585 591.045 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 153.585C580.094 153.585 580.896 152.782 580.896 151.791C580.896 150.799 580.094 149.996 579.104 149.996C578.115 149.996 577.313 150.799 577.313 151.791C577.313 152.782 578.115 153.585 579.104 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 153.585C574.123 153.585 574.925 152.782 574.925 151.791C574.925 150.799 574.123 149.996 573.134 149.996C572.145 149.996 571.343 150.799 571.343 151.791C571.343 152.782 572.145 153.585 573.134 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 153.585C568.153 153.585 568.955 152.782 568.955 151.791C568.955 150.799 568.153 149.996 567.164 149.996C566.175 149.996 565.373 150.799 565.373 151.791C565.373 152.782 566.175 153.585 567.164 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 153.585C562.183 153.585 562.985 152.782 562.985 151.791C562.985 150.799 562.183 149.996 561.194 149.996C560.205 149.996 559.403 150.799 559.403 151.791C559.403 152.782 560.205 153.585 561.194 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 153.585C556.213 153.585 557.015 152.782 557.015 151.791C557.015 150.799 556.213 149.996 555.224 149.996C554.235 149.996 553.433 150.799 553.433 151.791C553.433 152.782 554.235 153.585 555.224 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 153.585C550.243 153.585 551.045 152.782 551.045 151.791C551.045 150.799 550.243 149.996 549.254 149.996C548.265 149.996 547.463 150.799 547.463 151.791C547.463 152.782 548.265 153.585 549.254 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 153.585C544.273 153.585 545.075 152.782 545.075 151.791C545.075 150.799 544.273 149.996 543.284 149.996C542.294 149.996 541.492 150.799 541.492 151.791C541.492 152.782 542.294 153.585 543.284 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 153.585C538.303 153.585 539.104 152.782 539.104 151.791C539.104 150.799 538.303 149.996 537.313 149.996C536.324 149.996 535.522 150.799 535.522 151.791C535.522 152.782 536.324 153.585 537.313 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 153.585C532.332 153.585 533.134 152.782 533.134 151.791C533.134 150.799 532.332 149.996 531.343 149.996C530.354 149.996 529.552 150.799 529.552 151.791C529.552 152.782 530.354 153.585 531.343 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 147.603C1021.88 147.603 1022.69 146.799 1022.69 145.808C1022.69 144.817 1021.88 144.013 1020.9 144.013C1019.91 144.013 1019.1 144.817 1019.1 145.808C1019.1 146.799 1019.91 147.603 1020.9 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 147.603C1015.91 147.603 1016.72 146.799 1016.72 145.808C1016.72 144.817 1015.91 144.013 1014.93 144.013C1013.94 144.013 1013.13 144.817 1013.13 145.808C1013.13 146.799 1013.94 147.603 1014.93 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 147.603C1009.94 147.603 1010.75 146.799 1010.75 145.808C1010.75 144.817 1009.94 144.013 1008.96 144.013C1007.97 144.013 1007.16 144.817 1007.16 145.808C1007.16 146.799 1007.97 147.603 1008.96 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 147.603C1003.97 147.603 1004.78 146.799 1004.78 145.808C1004.78 144.817 1003.97 144.013 1002.99 144.013C1002 144.013 1001.19 144.817 1001.19 145.808C1001.19 146.799 1002 147.603 1002.99 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 147.603C998.004 147.603 998.806 146.799 998.806 145.808C998.806 144.817 998.004 144.013 997.015 144.013C996.026 144.013 995.224 144.817 995.224 145.808C995.224 146.799 996.026 147.603 997.015 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 147.603C992.034 147.603 992.836 146.799 992.836 145.808C992.836 144.817 992.034 144.013 991.045 144.013C990.056 144.013 989.254 144.817 989.254 145.808C989.254 146.799 990.056 147.603 991.045 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 147.603C986.064 147.603 986.866 146.799 986.866 145.808C986.866 144.817 986.064 144.013 985.075 144.013C984.085 144.013 983.284 144.817 983.284 145.808C983.284 146.799 984.085 147.603 985.075 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 147.603C980.094 147.603 980.896 146.799 980.896 145.808C980.896 144.817 980.094 144.013 979.104 144.013C978.115 144.013 977.313 144.817 977.313 145.808C977.313 146.799 978.115 147.603 979.104 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 147.603C974.123 147.603 974.925 146.799 974.925 145.808C974.925 144.817 974.123 144.013 973.134 144.013C972.145 144.013 971.343 144.817 971.343 145.808C971.343 146.799 972.145 147.603 973.134 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 147.603C968.153 147.603 968.955 146.799 968.955 145.808C968.955 144.817 968.153 144.013 967.164 144.013C966.175 144.013 965.373 144.817 965.373 145.808C965.373 146.799 966.175 147.603 967.164 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 147.603C962.183 147.603 962.985 146.799 962.985 145.808C962.985 144.817 962.183 144.013 961.194 144.013C960.205 144.013 959.403 144.817 959.403 145.808C959.403 146.799 960.205 147.603 961.194 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 147.603C956.213 147.603 957.015 146.799 957.015 145.808C957.015 144.817 956.213 144.013 955.224 144.013C954.235 144.013 953.433 144.817 953.433 145.808C953.433 146.799 954.235 147.603 955.224 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 147.603C950.243 147.603 951.045 146.799 951.045 145.808C951.045 144.817 950.243 144.013 949.254 144.013C948.265 144.013 947.463 144.817 947.463 145.808C947.463 146.799 948.265 147.603 949.254 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 147.603C944.273 147.603 945.075 146.799 945.075 145.808C945.075 144.817 944.273 144.013 943.284 144.013C942.294 144.013 941.492 144.817 941.492 145.808C941.492 146.799 942.294 147.603 943.284 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 147.603C938.303 147.603 939.105 146.799 939.105 145.808C939.105 144.817 938.303 144.013 937.313 144.013C936.324 144.013 935.522 144.817 935.522 145.808C935.522 146.799 936.324 147.603 937.313 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 147.603C932.332 147.603 933.134 146.799 933.134 145.808C933.134 144.817 932.332 144.013 931.343 144.013C930.354 144.013 929.552 144.817 929.552 145.808C929.552 146.799 930.354 147.603 931.343 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 147.603C926.362 147.603 927.164 146.799 927.164 145.808C927.164 144.817 926.362 144.013 925.373 144.013C924.384 144.013 923.582 144.817 923.582 145.808C923.582 146.799 924.384 147.603 925.373 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 147.603C920.392 147.603 921.194 146.799 921.194 145.808C921.194 144.817 920.392 144.013 919.403 144.013C918.414 144.013 917.612 144.817 917.612 145.808C917.612 146.799 918.414 147.603 919.403 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 147.603C914.422 147.603 915.224 146.799 915.224 145.808C915.224 144.817 914.422 144.013 913.433 144.013C912.444 144.013 911.642 144.817 911.642 145.808C911.642 146.799 912.444 147.603 913.433 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 147.603C908.452 147.603 909.254 146.799 909.254 145.808C909.254 144.817 908.452 144.013 907.463 144.013C906.474 144.013 905.672 144.817 905.672 145.808C905.672 146.799 906.474 147.603 907.463 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 147.603C902.482 147.603 903.284 146.799 903.284 145.808C903.284 144.817 902.482 144.013 901.492 144.013C900.503 144.013 899.701 144.817 899.701 145.808C899.701 146.799 900.503 147.603 901.492 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 147.603C896.512 147.603 897.313 146.799 897.313 145.808C897.313 144.817 896.512 144.013 895.522 144.013C894.533 144.013 893.731 144.817 893.731 145.808C893.731 146.799 894.533 147.603 895.522 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 147.603C890.541 147.603 891.343 146.799 891.343 145.808C891.343 144.817 890.541 144.013 889.552 144.013C888.563 144.013 887.761 144.817 887.761 145.808C887.761 146.799 888.563 147.603 889.552 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 147.603C884.571 147.603 885.373 146.799 885.373 145.808C885.373 144.817 884.571 144.013 883.582 144.013C882.593 144.013 881.791 144.817 881.791 145.808C881.791 146.799 882.593 147.603 883.582 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 147.603C878.601 147.603 879.403 146.799 879.403 145.808C879.403 144.817 878.601 144.013 877.612 144.013C876.623 144.013 875.821 144.817 875.821 145.808C875.821 146.799 876.623 147.603 877.612 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 147.603C872.631 147.603 873.433 146.799 873.433 145.808C873.433 144.817 872.631 144.013 871.642 144.013C870.653 144.013 869.851 144.817 869.851 145.808C869.851 146.799 870.653 147.603 871.642 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 147.603C866.661 147.603 867.463 146.799 867.463 145.808C867.463 144.817 866.661 144.013 865.672 144.013C864.683 144.013 863.881 144.817 863.881 145.808C863.881 146.799 864.683 147.603 865.672 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 147.603C860.691 147.603 861.493 146.799 861.493 145.808C861.493 144.817 860.691 144.013 859.701 144.013C858.712 144.013 857.91 144.817 857.91 145.808C857.91 146.799 858.712 147.603 859.701 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 147.603C854.721 147.603 855.522 146.799 855.522 145.808C855.522 144.817 854.721 144.013 853.731 144.013C852.742 144.013 851.94 144.817 851.94 145.808C851.94 146.799 852.742 147.603 853.731 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 147.603C848.75 147.603 849.552 146.799 849.552 145.808C849.552 144.817 848.75 144.013 847.761 144.013C846.772 144.013 845.97 144.817 845.97 145.808C845.97 146.799 846.772 147.603 847.761 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 147.603C842.78 147.603 843.582 146.799 843.582 145.808C843.582 144.817 842.78 144.013 841.791 144.013C840.802 144.013 840 144.817 840 145.808C840 146.799 840.802 147.603 841.791 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 147.603C836.81 147.603 837.612 146.799 837.612 145.808C837.612 144.817 836.81 144.013 835.821 144.013C834.832 144.013 834.03 144.817 834.03 145.808C834.03 146.799 834.832 147.603 835.821 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 147.603C830.84 147.603 831.642 146.799 831.642 145.808C831.642 144.817 830.84 144.013 829.851 144.013C828.862 144.013 828.06 144.817 828.06 145.808C828.06 146.799 828.862 147.603 829.851 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 147.603C824.87 147.603 825.672 146.799 825.672 145.808C825.672 144.817 824.87 144.013 823.881 144.013C822.891 144.013 822.09 144.817 822.09 145.808C822.09 146.799 822.891 147.603 823.881 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 147.603C818.9 147.603 819.701 146.799 819.701 145.808C819.701 144.817 818.9 144.013 817.91 144.013C816.921 144.013 816.119 144.817 816.119 145.808C816.119 146.799 816.921 147.603 817.91 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 147.603C812.929 147.603 813.731 146.799 813.731 145.808C813.731 144.817 812.929 144.013 811.94 144.013C810.951 144.013 810.149 144.817 810.149 145.808C810.149 146.799 810.951 147.603 811.94 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 147.603C806.959 147.603 807.761 146.799 807.761 145.808C807.761 144.817 806.959 144.013 805.97 144.013C804.981 144.013 804.179 144.817 804.179 145.808C804.179 146.799 804.981 147.603 805.97 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M800 147.603C800.989 147.603 801.791 146.799 801.791 145.808C801.791 144.817 800.989 144.013 800 144.013C799.011 144.013 798.209 144.817 798.209 145.808C798.209 146.799 799.011 147.603 800 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 147.603C795.019 147.603 795.821 146.799 795.821 145.808C795.821 144.817 795.019 144.013 794.03 144.013C793.041 144.013 792.239 144.817 792.239 145.808C792.239 146.799 793.041 147.603 794.03 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 147.603C789.049 147.603 789.851 146.799 789.851 145.808C789.851 144.817 789.049 144.013 788.06 144.013C787.071 144.013 786.269 144.817 786.269 145.808C786.269 146.799 787.071 147.603 788.06 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 147.603C783.079 147.603 783.881 146.799 783.881 145.808C783.881 144.817 783.079 144.013 782.09 144.013C781.1 144.013 780.299 144.817 780.299 145.808C780.299 146.799 781.1 147.603 782.09 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 147.603C777.109 147.603 777.91 146.799 777.91 145.808C777.91 144.817 777.109 144.013 776.119 144.013C775.13 144.013 774.328 144.817 774.328 145.808C774.328 146.799 775.13 147.603 776.119 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 147.603C771.138 147.603 771.94 146.799 771.94 145.808C771.94 144.817 771.138 144.013 770.149 144.013C769.16 144.013 768.358 144.817 768.358 145.808C768.358 146.799 769.16 147.603 770.149 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 147.603C765.168 147.603 765.97 146.799 765.97 145.808C765.97 144.817 765.168 144.013 764.179 144.013C763.19 144.013 762.388 144.817 762.388 145.808C762.388 146.799 763.19 147.603 764.179 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 147.603C759.198 147.603 760 146.799 760 145.808C760 144.817 759.198 144.013 758.209 144.013C757.22 144.013 756.418 144.817 756.418 145.808C756.418 146.799 757.22 147.603 758.209 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 147.603C753.228 147.603 754.03 146.799 754.03 145.808C754.03 144.817 753.228 144.013 752.239 144.013C751.25 144.013 750.448 144.817 750.448 145.808C750.448 146.799 751.25 147.603 752.239 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 147.603C747.258 147.603 748.06 146.799 748.06 145.808C748.06 144.817 747.258 144.013 746.269 144.013C745.279 144.013 744.478 144.817 744.478 145.808C744.478 146.799 745.279 147.603 746.269 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 147.603C741.288 147.603 742.09 146.799 742.09 145.808C742.09 144.817 741.288 144.013 740.299 144.013C739.309 144.013 738.507 144.817 738.507 145.808C738.507 146.799 739.309 147.603 740.299 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 147.603C735.318 147.603 736.119 146.799 736.119 145.808C736.119 144.817 735.318 144.013 734.328 144.013C733.339 144.013 732.537 144.817 732.537 145.808C732.537 146.799 733.339 147.603 734.328 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 147.603C729.347 147.603 730.149 146.799 730.149 145.808C730.149 144.817 729.347 144.013 728.358 144.013C727.369 144.013 726.567 144.817 726.567 145.808C726.567 146.799 727.369 147.603 728.358 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 147.603C705.467 147.603 706.269 146.799 706.269 145.808C706.269 144.817 705.467 144.013 704.478 144.013C703.488 144.013 702.687 144.817 702.687 145.808C702.687 146.799 703.488 147.603 704.478 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 147.603C699.497 147.603 700.299 146.799 700.299 145.808C700.299 144.817 699.497 144.013 698.507 144.013C697.518 144.013 696.716 144.817 696.716 145.808C696.716 146.799 697.518 147.603 698.507 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 147.603C693.526 147.603 694.328 146.799 694.328 145.808C694.328 144.817 693.526 144.013 692.537 144.013C691.548 144.013 690.746 144.817 690.746 145.808C690.746 146.799 691.548 147.603 692.537 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 147.603C687.556 147.603 688.358 146.799 688.358 145.808C688.358 144.817 687.556 144.013 686.567 144.013C685.578 144.013 684.776 144.817 684.776 145.808C684.776 146.799 685.578 147.603 686.567 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 147.603C681.586 147.603 682.388 146.799 682.388 145.808C682.388 144.817 681.586 144.013 680.597 144.013C679.608 144.013 678.806 144.817 678.806 145.808C678.806 146.799 679.608 147.603 680.597 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 147.603C675.616 147.603 676.418 146.799 676.418 145.808C676.418 144.817 675.616 144.013 674.627 144.013C673.638 144.013 672.836 144.817 672.836 145.808C672.836 146.799 673.638 147.603 674.627 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 147.603C657.706 147.603 658.507 146.799 658.507 145.808C658.507 144.817 657.706 144.013 656.716 144.013C655.727 144.013 654.925 144.817 654.925 145.808C654.925 146.799 655.727 147.603 656.716 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 147.603C651.735 147.603 652.537 146.799 652.537 145.808C652.537 144.817 651.735 144.013 650.746 144.013C649.757 144.013 648.955 144.817 648.955 145.808C648.955 146.799 649.757 147.603 650.746 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 147.603C639.795 147.603 640.597 146.799 640.597 145.808C640.597 144.817 639.795 144.013 638.806 144.013C637.817 144.013 637.015 144.817 637.015 145.808C637.015 146.799 637.817 147.603 638.806 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 147.603C633.825 147.603 634.627 146.799 634.627 145.808C634.627 144.817 633.825 144.013 632.836 144.013C631.847 144.013 631.045 144.817 631.045 145.808C631.045 146.799 631.847 147.603 632.836 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 147.603C627.855 147.603 628.657 146.799 628.657 145.808C628.657 144.817 627.855 144.013 626.866 144.013C625.876 144.013 625.075 144.817 625.075 145.808C625.075 146.799 625.876 147.603 626.866 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 147.603C621.885 147.603 622.687 146.799 622.687 145.808C622.687 144.817 621.885 144.013 620.895 144.013C619.906 144.013 619.104 144.817 619.104 145.808C619.104 146.799 619.906 147.603 620.895 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 147.603C615.915 147.603 616.716 146.799 616.716 145.808C616.716 144.817 615.915 144.013 614.925 144.013C613.936 144.013 613.134 144.817 613.134 145.808C613.134 146.799 613.936 147.603 614.925 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 147.603C609.944 147.603 610.746 146.799 610.746 145.808C610.746 144.817 609.944 144.013 608.955 144.013C607.966 144.013 607.164 144.817 607.164 145.808C607.164 146.799 607.966 147.603 608.955 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 147.603C603.974 147.603 604.776 146.799 604.776 145.808C604.776 144.817 603.974 144.013 602.985 144.013C601.996 144.013 601.194 144.817 601.194 145.808C601.194 146.799 601.996 147.603 602.985 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 147.603C598.004 147.603 598.806 146.799 598.806 145.808C598.806 144.817 598.004 144.013 597.015 144.013C596.026 144.013 595.224 144.817 595.224 145.808C595.224 146.799 596.026 147.603 597.015 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 147.603C592.034 147.603 592.836 146.799 592.836 145.808C592.836 144.817 592.034 144.013 591.045 144.013C590.056 144.013 589.254 144.817 589.254 145.808C589.254 146.799 590.056 147.603 591.045 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 147.603C586.064 147.603 586.866 146.799 586.866 145.808C586.866 144.817 586.064 144.013 585.075 144.013C584.085 144.013 583.284 144.817 583.284 145.808C583.284 146.799 584.085 147.603 585.075 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 147.603C580.094 147.603 580.896 146.799 580.896 145.808C580.896 144.817 580.094 144.013 579.104 144.013C578.115 144.013 577.313 144.817 577.313 145.808C577.313 146.799 578.115 147.603 579.104 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 147.603C562.183 147.603 562.985 146.799 562.985 145.808C562.985 144.817 562.183 144.013 561.194 144.013C560.205 144.013 559.403 144.817 559.403 145.808C559.403 146.799 560.205 147.603 561.194 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 147.603C550.243 147.603 551.045 146.799 551.045 145.808C551.045 144.817 550.243 144.013 549.254 144.013C548.265 144.013 547.463 144.817 547.463 145.808C547.463 146.799 548.265 147.603 549.254 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 147.603C544.273 147.603 545.075 146.799 545.075 145.808C545.075 144.817 544.273 144.013 543.284 144.013C542.294 144.013 541.492 144.817 541.492 145.808C541.492 146.799 542.294 147.603 543.284 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 147.603C538.303 147.603 539.104 146.799 539.104 145.808C539.104 144.817 538.303 144.013 537.313 144.013C536.324 144.013 535.522 144.817 535.522 145.808C535.522 146.799 536.324 147.603 537.313 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 147.603C532.332 147.603 533.134 146.799 533.134 145.808C533.134 144.817 532.332 144.013 531.343 144.013C530.354 144.013 529.552 144.817 529.552 145.808C529.552 146.799 530.354 147.603 531.343 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 141.621C1033.82 141.621 1034.63 140.817 1034.63 139.826C1034.63 138.835 1033.82 138.031 1032.84 138.031C1031.85 138.031 1031.04 138.835 1031.04 139.826C1031.04 140.817 1031.85 141.621 1032.84 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 141.621C1021.88 141.621 1022.69 140.817 1022.69 139.826C1022.69 138.835 1021.88 138.031 1020.9 138.031C1019.91 138.031 1019.1 138.835 1019.1 139.826C1019.1 140.817 1019.91 141.621 1020.9 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 141.621C1015.91 141.621 1016.72 140.817 1016.72 139.826C1016.72 138.835 1015.91 138.031 1014.93 138.031C1013.94 138.031 1013.13 138.835 1013.13 139.826C1013.13 140.817 1013.94 141.621 1014.93 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 141.621C1009.94 141.621 1010.75 140.817 1010.75 139.826C1010.75 138.835 1009.94 138.031 1008.96 138.031C1007.97 138.031 1007.16 138.835 1007.16 139.826C1007.16 140.817 1007.97 141.621 1008.96 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 141.621C1003.97 141.621 1004.78 140.817 1004.78 139.826C1004.78 138.835 1003.97 138.031 1002.99 138.031C1002 138.031 1001.19 138.835 1001.19 139.826C1001.19 140.817 1002 141.621 1002.99 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 141.621C998.004 141.621 998.806 140.817 998.806 139.826C998.806 138.835 998.004 138.031 997.015 138.031C996.026 138.031 995.224 138.835 995.224 139.826C995.224 140.817 996.026 141.621 997.015 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 141.621C992.034 141.621 992.836 140.817 992.836 139.826C992.836 138.835 992.034 138.031 991.045 138.031C990.056 138.031 989.254 138.835 989.254 139.826C989.254 140.817 990.056 141.621 991.045 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 141.621C986.064 141.621 986.866 140.817 986.866 139.826C986.866 138.835 986.064 138.031 985.075 138.031C984.085 138.031 983.284 138.835 983.284 139.826C983.284 140.817 984.085 141.621 985.075 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 141.621C980.094 141.621 980.896 140.817 980.896 139.826C980.896 138.835 980.094 138.031 979.104 138.031C978.115 138.031 977.313 138.835 977.313 139.826C977.313 140.817 978.115 141.621 979.104 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 141.621C974.123 141.621 974.925 140.817 974.925 139.826C974.925 138.835 974.123 138.031 973.134 138.031C972.145 138.031 971.343 138.835 971.343 139.826C971.343 140.817 972.145 141.621 973.134 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 141.621C968.153 141.621 968.955 140.817 968.955 139.826C968.955 138.835 968.153 138.031 967.164 138.031C966.175 138.031 965.373 138.835 965.373 139.826C965.373 140.817 966.175 141.621 967.164 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 141.621C962.183 141.621 962.985 140.817 962.985 139.826C962.985 138.835 962.183 138.031 961.194 138.031C960.205 138.031 959.403 138.835 959.403 139.826C959.403 140.817 960.205 141.621 961.194 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 141.621C956.213 141.621 957.015 140.817 957.015 139.826C957.015 138.835 956.213 138.031 955.224 138.031C954.235 138.031 953.433 138.835 953.433 139.826C953.433 140.817 954.235 141.621 955.224 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 141.621C950.243 141.621 951.045 140.817 951.045 139.826C951.045 138.835 950.243 138.031 949.254 138.031C948.265 138.031 947.463 138.835 947.463 139.826C947.463 140.817 948.265 141.621 949.254 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 141.621C944.273 141.621 945.075 140.817 945.075 139.826C945.075 138.835 944.273 138.031 943.284 138.031C942.294 138.031 941.492 138.835 941.492 139.826C941.492 140.817 942.294 141.621 943.284 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 141.621C938.303 141.621 939.105 140.817 939.105 139.826C939.105 138.835 938.303 138.031 937.313 138.031C936.324 138.031 935.522 138.835 935.522 139.826C935.522 140.817 936.324 141.621 937.313 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 141.621C932.332 141.621 933.134 140.817 933.134 139.826C933.134 138.835 932.332 138.031 931.343 138.031C930.354 138.031 929.552 138.835 929.552 139.826C929.552 140.817 930.354 141.621 931.343 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 141.621C926.362 141.621 927.164 140.817 927.164 139.826C927.164 138.835 926.362 138.031 925.373 138.031C924.384 138.031 923.582 138.835 923.582 139.826C923.582 140.817 924.384 141.621 925.373 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 141.621C920.392 141.621 921.194 140.817 921.194 139.826C921.194 138.835 920.392 138.031 919.403 138.031C918.414 138.031 917.612 138.835 917.612 139.826C917.612 140.817 918.414 141.621 919.403 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 141.621C914.422 141.621 915.224 140.817 915.224 139.826C915.224 138.835 914.422 138.031 913.433 138.031C912.444 138.031 911.642 138.835 911.642 139.826C911.642 140.817 912.444 141.621 913.433 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 141.621C908.452 141.621 909.254 140.817 909.254 139.826C909.254 138.835 908.452 138.031 907.463 138.031C906.474 138.031 905.672 138.835 905.672 139.826C905.672 140.817 906.474 141.621 907.463 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 141.621C902.482 141.621 903.284 140.817 903.284 139.826C903.284 138.835 902.482 138.031 901.492 138.031C900.503 138.031 899.701 138.835 899.701 139.826C899.701 140.817 900.503 141.621 901.492 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 141.621C896.512 141.621 897.313 140.817 897.313 139.826C897.313 138.835 896.512 138.031 895.522 138.031C894.533 138.031 893.731 138.835 893.731 139.826C893.731 140.817 894.533 141.621 895.522 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 141.621C890.541 141.621 891.343 140.817 891.343 139.826C891.343 138.835 890.541 138.031 889.552 138.031C888.563 138.031 887.761 138.835 887.761 139.826C887.761 140.817 888.563 141.621 889.552 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 141.621C884.571 141.621 885.373 140.817 885.373 139.826C885.373 138.835 884.571 138.031 883.582 138.031C882.593 138.031 881.791 138.835 881.791 139.826C881.791 140.817 882.593 141.621 883.582 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 141.621C878.601 141.621 879.403 140.817 879.403 139.826C879.403 138.835 878.601 138.031 877.612 138.031C876.623 138.031 875.821 138.835 875.821 139.826C875.821 140.817 876.623 141.621 877.612 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 141.621C872.631 141.621 873.433 140.817 873.433 139.826C873.433 138.835 872.631 138.031 871.642 138.031C870.653 138.031 869.851 138.835 869.851 139.826C869.851 140.817 870.653 141.621 871.642 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 141.621C866.661 141.621 867.463 140.817 867.463 139.826C867.463 138.835 866.661 138.031 865.672 138.031C864.683 138.031 863.881 138.835 863.881 139.826C863.881 140.817 864.683 141.621 865.672 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 141.621C860.691 141.621 861.493 140.817 861.493 139.826C861.493 138.835 860.691 138.031 859.701 138.031C858.712 138.031 857.91 138.835 857.91 139.826C857.91 140.817 858.712 141.621 859.701 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 141.621C854.721 141.621 855.522 140.817 855.522 139.826C855.522 138.835 854.721 138.031 853.731 138.031C852.742 138.031 851.94 138.835 851.94 139.826C851.94 140.817 852.742 141.621 853.731 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 141.621C848.75 141.621 849.552 140.817 849.552 139.826C849.552 138.835 848.75 138.031 847.761 138.031C846.772 138.031 845.97 138.835 845.97 139.826C845.97 140.817 846.772 141.621 847.761 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 141.621C842.78 141.621 843.582 140.817 843.582 139.826C843.582 138.835 842.78 138.031 841.791 138.031C840.802 138.031 840 138.835 840 139.826C840 140.817 840.802 141.621 841.791 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 141.621C836.81 141.621 837.612 140.817 837.612 139.826C837.612 138.835 836.81 138.031 835.821 138.031C834.832 138.031 834.03 138.835 834.03 139.826C834.03 140.817 834.832 141.621 835.821 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 141.621C830.84 141.621 831.642 140.817 831.642 139.826C831.642 138.835 830.84 138.031 829.851 138.031C828.862 138.031 828.06 138.835 828.06 139.826C828.06 140.817 828.862 141.621 829.851 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 141.621C824.87 141.621 825.672 140.817 825.672 139.826C825.672 138.835 824.87 138.031 823.881 138.031C822.891 138.031 822.09 138.835 822.09 139.826C822.09 140.817 822.891 141.621 823.881 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 141.621C812.929 141.621 813.731 140.817 813.731 139.826C813.731 138.835 812.929 138.031 811.94 138.031C810.951 138.031 810.149 138.835 810.149 139.826C810.149 140.817 810.951 141.621 811.94 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 141.621C806.959 141.621 807.761 140.817 807.761 139.826C807.761 138.835 806.959 138.031 805.97 138.031C804.981 138.031 804.179 138.835 804.179 139.826C804.179 140.817 804.981 141.621 805.97 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M800 141.621C800.989 141.621 801.791 140.817 801.791 139.826C801.791 138.835 800.989 138.031 800 138.031C799.011 138.031 798.209 138.835 798.209 139.826C798.209 140.817 799.011 141.621 800 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 141.621C795.019 141.621 795.821 140.817 795.821 139.826C795.821 138.835 795.019 138.031 794.03 138.031C793.041 138.031 792.239 138.835 792.239 139.826C792.239 140.817 793.041 141.621 794.03 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 141.621C789.049 141.621 789.851 140.817 789.851 139.826C789.851 138.835 789.049 138.031 788.06 138.031C787.071 138.031 786.269 138.835 786.269 139.826C786.269 140.817 787.071 141.621 788.06 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 141.621C783.079 141.621 783.881 140.817 783.881 139.826C783.881 138.835 783.079 138.031 782.09 138.031C781.1 138.031 780.299 138.835 780.299 139.826C780.299 140.817 781.1 141.621 782.09 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 141.621C777.109 141.621 777.91 140.817 777.91 139.826C777.91 138.835 777.109 138.031 776.119 138.031C775.13 138.031 774.328 138.835 774.328 139.826C774.328 140.817 775.13 141.621 776.119 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 141.621C771.138 141.621 771.94 140.817 771.94 139.826C771.94 138.835 771.138 138.031 770.149 138.031C769.16 138.031 768.358 138.835 768.358 139.826C768.358 140.817 769.16 141.621 770.149 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 141.621C765.168 141.621 765.97 140.817 765.97 139.826C765.97 138.835 765.168 138.031 764.179 138.031C763.19 138.031 762.388 138.835 762.388 139.826C762.388 140.817 763.19 141.621 764.179 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 141.621C759.198 141.621 760 140.817 760 139.826C760 138.835 759.198 138.031 758.209 138.031C757.22 138.031 756.418 138.835 756.418 139.826C756.418 140.817 757.22 141.621 758.209 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 141.621C753.228 141.621 754.03 140.817 754.03 139.826C754.03 138.835 753.228 138.031 752.239 138.031C751.25 138.031 750.448 138.835 750.448 139.826C750.448 140.817 751.25 141.621 752.239 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 141.621C747.258 141.621 748.06 140.817 748.06 139.826C748.06 138.835 747.258 138.031 746.269 138.031C745.279 138.031 744.478 138.835 744.478 139.826C744.478 140.817 745.279 141.621 746.269 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 141.621C741.288 141.621 742.09 140.817 742.09 139.826C742.09 138.835 741.288 138.031 740.299 138.031C739.309 138.031 738.507 138.835 738.507 139.826C738.507 140.817 739.309 141.621 740.299 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 141.621C735.318 141.621 736.119 140.817 736.119 139.826C736.119 138.835 735.318 138.031 734.328 138.031C733.339 138.031 732.537 138.835 732.537 139.826C732.537 140.817 733.339 141.621 734.328 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 141.621C729.347 141.621 730.149 140.817 730.149 139.826C730.149 138.835 729.347 138.031 728.358 138.031C727.369 138.031 726.567 138.835 726.567 139.826C726.567 140.817 727.369 141.621 728.358 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 141.621C723.377 141.621 724.179 140.817 724.179 139.826C724.179 138.835 723.377 138.031 722.388 138.031C721.399 138.031 720.597 138.835 720.597 139.826C720.597 140.817 721.399 141.621 722.388 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 141.621C717.407 141.621 718.209 140.817 718.209 139.826C718.209 138.835 717.407 138.031 716.418 138.031C715.429 138.031 714.627 138.835 714.627 139.826C714.627 140.817 715.429 141.621 716.418 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 141.621C711.437 141.621 712.239 140.817 712.239 139.826C712.239 138.835 711.437 138.031 710.448 138.031C709.459 138.031 708.657 138.835 708.657 139.826C708.657 140.817 709.459 141.621 710.448 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 141.621C705.467 141.621 706.269 140.817 706.269 139.826C706.269 138.835 705.467 138.031 704.478 138.031C703.488 138.031 702.687 138.835 702.687 139.826C702.687 140.817 703.488 141.621 704.478 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 141.621C699.497 141.621 700.299 140.817 700.299 139.826C700.299 138.835 699.497 138.031 698.507 138.031C697.518 138.031 696.716 138.835 696.716 139.826C696.716 140.817 697.518 141.621 698.507 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 141.621C693.526 141.621 694.328 140.817 694.328 139.826C694.328 138.835 693.526 138.031 692.537 138.031C691.548 138.031 690.746 138.835 690.746 139.826C690.746 140.817 691.548 141.621 692.537 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 141.621C687.556 141.621 688.358 140.817 688.358 139.826C688.358 138.835 687.556 138.031 686.567 138.031C685.578 138.031 684.776 138.835 684.776 139.826C684.776 140.817 685.578 141.621 686.567 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 141.621C681.586 141.621 682.388 140.817 682.388 139.826C682.388 138.835 681.586 138.031 680.597 138.031C679.608 138.031 678.806 138.835 678.806 139.826C678.806 140.817 679.608 141.621 680.597 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 141.621C675.616 141.621 676.418 140.817 676.418 139.826C676.418 138.835 675.616 138.031 674.627 138.031C673.638 138.031 672.836 138.835 672.836 139.826C672.836 140.817 673.638 141.621 674.627 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 141.621C669.646 141.621 670.448 140.817 670.448 139.826C670.448 138.835 669.646 138.031 668.657 138.031C667.668 138.031 666.866 138.835 666.866 139.826C666.866 140.817 667.668 141.621 668.657 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 141.621C663.676 141.621 664.478 140.817 664.478 139.826C664.478 138.835 663.676 138.031 662.687 138.031C661.697 138.031 660.896 138.835 660.896 139.826C660.896 140.817 661.697 141.621 662.687 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 141.621C657.706 141.621 658.507 140.817 658.507 139.826C658.507 138.835 657.706 138.031 656.716 138.031C655.727 138.031 654.925 138.835 654.925 139.826C654.925 140.817 655.727 141.621 656.716 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 141.621C651.735 141.621 652.537 140.817 652.537 139.826C652.537 138.835 651.735 138.031 650.746 138.031C649.757 138.031 648.955 138.835 648.955 139.826C648.955 140.817 649.757 141.621 650.746 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 141.621C645.765 141.621 646.567 140.817 646.567 139.826C646.567 138.835 645.765 138.031 644.776 138.031C643.787 138.031 642.985 138.835 642.985 139.826C642.985 140.817 643.787 141.621 644.776 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 141.621C639.795 141.621 640.597 140.817 640.597 139.826C640.597 138.835 639.795 138.031 638.806 138.031C637.817 138.031 637.015 138.835 637.015 139.826C637.015 140.817 637.817 141.621 638.806 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 141.621C633.825 141.621 634.627 140.817 634.627 139.826C634.627 138.835 633.825 138.031 632.836 138.031C631.847 138.031 631.045 138.835 631.045 139.826C631.045 140.817 631.847 141.621 632.836 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 141.621C627.855 141.621 628.657 140.817 628.657 139.826C628.657 138.835 627.855 138.031 626.866 138.031C625.876 138.031 625.075 138.835 625.075 139.826C625.075 140.817 625.876 141.621 626.866 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 141.621C621.885 141.621 622.687 140.817 622.687 139.826C622.687 138.835 621.885 138.031 620.895 138.031C619.906 138.031 619.104 138.835 619.104 139.826C619.104 140.817 619.906 141.621 620.895 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 141.621C615.915 141.621 616.716 140.817 616.716 139.826C616.716 138.835 615.915 138.031 614.925 138.031C613.936 138.031 613.134 138.835 613.134 139.826C613.134 140.817 613.936 141.621 614.925 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 141.621C609.944 141.621 610.746 140.817 610.746 139.826C610.746 138.835 609.944 138.031 608.955 138.031C607.966 138.031 607.164 138.835 607.164 139.826C607.164 140.817 607.966 141.621 608.955 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 141.621C598.004 141.621 598.806 140.817 598.806 139.826C598.806 138.835 598.004 138.031 597.015 138.031C596.026 138.031 595.224 138.835 595.224 139.826C595.224 140.817 596.026 141.621 597.015 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 141.621C574.123 141.621 574.925 140.817 574.925 139.826C574.925 138.835 574.123 138.031 573.134 138.031C572.145 138.031 571.343 138.835 571.343 139.826C571.343 140.817 572.145 141.621 573.134 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 141.621C556.213 141.621 557.015 140.817 557.015 139.826C557.015 138.835 556.213 138.031 555.224 138.031C554.235 138.031 553.433 138.835 553.433 139.826C553.433 140.817 554.235 141.621 555.224 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 141.621C550.243 141.621 551.045 140.817 551.045 139.826C551.045 138.835 550.243 138.031 549.254 138.031C548.265 138.031 547.463 138.835 547.463 139.826C547.463 140.817 548.265 141.621 549.254 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 141.621C544.273 141.621 545.075 140.817 545.075 139.826C545.075 138.835 544.273 138.031 543.284 138.031C542.294 138.031 541.492 138.835 541.492 139.826C541.492 140.817 542.294 141.621 543.284 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 141.621C538.303 141.621 539.104 140.817 539.104 139.826C539.104 138.835 538.303 138.031 537.313 138.031C536.324 138.031 535.522 138.835 535.522 139.826C535.522 140.817 536.324 141.621 537.313 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 141.621C532.332 141.621 533.134 140.817 533.134 139.826C533.134 138.835 532.332 138.031 531.343 138.031C530.354 138.031 529.552 138.835 529.552 139.826C529.552 140.817 530.354 141.621 531.343 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 141.621C526.362 141.621 527.164 140.817 527.164 139.826C527.164 138.835 526.362 138.031 525.373 138.031C524.384 138.031 523.582 138.835 523.582 139.826C523.582 140.817 524.384 141.621 525.373 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 135.638C1033.82 135.638 1034.63 134.835 1034.63 133.844C1034.63 132.852 1033.82 132.049 1032.84 132.049C1031.85 132.049 1031.04 132.852 1031.04 133.844C1031.04 134.835 1031.85 135.638 1032.84 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 135.638C1021.88 135.638 1022.69 134.835 1022.69 133.844C1022.69 132.852 1021.88 132.049 1020.9 132.049C1019.91 132.049 1019.1 132.852 1019.1 133.844C1019.1 134.835 1019.91 135.638 1020.9 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 135.638C1015.91 135.638 1016.72 134.835 1016.72 133.844C1016.72 132.852 1015.91 132.049 1014.93 132.049C1013.94 132.049 1013.13 132.852 1013.13 133.844C1013.13 134.835 1013.94 135.638 1014.93 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 135.638C1009.94 135.638 1010.75 134.835 1010.75 133.844C1010.75 132.852 1009.94 132.049 1008.96 132.049C1007.97 132.049 1007.16 132.852 1007.16 133.844C1007.16 134.835 1007.97 135.638 1008.96 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 135.638C1003.97 135.638 1004.78 134.835 1004.78 133.844C1004.78 132.852 1003.97 132.049 1002.99 132.049C1002 132.049 1001.19 132.852 1001.19 133.844C1001.19 134.835 1002 135.638 1002.99 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 135.638C998.004 135.638 998.806 134.835 998.806 133.844C998.806 132.852 998.004 132.049 997.015 132.049C996.026 132.049 995.224 132.852 995.224 133.844C995.224 134.835 996.026 135.638 997.015 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 135.638C992.034 135.638 992.836 134.835 992.836 133.844C992.836 132.852 992.034 132.049 991.045 132.049C990.056 132.049 989.254 132.852 989.254 133.844C989.254 134.835 990.056 135.638 991.045 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 135.638C986.064 135.638 986.866 134.835 986.866 133.844C986.866 132.852 986.064 132.049 985.075 132.049C984.085 132.049 983.284 132.852 983.284 133.844C983.284 134.835 984.085 135.638 985.075 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 135.638C980.094 135.638 980.896 134.835 980.896 133.844C980.896 132.852 980.094 132.049 979.104 132.049C978.115 132.049 977.313 132.852 977.313 133.844C977.313 134.835 978.115 135.638 979.104 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 135.638C974.123 135.638 974.925 134.835 974.925 133.844C974.925 132.852 974.123 132.049 973.134 132.049C972.145 132.049 971.343 132.852 971.343 133.844C971.343 134.835 972.145 135.638 973.134 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 135.638C968.153 135.638 968.955 134.835 968.955 133.844C968.955 132.852 968.153 132.049 967.164 132.049C966.175 132.049 965.373 132.852 965.373 133.844C965.373 134.835 966.175 135.638 967.164 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 135.638C962.183 135.638 962.985 134.835 962.985 133.844C962.985 132.852 962.183 132.049 961.194 132.049C960.205 132.049 959.403 132.852 959.403 133.844C959.403 134.835 960.205 135.638 961.194 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 135.638C956.213 135.638 957.015 134.835 957.015 133.844C957.015 132.852 956.213 132.049 955.224 132.049C954.235 132.049 953.433 132.852 953.433 133.844C953.433 134.835 954.235 135.638 955.224 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 135.638C950.243 135.638 951.045 134.835 951.045 133.844C951.045 132.852 950.243 132.049 949.254 132.049C948.265 132.049 947.463 132.852 947.463 133.844C947.463 134.835 948.265 135.638 949.254 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 135.638C944.273 135.638 945.075 134.835 945.075 133.844C945.075 132.852 944.273 132.049 943.284 132.049C942.294 132.049 941.492 132.852 941.492 133.844C941.492 134.835 942.294 135.638 943.284 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 135.638C938.303 135.638 939.105 134.835 939.105 133.844C939.105 132.852 938.303 132.049 937.313 132.049C936.324 132.049 935.522 132.852 935.522 133.844C935.522 134.835 936.324 135.638 937.313 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 135.638C932.332 135.638 933.134 134.835 933.134 133.844C933.134 132.852 932.332 132.049 931.343 132.049C930.354 132.049 929.552 132.852 929.552 133.844C929.552 134.835 930.354 135.638 931.343 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 135.638C926.362 135.638 927.164 134.835 927.164 133.844C927.164 132.852 926.362 132.049 925.373 132.049C924.384 132.049 923.582 132.852 923.582 133.844C923.582 134.835 924.384 135.638 925.373 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 135.638C920.392 135.638 921.194 134.835 921.194 133.844C921.194 132.852 920.392 132.049 919.403 132.049C918.414 132.049 917.612 132.852 917.612 133.844C917.612 134.835 918.414 135.638 919.403 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 135.638C914.422 135.638 915.224 134.835 915.224 133.844C915.224 132.852 914.422 132.049 913.433 132.049C912.444 132.049 911.642 132.852 911.642 133.844C911.642 134.835 912.444 135.638 913.433 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 135.638C908.452 135.638 909.254 134.835 909.254 133.844C909.254 132.852 908.452 132.049 907.463 132.049C906.474 132.049 905.672 132.852 905.672 133.844C905.672 134.835 906.474 135.638 907.463 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 135.638C902.482 135.638 903.284 134.835 903.284 133.844C903.284 132.852 902.482 132.049 901.492 132.049C900.503 132.049 899.701 132.852 899.701 133.844C899.701 134.835 900.503 135.638 901.492 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 135.638C896.512 135.638 897.313 134.835 897.313 133.844C897.313 132.852 896.512 132.049 895.522 132.049C894.533 132.049 893.731 132.852 893.731 133.844C893.731 134.835 894.533 135.638 895.522 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 135.638C890.541 135.638 891.343 134.835 891.343 133.844C891.343 132.852 890.541 132.049 889.552 132.049C888.563 132.049 887.761 132.852 887.761 133.844C887.761 134.835 888.563 135.638 889.552 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 135.638C884.571 135.638 885.373 134.835 885.373 133.844C885.373 132.852 884.571 132.049 883.582 132.049C882.593 132.049 881.791 132.852 881.791 133.844C881.791 134.835 882.593 135.638 883.582 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 135.638C878.601 135.638 879.403 134.835 879.403 133.844C879.403 132.852 878.601 132.049 877.612 132.049C876.623 132.049 875.821 132.852 875.821 133.844C875.821 134.835 876.623 135.638 877.612 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 135.638C872.631 135.638 873.433 134.835 873.433 133.844C873.433 132.852 872.631 132.049 871.642 132.049C870.653 132.049 869.851 132.852 869.851 133.844C869.851 134.835 870.653 135.638 871.642 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 135.638C866.661 135.638 867.463 134.835 867.463 133.844C867.463 132.852 866.661 132.049 865.672 132.049C864.683 132.049 863.881 132.852 863.881 133.844C863.881 134.835 864.683 135.638 865.672 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 135.638C860.691 135.638 861.493 134.835 861.493 133.844C861.493 132.852 860.691 132.049 859.701 132.049C858.712 132.049 857.91 132.852 857.91 133.844C857.91 134.835 858.712 135.638 859.701 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 135.638C854.721 135.638 855.522 134.835 855.522 133.844C855.522 132.852 854.721 132.049 853.731 132.049C852.742 132.049 851.94 132.852 851.94 133.844C851.94 134.835 852.742 135.638 853.731 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 135.638C848.75 135.638 849.552 134.835 849.552 133.844C849.552 132.852 848.75 132.049 847.761 132.049C846.772 132.049 845.97 132.852 845.97 133.844C845.97 134.835 846.772 135.638 847.761 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 135.638C842.78 135.638 843.582 134.835 843.582 133.844C843.582 132.852 842.78 132.049 841.791 132.049C840.802 132.049 840 132.852 840 133.844C840 134.835 840.802 135.638 841.791 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 135.638C836.81 135.638 837.612 134.835 837.612 133.844C837.612 132.852 836.81 132.049 835.821 132.049C834.832 132.049 834.03 132.852 834.03 133.844C834.03 134.835 834.832 135.638 835.821 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 135.638C830.84 135.638 831.642 134.835 831.642 133.844C831.642 132.852 830.84 132.049 829.851 132.049C828.862 132.049 828.06 132.852 828.06 133.844C828.06 134.835 828.862 135.638 829.851 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 135.638C824.87 135.638 825.672 134.835 825.672 133.844C825.672 132.852 824.87 132.049 823.881 132.049C822.891 132.049 822.09 132.852 822.09 133.844C822.09 134.835 822.891 135.638 823.881 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 135.638C818.9 135.638 819.701 134.835 819.701 133.844C819.701 132.852 818.9 132.049 817.91 132.049C816.921 132.049 816.119 132.852 816.119 133.844C816.119 134.835 816.921 135.638 817.91 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 135.638C812.929 135.638 813.731 134.835 813.731 133.844C813.731 132.852 812.929 132.049 811.94 132.049C810.951 132.049 810.149 132.852 810.149 133.844C810.149 134.835 810.951 135.638 811.94 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 135.638C806.959 135.638 807.761 134.835 807.761 133.844C807.761 132.852 806.959 132.049 805.97 132.049C804.981 132.049 804.179 132.852 804.179 133.844C804.179 134.835 804.981 135.638 805.97 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M800 135.638C800.989 135.638 801.791 134.835 801.791 133.844C801.791 132.852 800.989 132.049 800 132.049C799.011 132.049 798.209 132.852 798.209 133.844C798.209 134.835 799.011 135.638 800 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 135.638C795.019 135.638 795.821 134.835 795.821 133.844C795.821 132.852 795.019 132.049 794.03 132.049C793.041 132.049 792.239 132.852 792.239 133.844C792.239 134.835 793.041 135.638 794.03 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 135.638C789.049 135.638 789.851 134.835 789.851 133.844C789.851 132.852 789.049 132.049 788.06 132.049C787.071 132.049 786.269 132.852 786.269 133.844C786.269 134.835 787.071 135.638 788.06 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 135.638C783.079 135.638 783.881 134.835 783.881 133.844C783.881 132.852 783.079 132.049 782.09 132.049C781.1 132.049 780.299 132.852 780.299 133.844C780.299 134.835 781.1 135.638 782.09 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 135.638C777.109 135.638 777.91 134.835 777.91 133.844C777.91 132.852 777.109 132.049 776.119 132.049C775.13 132.049 774.328 132.852 774.328 133.844C774.328 134.835 775.13 135.638 776.119 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 135.638C771.138 135.638 771.94 134.835 771.94 133.844C771.94 132.852 771.138 132.049 770.149 132.049C769.16 132.049 768.358 132.852 768.358 133.844C768.358 134.835 769.16 135.638 770.149 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 135.638C765.168 135.638 765.97 134.835 765.97 133.844C765.97 132.852 765.168 132.049 764.179 132.049C763.19 132.049 762.388 132.852 762.388 133.844C762.388 134.835 763.19 135.638 764.179 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 135.638C759.198 135.638 760 134.835 760 133.844C760 132.852 759.198 132.049 758.209 132.049C757.22 132.049 756.418 132.852 756.418 133.844C756.418 134.835 757.22 135.638 758.209 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 135.638C753.228 135.638 754.03 134.835 754.03 133.844C754.03 132.852 753.228 132.049 752.239 132.049C751.25 132.049 750.448 132.852 750.448 133.844C750.448 134.835 751.25 135.638 752.239 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 135.638C747.258 135.638 748.06 134.835 748.06 133.844C748.06 132.852 747.258 132.049 746.269 132.049C745.279 132.049 744.478 132.852 744.478 133.844C744.478 134.835 745.279 135.638 746.269 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 135.638C741.288 135.638 742.09 134.835 742.09 133.844C742.09 132.852 741.288 132.049 740.299 132.049C739.309 132.049 738.507 132.852 738.507 133.844C738.507 134.835 739.309 135.638 740.299 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 135.638C735.318 135.638 736.119 134.835 736.119 133.844C736.119 132.852 735.318 132.049 734.328 132.049C733.339 132.049 732.537 132.852 732.537 133.844C732.537 134.835 733.339 135.638 734.328 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 135.638C729.347 135.638 730.149 134.835 730.149 133.844C730.149 132.852 729.347 132.049 728.358 132.049C727.369 132.049 726.567 132.852 726.567 133.844C726.567 134.835 727.369 135.638 728.358 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 135.638C723.377 135.638 724.179 134.835 724.179 133.844C724.179 132.852 723.377 132.049 722.388 132.049C721.399 132.049 720.597 132.852 720.597 133.844C720.597 134.835 721.399 135.638 722.388 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 135.638C717.407 135.638 718.209 134.835 718.209 133.844C718.209 132.852 717.407 132.049 716.418 132.049C715.429 132.049 714.627 132.852 714.627 133.844C714.627 134.835 715.429 135.638 716.418 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 135.638C711.437 135.638 712.239 134.835 712.239 133.844C712.239 132.852 711.437 132.049 710.448 132.049C709.459 132.049 708.657 132.852 708.657 133.844C708.657 134.835 709.459 135.638 710.448 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 135.638C705.467 135.638 706.269 134.835 706.269 133.844C706.269 132.852 705.467 132.049 704.478 132.049C703.488 132.049 702.687 132.852 702.687 133.844C702.687 134.835 703.488 135.638 704.478 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 135.638C699.497 135.638 700.299 134.835 700.299 133.844C700.299 132.852 699.497 132.049 698.507 132.049C697.518 132.049 696.716 132.852 696.716 133.844C696.716 134.835 697.518 135.638 698.507 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 135.638C693.526 135.638 694.328 134.835 694.328 133.844C694.328 132.852 693.526 132.049 692.537 132.049C691.548 132.049 690.746 132.852 690.746 133.844C690.746 134.835 691.548 135.638 692.537 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 135.638C687.556 135.638 688.358 134.835 688.358 133.844C688.358 132.852 687.556 132.049 686.567 132.049C685.578 132.049 684.776 132.852 684.776 133.844C684.776 134.835 685.578 135.638 686.567 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 135.638C681.586 135.638 682.388 134.835 682.388 133.844C682.388 132.852 681.586 132.049 680.597 132.049C679.608 132.049 678.806 132.852 678.806 133.844C678.806 134.835 679.608 135.638 680.597 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 135.638C675.616 135.638 676.418 134.835 676.418 133.844C676.418 132.852 675.616 132.049 674.627 132.049C673.638 132.049 672.836 132.852 672.836 133.844C672.836 134.835 673.638 135.638 674.627 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 135.638C669.646 135.638 670.448 134.835 670.448 133.844C670.448 132.852 669.646 132.049 668.657 132.049C667.668 132.049 666.866 132.852 666.866 133.844C666.866 134.835 667.668 135.638 668.657 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 135.638C663.676 135.638 664.478 134.835 664.478 133.844C664.478 132.852 663.676 132.049 662.687 132.049C661.697 132.049 660.896 132.852 660.896 133.844C660.896 134.835 661.697 135.638 662.687 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 135.638C657.706 135.638 658.507 134.835 658.507 133.844C658.507 132.852 657.706 132.049 656.716 132.049C655.727 132.049 654.925 132.852 654.925 133.844C654.925 134.835 655.727 135.638 656.716 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 135.638C651.735 135.638 652.537 134.835 652.537 133.844C652.537 132.852 651.735 132.049 650.746 132.049C649.757 132.049 648.955 132.852 648.955 133.844C648.955 134.835 649.757 135.638 650.746 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 135.638C645.765 135.638 646.567 134.835 646.567 133.844C646.567 132.852 645.765 132.049 644.776 132.049C643.787 132.049 642.985 132.852 642.985 133.844C642.985 134.835 643.787 135.638 644.776 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 135.638C639.795 135.638 640.597 134.835 640.597 133.844C640.597 132.852 639.795 132.049 638.806 132.049C637.817 132.049 637.015 132.852 637.015 133.844C637.015 134.835 637.817 135.638 638.806 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 135.638C633.825 135.638 634.627 134.835 634.627 133.844C634.627 132.852 633.825 132.049 632.836 132.049C631.847 132.049 631.045 132.852 631.045 133.844C631.045 134.835 631.847 135.638 632.836 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 135.638C627.855 135.638 628.657 134.835 628.657 133.844C628.657 132.852 627.855 132.049 626.866 132.049C625.876 132.049 625.075 132.852 625.075 133.844C625.075 134.835 625.876 135.638 626.866 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 135.638C621.885 135.638 622.687 134.835 622.687 133.844C622.687 132.852 621.885 132.049 620.895 132.049C619.906 132.049 619.104 132.852 619.104 133.844C619.104 134.835 619.906 135.638 620.895 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 135.638C615.915 135.638 616.716 134.835 616.716 133.844C616.716 132.852 615.915 132.049 614.925 132.049C613.936 132.049 613.134 132.852 613.134 133.844C613.134 134.835 613.936 135.638 614.925 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 135.638C609.944 135.638 610.746 134.835 610.746 133.844C610.746 132.852 609.944 132.049 608.955 132.049C607.966 132.049 607.164 132.852 607.164 133.844C607.164 134.835 607.966 135.638 608.955 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 135.638C603.974 135.638 604.776 134.835 604.776 133.844C604.776 132.852 603.974 132.049 602.985 132.049C601.996 132.049 601.194 132.852 601.194 133.844C601.194 134.835 601.996 135.638 602.985 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 135.638C598.004 135.638 598.806 134.835 598.806 133.844C598.806 132.852 598.004 132.049 597.015 132.049C596.026 132.049 595.224 132.852 595.224 133.844C595.224 134.835 596.026 135.638 597.015 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 135.638C592.034 135.638 592.836 134.835 592.836 133.844C592.836 132.852 592.034 132.049 591.045 132.049C590.056 132.049 589.254 132.852 589.254 133.844C589.254 134.835 590.056 135.638 591.045 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 135.638C586.064 135.638 586.866 134.835 586.866 133.844C586.866 132.852 586.064 132.049 585.075 132.049C584.085 132.049 583.284 132.852 583.284 133.844C583.284 134.835 584.085 135.638 585.075 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 135.638C574.123 135.638 574.925 134.835 574.925 133.844C574.925 132.852 574.123 132.049 573.134 132.049C572.145 132.049 571.343 132.852 571.343 133.844C571.343 134.835 572.145 135.638 573.134 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 135.638C562.183 135.638 562.985 134.835 562.985 133.844C562.985 132.852 562.183 132.049 561.194 132.049C560.205 132.049 559.403 132.852 559.403 133.844C559.403 134.835 560.205 135.638 561.194 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 135.638C556.213 135.638 557.015 134.835 557.015 133.844C557.015 132.852 556.213 132.049 555.224 132.049C554.235 132.049 553.433 132.852 553.433 133.844C553.433 134.835 554.235 135.638 555.224 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 135.638C544.273 135.638 545.075 134.835 545.075 133.844C545.075 132.852 544.273 132.049 543.284 132.049C542.294 132.049 541.492 132.852 541.492 133.844C541.492 134.835 542.294 135.638 543.284 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 135.638C538.303 135.638 539.104 134.835 539.104 133.844C539.104 132.852 538.303 132.049 537.313 132.049C536.324 132.049 535.522 132.852 535.522 133.844C535.522 134.835 536.324 135.638 537.313 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 135.638C532.332 135.638 533.134 134.835 533.134 133.844C533.134 132.852 532.332 132.049 531.343 132.049C530.354 132.049 529.552 132.852 529.552 133.844C529.552 134.835 530.354 135.638 531.343 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 129.656C1027.85 129.656 1028.66 128.853 1028.66 127.861C1028.66 126.87 1027.85 126.067 1026.87 126.067C1025.88 126.067 1025.07 126.87 1025.07 127.861C1025.07 128.853 1025.88 129.656 1026.87 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 129.656C1015.91 129.656 1016.72 128.853 1016.72 127.861C1016.72 126.87 1015.91 126.067 1014.93 126.067C1013.94 126.067 1013.13 126.87 1013.13 127.861C1013.13 128.853 1013.94 129.656 1014.93 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 129.656C1009.94 129.656 1010.75 128.853 1010.75 127.861C1010.75 126.87 1009.94 126.067 1008.96 126.067C1007.97 126.067 1007.16 126.87 1007.16 127.861C1007.16 128.853 1007.97 129.656 1008.96 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 129.656C1003.97 129.656 1004.78 128.853 1004.78 127.861C1004.78 126.87 1003.97 126.067 1002.99 126.067C1002 126.067 1001.19 126.87 1001.19 127.861C1001.19 128.853 1002 129.656 1002.99 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 129.656C998.004 129.656 998.806 128.853 998.806 127.861C998.806 126.87 998.004 126.067 997.015 126.067C996.026 126.067 995.224 126.87 995.224 127.861C995.224 128.853 996.026 129.656 997.015 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 129.656C992.034 129.656 992.836 128.853 992.836 127.861C992.836 126.87 992.034 126.067 991.045 126.067C990.056 126.067 989.254 126.87 989.254 127.861C989.254 128.853 990.056 129.656 991.045 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 129.656C986.064 129.656 986.866 128.853 986.866 127.861C986.866 126.87 986.064 126.067 985.075 126.067C984.085 126.067 983.284 126.87 983.284 127.861C983.284 128.853 984.085 129.656 985.075 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 129.656C980.094 129.656 980.896 128.853 980.896 127.861C980.896 126.87 980.094 126.067 979.104 126.067C978.115 126.067 977.313 126.87 977.313 127.861C977.313 128.853 978.115 129.656 979.104 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 129.656C974.123 129.656 974.925 128.853 974.925 127.861C974.925 126.87 974.123 126.067 973.134 126.067C972.145 126.067 971.343 126.87 971.343 127.861C971.343 128.853 972.145 129.656 973.134 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 129.656C968.153 129.656 968.955 128.853 968.955 127.861C968.955 126.87 968.153 126.067 967.164 126.067C966.175 126.067 965.373 126.87 965.373 127.861C965.373 128.853 966.175 129.656 967.164 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 129.656C962.183 129.656 962.985 128.853 962.985 127.861C962.985 126.87 962.183 126.067 961.194 126.067C960.205 126.067 959.403 126.87 959.403 127.861C959.403 128.853 960.205 129.656 961.194 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 129.656C956.213 129.656 957.015 128.853 957.015 127.861C957.015 126.87 956.213 126.067 955.224 126.067C954.235 126.067 953.433 126.87 953.433 127.861C953.433 128.853 954.235 129.656 955.224 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 129.656C950.243 129.656 951.045 128.853 951.045 127.861C951.045 126.87 950.243 126.067 949.254 126.067C948.265 126.067 947.463 126.87 947.463 127.861C947.463 128.853 948.265 129.656 949.254 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 129.656C944.273 129.656 945.075 128.853 945.075 127.861C945.075 126.87 944.273 126.067 943.284 126.067C942.294 126.067 941.492 126.87 941.492 127.861C941.492 128.853 942.294 129.656 943.284 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 129.656C938.303 129.656 939.105 128.853 939.105 127.861C939.105 126.87 938.303 126.067 937.313 126.067C936.324 126.067 935.522 126.87 935.522 127.861C935.522 128.853 936.324 129.656 937.313 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 129.656C932.332 129.656 933.134 128.853 933.134 127.861C933.134 126.87 932.332 126.067 931.343 126.067C930.354 126.067 929.552 126.87 929.552 127.861C929.552 128.853 930.354 129.656 931.343 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 129.656C926.362 129.656 927.164 128.853 927.164 127.861C927.164 126.87 926.362 126.067 925.373 126.067C924.384 126.067 923.582 126.87 923.582 127.861C923.582 128.853 924.384 129.656 925.373 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 129.656C920.392 129.656 921.194 128.853 921.194 127.861C921.194 126.87 920.392 126.067 919.403 126.067C918.414 126.067 917.612 126.87 917.612 127.861C917.612 128.853 918.414 129.656 919.403 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 129.656C914.422 129.656 915.224 128.853 915.224 127.861C915.224 126.87 914.422 126.067 913.433 126.067C912.444 126.067 911.642 126.87 911.642 127.861C911.642 128.853 912.444 129.656 913.433 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 129.656C908.452 129.656 909.254 128.853 909.254 127.861C909.254 126.87 908.452 126.067 907.463 126.067C906.474 126.067 905.672 126.87 905.672 127.861C905.672 128.853 906.474 129.656 907.463 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 129.656C902.482 129.656 903.284 128.853 903.284 127.861C903.284 126.87 902.482 126.067 901.492 126.067C900.503 126.067 899.701 126.87 899.701 127.861C899.701 128.853 900.503 129.656 901.492 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 129.656C896.512 129.656 897.313 128.853 897.313 127.861C897.313 126.87 896.512 126.067 895.522 126.067C894.533 126.067 893.731 126.87 893.731 127.861C893.731 128.853 894.533 129.656 895.522 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 129.656C890.541 129.656 891.343 128.853 891.343 127.861C891.343 126.87 890.541 126.067 889.552 126.067C888.563 126.067 887.761 126.87 887.761 127.861C887.761 128.853 888.563 129.656 889.552 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 129.656C884.571 129.656 885.373 128.853 885.373 127.861C885.373 126.87 884.571 126.067 883.582 126.067C882.593 126.067 881.791 126.87 881.791 127.861C881.791 128.853 882.593 129.656 883.582 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 129.656C878.601 129.656 879.403 128.853 879.403 127.861C879.403 126.87 878.601 126.067 877.612 126.067C876.623 126.067 875.821 126.87 875.821 127.861C875.821 128.853 876.623 129.656 877.612 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 129.656C872.631 129.656 873.433 128.853 873.433 127.861C873.433 126.87 872.631 126.067 871.642 126.067C870.653 126.067 869.851 126.87 869.851 127.861C869.851 128.853 870.653 129.656 871.642 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 129.656C866.661 129.656 867.463 128.853 867.463 127.861C867.463 126.87 866.661 126.067 865.672 126.067C864.683 126.067 863.881 126.87 863.881 127.861C863.881 128.853 864.683 129.656 865.672 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 129.656C860.691 129.656 861.493 128.853 861.493 127.861C861.493 126.87 860.691 126.067 859.701 126.067C858.712 126.067 857.91 126.87 857.91 127.861C857.91 128.853 858.712 129.656 859.701 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 129.656C854.721 129.656 855.522 128.853 855.522 127.861C855.522 126.87 854.721 126.067 853.731 126.067C852.742 126.067 851.94 126.87 851.94 127.861C851.94 128.853 852.742 129.656 853.731 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 129.656C848.75 129.656 849.552 128.853 849.552 127.861C849.552 126.87 848.75 126.067 847.761 126.067C846.772 126.067 845.97 126.87 845.97 127.861C845.97 128.853 846.772 129.656 847.761 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 129.656C842.78 129.656 843.582 128.853 843.582 127.861C843.582 126.87 842.78 126.067 841.791 126.067C840.802 126.067 840 126.87 840 127.861C840 128.853 840.802 129.656 841.791 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 129.656C836.81 129.656 837.612 128.853 837.612 127.861C837.612 126.87 836.81 126.067 835.821 126.067C834.832 126.067 834.03 126.87 834.03 127.861C834.03 128.853 834.832 129.656 835.821 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 129.656C830.84 129.656 831.642 128.853 831.642 127.861C831.642 126.87 830.84 126.067 829.851 126.067C828.862 126.067 828.06 126.87 828.06 127.861C828.06 128.853 828.862 129.656 829.851 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 129.656C824.87 129.656 825.672 128.853 825.672 127.861C825.672 126.87 824.87 126.067 823.881 126.067C822.891 126.067 822.09 126.87 822.09 127.861C822.09 128.853 822.891 129.656 823.881 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 129.656C818.9 129.656 819.701 128.853 819.701 127.861C819.701 126.87 818.9 126.067 817.91 126.067C816.921 126.067 816.119 126.87 816.119 127.861C816.119 128.853 816.921 129.656 817.91 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 129.656C812.929 129.656 813.731 128.853 813.731 127.861C813.731 126.87 812.929 126.067 811.94 126.067C810.951 126.067 810.149 126.87 810.149 127.861C810.149 128.853 810.951 129.656 811.94 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 129.656C806.959 129.656 807.761 128.853 807.761 127.861C807.761 126.87 806.959 126.067 805.97 126.067C804.981 126.067 804.179 126.87 804.179 127.861C804.179 128.853 804.981 129.656 805.97 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M800 129.656C800.989 129.656 801.791 128.853 801.791 127.861C801.791 126.87 800.989 126.067 800 126.067C799.011 126.067 798.209 126.87 798.209 127.861C798.209 128.853 799.011 129.656 800 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 129.656C795.019 129.656 795.821 128.853 795.821 127.861C795.821 126.87 795.019 126.067 794.03 126.067C793.041 126.067 792.239 126.87 792.239 127.861C792.239 128.853 793.041 129.656 794.03 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 129.656C789.049 129.656 789.851 128.853 789.851 127.861C789.851 126.87 789.049 126.067 788.06 126.067C787.071 126.067 786.269 126.87 786.269 127.861C786.269 128.853 787.071 129.656 788.06 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 129.656C783.079 129.656 783.881 128.853 783.881 127.861C783.881 126.87 783.079 126.067 782.09 126.067C781.1 126.067 780.299 126.87 780.299 127.861C780.299 128.853 781.1 129.656 782.09 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 129.656C777.109 129.656 777.91 128.853 777.91 127.861C777.91 126.87 777.109 126.067 776.119 126.067C775.13 126.067 774.328 126.87 774.328 127.861C774.328 128.853 775.13 129.656 776.119 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 129.656C771.138 129.656 771.94 128.853 771.94 127.861C771.94 126.87 771.138 126.067 770.149 126.067C769.16 126.067 768.358 126.87 768.358 127.861C768.358 128.853 769.16 129.656 770.149 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 129.656C765.168 129.656 765.97 128.853 765.97 127.861C765.97 126.87 765.168 126.067 764.179 126.067C763.19 126.067 762.388 126.87 762.388 127.861C762.388 128.853 763.19 129.656 764.179 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 129.656C759.198 129.656 760 128.853 760 127.861C760 126.87 759.198 126.067 758.209 126.067C757.22 126.067 756.418 126.87 756.418 127.861C756.418 128.853 757.22 129.656 758.209 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 129.656C753.228 129.656 754.03 128.853 754.03 127.861C754.03 126.87 753.228 126.067 752.239 126.067C751.25 126.067 750.448 126.87 750.448 127.861C750.448 128.853 751.25 129.656 752.239 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 129.656C747.258 129.656 748.06 128.853 748.06 127.861C748.06 126.87 747.258 126.067 746.269 126.067C745.279 126.067 744.478 126.87 744.478 127.861C744.478 128.853 745.279 129.656 746.269 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 129.656C741.288 129.656 742.09 128.853 742.09 127.861C742.09 126.87 741.288 126.067 740.299 126.067C739.309 126.067 738.507 126.87 738.507 127.861C738.507 128.853 739.309 129.656 740.299 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 129.656C735.318 129.656 736.119 128.853 736.119 127.861C736.119 126.87 735.318 126.067 734.328 126.067C733.339 126.067 732.537 126.87 732.537 127.861C732.537 128.853 733.339 129.656 734.328 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 129.656C729.347 129.656 730.149 128.853 730.149 127.861C730.149 126.87 729.347 126.067 728.358 126.067C727.369 126.067 726.567 126.87 726.567 127.861C726.567 128.853 727.369 129.656 728.358 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 129.656C723.377 129.656 724.179 128.853 724.179 127.861C724.179 126.87 723.377 126.067 722.388 126.067C721.399 126.067 720.597 126.87 720.597 127.861C720.597 128.853 721.399 129.656 722.388 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 129.656C717.407 129.656 718.209 128.853 718.209 127.861C718.209 126.87 717.407 126.067 716.418 126.067C715.429 126.067 714.627 126.87 714.627 127.861C714.627 128.853 715.429 129.656 716.418 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 129.656C711.437 129.656 712.239 128.853 712.239 127.861C712.239 126.87 711.437 126.067 710.448 126.067C709.459 126.067 708.657 126.87 708.657 127.861C708.657 128.853 709.459 129.656 710.448 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 129.656C705.467 129.656 706.269 128.853 706.269 127.861C706.269 126.87 705.467 126.067 704.478 126.067C703.488 126.067 702.687 126.87 702.687 127.861C702.687 128.853 703.488 129.656 704.478 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 129.656C699.497 129.656 700.299 128.853 700.299 127.861C700.299 126.87 699.497 126.067 698.507 126.067C697.518 126.067 696.716 126.87 696.716 127.861C696.716 128.853 697.518 129.656 698.507 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 129.656C693.526 129.656 694.328 128.853 694.328 127.861C694.328 126.87 693.526 126.067 692.537 126.067C691.548 126.067 690.746 126.87 690.746 127.861C690.746 128.853 691.548 129.656 692.537 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 129.656C687.556 129.656 688.358 128.853 688.358 127.861C688.358 126.87 687.556 126.067 686.567 126.067C685.578 126.067 684.776 126.87 684.776 127.861C684.776 128.853 685.578 129.656 686.567 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 129.656C681.586 129.656 682.388 128.853 682.388 127.861C682.388 126.87 681.586 126.067 680.597 126.067C679.608 126.067 678.806 126.87 678.806 127.861C678.806 128.853 679.608 129.656 680.597 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 129.656C675.616 129.656 676.418 128.853 676.418 127.861C676.418 126.87 675.616 126.067 674.627 126.067C673.638 126.067 672.836 126.87 672.836 127.861C672.836 128.853 673.638 129.656 674.627 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 129.656C669.646 129.656 670.448 128.853 670.448 127.861C670.448 126.87 669.646 126.067 668.657 126.067C667.668 126.067 666.866 126.87 666.866 127.861C666.866 128.853 667.668 129.656 668.657 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 129.656C663.676 129.656 664.478 128.853 664.478 127.861C664.478 126.87 663.676 126.067 662.687 126.067C661.697 126.067 660.896 126.87 660.896 127.861C660.896 128.853 661.697 129.656 662.687 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 129.656C657.706 129.656 658.507 128.853 658.507 127.861C658.507 126.87 657.706 126.067 656.716 126.067C655.727 126.067 654.925 126.87 654.925 127.861C654.925 128.853 655.727 129.656 656.716 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 129.656C651.735 129.656 652.537 128.853 652.537 127.861C652.537 126.87 651.735 126.067 650.746 126.067C649.757 126.067 648.955 126.87 648.955 127.861C648.955 128.853 649.757 129.656 650.746 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 129.656C645.765 129.656 646.567 128.853 646.567 127.861C646.567 126.87 645.765 126.067 644.776 126.067C643.787 126.067 642.985 126.87 642.985 127.861C642.985 128.853 643.787 129.656 644.776 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 129.656C639.795 129.656 640.597 128.853 640.597 127.861C640.597 126.87 639.795 126.067 638.806 126.067C637.817 126.067 637.015 126.87 637.015 127.861C637.015 128.853 637.817 129.656 638.806 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 129.656C633.825 129.656 634.627 128.853 634.627 127.861C634.627 126.87 633.825 126.067 632.836 126.067C631.847 126.067 631.045 126.87 631.045 127.861C631.045 128.853 631.847 129.656 632.836 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 129.656C627.855 129.656 628.657 128.853 628.657 127.861C628.657 126.87 627.855 126.067 626.866 126.067C625.876 126.067 625.075 126.87 625.075 127.861C625.075 128.853 625.876 129.656 626.866 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 129.656C621.885 129.656 622.687 128.853 622.687 127.861C622.687 126.87 621.885 126.067 620.895 126.067C619.906 126.067 619.104 126.87 619.104 127.861C619.104 128.853 619.906 129.656 620.895 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 129.656C615.915 129.656 616.716 128.853 616.716 127.861C616.716 126.87 615.915 126.067 614.925 126.067C613.936 126.067 613.134 126.87 613.134 127.861C613.134 128.853 613.936 129.656 614.925 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 129.656C609.944 129.656 610.746 128.853 610.746 127.861C610.746 126.87 609.944 126.067 608.955 126.067C607.966 126.067 607.164 126.87 607.164 127.861C607.164 128.853 607.966 129.656 608.955 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 129.656C603.974 129.656 604.776 128.853 604.776 127.861C604.776 126.87 603.974 126.067 602.985 126.067C601.996 126.067 601.194 126.87 601.194 127.861C601.194 128.853 601.996 129.656 602.985 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 129.656C598.004 129.656 598.806 128.853 598.806 127.861C598.806 126.87 598.004 126.067 597.015 126.067C596.026 126.067 595.224 126.87 595.224 127.861C595.224 128.853 596.026 129.656 597.015 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 129.656C592.034 129.656 592.836 128.853 592.836 127.861C592.836 126.87 592.034 126.067 591.045 126.067C590.056 126.067 589.254 126.87 589.254 127.861C589.254 128.853 590.056 129.656 591.045 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 129.656C580.094 129.656 580.896 128.853 580.896 127.861C580.896 126.87 580.094 126.067 579.104 126.067C578.115 126.067 577.313 126.87 577.313 127.861C577.313 128.853 578.115 129.656 579.104 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 129.656C550.243 129.656 551.045 128.853 551.045 127.861C551.045 126.87 550.243 126.067 549.254 126.067C548.265 126.067 547.463 126.87 547.463 127.861C547.463 128.853 548.265 129.656 549.254 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 129.656C544.273 129.656 545.075 128.853 545.075 127.861C545.075 126.87 544.273 126.067 543.284 126.067C542.294 126.067 541.492 126.87 541.492 127.861C541.492 128.853 542.294 129.656 543.284 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 129.656C526.362 129.656 527.164 128.853 527.164 127.861C527.164 126.87 526.362 126.067 525.373 126.067C524.384 126.067 523.582 126.87 523.582 127.861C523.582 128.853 524.384 129.656 525.373 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 129.656C520.392 129.656 521.194 128.853 521.194 127.861C521.194 126.87 520.392 126.067 519.403 126.067C518.414 126.067 517.612 126.87 517.612 127.861C517.612 128.853 518.414 129.656 519.403 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 123.674C1069.65 123.674 1070.45 122.87 1070.45 121.879C1070.45 120.888 1069.65 120.084 1068.66 120.084C1067.67 120.084 1066.87 120.888 1066.87 121.879C1066.87 122.87 1067.67 123.674 1068.66 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 123.674C1021.88 123.674 1022.69 122.87 1022.69 121.879C1022.69 120.888 1021.88 120.084 1020.9 120.084C1019.91 120.084 1019.1 120.888 1019.1 121.879C1019.1 122.87 1019.91 123.674 1020.9 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 123.674C1009.94 123.674 1010.75 122.87 1010.75 121.879C1010.75 120.888 1009.94 120.084 1008.96 120.084C1007.97 120.084 1007.16 120.888 1007.16 121.879C1007.16 122.87 1007.97 123.674 1008.96 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 123.674C1003.97 123.674 1004.78 122.87 1004.78 121.879C1004.78 120.888 1003.97 120.084 1002.99 120.084C1002 120.084 1001.19 120.888 1001.19 121.879C1001.19 122.87 1002 123.674 1002.99 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 123.674C998.004 123.674 998.806 122.87 998.806 121.879C998.806 120.888 998.004 120.084 997.015 120.084C996.026 120.084 995.224 120.888 995.224 121.879C995.224 122.87 996.026 123.674 997.015 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 123.674C992.034 123.674 992.836 122.87 992.836 121.879C992.836 120.888 992.034 120.084 991.045 120.084C990.056 120.084 989.254 120.888 989.254 121.879C989.254 122.87 990.056 123.674 991.045 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 123.674C986.064 123.674 986.866 122.87 986.866 121.879C986.866 120.888 986.064 120.084 985.075 120.084C984.085 120.084 983.284 120.888 983.284 121.879C983.284 122.87 984.085 123.674 985.075 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 123.674C980.094 123.674 980.896 122.87 980.896 121.879C980.896 120.888 980.094 120.084 979.104 120.084C978.115 120.084 977.313 120.888 977.313 121.879C977.313 122.87 978.115 123.674 979.104 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 123.674C974.123 123.674 974.925 122.87 974.925 121.879C974.925 120.888 974.123 120.084 973.134 120.084C972.145 120.084 971.343 120.888 971.343 121.879C971.343 122.87 972.145 123.674 973.134 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 123.674C968.153 123.674 968.955 122.87 968.955 121.879C968.955 120.888 968.153 120.084 967.164 120.084C966.175 120.084 965.373 120.888 965.373 121.879C965.373 122.87 966.175 123.674 967.164 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 123.674C962.183 123.674 962.985 122.87 962.985 121.879C962.985 120.888 962.183 120.084 961.194 120.084C960.205 120.084 959.403 120.888 959.403 121.879C959.403 122.87 960.205 123.674 961.194 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 123.674C956.213 123.674 957.015 122.87 957.015 121.879C957.015 120.888 956.213 120.084 955.224 120.084C954.235 120.084 953.433 120.888 953.433 121.879C953.433 122.87 954.235 123.674 955.224 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 123.674C950.243 123.674 951.045 122.87 951.045 121.879C951.045 120.888 950.243 120.084 949.254 120.084C948.265 120.084 947.463 120.888 947.463 121.879C947.463 122.87 948.265 123.674 949.254 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 123.674C944.273 123.674 945.075 122.87 945.075 121.879C945.075 120.888 944.273 120.084 943.284 120.084C942.294 120.084 941.492 120.888 941.492 121.879C941.492 122.87 942.294 123.674 943.284 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 123.674C938.303 123.674 939.105 122.87 939.105 121.879C939.105 120.888 938.303 120.084 937.313 120.084C936.324 120.084 935.522 120.888 935.522 121.879C935.522 122.87 936.324 123.674 937.313 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 123.674C932.332 123.674 933.134 122.87 933.134 121.879C933.134 120.888 932.332 120.084 931.343 120.084C930.354 120.084 929.552 120.888 929.552 121.879C929.552 122.87 930.354 123.674 931.343 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 123.674C926.362 123.674 927.164 122.87 927.164 121.879C927.164 120.888 926.362 120.084 925.373 120.084C924.384 120.084 923.582 120.888 923.582 121.879C923.582 122.87 924.384 123.674 925.373 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 123.674C920.392 123.674 921.194 122.87 921.194 121.879C921.194 120.888 920.392 120.084 919.403 120.084C918.414 120.084 917.612 120.888 917.612 121.879C917.612 122.87 918.414 123.674 919.403 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 123.674C914.422 123.674 915.224 122.87 915.224 121.879C915.224 120.888 914.422 120.084 913.433 120.084C912.444 120.084 911.642 120.888 911.642 121.879C911.642 122.87 912.444 123.674 913.433 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 123.674C908.452 123.674 909.254 122.87 909.254 121.879C909.254 120.888 908.452 120.084 907.463 120.084C906.474 120.084 905.672 120.888 905.672 121.879C905.672 122.87 906.474 123.674 907.463 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 123.674C902.482 123.674 903.284 122.87 903.284 121.879C903.284 120.888 902.482 120.084 901.492 120.084C900.503 120.084 899.701 120.888 899.701 121.879C899.701 122.87 900.503 123.674 901.492 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 123.674C896.512 123.674 897.313 122.87 897.313 121.879C897.313 120.888 896.512 120.084 895.522 120.084C894.533 120.084 893.731 120.888 893.731 121.879C893.731 122.87 894.533 123.674 895.522 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 123.674C890.541 123.674 891.343 122.87 891.343 121.879C891.343 120.888 890.541 120.084 889.552 120.084C888.563 120.084 887.761 120.888 887.761 121.879C887.761 122.87 888.563 123.674 889.552 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 123.674C884.571 123.674 885.373 122.87 885.373 121.879C885.373 120.888 884.571 120.084 883.582 120.084C882.593 120.084 881.791 120.888 881.791 121.879C881.791 122.87 882.593 123.674 883.582 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 123.674C878.601 123.674 879.403 122.87 879.403 121.879C879.403 120.888 878.601 120.084 877.612 120.084C876.623 120.084 875.821 120.888 875.821 121.879C875.821 122.87 876.623 123.674 877.612 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 123.674C872.631 123.674 873.433 122.87 873.433 121.879C873.433 120.888 872.631 120.084 871.642 120.084C870.653 120.084 869.851 120.888 869.851 121.879C869.851 122.87 870.653 123.674 871.642 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 123.674C866.661 123.674 867.463 122.87 867.463 121.879C867.463 120.888 866.661 120.084 865.672 120.084C864.683 120.084 863.881 120.888 863.881 121.879C863.881 122.87 864.683 123.674 865.672 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 123.674C860.691 123.674 861.493 122.87 861.493 121.879C861.493 120.888 860.691 120.084 859.701 120.084C858.712 120.084 857.91 120.888 857.91 121.879C857.91 122.87 858.712 123.674 859.701 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 123.674C854.721 123.674 855.522 122.87 855.522 121.879C855.522 120.888 854.721 120.084 853.731 120.084C852.742 120.084 851.94 120.888 851.94 121.879C851.94 122.87 852.742 123.674 853.731 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 123.674C848.75 123.674 849.552 122.87 849.552 121.879C849.552 120.888 848.75 120.084 847.761 120.084C846.772 120.084 845.97 120.888 845.97 121.879C845.97 122.87 846.772 123.674 847.761 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 123.674C842.78 123.674 843.582 122.87 843.582 121.879C843.582 120.888 842.78 120.084 841.791 120.084C840.802 120.084 840 120.888 840 121.879C840 122.87 840.802 123.674 841.791 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 123.674C836.81 123.674 837.612 122.87 837.612 121.879C837.612 120.888 836.81 120.084 835.821 120.084C834.832 120.084 834.03 120.888 834.03 121.879C834.03 122.87 834.832 123.674 835.821 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 123.674C830.84 123.674 831.642 122.87 831.642 121.879C831.642 120.888 830.84 120.084 829.851 120.084C828.862 120.084 828.06 120.888 828.06 121.879C828.06 122.87 828.862 123.674 829.851 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 123.674C824.87 123.674 825.672 122.87 825.672 121.879C825.672 120.888 824.87 120.084 823.881 120.084C822.891 120.084 822.09 120.888 822.09 121.879C822.09 122.87 822.891 123.674 823.881 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 123.674C818.9 123.674 819.701 122.87 819.701 121.879C819.701 120.888 818.9 120.084 817.91 120.084C816.921 120.084 816.119 120.888 816.119 121.879C816.119 122.87 816.921 123.674 817.91 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 123.674C812.929 123.674 813.731 122.87 813.731 121.879C813.731 120.888 812.929 120.084 811.94 120.084C810.951 120.084 810.149 120.888 810.149 121.879C810.149 122.87 810.951 123.674 811.94 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 123.674C806.959 123.674 807.761 122.87 807.761 121.879C807.761 120.888 806.959 120.084 805.97 120.084C804.981 120.084 804.179 120.888 804.179 121.879C804.179 122.87 804.981 123.674 805.97 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M800 123.674C800.989 123.674 801.791 122.87 801.791 121.879C801.791 120.888 800.989 120.084 800 120.084C799.011 120.084 798.209 120.888 798.209 121.879C798.209 122.87 799.011 123.674 800 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 123.674C795.019 123.674 795.821 122.87 795.821 121.879C795.821 120.888 795.019 120.084 794.03 120.084C793.041 120.084 792.239 120.888 792.239 121.879C792.239 122.87 793.041 123.674 794.03 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 123.674C789.049 123.674 789.851 122.87 789.851 121.879C789.851 120.888 789.049 120.084 788.06 120.084C787.071 120.084 786.269 120.888 786.269 121.879C786.269 122.87 787.071 123.674 788.06 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 123.674C783.079 123.674 783.881 122.87 783.881 121.879C783.881 120.888 783.079 120.084 782.09 120.084C781.1 120.084 780.299 120.888 780.299 121.879C780.299 122.87 781.1 123.674 782.09 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 123.674C777.109 123.674 777.91 122.87 777.91 121.879C777.91 120.888 777.109 120.084 776.119 120.084C775.13 120.084 774.328 120.888 774.328 121.879C774.328 122.87 775.13 123.674 776.119 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 123.674C771.138 123.674 771.94 122.87 771.94 121.879C771.94 120.888 771.138 120.084 770.149 120.084C769.16 120.084 768.358 120.888 768.358 121.879C768.358 122.87 769.16 123.674 770.149 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 123.674C765.168 123.674 765.97 122.87 765.97 121.879C765.97 120.888 765.168 120.084 764.179 120.084C763.19 120.084 762.388 120.888 762.388 121.879C762.388 122.87 763.19 123.674 764.179 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 123.674C759.198 123.674 760 122.87 760 121.879C760 120.888 759.198 120.084 758.209 120.084C757.22 120.084 756.418 120.888 756.418 121.879C756.418 122.87 757.22 123.674 758.209 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 123.674C753.228 123.674 754.03 122.87 754.03 121.879C754.03 120.888 753.228 120.084 752.239 120.084C751.25 120.084 750.448 120.888 750.448 121.879C750.448 122.87 751.25 123.674 752.239 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 123.674C747.258 123.674 748.06 122.87 748.06 121.879C748.06 120.888 747.258 120.084 746.269 120.084C745.279 120.084 744.478 120.888 744.478 121.879C744.478 122.87 745.279 123.674 746.269 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 123.674C741.288 123.674 742.09 122.87 742.09 121.879C742.09 120.888 741.288 120.084 740.299 120.084C739.309 120.084 738.507 120.888 738.507 121.879C738.507 122.87 739.309 123.674 740.299 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 123.674C735.318 123.674 736.119 122.87 736.119 121.879C736.119 120.888 735.318 120.084 734.328 120.084C733.339 120.084 732.537 120.888 732.537 121.879C732.537 122.87 733.339 123.674 734.328 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 123.674C729.347 123.674 730.149 122.87 730.149 121.879C730.149 120.888 729.347 120.084 728.358 120.084C727.369 120.084 726.567 120.888 726.567 121.879C726.567 122.87 727.369 123.674 728.358 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 123.674C723.377 123.674 724.179 122.87 724.179 121.879C724.179 120.888 723.377 120.084 722.388 120.084C721.399 120.084 720.597 120.888 720.597 121.879C720.597 122.87 721.399 123.674 722.388 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 123.674C717.407 123.674 718.209 122.87 718.209 121.879C718.209 120.888 717.407 120.084 716.418 120.084C715.429 120.084 714.627 120.888 714.627 121.879C714.627 122.87 715.429 123.674 716.418 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 123.674C711.437 123.674 712.239 122.87 712.239 121.879C712.239 120.888 711.437 120.084 710.448 120.084C709.459 120.084 708.657 120.888 708.657 121.879C708.657 122.87 709.459 123.674 710.448 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 123.674C705.467 123.674 706.269 122.87 706.269 121.879C706.269 120.888 705.467 120.084 704.478 120.084C703.488 120.084 702.687 120.888 702.687 121.879C702.687 122.87 703.488 123.674 704.478 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 123.674C699.497 123.674 700.299 122.87 700.299 121.879C700.299 120.888 699.497 120.084 698.507 120.084C697.518 120.084 696.716 120.888 696.716 121.879C696.716 122.87 697.518 123.674 698.507 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 123.674C693.526 123.674 694.328 122.87 694.328 121.879C694.328 120.888 693.526 120.084 692.537 120.084C691.548 120.084 690.746 120.888 690.746 121.879C690.746 122.87 691.548 123.674 692.537 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 123.674C687.556 123.674 688.358 122.87 688.358 121.879C688.358 120.888 687.556 120.084 686.567 120.084C685.578 120.084 684.776 120.888 684.776 121.879C684.776 122.87 685.578 123.674 686.567 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 123.674C681.586 123.674 682.388 122.87 682.388 121.879C682.388 120.888 681.586 120.084 680.597 120.084C679.608 120.084 678.806 120.888 678.806 121.879C678.806 122.87 679.608 123.674 680.597 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 123.674C675.616 123.674 676.418 122.87 676.418 121.879C676.418 120.888 675.616 120.084 674.627 120.084C673.638 120.084 672.836 120.888 672.836 121.879C672.836 122.87 673.638 123.674 674.627 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 123.674C669.646 123.674 670.448 122.87 670.448 121.879C670.448 120.888 669.646 120.084 668.657 120.084C667.668 120.084 666.866 120.888 666.866 121.879C666.866 122.87 667.668 123.674 668.657 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 123.674C663.676 123.674 664.478 122.87 664.478 121.879C664.478 120.888 663.676 120.084 662.687 120.084C661.697 120.084 660.896 120.888 660.896 121.879C660.896 122.87 661.697 123.674 662.687 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 123.674C657.706 123.674 658.507 122.87 658.507 121.879C658.507 120.888 657.706 120.084 656.716 120.084C655.727 120.084 654.925 120.888 654.925 121.879C654.925 122.87 655.727 123.674 656.716 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 123.674C651.735 123.674 652.537 122.87 652.537 121.879C652.537 120.888 651.735 120.084 650.746 120.084C649.757 120.084 648.955 120.888 648.955 121.879C648.955 122.87 649.757 123.674 650.746 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 123.674C645.765 123.674 646.567 122.87 646.567 121.879C646.567 120.888 645.765 120.084 644.776 120.084C643.787 120.084 642.985 120.888 642.985 121.879C642.985 122.87 643.787 123.674 644.776 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 123.674C639.795 123.674 640.597 122.87 640.597 121.879C640.597 120.888 639.795 120.084 638.806 120.084C637.817 120.084 637.015 120.888 637.015 121.879C637.015 122.87 637.817 123.674 638.806 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 123.674C633.825 123.674 634.627 122.87 634.627 121.879C634.627 120.888 633.825 120.084 632.836 120.084C631.847 120.084 631.045 120.888 631.045 121.879C631.045 122.87 631.847 123.674 632.836 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 123.674C627.855 123.674 628.657 122.87 628.657 121.879C628.657 120.888 627.855 120.084 626.866 120.084C625.876 120.084 625.075 120.888 625.075 121.879C625.075 122.87 625.876 123.674 626.866 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 123.674C621.885 123.674 622.687 122.87 622.687 121.879C622.687 120.888 621.885 120.084 620.895 120.084C619.906 120.084 619.104 120.888 619.104 121.879C619.104 122.87 619.906 123.674 620.895 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 123.674C615.915 123.674 616.716 122.87 616.716 121.879C616.716 120.888 615.915 120.084 614.925 120.084C613.936 120.084 613.134 120.888 613.134 121.879C613.134 122.87 613.936 123.674 614.925 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 123.674C609.944 123.674 610.746 122.87 610.746 121.879C610.746 120.888 609.944 120.084 608.955 120.084C607.966 120.084 607.164 120.888 607.164 121.879C607.164 122.87 607.966 123.674 608.955 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 123.674C598.004 123.674 598.806 122.87 598.806 121.879C598.806 120.888 598.004 120.084 597.015 120.084C596.026 120.084 595.224 120.888 595.224 121.879C595.224 122.87 596.026 123.674 597.015 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 123.674C592.034 123.674 592.836 122.87 592.836 121.879C592.836 120.888 592.034 120.084 591.045 120.084C590.056 120.084 589.254 120.888 589.254 121.879C589.254 122.87 590.056 123.674 591.045 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 123.674C586.064 123.674 586.866 122.87 586.866 121.879C586.866 120.888 586.064 120.084 585.075 120.084C584.085 120.084 583.284 120.888 583.284 121.879C583.284 122.87 584.085 123.674 585.075 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 123.674C574.123 123.674 574.925 122.87 574.925 121.879C574.925 120.888 574.123 120.084 573.134 120.084C572.145 120.084 571.343 120.888 571.343 121.879C571.343 122.87 572.145 123.674 573.134 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 123.674C550.243 123.674 551.045 122.87 551.045 121.879C551.045 120.888 550.243 120.084 549.254 120.084C548.265 120.084 547.463 120.888 547.463 121.879C547.463 122.87 548.265 123.674 549.254 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 123.674C526.362 123.674 527.164 122.87 527.164 121.879C527.164 120.888 526.362 120.084 525.373 120.084C524.384 120.084 523.582 120.888 523.582 121.879C523.582 122.87 524.384 123.674 525.373 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 123.674C514.422 123.674 515.224 122.87 515.224 121.879C515.224 120.888 514.422 120.084 513.433 120.084C512.444 120.084 511.642 120.888 511.642 121.879C511.642 122.87 512.444 123.674 513.433 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 123.674C508.452 123.674 509.254 122.87 509.254 121.879C509.254 120.888 508.452 120.084 507.463 120.084C506.474 120.084 505.672 120.888 505.672 121.879C505.672 122.87 506.474 123.674 507.463 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 117.691C1069.65 117.691 1070.45 116.888 1070.45 115.897C1070.45 114.906 1069.65 114.102 1068.66 114.102C1067.67 114.102 1066.87 114.906 1066.87 115.897C1066.87 116.888 1067.67 117.691 1068.66 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 117.691C1063.68 117.691 1064.48 116.888 1064.48 115.897C1064.48 114.906 1063.68 114.102 1062.69 114.102C1061.7 114.102 1060.9 114.906 1060.9 115.897C1060.9 116.888 1061.7 117.691 1062.69 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 117.691C1015.91 117.691 1016.72 116.888 1016.72 115.897C1016.72 114.906 1015.91 114.102 1014.93 114.102C1013.94 114.102 1013.13 114.906 1013.13 115.897C1013.13 116.888 1013.94 117.691 1014.93 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 117.691C1009.94 117.691 1010.75 116.888 1010.75 115.897C1010.75 114.906 1009.94 114.102 1008.96 114.102C1007.97 114.102 1007.16 114.906 1007.16 115.897C1007.16 116.888 1007.97 117.691 1008.96 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 117.691C1003.97 117.691 1004.78 116.888 1004.78 115.897C1004.78 114.906 1003.97 114.102 1002.99 114.102C1002 114.102 1001.19 114.906 1001.19 115.897C1001.19 116.888 1002 117.691 1002.99 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 117.691C998.004 117.691 998.806 116.888 998.806 115.897C998.806 114.906 998.004 114.102 997.015 114.102C996.026 114.102 995.224 114.906 995.224 115.897C995.224 116.888 996.026 117.691 997.015 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 117.691C992.034 117.691 992.836 116.888 992.836 115.897C992.836 114.906 992.034 114.102 991.045 114.102C990.056 114.102 989.254 114.906 989.254 115.897C989.254 116.888 990.056 117.691 991.045 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 117.691C986.064 117.691 986.866 116.888 986.866 115.897C986.866 114.906 986.064 114.102 985.075 114.102C984.085 114.102 983.284 114.906 983.284 115.897C983.284 116.888 984.085 117.691 985.075 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 117.691C980.094 117.691 980.896 116.888 980.896 115.897C980.896 114.906 980.094 114.102 979.104 114.102C978.115 114.102 977.313 114.906 977.313 115.897C977.313 116.888 978.115 117.691 979.104 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 117.691C974.123 117.691 974.925 116.888 974.925 115.897C974.925 114.906 974.123 114.102 973.134 114.102C972.145 114.102 971.343 114.906 971.343 115.897C971.343 116.888 972.145 117.691 973.134 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 117.691C968.153 117.691 968.955 116.888 968.955 115.897C968.955 114.906 968.153 114.102 967.164 114.102C966.175 114.102 965.373 114.906 965.373 115.897C965.373 116.888 966.175 117.691 967.164 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 117.691C962.183 117.691 962.985 116.888 962.985 115.897C962.985 114.906 962.183 114.102 961.194 114.102C960.205 114.102 959.403 114.906 959.403 115.897C959.403 116.888 960.205 117.691 961.194 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 117.691C956.213 117.691 957.015 116.888 957.015 115.897C957.015 114.906 956.213 114.102 955.224 114.102C954.235 114.102 953.433 114.906 953.433 115.897C953.433 116.888 954.235 117.691 955.224 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 117.691C950.243 117.691 951.045 116.888 951.045 115.897C951.045 114.906 950.243 114.102 949.254 114.102C948.265 114.102 947.463 114.906 947.463 115.897C947.463 116.888 948.265 117.691 949.254 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 117.691C944.273 117.691 945.075 116.888 945.075 115.897C945.075 114.906 944.273 114.102 943.284 114.102C942.294 114.102 941.492 114.906 941.492 115.897C941.492 116.888 942.294 117.691 943.284 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 117.691C938.303 117.691 939.105 116.888 939.105 115.897C939.105 114.906 938.303 114.102 937.313 114.102C936.324 114.102 935.522 114.906 935.522 115.897C935.522 116.888 936.324 117.691 937.313 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 117.691C932.332 117.691 933.134 116.888 933.134 115.897C933.134 114.906 932.332 114.102 931.343 114.102C930.354 114.102 929.552 114.906 929.552 115.897C929.552 116.888 930.354 117.691 931.343 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 117.691C926.362 117.691 927.164 116.888 927.164 115.897C927.164 114.906 926.362 114.102 925.373 114.102C924.384 114.102 923.582 114.906 923.582 115.897C923.582 116.888 924.384 117.691 925.373 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 117.691C920.392 117.691 921.194 116.888 921.194 115.897C921.194 114.906 920.392 114.102 919.403 114.102C918.414 114.102 917.612 114.906 917.612 115.897C917.612 116.888 918.414 117.691 919.403 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 117.691C914.422 117.691 915.224 116.888 915.224 115.897C915.224 114.906 914.422 114.102 913.433 114.102C912.444 114.102 911.642 114.906 911.642 115.897C911.642 116.888 912.444 117.691 913.433 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 117.691C908.452 117.691 909.254 116.888 909.254 115.897C909.254 114.906 908.452 114.102 907.463 114.102C906.474 114.102 905.672 114.906 905.672 115.897C905.672 116.888 906.474 117.691 907.463 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 117.691C902.482 117.691 903.284 116.888 903.284 115.897C903.284 114.906 902.482 114.102 901.492 114.102C900.503 114.102 899.701 114.906 899.701 115.897C899.701 116.888 900.503 117.691 901.492 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 117.691C896.512 117.691 897.313 116.888 897.313 115.897C897.313 114.906 896.512 114.102 895.522 114.102C894.533 114.102 893.731 114.906 893.731 115.897C893.731 116.888 894.533 117.691 895.522 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 117.691C890.541 117.691 891.343 116.888 891.343 115.897C891.343 114.906 890.541 114.102 889.552 114.102C888.563 114.102 887.761 114.906 887.761 115.897C887.761 116.888 888.563 117.691 889.552 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 117.691C884.571 117.691 885.373 116.888 885.373 115.897C885.373 114.906 884.571 114.102 883.582 114.102C882.593 114.102 881.791 114.906 881.791 115.897C881.791 116.888 882.593 117.691 883.582 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 117.691C878.601 117.691 879.403 116.888 879.403 115.897C879.403 114.906 878.601 114.102 877.612 114.102C876.623 114.102 875.821 114.906 875.821 115.897C875.821 116.888 876.623 117.691 877.612 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 117.691C872.631 117.691 873.433 116.888 873.433 115.897C873.433 114.906 872.631 114.102 871.642 114.102C870.653 114.102 869.851 114.906 869.851 115.897C869.851 116.888 870.653 117.691 871.642 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 117.691C866.661 117.691 867.463 116.888 867.463 115.897C867.463 114.906 866.661 114.102 865.672 114.102C864.683 114.102 863.881 114.906 863.881 115.897C863.881 116.888 864.683 117.691 865.672 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 117.691C860.691 117.691 861.493 116.888 861.493 115.897C861.493 114.906 860.691 114.102 859.701 114.102C858.712 114.102 857.91 114.906 857.91 115.897C857.91 116.888 858.712 117.691 859.701 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 117.691C854.721 117.691 855.522 116.888 855.522 115.897C855.522 114.906 854.721 114.102 853.731 114.102C852.742 114.102 851.94 114.906 851.94 115.897C851.94 116.888 852.742 117.691 853.731 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 117.691C848.75 117.691 849.552 116.888 849.552 115.897C849.552 114.906 848.75 114.102 847.761 114.102C846.772 114.102 845.97 114.906 845.97 115.897C845.97 116.888 846.772 117.691 847.761 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 117.691C842.78 117.691 843.582 116.888 843.582 115.897C843.582 114.906 842.78 114.102 841.791 114.102C840.802 114.102 840 114.906 840 115.897C840 116.888 840.802 117.691 841.791 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 117.691C836.81 117.691 837.612 116.888 837.612 115.897C837.612 114.906 836.81 114.102 835.821 114.102C834.832 114.102 834.03 114.906 834.03 115.897C834.03 116.888 834.832 117.691 835.821 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 117.691C830.84 117.691 831.642 116.888 831.642 115.897C831.642 114.906 830.84 114.102 829.851 114.102C828.862 114.102 828.06 114.906 828.06 115.897C828.06 116.888 828.862 117.691 829.851 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 117.691C824.87 117.691 825.672 116.888 825.672 115.897C825.672 114.906 824.87 114.102 823.881 114.102C822.891 114.102 822.09 114.906 822.09 115.897C822.09 116.888 822.891 117.691 823.881 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 117.691C818.9 117.691 819.701 116.888 819.701 115.897C819.701 114.906 818.9 114.102 817.91 114.102C816.921 114.102 816.119 114.906 816.119 115.897C816.119 116.888 816.921 117.691 817.91 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 117.691C812.929 117.691 813.731 116.888 813.731 115.897C813.731 114.906 812.929 114.102 811.94 114.102C810.951 114.102 810.149 114.906 810.149 115.897C810.149 116.888 810.951 117.691 811.94 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 117.691C806.959 117.691 807.761 116.888 807.761 115.897C807.761 114.906 806.959 114.102 805.97 114.102C804.981 114.102 804.179 114.906 804.179 115.897C804.179 116.888 804.981 117.691 805.97 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M800 117.691C800.989 117.691 801.791 116.888 801.791 115.897C801.791 114.906 800.989 114.102 800 114.102C799.011 114.102 798.209 114.906 798.209 115.897C798.209 116.888 799.011 117.691 800 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 117.691C795.019 117.691 795.821 116.888 795.821 115.897C795.821 114.906 795.019 114.102 794.03 114.102C793.041 114.102 792.239 114.906 792.239 115.897C792.239 116.888 793.041 117.691 794.03 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 117.691C789.049 117.691 789.851 116.888 789.851 115.897C789.851 114.906 789.049 114.102 788.06 114.102C787.071 114.102 786.269 114.906 786.269 115.897C786.269 116.888 787.071 117.691 788.06 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 117.691C783.079 117.691 783.881 116.888 783.881 115.897C783.881 114.906 783.079 114.102 782.09 114.102C781.1 114.102 780.299 114.906 780.299 115.897C780.299 116.888 781.1 117.691 782.09 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 117.691C777.109 117.691 777.91 116.888 777.91 115.897C777.91 114.906 777.109 114.102 776.119 114.102C775.13 114.102 774.328 114.906 774.328 115.897C774.328 116.888 775.13 117.691 776.119 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 117.691C771.138 117.691 771.94 116.888 771.94 115.897C771.94 114.906 771.138 114.102 770.149 114.102C769.16 114.102 768.358 114.906 768.358 115.897C768.358 116.888 769.16 117.691 770.149 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 117.691C765.168 117.691 765.97 116.888 765.97 115.897C765.97 114.906 765.168 114.102 764.179 114.102C763.19 114.102 762.388 114.906 762.388 115.897C762.388 116.888 763.19 117.691 764.179 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 117.691C759.198 117.691 760 116.888 760 115.897C760 114.906 759.198 114.102 758.209 114.102C757.22 114.102 756.418 114.906 756.418 115.897C756.418 116.888 757.22 117.691 758.209 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 117.691C753.228 117.691 754.03 116.888 754.03 115.897C754.03 114.906 753.228 114.102 752.239 114.102C751.25 114.102 750.448 114.906 750.448 115.897C750.448 116.888 751.25 117.691 752.239 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 117.691C747.258 117.691 748.06 116.888 748.06 115.897C748.06 114.906 747.258 114.102 746.269 114.102C745.279 114.102 744.478 114.906 744.478 115.897C744.478 116.888 745.279 117.691 746.269 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 117.691C741.288 117.691 742.09 116.888 742.09 115.897C742.09 114.906 741.288 114.102 740.299 114.102C739.309 114.102 738.507 114.906 738.507 115.897C738.507 116.888 739.309 117.691 740.299 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 117.691C735.318 117.691 736.119 116.888 736.119 115.897C736.119 114.906 735.318 114.102 734.328 114.102C733.339 114.102 732.537 114.906 732.537 115.897C732.537 116.888 733.339 117.691 734.328 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 117.691C729.347 117.691 730.149 116.888 730.149 115.897C730.149 114.906 729.347 114.102 728.358 114.102C727.369 114.102 726.567 114.906 726.567 115.897C726.567 116.888 727.369 117.691 728.358 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 117.691C723.377 117.691 724.179 116.888 724.179 115.897C724.179 114.906 723.377 114.102 722.388 114.102C721.399 114.102 720.597 114.906 720.597 115.897C720.597 116.888 721.399 117.691 722.388 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 117.691C717.407 117.691 718.209 116.888 718.209 115.897C718.209 114.906 717.407 114.102 716.418 114.102C715.429 114.102 714.627 114.906 714.627 115.897C714.627 116.888 715.429 117.691 716.418 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 117.691C711.437 117.691 712.239 116.888 712.239 115.897C712.239 114.906 711.437 114.102 710.448 114.102C709.459 114.102 708.657 114.906 708.657 115.897C708.657 116.888 709.459 117.691 710.448 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 117.691C705.467 117.691 706.269 116.888 706.269 115.897C706.269 114.906 705.467 114.102 704.478 114.102C703.488 114.102 702.687 114.906 702.687 115.897C702.687 116.888 703.488 117.691 704.478 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 117.691C699.497 117.691 700.299 116.888 700.299 115.897C700.299 114.906 699.497 114.102 698.507 114.102C697.518 114.102 696.716 114.906 696.716 115.897C696.716 116.888 697.518 117.691 698.507 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 117.691C693.526 117.691 694.328 116.888 694.328 115.897C694.328 114.906 693.526 114.102 692.537 114.102C691.548 114.102 690.746 114.906 690.746 115.897C690.746 116.888 691.548 117.691 692.537 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 117.691C687.556 117.691 688.358 116.888 688.358 115.897C688.358 114.906 687.556 114.102 686.567 114.102C685.578 114.102 684.776 114.906 684.776 115.897C684.776 116.888 685.578 117.691 686.567 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 117.691C681.586 117.691 682.388 116.888 682.388 115.897C682.388 114.906 681.586 114.102 680.597 114.102C679.608 114.102 678.806 114.906 678.806 115.897C678.806 116.888 679.608 117.691 680.597 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 117.691C675.616 117.691 676.418 116.888 676.418 115.897C676.418 114.906 675.616 114.102 674.627 114.102C673.638 114.102 672.836 114.906 672.836 115.897C672.836 116.888 673.638 117.691 674.627 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 117.691C669.646 117.691 670.448 116.888 670.448 115.897C670.448 114.906 669.646 114.102 668.657 114.102C667.668 114.102 666.866 114.906 666.866 115.897C666.866 116.888 667.668 117.691 668.657 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 117.691C663.676 117.691 664.478 116.888 664.478 115.897C664.478 114.906 663.676 114.102 662.687 114.102C661.697 114.102 660.896 114.906 660.896 115.897C660.896 116.888 661.697 117.691 662.687 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 117.691C657.706 117.691 658.507 116.888 658.507 115.897C658.507 114.906 657.706 114.102 656.716 114.102C655.727 114.102 654.925 114.906 654.925 115.897C654.925 116.888 655.727 117.691 656.716 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 117.691C651.735 117.691 652.537 116.888 652.537 115.897C652.537 114.906 651.735 114.102 650.746 114.102C649.757 114.102 648.955 114.906 648.955 115.897C648.955 116.888 649.757 117.691 650.746 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 117.691C645.765 117.691 646.567 116.888 646.567 115.897C646.567 114.906 645.765 114.102 644.776 114.102C643.787 114.102 642.985 114.906 642.985 115.897C642.985 116.888 643.787 117.691 644.776 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 117.691C639.795 117.691 640.597 116.888 640.597 115.897C640.597 114.906 639.795 114.102 638.806 114.102C637.817 114.102 637.015 114.906 637.015 115.897C637.015 116.888 637.817 117.691 638.806 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 117.691C633.825 117.691 634.627 116.888 634.627 115.897C634.627 114.906 633.825 114.102 632.836 114.102C631.847 114.102 631.045 114.906 631.045 115.897C631.045 116.888 631.847 117.691 632.836 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 117.691C627.855 117.691 628.657 116.888 628.657 115.897C628.657 114.906 627.855 114.102 626.866 114.102C625.876 114.102 625.075 114.906 625.075 115.897C625.075 116.888 625.876 117.691 626.866 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 117.691C621.885 117.691 622.687 116.888 622.687 115.897C622.687 114.906 621.885 114.102 620.895 114.102C619.906 114.102 619.104 114.906 619.104 115.897C619.104 116.888 619.906 117.691 620.895 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 117.691C615.915 117.691 616.716 116.888 616.716 115.897C616.716 114.906 615.915 114.102 614.925 114.102C613.936 114.102 613.134 114.906 613.134 115.897C613.134 116.888 613.936 117.691 614.925 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 117.691C609.944 117.691 610.746 116.888 610.746 115.897C610.746 114.906 609.944 114.102 608.955 114.102C607.966 114.102 607.164 114.906 607.164 115.897C607.164 116.888 607.966 117.691 608.955 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 117.691C603.974 117.691 604.776 116.888 604.776 115.897C604.776 114.906 603.974 114.102 602.985 114.102C601.996 114.102 601.194 114.906 601.194 115.897C601.194 116.888 601.996 117.691 602.985 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 117.691C598.004 117.691 598.806 116.888 598.806 115.897C598.806 114.906 598.004 114.102 597.015 114.102C596.026 114.102 595.224 114.906 595.224 115.897C595.224 116.888 596.026 117.691 597.015 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 117.691C592.034 117.691 592.836 116.888 592.836 115.897C592.836 114.906 592.034 114.102 591.045 114.102C590.056 114.102 589.254 114.906 589.254 115.897C589.254 116.888 590.056 117.691 591.045 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 117.691C586.064 117.691 586.866 116.888 586.866 115.897C586.866 114.906 586.064 114.102 585.075 114.102C584.085 114.102 583.284 114.906 583.284 115.897C583.284 116.888 584.085 117.691 585.075 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 117.691C580.094 117.691 580.896 116.888 580.896 115.897C580.896 114.906 580.094 114.102 579.104 114.102C578.115 114.102 577.313 114.906 577.313 115.897C577.313 116.888 578.115 117.691 579.104 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 117.691C574.123 117.691 574.925 116.888 574.925 115.897C574.925 114.906 574.123 114.102 573.134 114.102C572.145 114.102 571.343 114.906 571.343 115.897C571.343 116.888 572.145 117.691 573.134 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 117.691C562.183 117.691 562.985 116.888 562.985 115.897C562.985 114.906 562.183 114.102 561.194 114.102C560.205 114.102 559.403 114.906 559.403 115.897C559.403 116.888 560.205 117.691 561.194 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 117.691C538.303 117.691 539.104 116.888 539.104 115.897C539.104 114.906 538.303 114.102 537.313 114.102C536.324 114.102 535.522 114.906 535.522 115.897C535.522 116.888 536.324 117.691 537.313 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 117.691C526.362 117.691 527.164 116.888 527.164 115.897C527.164 114.906 526.362 114.102 525.373 114.102C524.384 114.102 523.582 114.906 523.582 115.897C523.582 116.888 524.384 117.691 525.373 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 117.691C508.452 117.691 509.254 116.888 509.254 115.897C509.254 114.906 508.452 114.102 507.463 114.102C506.474 114.102 505.672 114.906 505.672 115.897C505.672 116.888 506.474 117.691 507.463 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 111.709C1069.65 111.709 1070.45 110.906 1070.45 109.915C1070.45 108.923 1069.65 108.12 1068.66 108.12C1067.67 108.12 1066.87 108.923 1066.87 109.915C1066.87 110.906 1067.67 111.709 1068.66 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 111.709C1063.68 111.709 1064.48 110.906 1064.48 109.915C1064.48 108.923 1063.68 108.12 1062.69 108.12C1061.7 108.12 1060.9 108.923 1060.9 109.915C1060.9 110.906 1061.7 111.709 1062.69 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 111.709C1057.71 111.709 1058.51 110.906 1058.51 109.915C1058.51 108.923 1057.71 108.12 1056.72 108.12C1055.73 108.12 1054.93 108.923 1054.93 109.915C1054.93 110.906 1055.73 111.709 1056.72 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 111.709C986.064 111.709 986.866 110.906 986.866 109.915C986.866 108.923 986.064 108.12 985.075 108.12C984.085 108.12 983.284 108.923 983.284 109.915C983.284 110.906 984.085 111.709 985.075 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 111.709C980.094 111.709 980.896 110.906 980.896 109.915C980.896 108.923 980.094 108.12 979.104 108.12C978.115 108.12 977.313 108.923 977.313 109.915C977.313 110.906 978.115 111.709 979.104 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 111.709C974.123 111.709 974.925 110.906 974.925 109.915C974.925 108.923 974.123 108.12 973.134 108.12C972.145 108.12 971.343 108.923 971.343 109.915C971.343 110.906 972.145 111.709 973.134 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 111.709C968.153 111.709 968.955 110.906 968.955 109.915C968.955 108.923 968.153 108.12 967.164 108.12C966.175 108.12 965.373 108.923 965.373 109.915C965.373 110.906 966.175 111.709 967.164 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 111.709C962.183 111.709 962.985 110.906 962.985 109.915C962.985 108.923 962.183 108.12 961.194 108.12C960.205 108.12 959.403 108.923 959.403 109.915C959.403 110.906 960.205 111.709 961.194 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 111.709C956.213 111.709 957.015 110.906 957.015 109.915C957.015 108.923 956.213 108.12 955.224 108.12C954.235 108.12 953.433 108.923 953.433 109.915C953.433 110.906 954.235 111.709 955.224 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 111.709C950.243 111.709 951.045 110.906 951.045 109.915C951.045 108.923 950.243 108.12 949.254 108.12C948.265 108.12 947.463 108.923 947.463 109.915C947.463 110.906 948.265 111.709 949.254 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 111.709C944.273 111.709 945.075 110.906 945.075 109.915C945.075 108.923 944.273 108.12 943.284 108.12C942.294 108.12 941.492 108.923 941.492 109.915C941.492 110.906 942.294 111.709 943.284 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 111.709C938.303 111.709 939.105 110.906 939.105 109.915C939.105 108.923 938.303 108.12 937.313 108.12C936.324 108.12 935.522 108.923 935.522 109.915C935.522 110.906 936.324 111.709 937.313 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 111.709C932.332 111.709 933.134 110.906 933.134 109.915C933.134 108.923 932.332 108.12 931.343 108.12C930.354 108.12 929.552 108.923 929.552 109.915C929.552 110.906 930.354 111.709 931.343 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 111.709C926.362 111.709 927.164 110.906 927.164 109.915C927.164 108.923 926.362 108.12 925.373 108.12C924.384 108.12 923.582 108.923 923.582 109.915C923.582 110.906 924.384 111.709 925.373 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 111.709C920.392 111.709 921.194 110.906 921.194 109.915C921.194 108.923 920.392 108.12 919.403 108.12C918.414 108.12 917.612 108.923 917.612 109.915C917.612 110.906 918.414 111.709 919.403 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 111.709C914.422 111.709 915.224 110.906 915.224 109.915C915.224 108.923 914.422 108.12 913.433 108.12C912.444 108.12 911.642 108.923 911.642 109.915C911.642 110.906 912.444 111.709 913.433 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 111.709C908.452 111.709 909.254 110.906 909.254 109.915C909.254 108.923 908.452 108.12 907.463 108.12C906.474 108.12 905.672 108.923 905.672 109.915C905.672 110.906 906.474 111.709 907.463 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 111.709C902.482 111.709 903.284 110.906 903.284 109.915C903.284 108.923 902.482 108.12 901.492 108.12C900.503 108.12 899.701 108.923 899.701 109.915C899.701 110.906 900.503 111.709 901.492 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 111.709C896.512 111.709 897.313 110.906 897.313 109.915C897.313 108.923 896.512 108.12 895.522 108.12C894.533 108.12 893.731 108.923 893.731 109.915C893.731 110.906 894.533 111.709 895.522 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 111.709C890.541 111.709 891.343 110.906 891.343 109.915C891.343 108.923 890.541 108.12 889.552 108.12C888.563 108.12 887.761 108.923 887.761 109.915C887.761 110.906 888.563 111.709 889.552 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 111.709C884.571 111.709 885.373 110.906 885.373 109.915C885.373 108.923 884.571 108.12 883.582 108.12C882.593 108.12 881.791 108.923 881.791 109.915C881.791 110.906 882.593 111.709 883.582 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 111.709C878.601 111.709 879.403 110.906 879.403 109.915C879.403 108.923 878.601 108.12 877.612 108.12C876.623 108.12 875.821 108.923 875.821 109.915C875.821 110.906 876.623 111.709 877.612 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 111.709C872.631 111.709 873.433 110.906 873.433 109.915C873.433 108.923 872.631 108.12 871.642 108.12C870.653 108.12 869.851 108.923 869.851 109.915C869.851 110.906 870.653 111.709 871.642 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 111.709C866.661 111.709 867.463 110.906 867.463 109.915C867.463 108.923 866.661 108.12 865.672 108.12C864.683 108.12 863.881 108.923 863.881 109.915C863.881 110.906 864.683 111.709 865.672 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 111.709C860.691 111.709 861.493 110.906 861.493 109.915C861.493 108.923 860.691 108.12 859.701 108.12C858.712 108.12 857.91 108.923 857.91 109.915C857.91 110.906 858.712 111.709 859.701 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 111.709C854.721 111.709 855.522 110.906 855.522 109.915C855.522 108.923 854.721 108.12 853.731 108.12C852.742 108.12 851.94 108.923 851.94 109.915C851.94 110.906 852.742 111.709 853.731 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 111.709C848.75 111.709 849.552 110.906 849.552 109.915C849.552 108.923 848.75 108.12 847.761 108.12C846.772 108.12 845.97 108.923 845.97 109.915C845.97 110.906 846.772 111.709 847.761 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 111.709C842.78 111.709 843.582 110.906 843.582 109.915C843.582 108.923 842.78 108.12 841.791 108.12C840.802 108.12 840 108.923 840 109.915C840 110.906 840.802 111.709 841.791 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 111.709C836.81 111.709 837.612 110.906 837.612 109.915C837.612 108.923 836.81 108.12 835.821 108.12C834.832 108.12 834.03 108.923 834.03 109.915C834.03 110.906 834.832 111.709 835.821 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 111.709C830.84 111.709 831.642 110.906 831.642 109.915C831.642 108.923 830.84 108.12 829.851 108.12C828.862 108.12 828.06 108.923 828.06 109.915C828.06 110.906 828.862 111.709 829.851 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 111.709C824.87 111.709 825.672 110.906 825.672 109.915C825.672 108.923 824.87 108.12 823.881 108.12C822.891 108.12 822.09 108.923 822.09 109.915C822.09 110.906 822.891 111.709 823.881 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 111.709C818.9 111.709 819.701 110.906 819.701 109.915C819.701 108.923 818.9 108.12 817.91 108.12C816.921 108.12 816.119 108.923 816.119 109.915C816.119 110.906 816.921 111.709 817.91 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 111.709C812.929 111.709 813.731 110.906 813.731 109.915C813.731 108.923 812.929 108.12 811.94 108.12C810.951 108.12 810.149 108.923 810.149 109.915C810.149 110.906 810.951 111.709 811.94 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 111.709C806.959 111.709 807.761 110.906 807.761 109.915C807.761 108.923 806.959 108.12 805.97 108.12C804.981 108.12 804.179 108.923 804.179 109.915C804.179 110.906 804.981 111.709 805.97 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M800 111.709C800.989 111.709 801.791 110.906 801.791 109.915C801.791 108.923 800.989 108.12 800 108.12C799.011 108.12 798.209 108.923 798.209 109.915C798.209 110.906 799.011 111.709 800 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 111.709C795.019 111.709 795.821 110.906 795.821 109.915C795.821 108.923 795.019 108.12 794.03 108.12C793.041 108.12 792.239 108.923 792.239 109.915C792.239 110.906 793.041 111.709 794.03 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 111.709C789.049 111.709 789.851 110.906 789.851 109.915C789.851 108.923 789.049 108.12 788.06 108.12C787.071 108.12 786.269 108.923 786.269 109.915C786.269 110.906 787.071 111.709 788.06 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 111.709C783.079 111.709 783.881 110.906 783.881 109.915C783.881 108.923 783.079 108.12 782.09 108.12C781.1 108.12 780.299 108.923 780.299 109.915C780.299 110.906 781.1 111.709 782.09 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 111.709C777.109 111.709 777.91 110.906 777.91 109.915C777.91 108.923 777.109 108.12 776.119 108.12C775.13 108.12 774.328 108.923 774.328 109.915C774.328 110.906 775.13 111.709 776.119 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 111.709C771.138 111.709 771.94 110.906 771.94 109.915C771.94 108.923 771.138 108.12 770.149 108.12C769.16 108.12 768.358 108.923 768.358 109.915C768.358 110.906 769.16 111.709 770.149 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 111.709C765.168 111.709 765.97 110.906 765.97 109.915C765.97 108.923 765.168 108.12 764.179 108.12C763.19 108.12 762.388 108.923 762.388 109.915C762.388 110.906 763.19 111.709 764.179 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 111.709C759.198 111.709 760 110.906 760 109.915C760 108.923 759.198 108.12 758.209 108.12C757.22 108.12 756.418 108.923 756.418 109.915C756.418 110.906 757.22 111.709 758.209 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 111.709C753.228 111.709 754.03 110.906 754.03 109.915C754.03 108.923 753.228 108.12 752.239 108.12C751.25 108.12 750.448 108.923 750.448 109.915C750.448 110.906 751.25 111.709 752.239 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 111.709C747.258 111.709 748.06 110.906 748.06 109.915C748.06 108.923 747.258 108.12 746.269 108.12C745.279 108.12 744.478 108.923 744.478 109.915C744.478 110.906 745.279 111.709 746.269 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 111.709C741.288 111.709 742.09 110.906 742.09 109.915C742.09 108.923 741.288 108.12 740.299 108.12C739.309 108.12 738.507 108.923 738.507 109.915C738.507 110.906 739.309 111.709 740.299 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 111.709C735.318 111.709 736.119 110.906 736.119 109.915C736.119 108.923 735.318 108.12 734.328 108.12C733.339 108.12 732.537 108.923 732.537 109.915C732.537 110.906 733.339 111.709 734.328 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 111.709C729.347 111.709 730.149 110.906 730.149 109.915C730.149 108.923 729.347 108.12 728.358 108.12C727.369 108.12 726.567 108.923 726.567 109.915C726.567 110.906 727.369 111.709 728.358 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 111.709C723.377 111.709 724.179 110.906 724.179 109.915C724.179 108.923 723.377 108.12 722.388 108.12C721.399 108.12 720.597 108.923 720.597 109.915C720.597 110.906 721.399 111.709 722.388 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 111.709C717.407 111.709 718.209 110.906 718.209 109.915C718.209 108.923 717.407 108.12 716.418 108.12C715.429 108.12 714.627 108.923 714.627 109.915C714.627 110.906 715.429 111.709 716.418 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 111.709C711.437 111.709 712.239 110.906 712.239 109.915C712.239 108.923 711.437 108.12 710.448 108.12C709.459 108.12 708.657 108.923 708.657 109.915C708.657 110.906 709.459 111.709 710.448 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 111.709C705.467 111.709 706.269 110.906 706.269 109.915C706.269 108.923 705.467 108.12 704.478 108.12C703.488 108.12 702.687 108.923 702.687 109.915C702.687 110.906 703.488 111.709 704.478 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 111.709C699.497 111.709 700.299 110.906 700.299 109.915C700.299 108.923 699.497 108.12 698.507 108.12C697.518 108.12 696.716 108.923 696.716 109.915C696.716 110.906 697.518 111.709 698.507 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 111.709C693.526 111.709 694.328 110.906 694.328 109.915C694.328 108.923 693.526 108.12 692.537 108.12C691.548 108.12 690.746 108.923 690.746 109.915C690.746 110.906 691.548 111.709 692.537 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 111.709C687.556 111.709 688.358 110.906 688.358 109.915C688.358 108.923 687.556 108.12 686.567 108.12C685.578 108.12 684.776 108.923 684.776 109.915C684.776 110.906 685.578 111.709 686.567 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 111.709C681.586 111.709 682.388 110.906 682.388 109.915C682.388 108.923 681.586 108.12 680.597 108.12C679.608 108.12 678.806 108.923 678.806 109.915C678.806 110.906 679.608 111.709 680.597 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 111.709C675.616 111.709 676.418 110.906 676.418 109.915C676.418 108.923 675.616 108.12 674.627 108.12C673.638 108.12 672.836 108.923 672.836 109.915C672.836 110.906 673.638 111.709 674.627 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 111.709C669.646 111.709 670.448 110.906 670.448 109.915C670.448 108.923 669.646 108.12 668.657 108.12C667.668 108.12 666.866 108.923 666.866 109.915C666.866 110.906 667.668 111.709 668.657 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 111.709C663.676 111.709 664.478 110.906 664.478 109.915C664.478 108.923 663.676 108.12 662.687 108.12C661.697 108.12 660.896 108.923 660.896 109.915C660.896 110.906 661.697 111.709 662.687 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 111.709C657.706 111.709 658.507 110.906 658.507 109.915C658.507 108.923 657.706 108.12 656.716 108.12C655.727 108.12 654.925 108.923 654.925 109.915C654.925 110.906 655.727 111.709 656.716 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 111.709C651.735 111.709 652.537 110.906 652.537 109.915C652.537 108.923 651.735 108.12 650.746 108.12C649.757 108.12 648.955 108.923 648.955 109.915C648.955 110.906 649.757 111.709 650.746 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 111.709C645.765 111.709 646.567 110.906 646.567 109.915C646.567 108.923 645.765 108.12 644.776 108.12C643.787 108.12 642.985 108.923 642.985 109.915C642.985 110.906 643.787 111.709 644.776 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 111.709C639.795 111.709 640.597 110.906 640.597 109.915C640.597 108.923 639.795 108.12 638.806 108.12C637.817 108.12 637.015 108.923 637.015 109.915C637.015 110.906 637.817 111.709 638.806 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 111.709C633.825 111.709 634.627 110.906 634.627 109.915C634.627 108.923 633.825 108.12 632.836 108.12C631.847 108.12 631.045 108.923 631.045 109.915C631.045 110.906 631.847 111.709 632.836 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 111.709C627.855 111.709 628.657 110.906 628.657 109.915C628.657 108.923 627.855 108.12 626.866 108.12C625.876 108.12 625.075 108.923 625.075 109.915C625.075 110.906 625.876 111.709 626.866 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 111.709C621.885 111.709 622.687 110.906 622.687 109.915C622.687 108.923 621.885 108.12 620.895 108.12C619.906 108.12 619.104 108.923 619.104 109.915C619.104 110.906 619.906 111.709 620.895 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 111.709C615.915 111.709 616.716 110.906 616.716 109.915C616.716 108.923 615.915 108.12 614.925 108.12C613.936 108.12 613.134 108.923 613.134 109.915C613.134 110.906 613.936 111.709 614.925 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 111.709C609.944 111.709 610.746 110.906 610.746 109.915C610.746 108.923 609.944 108.12 608.955 108.12C607.966 108.12 607.164 108.923 607.164 109.915C607.164 110.906 607.966 111.709 608.955 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 111.709C603.974 111.709 604.776 110.906 604.776 109.915C604.776 108.923 603.974 108.12 602.985 108.12C601.996 108.12 601.194 108.923 601.194 109.915C601.194 110.906 601.996 111.709 602.985 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 111.709C598.004 111.709 598.806 110.906 598.806 109.915C598.806 108.923 598.004 108.12 597.015 108.12C596.026 108.12 595.224 108.923 595.224 109.915C595.224 110.906 596.026 111.709 597.015 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 111.709C592.034 111.709 592.836 110.906 592.836 109.915C592.836 108.923 592.034 108.12 591.045 108.12C590.056 108.12 589.254 108.923 589.254 109.915C589.254 110.906 590.056 111.709 591.045 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 111.709C568.153 111.709 568.955 110.906 568.955 109.915C568.955 108.923 568.153 108.12 567.164 108.12C566.175 108.12 565.373 108.923 565.373 109.915C565.373 110.906 566.175 111.709 567.164 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 111.709C532.332 111.709 533.134 110.906 533.134 109.915C533.134 108.923 532.332 108.12 531.343 108.12C530.354 108.12 529.552 108.923 529.552 109.915C529.552 110.906 530.354 111.709 531.343 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 111.709C526.362 111.709 527.164 110.906 527.164 109.915C527.164 108.923 526.362 108.12 525.373 108.12C524.384 108.12 523.582 108.923 523.582 109.915C523.582 110.906 524.384 111.709 525.373 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 111.709C520.392 111.709 521.194 110.906 521.194 109.915C521.194 108.923 520.392 108.12 519.403 108.12C518.414 108.12 517.612 108.923 517.612 109.915C517.612 110.906 518.414 111.709 519.403 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 111.709C514.422 111.709 515.224 110.906 515.224 109.915C515.224 108.923 514.422 108.12 513.433 108.12C512.444 108.12 511.642 108.923 511.642 109.915C511.642 110.906 512.444 111.709 513.433 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 111.709C508.452 111.709 509.254 110.906 509.254 109.915C509.254 108.923 508.452 108.12 507.463 108.12C506.474 108.12 505.672 108.923 505.672 109.915C505.672 110.906 506.474 111.709 507.463 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 105.727C1069.65 105.727 1070.45 104.923 1070.45 103.932C1070.45 102.941 1069.65 102.137 1068.66 102.137C1067.67 102.137 1066.87 102.941 1066.87 103.932C1066.87 104.923 1067.67 105.727 1068.66 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 105.727C1063.68 105.727 1064.48 104.923 1064.48 103.932C1064.48 102.941 1063.68 102.137 1062.69 102.137C1061.7 102.137 1060.9 102.941 1060.9 103.932C1060.9 104.923 1061.7 105.727 1062.69 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 105.727C1057.71 105.727 1058.51 104.923 1058.51 103.932C1058.51 102.941 1057.71 102.137 1056.72 102.137C1055.73 102.137 1054.93 102.941 1054.93 103.932C1054.93 104.923 1055.73 105.727 1056.72 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 105.727C1051.74 105.727 1052.54 104.923 1052.54 103.932C1052.54 102.941 1051.74 102.137 1050.75 102.137C1049.76 102.137 1048.96 102.941 1048.96 103.932C1048.96 104.923 1049.76 105.727 1050.75 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 105.727C986.064 105.727 986.866 104.923 986.866 103.932C986.866 102.941 986.064 102.137 985.075 102.137C984.085 102.137 983.284 102.941 983.284 103.932C983.284 104.923 984.085 105.727 985.075 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 105.727C980.094 105.727 980.896 104.923 980.896 103.932C980.896 102.941 980.094 102.137 979.104 102.137C978.115 102.137 977.313 102.941 977.313 103.932C977.313 104.923 978.115 105.727 979.104 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 105.727C974.123 105.727 974.925 104.923 974.925 103.932C974.925 102.941 974.123 102.137 973.134 102.137C972.145 102.137 971.343 102.941 971.343 103.932C971.343 104.923 972.145 105.727 973.134 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 105.727C968.153 105.727 968.955 104.923 968.955 103.932C968.955 102.941 968.153 102.137 967.164 102.137C966.175 102.137 965.373 102.941 965.373 103.932C965.373 104.923 966.175 105.727 967.164 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 105.727C962.183 105.727 962.985 104.923 962.985 103.932C962.985 102.941 962.183 102.137 961.194 102.137C960.205 102.137 959.403 102.941 959.403 103.932C959.403 104.923 960.205 105.727 961.194 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 105.727C956.213 105.727 957.015 104.923 957.015 103.932C957.015 102.941 956.213 102.137 955.224 102.137C954.235 102.137 953.433 102.941 953.433 103.932C953.433 104.923 954.235 105.727 955.224 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 105.727C950.243 105.727 951.045 104.923 951.045 103.932C951.045 102.941 950.243 102.137 949.254 102.137C948.265 102.137 947.463 102.941 947.463 103.932C947.463 104.923 948.265 105.727 949.254 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 105.727C944.273 105.727 945.075 104.923 945.075 103.932C945.075 102.941 944.273 102.137 943.284 102.137C942.294 102.137 941.492 102.941 941.492 103.932C941.492 104.923 942.294 105.727 943.284 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 105.727C938.303 105.727 939.105 104.923 939.105 103.932C939.105 102.941 938.303 102.137 937.313 102.137C936.324 102.137 935.522 102.941 935.522 103.932C935.522 104.923 936.324 105.727 937.313 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 105.727C932.332 105.727 933.134 104.923 933.134 103.932C933.134 102.941 932.332 102.137 931.343 102.137C930.354 102.137 929.552 102.941 929.552 103.932C929.552 104.923 930.354 105.727 931.343 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 105.727C926.362 105.727 927.164 104.923 927.164 103.932C927.164 102.941 926.362 102.137 925.373 102.137C924.384 102.137 923.582 102.941 923.582 103.932C923.582 104.923 924.384 105.727 925.373 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 105.727C920.392 105.727 921.194 104.923 921.194 103.932C921.194 102.941 920.392 102.137 919.403 102.137C918.414 102.137 917.612 102.941 917.612 103.932C917.612 104.923 918.414 105.727 919.403 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 105.727C914.422 105.727 915.224 104.923 915.224 103.932C915.224 102.941 914.422 102.137 913.433 102.137C912.444 102.137 911.642 102.941 911.642 103.932C911.642 104.923 912.444 105.727 913.433 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 105.727C908.452 105.727 909.254 104.923 909.254 103.932C909.254 102.941 908.452 102.137 907.463 102.137C906.474 102.137 905.672 102.941 905.672 103.932C905.672 104.923 906.474 105.727 907.463 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 105.727C902.482 105.727 903.284 104.923 903.284 103.932C903.284 102.941 902.482 102.137 901.492 102.137C900.503 102.137 899.701 102.941 899.701 103.932C899.701 104.923 900.503 105.727 901.492 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 105.727C896.512 105.727 897.313 104.923 897.313 103.932C897.313 102.941 896.512 102.137 895.522 102.137C894.533 102.137 893.731 102.941 893.731 103.932C893.731 104.923 894.533 105.727 895.522 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 105.727C890.541 105.727 891.343 104.923 891.343 103.932C891.343 102.941 890.541 102.137 889.552 102.137C888.563 102.137 887.761 102.941 887.761 103.932C887.761 104.923 888.563 105.727 889.552 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 105.727C884.571 105.727 885.373 104.923 885.373 103.932C885.373 102.941 884.571 102.137 883.582 102.137C882.593 102.137 881.791 102.941 881.791 103.932C881.791 104.923 882.593 105.727 883.582 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 105.727C878.601 105.727 879.403 104.923 879.403 103.932C879.403 102.941 878.601 102.137 877.612 102.137C876.623 102.137 875.821 102.941 875.821 103.932C875.821 104.923 876.623 105.727 877.612 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 105.727C872.631 105.727 873.433 104.923 873.433 103.932C873.433 102.941 872.631 102.137 871.642 102.137C870.653 102.137 869.851 102.941 869.851 103.932C869.851 104.923 870.653 105.727 871.642 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 105.727C866.661 105.727 867.463 104.923 867.463 103.932C867.463 102.941 866.661 102.137 865.672 102.137C864.683 102.137 863.881 102.941 863.881 103.932C863.881 104.923 864.683 105.727 865.672 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 105.727C860.691 105.727 861.493 104.923 861.493 103.932C861.493 102.941 860.691 102.137 859.701 102.137C858.712 102.137 857.91 102.941 857.91 103.932C857.91 104.923 858.712 105.727 859.701 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 105.727C854.721 105.727 855.522 104.923 855.522 103.932C855.522 102.941 854.721 102.137 853.731 102.137C852.742 102.137 851.94 102.941 851.94 103.932C851.94 104.923 852.742 105.727 853.731 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 105.727C848.75 105.727 849.552 104.923 849.552 103.932C849.552 102.941 848.75 102.137 847.761 102.137C846.772 102.137 845.97 102.941 845.97 103.932C845.97 104.923 846.772 105.727 847.761 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 105.727C842.78 105.727 843.582 104.923 843.582 103.932C843.582 102.941 842.78 102.137 841.791 102.137C840.802 102.137 840 102.941 840 103.932C840 104.923 840.802 105.727 841.791 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 105.727C836.81 105.727 837.612 104.923 837.612 103.932C837.612 102.941 836.81 102.137 835.821 102.137C834.832 102.137 834.03 102.941 834.03 103.932C834.03 104.923 834.832 105.727 835.821 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 105.727C830.84 105.727 831.642 104.923 831.642 103.932C831.642 102.941 830.84 102.137 829.851 102.137C828.862 102.137 828.06 102.941 828.06 103.932C828.06 104.923 828.862 105.727 829.851 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 105.727C824.87 105.727 825.672 104.923 825.672 103.932C825.672 102.941 824.87 102.137 823.881 102.137C822.891 102.137 822.09 102.941 822.09 103.932C822.09 104.923 822.891 105.727 823.881 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 105.727C818.9 105.727 819.701 104.923 819.701 103.932C819.701 102.941 818.9 102.137 817.91 102.137C816.921 102.137 816.119 102.941 816.119 103.932C816.119 104.923 816.921 105.727 817.91 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 105.727C812.929 105.727 813.731 104.923 813.731 103.932C813.731 102.941 812.929 102.137 811.94 102.137C810.951 102.137 810.149 102.941 810.149 103.932C810.149 104.923 810.951 105.727 811.94 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 105.727C806.959 105.727 807.761 104.923 807.761 103.932C807.761 102.941 806.959 102.137 805.97 102.137C804.981 102.137 804.179 102.941 804.179 103.932C804.179 104.923 804.981 105.727 805.97 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M800 105.727C800.989 105.727 801.791 104.923 801.791 103.932C801.791 102.941 800.989 102.137 800 102.137C799.011 102.137 798.209 102.941 798.209 103.932C798.209 104.923 799.011 105.727 800 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 105.727C795.019 105.727 795.821 104.923 795.821 103.932C795.821 102.941 795.019 102.137 794.03 102.137C793.041 102.137 792.239 102.941 792.239 103.932C792.239 104.923 793.041 105.727 794.03 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 105.727C789.049 105.727 789.851 104.923 789.851 103.932C789.851 102.941 789.049 102.137 788.06 102.137C787.071 102.137 786.269 102.941 786.269 103.932C786.269 104.923 787.071 105.727 788.06 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 105.727C783.079 105.727 783.881 104.923 783.881 103.932C783.881 102.941 783.079 102.137 782.09 102.137C781.1 102.137 780.299 102.941 780.299 103.932C780.299 104.923 781.1 105.727 782.09 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 105.727C777.109 105.727 777.91 104.923 777.91 103.932C777.91 102.941 777.109 102.137 776.119 102.137C775.13 102.137 774.328 102.941 774.328 103.932C774.328 104.923 775.13 105.727 776.119 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 105.727C771.138 105.727 771.94 104.923 771.94 103.932C771.94 102.941 771.138 102.137 770.149 102.137C769.16 102.137 768.358 102.941 768.358 103.932C768.358 104.923 769.16 105.727 770.149 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 105.727C765.168 105.727 765.97 104.923 765.97 103.932C765.97 102.941 765.168 102.137 764.179 102.137C763.19 102.137 762.388 102.941 762.388 103.932C762.388 104.923 763.19 105.727 764.179 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 105.727C759.198 105.727 760 104.923 760 103.932C760 102.941 759.198 102.137 758.209 102.137C757.22 102.137 756.418 102.941 756.418 103.932C756.418 104.923 757.22 105.727 758.209 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 105.727C753.228 105.727 754.03 104.923 754.03 103.932C754.03 102.941 753.228 102.137 752.239 102.137C751.25 102.137 750.448 102.941 750.448 103.932C750.448 104.923 751.25 105.727 752.239 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 105.727C747.258 105.727 748.06 104.923 748.06 103.932C748.06 102.941 747.258 102.137 746.269 102.137C745.279 102.137 744.478 102.941 744.478 103.932C744.478 104.923 745.279 105.727 746.269 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 105.727C741.288 105.727 742.09 104.923 742.09 103.932C742.09 102.941 741.288 102.137 740.299 102.137C739.309 102.137 738.507 102.941 738.507 103.932C738.507 104.923 739.309 105.727 740.299 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 105.727C735.318 105.727 736.119 104.923 736.119 103.932C736.119 102.941 735.318 102.137 734.328 102.137C733.339 102.137 732.537 102.941 732.537 103.932C732.537 104.923 733.339 105.727 734.328 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 105.727C729.347 105.727 730.149 104.923 730.149 103.932C730.149 102.941 729.347 102.137 728.358 102.137C727.369 102.137 726.567 102.941 726.567 103.932C726.567 104.923 727.369 105.727 728.358 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 105.727C723.377 105.727 724.179 104.923 724.179 103.932C724.179 102.941 723.377 102.137 722.388 102.137C721.399 102.137 720.597 102.941 720.597 103.932C720.597 104.923 721.399 105.727 722.388 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 105.727C717.407 105.727 718.209 104.923 718.209 103.932C718.209 102.941 717.407 102.137 716.418 102.137C715.429 102.137 714.627 102.941 714.627 103.932C714.627 104.923 715.429 105.727 716.418 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 105.727C711.437 105.727 712.239 104.923 712.239 103.932C712.239 102.941 711.437 102.137 710.448 102.137C709.459 102.137 708.657 102.941 708.657 103.932C708.657 104.923 709.459 105.727 710.448 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 105.727C705.467 105.727 706.269 104.923 706.269 103.932C706.269 102.941 705.467 102.137 704.478 102.137C703.488 102.137 702.687 102.941 702.687 103.932C702.687 104.923 703.488 105.727 704.478 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 105.727C699.497 105.727 700.299 104.923 700.299 103.932C700.299 102.941 699.497 102.137 698.507 102.137C697.518 102.137 696.716 102.941 696.716 103.932C696.716 104.923 697.518 105.727 698.507 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 105.727C693.526 105.727 694.328 104.923 694.328 103.932C694.328 102.941 693.526 102.137 692.537 102.137C691.548 102.137 690.746 102.941 690.746 103.932C690.746 104.923 691.548 105.727 692.537 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 105.727C687.556 105.727 688.358 104.923 688.358 103.932C688.358 102.941 687.556 102.137 686.567 102.137C685.578 102.137 684.776 102.941 684.776 103.932C684.776 104.923 685.578 105.727 686.567 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 105.727C681.586 105.727 682.388 104.923 682.388 103.932C682.388 102.941 681.586 102.137 680.597 102.137C679.608 102.137 678.806 102.941 678.806 103.932C678.806 104.923 679.608 105.727 680.597 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 105.727C675.616 105.727 676.418 104.923 676.418 103.932C676.418 102.941 675.616 102.137 674.627 102.137C673.638 102.137 672.836 102.941 672.836 103.932C672.836 104.923 673.638 105.727 674.627 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 105.727C669.646 105.727 670.448 104.923 670.448 103.932C670.448 102.941 669.646 102.137 668.657 102.137C667.668 102.137 666.866 102.941 666.866 103.932C666.866 104.923 667.668 105.727 668.657 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 105.727C663.676 105.727 664.478 104.923 664.478 103.932C664.478 102.941 663.676 102.137 662.687 102.137C661.697 102.137 660.896 102.941 660.896 103.932C660.896 104.923 661.697 105.727 662.687 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 105.727C651.735 105.727 652.537 104.923 652.537 103.932C652.537 102.941 651.735 102.137 650.746 102.137C649.757 102.137 648.955 102.941 648.955 103.932C648.955 104.923 649.757 105.727 650.746 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 105.727C645.765 105.727 646.567 104.923 646.567 103.932C646.567 102.941 645.765 102.137 644.776 102.137C643.787 102.137 642.985 102.941 642.985 103.932C642.985 104.923 643.787 105.727 644.776 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 105.727C639.795 105.727 640.597 104.923 640.597 103.932C640.597 102.941 639.795 102.137 638.806 102.137C637.817 102.137 637.015 102.941 637.015 103.932C637.015 104.923 637.817 105.727 638.806 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 105.727C633.825 105.727 634.627 104.923 634.627 103.932C634.627 102.941 633.825 102.137 632.836 102.137C631.847 102.137 631.045 102.941 631.045 103.932C631.045 104.923 631.847 105.727 632.836 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 105.727C627.855 105.727 628.657 104.923 628.657 103.932C628.657 102.941 627.855 102.137 626.866 102.137C625.876 102.137 625.075 102.941 625.075 103.932C625.075 104.923 625.876 105.727 626.866 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 105.727C621.885 105.727 622.687 104.923 622.687 103.932C622.687 102.941 621.885 102.137 620.895 102.137C619.906 102.137 619.104 102.941 619.104 103.932C619.104 104.923 619.906 105.727 620.895 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 105.727C615.915 105.727 616.716 104.923 616.716 103.932C616.716 102.941 615.915 102.137 614.925 102.137C613.936 102.137 613.134 102.941 613.134 103.932C613.134 104.923 613.936 105.727 614.925 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 105.727C609.944 105.727 610.746 104.923 610.746 103.932C610.746 102.941 609.944 102.137 608.955 102.137C607.966 102.137 607.164 102.941 607.164 103.932C607.164 104.923 607.966 105.727 608.955 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 105.727C586.064 105.727 586.866 104.923 586.866 103.932C586.866 102.941 586.064 102.137 585.075 102.137C584.085 102.137 583.284 102.941 583.284 103.932C583.284 104.923 584.085 105.727 585.075 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 105.727C580.094 105.727 580.896 104.923 580.896 103.932C580.896 102.941 580.094 102.137 579.104 102.137C578.115 102.137 577.313 102.941 577.313 103.932C577.313 104.923 578.115 105.727 579.104 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 105.727C568.153 105.727 568.955 104.923 568.955 103.932C568.955 102.941 568.153 102.137 567.164 102.137C566.175 102.137 565.373 102.941 565.373 103.932C565.373 104.923 566.175 105.727 567.164 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 105.727C526.362 105.727 527.164 104.923 527.164 103.932C527.164 102.941 526.362 102.137 525.373 102.137C524.384 102.137 523.582 102.941 523.582 103.932C523.582 104.923 524.384 105.727 525.373 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 99.7446C1063.68 99.7446 1064.48 98.9411 1064.48 97.95C1064.48 96.9588 1063.68 96.1553 1062.69 96.1553C1061.7 96.1553 1060.9 96.9588 1060.9 97.95C1060.9 98.9411 1061.7 99.7446 1062.69 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 99.7446C1057.71 99.7446 1058.51 98.9411 1058.51 97.95C1058.51 96.9588 1057.71 96.1553 1056.72 96.1553C1055.73 96.1553 1054.93 96.9588 1054.93 97.95C1054.93 98.9411 1055.73 99.7446 1056.72 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 99.7446C1051.74 99.7446 1052.54 98.9411 1052.54 97.95C1052.54 96.9588 1051.74 96.1553 1050.75 96.1553C1049.76 96.1553 1048.96 96.9588 1048.96 97.95C1048.96 98.9411 1049.76 99.7446 1050.75 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 99.7446C986.064 99.7446 986.866 98.9411 986.866 97.95C986.866 96.9588 986.064 96.1553 985.075 96.1553C984.085 96.1553 983.284 96.9588 983.284 97.95C983.284 98.9411 984.085 99.7446 985.075 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 99.7446C980.094 99.7446 980.896 98.9411 980.896 97.95C980.896 96.9588 980.094 96.1553 979.104 96.1553C978.115 96.1553 977.313 96.9588 977.313 97.95C977.313 98.9411 978.115 99.7446 979.104 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 99.7446C974.123 99.7446 974.925 98.9411 974.925 97.95C974.925 96.9588 974.123 96.1553 973.134 96.1553C972.145 96.1553 971.343 96.9588 971.343 97.95C971.343 98.9411 972.145 99.7446 973.134 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 99.7446C968.153 99.7446 968.955 98.9411 968.955 97.95C968.955 96.9588 968.153 96.1553 967.164 96.1553C966.175 96.1553 965.373 96.9588 965.373 97.95C965.373 98.9411 966.175 99.7446 967.164 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 99.7446C962.183 99.7446 962.985 98.9411 962.985 97.95C962.985 96.9588 962.183 96.1553 961.194 96.1553C960.205 96.1553 959.403 96.9588 959.403 97.95C959.403 98.9411 960.205 99.7446 961.194 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 99.7446C956.213 99.7446 957.015 98.9411 957.015 97.95C957.015 96.9588 956.213 96.1553 955.224 96.1553C954.235 96.1553 953.433 96.9588 953.433 97.95C953.433 98.9411 954.235 99.7446 955.224 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 99.7446C950.243 99.7446 951.045 98.9411 951.045 97.95C951.045 96.9588 950.243 96.1553 949.254 96.1553C948.265 96.1553 947.463 96.9588 947.463 97.95C947.463 98.9411 948.265 99.7446 949.254 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 99.7446C944.273 99.7446 945.075 98.9411 945.075 97.95C945.075 96.9588 944.273 96.1553 943.284 96.1553C942.294 96.1553 941.492 96.9588 941.492 97.95C941.492 98.9411 942.294 99.7446 943.284 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 99.7446C938.303 99.7446 939.105 98.9411 939.105 97.95C939.105 96.9588 938.303 96.1553 937.313 96.1553C936.324 96.1553 935.522 96.9588 935.522 97.95C935.522 98.9411 936.324 99.7446 937.313 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 99.7446C932.332 99.7446 933.134 98.9411 933.134 97.95C933.134 96.9588 932.332 96.1553 931.343 96.1553C930.354 96.1553 929.552 96.9588 929.552 97.95C929.552 98.9411 930.354 99.7446 931.343 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 99.7446C926.362 99.7446 927.164 98.9411 927.164 97.95C927.164 96.9588 926.362 96.1553 925.373 96.1553C924.384 96.1553 923.582 96.9588 923.582 97.95C923.582 98.9411 924.384 99.7446 925.373 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 99.7446C920.392 99.7446 921.194 98.9411 921.194 97.95C921.194 96.9588 920.392 96.1553 919.403 96.1553C918.414 96.1553 917.612 96.9588 917.612 97.95C917.612 98.9411 918.414 99.7446 919.403 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 99.7446C914.422 99.7446 915.224 98.9411 915.224 97.95C915.224 96.9588 914.422 96.1553 913.433 96.1553C912.444 96.1553 911.642 96.9588 911.642 97.95C911.642 98.9411 912.444 99.7446 913.433 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 99.7446C908.452 99.7446 909.254 98.9411 909.254 97.95C909.254 96.9588 908.452 96.1553 907.463 96.1553C906.474 96.1553 905.672 96.9588 905.672 97.95C905.672 98.9411 906.474 99.7446 907.463 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 99.7446C902.482 99.7446 903.284 98.9411 903.284 97.95C903.284 96.9588 902.482 96.1553 901.492 96.1553C900.503 96.1553 899.701 96.9588 899.701 97.95C899.701 98.9411 900.503 99.7446 901.492 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 99.7446C896.512 99.7446 897.313 98.9411 897.313 97.95C897.313 96.9588 896.512 96.1553 895.522 96.1553C894.533 96.1553 893.731 96.9588 893.731 97.95C893.731 98.9411 894.533 99.7446 895.522 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 99.7446C890.541 99.7446 891.343 98.9411 891.343 97.95C891.343 96.9588 890.541 96.1553 889.552 96.1553C888.563 96.1553 887.761 96.9588 887.761 97.95C887.761 98.9411 888.563 99.7446 889.552 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 99.7446C884.571 99.7446 885.373 98.9411 885.373 97.95C885.373 96.9588 884.571 96.1553 883.582 96.1553C882.593 96.1553 881.791 96.9588 881.791 97.95C881.791 98.9411 882.593 99.7446 883.582 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 99.7446C878.601 99.7446 879.403 98.9411 879.403 97.95C879.403 96.9588 878.601 96.1553 877.612 96.1553C876.623 96.1553 875.821 96.9588 875.821 97.95C875.821 98.9411 876.623 99.7446 877.612 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 99.7446C872.631 99.7446 873.433 98.9411 873.433 97.95C873.433 96.9588 872.631 96.1553 871.642 96.1553C870.653 96.1553 869.851 96.9588 869.851 97.95C869.851 98.9411 870.653 99.7446 871.642 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 99.7446C866.661 99.7446 867.463 98.9411 867.463 97.95C867.463 96.9588 866.661 96.1553 865.672 96.1553C864.683 96.1553 863.881 96.9588 863.881 97.95C863.881 98.9411 864.683 99.7446 865.672 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 99.7446C860.691 99.7446 861.493 98.9411 861.493 97.95C861.493 96.9588 860.691 96.1553 859.701 96.1553C858.712 96.1553 857.91 96.9588 857.91 97.95C857.91 98.9411 858.712 99.7446 859.701 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 99.7446C854.721 99.7446 855.522 98.9411 855.522 97.95C855.522 96.9588 854.721 96.1553 853.731 96.1553C852.742 96.1553 851.94 96.9588 851.94 97.95C851.94 98.9411 852.742 99.7446 853.731 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 99.7446C848.75 99.7446 849.552 98.9411 849.552 97.95C849.552 96.9588 848.75 96.1553 847.761 96.1553C846.772 96.1553 845.97 96.9588 845.97 97.95C845.97 98.9411 846.772 99.7446 847.761 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 99.7446C842.78 99.7446 843.582 98.9411 843.582 97.95C843.582 96.9588 842.78 96.1553 841.791 96.1553C840.802 96.1553 840 96.9588 840 97.95C840 98.9411 840.802 99.7446 841.791 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 99.7446C836.81 99.7446 837.612 98.9411 837.612 97.95C837.612 96.9588 836.81 96.1553 835.821 96.1553C834.832 96.1553 834.03 96.9588 834.03 97.95C834.03 98.9411 834.832 99.7446 835.821 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 99.7446C830.84 99.7446 831.642 98.9411 831.642 97.95C831.642 96.9588 830.84 96.1553 829.851 96.1553C828.862 96.1553 828.06 96.9588 828.06 97.95C828.06 98.9411 828.862 99.7446 829.851 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 99.7446C824.87 99.7446 825.672 98.9411 825.672 97.95C825.672 96.9588 824.87 96.1553 823.881 96.1553C822.891 96.1553 822.09 96.9588 822.09 97.95C822.09 98.9411 822.891 99.7446 823.881 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 99.7446C818.9 99.7446 819.701 98.9411 819.701 97.95C819.701 96.9588 818.9 96.1553 817.91 96.1553C816.921 96.1553 816.119 96.9588 816.119 97.95C816.119 98.9411 816.921 99.7446 817.91 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 99.7446C812.929 99.7446 813.731 98.9411 813.731 97.95C813.731 96.9588 812.929 96.1553 811.94 96.1553C810.951 96.1553 810.149 96.9588 810.149 97.95C810.149 98.9411 810.951 99.7446 811.94 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 99.7446C806.959 99.7446 807.761 98.9411 807.761 97.95C807.761 96.9588 806.959 96.1553 805.97 96.1553C804.981 96.1553 804.179 96.9588 804.179 97.95C804.179 98.9411 804.981 99.7446 805.97 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M800 99.7446C800.989 99.7446 801.791 98.9411 801.791 97.95C801.791 96.9588 800.989 96.1553 800 96.1553C799.011 96.1553 798.209 96.9588 798.209 97.95C798.209 98.9411 799.011 99.7446 800 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 99.7446C795.019 99.7446 795.821 98.9411 795.821 97.95C795.821 96.9588 795.019 96.1553 794.03 96.1553C793.041 96.1553 792.239 96.9588 792.239 97.95C792.239 98.9411 793.041 99.7446 794.03 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 99.7446C789.049 99.7446 789.851 98.9411 789.851 97.95C789.851 96.9588 789.049 96.1553 788.06 96.1553C787.071 96.1553 786.269 96.9588 786.269 97.95C786.269 98.9411 787.071 99.7446 788.06 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 99.7446C783.079 99.7446 783.881 98.9411 783.881 97.95C783.881 96.9588 783.079 96.1553 782.09 96.1553C781.1 96.1553 780.299 96.9588 780.299 97.95C780.299 98.9411 781.1 99.7446 782.09 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 99.7446C777.109 99.7446 777.91 98.9411 777.91 97.95C777.91 96.9588 777.109 96.1553 776.119 96.1553C775.13 96.1553 774.328 96.9588 774.328 97.95C774.328 98.9411 775.13 99.7446 776.119 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 99.7446C771.138 99.7446 771.94 98.9411 771.94 97.95C771.94 96.9588 771.138 96.1553 770.149 96.1553C769.16 96.1553 768.358 96.9588 768.358 97.95C768.358 98.9411 769.16 99.7446 770.149 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 99.7446C765.168 99.7446 765.97 98.9411 765.97 97.95C765.97 96.9588 765.168 96.1553 764.179 96.1553C763.19 96.1553 762.388 96.9588 762.388 97.95C762.388 98.9411 763.19 99.7446 764.179 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 99.7446C759.198 99.7446 760 98.9411 760 97.95C760 96.9588 759.198 96.1553 758.209 96.1553C757.22 96.1553 756.418 96.9588 756.418 97.95C756.418 98.9411 757.22 99.7446 758.209 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 99.7446C753.228 99.7446 754.03 98.9411 754.03 97.95C754.03 96.9588 753.228 96.1553 752.239 96.1553C751.25 96.1553 750.448 96.9588 750.448 97.95C750.448 98.9411 751.25 99.7446 752.239 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 99.7446C747.258 99.7446 748.06 98.9411 748.06 97.95C748.06 96.9588 747.258 96.1553 746.269 96.1553C745.279 96.1553 744.478 96.9588 744.478 97.95C744.478 98.9411 745.279 99.7446 746.269 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 99.7446C741.288 99.7446 742.09 98.9411 742.09 97.95C742.09 96.9588 741.288 96.1553 740.299 96.1553C739.309 96.1553 738.507 96.9588 738.507 97.95C738.507 98.9411 739.309 99.7446 740.299 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 99.7446C735.318 99.7446 736.119 98.9411 736.119 97.95C736.119 96.9588 735.318 96.1553 734.328 96.1553C733.339 96.1553 732.537 96.9588 732.537 97.95C732.537 98.9411 733.339 99.7446 734.328 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 99.7446C729.347 99.7446 730.149 98.9411 730.149 97.95C730.149 96.9588 729.347 96.1553 728.358 96.1553C727.369 96.1553 726.567 96.9588 726.567 97.95C726.567 98.9411 727.369 99.7446 728.358 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 99.7446C723.377 99.7446 724.179 98.9411 724.179 97.95C724.179 96.9588 723.377 96.1553 722.388 96.1553C721.399 96.1553 720.597 96.9588 720.597 97.95C720.597 98.9411 721.399 99.7446 722.388 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 99.7446C717.407 99.7446 718.209 98.9411 718.209 97.95C718.209 96.9588 717.407 96.1553 716.418 96.1553C715.429 96.1553 714.627 96.9588 714.627 97.95C714.627 98.9411 715.429 99.7446 716.418 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 99.7446C711.437 99.7446 712.239 98.9411 712.239 97.95C712.239 96.9588 711.437 96.1553 710.448 96.1553C709.459 96.1553 708.657 96.9588 708.657 97.95C708.657 98.9411 709.459 99.7446 710.448 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 99.7446C705.467 99.7446 706.269 98.9411 706.269 97.95C706.269 96.9588 705.467 96.1553 704.478 96.1553C703.488 96.1553 702.687 96.9588 702.687 97.95C702.687 98.9411 703.488 99.7446 704.478 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 99.7446C699.497 99.7446 700.299 98.9411 700.299 97.95C700.299 96.9588 699.497 96.1553 698.507 96.1553C697.518 96.1553 696.716 96.9588 696.716 97.95C696.716 98.9411 697.518 99.7446 698.507 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 99.7446C693.526 99.7446 694.328 98.9411 694.328 97.95C694.328 96.9588 693.526 96.1553 692.537 96.1553C691.548 96.1553 690.746 96.9588 690.746 97.95C690.746 98.9411 691.548 99.7446 692.537 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 99.7446C687.556 99.7446 688.358 98.9411 688.358 97.95C688.358 96.9588 687.556 96.1553 686.567 96.1553C685.578 96.1553 684.776 96.9588 684.776 97.95C684.776 98.9411 685.578 99.7446 686.567 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 99.7446C681.586 99.7446 682.388 98.9411 682.388 97.95C682.388 96.9588 681.586 96.1553 680.597 96.1553C679.608 96.1553 678.806 96.9588 678.806 97.95C678.806 98.9411 679.608 99.7446 680.597 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 99.7446C675.616 99.7446 676.418 98.9411 676.418 97.95C676.418 96.9588 675.616 96.1553 674.627 96.1553C673.638 96.1553 672.836 96.9588 672.836 97.95C672.836 98.9411 673.638 99.7446 674.627 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 99.7446C669.646 99.7446 670.448 98.9411 670.448 97.95C670.448 96.9588 669.646 96.1553 668.657 96.1553C667.668 96.1553 666.866 96.9588 666.866 97.95C666.866 98.9411 667.668 99.7446 668.657 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 99.7446C663.676 99.7446 664.478 98.9411 664.478 97.95C664.478 96.9588 663.676 96.1553 662.687 96.1553C661.697 96.1553 660.896 96.9588 660.896 97.95C660.896 98.9411 661.697 99.7446 662.687 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 99.7446C657.706 99.7446 658.507 98.9411 658.507 97.95C658.507 96.9588 657.706 96.1553 656.716 96.1553C655.727 96.1553 654.925 96.9588 654.925 97.95C654.925 98.9411 655.727 99.7446 656.716 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 99.7446C651.735 99.7446 652.537 98.9411 652.537 97.95C652.537 96.9588 651.735 96.1553 650.746 96.1553C649.757 96.1553 648.955 96.9588 648.955 97.95C648.955 98.9411 649.757 99.7446 650.746 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 99.7446C645.765 99.7446 646.567 98.9411 646.567 97.95C646.567 96.9588 645.765 96.1553 644.776 96.1553C643.787 96.1553 642.985 96.9588 642.985 97.95C642.985 98.9411 643.787 99.7446 644.776 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 99.7446C639.795 99.7446 640.597 98.9411 640.597 97.95C640.597 96.9588 639.795 96.1553 638.806 96.1553C637.817 96.1553 637.015 96.9588 637.015 97.95C637.015 98.9411 637.817 99.7446 638.806 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 99.7446C633.825 99.7446 634.627 98.9411 634.627 97.95C634.627 96.9588 633.825 96.1553 632.836 96.1553C631.847 96.1553 631.045 96.9588 631.045 97.95C631.045 98.9411 631.847 99.7446 632.836 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 99.7446C627.855 99.7446 628.657 98.9411 628.657 97.95C628.657 96.9588 627.855 96.1553 626.866 96.1553C625.876 96.1553 625.075 96.9588 625.075 97.95C625.075 98.9411 625.876 99.7446 626.866 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 99.7446C621.885 99.7446 622.687 98.9411 622.687 97.95C622.687 96.9588 621.885 96.1553 620.895 96.1553C619.906 96.1553 619.104 96.9588 619.104 97.95C619.104 98.9411 619.906 99.7446 620.895 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 99.7446C615.915 99.7446 616.716 98.9411 616.716 97.95C616.716 96.9588 615.915 96.1553 614.925 96.1553C613.936 96.1553 613.134 96.9588 613.134 97.95C613.134 98.9411 613.936 99.7446 614.925 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 99.7446C609.944 99.7446 610.746 98.9411 610.746 97.95C610.746 96.9588 609.944 96.1553 608.955 96.1553C607.966 96.1553 607.164 96.9588 607.164 97.95C607.164 98.9411 607.966 99.7446 608.955 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 99.7446C592.034 99.7446 592.836 98.9411 592.836 97.95C592.836 96.9588 592.034 96.1553 591.045 96.1553C590.056 96.1553 589.254 96.9588 589.254 97.95C589.254 98.9411 590.056 99.7446 591.045 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 99.7446C586.064 99.7446 586.866 98.9411 586.866 97.95C586.866 96.9588 586.064 96.1553 585.075 96.1553C584.085 96.1553 583.284 96.9588 583.284 97.95C583.284 98.9411 584.085 99.7446 585.075 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M525.373 99.7446C526.362 99.7446 527.164 98.9411 527.164 97.95C527.164 96.9588 526.362 96.1553 525.373 96.1553C524.384 96.1553 523.582 96.9588 523.582 97.95C523.582 98.9411 524.384 99.7446 525.373 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 93.7625C1057.71 93.7625 1058.51 92.959 1058.51 91.9678C1058.51 90.9766 1057.71 90.1731 1056.72 90.1731C1055.73 90.1731 1054.93 90.9766 1054.93 91.9678C1054.93 92.959 1055.73 93.7625 1056.72 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 93.7625C1051.74 93.7625 1052.54 92.959 1052.54 91.9678C1052.54 90.9766 1051.74 90.1731 1050.75 90.1731C1049.76 90.1731 1048.96 90.9766 1048.96 91.9678C1048.96 92.959 1049.76 93.7625 1050.75 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 93.7625C1027.85 93.7625 1028.66 92.959 1028.66 91.9678C1028.66 90.9766 1027.85 90.1731 1026.87 90.1731C1025.88 90.1731 1025.07 90.9766 1025.07 91.9678C1025.07 92.959 1025.88 93.7625 1026.87 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 93.7625C1021.88 93.7625 1022.69 92.959 1022.69 91.9678C1022.69 90.9766 1021.88 90.1731 1020.9 90.1731C1019.91 90.1731 1019.1 90.9766 1019.1 91.9678C1019.1 92.959 1019.91 93.7625 1020.9 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 93.7625C1009.94 93.7625 1010.75 92.959 1010.75 91.9678C1010.75 90.9766 1009.94 90.1731 1008.96 90.1731C1007.97 90.1731 1007.16 90.9766 1007.16 91.9678C1007.16 92.959 1007.97 93.7625 1008.96 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 93.7625C1003.97 93.7625 1004.78 92.959 1004.78 91.9678C1004.78 90.9766 1003.97 90.1731 1002.99 90.1731C1002 90.1731 1001.19 90.9766 1001.19 91.9678C1001.19 92.959 1002 93.7625 1002.99 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 93.7625C998.004 93.7625 998.806 92.959 998.806 91.9678C998.806 90.9766 998.004 90.1731 997.015 90.1731C996.026 90.1731 995.224 90.9766 995.224 91.9678C995.224 92.959 996.026 93.7625 997.015 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 93.7625C992.034 93.7625 992.836 92.959 992.836 91.9678C992.836 90.9766 992.034 90.1731 991.045 90.1731C990.056 90.1731 989.254 90.9766 989.254 91.9678C989.254 92.959 990.056 93.7625 991.045 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 93.7625C986.064 93.7625 986.866 92.959 986.866 91.9678C986.866 90.9766 986.064 90.1731 985.075 90.1731C984.085 90.1731 983.284 90.9766 983.284 91.9678C983.284 92.959 984.085 93.7625 985.075 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 93.7625C980.094 93.7625 980.896 92.959 980.896 91.9678C980.896 90.9766 980.094 90.1731 979.104 90.1731C978.115 90.1731 977.313 90.9766 977.313 91.9678C977.313 92.959 978.115 93.7625 979.104 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 93.7625C974.123 93.7625 974.925 92.959 974.925 91.9678C974.925 90.9766 974.123 90.1731 973.134 90.1731C972.145 90.1731 971.343 90.9766 971.343 91.9678C971.343 92.959 972.145 93.7625 973.134 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 93.7625C968.153 93.7625 968.955 92.959 968.955 91.9678C968.955 90.9766 968.153 90.1731 967.164 90.1731C966.175 90.1731 965.373 90.9766 965.373 91.9678C965.373 92.959 966.175 93.7625 967.164 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 93.7625C962.183 93.7625 962.985 92.959 962.985 91.9678C962.985 90.9766 962.183 90.1731 961.194 90.1731C960.205 90.1731 959.403 90.9766 959.403 91.9678C959.403 92.959 960.205 93.7625 961.194 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 93.7625C956.213 93.7625 957.015 92.959 957.015 91.9678C957.015 90.9766 956.213 90.1731 955.224 90.1731C954.235 90.1731 953.433 90.9766 953.433 91.9678C953.433 92.959 954.235 93.7625 955.224 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 93.7625C950.243 93.7625 951.045 92.959 951.045 91.9678C951.045 90.9766 950.243 90.1731 949.254 90.1731C948.265 90.1731 947.463 90.9766 947.463 91.9678C947.463 92.959 948.265 93.7625 949.254 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 93.7625C944.273 93.7625 945.075 92.959 945.075 91.9678C945.075 90.9766 944.273 90.1731 943.284 90.1731C942.294 90.1731 941.492 90.9766 941.492 91.9678C941.492 92.959 942.294 93.7625 943.284 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 93.7625C938.303 93.7625 939.105 92.959 939.105 91.9678C939.105 90.9766 938.303 90.1731 937.313 90.1731C936.324 90.1731 935.522 90.9766 935.522 91.9678C935.522 92.959 936.324 93.7625 937.313 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 93.7625C932.332 93.7625 933.134 92.959 933.134 91.9678C933.134 90.9766 932.332 90.1731 931.343 90.1731C930.354 90.1731 929.552 90.9766 929.552 91.9678C929.552 92.959 930.354 93.7625 931.343 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 93.7625C926.362 93.7625 927.164 92.959 927.164 91.9678C927.164 90.9766 926.362 90.1731 925.373 90.1731C924.384 90.1731 923.582 90.9766 923.582 91.9678C923.582 92.959 924.384 93.7625 925.373 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 93.7625C920.392 93.7625 921.194 92.959 921.194 91.9678C921.194 90.9766 920.392 90.1731 919.403 90.1731C918.414 90.1731 917.612 90.9766 917.612 91.9678C917.612 92.959 918.414 93.7625 919.403 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 93.7625C914.422 93.7625 915.224 92.959 915.224 91.9678C915.224 90.9766 914.422 90.1731 913.433 90.1731C912.444 90.1731 911.642 90.9766 911.642 91.9678C911.642 92.959 912.444 93.7625 913.433 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 93.7625C908.452 93.7625 909.254 92.959 909.254 91.9678C909.254 90.9766 908.452 90.1731 907.463 90.1731C906.474 90.1731 905.672 90.9766 905.672 91.9678C905.672 92.959 906.474 93.7625 907.463 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 93.7625C902.482 93.7625 903.284 92.959 903.284 91.9678C903.284 90.9766 902.482 90.1731 901.492 90.1731C900.503 90.1731 899.701 90.9766 899.701 91.9678C899.701 92.959 900.503 93.7625 901.492 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 93.7625C896.512 93.7625 897.313 92.959 897.313 91.9678C897.313 90.9766 896.512 90.1731 895.522 90.1731C894.533 90.1731 893.731 90.9766 893.731 91.9678C893.731 92.959 894.533 93.7625 895.522 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 93.7625C890.541 93.7625 891.343 92.959 891.343 91.9678C891.343 90.9766 890.541 90.1731 889.552 90.1731C888.563 90.1731 887.761 90.9766 887.761 91.9678C887.761 92.959 888.563 93.7625 889.552 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 93.7625C884.571 93.7625 885.373 92.959 885.373 91.9678C885.373 90.9766 884.571 90.1731 883.582 90.1731C882.593 90.1731 881.791 90.9766 881.791 91.9678C881.791 92.959 882.593 93.7625 883.582 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 93.7625C878.601 93.7625 879.403 92.959 879.403 91.9678C879.403 90.9766 878.601 90.1731 877.612 90.1731C876.623 90.1731 875.821 90.9766 875.821 91.9678C875.821 92.959 876.623 93.7625 877.612 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 93.7625C872.631 93.7625 873.433 92.959 873.433 91.9678C873.433 90.9766 872.631 90.1731 871.642 90.1731C870.653 90.1731 869.851 90.9766 869.851 91.9678C869.851 92.959 870.653 93.7625 871.642 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 93.7625C866.661 93.7625 867.463 92.959 867.463 91.9678C867.463 90.9766 866.661 90.1731 865.672 90.1731C864.683 90.1731 863.881 90.9766 863.881 91.9678C863.881 92.959 864.683 93.7625 865.672 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 93.7625C860.691 93.7625 861.493 92.959 861.493 91.9678C861.493 90.9766 860.691 90.1731 859.701 90.1731C858.712 90.1731 857.91 90.9766 857.91 91.9678C857.91 92.959 858.712 93.7625 859.701 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 93.7625C854.721 93.7625 855.522 92.959 855.522 91.9678C855.522 90.9766 854.721 90.1731 853.731 90.1731C852.742 90.1731 851.94 90.9766 851.94 91.9678C851.94 92.959 852.742 93.7625 853.731 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 93.7625C848.75 93.7625 849.552 92.959 849.552 91.9678C849.552 90.9766 848.75 90.1731 847.761 90.1731C846.772 90.1731 845.97 90.9766 845.97 91.9678C845.97 92.959 846.772 93.7625 847.761 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 93.7625C842.78 93.7625 843.582 92.959 843.582 91.9678C843.582 90.9766 842.78 90.1731 841.791 90.1731C840.802 90.1731 840 90.9766 840 91.9678C840 92.959 840.802 93.7625 841.791 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 93.7625C836.81 93.7625 837.612 92.959 837.612 91.9678C837.612 90.9766 836.81 90.1731 835.821 90.1731C834.832 90.1731 834.03 90.9766 834.03 91.9678C834.03 92.959 834.832 93.7625 835.821 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 93.7625C830.84 93.7625 831.642 92.959 831.642 91.9678C831.642 90.9766 830.84 90.1731 829.851 90.1731C828.862 90.1731 828.06 90.9766 828.06 91.9678C828.06 92.959 828.862 93.7625 829.851 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 93.7625C824.87 93.7625 825.672 92.959 825.672 91.9678C825.672 90.9766 824.87 90.1731 823.881 90.1731C822.891 90.1731 822.09 90.9766 822.09 91.9678C822.09 92.959 822.891 93.7625 823.881 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 93.7625C818.9 93.7625 819.701 92.959 819.701 91.9678C819.701 90.9766 818.9 90.1731 817.91 90.1731C816.921 90.1731 816.119 90.9766 816.119 91.9678C816.119 92.959 816.921 93.7625 817.91 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 93.7625C812.929 93.7625 813.731 92.959 813.731 91.9678C813.731 90.9766 812.929 90.1731 811.94 90.1731C810.951 90.1731 810.149 90.9766 810.149 91.9678C810.149 92.959 810.951 93.7625 811.94 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 93.7625C806.959 93.7625 807.761 92.959 807.761 91.9678C807.761 90.9766 806.959 90.1731 805.97 90.1731C804.981 90.1731 804.179 90.9766 804.179 91.9678C804.179 92.959 804.981 93.7625 805.97 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M800 93.7625C800.989 93.7625 801.791 92.959 801.791 91.9678C801.791 90.9766 800.989 90.1731 800 90.1731C799.011 90.1731 798.209 90.9766 798.209 91.9678C798.209 92.959 799.011 93.7625 800 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 93.7625C795.019 93.7625 795.821 92.959 795.821 91.9678C795.821 90.9766 795.019 90.1731 794.03 90.1731C793.041 90.1731 792.239 90.9766 792.239 91.9678C792.239 92.959 793.041 93.7625 794.03 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 93.7625C789.049 93.7625 789.851 92.959 789.851 91.9678C789.851 90.9766 789.049 90.1731 788.06 90.1731C787.071 90.1731 786.269 90.9766 786.269 91.9678C786.269 92.959 787.071 93.7625 788.06 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 93.7625C783.079 93.7625 783.881 92.959 783.881 91.9678C783.881 90.9766 783.079 90.1731 782.09 90.1731C781.1 90.1731 780.299 90.9766 780.299 91.9678C780.299 92.959 781.1 93.7625 782.09 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 93.7625C777.109 93.7625 777.91 92.959 777.91 91.9678C777.91 90.9766 777.109 90.1731 776.119 90.1731C775.13 90.1731 774.328 90.9766 774.328 91.9678C774.328 92.959 775.13 93.7625 776.119 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 93.7625C771.138 93.7625 771.94 92.959 771.94 91.9678C771.94 90.9766 771.138 90.1731 770.149 90.1731C769.16 90.1731 768.358 90.9766 768.358 91.9678C768.358 92.959 769.16 93.7625 770.149 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 93.7625C765.168 93.7625 765.97 92.959 765.97 91.9678C765.97 90.9766 765.168 90.1731 764.179 90.1731C763.19 90.1731 762.388 90.9766 762.388 91.9678C762.388 92.959 763.19 93.7625 764.179 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 93.7625C759.198 93.7625 760 92.959 760 91.9678C760 90.9766 759.198 90.1731 758.209 90.1731C757.22 90.1731 756.418 90.9766 756.418 91.9678C756.418 92.959 757.22 93.7625 758.209 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 93.7625C753.228 93.7625 754.03 92.959 754.03 91.9678C754.03 90.9766 753.228 90.1731 752.239 90.1731C751.25 90.1731 750.448 90.9766 750.448 91.9678C750.448 92.959 751.25 93.7625 752.239 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 93.7625C747.258 93.7625 748.06 92.959 748.06 91.9678C748.06 90.9766 747.258 90.1731 746.269 90.1731C745.279 90.1731 744.478 90.9766 744.478 91.9678C744.478 92.959 745.279 93.7625 746.269 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 93.7625C741.288 93.7625 742.09 92.959 742.09 91.9678C742.09 90.9766 741.288 90.1731 740.299 90.1731C739.309 90.1731 738.507 90.9766 738.507 91.9678C738.507 92.959 739.309 93.7625 740.299 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 93.7625C735.318 93.7625 736.119 92.959 736.119 91.9678C736.119 90.9766 735.318 90.1731 734.328 90.1731C733.339 90.1731 732.537 90.9766 732.537 91.9678C732.537 92.959 733.339 93.7625 734.328 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 93.7625C729.347 93.7625 730.149 92.959 730.149 91.9678C730.149 90.9766 729.347 90.1731 728.358 90.1731C727.369 90.1731 726.567 90.9766 726.567 91.9678C726.567 92.959 727.369 93.7625 728.358 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 93.7625C723.377 93.7625 724.179 92.959 724.179 91.9678C724.179 90.9766 723.377 90.1731 722.388 90.1731C721.399 90.1731 720.597 90.9766 720.597 91.9678C720.597 92.959 721.399 93.7625 722.388 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 93.7625C717.407 93.7625 718.209 92.959 718.209 91.9678C718.209 90.9766 717.407 90.1731 716.418 90.1731C715.429 90.1731 714.627 90.9766 714.627 91.9678C714.627 92.959 715.429 93.7625 716.418 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 93.7625C711.437 93.7625 712.239 92.959 712.239 91.9678C712.239 90.9766 711.437 90.1731 710.448 90.1731C709.459 90.1731 708.657 90.9766 708.657 91.9678C708.657 92.959 709.459 93.7625 710.448 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 93.7625C705.467 93.7625 706.269 92.959 706.269 91.9678C706.269 90.9766 705.467 90.1731 704.478 90.1731C703.488 90.1731 702.687 90.9766 702.687 91.9678C702.687 92.959 703.488 93.7625 704.478 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 93.7625C699.497 93.7625 700.299 92.959 700.299 91.9678C700.299 90.9766 699.497 90.1731 698.507 90.1731C697.518 90.1731 696.716 90.9766 696.716 91.9678C696.716 92.959 697.518 93.7625 698.507 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 93.7625C693.526 93.7625 694.328 92.959 694.328 91.9678C694.328 90.9766 693.526 90.1731 692.537 90.1731C691.548 90.1731 690.746 90.9766 690.746 91.9678C690.746 92.959 691.548 93.7625 692.537 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 93.7625C687.556 93.7625 688.358 92.959 688.358 91.9678C688.358 90.9766 687.556 90.1731 686.567 90.1731C685.578 90.1731 684.776 90.9766 684.776 91.9678C684.776 92.959 685.578 93.7625 686.567 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 93.7625C681.586 93.7625 682.388 92.959 682.388 91.9678C682.388 90.9766 681.586 90.1731 680.597 90.1731C679.608 90.1731 678.806 90.9766 678.806 91.9678C678.806 92.959 679.608 93.7625 680.597 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 93.7625C675.616 93.7625 676.418 92.959 676.418 91.9678C676.418 90.9766 675.616 90.1731 674.627 90.1731C673.638 90.1731 672.836 90.9766 672.836 91.9678C672.836 92.959 673.638 93.7625 674.627 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 93.7625C669.646 93.7625 670.448 92.959 670.448 91.9678C670.448 90.9766 669.646 90.1731 668.657 90.1731C667.668 90.1731 666.866 90.9766 666.866 91.9678C666.866 92.959 667.668 93.7625 668.657 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 93.7625C663.676 93.7625 664.478 92.959 664.478 91.9678C664.478 90.9766 663.676 90.1731 662.687 90.1731C661.697 90.1731 660.896 90.9766 660.896 91.9678C660.896 92.959 661.697 93.7625 662.687 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 93.7625C657.706 93.7625 658.507 92.959 658.507 91.9678C658.507 90.9766 657.706 90.1731 656.716 90.1731C655.727 90.1731 654.925 90.9766 654.925 91.9678C654.925 92.959 655.727 93.7625 656.716 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 93.7625C651.735 93.7625 652.537 92.959 652.537 91.9678C652.537 90.9766 651.735 90.1731 650.746 90.1731C649.757 90.1731 648.955 90.9766 648.955 91.9678C648.955 92.959 649.757 93.7625 650.746 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 93.7625C645.765 93.7625 646.567 92.959 646.567 91.9678C646.567 90.9766 645.765 90.1731 644.776 90.1731C643.787 90.1731 642.985 90.9766 642.985 91.9678C642.985 92.959 643.787 93.7625 644.776 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 93.7625C639.795 93.7625 640.597 92.959 640.597 91.9678C640.597 90.9766 639.795 90.1731 638.806 90.1731C637.817 90.1731 637.015 90.9766 637.015 91.9678C637.015 92.959 637.817 93.7625 638.806 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 93.7625C633.825 93.7625 634.627 92.959 634.627 91.9678C634.627 90.9766 633.825 90.1731 632.836 90.1731C631.847 90.1731 631.045 90.9766 631.045 91.9678C631.045 92.959 631.847 93.7625 632.836 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 93.7625C627.855 93.7625 628.657 92.959 628.657 91.9678C628.657 90.9766 627.855 90.1731 626.866 90.1731C625.876 90.1731 625.075 90.9766 625.075 91.9678C625.075 92.959 625.876 93.7625 626.866 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 93.7625C621.885 93.7625 622.687 92.959 622.687 91.9678C622.687 90.9766 621.885 90.1731 620.895 90.1731C619.906 90.1731 619.104 90.9766 619.104 91.9678C619.104 92.959 619.906 93.7625 620.895 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 93.7625C615.915 93.7625 616.716 92.959 616.716 91.9678C616.716 90.9766 615.915 90.1731 614.925 90.1731C613.936 90.1731 613.134 90.9766 613.134 91.9678C613.134 92.959 613.936 93.7625 614.925 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 93.7625C609.944 93.7625 610.746 92.959 610.746 91.9678C610.746 90.9766 609.944 90.1731 608.955 90.1731C607.966 90.1731 607.164 90.9766 607.164 91.9678C607.164 92.959 607.966 93.7625 608.955 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 93.7625C592.034 93.7625 592.836 92.959 592.836 91.9678C592.836 90.9766 592.034 90.1731 591.045 90.1731C590.056 90.1731 589.254 90.9766 589.254 91.9678C589.254 92.959 590.056 93.7625 591.045 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 93.7625C586.064 93.7625 586.866 92.959 586.866 91.9678C586.866 90.9766 586.064 90.1731 585.075 90.1731C584.085 90.1731 583.284 90.9766 583.284 91.9678C583.284 92.959 584.085 93.7625 585.075 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 93.7625C580.094 93.7625 580.896 92.959 580.896 91.9678C580.896 90.9766 580.094 90.1731 579.104 90.1731C578.115 90.1731 577.313 90.9766 577.313 91.9678C577.313 92.959 578.115 93.7625 579.104 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 93.7625C568.153 93.7625 568.955 92.959 568.955 91.9678C568.955 90.9766 568.153 90.1731 567.164 90.1731C566.175 90.1731 565.373 90.9766 565.373 91.9678C565.373 92.959 566.175 93.7625 567.164 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 93.7625C562.183 93.7625 562.985 92.959 562.985 91.9678C562.985 90.9766 562.183 90.1731 561.194 90.1731C560.205 90.1731 559.403 90.9766 559.403 91.9678C559.403 92.959 560.205 93.7625 561.194 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 87.78C1075.62 87.78 1076.42 86.9765 1076.42 85.9854C1076.42 84.9942 1075.62 84.1907 1074.63 84.1907C1073.64 84.1907 1072.84 84.9942 1072.84 85.9854C1072.84 86.9765 1073.64 87.78 1074.63 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 87.78C1069.65 87.78 1070.45 86.9765 1070.45 85.9854C1070.45 84.9942 1069.65 84.1907 1068.66 84.1907C1067.67 84.1907 1066.87 84.9942 1066.87 85.9854C1066.87 86.9765 1067.67 87.78 1068.66 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 87.78C1063.68 87.78 1064.48 86.9765 1064.48 85.9854C1064.48 84.9942 1063.68 84.1907 1062.69 84.1907C1061.7 84.1907 1060.9 84.9942 1060.9 85.9854C1060.9 86.9765 1061.7 87.78 1062.69 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 87.78C1057.71 87.78 1058.51 86.9765 1058.51 85.9854C1058.51 84.9942 1057.71 84.1907 1056.72 84.1907C1055.73 84.1907 1054.93 84.9942 1054.93 85.9854C1054.93 86.9765 1055.73 87.78 1056.72 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 87.78C1051.74 87.78 1052.54 86.9765 1052.54 85.9854C1052.54 84.9942 1051.74 84.1907 1050.75 84.1907C1049.76 84.1907 1048.96 84.9942 1048.96 85.9854C1048.96 86.9765 1049.76 87.78 1050.75 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 87.78C1027.85 87.78 1028.66 86.9765 1028.66 85.9854C1028.66 84.9942 1027.85 84.1907 1026.87 84.1907C1025.88 84.1907 1025.07 84.9942 1025.07 85.9854C1025.07 86.9765 1025.88 87.78 1026.87 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 87.78C1021.88 87.78 1022.69 86.9765 1022.69 85.9854C1022.69 84.9942 1021.88 84.1907 1020.9 84.1907C1019.91 84.1907 1019.1 84.9942 1019.1 85.9854C1019.1 86.9765 1019.91 87.78 1020.9 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 87.78C1015.91 87.78 1016.72 86.9765 1016.72 85.9854C1016.72 84.9942 1015.91 84.1907 1014.93 84.1907C1013.94 84.1907 1013.13 84.9942 1013.13 85.9854C1013.13 86.9765 1013.94 87.78 1014.93 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 87.78C1009.94 87.78 1010.75 86.9765 1010.75 85.9854C1010.75 84.9942 1009.94 84.1907 1008.96 84.1907C1007.97 84.1907 1007.16 84.9942 1007.16 85.9854C1007.16 86.9765 1007.97 87.78 1008.96 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 87.78C1003.97 87.78 1004.78 86.9765 1004.78 85.9854C1004.78 84.9942 1003.97 84.1907 1002.99 84.1907C1002 84.1907 1001.19 84.9942 1001.19 85.9854C1001.19 86.9765 1002 87.78 1002.99 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 87.78C998.004 87.78 998.806 86.9765 998.806 85.9854C998.806 84.9942 998.004 84.1907 997.015 84.1907C996.026 84.1907 995.224 84.9942 995.224 85.9854C995.224 86.9765 996.026 87.78 997.015 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 87.78C992.034 87.78 992.836 86.9765 992.836 85.9854C992.836 84.9942 992.034 84.1907 991.045 84.1907C990.056 84.1907 989.254 84.9942 989.254 85.9854C989.254 86.9765 990.056 87.78 991.045 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 87.78C986.064 87.78 986.866 86.9765 986.866 85.9854C986.866 84.9942 986.064 84.1907 985.075 84.1907C984.085 84.1907 983.284 84.9942 983.284 85.9854C983.284 86.9765 984.085 87.78 985.075 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 87.78C980.094 87.78 980.896 86.9765 980.896 85.9854C980.896 84.9942 980.094 84.1907 979.104 84.1907C978.115 84.1907 977.313 84.9942 977.313 85.9854C977.313 86.9765 978.115 87.78 979.104 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 87.78C974.123 87.78 974.925 86.9765 974.925 85.9854C974.925 84.9942 974.123 84.1907 973.134 84.1907C972.145 84.1907 971.343 84.9942 971.343 85.9854C971.343 86.9765 972.145 87.78 973.134 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 87.78C968.153 87.78 968.955 86.9765 968.955 85.9854C968.955 84.9942 968.153 84.1907 967.164 84.1907C966.175 84.1907 965.373 84.9942 965.373 85.9854C965.373 86.9765 966.175 87.78 967.164 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 87.78C962.183 87.78 962.985 86.9765 962.985 85.9854C962.985 84.9942 962.183 84.1907 961.194 84.1907C960.205 84.1907 959.403 84.9942 959.403 85.9854C959.403 86.9765 960.205 87.78 961.194 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 87.78C956.213 87.78 957.015 86.9765 957.015 85.9854C957.015 84.9942 956.213 84.1907 955.224 84.1907C954.235 84.1907 953.433 84.9942 953.433 85.9854C953.433 86.9765 954.235 87.78 955.224 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 87.78C950.243 87.78 951.045 86.9765 951.045 85.9854C951.045 84.9942 950.243 84.1907 949.254 84.1907C948.265 84.1907 947.463 84.9942 947.463 85.9854C947.463 86.9765 948.265 87.78 949.254 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 87.78C944.273 87.78 945.075 86.9765 945.075 85.9854C945.075 84.9942 944.273 84.1907 943.284 84.1907C942.294 84.1907 941.492 84.9942 941.492 85.9854C941.492 86.9765 942.294 87.78 943.284 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 87.78C938.303 87.78 939.105 86.9765 939.105 85.9854C939.105 84.9942 938.303 84.1907 937.313 84.1907C936.324 84.1907 935.522 84.9942 935.522 85.9854C935.522 86.9765 936.324 87.78 937.313 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 87.78C932.332 87.78 933.134 86.9765 933.134 85.9854C933.134 84.9942 932.332 84.1907 931.343 84.1907C930.354 84.1907 929.552 84.9942 929.552 85.9854C929.552 86.9765 930.354 87.78 931.343 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 87.78C926.362 87.78 927.164 86.9765 927.164 85.9854C927.164 84.9942 926.362 84.1907 925.373 84.1907C924.384 84.1907 923.582 84.9942 923.582 85.9854C923.582 86.9765 924.384 87.78 925.373 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 87.78C920.392 87.78 921.194 86.9765 921.194 85.9854C921.194 84.9942 920.392 84.1907 919.403 84.1907C918.414 84.1907 917.612 84.9942 917.612 85.9854C917.612 86.9765 918.414 87.78 919.403 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 87.78C914.422 87.78 915.224 86.9765 915.224 85.9854C915.224 84.9942 914.422 84.1907 913.433 84.1907C912.444 84.1907 911.642 84.9942 911.642 85.9854C911.642 86.9765 912.444 87.78 913.433 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 87.78C908.452 87.78 909.254 86.9765 909.254 85.9854C909.254 84.9942 908.452 84.1907 907.463 84.1907C906.474 84.1907 905.672 84.9942 905.672 85.9854C905.672 86.9765 906.474 87.78 907.463 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 87.78C902.482 87.78 903.284 86.9765 903.284 85.9854C903.284 84.9942 902.482 84.1907 901.492 84.1907C900.503 84.1907 899.701 84.9942 899.701 85.9854C899.701 86.9765 900.503 87.78 901.492 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 87.78C896.512 87.78 897.313 86.9765 897.313 85.9854C897.313 84.9942 896.512 84.1907 895.522 84.1907C894.533 84.1907 893.731 84.9942 893.731 85.9854C893.731 86.9765 894.533 87.78 895.522 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 87.78C890.541 87.78 891.343 86.9765 891.343 85.9854C891.343 84.9942 890.541 84.1907 889.552 84.1907C888.563 84.1907 887.761 84.9942 887.761 85.9854C887.761 86.9765 888.563 87.78 889.552 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 87.78C884.571 87.78 885.373 86.9765 885.373 85.9854C885.373 84.9942 884.571 84.1907 883.582 84.1907C882.593 84.1907 881.791 84.9942 881.791 85.9854C881.791 86.9765 882.593 87.78 883.582 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 87.78C878.601 87.78 879.403 86.9765 879.403 85.9854C879.403 84.9942 878.601 84.1907 877.612 84.1907C876.623 84.1907 875.821 84.9942 875.821 85.9854C875.821 86.9765 876.623 87.78 877.612 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 87.78C872.631 87.78 873.433 86.9765 873.433 85.9854C873.433 84.9942 872.631 84.1907 871.642 84.1907C870.653 84.1907 869.851 84.9942 869.851 85.9854C869.851 86.9765 870.653 87.78 871.642 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 87.78C866.661 87.78 867.463 86.9765 867.463 85.9854C867.463 84.9942 866.661 84.1907 865.672 84.1907C864.683 84.1907 863.881 84.9942 863.881 85.9854C863.881 86.9765 864.683 87.78 865.672 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 87.78C860.691 87.78 861.493 86.9765 861.493 85.9854C861.493 84.9942 860.691 84.1907 859.701 84.1907C858.712 84.1907 857.91 84.9942 857.91 85.9854C857.91 86.9765 858.712 87.78 859.701 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 87.78C854.721 87.78 855.522 86.9765 855.522 85.9854C855.522 84.9942 854.721 84.1907 853.731 84.1907C852.742 84.1907 851.94 84.9942 851.94 85.9854C851.94 86.9765 852.742 87.78 853.731 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 87.78C848.75 87.78 849.552 86.9765 849.552 85.9854C849.552 84.9942 848.75 84.1907 847.761 84.1907C846.772 84.1907 845.97 84.9942 845.97 85.9854C845.97 86.9765 846.772 87.78 847.761 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 87.78C842.78 87.78 843.582 86.9765 843.582 85.9854C843.582 84.9942 842.78 84.1907 841.791 84.1907C840.802 84.1907 840 84.9942 840 85.9854C840 86.9765 840.802 87.78 841.791 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 87.78C836.81 87.78 837.612 86.9765 837.612 85.9854C837.612 84.9942 836.81 84.1907 835.821 84.1907C834.832 84.1907 834.03 84.9942 834.03 85.9854C834.03 86.9765 834.832 87.78 835.821 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 87.78C830.84 87.78 831.642 86.9765 831.642 85.9854C831.642 84.9942 830.84 84.1907 829.851 84.1907C828.862 84.1907 828.06 84.9942 828.06 85.9854C828.06 86.9765 828.862 87.78 829.851 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 87.78C824.87 87.78 825.672 86.9765 825.672 85.9854C825.672 84.9942 824.87 84.1907 823.881 84.1907C822.891 84.1907 822.09 84.9942 822.09 85.9854C822.09 86.9765 822.891 87.78 823.881 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 87.78C818.9 87.78 819.701 86.9765 819.701 85.9854C819.701 84.9942 818.9 84.1907 817.91 84.1907C816.921 84.1907 816.119 84.9942 816.119 85.9854C816.119 86.9765 816.921 87.78 817.91 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 87.78C812.929 87.78 813.731 86.9765 813.731 85.9854C813.731 84.9942 812.929 84.1907 811.94 84.1907C810.951 84.1907 810.149 84.9942 810.149 85.9854C810.149 86.9765 810.951 87.78 811.94 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 87.78C806.959 87.78 807.761 86.9765 807.761 85.9854C807.761 84.9942 806.959 84.1907 805.97 84.1907C804.981 84.1907 804.179 84.9942 804.179 85.9854C804.179 86.9765 804.981 87.78 805.97 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M800 87.78C800.989 87.78 801.791 86.9765 801.791 85.9854C801.791 84.9942 800.989 84.1907 800 84.1907C799.011 84.1907 798.209 84.9942 798.209 85.9854C798.209 86.9765 799.011 87.78 800 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 87.78C795.019 87.78 795.821 86.9765 795.821 85.9854C795.821 84.9942 795.019 84.1907 794.03 84.1907C793.041 84.1907 792.239 84.9942 792.239 85.9854C792.239 86.9765 793.041 87.78 794.03 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 87.78C789.049 87.78 789.851 86.9765 789.851 85.9854C789.851 84.9942 789.049 84.1907 788.06 84.1907C787.071 84.1907 786.269 84.9942 786.269 85.9854C786.269 86.9765 787.071 87.78 788.06 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 87.78C783.079 87.78 783.881 86.9765 783.881 85.9854C783.881 84.9942 783.079 84.1907 782.09 84.1907C781.1 84.1907 780.299 84.9942 780.299 85.9854C780.299 86.9765 781.1 87.78 782.09 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 87.78C777.109 87.78 777.91 86.9765 777.91 85.9854C777.91 84.9942 777.109 84.1907 776.119 84.1907C775.13 84.1907 774.328 84.9942 774.328 85.9854C774.328 86.9765 775.13 87.78 776.119 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 87.78C771.138 87.78 771.94 86.9765 771.94 85.9854C771.94 84.9942 771.138 84.1907 770.149 84.1907C769.16 84.1907 768.358 84.9942 768.358 85.9854C768.358 86.9765 769.16 87.78 770.149 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 87.78C765.168 87.78 765.97 86.9765 765.97 85.9854C765.97 84.9942 765.168 84.1907 764.179 84.1907C763.19 84.1907 762.388 84.9942 762.388 85.9854C762.388 86.9765 763.19 87.78 764.179 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 87.78C759.198 87.78 760 86.9765 760 85.9854C760 84.9942 759.198 84.1907 758.209 84.1907C757.22 84.1907 756.418 84.9942 756.418 85.9854C756.418 86.9765 757.22 87.78 758.209 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 87.78C753.228 87.78 754.03 86.9765 754.03 85.9854C754.03 84.9942 753.228 84.1907 752.239 84.1907C751.25 84.1907 750.448 84.9942 750.448 85.9854C750.448 86.9765 751.25 87.78 752.239 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 87.78C747.258 87.78 748.06 86.9765 748.06 85.9854C748.06 84.9942 747.258 84.1907 746.269 84.1907C745.279 84.1907 744.478 84.9942 744.478 85.9854C744.478 86.9765 745.279 87.78 746.269 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 87.78C741.288 87.78 742.09 86.9765 742.09 85.9854C742.09 84.9942 741.288 84.1907 740.299 84.1907C739.309 84.1907 738.507 84.9942 738.507 85.9854C738.507 86.9765 739.309 87.78 740.299 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 87.78C735.318 87.78 736.119 86.9765 736.119 85.9854C736.119 84.9942 735.318 84.1907 734.328 84.1907C733.339 84.1907 732.537 84.9942 732.537 85.9854C732.537 86.9765 733.339 87.78 734.328 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 87.78C729.347 87.78 730.149 86.9765 730.149 85.9854C730.149 84.9942 729.347 84.1907 728.358 84.1907C727.369 84.1907 726.567 84.9942 726.567 85.9854C726.567 86.9765 727.369 87.78 728.358 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 87.78C723.377 87.78 724.179 86.9765 724.179 85.9854C724.179 84.9942 723.377 84.1907 722.388 84.1907C721.399 84.1907 720.597 84.9942 720.597 85.9854C720.597 86.9765 721.399 87.78 722.388 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 87.78C717.407 87.78 718.209 86.9765 718.209 85.9854C718.209 84.9942 717.407 84.1907 716.418 84.1907C715.429 84.1907 714.627 84.9942 714.627 85.9854C714.627 86.9765 715.429 87.78 716.418 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 87.78C711.437 87.78 712.239 86.9765 712.239 85.9854C712.239 84.9942 711.437 84.1907 710.448 84.1907C709.459 84.1907 708.657 84.9942 708.657 85.9854C708.657 86.9765 709.459 87.78 710.448 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 87.78C705.467 87.78 706.269 86.9765 706.269 85.9854C706.269 84.9942 705.467 84.1907 704.478 84.1907C703.488 84.1907 702.687 84.9942 702.687 85.9854C702.687 86.9765 703.488 87.78 704.478 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 87.78C699.497 87.78 700.299 86.9765 700.299 85.9854C700.299 84.9942 699.497 84.1907 698.507 84.1907C697.518 84.1907 696.716 84.9942 696.716 85.9854C696.716 86.9765 697.518 87.78 698.507 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 87.78C693.526 87.78 694.328 86.9765 694.328 85.9854C694.328 84.9942 693.526 84.1907 692.537 84.1907C691.548 84.1907 690.746 84.9942 690.746 85.9854C690.746 86.9765 691.548 87.78 692.537 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 87.78C687.556 87.78 688.358 86.9765 688.358 85.9854C688.358 84.9942 687.556 84.1907 686.567 84.1907C685.578 84.1907 684.776 84.9942 684.776 85.9854C684.776 86.9765 685.578 87.78 686.567 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 87.78C681.586 87.78 682.388 86.9765 682.388 85.9854C682.388 84.9942 681.586 84.1907 680.597 84.1907C679.608 84.1907 678.806 84.9942 678.806 85.9854C678.806 86.9765 679.608 87.78 680.597 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 87.78C675.616 87.78 676.418 86.9765 676.418 85.9854C676.418 84.9942 675.616 84.1907 674.627 84.1907C673.638 84.1907 672.836 84.9942 672.836 85.9854C672.836 86.9765 673.638 87.78 674.627 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 87.78C669.646 87.78 670.448 86.9765 670.448 85.9854C670.448 84.9942 669.646 84.1907 668.657 84.1907C667.668 84.1907 666.866 84.9942 666.866 85.9854C666.866 86.9765 667.668 87.78 668.657 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 87.78C663.676 87.78 664.478 86.9765 664.478 85.9854C664.478 84.9942 663.676 84.1907 662.687 84.1907C661.697 84.1907 660.896 84.9942 660.896 85.9854C660.896 86.9765 661.697 87.78 662.687 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 87.78C657.706 87.78 658.507 86.9765 658.507 85.9854C658.507 84.9942 657.706 84.1907 656.716 84.1907C655.727 84.1907 654.925 84.9942 654.925 85.9854C654.925 86.9765 655.727 87.78 656.716 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 87.78C651.735 87.78 652.537 86.9765 652.537 85.9854C652.537 84.9942 651.735 84.1907 650.746 84.1907C649.757 84.1907 648.955 84.9942 648.955 85.9854C648.955 86.9765 649.757 87.78 650.746 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 87.78C645.765 87.78 646.567 86.9765 646.567 85.9854C646.567 84.9942 645.765 84.1907 644.776 84.1907C643.787 84.1907 642.985 84.9942 642.985 85.9854C642.985 86.9765 643.787 87.78 644.776 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 87.78C639.795 87.78 640.597 86.9765 640.597 85.9854C640.597 84.9942 639.795 84.1907 638.806 84.1907C637.817 84.1907 637.015 84.9942 637.015 85.9854C637.015 86.9765 637.817 87.78 638.806 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 87.78C633.825 87.78 634.627 86.9765 634.627 85.9854C634.627 84.9942 633.825 84.1907 632.836 84.1907C631.847 84.1907 631.045 84.9942 631.045 85.9854C631.045 86.9765 631.847 87.78 632.836 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 87.78C627.855 87.78 628.657 86.9765 628.657 85.9854C628.657 84.9942 627.855 84.1907 626.866 84.1907C625.876 84.1907 625.075 84.9942 625.075 85.9854C625.075 86.9765 625.876 87.78 626.866 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 87.78C621.885 87.78 622.687 86.9765 622.687 85.9854C622.687 84.9942 621.885 84.1907 620.895 84.1907C619.906 84.1907 619.104 84.9942 619.104 85.9854C619.104 86.9765 619.906 87.78 620.895 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 87.78C615.915 87.78 616.716 86.9765 616.716 85.9854C616.716 84.9942 615.915 84.1907 614.925 84.1907C613.936 84.1907 613.134 84.9942 613.134 85.9854C613.134 86.9765 613.936 87.78 614.925 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 87.78C609.944 87.78 610.746 86.9765 610.746 85.9854C610.746 84.9942 609.944 84.1907 608.955 84.1907C607.966 84.1907 607.164 84.9942 607.164 85.9854C607.164 86.9765 607.966 87.78 608.955 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 87.78C598.004 87.78 598.806 86.9765 598.806 85.9854C598.806 84.9942 598.004 84.1907 597.015 84.1907C596.026 84.1907 595.224 84.9942 595.224 85.9854C595.224 86.9765 596.026 87.78 597.015 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 87.78C592.034 87.78 592.836 86.9765 592.836 85.9854C592.836 84.9942 592.034 84.1907 591.045 84.1907C590.056 84.1907 589.254 84.9942 589.254 85.9854C589.254 86.9765 590.056 87.78 591.045 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 87.78C586.064 87.78 586.866 86.9765 586.866 85.9854C586.866 84.9942 586.064 84.1907 585.075 84.1907C584.085 84.1907 583.284 84.9942 583.284 85.9854C583.284 86.9765 584.085 87.78 585.075 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 87.78C580.094 87.78 580.896 86.9765 580.896 85.9854C580.896 84.9942 580.094 84.1907 579.104 84.1907C578.115 84.1907 577.313 84.9942 577.313 85.9854C577.313 86.9765 578.115 87.78 579.104 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 87.78C568.153 87.78 568.955 86.9765 568.955 85.9854C568.955 84.9942 568.153 84.1907 567.164 84.1907C566.175 84.1907 565.373 84.9942 565.373 85.9854C565.373 86.9765 566.175 87.78 567.164 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 87.78C562.183 87.78 562.985 86.9765 562.985 85.9854C562.985 84.9942 562.183 84.1907 561.194 84.1907C560.205 84.1907 559.403 84.9942 559.403 85.9854C559.403 86.9765 560.205 87.78 561.194 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 81.7979C1087.56 81.7979 1088.36 80.9944 1088.36 80.0032C1088.36 79.012 1087.56 78.2085 1086.57 78.2085C1085.58 78.2085 1084.78 79.012 1084.78 80.0032C1084.78 80.9944 1085.58 81.7979 1086.57 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 81.7979C1081.59 81.7979 1082.39 80.9944 1082.39 80.0032C1082.39 79.012 1081.59 78.2085 1080.6 78.2085C1079.61 78.2085 1078.81 79.012 1078.81 80.0032C1078.81 80.9944 1079.61 81.7979 1080.6 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 81.7979C1075.62 81.7979 1076.42 80.9944 1076.42 80.0032C1076.42 79.012 1075.62 78.2085 1074.63 78.2085C1073.64 78.2085 1072.84 79.012 1072.84 80.0032C1072.84 80.9944 1073.64 81.7979 1074.63 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 81.7979C1069.65 81.7979 1070.45 80.9944 1070.45 80.0032C1070.45 79.012 1069.65 78.2085 1068.66 78.2085C1067.67 78.2085 1066.87 79.012 1066.87 80.0032C1066.87 80.9944 1067.67 81.7979 1068.66 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 81.7979C1063.68 81.7979 1064.48 80.9944 1064.48 80.0032C1064.48 79.012 1063.68 78.2085 1062.69 78.2085C1061.7 78.2085 1060.9 79.012 1060.9 80.0032C1060.9 80.9944 1061.7 81.7979 1062.69 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 81.7979C1057.71 81.7979 1058.51 80.9944 1058.51 80.0032C1058.51 79.012 1057.71 78.2085 1056.72 78.2085C1055.73 78.2085 1054.93 79.012 1054.93 80.0032C1054.93 80.9944 1055.73 81.7979 1056.72 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 81.7979C1051.74 81.7979 1052.54 80.9944 1052.54 80.0032C1052.54 79.012 1051.74 78.2085 1050.75 78.2085C1049.76 78.2085 1048.96 79.012 1048.96 80.0032C1048.96 80.9944 1049.76 81.7979 1050.75 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 81.7979C1045.77 81.7979 1046.57 80.9944 1046.57 80.0032C1046.57 79.012 1045.77 78.2085 1044.78 78.2085C1043.79 78.2085 1042.99 79.012 1042.99 80.0032C1042.99 80.9944 1043.79 81.7979 1044.78 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 81.7979C1039.8 81.7979 1040.6 80.9944 1040.6 80.0032C1040.6 79.012 1039.8 78.2085 1038.81 78.2085C1037.82 78.2085 1037.01 79.012 1037.01 80.0032C1037.01 80.9944 1037.82 81.7979 1038.81 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 81.7979C1033.82 81.7979 1034.63 80.9944 1034.63 80.0032C1034.63 79.012 1033.82 78.2085 1032.84 78.2085C1031.85 78.2085 1031.04 79.012 1031.04 80.0032C1031.04 80.9944 1031.85 81.7979 1032.84 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 81.7979C1027.85 81.7979 1028.66 80.9944 1028.66 80.0032C1028.66 79.012 1027.85 78.2085 1026.87 78.2085C1025.88 78.2085 1025.07 79.012 1025.07 80.0032C1025.07 80.9944 1025.88 81.7979 1026.87 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 81.7979C1021.88 81.7979 1022.69 80.9944 1022.69 80.0032C1022.69 79.012 1021.88 78.2085 1020.9 78.2085C1019.91 78.2085 1019.1 79.012 1019.1 80.0032C1019.1 80.9944 1019.91 81.7979 1020.9 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 81.7979C1015.91 81.7979 1016.72 80.9944 1016.72 80.0032C1016.72 79.012 1015.91 78.2085 1014.93 78.2085C1013.94 78.2085 1013.13 79.012 1013.13 80.0032C1013.13 80.9944 1013.94 81.7979 1014.93 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 81.7979C1009.94 81.7979 1010.75 80.9944 1010.75 80.0032C1010.75 79.012 1009.94 78.2085 1008.96 78.2085C1007.97 78.2085 1007.16 79.012 1007.16 80.0032C1007.16 80.9944 1007.97 81.7979 1008.96 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 81.7979C1003.97 81.7979 1004.78 80.9944 1004.78 80.0032C1004.78 79.012 1003.97 78.2085 1002.99 78.2085C1002 78.2085 1001.19 79.012 1001.19 80.0032C1001.19 80.9944 1002 81.7979 1002.99 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 81.7979C998.004 81.7979 998.806 80.9944 998.806 80.0032C998.806 79.012 998.004 78.2085 997.015 78.2085C996.026 78.2085 995.224 79.012 995.224 80.0032C995.224 80.9944 996.026 81.7979 997.015 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 81.7979C992.034 81.7979 992.836 80.9944 992.836 80.0032C992.836 79.012 992.034 78.2085 991.045 78.2085C990.056 78.2085 989.254 79.012 989.254 80.0032C989.254 80.9944 990.056 81.7979 991.045 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 81.7979C986.064 81.7979 986.866 80.9944 986.866 80.0032C986.866 79.012 986.064 78.2085 985.075 78.2085C984.085 78.2085 983.284 79.012 983.284 80.0032C983.284 80.9944 984.085 81.7979 985.075 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 81.7979C980.094 81.7979 980.896 80.9944 980.896 80.0032C980.896 79.012 980.094 78.2085 979.104 78.2085C978.115 78.2085 977.313 79.012 977.313 80.0032C977.313 80.9944 978.115 81.7979 979.104 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 81.7979C974.123 81.7979 974.925 80.9944 974.925 80.0032C974.925 79.012 974.123 78.2085 973.134 78.2085C972.145 78.2085 971.343 79.012 971.343 80.0032C971.343 80.9944 972.145 81.7979 973.134 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 81.7979C968.153 81.7979 968.955 80.9944 968.955 80.0032C968.955 79.012 968.153 78.2085 967.164 78.2085C966.175 78.2085 965.373 79.012 965.373 80.0032C965.373 80.9944 966.175 81.7979 967.164 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 81.7979C962.183 81.7979 962.985 80.9944 962.985 80.0032C962.985 79.012 962.183 78.2085 961.194 78.2085C960.205 78.2085 959.403 79.012 959.403 80.0032C959.403 80.9944 960.205 81.7979 961.194 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 81.7979C956.213 81.7979 957.015 80.9944 957.015 80.0032C957.015 79.012 956.213 78.2085 955.224 78.2085C954.235 78.2085 953.433 79.012 953.433 80.0032C953.433 80.9944 954.235 81.7979 955.224 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 81.7979C950.243 81.7979 951.045 80.9944 951.045 80.0032C951.045 79.012 950.243 78.2085 949.254 78.2085C948.265 78.2085 947.463 79.012 947.463 80.0032C947.463 80.9944 948.265 81.7979 949.254 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 81.7979C944.273 81.7979 945.075 80.9944 945.075 80.0032C945.075 79.012 944.273 78.2085 943.284 78.2085C942.294 78.2085 941.492 79.012 941.492 80.0032C941.492 80.9944 942.294 81.7979 943.284 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 81.7979C938.303 81.7979 939.105 80.9944 939.105 80.0032C939.105 79.012 938.303 78.2085 937.313 78.2085C936.324 78.2085 935.522 79.012 935.522 80.0032C935.522 80.9944 936.324 81.7979 937.313 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 81.7979C932.332 81.7979 933.134 80.9944 933.134 80.0032C933.134 79.012 932.332 78.2085 931.343 78.2085C930.354 78.2085 929.552 79.012 929.552 80.0032C929.552 80.9944 930.354 81.7979 931.343 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 81.7979C926.362 81.7979 927.164 80.9944 927.164 80.0032C927.164 79.012 926.362 78.2085 925.373 78.2085C924.384 78.2085 923.582 79.012 923.582 80.0032C923.582 80.9944 924.384 81.7979 925.373 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 81.7979C920.392 81.7979 921.194 80.9944 921.194 80.0032C921.194 79.012 920.392 78.2085 919.403 78.2085C918.414 78.2085 917.612 79.012 917.612 80.0032C917.612 80.9944 918.414 81.7979 919.403 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 81.7979C914.422 81.7979 915.224 80.9944 915.224 80.0032C915.224 79.012 914.422 78.2085 913.433 78.2085C912.444 78.2085 911.642 79.012 911.642 80.0032C911.642 80.9944 912.444 81.7979 913.433 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 81.7979C908.452 81.7979 909.254 80.9944 909.254 80.0032C909.254 79.012 908.452 78.2085 907.463 78.2085C906.474 78.2085 905.672 79.012 905.672 80.0032C905.672 80.9944 906.474 81.7979 907.463 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 81.7979C902.482 81.7979 903.284 80.9944 903.284 80.0032C903.284 79.012 902.482 78.2085 901.492 78.2085C900.503 78.2085 899.701 79.012 899.701 80.0032C899.701 80.9944 900.503 81.7979 901.492 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 81.7979C896.512 81.7979 897.313 80.9944 897.313 80.0032C897.313 79.012 896.512 78.2085 895.522 78.2085C894.533 78.2085 893.731 79.012 893.731 80.0032C893.731 80.9944 894.533 81.7979 895.522 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 81.7979C890.541 81.7979 891.343 80.9944 891.343 80.0032C891.343 79.012 890.541 78.2085 889.552 78.2085C888.563 78.2085 887.761 79.012 887.761 80.0032C887.761 80.9944 888.563 81.7979 889.552 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 81.7979C884.571 81.7979 885.373 80.9944 885.373 80.0032C885.373 79.012 884.571 78.2085 883.582 78.2085C882.593 78.2085 881.791 79.012 881.791 80.0032C881.791 80.9944 882.593 81.7979 883.582 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 81.7979C878.601 81.7979 879.403 80.9944 879.403 80.0032C879.403 79.012 878.601 78.2085 877.612 78.2085C876.623 78.2085 875.821 79.012 875.821 80.0032C875.821 80.9944 876.623 81.7979 877.612 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 81.7979C872.631 81.7979 873.433 80.9944 873.433 80.0032C873.433 79.012 872.631 78.2085 871.642 78.2085C870.653 78.2085 869.851 79.012 869.851 80.0032C869.851 80.9944 870.653 81.7979 871.642 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 81.7979C866.661 81.7979 867.463 80.9944 867.463 80.0032C867.463 79.012 866.661 78.2085 865.672 78.2085C864.683 78.2085 863.881 79.012 863.881 80.0032C863.881 80.9944 864.683 81.7979 865.672 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 81.7979C860.691 81.7979 861.493 80.9944 861.493 80.0032C861.493 79.012 860.691 78.2085 859.701 78.2085C858.712 78.2085 857.91 79.012 857.91 80.0032C857.91 80.9944 858.712 81.7979 859.701 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 81.7979C854.721 81.7979 855.522 80.9944 855.522 80.0032C855.522 79.012 854.721 78.2085 853.731 78.2085C852.742 78.2085 851.94 79.012 851.94 80.0032C851.94 80.9944 852.742 81.7979 853.731 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 81.7979C848.75 81.7979 849.552 80.9944 849.552 80.0032C849.552 79.012 848.75 78.2085 847.761 78.2085C846.772 78.2085 845.97 79.012 845.97 80.0032C845.97 80.9944 846.772 81.7979 847.761 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 81.7979C842.78 81.7979 843.582 80.9944 843.582 80.0032C843.582 79.012 842.78 78.2085 841.791 78.2085C840.802 78.2085 840 79.012 840 80.0032C840 80.9944 840.802 81.7979 841.791 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 81.7979C836.81 81.7979 837.612 80.9944 837.612 80.0032C837.612 79.012 836.81 78.2085 835.821 78.2085C834.832 78.2085 834.03 79.012 834.03 80.0032C834.03 80.9944 834.832 81.7979 835.821 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 81.7979C830.84 81.7979 831.642 80.9944 831.642 80.0032C831.642 79.012 830.84 78.2085 829.851 78.2085C828.862 78.2085 828.06 79.012 828.06 80.0032C828.06 80.9944 828.862 81.7979 829.851 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 81.7979C824.87 81.7979 825.672 80.9944 825.672 80.0032C825.672 79.012 824.87 78.2085 823.881 78.2085C822.891 78.2085 822.09 79.012 822.09 80.0032C822.09 80.9944 822.891 81.7979 823.881 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 81.7979C818.9 81.7979 819.701 80.9944 819.701 80.0032C819.701 79.012 818.9 78.2085 817.91 78.2085C816.921 78.2085 816.119 79.012 816.119 80.0032C816.119 80.9944 816.921 81.7979 817.91 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 81.7979C812.929 81.7979 813.731 80.9944 813.731 80.0032C813.731 79.012 812.929 78.2085 811.94 78.2085C810.951 78.2085 810.149 79.012 810.149 80.0032C810.149 80.9944 810.951 81.7979 811.94 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 81.7979C806.959 81.7979 807.761 80.9944 807.761 80.0032C807.761 79.012 806.959 78.2085 805.97 78.2085C804.981 78.2085 804.179 79.012 804.179 80.0032C804.179 80.9944 804.981 81.7979 805.97 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M800 81.7979C800.989 81.7979 801.791 80.9944 801.791 80.0032C801.791 79.012 800.989 78.2085 800 78.2085C799.011 78.2085 798.209 79.012 798.209 80.0032C798.209 80.9944 799.011 81.7979 800 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 81.7979C795.019 81.7979 795.821 80.9944 795.821 80.0032C795.821 79.012 795.019 78.2085 794.03 78.2085C793.041 78.2085 792.239 79.012 792.239 80.0032C792.239 80.9944 793.041 81.7979 794.03 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 81.7979C789.049 81.7979 789.851 80.9944 789.851 80.0032C789.851 79.012 789.049 78.2085 788.06 78.2085C787.071 78.2085 786.269 79.012 786.269 80.0032C786.269 80.9944 787.071 81.7979 788.06 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 81.7979C783.079 81.7979 783.881 80.9944 783.881 80.0032C783.881 79.012 783.079 78.2085 782.09 78.2085C781.1 78.2085 780.299 79.012 780.299 80.0032C780.299 80.9944 781.1 81.7979 782.09 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 81.7979C777.109 81.7979 777.91 80.9944 777.91 80.0032C777.91 79.012 777.109 78.2085 776.119 78.2085C775.13 78.2085 774.328 79.012 774.328 80.0032C774.328 80.9944 775.13 81.7979 776.119 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 81.7979C771.138 81.7979 771.94 80.9944 771.94 80.0032C771.94 79.012 771.138 78.2085 770.149 78.2085C769.16 78.2085 768.358 79.012 768.358 80.0032C768.358 80.9944 769.16 81.7979 770.149 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 81.7979C765.168 81.7979 765.97 80.9944 765.97 80.0032C765.97 79.012 765.168 78.2085 764.179 78.2085C763.19 78.2085 762.388 79.012 762.388 80.0032C762.388 80.9944 763.19 81.7979 764.179 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 81.7979C759.198 81.7979 760 80.9944 760 80.0032C760 79.012 759.198 78.2085 758.209 78.2085C757.22 78.2085 756.418 79.012 756.418 80.0032C756.418 80.9944 757.22 81.7979 758.209 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 81.7979C753.228 81.7979 754.03 80.9944 754.03 80.0032C754.03 79.012 753.228 78.2085 752.239 78.2085C751.25 78.2085 750.448 79.012 750.448 80.0032C750.448 80.9944 751.25 81.7979 752.239 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 81.7979C747.258 81.7979 748.06 80.9944 748.06 80.0032C748.06 79.012 747.258 78.2085 746.269 78.2085C745.279 78.2085 744.478 79.012 744.478 80.0032C744.478 80.9944 745.279 81.7979 746.269 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 81.7979C741.288 81.7979 742.09 80.9944 742.09 80.0032C742.09 79.012 741.288 78.2085 740.299 78.2085C739.309 78.2085 738.507 79.012 738.507 80.0032C738.507 80.9944 739.309 81.7979 740.299 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 81.7979C735.318 81.7979 736.119 80.9944 736.119 80.0032C736.119 79.012 735.318 78.2085 734.328 78.2085C733.339 78.2085 732.537 79.012 732.537 80.0032C732.537 80.9944 733.339 81.7979 734.328 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 81.7979C729.347 81.7979 730.149 80.9944 730.149 80.0032C730.149 79.012 729.347 78.2085 728.358 78.2085C727.369 78.2085 726.567 79.012 726.567 80.0032C726.567 80.9944 727.369 81.7979 728.358 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 81.7979C723.377 81.7979 724.179 80.9944 724.179 80.0032C724.179 79.012 723.377 78.2085 722.388 78.2085C721.399 78.2085 720.597 79.012 720.597 80.0032C720.597 80.9944 721.399 81.7979 722.388 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 81.7979C717.407 81.7979 718.209 80.9944 718.209 80.0032C718.209 79.012 717.407 78.2085 716.418 78.2085C715.429 78.2085 714.627 79.012 714.627 80.0032C714.627 80.9944 715.429 81.7979 716.418 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 81.7979C711.437 81.7979 712.239 80.9944 712.239 80.0032C712.239 79.012 711.437 78.2085 710.448 78.2085C709.459 78.2085 708.657 79.012 708.657 80.0032C708.657 80.9944 709.459 81.7979 710.448 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 81.7979C705.467 81.7979 706.269 80.9944 706.269 80.0032C706.269 79.012 705.467 78.2085 704.478 78.2085C703.488 78.2085 702.687 79.012 702.687 80.0032C702.687 80.9944 703.488 81.7979 704.478 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 81.7979C699.497 81.7979 700.299 80.9944 700.299 80.0032C700.299 79.012 699.497 78.2085 698.507 78.2085C697.518 78.2085 696.716 79.012 696.716 80.0032C696.716 80.9944 697.518 81.7979 698.507 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 81.7979C693.526 81.7979 694.328 80.9944 694.328 80.0032C694.328 79.012 693.526 78.2085 692.537 78.2085C691.548 78.2085 690.746 79.012 690.746 80.0032C690.746 80.9944 691.548 81.7979 692.537 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 81.7979C687.556 81.7979 688.358 80.9944 688.358 80.0032C688.358 79.012 687.556 78.2085 686.567 78.2085C685.578 78.2085 684.776 79.012 684.776 80.0032C684.776 80.9944 685.578 81.7979 686.567 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 81.7979C681.586 81.7979 682.388 80.9944 682.388 80.0032C682.388 79.012 681.586 78.2085 680.597 78.2085C679.608 78.2085 678.806 79.012 678.806 80.0032C678.806 80.9944 679.608 81.7979 680.597 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 81.7979C675.616 81.7979 676.418 80.9944 676.418 80.0032C676.418 79.012 675.616 78.2085 674.627 78.2085C673.638 78.2085 672.836 79.012 672.836 80.0032C672.836 80.9944 673.638 81.7979 674.627 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 81.7979C669.646 81.7979 670.448 80.9944 670.448 80.0032C670.448 79.012 669.646 78.2085 668.657 78.2085C667.668 78.2085 666.866 79.012 666.866 80.0032C666.866 80.9944 667.668 81.7979 668.657 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 81.7979C663.676 81.7979 664.478 80.9944 664.478 80.0032C664.478 79.012 663.676 78.2085 662.687 78.2085C661.697 78.2085 660.896 79.012 660.896 80.0032C660.896 80.9944 661.697 81.7979 662.687 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 81.7979C657.706 81.7979 658.507 80.9944 658.507 80.0032C658.507 79.012 657.706 78.2085 656.716 78.2085C655.727 78.2085 654.925 79.012 654.925 80.0032C654.925 80.9944 655.727 81.7979 656.716 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 81.7979C651.735 81.7979 652.537 80.9944 652.537 80.0032C652.537 79.012 651.735 78.2085 650.746 78.2085C649.757 78.2085 648.955 79.012 648.955 80.0032C648.955 80.9944 649.757 81.7979 650.746 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 81.7979C645.765 81.7979 646.567 80.9944 646.567 80.0032C646.567 79.012 645.765 78.2085 644.776 78.2085C643.787 78.2085 642.985 79.012 642.985 80.0032C642.985 80.9944 643.787 81.7979 644.776 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 81.7979C639.795 81.7979 640.597 80.9944 640.597 80.0032C640.597 79.012 639.795 78.2085 638.806 78.2085C637.817 78.2085 637.015 79.012 637.015 80.0032C637.015 80.9944 637.817 81.7979 638.806 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 81.7979C633.825 81.7979 634.627 80.9944 634.627 80.0032C634.627 79.012 633.825 78.2085 632.836 78.2085C631.847 78.2085 631.045 79.012 631.045 80.0032C631.045 80.9944 631.847 81.7979 632.836 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 81.7979C627.855 81.7979 628.657 80.9944 628.657 80.0032C628.657 79.012 627.855 78.2085 626.866 78.2085C625.876 78.2085 625.075 79.012 625.075 80.0032C625.075 80.9944 625.876 81.7979 626.866 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 81.7979C621.885 81.7979 622.687 80.9944 622.687 80.0032C622.687 79.012 621.885 78.2085 620.895 78.2085C619.906 78.2085 619.104 79.012 619.104 80.0032C619.104 80.9944 619.906 81.7979 620.895 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 81.7979C615.915 81.7979 616.716 80.9944 616.716 80.0032C616.716 79.012 615.915 78.2085 614.925 78.2085C613.936 78.2085 613.134 79.012 613.134 80.0032C613.134 80.9944 613.936 81.7979 614.925 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 81.7979C609.944 81.7979 610.746 80.9944 610.746 80.0032C610.746 79.012 609.944 78.2085 608.955 78.2085C607.966 78.2085 607.164 79.012 607.164 80.0032C607.164 80.9944 607.966 81.7979 608.955 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 81.7979C592.034 81.7979 592.836 80.9944 592.836 80.0032C592.836 79.012 592.034 78.2085 591.045 78.2085C590.056 78.2085 589.254 79.012 589.254 80.0032C589.254 80.9944 590.056 81.7979 591.045 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 81.7979C586.064 81.7979 586.866 80.9944 586.866 80.0032C586.866 79.012 586.064 78.2085 585.075 78.2085C584.085 78.2085 583.284 79.012 583.284 80.0032C583.284 80.9944 584.085 81.7979 585.075 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 81.7979C580.094 81.7979 580.896 80.9944 580.896 80.0032C580.896 79.012 580.094 78.2085 579.104 78.2085C578.115 78.2085 577.313 79.012 577.313 80.0032C577.313 80.9944 578.115 81.7979 579.104 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 81.7979C574.123 81.7979 574.925 80.9944 574.925 80.0032C574.925 79.012 574.123 78.2085 573.134 78.2085C572.145 78.2085 571.343 79.012 571.343 80.0032C571.343 80.9944 572.145 81.7979 573.134 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 81.7979C568.153 81.7979 568.955 80.9944 568.955 80.0032C568.955 79.012 568.153 78.2085 567.164 78.2085C566.175 78.2085 565.373 79.012 565.373 80.0032C565.373 80.9944 566.175 81.7979 567.164 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 81.7979C562.183 81.7979 562.985 80.9944 562.985 80.0032C562.985 79.012 562.183 78.2085 561.194 78.2085C560.205 78.2085 559.403 79.012 559.403 80.0032C559.403 80.9944 560.205 81.7979 561.194 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 75.8154C1081.59 75.8154 1082.39 75.0119 1082.39 74.0208C1082.39 73.0296 1081.59 72.2261 1080.6 72.2261C1079.61 72.2261 1078.81 73.0296 1078.81 74.0208C1078.81 75.0119 1079.61 75.8154 1080.6 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 75.8154C1075.62 75.8154 1076.42 75.0119 1076.42 74.0208C1076.42 73.0296 1075.62 72.2261 1074.63 72.2261C1073.64 72.2261 1072.84 73.0296 1072.84 74.0208C1072.84 75.0119 1073.64 75.8154 1074.63 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 75.8154C1069.65 75.8154 1070.45 75.0119 1070.45 74.0208C1070.45 73.0296 1069.65 72.2261 1068.66 72.2261C1067.67 72.2261 1066.87 73.0296 1066.87 74.0208C1066.87 75.0119 1067.67 75.8154 1068.66 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 75.8154C1063.68 75.8154 1064.48 75.0119 1064.48 74.0208C1064.48 73.0296 1063.68 72.2261 1062.69 72.2261C1061.7 72.2261 1060.9 73.0296 1060.9 74.0208C1060.9 75.0119 1061.7 75.8154 1062.69 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 75.8154C1057.71 75.8154 1058.51 75.0119 1058.51 74.0208C1058.51 73.0296 1057.71 72.2261 1056.72 72.2261C1055.73 72.2261 1054.93 73.0296 1054.93 74.0208C1054.93 75.0119 1055.73 75.8154 1056.72 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 75.8154C1051.74 75.8154 1052.54 75.0119 1052.54 74.0208C1052.54 73.0296 1051.74 72.2261 1050.75 72.2261C1049.76 72.2261 1048.96 73.0296 1048.96 74.0208C1048.96 75.0119 1049.76 75.8154 1050.75 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 75.8154C1045.77 75.8154 1046.57 75.0119 1046.57 74.0208C1046.57 73.0296 1045.77 72.2261 1044.78 72.2261C1043.79 72.2261 1042.99 73.0296 1042.99 74.0208C1042.99 75.0119 1043.79 75.8154 1044.78 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 75.8154C1039.8 75.8154 1040.6 75.0119 1040.6 74.0208C1040.6 73.0296 1039.8 72.2261 1038.81 72.2261C1037.82 72.2261 1037.01 73.0296 1037.01 74.0208C1037.01 75.0119 1037.82 75.8154 1038.81 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 75.8154C1033.82 75.8154 1034.63 75.0119 1034.63 74.0208C1034.63 73.0296 1033.82 72.2261 1032.84 72.2261C1031.85 72.2261 1031.04 73.0296 1031.04 74.0208C1031.04 75.0119 1031.85 75.8154 1032.84 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 75.8154C1027.85 75.8154 1028.66 75.0119 1028.66 74.0208C1028.66 73.0296 1027.85 72.2261 1026.87 72.2261C1025.88 72.2261 1025.07 73.0296 1025.07 74.0208C1025.07 75.0119 1025.88 75.8154 1026.87 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 75.8154C1021.88 75.8154 1022.69 75.0119 1022.69 74.0208C1022.69 73.0296 1021.88 72.2261 1020.9 72.2261C1019.91 72.2261 1019.1 73.0296 1019.1 74.0208C1019.1 75.0119 1019.91 75.8154 1020.9 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 75.8154C1015.91 75.8154 1016.72 75.0119 1016.72 74.0208C1016.72 73.0296 1015.91 72.2261 1014.93 72.2261C1013.94 72.2261 1013.13 73.0296 1013.13 74.0208C1013.13 75.0119 1013.94 75.8154 1014.93 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 75.8154C1009.94 75.8154 1010.75 75.0119 1010.75 74.0208C1010.75 73.0296 1009.94 72.2261 1008.96 72.2261C1007.97 72.2261 1007.16 73.0296 1007.16 74.0208C1007.16 75.0119 1007.97 75.8154 1008.96 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 75.8154C1003.97 75.8154 1004.78 75.0119 1004.78 74.0208C1004.78 73.0296 1003.97 72.2261 1002.99 72.2261C1002 72.2261 1001.19 73.0296 1001.19 74.0208C1001.19 75.0119 1002 75.8154 1002.99 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 75.8154C998.004 75.8154 998.806 75.0119 998.806 74.0208C998.806 73.0296 998.004 72.2261 997.015 72.2261C996.026 72.2261 995.224 73.0296 995.224 74.0208C995.224 75.0119 996.026 75.8154 997.015 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 75.8154C992.034 75.8154 992.836 75.0119 992.836 74.0208C992.836 73.0296 992.034 72.2261 991.045 72.2261C990.056 72.2261 989.254 73.0296 989.254 74.0208C989.254 75.0119 990.056 75.8154 991.045 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 75.8154C986.064 75.8154 986.866 75.0119 986.866 74.0208C986.866 73.0296 986.064 72.2261 985.075 72.2261C984.085 72.2261 983.284 73.0296 983.284 74.0208C983.284 75.0119 984.085 75.8154 985.075 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 75.8154C980.094 75.8154 980.896 75.0119 980.896 74.0208C980.896 73.0296 980.094 72.2261 979.104 72.2261C978.115 72.2261 977.313 73.0296 977.313 74.0208C977.313 75.0119 978.115 75.8154 979.104 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 75.8154C974.123 75.8154 974.925 75.0119 974.925 74.0208C974.925 73.0296 974.123 72.2261 973.134 72.2261C972.145 72.2261 971.343 73.0296 971.343 74.0208C971.343 75.0119 972.145 75.8154 973.134 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 75.8154C968.153 75.8154 968.955 75.0119 968.955 74.0208C968.955 73.0296 968.153 72.2261 967.164 72.2261C966.175 72.2261 965.373 73.0296 965.373 74.0208C965.373 75.0119 966.175 75.8154 967.164 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 75.8154C962.183 75.8154 962.985 75.0119 962.985 74.0208C962.985 73.0296 962.183 72.2261 961.194 72.2261C960.205 72.2261 959.403 73.0296 959.403 74.0208C959.403 75.0119 960.205 75.8154 961.194 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 75.8154C956.213 75.8154 957.015 75.0119 957.015 74.0208C957.015 73.0296 956.213 72.2261 955.224 72.2261C954.235 72.2261 953.433 73.0296 953.433 74.0208C953.433 75.0119 954.235 75.8154 955.224 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 75.8154C950.243 75.8154 951.045 75.0119 951.045 74.0208C951.045 73.0296 950.243 72.2261 949.254 72.2261C948.265 72.2261 947.463 73.0296 947.463 74.0208C947.463 75.0119 948.265 75.8154 949.254 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 75.8154C944.273 75.8154 945.075 75.0119 945.075 74.0208C945.075 73.0296 944.273 72.2261 943.284 72.2261C942.294 72.2261 941.492 73.0296 941.492 74.0208C941.492 75.0119 942.294 75.8154 943.284 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 75.8154C938.303 75.8154 939.105 75.0119 939.105 74.0208C939.105 73.0296 938.303 72.2261 937.313 72.2261C936.324 72.2261 935.522 73.0296 935.522 74.0208C935.522 75.0119 936.324 75.8154 937.313 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 75.8154C932.332 75.8154 933.134 75.0119 933.134 74.0208C933.134 73.0296 932.332 72.2261 931.343 72.2261C930.354 72.2261 929.552 73.0296 929.552 74.0208C929.552 75.0119 930.354 75.8154 931.343 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 75.8154C926.362 75.8154 927.164 75.0119 927.164 74.0208C927.164 73.0296 926.362 72.2261 925.373 72.2261C924.384 72.2261 923.582 73.0296 923.582 74.0208C923.582 75.0119 924.384 75.8154 925.373 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 75.8154C920.392 75.8154 921.194 75.0119 921.194 74.0208C921.194 73.0296 920.392 72.2261 919.403 72.2261C918.414 72.2261 917.612 73.0296 917.612 74.0208C917.612 75.0119 918.414 75.8154 919.403 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 75.8154C914.422 75.8154 915.224 75.0119 915.224 74.0208C915.224 73.0296 914.422 72.2261 913.433 72.2261C912.444 72.2261 911.642 73.0296 911.642 74.0208C911.642 75.0119 912.444 75.8154 913.433 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 75.8154C908.452 75.8154 909.254 75.0119 909.254 74.0208C909.254 73.0296 908.452 72.2261 907.463 72.2261C906.474 72.2261 905.672 73.0296 905.672 74.0208C905.672 75.0119 906.474 75.8154 907.463 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 75.8154C902.482 75.8154 903.284 75.0119 903.284 74.0208C903.284 73.0296 902.482 72.2261 901.492 72.2261C900.503 72.2261 899.701 73.0296 899.701 74.0208C899.701 75.0119 900.503 75.8154 901.492 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 75.8154C896.512 75.8154 897.313 75.0119 897.313 74.0208C897.313 73.0296 896.512 72.2261 895.522 72.2261C894.533 72.2261 893.731 73.0296 893.731 74.0208C893.731 75.0119 894.533 75.8154 895.522 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 75.8154C890.541 75.8154 891.343 75.0119 891.343 74.0208C891.343 73.0296 890.541 72.2261 889.552 72.2261C888.563 72.2261 887.761 73.0296 887.761 74.0208C887.761 75.0119 888.563 75.8154 889.552 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 75.8154C884.571 75.8154 885.373 75.0119 885.373 74.0208C885.373 73.0296 884.571 72.2261 883.582 72.2261C882.593 72.2261 881.791 73.0296 881.791 74.0208C881.791 75.0119 882.593 75.8154 883.582 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 75.8154C878.601 75.8154 879.403 75.0119 879.403 74.0208C879.403 73.0296 878.601 72.2261 877.612 72.2261C876.623 72.2261 875.821 73.0296 875.821 74.0208C875.821 75.0119 876.623 75.8154 877.612 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 75.8154C872.631 75.8154 873.433 75.0119 873.433 74.0208C873.433 73.0296 872.631 72.2261 871.642 72.2261C870.653 72.2261 869.851 73.0296 869.851 74.0208C869.851 75.0119 870.653 75.8154 871.642 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 75.8154C866.661 75.8154 867.463 75.0119 867.463 74.0208C867.463 73.0296 866.661 72.2261 865.672 72.2261C864.683 72.2261 863.881 73.0296 863.881 74.0208C863.881 75.0119 864.683 75.8154 865.672 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 75.8154C860.691 75.8154 861.493 75.0119 861.493 74.0208C861.493 73.0296 860.691 72.2261 859.701 72.2261C858.712 72.2261 857.91 73.0296 857.91 74.0208C857.91 75.0119 858.712 75.8154 859.701 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 75.8154C854.721 75.8154 855.522 75.0119 855.522 74.0208C855.522 73.0296 854.721 72.2261 853.731 72.2261C852.742 72.2261 851.94 73.0296 851.94 74.0208C851.94 75.0119 852.742 75.8154 853.731 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 75.8154C848.75 75.8154 849.552 75.0119 849.552 74.0208C849.552 73.0296 848.75 72.2261 847.761 72.2261C846.772 72.2261 845.97 73.0296 845.97 74.0208C845.97 75.0119 846.772 75.8154 847.761 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 75.8154C842.78 75.8154 843.582 75.0119 843.582 74.0208C843.582 73.0296 842.78 72.2261 841.791 72.2261C840.802 72.2261 840 73.0296 840 74.0208C840 75.0119 840.802 75.8154 841.791 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 75.8154C836.81 75.8154 837.612 75.0119 837.612 74.0208C837.612 73.0296 836.81 72.2261 835.821 72.2261C834.832 72.2261 834.03 73.0296 834.03 74.0208C834.03 75.0119 834.832 75.8154 835.821 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 75.8154C830.84 75.8154 831.642 75.0119 831.642 74.0208C831.642 73.0296 830.84 72.2261 829.851 72.2261C828.862 72.2261 828.06 73.0296 828.06 74.0208C828.06 75.0119 828.862 75.8154 829.851 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 75.8154C824.87 75.8154 825.672 75.0119 825.672 74.0208C825.672 73.0296 824.87 72.2261 823.881 72.2261C822.891 72.2261 822.09 73.0296 822.09 74.0208C822.09 75.0119 822.891 75.8154 823.881 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 75.8154C818.9 75.8154 819.701 75.0119 819.701 74.0208C819.701 73.0296 818.9 72.2261 817.91 72.2261C816.921 72.2261 816.119 73.0296 816.119 74.0208C816.119 75.0119 816.921 75.8154 817.91 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 75.8154C812.929 75.8154 813.731 75.0119 813.731 74.0208C813.731 73.0296 812.929 72.2261 811.94 72.2261C810.951 72.2261 810.149 73.0296 810.149 74.0208C810.149 75.0119 810.951 75.8154 811.94 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 75.8154C806.959 75.8154 807.761 75.0119 807.761 74.0208C807.761 73.0296 806.959 72.2261 805.97 72.2261C804.981 72.2261 804.179 73.0296 804.179 74.0208C804.179 75.0119 804.981 75.8154 805.97 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M800 75.8154C800.989 75.8154 801.791 75.0119 801.791 74.0208C801.791 73.0296 800.989 72.2261 800 72.2261C799.011 72.2261 798.209 73.0296 798.209 74.0208C798.209 75.0119 799.011 75.8154 800 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 75.8154C795.019 75.8154 795.821 75.0119 795.821 74.0208C795.821 73.0296 795.019 72.2261 794.03 72.2261C793.041 72.2261 792.239 73.0296 792.239 74.0208C792.239 75.0119 793.041 75.8154 794.03 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 75.8154C789.049 75.8154 789.851 75.0119 789.851 74.0208C789.851 73.0296 789.049 72.2261 788.06 72.2261C787.071 72.2261 786.269 73.0296 786.269 74.0208C786.269 75.0119 787.071 75.8154 788.06 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 75.8154C783.079 75.8154 783.881 75.0119 783.881 74.0208C783.881 73.0296 783.079 72.2261 782.09 72.2261C781.1 72.2261 780.299 73.0296 780.299 74.0208C780.299 75.0119 781.1 75.8154 782.09 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 75.8154C777.109 75.8154 777.91 75.0119 777.91 74.0208C777.91 73.0296 777.109 72.2261 776.119 72.2261C775.13 72.2261 774.328 73.0296 774.328 74.0208C774.328 75.0119 775.13 75.8154 776.119 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 75.8154C771.138 75.8154 771.94 75.0119 771.94 74.0208C771.94 73.0296 771.138 72.2261 770.149 72.2261C769.16 72.2261 768.358 73.0296 768.358 74.0208C768.358 75.0119 769.16 75.8154 770.149 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 75.8154C765.168 75.8154 765.97 75.0119 765.97 74.0208C765.97 73.0296 765.168 72.2261 764.179 72.2261C763.19 72.2261 762.388 73.0296 762.388 74.0208C762.388 75.0119 763.19 75.8154 764.179 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 75.8154C759.198 75.8154 760 75.0119 760 74.0208C760 73.0296 759.198 72.2261 758.209 72.2261C757.22 72.2261 756.418 73.0296 756.418 74.0208C756.418 75.0119 757.22 75.8154 758.209 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 75.8154C753.228 75.8154 754.03 75.0119 754.03 74.0208C754.03 73.0296 753.228 72.2261 752.239 72.2261C751.25 72.2261 750.448 73.0296 750.448 74.0208C750.448 75.0119 751.25 75.8154 752.239 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 75.8154C747.258 75.8154 748.06 75.0119 748.06 74.0208C748.06 73.0296 747.258 72.2261 746.269 72.2261C745.279 72.2261 744.478 73.0296 744.478 74.0208C744.478 75.0119 745.279 75.8154 746.269 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 75.8154C741.288 75.8154 742.09 75.0119 742.09 74.0208C742.09 73.0296 741.288 72.2261 740.299 72.2261C739.309 72.2261 738.507 73.0296 738.507 74.0208C738.507 75.0119 739.309 75.8154 740.299 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 75.8154C735.318 75.8154 736.119 75.0119 736.119 74.0208C736.119 73.0296 735.318 72.2261 734.328 72.2261C733.339 72.2261 732.537 73.0296 732.537 74.0208C732.537 75.0119 733.339 75.8154 734.328 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 75.8154C729.347 75.8154 730.149 75.0119 730.149 74.0208C730.149 73.0296 729.347 72.2261 728.358 72.2261C727.369 72.2261 726.567 73.0296 726.567 74.0208C726.567 75.0119 727.369 75.8154 728.358 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 75.8154C723.377 75.8154 724.179 75.0119 724.179 74.0208C724.179 73.0296 723.377 72.2261 722.388 72.2261C721.399 72.2261 720.597 73.0296 720.597 74.0208C720.597 75.0119 721.399 75.8154 722.388 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 75.8154C717.407 75.8154 718.209 75.0119 718.209 74.0208C718.209 73.0296 717.407 72.2261 716.418 72.2261C715.429 72.2261 714.627 73.0296 714.627 74.0208C714.627 75.0119 715.429 75.8154 716.418 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 75.8154C711.437 75.8154 712.239 75.0119 712.239 74.0208C712.239 73.0296 711.437 72.2261 710.448 72.2261C709.459 72.2261 708.657 73.0296 708.657 74.0208C708.657 75.0119 709.459 75.8154 710.448 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 75.8154C705.467 75.8154 706.269 75.0119 706.269 74.0208C706.269 73.0296 705.467 72.2261 704.478 72.2261C703.488 72.2261 702.687 73.0296 702.687 74.0208C702.687 75.0119 703.488 75.8154 704.478 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 75.8154C699.497 75.8154 700.299 75.0119 700.299 74.0208C700.299 73.0296 699.497 72.2261 698.507 72.2261C697.518 72.2261 696.716 73.0296 696.716 74.0208C696.716 75.0119 697.518 75.8154 698.507 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 75.8154C693.526 75.8154 694.328 75.0119 694.328 74.0208C694.328 73.0296 693.526 72.2261 692.537 72.2261C691.548 72.2261 690.746 73.0296 690.746 74.0208C690.746 75.0119 691.548 75.8154 692.537 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 75.8154C687.556 75.8154 688.358 75.0119 688.358 74.0208C688.358 73.0296 687.556 72.2261 686.567 72.2261C685.578 72.2261 684.776 73.0296 684.776 74.0208C684.776 75.0119 685.578 75.8154 686.567 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 75.8154C681.586 75.8154 682.388 75.0119 682.388 74.0208C682.388 73.0296 681.586 72.2261 680.597 72.2261C679.608 72.2261 678.806 73.0296 678.806 74.0208C678.806 75.0119 679.608 75.8154 680.597 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 75.8154C675.616 75.8154 676.418 75.0119 676.418 74.0208C676.418 73.0296 675.616 72.2261 674.627 72.2261C673.638 72.2261 672.836 73.0296 672.836 74.0208C672.836 75.0119 673.638 75.8154 674.627 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 75.8154C669.646 75.8154 670.448 75.0119 670.448 74.0208C670.448 73.0296 669.646 72.2261 668.657 72.2261C667.668 72.2261 666.866 73.0296 666.866 74.0208C666.866 75.0119 667.668 75.8154 668.657 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 75.8154C663.676 75.8154 664.478 75.0119 664.478 74.0208C664.478 73.0296 663.676 72.2261 662.687 72.2261C661.697 72.2261 660.896 73.0296 660.896 74.0208C660.896 75.0119 661.697 75.8154 662.687 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 75.8154C657.706 75.8154 658.507 75.0119 658.507 74.0208C658.507 73.0296 657.706 72.2261 656.716 72.2261C655.727 72.2261 654.925 73.0296 654.925 74.0208C654.925 75.0119 655.727 75.8154 656.716 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 75.8154C651.735 75.8154 652.537 75.0119 652.537 74.0208C652.537 73.0296 651.735 72.2261 650.746 72.2261C649.757 72.2261 648.955 73.0296 648.955 74.0208C648.955 75.0119 649.757 75.8154 650.746 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 75.8154C645.765 75.8154 646.567 75.0119 646.567 74.0208C646.567 73.0296 645.765 72.2261 644.776 72.2261C643.787 72.2261 642.985 73.0296 642.985 74.0208C642.985 75.0119 643.787 75.8154 644.776 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 75.8154C639.795 75.8154 640.597 75.0119 640.597 74.0208C640.597 73.0296 639.795 72.2261 638.806 72.2261C637.817 72.2261 637.015 73.0296 637.015 74.0208C637.015 75.0119 637.817 75.8154 638.806 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 75.8154C633.825 75.8154 634.627 75.0119 634.627 74.0208C634.627 73.0296 633.825 72.2261 632.836 72.2261C631.847 72.2261 631.045 73.0296 631.045 74.0208C631.045 75.0119 631.847 75.8154 632.836 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 75.8154C627.855 75.8154 628.657 75.0119 628.657 74.0208C628.657 73.0296 627.855 72.2261 626.866 72.2261C625.876 72.2261 625.075 73.0296 625.075 74.0208C625.075 75.0119 625.876 75.8154 626.866 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 75.8154C621.885 75.8154 622.687 75.0119 622.687 74.0208C622.687 73.0296 621.885 72.2261 620.895 72.2261C619.906 72.2261 619.104 73.0296 619.104 74.0208C619.104 75.0119 619.906 75.8154 620.895 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 75.8154C615.915 75.8154 616.716 75.0119 616.716 74.0208C616.716 73.0296 615.915 72.2261 614.925 72.2261C613.936 72.2261 613.134 73.0296 613.134 74.0208C613.134 75.0119 613.936 75.8154 614.925 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 75.8154C609.944 75.8154 610.746 75.0119 610.746 74.0208C610.746 73.0296 609.944 72.2261 608.955 72.2261C607.966 72.2261 607.164 73.0296 607.164 74.0208C607.164 75.0119 607.966 75.8154 608.955 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 75.8154C598.004 75.8154 598.806 75.0119 598.806 74.0208C598.806 73.0296 598.004 72.2261 597.015 72.2261C596.026 72.2261 595.224 73.0296 595.224 74.0208C595.224 75.0119 596.026 75.8154 597.015 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 75.8154C592.034 75.8154 592.836 75.0119 592.836 74.0208C592.836 73.0296 592.034 72.2261 591.045 72.2261C590.056 72.2261 589.254 73.0296 589.254 74.0208C589.254 75.0119 590.056 75.8154 591.045 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 75.8154C586.064 75.8154 586.866 75.0119 586.866 74.0208C586.866 73.0296 586.064 72.2261 585.075 72.2261C584.085 72.2261 583.284 73.0296 583.284 74.0208C583.284 75.0119 584.085 75.8154 585.075 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 75.8154C580.094 75.8154 580.896 75.0119 580.896 74.0208C580.896 73.0296 580.094 72.2261 579.104 72.2261C578.115 72.2261 577.313 73.0296 577.313 74.0208C577.313 75.0119 578.115 75.8154 579.104 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 75.8154C574.123 75.8154 574.925 75.0119 574.925 74.0208C574.925 73.0296 574.123 72.2261 573.134 72.2261C572.145 72.2261 571.343 73.0296 571.343 74.0208C571.343 75.0119 572.145 75.8154 573.134 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 75.8154C490.541 75.8154 491.343 75.0119 491.343 74.0208C491.343 73.0296 490.541 72.2261 489.552 72.2261C488.563 72.2261 487.761 73.0296 487.761 74.0208C487.761 75.0119 488.563 75.8154 489.552 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 75.8154C484.571 75.8154 485.373 75.0119 485.373 74.0208C485.373 73.0296 484.571 72.2261 483.582 72.2261C482.593 72.2261 481.791 73.0296 481.791 74.0208C481.791 75.0119 482.593 75.8154 483.582 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 75.8154C478.601 75.8154 479.403 75.0119 479.403 74.0208C479.403 73.0296 478.601 72.2261 477.612 72.2261C476.623 72.2261 475.821 73.0296 475.821 74.0208C475.821 75.0119 476.623 75.8154 477.612 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M1104.48 69.8333C1105.47 69.8333 1106.27 69.0298 1106.27 68.0386C1106.27 67.0474 1105.47 66.2439 1104.48 66.2439C1103.49 66.2439 1102.69 67.0474 1102.69 68.0386C1102.69 69.0298 1103.49 69.8333 1104.48 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1098.51 69.8333C1099.5 69.8333 1100.3 69.0298 1100.3 68.0386C1100.3 67.0474 1099.5 66.2439 1098.51 66.2439C1097.52 66.2439 1096.72 67.0474 1096.72 68.0386C1096.72 69.0298 1097.52 69.8333 1098.51 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 69.8333C1093.53 69.8333 1094.33 69.0298 1094.33 68.0386C1094.33 67.0474 1093.53 66.2439 1092.54 66.2439C1091.55 66.2439 1090.75 67.0474 1090.75 68.0386C1090.75 69.0298 1091.55 69.8333 1092.54 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 69.8333C1081.59 69.8333 1082.39 69.0298 1082.39 68.0386C1082.39 67.0474 1081.59 66.2439 1080.6 66.2439C1079.61 66.2439 1078.81 67.0474 1078.81 68.0386C1078.81 69.0298 1079.61 69.8333 1080.6 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 69.8333C1075.62 69.8333 1076.42 69.0298 1076.42 68.0386C1076.42 67.0474 1075.62 66.2439 1074.63 66.2439C1073.64 66.2439 1072.84 67.0474 1072.84 68.0386C1072.84 69.0298 1073.64 69.8333 1074.63 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 69.8333C1069.65 69.8333 1070.45 69.0298 1070.45 68.0386C1070.45 67.0474 1069.65 66.2439 1068.66 66.2439C1067.67 66.2439 1066.87 67.0474 1066.87 68.0386C1066.87 69.0298 1067.67 69.8333 1068.66 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 69.8333C1063.68 69.8333 1064.48 69.0298 1064.48 68.0386C1064.48 67.0474 1063.68 66.2439 1062.69 66.2439C1061.7 66.2439 1060.9 67.0474 1060.9 68.0386C1060.9 69.0298 1061.7 69.8333 1062.69 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 69.8333C1057.71 69.8333 1058.51 69.0298 1058.51 68.0386C1058.51 67.0474 1057.71 66.2439 1056.72 66.2439C1055.73 66.2439 1054.93 67.0474 1054.93 68.0386C1054.93 69.0298 1055.73 69.8333 1056.72 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 69.8333C1051.74 69.8333 1052.54 69.0298 1052.54 68.0386C1052.54 67.0474 1051.74 66.2439 1050.75 66.2439C1049.76 66.2439 1048.96 67.0474 1048.96 68.0386C1048.96 69.0298 1049.76 69.8333 1050.75 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 69.8333C1045.77 69.8333 1046.57 69.0298 1046.57 68.0386C1046.57 67.0474 1045.77 66.2439 1044.78 66.2439C1043.79 66.2439 1042.99 67.0474 1042.99 68.0386C1042.99 69.0298 1043.79 69.8333 1044.78 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 69.8333C1039.8 69.8333 1040.6 69.0298 1040.6 68.0386C1040.6 67.0474 1039.8 66.2439 1038.81 66.2439C1037.82 66.2439 1037.01 67.0474 1037.01 68.0386C1037.01 69.0298 1037.82 69.8333 1038.81 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 69.8333C1033.82 69.8333 1034.63 69.0298 1034.63 68.0386C1034.63 67.0474 1033.82 66.2439 1032.84 66.2439C1031.85 66.2439 1031.04 67.0474 1031.04 68.0386C1031.04 69.0298 1031.85 69.8333 1032.84 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 69.8333C1027.85 69.8333 1028.66 69.0298 1028.66 68.0386C1028.66 67.0474 1027.85 66.2439 1026.87 66.2439C1025.88 66.2439 1025.07 67.0474 1025.07 68.0386C1025.07 69.0298 1025.88 69.8333 1026.87 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 69.8333C1021.88 69.8333 1022.69 69.0298 1022.69 68.0386C1022.69 67.0474 1021.88 66.2439 1020.9 66.2439C1019.91 66.2439 1019.1 67.0474 1019.1 68.0386C1019.1 69.0298 1019.91 69.8333 1020.9 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 69.8333C1015.91 69.8333 1016.72 69.0298 1016.72 68.0386C1016.72 67.0474 1015.91 66.2439 1014.93 66.2439C1013.94 66.2439 1013.13 67.0474 1013.13 68.0386C1013.13 69.0298 1013.94 69.8333 1014.93 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 69.8333C1009.94 69.8333 1010.75 69.0298 1010.75 68.0386C1010.75 67.0474 1009.94 66.2439 1008.96 66.2439C1007.97 66.2439 1007.16 67.0474 1007.16 68.0386C1007.16 69.0298 1007.97 69.8333 1008.96 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 69.8333C1003.97 69.8333 1004.78 69.0298 1004.78 68.0386C1004.78 67.0474 1003.97 66.2439 1002.99 66.2439C1002 66.2439 1001.19 67.0474 1001.19 68.0386C1001.19 69.0298 1002 69.8333 1002.99 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 69.8333C998.004 69.8333 998.806 69.0298 998.806 68.0386C998.806 67.0474 998.004 66.2439 997.015 66.2439C996.026 66.2439 995.224 67.0474 995.224 68.0386C995.224 69.0298 996.026 69.8333 997.015 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 69.8333C992.034 69.8333 992.836 69.0298 992.836 68.0386C992.836 67.0474 992.034 66.2439 991.045 66.2439C990.056 66.2439 989.254 67.0474 989.254 68.0386C989.254 69.0298 990.056 69.8333 991.045 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 69.8333C986.064 69.8333 986.866 69.0298 986.866 68.0386C986.866 67.0474 986.064 66.2439 985.075 66.2439C984.085 66.2439 983.284 67.0474 983.284 68.0386C983.284 69.0298 984.085 69.8333 985.075 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 69.8333C980.094 69.8333 980.896 69.0298 980.896 68.0386C980.896 67.0474 980.094 66.2439 979.104 66.2439C978.115 66.2439 977.313 67.0474 977.313 68.0386C977.313 69.0298 978.115 69.8333 979.104 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 69.8333C974.123 69.8333 974.925 69.0298 974.925 68.0386C974.925 67.0474 974.123 66.2439 973.134 66.2439C972.145 66.2439 971.343 67.0474 971.343 68.0386C971.343 69.0298 972.145 69.8333 973.134 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 69.8333C968.153 69.8333 968.955 69.0298 968.955 68.0386C968.955 67.0474 968.153 66.2439 967.164 66.2439C966.175 66.2439 965.373 67.0474 965.373 68.0386C965.373 69.0298 966.175 69.8333 967.164 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 69.8333C962.183 69.8333 962.985 69.0298 962.985 68.0386C962.985 67.0474 962.183 66.2439 961.194 66.2439C960.205 66.2439 959.403 67.0474 959.403 68.0386C959.403 69.0298 960.205 69.8333 961.194 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 69.8333C956.213 69.8333 957.015 69.0298 957.015 68.0386C957.015 67.0474 956.213 66.2439 955.224 66.2439C954.235 66.2439 953.433 67.0474 953.433 68.0386C953.433 69.0298 954.235 69.8333 955.224 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 69.8333C950.243 69.8333 951.045 69.0298 951.045 68.0386C951.045 67.0474 950.243 66.2439 949.254 66.2439C948.265 66.2439 947.463 67.0474 947.463 68.0386C947.463 69.0298 948.265 69.8333 949.254 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 69.8333C944.273 69.8333 945.075 69.0298 945.075 68.0386C945.075 67.0474 944.273 66.2439 943.284 66.2439C942.294 66.2439 941.492 67.0474 941.492 68.0386C941.492 69.0298 942.294 69.8333 943.284 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 69.8333C938.303 69.8333 939.105 69.0298 939.105 68.0386C939.105 67.0474 938.303 66.2439 937.313 66.2439C936.324 66.2439 935.522 67.0474 935.522 68.0386C935.522 69.0298 936.324 69.8333 937.313 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 69.8333C932.332 69.8333 933.134 69.0298 933.134 68.0386C933.134 67.0474 932.332 66.2439 931.343 66.2439C930.354 66.2439 929.552 67.0474 929.552 68.0386C929.552 69.0298 930.354 69.8333 931.343 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 69.8333C926.362 69.8333 927.164 69.0298 927.164 68.0386C927.164 67.0474 926.362 66.2439 925.373 66.2439C924.384 66.2439 923.582 67.0474 923.582 68.0386C923.582 69.0298 924.384 69.8333 925.373 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 69.8333C920.392 69.8333 921.194 69.0298 921.194 68.0386C921.194 67.0474 920.392 66.2439 919.403 66.2439C918.414 66.2439 917.612 67.0474 917.612 68.0386C917.612 69.0298 918.414 69.8333 919.403 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 69.8333C914.422 69.8333 915.224 69.0298 915.224 68.0386C915.224 67.0474 914.422 66.2439 913.433 66.2439C912.444 66.2439 911.642 67.0474 911.642 68.0386C911.642 69.0298 912.444 69.8333 913.433 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 69.8333C908.452 69.8333 909.254 69.0298 909.254 68.0386C909.254 67.0474 908.452 66.2439 907.463 66.2439C906.474 66.2439 905.672 67.0474 905.672 68.0386C905.672 69.0298 906.474 69.8333 907.463 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 69.8333C902.482 69.8333 903.284 69.0298 903.284 68.0386C903.284 67.0474 902.482 66.2439 901.492 66.2439C900.503 66.2439 899.701 67.0474 899.701 68.0386C899.701 69.0298 900.503 69.8333 901.492 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 69.8333C896.512 69.8333 897.313 69.0298 897.313 68.0386C897.313 67.0474 896.512 66.2439 895.522 66.2439C894.533 66.2439 893.731 67.0474 893.731 68.0386C893.731 69.0298 894.533 69.8333 895.522 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 69.8333C890.541 69.8333 891.343 69.0298 891.343 68.0386C891.343 67.0474 890.541 66.2439 889.552 66.2439C888.563 66.2439 887.761 67.0474 887.761 68.0386C887.761 69.0298 888.563 69.8333 889.552 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 69.8333C884.571 69.8333 885.373 69.0298 885.373 68.0386C885.373 67.0474 884.571 66.2439 883.582 66.2439C882.593 66.2439 881.791 67.0474 881.791 68.0386C881.791 69.0298 882.593 69.8333 883.582 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 69.8333C878.601 69.8333 879.403 69.0298 879.403 68.0386C879.403 67.0474 878.601 66.2439 877.612 66.2439C876.623 66.2439 875.821 67.0474 875.821 68.0386C875.821 69.0298 876.623 69.8333 877.612 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 69.8333C872.631 69.8333 873.433 69.0298 873.433 68.0386C873.433 67.0474 872.631 66.2439 871.642 66.2439C870.653 66.2439 869.851 67.0474 869.851 68.0386C869.851 69.0298 870.653 69.8333 871.642 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 69.8333C866.661 69.8333 867.463 69.0298 867.463 68.0386C867.463 67.0474 866.661 66.2439 865.672 66.2439C864.683 66.2439 863.881 67.0474 863.881 68.0386C863.881 69.0298 864.683 69.8333 865.672 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 69.8333C860.691 69.8333 861.493 69.0298 861.493 68.0386C861.493 67.0474 860.691 66.2439 859.701 66.2439C858.712 66.2439 857.91 67.0474 857.91 68.0386C857.91 69.0298 858.712 69.8333 859.701 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 69.8333C854.721 69.8333 855.522 69.0298 855.522 68.0386C855.522 67.0474 854.721 66.2439 853.731 66.2439C852.742 66.2439 851.94 67.0474 851.94 68.0386C851.94 69.0298 852.742 69.8333 853.731 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 69.8333C848.75 69.8333 849.552 69.0298 849.552 68.0386C849.552 67.0474 848.75 66.2439 847.761 66.2439C846.772 66.2439 845.97 67.0474 845.97 68.0386C845.97 69.0298 846.772 69.8333 847.761 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 69.8333C842.78 69.8333 843.582 69.0298 843.582 68.0386C843.582 67.0474 842.78 66.2439 841.791 66.2439C840.802 66.2439 840 67.0474 840 68.0386C840 69.0298 840.802 69.8333 841.791 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 69.8333C836.81 69.8333 837.612 69.0298 837.612 68.0386C837.612 67.0474 836.81 66.2439 835.821 66.2439C834.832 66.2439 834.03 67.0474 834.03 68.0386C834.03 69.0298 834.832 69.8333 835.821 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 69.8333C830.84 69.8333 831.642 69.0298 831.642 68.0386C831.642 67.0474 830.84 66.2439 829.851 66.2439C828.862 66.2439 828.06 67.0474 828.06 68.0386C828.06 69.0298 828.862 69.8333 829.851 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 69.8333C824.87 69.8333 825.672 69.0298 825.672 68.0386C825.672 67.0474 824.87 66.2439 823.881 66.2439C822.891 66.2439 822.09 67.0474 822.09 68.0386C822.09 69.0298 822.891 69.8333 823.881 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 69.8333C818.9 69.8333 819.701 69.0298 819.701 68.0386C819.701 67.0474 818.9 66.2439 817.91 66.2439C816.921 66.2439 816.119 67.0474 816.119 68.0386C816.119 69.0298 816.921 69.8333 817.91 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 69.8333C812.929 69.8333 813.731 69.0298 813.731 68.0386C813.731 67.0474 812.929 66.2439 811.94 66.2439C810.951 66.2439 810.149 67.0474 810.149 68.0386C810.149 69.0298 810.951 69.8333 811.94 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 69.8333C806.959 69.8333 807.761 69.0298 807.761 68.0386C807.761 67.0474 806.959 66.2439 805.97 66.2439C804.981 66.2439 804.179 67.0474 804.179 68.0386C804.179 69.0298 804.981 69.8333 805.97 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M800 69.8333C800.989 69.8333 801.791 69.0298 801.791 68.0386C801.791 67.0474 800.989 66.2439 800 66.2439C799.011 66.2439 798.209 67.0474 798.209 68.0386C798.209 69.0298 799.011 69.8333 800 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 69.8333C795.019 69.8333 795.821 69.0298 795.821 68.0386C795.821 67.0474 795.019 66.2439 794.03 66.2439C793.041 66.2439 792.239 67.0474 792.239 68.0386C792.239 69.0298 793.041 69.8333 794.03 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 69.8333C789.049 69.8333 789.851 69.0298 789.851 68.0386C789.851 67.0474 789.049 66.2439 788.06 66.2439C787.071 66.2439 786.269 67.0474 786.269 68.0386C786.269 69.0298 787.071 69.8333 788.06 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 69.8333C783.079 69.8333 783.881 69.0298 783.881 68.0386C783.881 67.0474 783.079 66.2439 782.09 66.2439C781.1 66.2439 780.299 67.0474 780.299 68.0386C780.299 69.0298 781.1 69.8333 782.09 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 69.8333C777.109 69.8333 777.91 69.0298 777.91 68.0386C777.91 67.0474 777.109 66.2439 776.119 66.2439C775.13 66.2439 774.328 67.0474 774.328 68.0386C774.328 69.0298 775.13 69.8333 776.119 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 69.8333C771.138 69.8333 771.94 69.0298 771.94 68.0386C771.94 67.0474 771.138 66.2439 770.149 66.2439C769.16 66.2439 768.358 67.0474 768.358 68.0386C768.358 69.0298 769.16 69.8333 770.149 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 69.8333C765.168 69.8333 765.97 69.0298 765.97 68.0386C765.97 67.0474 765.168 66.2439 764.179 66.2439C763.19 66.2439 762.388 67.0474 762.388 68.0386C762.388 69.0298 763.19 69.8333 764.179 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 69.8333C759.198 69.8333 760 69.0298 760 68.0386C760 67.0474 759.198 66.2439 758.209 66.2439C757.22 66.2439 756.418 67.0474 756.418 68.0386C756.418 69.0298 757.22 69.8333 758.209 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 69.8333C753.228 69.8333 754.03 69.0298 754.03 68.0386C754.03 67.0474 753.228 66.2439 752.239 66.2439C751.25 66.2439 750.448 67.0474 750.448 68.0386C750.448 69.0298 751.25 69.8333 752.239 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 69.8333C747.258 69.8333 748.06 69.0298 748.06 68.0386C748.06 67.0474 747.258 66.2439 746.269 66.2439C745.279 66.2439 744.478 67.0474 744.478 68.0386C744.478 69.0298 745.279 69.8333 746.269 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 69.8333C741.288 69.8333 742.09 69.0298 742.09 68.0386C742.09 67.0474 741.288 66.2439 740.299 66.2439C739.309 66.2439 738.507 67.0474 738.507 68.0386C738.507 69.0298 739.309 69.8333 740.299 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 69.8333C735.318 69.8333 736.119 69.0298 736.119 68.0386C736.119 67.0474 735.318 66.2439 734.328 66.2439C733.339 66.2439 732.537 67.0474 732.537 68.0386C732.537 69.0298 733.339 69.8333 734.328 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 69.8333C729.347 69.8333 730.149 69.0298 730.149 68.0386C730.149 67.0474 729.347 66.2439 728.358 66.2439C727.369 66.2439 726.567 67.0474 726.567 68.0386C726.567 69.0298 727.369 69.8333 728.358 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 69.8333C723.377 69.8333 724.179 69.0298 724.179 68.0386C724.179 67.0474 723.377 66.2439 722.388 66.2439C721.399 66.2439 720.597 67.0474 720.597 68.0386C720.597 69.0298 721.399 69.8333 722.388 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 69.8333C717.407 69.8333 718.209 69.0298 718.209 68.0386C718.209 67.0474 717.407 66.2439 716.418 66.2439C715.429 66.2439 714.627 67.0474 714.627 68.0386C714.627 69.0298 715.429 69.8333 716.418 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 69.8333C711.437 69.8333 712.239 69.0298 712.239 68.0386C712.239 67.0474 711.437 66.2439 710.448 66.2439C709.459 66.2439 708.657 67.0474 708.657 68.0386C708.657 69.0298 709.459 69.8333 710.448 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 69.8333C705.467 69.8333 706.269 69.0298 706.269 68.0386C706.269 67.0474 705.467 66.2439 704.478 66.2439C703.488 66.2439 702.687 67.0474 702.687 68.0386C702.687 69.0298 703.488 69.8333 704.478 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 69.8333C699.497 69.8333 700.299 69.0298 700.299 68.0386C700.299 67.0474 699.497 66.2439 698.507 66.2439C697.518 66.2439 696.716 67.0474 696.716 68.0386C696.716 69.0298 697.518 69.8333 698.507 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 69.8333C693.526 69.8333 694.328 69.0298 694.328 68.0386C694.328 67.0474 693.526 66.2439 692.537 66.2439C691.548 66.2439 690.746 67.0474 690.746 68.0386C690.746 69.0298 691.548 69.8333 692.537 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 69.8333C687.556 69.8333 688.358 69.0298 688.358 68.0386C688.358 67.0474 687.556 66.2439 686.567 66.2439C685.578 66.2439 684.776 67.0474 684.776 68.0386C684.776 69.0298 685.578 69.8333 686.567 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 69.8333C681.586 69.8333 682.388 69.0298 682.388 68.0386C682.388 67.0474 681.586 66.2439 680.597 66.2439C679.608 66.2439 678.806 67.0474 678.806 68.0386C678.806 69.0298 679.608 69.8333 680.597 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 69.8333C675.616 69.8333 676.418 69.0298 676.418 68.0386C676.418 67.0474 675.616 66.2439 674.627 66.2439C673.638 66.2439 672.836 67.0474 672.836 68.0386C672.836 69.0298 673.638 69.8333 674.627 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 69.8333C669.646 69.8333 670.448 69.0298 670.448 68.0386C670.448 67.0474 669.646 66.2439 668.657 66.2439C667.668 66.2439 666.866 67.0474 666.866 68.0386C666.866 69.0298 667.668 69.8333 668.657 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 69.8333C663.676 69.8333 664.478 69.0298 664.478 68.0386C664.478 67.0474 663.676 66.2439 662.687 66.2439C661.697 66.2439 660.896 67.0474 660.896 68.0386C660.896 69.0298 661.697 69.8333 662.687 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 69.8333C657.706 69.8333 658.507 69.0298 658.507 68.0386C658.507 67.0474 657.706 66.2439 656.716 66.2439C655.727 66.2439 654.925 67.0474 654.925 68.0386C654.925 69.0298 655.727 69.8333 656.716 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 69.8333C645.765 69.8333 646.567 69.0298 646.567 68.0386C646.567 67.0474 645.765 66.2439 644.776 66.2439C643.787 66.2439 642.985 67.0474 642.985 68.0386C642.985 69.0298 643.787 69.8333 644.776 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 69.8333C639.795 69.8333 640.597 69.0298 640.597 68.0386C640.597 67.0474 639.795 66.2439 638.806 66.2439C637.817 66.2439 637.015 67.0474 637.015 68.0386C637.015 69.0298 637.817 69.8333 638.806 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 69.8333C633.825 69.8333 634.627 69.0298 634.627 68.0386C634.627 67.0474 633.825 66.2439 632.836 66.2439C631.847 66.2439 631.045 67.0474 631.045 68.0386C631.045 69.0298 631.847 69.8333 632.836 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 69.8333C627.855 69.8333 628.657 69.0298 628.657 68.0386C628.657 67.0474 627.855 66.2439 626.866 66.2439C625.876 66.2439 625.075 67.0474 625.075 68.0386C625.075 69.0298 625.876 69.8333 626.866 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 69.8333C621.885 69.8333 622.687 69.0298 622.687 68.0386C622.687 67.0474 621.885 66.2439 620.895 66.2439C619.906 66.2439 619.104 67.0474 619.104 68.0386C619.104 69.0298 619.906 69.8333 620.895 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 69.8333C615.915 69.8333 616.716 69.0298 616.716 68.0386C616.716 67.0474 615.915 66.2439 614.925 66.2439C613.936 66.2439 613.134 67.0474 613.134 68.0386C613.134 69.0298 613.936 69.8333 614.925 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 69.8333C603.974 69.8333 604.776 69.0298 604.776 68.0386C604.776 67.0474 603.974 66.2439 602.985 66.2439C601.996 66.2439 601.194 67.0474 601.194 68.0386C601.194 69.0298 601.996 69.8333 602.985 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 69.8333C598.004 69.8333 598.806 69.0298 598.806 68.0386C598.806 67.0474 598.004 66.2439 597.015 66.2439C596.026 66.2439 595.224 67.0474 595.224 68.0386C595.224 69.0298 596.026 69.8333 597.015 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 69.8333C592.034 69.8333 592.836 69.0298 592.836 68.0386C592.836 67.0474 592.034 66.2439 591.045 66.2439C590.056 66.2439 589.254 67.0474 589.254 68.0386C589.254 69.0298 590.056 69.8333 591.045 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 69.8333C586.064 69.8333 586.866 69.0298 586.866 68.0386C586.866 67.0474 586.064 66.2439 585.075 66.2439C584.085 66.2439 583.284 67.0474 583.284 68.0386C583.284 69.0298 584.085 69.8333 585.075 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 69.8333C580.094 69.8333 580.896 69.0298 580.896 68.0386C580.896 67.0474 580.094 66.2439 579.104 66.2439C578.115 66.2439 577.313 67.0474 577.313 68.0386C577.313 69.0298 578.115 69.8333 579.104 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 69.8333C496.512 69.8333 497.313 69.0298 497.313 68.0386C497.313 67.0474 496.512 66.2439 495.522 66.2439C494.533 66.2439 493.731 67.0474 493.731 68.0386C493.731 69.0298 494.533 69.8333 495.522 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 69.8333C490.541 69.8333 491.343 69.0298 491.343 68.0386C491.343 67.0474 490.541 66.2439 489.552 66.2439C488.563 66.2439 487.761 67.0474 487.761 68.0386C487.761 69.0298 488.563 69.8333 489.552 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 69.8333C484.571 69.8333 485.373 69.0298 485.373 68.0386C485.373 67.0474 484.571 66.2439 483.582 66.2439C482.593 66.2439 481.791 67.0474 481.791 68.0386C481.791 69.0298 482.593 69.8333 483.582 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 69.8333C478.601 69.8333 479.403 69.0298 479.403 68.0386C479.403 67.0474 478.601 66.2439 477.612 66.2439C476.623 66.2439 475.821 67.0474 475.821 68.0386C475.821 69.0298 476.623 69.8333 477.612 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M1092.54 63.8511C1093.53 63.8511 1094.33 63.0476 1094.33 62.0564C1094.33 61.0652 1093.53 60.2617 1092.54 60.2617C1091.55 60.2617 1090.75 61.0652 1090.75 62.0564C1090.75 63.0476 1091.55 63.8511 1092.54 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1086.57 63.8511C1087.56 63.8511 1088.36 63.0476 1088.36 62.0564C1088.36 61.0652 1087.56 60.2617 1086.57 60.2617C1085.58 60.2617 1084.78 61.0652 1084.78 62.0564C1084.78 63.0476 1085.58 63.8511 1086.57 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1080.6 63.8511C1081.59 63.8511 1082.39 63.0476 1082.39 62.0564C1082.39 61.0652 1081.59 60.2617 1080.6 60.2617C1079.61 60.2617 1078.81 61.0652 1078.81 62.0564C1078.81 63.0476 1079.61 63.8511 1080.6 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1074.63 63.8511C1075.62 63.8511 1076.42 63.0476 1076.42 62.0564C1076.42 61.0652 1075.62 60.2617 1074.63 60.2617C1073.64 60.2617 1072.84 61.0652 1072.84 62.0564C1072.84 63.0476 1073.64 63.8511 1074.63 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1068.66 63.8511C1069.65 63.8511 1070.45 63.0476 1070.45 62.0564C1070.45 61.0652 1069.65 60.2617 1068.66 60.2617C1067.67 60.2617 1066.87 61.0652 1066.87 62.0564C1066.87 63.0476 1067.67 63.8511 1068.66 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 63.8511C1063.68 63.8511 1064.48 63.0476 1064.48 62.0564C1064.48 61.0652 1063.68 60.2617 1062.69 60.2617C1061.7 60.2617 1060.9 61.0652 1060.9 62.0564C1060.9 63.0476 1061.7 63.8511 1062.69 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 63.8511C1057.71 63.8511 1058.51 63.0476 1058.51 62.0564C1058.51 61.0652 1057.71 60.2617 1056.72 60.2617C1055.73 60.2617 1054.93 61.0652 1054.93 62.0564C1054.93 63.0476 1055.73 63.8511 1056.72 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 63.8511C1051.74 63.8511 1052.54 63.0476 1052.54 62.0564C1052.54 61.0652 1051.74 60.2617 1050.75 60.2617C1049.76 60.2617 1048.96 61.0652 1048.96 62.0564C1048.96 63.0476 1049.76 63.8511 1050.75 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 63.8511C1045.77 63.8511 1046.57 63.0476 1046.57 62.0564C1046.57 61.0652 1045.77 60.2617 1044.78 60.2617C1043.79 60.2617 1042.99 61.0652 1042.99 62.0564C1042.99 63.0476 1043.79 63.8511 1044.78 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 63.8511C1039.8 63.8511 1040.6 63.0476 1040.6 62.0564C1040.6 61.0652 1039.8 60.2617 1038.81 60.2617C1037.82 60.2617 1037.01 61.0652 1037.01 62.0564C1037.01 63.0476 1037.82 63.8511 1038.81 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 63.8511C1033.82 63.8511 1034.63 63.0476 1034.63 62.0564C1034.63 61.0652 1033.82 60.2617 1032.84 60.2617C1031.85 60.2617 1031.04 61.0652 1031.04 62.0564C1031.04 63.0476 1031.85 63.8511 1032.84 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 63.8511C1027.85 63.8511 1028.66 63.0476 1028.66 62.0564C1028.66 61.0652 1027.85 60.2617 1026.87 60.2617C1025.88 60.2617 1025.07 61.0652 1025.07 62.0564C1025.07 63.0476 1025.88 63.8511 1026.87 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 63.8511C1021.88 63.8511 1022.69 63.0476 1022.69 62.0564C1022.69 61.0652 1021.88 60.2617 1020.9 60.2617C1019.91 60.2617 1019.1 61.0652 1019.1 62.0564C1019.1 63.0476 1019.91 63.8511 1020.9 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 63.8511C1015.91 63.8511 1016.72 63.0476 1016.72 62.0564C1016.72 61.0652 1015.91 60.2617 1014.93 60.2617C1013.94 60.2617 1013.13 61.0652 1013.13 62.0564C1013.13 63.0476 1013.94 63.8511 1014.93 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 63.8511C1009.94 63.8511 1010.75 63.0476 1010.75 62.0564C1010.75 61.0652 1009.94 60.2617 1008.96 60.2617C1007.97 60.2617 1007.16 61.0652 1007.16 62.0564C1007.16 63.0476 1007.97 63.8511 1008.96 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 63.8511C1003.97 63.8511 1004.78 63.0476 1004.78 62.0564C1004.78 61.0652 1003.97 60.2617 1002.99 60.2617C1002 60.2617 1001.19 61.0652 1001.19 62.0564C1001.19 63.0476 1002 63.8511 1002.99 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 63.8511C998.004 63.8511 998.806 63.0476 998.806 62.0564C998.806 61.0652 998.004 60.2617 997.015 60.2617C996.026 60.2617 995.224 61.0652 995.224 62.0564C995.224 63.0476 996.026 63.8511 997.015 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 63.8511C992.034 63.8511 992.836 63.0476 992.836 62.0564C992.836 61.0652 992.034 60.2617 991.045 60.2617C990.056 60.2617 989.254 61.0652 989.254 62.0564C989.254 63.0476 990.056 63.8511 991.045 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 63.8511C986.064 63.8511 986.866 63.0476 986.866 62.0564C986.866 61.0652 986.064 60.2617 985.075 60.2617C984.085 60.2617 983.284 61.0652 983.284 62.0564C983.284 63.0476 984.085 63.8511 985.075 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 63.8511C980.094 63.8511 980.896 63.0476 980.896 62.0564C980.896 61.0652 980.094 60.2617 979.104 60.2617C978.115 60.2617 977.313 61.0652 977.313 62.0564C977.313 63.0476 978.115 63.8511 979.104 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 63.8511C974.123 63.8511 974.925 63.0476 974.925 62.0564C974.925 61.0652 974.123 60.2617 973.134 60.2617C972.145 60.2617 971.343 61.0652 971.343 62.0564C971.343 63.0476 972.145 63.8511 973.134 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 63.8511C968.153 63.8511 968.955 63.0476 968.955 62.0564C968.955 61.0652 968.153 60.2617 967.164 60.2617C966.175 60.2617 965.373 61.0652 965.373 62.0564C965.373 63.0476 966.175 63.8511 967.164 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 63.8511C962.183 63.8511 962.985 63.0476 962.985 62.0564C962.985 61.0652 962.183 60.2617 961.194 60.2617C960.205 60.2617 959.403 61.0652 959.403 62.0564C959.403 63.0476 960.205 63.8511 961.194 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 63.8511C956.213 63.8511 957.015 63.0476 957.015 62.0564C957.015 61.0652 956.213 60.2617 955.224 60.2617C954.235 60.2617 953.433 61.0652 953.433 62.0564C953.433 63.0476 954.235 63.8511 955.224 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 63.8511C950.243 63.8511 951.045 63.0476 951.045 62.0564C951.045 61.0652 950.243 60.2617 949.254 60.2617C948.265 60.2617 947.463 61.0652 947.463 62.0564C947.463 63.0476 948.265 63.8511 949.254 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 63.8511C944.273 63.8511 945.075 63.0476 945.075 62.0564C945.075 61.0652 944.273 60.2617 943.284 60.2617C942.294 60.2617 941.492 61.0652 941.492 62.0564C941.492 63.0476 942.294 63.8511 943.284 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 63.8511C938.303 63.8511 939.105 63.0476 939.105 62.0564C939.105 61.0652 938.303 60.2617 937.313 60.2617C936.324 60.2617 935.522 61.0652 935.522 62.0564C935.522 63.0476 936.324 63.8511 937.313 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 63.8511C932.332 63.8511 933.134 63.0476 933.134 62.0564C933.134 61.0652 932.332 60.2617 931.343 60.2617C930.354 60.2617 929.552 61.0652 929.552 62.0564C929.552 63.0476 930.354 63.8511 931.343 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 63.8511C926.362 63.8511 927.164 63.0476 927.164 62.0564C927.164 61.0652 926.362 60.2617 925.373 60.2617C924.384 60.2617 923.582 61.0652 923.582 62.0564C923.582 63.0476 924.384 63.8511 925.373 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 63.8511C920.392 63.8511 921.194 63.0476 921.194 62.0564C921.194 61.0652 920.392 60.2617 919.403 60.2617C918.414 60.2617 917.612 61.0652 917.612 62.0564C917.612 63.0476 918.414 63.8511 919.403 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 63.8511C914.422 63.8511 915.224 63.0476 915.224 62.0564C915.224 61.0652 914.422 60.2617 913.433 60.2617C912.444 60.2617 911.642 61.0652 911.642 62.0564C911.642 63.0476 912.444 63.8511 913.433 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 63.8511C908.452 63.8511 909.254 63.0476 909.254 62.0564C909.254 61.0652 908.452 60.2617 907.463 60.2617C906.474 60.2617 905.672 61.0652 905.672 62.0564C905.672 63.0476 906.474 63.8511 907.463 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 63.8511C902.482 63.8511 903.284 63.0476 903.284 62.0564C903.284 61.0652 902.482 60.2617 901.492 60.2617C900.503 60.2617 899.701 61.0652 899.701 62.0564C899.701 63.0476 900.503 63.8511 901.492 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 63.8511C896.512 63.8511 897.313 63.0476 897.313 62.0564C897.313 61.0652 896.512 60.2617 895.522 60.2617C894.533 60.2617 893.731 61.0652 893.731 62.0564C893.731 63.0476 894.533 63.8511 895.522 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 63.8511C890.541 63.8511 891.343 63.0476 891.343 62.0564C891.343 61.0652 890.541 60.2617 889.552 60.2617C888.563 60.2617 887.761 61.0652 887.761 62.0564C887.761 63.0476 888.563 63.8511 889.552 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 63.8511C884.571 63.8511 885.373 63.0476 885.373 62.0564C885.373 61.0652 884.571 60.2617 883.582 60.2617C882.593 60.2617 881.791 61.0652 881.791 62.0564C881.791 63.0476 882.593 63.8511 883.582 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 63.8511C878.601 63.8511 879.403 63.0476 879.403 62.0564C879.403 61.0652 878.601 60.2617 877.612 60.2617C876.623 60.2617 875.821 61.0652 875.821 62.0564C875.821 63.0476 876.623 63.8511 877.612 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 63.8511C872.631 63.8511 873.433 63.0476 873.433 62.0564C873.433 61.0652 872.631 60.2617 871.642 60.2617C870.653 60.2617 869.851 61.0652 869.851 62.0564C869.851 63.0476 870.653 63.8511 871.642 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 63.8511C866.661 63.8511 867.463 63.0476 867.463 62.0564C867.463 61.0652 866.661 60.2617 865.672 60.2617C864.683 60.2617 863.881 61.0652 863.881 62.0564C863.881 63.0476 864.683 63.8511 865.672 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 63.8511C860.691 63.8511 861.493 63.0476 861.493 62.0564C861.493 61.0652 860.691 60.2617 859.701 60.2617C858.712 60.2617 857.91 61.0652 857.91 62.0564C857.91 63.0476 858.712 63.8511 859.701 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 63.8511C854.721 63.8511 855.522 63.0476 855.522 62.0564C855.522 61.0652 854.721 60.2617 853.731 60.2617C852.742 60.2617 851.94 61.0652 851.94 62.0564C851.94 63.0476 852.742 63.8511 853.731 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 63.8511C848.75 63.8511 849.552 63.0476 849.552 62.0564C849.552 61.0652 848.75 60.2617 847.761 60.2617C846.772 60.2617 845.97 61.0652 845.97 62.0564C845.97 63.0476 846.772 63.8511 847.761 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 63.8511C842.78 63.8511 843.582 63.0476 843.582 62.0564C843.582 61.0652 842.78 60.2617 841.791 60.2617C840.802 60.2617 840 61.0652 840 62.0564C840 63.0476 840.802 63.8511 841.791 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 63.8511C836.81 63.8511 837.612 63.0476 837.612 62.0564C837.612 61.0652 836.81 60.2617 835.821 60.2617C834.832 60.2617 834.03 61.0652 834.03 62.0564C834.03 63.0476 834.832 63.8511 835.821 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 63.8511C830.84 63.8511 831.642 63.0476 831.642 62.0564C831.642 61.0652 830.84 60.2617 829.851 60.2617C828.862 60.2617 828.06 61.0652 828.06 62.0564C828.06 63.0476 828.862 63.8511 829.851 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 63.8511C824.87 63.8511 825.672 63.0476 825.672 62.0564C825.672 61.0652 824.87 60.2617 823.881 60.2617C822.891 60.2617 822.09 61.0652 822.09 62.0564C822.09 63.0476 822.891 63.8511 823.881 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 63.8511C818.9 63.8511 819.701 63.0476 819.701 62.0564C819.701 61.0652 818.9 60.2617 817.91 60.2617C816.921 60.2617 816.119 61.0652 816.119 62.0564C816.119 63.0476 816.921 63.8511 817.91 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 63.8511C812.929 63.8511 813.731 63.0476 813.731 62.0564C813.731 61.0652 812.929 60.2617 811.94 60.2617C810.951 60.2617 810.149 61.0652 810.149 62.0564C810.149 63.0476 810.951 63.8511 811.94 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 63.8511C806.959 63.8511 807.761 63.0476 807.761 62.0564C807.761 61.0652 806.959 60.2617 805.97 60.2617C804.981 60.2617 804.179 61.0652 804.179 62.0564C804.179 63.0476 804.981 63.8511 805.97 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M800 63.8511C800.989 63.8511 801.791 63.0476 801.791 62.0564C801.791 61.0652 800.989 60.2617 800 60.2617C799.011 60.2617 798.209 61.0652 798.209 62.0564C798.209 63.0476 799.011 63.8511 800 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 63.8511C795.019 63.8511 795.821 63.0476 795.821 62.0564C795.821 61.0652 795.019 60.2617 794.03 60.2617C793.041 60.2617 792.239 61.0652 792.239 62.0564C792.239 63.0476 793.041 63.8511 794.03 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 63.8511C789.049 63.8511 789.851 63.0476 789.851 62.0564C789.851 61.0652 789.049 60.2617 788.06 60.2617C787.071 60.2617 786.269 61.0652 786.269 62.0564C786.269 63.0476 787.071 63.8511 788.06 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 63.8511C783.079 63.8511 783.881 63.0476 783.881 62.0564C783.881 61.0652 783.079 60.2617 782.09 60.2617C781.1 60.2617 780.299 61.0652 780.299 62.0564C780.299 63.0476 781.1 63.8511 782.09 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 63.8511C777.109 63.8511 777.91 63.0476 777.91 62.0564C777.91 61.0652 777.109 60.2617 776.119 60.2617C775.13 60.2617 774.328 61.0652 774.328 62.0564C774.328 63.0476 775.13 63.8511 776.119 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 63.8511C771.138 63.8511 771.94 63.0476 771.94 62.0564C771.94 61.0652 771.138 60.2617 770.149 60.2617C769.16 60.2617 768.358 61.0652 768.358 62.0564C768.358 63.0476 769.16 63.8511 770.149 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 63.8511C765.168 63.8511 765.97 63.0476 765.97 62.0564C765.97 61.0652 765.168 60.2617 764.179 60.2617C763.19 60.2617 762.388 61.0652 762.388 62.0564C762.388 63.0476 763.19 63.8511 764.179 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 63.8511C759.198 63.8511 760 63.0476 760 62.0564C760 61.0652 759.198 60.2617 758.209 60.2617C757.22 60.2617 756.418 61.0652 756.418 62.0564C756.418 63.0476 757.22 63.8511 758.209 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 63.8511C753.228 63.8511 754.03 63.0476 754.03 62.0564C754.03 61.0652 753.228 60.2617 752.239 60.2617C751.25 60.2617 750.448 61.0652 750.448 62.0564C750.448 63.0476 751.25 63.8511 752.239 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 63.8511C747.258 63.8511 748.06 63.0476 748.06 62.0564C748.06 61.0652 747.258 60.2617 746.269 60.2617C745.279 60.2617 744.478 61.0652 744.478 62.0564C744.478 63.0476 745.279 63.8511 746.269 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 63.8511C741.288 63.8511 742.09 63.0476 742.09 62.0564C742.09 61.0652 741.288 60.2617 740.299 60.2617C739.309 60.2617 738.507 61.0652 738.507 62.0564C738.507 63.0476 739.309 63.8511 740.299 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 63.8511C735.318 63.8511 736.119 63.0476 736.119 62.0564C736.119 61.0652 735.318 60.2617 734.328 60.2617C733.339 60.2617 732.537 61.0652 732.537 62.0564C732.537 63.0476 733.339 63.8511 734.328 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 63.8511C729.347 63.8511 730.149 63.0476 730.149 62.0564C730.149 61.0652 729.347 60.2617 728.358 60.2617C727.369 60.2617 726.567 61.0652 726.567 62.0564C726.567 63.0476 727.369 63.8511 728.358 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 63.8511C723.377 63.8511 724.179 63.0476 724.179 62.0564C724.179 61.0652 723.377 60.2617 722.388 60.2617C721.399 60.2617 720.597 61.0652 720.597 62.0564C720.597 63.0476 721.399 63.8511 722.388 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 63.8511C717.407 63.8511 718.209 63.0476 718.209 62.0564C718.209 61.0652 717.407 60.2617 716.418 60.2617C715.429 60.2617 714.627 61.0652 714.627 62.0564C714.627 63.0476 715.429 63.8511 716.418 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 63.8511C711.437 63.8511 712.239 63.0476 712.239 62.0564C712.239 61.0652 711.437 60.2617 710.448 60.2617C709.459 60.2617 708.657 61.0652 708.657 62.0564C708.657 63.0476 709.459 63.8511 710.448 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 63.8511C705.467 63.8511 706.269 63.0476 706.269 62.0564C706.269 61.0652 705.467 60.2617 704.478 60.2617C703.488 60.2617 702.687 61.0652 702.687 62.0564C702.687 63.0476 703.488 63.8511 704.478 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 63.8511C699.497 63.8511 700.299 63.0476 700.299 62.0564C700.299 61.0652 699.497 60.2617 698.507 60.2617C697.518 60.2617 696.716 61.0652 696.716 62.0564C696.716 63.0476 697.518 63.8511 698.507 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 63.8511C693.526 63.8511 694.328 63.0476 694.328 62.0564C694.328 61.0652 693.526 60.2617 692.537 60.2617C691.548 60.2617 690.746 61.0652 690.746 62.0564C690.746 63.0476 691.548 63.8511 692.537 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M686.567 63.8511C687.556 63.8511 688.358 63.0476 688.358 62.0564C688.358 61.0652 687.556 60.2617 686.567 60.2617C685.578 60.2617 684.776 61.0652 684.776 62.0564C684.776 63.0476 685.578 63.8511 686.567 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M680.597 63.8511C681.586 63.8511 682.388 63.0476 682.388 62.0564C682.388 61.0652 681.586 60.2617 680.597 60.2617C679.608 60.2617 678.806 61.0652 678.806 62.0564C678.806 63.0476 679.608 63.8511 680.597 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 63.8511C675.616 63.8511 676.418 63.0476 676.418 62.0564C676.418 61.0652 675.616 60.2617 674.627 60.2617C673.638 60.2617 672.836 61.0652 672.836 62.0564C672.836 63.0476 673.638 63.8511 674.627 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M662.687 63.8511C663.676 63.8511 664.478 63.0476 664.478 62.0564C664.478 61.0652 663.676 60.2617 662.687 60.2617C661.697 60.2617 660.896 61.0652 660.896 62.0564C660.896 63.0476 661.697 63.8511 662.687 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 63.8511C657.706 63.8511 658.507 63.0476 658.507 62.0564C658.507 61.0652 657.706 60.2617 656.716 60.2617C655.727 60.2617 654.925 61.0652 654.925 62.0564C654.925 63.0476 655.727 63.8511 656.716 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 63.8511C651.735 63.8511 652.537 63.0476 652.537 62.0564C652.537 61.0652 651.735 60.2617 650.746 60.2617C649.757 60.2617 648.955 61.0652 648.955 62.0564C648.955 63.0476 649.757 63.8511 650.746 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 63.8511C645.765 63.8511 646.567 63.0476 646.567 62.0564C646.567 61.0652 645.765 60.2617 644.776 60.2617C643.787 60.2617 642.985 61.0652 642.985 62.0564C642.985 63.0476 643.787 63.8511 644.776 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 63.8511C639.795 63.8511 640.597 63.0476 640.597 62.0564C640.597 61.0652 639.795 60.2617 638.806 60.2617C637.817 60.2617 637.015 61.0652 637.015 62.0564C637.015 63.0476 637.817 63.8511 638.806 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 63.8511C633.825 63.8511 634.627 63.0476 634.627 62.0564C634.627 61.0652 633.825 60.2617 632.836 60.2617C631.847 60.2617 631.045 61.0652 631.045 62.0564C631.045 63.0476 631.847 63.8511 632.836 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 63.8511C627.855 63.8511 628.657 63.0476 628.657 62.0564C628.657 61.0652 627.855 60.2617 626.866 60.2617C625.876 60.2617 625.075 61.0652 625.075 62.0564C625.075 63.0476 625.876 63.8511 626.866 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 63.8511C621.885 63.8511 622.687 63.0476 622.687 62.0564C622.687 61.0652 621.885 60.2617 620.895 60.2617C619.906 60.2617 619.104 61.0652 619.104 62.0564C619.104 63.0476 619.906 63.8511 620.895 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 63.8511C615.915 63.8511 616.716 63.0476 616.716 62.0564C616.716 61.0652 615.915 60.2617 614.925 60.2617C613.936 60.2617 613.134 61.0652 613.134 62.0564C613.134 63.0476 613.936 63.8511 614.925 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 63.8511C609.944 63.8511 610.746 63.0476 610.746 62.0564C610.746 61.0652 609.944 60.2617 608.955 60.2617C607.966 60.2617 607.164 61.0652 607.164 62.0564C607.164 63.0476 607.966 63.8511 608.955 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 63.8511C603.974 63.8511 604.776 63.0476 604.776 62.0564C604.776 61.0652 603.974 60.2617 602.985 60.2617C601.996 60.2617 601.194 61.0652 601.194 62.0564C601.194 63.0476 601.996 63.8511 602.985 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 63.8511C598.004 63.8511 598.806 63.0476 598.806 62.0564C598.806 61.0652 598.004 60.2617 597.015 60.2617C596.026 60.2617 595.224 61.0652 595.224 62.0564C595.224 63.0476 596.026 63.8511 597.015 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 63.8511C592.034 63.8511 592.836 63.0476 592.836 62.0564C592.836 61.0652 592.034 60.2617 591.045 60.2617C590.056 60.2617 589.254 61.0652 589.254 62.0564C589.254 63.0476 590.056 63.8511 591.045 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 63.8511C586.064 63.8511 586.866 63.0476 586.866 62.0564C586.866 61.0652 586.064 60.2617 585.075 60.2617C584.085 60.2617 583.284 61.0652 583.284 62.0564C583.284 63.0476 584.085 63.8511 585.075 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M1062.69 57.8687C1063.68 57.8687 1064.48 57.0652 1064.48 56.074C1064.48 55.0828 1063.68 54.2793 1062.69 54.2793C1061.7 54.2793 1060.9 55.0828 1060.9 56.074C1060.9 57.0652 1061.7 57.8687 1062.69 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 57.8687C1057.71 57.8687 1058.51 57.0652 1058.51 56.074C1058.51 55.0828 1057.71 54.2793 1056.72 54.2793C1055.73 54.2793 1054.93 55.0828 1054.93 56.074C1054.93 57.0652 1055.73 57.8687 1056.72 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1050.75 57.8687C1051.74 57.8687 1052.54 57.0652 1052.54 56.074C1052.54 55.0828 1051.74 54.2793 1050.75 54.2793C1049.76 54.2793 1048.96 55.0828 1048.96 56.074C1048.96 57.0652 1049.76 57.8687 1050.75 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 57.8687C1045.77 57.8687 1046.57 57.0652 1046.57 56.074C1046.57 55.0828 1045.77 54.2793 1044.78 54.2793C1043.79 54.2793 1042.99 55.0828 1042.99 56.074C1042.99 57.0652 1043.79 57.8687 1044.78 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 57.8687C1039.8 57.8687 1040.6 57.0652 1040.6 56.074C1040.6 55.0828 1039.8 54.2793 1038.81 54.2793C1037.82 54.2793 1037.01 55.0828 1037.01 56.074C1037.01 57.0652 1037.82 57.8687 1038.81 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 57.8687C1033.82 57.8687 1034.63 57.0652 1034.63 56.074C1034.63 55.0828 1033.82 54.2793 1032.84 54.2793C1031.85 54.2793 1031.04 55.0828 1031.04 56.074C1031.04 57.0652 1031.85 57.8687 1032.84 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1026.87 57.8687C1027.85 57.8687 1028.66 57.0652 1028.66 56.074C1028.66 55.0828 1027.85 54.2793 1026.87 54.2793C1025.88 54.2793 1025.07 55.0828 1025.07 56.074C1025.07 57.0652 1025.88 57.8687 1026.87 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1020.9 57.8687C1021.88 57.8687 1022.69 57.0652 1022.69 56.074C1022.69 55.0828 1021.88 54.2793 1020.9 54.2793C1019.91 54.2793 1019.1 55.0828 1019.1 56.074C1019.1 57.0652 1019.91 57.8687 1020.9 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1014.93 57.8687C1015.91 57.8687 1016.72 57.0652 1016.72 56.074C1016.72 55.0828 1015.91 54.2793 1014.93 54.2793C1013.94 54.2793 1013.13 55.0828 1013.13 56.074C1013.13 57.0652 1013.94 57.8687 1014.93 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1008.96 57.8687C1009.94 57.8687 1010.75 57.0652 1010.75 56.074C1010.75 55.0828 1009.94 54.2793 1008.96 54.2793C1007.97 54.2793 1007.16 55.0828 1007.16 56.074C1007.16 57.0652 1007.97 57.8687 1008.96 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 57.8687C1003.97 57.8687 1004.78 57.0652 1004.78 56.074C1004.78 55.0828 1003.97 54.2793 1002.99 54.2793C1002 54.2793 1001.19 55.0828 1001.19 56.074C1001.19 57.0652 1002 57.8687 1002.99 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 57.8687C998.004 57.8687 998.806 57.0652 998.806 56.074C998.806 55.0828 998.004 54.2793 997.015 54.2793C996.026 54.2793 995.224 55.0828 995.224 56.074C995.224 57.0652 996.026 57.8687 997.015 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 57.8687C992.034 57.8687 992.836 57.0652 992.836 56.074C992.836 55.0828 992.034 54.2793 991.045 54.2793C990.056 54.2793 989.254 55.0828 989.254 56.074C989.254 57.0652 990.056 57.8687 991.045 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 57.8687C986.064 57.8687 986.866 57.0652 986.866 56.074C986.866 55.0828 986.064 54.2793 985.075 54.2793C984.085 54.2793 983.284 55.0828 983.284 56.074C983.284 57.0652 984.085 57.8687 985.075 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 57.8687C980.094 57.8687 980.896 57.0652 980.896 56.074C980.896 55.0828 980.094 54.2793 979.104 54.2793C978.115 54.2793 977.313 55.0828 977.313 56.074C977.313 57.0652 978.115 57.8687 979.104 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 57.8687C974.123 57.8687 974.925 57.0652 974.925 56.074C974.925 55.0828 974.123 54.2793 973.134 54.2793C972.145 54.2793 971.343 55.0828 971.343 56.074C971.343 57.0652 972.145 57.8687 973.134 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 57.8687C968.153 57.8687 968.955 57.0652 968.955 56.074C968.955 55.0828 968.153 54.2793 967.164 54.2793C966.175 54.2793 965.373 55.0828 965.373 56.074C965.373 57.0652 966.175 57.8687 967.164 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 57.8687C962.183 57.8687 962.985 57.0652 962.985 56.074C962.985 55.0828 962.183 54.2793 961.194 54.2793C960.205 54.2793 959.403 55.0828 959.403 56.074C959.403 57.0652 960.205 57.8687 961.194 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 57.8687C956.213 57.8687 957.015 57.0652 957.015 56.074C957.015 55.0828 956.213 54.2793 955.224 54.2793C954.235 54.2793 953.433 55.0828 953.433 56.074C953.433 57.0652 954.235 57.8687 955.224 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 57.8687C950.243 57.8687 951.045 57.0652 951.045 56.074C951.045 55.0828 950.243 54.2793 949.254 54.2793C948.265 54.2793 947.463 55.0828 947.463 56.074C947.463 57.0652 948.265 57.8687 949.254 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 57.8687C944.273 57.8687 945.075 57.0652 945.075 56.074C945.075 55.0828 944.273 54.2793 943.284 54.2793C942.294 54.2793 941.492 55.0828 941.492 56.074C941.492 57.0652 942.294 57.8687 943.284 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 57.8687C938.303 57.8687 939.105 57.0652 939.105 56.074C939.105 55.0828 938.303 54.2793 937.313 54.2793C936.324 54.2793 935.522 55.0828 935.522 56.074C935.522 57.0652 936.324 57.8687 937.313 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 57.8687C932.332 57.8687 933.134 57.0652 933.134 56.074C933.134 55.0828 932.332 54.2793 931.343 54.2793C930.354 54.2793 929.552 55.0828 929.552 56.074C929.552 57.0652 930.354 57.8687 931.343 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 57.8687C926.362 57.8687 927.164 57.0652 927.164 56.074C927.164 55.0828 926.362 54.2793 925.373 54.2793C924.384 54.2793 923.582 55.0828 923.582 56.074C923.582 57.0652 924.384 57.8687 925.373 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 57.8687C920.392 57.8687 921.194 57.0652 921.194 56.074C921.194 55.0828 920.392 54.2793 919.403 54.2793C918.414 54.2793 917.612 55.0828 917.612 56.074C917.612 57.0652 918.414 57.8687 919.403 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 57.8687C914.422 57.8687 915.224 57.0652 915.224 56.074C915.224 55.0828 914.422 54.2793 913.433 54.2793C912.444 54.2793 911.642 55.0828 911.642 56.074C911.642 57.0652 912.444 57.8687 913.433 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 57.8687C908.452 57.8687 909.254 57.0652 909.254 56.074C909.254 55.0828 908.452 54.2793 907.463 54.2793C906.474 54.2793 905.672 55.0828 905.672 56.074C905.672 57.0652 906.474 57.8687 907.463 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 57.8687C902.482 57.8687 903.284 57.0652 903.284 56.074C903.284 55.0828 902.482 54.2793 901.492 54.2793C900.503 54.2793 899.701 55.0828 899.701 56.074C899.701 57.0652 900.503 57.8687 901.492 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 57.8687C896.512 57.8687 897.313 57.0652 897.313 56.074C897.313 55.0828 896.512 54.2793 895.522 54.2793C894.533 54.2793 893.731 55.0828 893.731 56.074C893.731 57.0652 894.533 57.8687 895.522 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 57.8687C890.541 57.8687 891.343 57.0652 891.343 56.074C891.343 55.0828 890.541 54.2793 889.552 54.2793C888.563 54.2793 887.761 55.0828 887.761 56.074C887.761 57.0652 888.563 57.8687 889.552 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 57.8687C884.571 57.8687 885.373 57.0652 885.373 56.074C885.373 55.0828 884.571 54.2793 883.582 54.2793C882.593 54.2793 881.791 55.0828 881.791 56.074C881.791 57.0652 882.593 57.8687 883.582 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 57.8687C878.601 57.8687 879.403 57.0652 879.403 56.074C879.403 55.0828 878.601 54.2793 877.612 54.2793C876.623 54.2793 875.821 55.0828 875.821 56.074C875.821 57.0652 876.623 57.8687 877.612 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 57.8687C872.631 57.8687 873.433 57.0652 873.433 56.074C873.433 55.0828 872.631 54.2793 871.642 54.2793C870.653 54.2793 869.851 55.0828 869.851 56.074C869.851 57.0652 870.653 57.8687 871.642 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 57.8687C866.661 57.8687 867.463 57.0652 867.463 56.074C867.463 55.0828 866.661 54.2793 865.672 54.2793C864.683 54.2793 863.881 55.0828 863.881 56.074C863.881 57.0652 864.683 57.8687 865.672 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 57.8687C860.691 57.8687 861.493 57.0652 861.493 56.074C861.493 55.0828 860.691 54.2793 859.701 54.2793C858.712 54.2793 857.91 55.0828 857.91 56.074C857.91 57.0652 858.712 57.8687 859.701 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 57.8687C854.721 57.8687 855.522 57.0652 855.522 56.074C855.522 55.0828 854.721 54.2793 853.731 54.2793C852.742 54.2793 851.94 55.0828 851.94 56.074C851.94 57.0652 852.742 57.8687 853.731 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 57.8687C848.75 57.8687 849.552 57.0652 849.552 56.074C849.552 55.0828 848.75 54.2793 847.761 54.2793C846.772 54.2793 845.97 55.0828 845.97 56.074C845.97 57.0652 846.772 57.8687 847.761 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 57.8687C842.78 57.8687 843.582 57.0652 843.582 56.074C843.582 55.0828 842.78 54.2793 841.791 54.2793C840.802 54.2793 840 55.0828 840 56.074C840 57.0652 840.802 57.8687 841.791 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 57.8687C836.81 57.8687 837.612 57.0652 837.612 56.074C837.612 55.0828 836.81 54.2793 835.821 54.2793C834.832 54.2793 834.03 55.0828 834.03 56.074C834.03 57.0652 834.832 57.8687 835.821 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 57.8687C830.84 57.8687 831.642 57.0652 831.642 56.074C831.642 55.0828 830.84 54.2793 829.851 54.2793C828.862 54.2793 828.06 55.0828 828.06 56.074C828.06 57.0652 828.862 57.8687 829.851 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 57.8687C824.87 57.8687 825.672 57.0652 825.672 56.074C825.672 55.0828 824.87 54.2793 823.881 54.2793C822.891 54.2793 822.09 55.0828 822.09 56.074C822.09 57.0652 822.891 57.8687 823.881 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 57.8687C818.9 57.8687 819.701 57.0652 819.701 56.074C819.701 55.0828 818.9 54.2793 817.91 54.2793C816.921 54.2793 816.119 55.0828 816.119 56.074C816.119 57.0652 816.921 57.8687 817.91 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 57.8687C812.929 57.8687 813.731 57.0652 813.731 56.074C813.731 55.0828 812.929 54.2793 811.94 54.2793C810.951 54.2793 810.149 55.0828 810.149 56.074C810.149 57.0652 810.951 57.8687 811.94 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 57.8687C806.959 57.8687 807.761 57.0652 807.761 56.074C807.761 55.0828 806.959 54.2793 805.97 54.2793C804.981 54.2793 804.179 55.0828 804.179 56.074C804.179 57.0652 804.981 57.8687 805.97 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M800 57.8687C800.989 57.8687 801.791 57.0652 801.791 56.074C801.791 55.0828 800.989 54.2793 800 54.2793C799.011 54.2793 798.209 55.0828 798.209 56.074C798.209 57.0652 799.011 57.8687 800 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 57.8687C795.019 57.8687 795.821 57.0652 795.821 56.074C795.821 55.0828 795.019 54.2793 794.03 54.2793C793.041 54.2793 792.239 55.0828 792.239 56.074C792.239 57.0652 793.041 57.8687 794.03 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 57.8687C789.049 57.8687 789.851 57.0652 789.851 56.074C789.851 55.0828 789.049 54.2793 788.06 54.2793C787.071 54.2793 786.269 55.0828 786.269 56.074C786.269 57.0652 787.071 57.8687 788.06 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 57.8687C783.079 57.8687 783.881 57.0652 783.881 56.074C783.881 55.0828 783.079 54.2793 782.09 54.2793C781.1 54.2793 780.299 55.0828 780.299 56.074C780.299 57.0652 781.1 57.8687 782.09 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 57.8687C777.109 57.8687 777.91 57.0652 777.91 56.074C777.91 55.0828 777.109 54.2793 776.119 54.2793C775.13 54.2793 774.328 55.0828 774.328 56.074C774.328 57.0652 775.13 57.8687 776.119 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 57.8687C771.138 57.8687 771.94 57.0652 771.94 56.074C771.94 55.0828 771.138 54.2793 770.149 54.2793C769.16 54.2793 768.358 55.0828 768.358 56.074C768.358 57.0652 769.16 57.8687 770.149 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 57.8687C765.168 57.8687 765.97 57.0652 765.97 56.074C765.97 55.0828 765.168 54.2793 764.179 54.2793C763.19 54.2793 762.388 55.0828 762.388 56.074C762.388 57.0652 763.19 57.8687 764.179 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 57.8687C759.198 57.8687 760 57.0652 760 56.074C760 55.0828 759.198 54.2793 758.209 54.2793C757.22 54.2793 756.418 55.0828 756.418 56.074C756.418 57.0652 757.22 57.8687 758.209 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 57.8687C753.228 57.8687 754.03 57.0652 754.03 56.074C754.03 55.0828 753.228 54.2793 752.239 54.2793C751.25 54.2793 750.448 55.0828 750.448 56.074C750.448 57.0652 751.25 57.8687 752.239 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 57.8687C747.258 57.8687 748.06 57.0652 748.06 56.074C748.06 55.0828 747.258 54.2793 746.269 54.2793C745.279 54.2793 744.478 55.0828 744.478 56.074C744.478 57.0652 745.279 57.8687 746.269 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 57.8687C741.288 57.8687 742.09 57.0652 742.09 56.074C742.09 55.0828 741.288 54.2793 740.299 54.2793C739.309 54.2793 738.507 55.0828 738.507 56.074C738.507 57.0652 739.309 57.8687 740.299 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 57.8687C735.318 57.8687 736.119 57.0652 736.119 56.074C736.119 55.0828 735.318 54.2793 734.328 54.2793C733.339 54.2793 732.537 55.0828 732.537 56.074C732.537 57.0652 733.339 57.8687 734.328 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M728.358 57.8687C729.347 57.8687 730.149 57.0652 730.149 56.074C730.149 55.0828 729.347 54.2793 728.358 54.2793C727.369 54.2793 726.567 55.0828 726.567 56.074C726.567 57.0652 727.369 57.8687 728.358 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M722.388 57.8687C723.377 57.8687 724.179 57.0652 724.179 56.074C724.179 55.0828 723.377 54.2793 722.388 54.2793C721.399 54.2793 720.597 55.0828 720.597 56.074C720.597 57.0652 721.399 57.8687 722.388 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M716.418 57.8687C717.407 57.8687 718.209 57.0652 718.209 56.074C718.209 55.0828 717.407 54.2793 716.418 54.2793C715.429 54.2793 714.627 55.0828 714.627 56.074C714.627 57.0652 715.429 57.8687 716.418 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M710.448 57.8687C711.437 57.8687 712.239 57.0652 712.239 56.074C712.239 55.0828 711.437 54.2793 710.448 54.2793C709.459 54.2793 708.657 55.0828 708.657 56.074C708.657 57.0652 709.459 57.8687 710.448 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M704.478 57.8687C705.467 57.8687 706.269 57.0652 706.269 56.074C706.269 55.0828 705.467 54.2793 704.478 54.2793C703.488 54.2793 702.687 55.0828 702.687 56.074C702.687 57.0652 703.488 57.8687 704.478 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M698.507 57.8687C699.497 57.8687 700.299 57.0652 700.299 56.074C700.299 55.0828 699.497 54.2793 698.507 54.2793C697.518 54.2793 696.716 55.0828 696.716 56.074C696.716 57.0652 697.518 57.8687 698.507 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M692.537 57.8687C693.526 57.8687 694.328 57.0652 694.328 56.074C694.328 55.0828 693.526 54.2793 692.537 54.2793C691.548 54.2793 690.746 55.0828 690.746 56.074C690.746 57.0652 691.548 57.8687 692.537 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M674.627 57.8687C675.616 57.8687 676.418 57.0652 676.418 56.074C676.418 55.0828 675.616 54.2793 674.627 54.2793C673.638 54.2793 672.836 55.0828 672.836 56.074C672.836 57.0652 673.638 57.8687 674.627 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 57.8687C657.706 57.8687 658.507 57.0652 658.507 56.074C658.507 55.0828 657.706 54.2793 656.716 54.2793C655.727 54.2793 654.925 55.0828 654.925 56.074C654.925 57.0652 655.727 57.8687 656.716 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M650.746 57.8687C651.735 57.8687 652.537 57.0652 652.537 56.074C652.537 55.0828 651.735 54.2793 650.746 54.2793C649.757 54.2793 648.955 55.0828 648.955 56.074C648.955 57.0652 649.757 57.8687 650.746 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M644.776 57.8687C645.765 57.8687 646.567 57.0652 646.567 56.074C646.567 55.0828 645.765 54.2793 644.776 54.2793C643.787 54.2793 642.985 55.0828 642.985 56.074C642.985 57.0652 643.787 57.8687 644.776 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 57.8687C639.795 57.8687 640.597 57.0652 640.597 56.074C640.597 55.0828 639.795 54.2793 638.806 54.2793C637.817 54.2793 637.015 55.0828 637.015 56.074C637.015 57.0652 637.817 57.8687 638.806 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 57.8687C633.825 57.8687 634.627 57.0652 634.627 56.074C634.627 55.0828 633.825 54.2793 632.836 54.2793C631.847 54.2793 631.045 55.0828 631.045 56.074C631.045 57.0652 631.847 57.8687 632.836 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 57.8687C627.855 57.8687 628.657 57.0652 628.657 56.074C628.657 55.0828 627.855 54.2793 626.866 54.2793C625.876 54.2793 625.075 55.0828 625.075 56.074C625.075 57.0652 625.876 57.8687 626.866 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 57.8687C621.885 57.8687 622.687 57.0652 622.687 56.074C622.687 55.0828 621.885 54.2793 620.895 54.2793C619.906 54.2793 619.104 55.0828 619.104 56.074C619.104 57.0652 619.906 57.8687 620.895 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 57.8687C615.915 57.8687 616.716 57.0652 616.716 56.074C616.716 55.0828 615.915 54.2793 614.925 54.2793C613.936 54.2793 613.134 55.0828 613.134 56.074C613.134 57.0652 613.936 57.8687 614.925 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 57.8687C609.944 57.8687 610.746 57.0652 610.746 56.074C610.746 55.0828 609.944 54.2793 608.955 54.2793C607.966 54.2793 607.164 55.0828 607.164 56.074C607.164 57.0652 607.966 57.8687 608.955 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 57.8687C603.974 57.8687 604.776 57.0652 604.776 56.074C604.776 55.0828 603.974 54.2793 602.985 54.2793C601.996 54.2793 601.194 55.0828 601.194 56.074C601.194 57.0652 601.996 57.8687 602.985 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 57.8687C598.004 57.8687 598.806 57.0652 598.806 56.074C598.806 55.0828 598.004 54.2793 597.015 54.2793C596.026 54.2793 595.224 55.0828 595.224 56.074C595.224 57.0652 596.026 57.8687 597.015 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 57.8687C592.034 57.8687 592.836 57.0652 592.836 56.074C592.836 55.0828 592.034 54.2793 591.045 54.2793C590.056 54.2793 589.254 55.0828 589.254 56.074C589.254 57.0652 590.056 57.8687 591.045 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M1032.84 51.8865C1033.82 51.8865 1034.63 51.083 1034.63 50.0918C1034.63 49.1006 1033.82 48.2971 1032.84 48.2971C1031.85 48.2971 1031.04 49.1006 1031.04 50.0918C1031.04 51.083 1031.85 51.8865 1032.84 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M997.015 51.8865C998.004 51.8865 998.806 51.083 998.806 50.0918C998.806 49.1006 998.004 48.2971 997.015 48.2971C996.026 48.2971 995.224 49.1006 995.224 50.0918C995.224 51.083 996.026 51.8865 997.015 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 51.8865C992.034 51.8865 992.836 51.083 992.836 50.0918C992.836 49.1006 992.034 48.2971 991.045 48.2971C990.056 48.2971 989.254 49.1006 989.254 50.0918C989.254 51.083 990.056 51.8865 991.045 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M985.075 51.8865C986.064 51.8865 986.866 51.083 986.866 50.0918C986.866 49.1006 986.064 48.2971 985.075 48.2971C984.085 48.2971 983.284 49.1006 983.284 50.0918C983.284 51.083 984.085 51.8865 985.075 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M979.104 51.8865C980.094 51.8865 980.896 51.083 980.896 50.0918C980.896 49.1006 980.094 48.2971 979.104 48.2971C978.115 48.2971 977.313 49.1006 977.313 50.0918C977.313 51.083 978.115 51.8865 979.104 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 51.8865C974.123 51.8865 974.925 51.083 974.925 50.0918C974.925 49.1006 974.123 48.2971 973.134 48.2971C972.145 48.2971 971.343 49.1006 971.343 50.0918C971.343 51.083 972.145 51.8865 973.134 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M967.164 51.8865C968.153 51.8865 968.955 51.083 968.955 50.0918C968.955 49.1006 968.153 48.2971 967.164 48.2971C966.175 48.2971 965.373 49.1006 965.373 50.0918C965.373 51.083 966.175 51.8865 967.164 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M961.194 51.8865C962.183 51.8865 962.985 51.083 962.985 50.0918C962.985 49.1006 962.183 48.2971 961.194 48.2971C960.205 48.2971 959.403 49.1006 959.403 50.0918C959.403 51.083 960.205 51.8865 961.194 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 51.8865C956.213 51.8865 957.015 51.083 957.015 50.0918C957.015 49.1006 956.213 48.2971 955.224 48.2971C954.235 48.2971 953.433 49.1006 953.433 50.0918C953.433 51.083 954.235 51.8865 955.224 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 51.8865C950.243 51.8865 951.045 51.083 951.045 50.0918C951.045 49.1006 950.243 48.2971 949.254 48.2971C948.265 48.2971 947.463 49.1006 947.463 50.0918C947.463 51.083 948.265 51.8865 949.254 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 51.8865C944.273 51.8865 945.075 51.083 945.075 50.0918C945.075 49.1006 944.273 48.2971 943.284 48.2971C942.294 48.2971 941.492 49.1006 941.492 50.0918C941.492 51.083 942.294 51.8865 943.284 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 51.8865C938.303 51.8865 939.105 51.083 939.105 50.0918C939.105 49.1006 938.303 48.2971 937.313 48.2971C936.324 48.2971 935.522 49.1006 935.522 50.0918C935.522 51.083 936.324 51.8865 937.313 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 51.8865C932.332 51.8865 933.134 51.083 933.134 50.0918C933.134 49.1006 932.332 48.2971 931.343 48.2971C930.354 48.2971 929.552 49.1006 929.552 50.0918C929.552 51.083 930.354 51.8865 931.343 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 51.8865C926.362 51.8865 927.164 51.083 927.164 50.0918C927.164 49.1006 926.362 48.2971 925.373 48.2971C924.384 48.2971 923.582 49.1006 923.582 50.0918C923.582 51.083 924.384 51.8865 925.373 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M919.403 51.8865C920.392 51.8865 921.194 51.083 921.194 50.0918C921.194 49.1006 920.392 48.2971 919.403 48.2971C918.414 48.2971 917.612 49.1006 917.612 50.0918C917.612 51.083 918.414 51.8865 919.403 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M913.433 51.8865C914.422 51.8865 915.224 51.083 915.224 50.0918C915.224 49.1006 914.422 48.2971 913.433 48.2971C912.444 48.2971 911.642 49.1006 911.642 50.0918C911.642 51.083 912.444 51.8865 913.433 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M907.463 51.8865C908.452 51.8865 909.254 51.083 909.254 50.0918C909.254 49.1006 908.452 48.2971 907.463 48.2971C906.474 48.2971 905.672 49.1006 905.672 50.0918C905.672 51.083 906.474 51.8865 907.463 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 51.8865C902.482 51.8865 903.284 51.083 903.284 50.0918C903.284 49.1006 902.482 48.2971 901.492 48.2971C900.503 48.2971 899.701 49.1006 899.701 50.0918C899.701 51.083 900.503 51.8865 901.492 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 51.8865C896.512 51.8865 897.313 51.083 897.313 50.0918C897.313 49.1006 896.512 48.2971 895.522 48.2971C894.533 48.2971 893.731 49.1006 893.731 50.0918C893.731 51.083 894.533 51.8865 895.522 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 51.8865C890.541 51.8865 891.343 51.083 891.343 50.0918C891.343 49.1006 890.541 48.2971 889.552 48.2971C888.563 48.2971 887.761 49.1006 887.761 50.0918C887.761 51.083 888.563 51.8865 889.552 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 51.8865C884.571 51.8865 885.373 51.083 885.373 50.0918C885.373 49.1006 884.571 48.2971 883.582 48.2971C882.593 48.2971 881.791 49.1006 881.791 50.0918C881.791 51.083 882.593 51.8865 883.582 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 51.8865C878.601 51.8865 879.403 51.083 879.403 50.0918C879.403 49.1006 878.601 48.2971 877.612 48.2971C876.623 48.2971 875.821 49.1006 875.821 50.0918C875.821 51.083 876.623 51.8865 877.612 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 51.8865C872.631 51.8865 873.433 51.083 873.433 50.0918C873.433 49.1006 872.631 48.2971 871.642 48.2971C870.653 48.2971 869.851 49.1006 869.851 50.0918C869.851 51.083 870.653 51.8865 871.642 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 51.8865C866.661 51.8865 867.463 51.083 867.463 50.0918C867.463 49.1006 866.661 48.2971 865.672 48.2971C864.683 48.2971 863.881 49.1006 863.881 50.0918C863.881 51.083 864.683 51.8865 865.672 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 51.8865C860.691 51.8865 861.493 51.083 861.493 50.0918C861.493 49.1006 860.691 48.2971 859.701 48.2971C858.712 48.2971 857.91 49.1006 857.91 50.0918C857.91 51.083 858.712 51.8865 859.701 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 51.8865C854.721 51.8865 855.522 51.083 855.522 50.0918C855.522 49.1006 854.721 48.2971 853.731 48.2971C852.742 48.2971 851.94 49.1006 851.94 50.0918C851.94 51.083 852.742 51.8865 853.731 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 51.8865C848.75 51.8865 849.552 51.083 849.552 50.0918C849.552 49.1006 848.75 48.2971 847.761 48.2971C846.772 48.2971 845.97 49.1006 845.97 50.0918C845.97 51.083 846.772 51.8865 847.761 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 51.8865C842.78 51.8865 843.582 51.083 843.582 50.0918C843.582 49.1006 842.78 48.2971 841.791 48.2971C840.802 48.2971 840 49.1006 840 50.0918C840 51.083 840.802 51.8865 841.791 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 51.8865C836.81 51.8865 837.612 51.083 837.612 50.0918C837.612 49.1006 836.81 48.2971 835.821 48.2971C834.832 48.2971 834.03 49.1006 834.03 50.0918C834.03 51.083 834.832 51.8865 835.821 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 51.8865C830.84 51.8865 831.642 51.083 831.642 50.0918C831.642 49.1006 830.84 48.2971 829.851 48.2971C828.862 48.2971 828.06 49.1006 828.06 50.0918C828.06 51.083 828.862 51.8865 829.851 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 51.8865C824.87 51.8865 825.672 51.083 825.672 50.0918C825.672 49.1006 824.87 48.2971 823.881 48.2971C822.891 48.2971 822.09 49.1006 822.09 50.0918C822.09 51.083 822.891 51.8865 823.881 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 51.8865C818.9 51.8865 819.701 51.083 819.701 50.0918C819.701 49.1006 818.9 48.2971 817.91 48.2971C816.921 48.2971 816.119 49.1006 816.119 50.0918C816.119 51.083 816.921 51.8865 817.91 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 51.8865C812.929 51.8865 813.731 51.083 813.731 50.0918C813.731 49.1006 812.929 48.2971 811.94 48.2971C810.951 48.2971 810.149 49.1006 810.149 50.0918C810.149 51.083 810.951 51.8865 811.94 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 51.8865C806.959 51.8865 807.761 51.083 807.761 50.0918C807.761 49.1006 806.959 48.2971 805.97 48.2971C804.981 48.2971 804.179 49.1006 804.179 50.0918C804.179 51.083 804.981 51.8865 805.97 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M800 51.8865C800.989 51.8865 801.791 51.083 801.791 50.0918C801.791 49.1006 800.989 48.2971 800 48.2971C799.011 48.2971 798.209 49.1006 798.209 50.0918C798.209 51.083 799.011 51.8865 800 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 51.8865C795.019 51.8865 795.821 51.083 795.821 50.0918C795.821 49.1006 795.019 48.2971 794.03 48.2971C793.041 48.2971 792.239 49.1006 792.239 50.0918C792.239 51.083 793.041 51.8865 794.03 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 51.8865C789.049 51.8865 789.851 51.083 789.851 50.0918C789.851 49.1006 789.049 48.2971 788.06 48.2971C787.071 48.2971 786.269 49.1006 786.269 50.0918C786.269 51.083 787.071 51.8865 788.06 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 51.8865C783.079 51.8865 783.881 51.083 783.881 50.0918C783.881 49.1006 783.079 48.2971 782.09 48.2971C781.1 48.2971 780.299 49.1006 780.299 50.0918C780.299 51.083 781.1 51.8865 782.09 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 51.8865C777.109 51.8865 777.91 51.083 777.91 50.0918C777.91 49.1006 777.109 48.2971 776.119 48.2971C775.13 48.2971 774.328 49.1006 774.328 50.0918C774.328 51.083 775.13 51.8865 776.119 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 51.8865C771.138 51.8865 771.94 51.083 771.94 50.0918C771.94 49.1006 771.138 48.2971 770.149 48.2971C769.16 48.2971 768.358 49.1006 768.358 50.0918C768.358 51.083 769.16 51.8865 770.149 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 51.8865C765.168 51.8865 765.97 51.083 765.97 50.0918C765.97 49.1006 765.168 48.2971 764.179 48.2971C763.19 48.2971 762.388 49.1006 762.388 50.0918C762.388 51.083 763.19 51.8865 764.179 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 51.8865C759.198 51.8865 760 51.083 760 50.0918C760 49.1006 759.198 48.2971 758.209 48.2971C757.22 48.2971 756.418 49.1006 756.418 50.0918C756.418 51.083 757.22 51.8865 758.209 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 51.8865C753.228 51.8865 754.03 51.083 754.03 50.0918C754.03 49.1006 753.228 48.2971 752.239 48.2971C751.25 48.2971 750.448 49.1006 750.448 50.0918C750.448 51.083 751.25 51.8865 752.239 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 51.8865C747.258 51.8865 748.06 51.083 748.06 50.0918C748.06 49.1006 747.258 48.2971 746.269 48.2971C745.279 48.2971 744.478 49.1006 744.478 50.0918C744.478 51.083 745.279 51.8865 746.269 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 51.8865C741.288 51.8865 742.09 51.083 742.09 50.0918C742.09 49.1006 741.288 48.2971 740.299 48.2971C739.309 48.2971 738.507 49.1006 738.507 50.0918C738.507 51.083 739.309 51.8865 740.299 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 51.8865C735.318 51.8865 736.119 51.083 736.119 50.0918C736.119 49.1006 735.318 48.2971 734.328 48.2971C733.339 48.2971 732.537 49.1006 732.537 50.0918C732.537 51.083 733.339 51.8865 734.328 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M632.836 51.8865C633.825 51.8865 634.627 51.083 634.627 50.0918C634.627 49.1006 633.825 48.2971 632.836 48.2971C631.847 48.2971 631.045 49.1006 631.045 50.0918C631.045 51.083 631.847 51.8865 632.836 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M626.866 51.8865C627.855 51.8865 628.657 51.083 628.657 50.0918C628.657 49.1006 627.855 48.2971 626.866 48.2971C625.876 48.2971 625.075 49.1006 625.075 50.0918C625.075 51.083 625.876 51.8865 626.866 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 51.8865C621.885 51.8865 622.687 51.083 622.687 50.0918C622.687 49.1006 621.885 48.2971 620.895 48.2971C619.906 48.2971 619.104 49.1006 619.104 50.0918C619.104 51.083 619.906 51.8865 620.895 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M614.925 51.8865C615.915 51.8865 616.716 51.083 616.716 50.0918C616.716 49.1006 615.915 48.2971 614.925 48.2971C613.936 48.2971 613.134 49.1006 613.134 50.0918C613.134 51.083 613.936 51.8865 614.925 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M608.955 51.8865C609.944 51.8865 610.746 51.083 610.746 50.0918C610.746 49.1006 609.944 48.2971 608.955 48.2971C607.966 48.2971 607.164 49.1006 607.164 50.0918C607.164 51.083 607.966 51.8865 608.955 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 51.8865C603.974 51.8865 604.776 51.083 604.776 50.0918C604.776 49.1006 603.974 48.2971 602.985 48.2971C601.996 48.2971 601.194 49.1006 601.194 50.0918C601.194 51.083 601.996 51.8865 602.985 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M955.224 45.9041C956.213 45.9041 957.015 45.1006 957.015 44.1094C957.015 43.1182 956.213 42.3147 955.224 42.3147C954.235 42.3147 953.433 43.1182 953.433 44.1094C953.433 45.1006 954.235 45.9041 955.224 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M949.254 45.9041C950.243 45.9041 951.045 45.1006 951.045 44.1094C951.045 43.1182 950.243 42.3147 949.254 42.3147C948.265 42.3147 947.463 43.1182 947.463 44.1094C947.463 45.1006 948.265 45.9041 949.254 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M943.284 45.9041C944.273 45.9041 945.075 45.1006 945.075 44.1094C945.075 43.1182 944.273 42.3147 943.284 42.3147C942.294 42.3147 941.492 43.1182 941.492 44.1094C941.492 45.1006 942.294 45.9041 943.284 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 45.9041C938.303 45.9041 939.105 45.1006 939.105 44.1094C939.105 43.1182 938.303 42.3147 937.313 42.3147C936.324 42.3147 935.522 43.1182 935.522 44.1094C935.522 45.1006 936.324 45.9041 937.313 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 45.9041C932.332 45.9041 933.134 45.1006 933.134 44.1094C933.134 43.1182 932.332 42.3147 931.343 42.3147C930.354 42.3147 929.552 43.1182 929.552 44.1094C929.552 45.1006 930.354 45.9041 931.343 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M901.492 45.9041C902.482 45.9041 903.284 45.1006 903.284 44.1094C903.284 43.1182 902.482 42.3147 901.492 42.3147C900.503 42.3147 899.701 43.1182 899.701 44.1094C899.701 45.1006 900.503 45.9041 901.492 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M895.522 45.9041C896.512 45.9041 897.313 45.1006 897.313 44.1094C897.313 43.1182 896.512 42.3147 895.522 42.3147C894.533 42.3147 893.731 43.1182 893.731 44.1094C893.731 45.1006 894.533 45.9041 895.522 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 45.9041C890.541 45.9041 891.343 45.1006 891.343 44.1094C891.343 43.1182 890.541 42.3147 889.552 42.3147C888.563 42.3147 887.761 43.1182 887.761 44.1094C887.761 45.1006 888.563 45.9041 889.552 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 45.9041C884.571 45.9041 885.373 45.1006 885.373 44.1094C885.373 43.1182 884.571 42.3147 883.582 42.3147C882.593 42.3147 881.791 43.1182 881.791 44.1094C881.791 45.1006 882.593 45.9041 883.582 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M877.612 45.9041C878.601 45.9041 879.403 45.1006 879.403 44.1094C879.403 43.1182 878.601 42.3147 877.612 42.3147C876.623 42.3147 875.821 43.1182 875.821 44.1094C875.821 45.1006 876.623 45.9041 877.612 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M871.642 45.9041C872.631 45.9041 873.433 45.1006 873.433 44.1094C873.433 43.1182 872.631 42.3147 871.642 42.3147C870.653 42.3147 869.851 43.1182 869.851 44.1094C869.851 45.1006 870.653 45.9041 871.642 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 45.9041C866.661 45.9041 867.463 45.1006 867.463 44.1094C867.463 43.1182 866.661 42.3147 865.672 42.3147C864.683 42.3147 863.881 43.1182 863.881 44.1094C863.881 45.1006 864.683 45.9041 865.672 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 45.9041C860.691 45.9041 861.493 45.1006 861.493 44.1094C861.493 43.1182 860.691 42.3147 859.701 42.3147C858.712 42.3147 857.91 43.1182 857.91 44.1094C857.91 45.1006 858.712 45.9041 859.701 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 45.9041C854.721 45.9041 855.522 45.1006 855.522 44.1094C855.522 43.1182 854.721 42.3147 853.731 42.3147C852.742 42.3147 851.94 43.1182 851.94 44.1094C851.94 45.1006 852.742 45.9041 853.731 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 45.9041C848.75 45.9041 849.552 45.1006 849.552 44.1094C849.552 43.1182 848.75 42.3147 847.761 42.3147C846.772 42.3147 845.97 43.1182 845.97 44.1094C845.97 45.1006 846.772 45.9041 847.761 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 45.9041C842.78 45.9041 843.582 45.1006 843.582 44.1094C843.582 43.1182 842.78 42.3147 841.791 42.3147C840.802 42.3147 840 43.1182 840 44.1094C840 45.1006 840.802 45.9041 841.791 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 45.9041C836.81 45.9041 837.612 45.1006 837.612 44.1094C837.612 43.1182 836.81 42.3147 835.821 42.3147C834.832 42.3147 834.03 43.1182 834.03 44.1094C834.03 45.1006 834.832 45.9041 835.821 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 45.9041C830.84 45.9041 831.642 45.1006 831.642 44.1094C831.642 43.1182 830.84 42.3147 829.851 42.3147C828.862 42.3147 828.06 43.1182 828.06 44.1094C828.06 45.1006 828.862 45.9041 829.851 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 45.9041C824.87 45.9041 825.672 45.1006 825.672 44.1094C825.672 43.1182 824.87 42.3147 823.881 42.3147C822.891 42.3147 822.09 43.1182 822.09 44.1094C822.09 45.1006 822.891 45.9041 823.881 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 45.9041C818.9 45.9041 819.701 45.1006 819.701 44.1094C819.701 43.1182 818.9 42.3147 817.91 42.3147C816.921 42.3147 816.119 43.1182 816.119 44.1094C816.119 45.1006 816.921 45.9041 817.91 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 45.9041C812.929 45.9041 813.731 45.1006 813.731 44.1094C813.731 43.1182 812.929 42.3147 811.94 42.3147C810.951 42.3147 810.149 43.1182 810.149 44.1094C810.149 45.1006 810.951 45.9041 811.94 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 45.9041C806.959 45.9041 807.761 45.1006 807.761 44.1094C807.761 43.1182 806.959 42.3147 805.97 42.3147C804.981 42.3147 804.179 43.1182 804.179 44.1094C804.179 45.1006 804.981 45.9041 805.97 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M800 45.9041C800.989 45.9041 801.791 45.1006 801.791 44.1094C801.791 43.1182 800.989 42.3147 800 42.3147C799.011 42.3147 798.209 43.1182 798.209 44.1094C798.209 45.1006 799.011 45.9041 800 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 45.9041C795.019 45.9041 795.821 45.1006 795.821 44.1094C795.821 43.1182 795.019 42.3147 794.03 42.3147C793.041 42.3147 792.239 43.1182 792.239 44.1094C792.239 45.1006 793.041 45.9041 794.03 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 45.9041C789.049 45.9041 789.851 45.1006 789.851 44.1094C789.851 43.1182 789.049 42.3147 788.06 42.3147C787.071 42.3147 786.269 43.1182 786.269 44.1094C786.269 45.1006 787.071 45.9041 788.06 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 45.9041C783.079 45.9041 783.881 45.1006 783.881 44.1094C783.881 43.1182 783.079 42.3147 782.09 42.3147C781.1 42.3147 780.299 43.1182 780.299 44.1094C780.299 45.1006 781.1 45.9041 782.09 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 45.9041C777.109 45.9041 777.91 45.1006 777.91 44.1094C777.91 43.1182 777.109 42.3147 776.119 42.3147C775.13 42.3147 774.328 43.1182 774.328 44.1094C774.328 45.1006 775.13 45.9041 776.119 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 45.9041C771.138 45.9041 771.94 45.1006 771.94 44.1094C771.94 43.1182 771.138 42.3147 770.149 42.3147C769.16 42.3147 768.358 43.1182 768.358 44.1094C768.358 45.1006 769.16 45.9041 770.149 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M764.179 45.9041C765.168 45.9041 765.97 45.1006 765.97 44.1094C765.97 43.1182 765.168 42.3147 764.179 42.3147C763.19 42.3147 762.388 43.1182 762.388 44.1094C762.388 45.1006 763.19 45.9041 764.179 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M758.209 45.9041C759.198 45.9041 760 45.1006 760 44.1094C760 43.1182 759.198 42.3147 758.209 42.3147C757.22 42.3147 756.418 43.1182 756.418 44.1094C756.418 45.1006 757.22 45.9041 758.209 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M752.239 45.9041C753.228 45.9041 754.03 45.1006 754.03 44.1094C754.03 43.1182 753.228 42.3147 752.239 42.3147C751.25 42.3147 750.448 43.1182 750.448 44.1094C750.448 45.1006 751.25 45.9041 752.239 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M740.299 45.9041C741.288 45.9041 742.09 45.1006 742.09 44.1094C742.09 43.1182 741.288 42.3147 740.299 42.3147C739.309 42.3147 738.507 43.1182 738.507 44.1094C738.507 45.1006 739.309 45.9041 740.299 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M734.328 45.9041C735.318 45.9041 736.119 45.1006 736.119 44.1094C736.119 43.1182 735.318 42.3147 734.328 42.3147C733.339 42.3147 732.537 43.1182 732.537 44.1094C732.537 45.1006 733.339 45.9041 734.328 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M889.552 39.9219C890.541 39.9219 891.343 39.1184 891.343 38.1272C891.343 37.136 890.541 36.3325 889.552 36.3325C888.563 36.3325 887.761 37.136 887.761 38.1272C887.761 39.1184 888.563 39.9219 889.552 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M883.582 39.9219C884.571 39.9219 885.373 39.1184 885.373 38.1272C885.373 37.136 884.571 36.3325 883.582 36.3325C882.593 36.3325 881.791 37.136 881.791 38.1272C881.791 39.1184 882.593 39.9219 883.582 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M865.672 39.9219C866.661 39.9219 867.463 39.1184 867.463 38.1272C867.463 37.136 866.661 36.3325 865.672 36.3325C864.683 36.3325 863.881 37.136 863.881 38.1272C863.881 39.1184 864.683 39.9219 865.672 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M859.701 39.9219C860.691 39.9219 861.493 39.1184 861.493 38.1272C861.493 37.136 860.691 36.3325 859.701 36.3325C858.712 36.3325 857.91 37.136 857.91 38.1272C857.91 39.1184 858.712 39.9219 859.701 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M853.731 39.9219C854.721 39.9219 855.522 39.1184 855.522 38.1272C855.522 37.136 854.721 36.3325 853.731 36.3325C852.742 36.3325 851.94 37.136 851.94 38.1272C851.94 39.1184 852.742 39.9219 853.731 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M847.761 39.9219C848.75 39.9219 849.552 39.1184 849.552 38.1272C849.552 37.136 848.75 36.3325 847.761 36.3325C846.772 36.3325 845.97 37.136 845.97 38.1272C845.97 39.1184 846.772 39.9219 847.761 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 39.9219C842.78 39.9219 843.582 39.1184 843.582 38.1272C843.582 37.136 842.78 36.3325 841.791 36.3325C840.802 36.3325 840 37.136 840 38.1272C840 39.1184 840.802 39.9219 841.791 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 39.9219C836.81 39.9219 837.612 39.1184 837.612 38.1272C837.612 37.136 836.81 36.3325 835.821 36.3325C834.832 36.3325 834.03 37.136 834.03 38.1272C834.03 39.1184 834.832 39.9219 835.821 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 39.9219C830.84 39.9219 831.642 39.1184 831.642 38.1272C831.642 37.136 830.84 36.3325 829.851 36.3325C828.862 36.3325 828.06 37.136 828.06 38.1272C828.06 39.1184 828.862 39.9219 829.851 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 39.9219C824.87 39.9219 825.672 39.1184 825.672 38.1272C825.672 37.136 824.87 36.3325 823.881 36.3325C822.891 36.3325 822.09 37.136 822.09 38.1272C822.09 39.1184 822.891 39.9219 823.881 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 39.9219C818.9 39.9219 819.701 39.1184 819.701 38.1272C819.701 37.136 818.9 36.3325 817.91 36.3325C816.921 36.3325 816.119 37.136 816.119 38.1272C816.119 39.1184 816.921 39.9219 817.91 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 39.9219C812.929 39.9219 813.731 39.1184 813.731 38.1272C813.731 37.136 812.929 36.3325 811.94 36.3325C810.951 36.3325 810.149 37.136 810.149 38.1272C810.149 39.1184 810.951 39.9219 811.94 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 39.9219C806.959 39.9219 807.761 39.1184 807.761 38.1272C807.761 37.136 806.959 36.3325 805.97 36.3325C804.981 36.3325 804.179 37.136 804.179 38.1272C804.179 39.1184 804.981 39.9219 805.97 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M800 39.9219C800.989 39.9219 801.791 39.1184 801.791 38.1272C801.791 37.136 800.989 36.3325 800 36.3325C799.011 36.3325 798.209 37.136 798.209 38.1272C798.209 39.1184 799.011 39.9219 800 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 39.9219C795.019 39.9219 795.821 39.1184 795.821 38.1272C795.821 37.136 795.019 36.3325 794.03 36.3325C793.041 36.3325 792.239 37.136 792.239 38.1272C792.239 39.1184 793.041 39.9219 794.03 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 39.9219C789.049 39.9219 789.851 39.1184 789.851 38.1272C789.851 37.136 789.049 36.3325 788.06 36.3325C787.071 36.3325 786.269 37.136 786.269 38.1272C786.269 39.1184 787.071 39.9219 788.06 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 39.9219C783.079 39.9219 783.881 39.1184 783.881 38.1272C783.881 37.136 783.079 36.3325 782.09 36.3325C781.1 36.3325 780.299 37.136 780.299 38.1272C780.299 39.1184 781.1 39.9219 782.09 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M776.119 39.9219C777.109 39.9219 777.91 39.1184 777.91 38.1272C777.91 37.136 777.109 36.3325 776.119 36.3325C775.13 36.3325 774.328 37.136 774.328 38.1272C774.328 39.1184 775.13 39.9219 776.119 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M770.149 39.9219C771.138 39.9219 771.94 39.1184 771.94 38.1272C771.94 37.136 771.138 36.3325 770.149 36.3325C769.16 36.3325 768.358 37.136 768.358 38.1272C768.358 39.1184 769.16 39.9219 770.149 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 33.9397C842.78 33.9397 843.582 33.1362 843.582 32.145C843.582 31.1538 842.78 30.3503 841.791 30.3503C840.802 30.3503 840 31.1538 840 32.145C840 33.1362 840.802 33.9397 841.791 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 33.9397C836.81 33.9397 837.612 33.1362 837.612 32.145C837.612 31.1538 836.81 30.3503 835.821 30.3503C834.832 30.3503 834.03 31.1538 834.03 32.145C834.03 33.1362 834.832 33.9397 835.821 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 33.9397C830.84 33.9397 831.642 33.1362 831.642 32.145C831.642 31.1538 830.84 30.3503 829.851 30.3503C828.862 30.3503 828.06 31.1538 828.06 32.145C828.06 33.1362 828.862 33.9397 829.851 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M823.881 33.9397C824.87 33.9397 825.672 33.1362 825.672 32.145C825.672 31.1538 824.87 30.3503 823.881 30.3503C822.891 30.3503 822.09 31.1538 822.09 32.145C822.09 33.1362 822.891 33.9397 823.881 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 33.9397C818.9 33.9397 819.701 33.1362 819.701 32.145C819.701 31.1538 818.9 30.3503 817.91 30.3503C816.921 30.3503 816.119 31.1538 816.119 32.145C816.119 33.1362 816.921 33.9397 817.91 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 33.9397C812.929 33.9397 813.731 33.1362 813.731 32.145C813.731 31.1538 812.929 30.3503 811.94 30.3503C810.951 30.3503 810.149 31.1538 810.149 32.145C810.149 33.1362 810.951 33.9397 811.94 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 33.9397C806.959 33.9397 807.761 33.1362 807.761 32.145C807.761 31.1538 806.959 30.3503 805.97 30.3503C804.981 30.3503 804.179 31.1538 804.179 32.145C804.179 33.1362 804.981 33.9397 805.97 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M800 33.9397C800.989 33.9397 801.791 33.1362 801.791 32.145C801.791 31.1538 800.989 30.3503 800 30.3503C799.011 30.3503 798.209 31.1538 798.209 32.145C798.209 33.1362 799.011 33.9397 800 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M794.03 33.9397C795.019 33.9397 795.821 33.1362 795.821 32.145C795.821 31.1538 795.019 30.3503 794.03 30.3503C793.041 30.3503 792.239 31.1538 792.239 32.145C792.239 33.1362 793.041 33.9397 794.03 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M788.06 33.9397C789.049 33.9397 789.851 33.1362 789.851 32.145C789.851 31.1538 789.049 30.3503 788.06 30.3503C787.071 30.3503 786.269 31.1538 786.269 32.145C786.269 33.1362 787.071 33.9397 788.06 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M782.09 33.9397C783.079 33.9397 783.881 33.1362 783.881 32.145C783.881 31.1538 783.079 30.3503 782.09 30.3503C781.1 30.3503 780.299 31.1538 780.299 32.145C780.299 33.1362 781.1 33.9397 782.09 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 27.9573C818.9 27.9573 819.701 27.1538 819.701 26.1626C819.701 25.1714 818.9 24.3679 817.91 24.3679C816.921 24.3679 816.119 25.1714 816.119 26.1626C816.119 27.1538 816.921 27.9573 817.91 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M811.94 27.9573C812.929 27.9573 813.731 27.1538 813.731 26.1626C813.731 25.1714 812.929 24.3679 811.94 24.3679C810.951 24.3679 810.149 25.1714 810.149 26.1626C810.149 27.1538 810.951 27.9573 811.94 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M805.97 27.9573C806.959 27.9573 807.761 27.1538 807.761 26.1626C807.761 25.1714 806.959 24.3679 805.97 24.3679C804.981 24.3679 804.179 25.1714 804.179 26.1626C804.179 27.1538 804.981 27.9573 805.97 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M543.284 165.55C544.273 165.55 545.075 164.746 545.075 163.755C545.075 162.764 544.273 161.96 543.284 161.96C542.294 161.96 541.492 162.764 541.492 163.755C541.492 164.746 542.294 165.55 543.284 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 123.674C532.332 123.674 533.134 122.87 533.134 121.879C533.134 120.888 532.332 120.084 531.343 120.084C530.354 120.084 529.552 120.888 529.552 121.879C529.552 122.87 530.354 123.674 531.343 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 93.7625C574.123 93.7625 574.925 92.959 574.925 91.9678C574.925 90.9766 574.123 90.1731 573.134 90.1731C572.145 90.1731 571.343 90.9766 571.343 91.9678C571.343 92.959 572.145 93.7625 573.134 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 141.621C818.9 141.621 819.701 140.817 819.701 139.826C819.701 138.835 818.9 138.031 817.91 138.031C816.921 138.031 816.119 138.835 816.119 139.826C816.119 140.817 816.921 141.621 817.91 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M937.313 333.053C938.303 333.053 939.105 332.25 939.105 331.259C939.105 330.267 938.303 329.464 937.313 329.464C936.324 329.464 935.522 330.267 935.522 331.259C935.522 332.25 936.324 333.053 937.313 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M931.343 327.071C932.332 327.071 933.134 326.268 933.134 325.277C933.134 324.285 932.332 323.482 931.343 323.482C930.354 323.482 929.552 324.285 929.552 325.277C929.552 326.268 930.354 327.071 931.343 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M841.791 291.177C842.78 291.177 843.582 290.374 843.582 289.383C843.582 288.391 842.78 287.588 841.791 287.588C840.802 287.588 840 288.391 840 289.383C840 290.374 840.802 291.177 841.791 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 291.177C836.81 291.177 837.612 290.374 837.612 289.383C837.612 288.391 836.81 287.588 835.821 287.588C834.832 287.588 834.03 288.391 834.03 289.383C834.03 290.374 834.832 291.177 835.821 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M925.373 285.195C926.362 285.195 927.164 284.392 927.164 283.401C927.164 282.409 926.362 281.606 925.373 281.606C924.384 281.606 923.582 282.409 923.582 283.401C923.582 284.392 924.384 285.195 925.373 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M835.821 267.249C836.81 267.249 837.612 266.445 837.612 265.454C837.612 264.463 836.81 263.659 835.821 263.659C834.832 263.659 834.03 264.463 834.03 265.454C834.03 266.445 834.832 267.249 835.821 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 261.266C818.9 261.266 819.701 260.463 819.701 259.471C819.701 258.48 818.9 257.677 817.91 257.677C816.921 257.677 816.119 258.48 816.119 259.471C816.119 260.463 816.921 261.266 817.91 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M973.134 249.302C974.123 249.302 974.925 248.498 974.925 247.507C974.925 246.516 974.123 245.712 973.134 245.712C972.145 245.712 971.343 246.516 971.343 247.507C971.343 248.498 972.145 249.302 973.134 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M829.851 219.39C830.84 219.39 831.642 218.587 831.642 217.595C831.642 216.604 830.84 215.801 829.851 215.801C828.862 215.801 828.06 216.604 828.06 217.595C828.06 218.587 828.862 219.39 829.851 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M991.045 213.408C992.034 213.408 992.836 212.604 992.836 211.613C992.836 210.622 992.034 209.818 991.045 209.818C990.056 209.818 989.254 210.622 989.254 211.613C989.254 212.604 990.056 213.408 991.045 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M1044.78 195.461C1045.77 195.461 1046.57 194.657 1046.57 193.666C1046.57 192.675 1045.77 191.872 1044.78 191.872C1043.79 191.872 1042.99 192.675 1042.99 193.666C1042.99 194.657 1043.79 195.461 1044.78 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M1038.81 195.461C1039.8 195.461 1040.6 194.657 1040.6 193.666C1040.6 192.675 1039.8 191.872 1038.81 191.872C1037.82 191.872 1037.01 192.675 1037.01 193.666C1037.01 194.657 1037.82 195.461 1038.81 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M1056.72 189.479C1057.71 189.479 1058.51 188.675 1058.51 187.684C1058.51 186.693 1057.71 185.889 1056.72 185.889C1055.73 185.889 1054.93 186.693 1054.93 187.684C1054.93 188.675 1055.73 189.479 1056.72 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M1002.99 183.497C1003.97 183.497 1004.78 182.693 1004.78 181.702C1004.78 180.711 1003.97 179.907 1002.99 179.907C1002 179.907 1001.19 180.711 1001.19 181.702C1001.19 182.693 1002 183.497 1002.99 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 99.7446C580.094 99.7446 580.896 98.9411 580.896 97.95C580.896 96.9588 580.094 96.1553 579.104 96.1553C578.115 96.1553 577.313 96.9588 577.313 97.95C577.313 98.9411 578.115 99.7446 579.104 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M513.433 117.691C514.422 117.691 515.224 116.888 515.224 115.897C515.224 114.906 514.422 114.102 513.433 114.102C512.444 114.102 511.642 114.906 511.642 115.897C511.642 116.888 512.444 117.691 513.433 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M531.343 117.691C532.332 117.691 533.134 116.888 533.134 115.897C533.134 114.906 532.332 114.102 531.343 114.102C530.354 114.102 529.552 114.906 529.552 115.897C529.552 116.888 530.354 117.691 531.343 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 111.709C574.123 111.709 574.925 110.906 574.925 109.915C574.925 108.923 574.123 108.12 573.134 108.12C572.145 108.12 571.343 108.923 571.343 109.915C571.343 110.906 572.145 111.709 573.134 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 165.55C508.452 165.55 509.254 164.746 509.254 163.755C509.254 162.764 508.452 161.96 507.463 161.96C506.474 161.96 505.672 162.764 505.672 163.755C505.672 164.746 506.474 165.55 507.463 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M519.403 171.532C520.392 171.532 521.194 170.728 521.194 169.737C521.194 168.746 520.392 167.943 519.403 167.943C518.414 167.943 517.612 168.746 517.612 169.737C517.612 170.728 518.414 171.532 519.403 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 171.532C639.795 171.532 640.597 170.728 640.597 169.737C640.597 168.746 639.795 167.943 638.806 167.943C637.817 167.943 637.015 168.746 637.015 169.737C637.015 170.728 637.817 171.532 638.806 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 177.514C502.482 177.514 503.284 176.711 503.284 175.719C503.284 174.728 502.482 173.925 501.493 173.925C500.503 173.925 499.702 174.728 499.702 175.719C499.702 176.711 500.503 177.514 501.493 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M620.895 183.497C621.885 183.497 622.687 182.693 622.687 181.702C622.687 180.711 621.885 179.907 620.895 179.907C619.906 179.907 619.104 180.711 619.104 181.702C619.104 182.693 619.906 183.497 620.895 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M668.657 207.426C669.646 207.426 670.448 206.622 670.448 205.631C670.448 204.64 669.646 203.836 668.657 203.836C667.668 203.836 666.866 204.64 666.866 205.631C666.866 206.622 667.668 207.426 668.657 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M638.806 458.681C639.795 458.681 640.597 457.878 640.597 456.886C640.597 455.895 639.795 455.092 638.806 455.092C637.817 455.092 637.015 455.895 637.015 456.886C637.015 457.878 637.817 458.681 638.806 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 195.461C592.034 195.461 592.836 194.657 592.836 193.666C592.836 192.675 592.034 191.872 591.045 191.872C590.056 191.872 589.254 192.675 589.254 193.666C589.254 194.657 590.056 195.461 591.045 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 165.55C580.094 165.55 580.896 164.746 580.896 163.755C580.896 162.764 580.094 161.96 579.104 161.96C578.115 161.96 577.313 162.764 577.313 163.755C577.313 164.746 578.115 165.55 579.104 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 165.55C538.303 165.55 539.104 164.746 539.104 163.755C539.104 162.764 538.303 161.96 537.313 161.96C536.324 161.96 535.522 162.764 535.522 163.755C535.522 164.746 536.324 165.55 537.313 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 159.567C574.123 159.567 574.925 158.764 574.925 157.773C574.925 156.782 574.123 155.978 573.134 155.978C572.145 155.978 571.343 156.782 571.343 157.773C571.343 158.764 572.145 159.567 573.134 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M817.91 153.585C818.9 153.585 819.701 152.782 819.701 151.791C819.701 150.799 818.9 149.996 817.91 149.996C816.921 149.996 816.119 150.799 816.119 151.791C816.119 152.782 816.921 153.585 817.91 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 153.585C586.064 153.585 586.866 152.782 586.866 151.791C586.866 150.799 586.064 149.996 585.075 149.996C584.085 149.996 583.284 150.799 583.284 151.791C583.284 152.782 584.085 153.585 585.075 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 147.603C574.123 147.603 574.925 146.799 574.925 145.808C574.925 144.817 574.123 144.013 573.134 144.013C572.145 144.013 571.343 144.817 571.343 145.808C571.343 146.799 572.145 147.603 573.134 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 147.603C568.153 147.603 568.955 146.799 568.955 145.808C568.955 144.817 568.153 144.013 567.164 144.013C566.175 144.013 565.373 144.817 565.373 145.808C565.373 146.799 566.175 147.603 567.164 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 147.603C556.213 147.603 557.015 146.799 557.015 145.808C557.015 144.817 556.213 144.013 555.224 144.013C554.235 144.013 553.433 144.817 553.433 145.808C553.433 146.799 554.235 147.603 555.224 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 141.621C603.974 141.621 604.776 140.817 604.776 139.826C604.776 138.835 603.974 138.031 602.985 138.031C601.996 138.031 601.194 138.835 601.194 139.826C601.194 140.817 601.996 141.621 602.985 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M591.045 141.621C592.034 141.621 592.836 140.817 592.836 139.826C592.836 138.835 592.034 138.031 591.045 138.031C590.056 138.031 589.254 138.835 589.254 139.826C589.254 140.817 590.056 141.621 591.045 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 141.621C586.064 141.621 586.866 140.817 586.866 139.826C586.866 138.835 586.064 138.031 585.075 138.031C584.085 138.031 583.284 138.835 583.284 139.826C583.284 140.817 584.085 141.621 585.075 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 141.621C580.094 141.621 580.896 140.817 580.896 139.826C580.896 138.835 580.094 138.031 579.104 138.031C578.115 138.031 577.313 138.835 577.313 139.826C577.313 140.817 578.115 141.621 579.104 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 141.621C568.153 141.621 568.955 140.817 568.955 139.826C568.955 138.835 568.153 138.031 567.164 138.031C566.175 138.031 565.373 138.835 565.373 139.826C565.373 140.817 566.175 141.621 567.164 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 141.621C562.183 141.621 562.985 140.817 562.985 139.826C562.985 138.835 562.183 138.031 561.194 138.031C560.205 138.031 559.403 138.835 559.403 139.826C559.403 140.817 560.205 141.621 561.194 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 135.638C568.153 135.638 568.955 134.835 568.955 133.844C568.955 132.852 568.153 132.049 567.164 132.049C566.175 132.049 565.373 132.852 565.373 133.844C565.373 134.835 566.175 135.638 567.164 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M549.254 135.638C550.243 135.638 551.045 134.835 551.045 133.844C551.045 132.852 550.243 132.049 549.254 132.049C548.265 132.049 547.463 132.852 547.463 133.844C547.463 134.835 548.265 135.638 549.254 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M585.075 129.656C586.064 129.656 586.866 128.853 586.866 127.861C586.866 126.87 586.064 126.067 585.075 126.067C584.085 126.067 583.284 126.87 583.284 127.861C583.284 128.853 584.085 129.656 585.075 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 129.656C574.123 129.656 574.925 128.853 574.925 127.861C574.925 126.87 574.123 126.067 573.134 126.067C572.145 126.067 571.343 126.87 571.343 127.861C571.343 128.853 572.145 129.656 573.134 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 129.656C562.183 129.656 562.985 128.853 562.985 127.861C562.985 126.87 562.183 126.067 561.194 126.067C560.205 126.067 559.403 126.87 559.403 127.861C559.403 128.853 560.205 129.656 561.194 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 129.656C556.213 129.656 557.015 128.853 557.015 127.861C557.015 126.87 556.213 126.067 555.224 126.067C554.235 126.067 553.433 126.87 553.433 127.861C553.433 128.853 554.235 129.656 555.224 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M602.985 123.674C603.974 123.674 604.776 122.87 604.776 121.879C604.776 120.888 603.974 120.084 602.985 120.084C601.996 120.084 601.194 120.888 601.194 121.879C601.194 122.87 601.996 123.674 602.985 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 123.674C580.094 123.674 580.896 122.87 580.896 121.879C580.896 120.888 580.094 120.084 579.104 120.084C578.115 120.084 577.313 120.888 577.313 121.879C577.313 122.87 578.115 123.674 579.104 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 123.674C568.153 123.674 568.955 122.87 568.955 121.879C568.955 120.888 568.153 120.084 567.164 120.084C566.175 120.084 565.373 120.888 565.373 121.879C565.373 122.87 566.175 123.674 567.164 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M561.194 123.674C562.183 123.674 562.985 122.87 562.985 121.879C562.985 120.888 562.183 120.084 561.194 120.084C560.205 120.084 559.403 120.888 559.403 121.879C559.403 122.87 560.205 123.674 561.194 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M555.224 123.674C556.213 123.674 557.015 122.87 557.015 121.879C557.015 120.888 556.213 120.084 555.224 120.084C554.235 120.084 553.433 120.888 553.433 121.879C553.433 122.87 554.235 123.674 555.224 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M537.313 123.674C538.303 123.674 539.104 122.87 539.104 121.879C539.104 120.888 538.303 120.084 537.313 120.084C536.324 120.084 535.522 120.888 535.522 121.879C535.522 122.87 536.324 123.674 537.313 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 117.691C568.153 117.691 568.955 116.888 568.955 115.897C568.955 114.906 568.153 114.102 567.164 114.102C566.175 114.102 565.373 114.906 565.373 115.897C565.373 116.888 566.175 117.691 567.164 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M656.716 105.727C657.706 105.727 658.507 104.923 658.507 103.932C658.507 102.941 657.706 102.137 656.716 102.137C655.727 102.137 654.925 102.941 654.925 103.932C654.925 104.923 655.727 105.727 656.716 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M597.015 93.7625C598.004 93.7625 598.806 92.959 598.806 91.9678C598.806 90.9766 598.004 90.1731 597.015 90.1731C596.026 90.1731 595.224 90.9766 595.224 91.9678C595.224 92.959 596.026 93.7625 597.015 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M573.134 87.78C574.123 87.78 574.925 86.9765 574.925 85.9854C574.925 84.9942 574.123 84.1907 573.134 84.1907C572.145 84.1907 571.343 84.9942 571.343 85.9854C571.343 86.9765 572.145 87.78 573.134 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M746.269 237.337C747.258 237.337 748.06 236.533 748.06 235.542C748.06 234.551 747.258 233.748 746.269 233.748C745.279 233.748 744.478 234.551 744.478 235.542C744.478 236.533 745.279 237.337 746.269 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M579.104 135.638C580.094 135.638 580.896 134.835 580.896 133.844C580.896 132.852 580.094 132.049 579.104 132.049C578.115 132.049 577.313 132.852 577.313 133.844C577.313 134.835 578.115 135.638 579.104 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 578.327C323.377 578.327 324.179 577.523 324.179 576.532C324.179 575.541 323.377 574.737 322.388 574.737C321.399 574.737 320.597 575.541 320.597 576.532C320.597 577.523 321.399 578.327 322.388 578.327Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 578.327C317.407 578.327 318.209 577.523 318.209 576.532C318.209 575.541 317.407 574.737 316.418 574.737C315.429 574.737 314.627 575.541 314.627 576.532C314.627 577.523 315.429 578.327 316.418 578.327Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 578.327C311.437 578.327 312.239 577.523 312.239 576.532C312.239 575.541 311.437 574.737 310.448 574.737C309.459 574.737 308.657 575.541 308.657 576.532C308.657 577.523 309.459 578.327 310.448 578.327Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 578.327C305.467 578.327 306.269 577.523 306.269 576.532C306.269 575.541 305.467 574.737 304.478 574.737C303.488 574.737 302.687 575.541 302.687 576.532C302.687 577.523 303.488 578.327 304.478 578.327Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 572.344C311.437 572.344 312.239 571.541 312.239 570.55C312.239 569.558 311.437 568.755 310.448 568.755C309.459 568.755 308.657 569.558 308.657 570.55C308.657 571.541 309.459 572.344 310.448 572.344Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 572.344C305.467 572.344 306.269 571.541 306.269 570.55C306.269 569.558 305.467 568.755 304.478 568.755C303.488 568.755 302.687 569.558 302.687 570.55C302.687 571.541 303.488 572.344 304.478 572.344Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 566.362C299.497 566.362 300.299 565.559 300.299 564.568C300.299 563.576 299.497 562.773 298.507 562.773C297.518 562.773 296.716 563.576 296.716 564.568C296.716 565.559 297.518 566.362 298.507 566.362Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 566.362C293.526 566.362 294.328 565.559 294.328 564.568C294.328 563.576 293.526 562.773 292.537 562.773C291.548 562.773 290.746 563.576 290.746 564.568C290.746 565.559 291.548 566.362 292.537 566.362Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 566.362C287.556 566.362 288.358 565.559 288.358 564.568C288.358 563.576 287.556 562.773 286.567 562.773C285.578 562.773 284.776 563.576 284.776 564.568C284.776 565.559 285.578 566.362 286.567 566.362Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 560.38C299.497 560.38 300.299 559.577 300.299 558.586C300.299 557.595 299.497 556.791 298.507 556.791C297.518 556.791 296.716 557.595 296.716 558.586C296.716 559.577 297.518 560.38 298.507 560.38Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 560.38C293.526 560.38 294.328 559.577 294.328 558.586C294.328 557.595 293.526 556.791 292.537 556.791C291.548 556.791 290.746 557.595 290.746 558.586C290.746 559.577 291.548 560.38 292.537 560.38Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 560.38C287.556 560.38 288.358 559.577 288.358 558.586C288.358 557.595 287.556 556.791 286.567 556.791C285.578 556.791 284.776 557.595 284.776 558.586C284.776 559.577 285.578 560.38 286.567 560.38Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 554.398C305.467 554.398 306.269 553.594 306.269 552.603C306.269 551.612 305.467 550.808 304.478 550.808C303.488 550.808 302.687 551.612 302.687 552.603C302.687 553.594 303.488 554.398 304.478 554.398Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 554.398C299.497 554.398 300.299 553.594 300.299 552.603C300.299 551.612 299.497 550.808 298.507 550.808C297.518 550.808 296.716 551.612 296.716 552.603C296.716 553.594 297.518 554.398 298.507 554.398Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 554.398C293.526 554.398 294.328 553.594 294.328 552.603C294.328 551.612 293.526 550.808 292.537 550.808C291.548 550.808 290.746 551.612 290.746 552.603C290.746 553.594 291.548 554.398 292.537 554.398Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 554.398C287.556 554.398 288.358 553.594 288.358 552.603C288.358 551.612 287.556 550.808 286.567 550.808C285.578 550.808 284.776 551.612 284.776 552.603C284.776 553.594 285.578 554.398 286.567 554.398Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 554.398C281.586 554.398 282.388 553.594 282.388 552.603C282.388 551.612 281.586 550.808 280.597 550.808C279.608 550.808 278.806 551.612 278.806 552.603C278.806 553.594 279.608 554.398 280.597 554.398Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 548.416C305.467 548.416 306.269 547.612 306.269 546.621C306.269 545.63 305.467 544.826 304.478 544.826C303.488 544.826 302.687 545.63 302.687 546.621C302.687 547.612 303.488 548.416 304.478 548.416Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 548.416C299.497 548.416 300.299 547.612 300.299 546.621C300.299 545.63 299.497 544.826 298.507 544.826C297.518 544.826 296.716 545.63 296.716 546.621C296.716 547.612 297.518 548.416 298.507 548.416Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 548.416C293.526 548.416 294.328 547.612 294.328 546.621C294.328 545.63 293.526 544.826 292.537 544.826C291.548 544.826 290.746 545.63 290.746 546.621C290.746 547.612 291.548 548.416 292.537 548.416Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 548.416C287.556 548.416 288.358 547.612 288.358 546.621C288.358 545.63 287.556 544.826 286.567 544.826C285.578 544.826 284.776 545.63 284.776 546.621C284.776 547.612 285.578 548.416 286.567 548.416Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 548.416C281.586 548.416 282.388 547.612 282.388 546.621C282.388 545.63 281.586 544.826 280.597 544.826C279.608 544.826 278.806 545.63 278.806 546.621C278.806 547.612 279.608 548.416 280.597 548.416Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 542.433C299.497 542.433 300.299 541.63 300.299 540.638C300.299 539.647 299.497 538.844 298.507 538.844C297.518 538.844 296.716 539.647 296.716 540.638C296.716 541.63 297.518 542.433 298.507 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 542.433C293.526 542.433 294.328 541.63 294.328 540.638C294.328 539.647 293.526 538.844 292.537 538.844C291.548 538.844 290.746 539.647 290.746 540.638C290.746 541.63 291.548 542.433 292.537 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 542.433C287.556 542.433 288.358 541.63 288.358 540.638C288.358 539.647 287.556 538.844 286.567 538.844C285.578 538.844 284.776 539.647 284.776 540.638C284.776 541.63 285.578 542.433 286.567 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 542.433C281.586 542.433 282.388 541.63 282.388 540.638C282.388 539.647 281.586 538.844 280.597 538.844C279.608 538.844 278.806 539.647 278.806 540.638C278.806 541.63 279.608 542.433 280.597 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 542.433C275.616 542.433 276.418 541.63 276.418 540.638C276.418 539.647 275.616 538.844 274.627 538.844C273.638 538.844 272.836 539.647 272.836 540.638C272.836 541.63 273.638 542.433 274.627 542.433Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 536.451C299.497 536.451 300.299 535.647 300.299 534.656C300.299 533.665 299.497 532.861 298.507 532.861C297.518 532.861 296.716 533.665 296.716 534.656C296.716 535.647 297.518 536.451 298.507 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 536.451C293.526 536.451 294.328 535.647 294.328 534.656C294.328 533.665 293.526 532.861 292.537 532.861C291.548 532.861 290.746 533.665 290.746 534.656C290.746 535.647 291.548 536.451 292.537 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 536.451C287.556 536.451 288.358 535.647 288.358 534.656C288.358 533.665 287.556 532.861 286.567 532.861C285.578 532.861 284.776 533.665 284.776 534.656C284.776 535.647 285.578 536.451 286.567 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 536.451C281.586 536.451 282.388 535.647 282.388 534.656C282.388 533.665 281.586 532.861 280.597 532.861C279.608 532.861 278.806 533.665 278.806 534.656C278.806 535.647 279.608 536.451 280.597 536.451Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 530.468C299.497 530.468 300.299 529.665 300.299 528.674C300.299 527.682 299.497 526.879 298.507 526.879C297.518 526.879 296.716 527.682 296.716 528.674C296.716 529.665 297.518 530.468 298.507 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 530.468C293.526 530.468 294.328 529.665 294.328 528.674C294.328 527.682 293.526 526.879 292.537 526.879C291.548 526.879 290.746 527.682 290.746 528.674C290.746 529.665 291.548 530.468 292.537 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 530.468C287.556 530.468 288.358 529.665 288.358 528.674C288.358 527.682 287.556 526.879 286.567 526.879C285.578 526.879 284.776 527.682 284.776 528.674C284.776 529.665 285.578 530.468 286.567 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 530.468C281.586 530.468 282.388 529.665 282.388 528.674C282.388 527.682 281.586 526.879 280.597 526.879C279.608 526.879 278.806 527.682 278.806 528.674C278.806 529.665 279.608 530.468 280.597 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 530.468C275.616 530.468 276.418 529.665 276.418 528.674C276.418 527.682 275.616 526.879 274.627 526.879C273.638 526.879 272.836 527.682 272.836 528.674C272.836 529.665 273.638 530.468 274.627 530.468Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 524.486C299.497 524.486 300.299 523.683 300.299 522.692C300.299 521.7 299.497 520.897 298.507 520.897C297.518 520.897 296.716 521.7 296.716 522.692C296.716 523.683 297.518 524.486 298.507 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 524.486C293.526 524.486 294.328 523.683 294.328 522.692C294.328 521.7 293.526 520.897 292.537 520.897C291.548 520.897 290.746 521.7 290.746 522.692C290.746 523.683 291.548 524.486 292.537 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 524.486C287.556 524.486 288.358 523.683 288.358 522.692C288.358 521.7 287.556 520.897 286.567 520.897C285.578 520.897 284.776 521.7 284.776 522.692C284.776 523.683 285.578 524.486 286.567 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 524.486C281.586 524.486 282.388 523.683 282.388 522.692C282.388 521.7 281.586 520.897 280.597 520.897C279.608 520.897 278.806 521.7 278.806 522.692C278.806 523.683 279.608 524.486 280.597 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 524.486C275.616 524.486 276.418 523.683 276.418 522.692C276.418 521.7 275.616 520.897 274.627 520.897C273.638 520.897 272.836 521.7 272.836 522.692C272.836 523.683 273.638 524.486 274.627 524.486Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 518.504C305.467 518.504 306.269 517.7 306.269 516.709C306.269 515.718 305.467 514.915 304.478 514.915C303.488 514.915 302.687 515.718 302.687 516.709C302.687 517.7 303.488 518.504 304.478 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 518.504C299.497 518.504 300.299 517.7 300.299 516.709C300.299 515.718 299.497 514.915 298.507 514.915C297.518 514.915 296.716 515.718 296.716 516.709C296.716 517.7 297.518 518.504 298.507 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 518.504C293.526 518.504 294.328 517.7 294.328 516.709C294.328 515.718 293.526 514.915 292.537 514.915C291.548 514.915 290.746 515.718 290.746 516.709C290.746 517.7 291.548 518.504 292.537 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 518.504C287.556 518.504 288.358 517.7 288.358 516.709C288.358 515.718 287.556 514.915 286.567 514.915C285.578 514.915 284.776 515.718 284.776 516.709C284.776 517.7 285.578 518.504 286.567 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 518.504C281.586 518.504 282.388 517.7 282.388 516.709C282.388 515.718 281.586 514.915 280.597 514.915C279.608 514.915 278.806 515.718 278.806 516.709C278.806 517.7 279.608 518.504 280.597 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 518.504C275.616 518.504 276.418 517.7 276.418 516.709C276.418 515.718 275.616 514.915 274.627 514.915C273.638 514.915 272.836 515.718 272.836 516.709C272.836 517.7 273.638 518.504 274.627 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 518.504C269.646 518.504 270.448 517.7 270.448 516.709C270.448 515.718 269.646 514.915 268.657 514.915C267.668 514.915 266.866 515.718 266.866 516.709C266.866 517.7 267.668 518.504 268.657 518.504Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 512.522C305.467 512.522 306.269 511.718 306.269 510.727C306.269 509.736 305.467 508.932 304.478 508.932C303.488 508.932 302.687 509.736 302.687 510.727C302.687 511.718 303.488 512.522 304.478 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 512.522C299.497 512.522 300.299 511.718 300.299 510.727C300.299 509.736 299.497 508.932 298.507 508.932C297.518 508.932 296.716 509.736 296.716 510.727C296.716 511.718 297.518 512.522 298.507 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 512.522C293.526 512.522 294.328 511.718 294.328 510.727C294.328 509.736 293.526 508.932 292.537 508.932C291.548 508.932 290.746 509.736 290.746 510.727C290.746 511.718 291.548 512.522 292.537 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 512.522C287.556 512.522 288.358 511.718 288.358 510.727C288.358 509.736 287.556 508.932 286.567 508.932C285.578 508.932 284.776 509.736 284.776 510.727C284.776 511.718 285.578 512.522 286.567 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 512.522C281.586 512.522 282.388 511.718 282.388 510.727C282.388 509.736 281.586 508.932 280.597 508.932C279.608 508.932 278.806 509.736 278.806 510.727C278.806 511.718 279.608 512.522 280.597 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 512.522C275.616 512.522 276.418 511.718 276.418 510.727C276.418 509.736 275.616 508.932 274.627 508.932C273.638 508.932 272.836 509.736 272.836 510.727C272.836 511.718 273.638 512.522 274.627 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 512.522C269.646 512.522 270.448 511.718 270.448 510.727C270.448 509.736 269.646 508.932 268.657 508.932C267.668 508.932 266.866 509.736 266.866 510.727C266.866 511.718 267.668 512.522 268.657 512.522Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 506.54C323.377 506.54 324.179 505.736 324.179 504.745C324.179 503.754 323.377 502.95 322.388 502.95C321.399 502.95 320.597 503.754 320.597 504.745C320.597 505.736 321.399 506.54 322.388 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 506.54C317.407 506.54 318.209 505.736 318.209 504.745C318.209 503.754 317.407 502.95 316.418 502.95C315.429 502.95 314.627 503.754 314.627 504.745C314.627 505.736 315.429 506.54 316.418 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 506.54C311.437 506.54 312.239 505.736 312.239 504.745C312.239 503.754 311.437 502.95 310.448 502.95C309.459 502.95 308.657 503.754 308.657 504.745C308.657 505.736 309.459 506.54 310.448 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 506.54C305.467 506.54 306.269 505.736 306.269 504.745C306.269 503.754 305.467 502.95 304.478 502.95C303.488 502.95 302.687 503.754 302.687 504.745C302.687 505.736 303.488 506.54 304.478 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 506.54C299.497 506.54 300.299 505.736 300.299 504.745C300.299 503.754 299.497 502.95 298.507 502.95C297.518 502.95 296.716 503.754 296.716 504.745C296.716 505.736 297.518 506.54 298.507 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 506.54C293.526 506.54 294.328 505.736 294.328 504.745C294.328 503.754 293.526 502.95 292.537 502.95C291.548 502.95 290.746 503.754 290.746 504.745C290.746 505.736 291.548 506.54 292.537 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 506.54C287.556 506.54 288.358 505.736 288.358 504.745C288.358 503.754 287.556 502.95 286.567 502.95C285.578 502.95 284.776 503.754 284.776 504.745C284.776 505.736 285.578 506.54 286.567 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 506.54C281.586 506.54 282.388 505.736 282.388 504.745C282.388 503.754 281.586 502.95 280.597 502.95C279.608 502.95 278.806 503.754 278.806 504.745C278.806 505.736 279.608 506.54 280.597 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 506.54C275.616 506.54 276.418 505.736 276.418 504.745C276.418 503.754 275.616 502.95 274.627 502.95C273.638 502.95 272.836 503.754 272.836 504.745C272.836 505.736 273.638 506.54 274.627 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 506.54C269.646 506.54 270.448 505.736 270.448 504.745C270.448 503.754 269.646 502.95 268.657 502.95C267.668 502.95 266.866 503.754 266.866 504.745C266.866 505.736 267.668 506.54 268.657 506.54Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 500.557C323.377 500.557 324.179 499.754 324.179 498.762C324.179 497.771 323.377 496.968 322.388 496.968C321.399 496.968 320.597 497.771 320.597 498.762C320.597 499.754 321.399 500.557 322.388 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 500.557C317.407 500.557 318.209 499.754 318.209 498.762C318.209 497.771 317.407 496.968 316.418 496.968C315.429 496.968 314.627 497.771 314.627 498.762C314.627 499.754 315.429 500.557 316.418 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 500.557C311.437 500.557 312.239 499.754 312.239 498.762C312.239 497.771 311.437 496.968 310.448 496.968C309.459 496.968 308.657 497.771 308.657 498.762C308.657 499.754 309.459 500.557 310.448 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 500.557C305.467 500.557 306.269 499.754 306.269 498.762C306.269 497.771 305.467 496.968 304.478 496.968C303.488 496.968 302.687 497.771 302.687 498.762C302.687 499.754 303.488 500.557 304.478 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 500.557C299.497 500.557 300.299 499.754 300.299 498.762C300.299 497.771 299.497 496.968 298.507 496.968C297.518 496.968 296.716 497.771 296.716 498.762C296.716 499.754 297.518 500.557 298.507 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 500.557C293.526 500.557 294.328 499.754 294.328 498.762C294.328 497.771 293.526 496.968 292.537 496.968C291.548 496.968 290.746 497.771 290.746 498.762C290.746 499.754 291.548 500.557 292.537 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 500.557C287.556 500.557 288.358 499.754 288.358 498.762C288.358 497.771 287.556 496.968 286.567 496.968C285.578 496.968 284.776 497.771 284.776 498.762C284.776 499.754 285.578 500.557 286.567 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 500.557C281.586 500.557 282.388 499.754 282.388 498.762C282.388 497.771 281.586 496.968 280.597 496.968C279.608 496.968 278.806 497.771 278.806 498.762C278.806 499.754 279.608 500.557 280.597 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 500.557C275.616 500.557 276.418 499.754 276.418 498.762C276.418 497.771 275.616 496.968 274.627 496.968C273.638 496.968 272.836 497.771 272.836 498.762C272.836 499.754 273.638 500.557 274.627 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 500.557C269.646 500.557 270.448 499.754 270.448 498.762C270.448 497.771 269.646 496.968 268.657 496.968C267.668 496.968 266.866 497.771 266.866 498.762C266.866 499.754 267.668 500.557 268.657 500.557Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 494.575C317.407 494.575 318.209 493.771 318.209 492.78C318.209 491.789 317.407 490.985 316.418 490.985C315.429 490.985 314.627 491.789 314.627 492.78C314.627 493.771 315.429 494.575 316.418 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 494.575C311.437 494.575 312.239 493.771 312.239 492.78C312.239 491.789 311.437 490.985 310.448 490.985C309.459 490.985 308.657 491.789 308.657 492.78C308.657 493.771 309.459 494.575 310.448 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 494.575C305.467 494.575 306.269 493.771 306.269 492.78C306.269 491.789 305.467 490.985 304.478 490.985C303.488 490.985 302.687 491.789 302.687 492.78C302.687 493.771 303.488 494.575 304.478 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 494.575C299.497 494.575 300.299 493.771 300.299 492.78C300.299 491.789 299.497 490.985 298.507 490.985C297.518 490.985 296.716 491.789 296.716 492.78C296.716 493.771 297.518 494.575 298.507 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 494.575C293.526 494.575 294.328 493.771 294.328 492.78C294.328 491.789 293.526 490.985 292.537 490.985C291.548 490.985 290.746 491.789 290.746 492.78C290.746 493.771 291.548 494.575 292.537 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 494.575C287.556 494.575 288.358 493.771 288.358 492.78C288.358 491.789 287.556 490.985 286.567 490.985C285.578 490.985 284.776 491.789 284.776 492.78C284.776 493.771 285.578 494.575 286.567 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 494.575C281.586 494.575 282.388 493.771 282.388 492.78C282.388 491.789 281.586 490.985 280.597 490.985C279.608 490.985 278.806 491.789 278.806 492.78C278.806 493.771 279.608 494.575 280.597 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 494.575C275.616 494.575 276.418 493.771 276.418 492.78C276.418 491.789 275.616 490.985 274.627 490.985C273.638 490.985 272.836 491.789 272.836 492.78C272.836 493.771 273.638 494.575 274.627 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 494.575C269.646 494.575 270.448 493.771 270.448 492.78C270.448 491.789 269.646 490.985 268.657 490.985C267.668 490.985 266.866 491.789 266.866 492.78C266.866 493.771 267.668 494.575 268.657 494.575Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 488.593C335.318 488.593 336.119 487.789 336.119 486.798C336.119 485.807 335.318 485.003 334.328 485.003C333.339 485.003 332.537 485.807 332.537 486.798C332.537 487.789 333.339 488.593 334.328 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 488.593C323.377 488.593 324.179 487.789 324.179 486.798C324.179 485.807 323.377 485.003 322.388 485.003C321.399 485.003 320.597 485.807 320.597 486.798C320.597 487.789 321.399 488.593 322.388 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 488.593C311.437 488.593 312.239 487.789 312.239 486.798C312.239 485.807 311.437 485.003 310.448 485.003C309.459 485.003 308.657 485.807 308.657 486.798C308.657 487.789 309.459 488.593 310.448 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 488.593C305.467 488.593 306.269 487.789 306.269 486.798C306.269 485.807 305.467 485.003 304.478 485.003C303.488 485.003 302.687 485.807 302.687 486.798C302.687 487.789 303.488 488.593 304.478 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 488.593C299.497 488.593 300.299 487.789 300.299 486.798C300.299 485.807 299.497 485.003 298.507 485.003C297.518 485.003 296.716 485.807 296.716 486.798C296.716 487.789 297.518 488.593 298.507 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 488.593C293.526 488.593 294.328 487.789 294.328 486.798C294.328 485.807 293.526 485.003 292.537 485.003C291.548 485.003 290.746 485.807 290.746 486.798C290.746 487.789 291.548 488.593 292.537 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 488.593C287.556 488.593 288.358 487.789 288.358 486.798C288.358 485.807 287.556 485.003 286.567 485.003C285.578 485.003 284.776 485.807 284.776 486.798C284.776 487.789 285.578 488.593 286.567 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 488.593C281.586 488.593 282.388 487.789 282.388 486.798C282.388 485.807 281.586 485.003 280.597 485.003C279.608 485.003 278.806 485.807 278.806 486.798C278.806 487.789 279.608 488.593 280.597 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 488.593C275.616 488.593 276.418 487.789 276.418 486.798C276.418 485.807 275.616 485.003 274.627 485.003C273.638 485.003 272.836 485.807 272.836 486.798C272.836 487.789 273.638 488.593 274.627 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 482.61C335.318 482.61 336.119 481.807 336.119 480.816C336.119 479.825 335.318 479.021 334.328 479.021C333.339 479.021 332.537 479.825 332.537 480.816C332.537 481.807 333.339 482.61 334.328 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 482.61C329.347 482.61 330.149 481.807 330.149 480.816C330.149 479.825 329.347 479.021 328.358 479.021C327.369 479.021 326.567 479.825 326.567 480.816C326.567 481.807 327.369 482.61 328.358 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 482.61C323.377 482.61 324.179 481.807 324.179 480.816C324.179 479.825 323.377 479.021 322.388 479.021C321.399 479.021 320.597 479.825 320.597 480.816C320.597 481.807 321.399 482.61 322.388 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 482.61C317.407 482.61 318.209 481.807 318.209 480.816C318.209 479.825 317.407 479.021 316.418 479.021C315.429 479.021 314.627 479.825 314.627 480.816C314.627 481.807 315.429 482.61 316.418 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 482.61C311.437 482.61 312.239 481.807 312.239 480.816C312.239 479.825 311.437 479.021 310.448 479.021C309.459 479.021 308.657 479.825 308.657 480.816C308.657 481.807 309.459 482.61 310.448 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 482.61C305.467 482.61 306.269 481.807 306.269 480.816C306.269 479.825 305.467 479.021 304.478 479.021C303.488 479.021 302.687 479.825 302.687 480.816C302.687 481.807 303.488 482.61 304.478 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 482.61C299.497 482.61 300.299 481.807 300.299 480.816C300.299 479.825 299.497 479.021 298.507 479.021C297.518 479.021 296.716 479.825 296.716 480.816C296.716 481.807 297.518 482.61 298.507 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 482.61C293.526 482.61 294.328 481.807 294.328 480.816C294.328 479.825 293.526 479.021 292.537 479.021C291.548 479.021 290.746 479.825 290.746 480.816C290.746 481.807 291.548 482.61 292.537 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 482.61C287.556 482.61 288.358 481.807 288.358 480.816C288.358 479.825 287.556 479.021 286.567 479.021C285.578 479.021 284.776 479.825 284.776 480.816C284.776 481.807 285.578 482.61 286.567 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 482.61C281.586 482.61 282.388 481.807 282.388 480.816C282.388 479.825 281.586 479.021 280.597 479.021C279.608 479.021 278.806 479.825 278.806 480.816C278.806 481.807 279.608 482.61 280.597 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 482.61C275.616 482.61 276.418 481.807 276.418 480.816C276.418 479.825 275.616 479.021 274.627 479.021C273.638 479.021 272.836 479.825 272.836 480.816C272.836 481.807 273.638 482.61 274.627 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 482.61C269.646 482.61 270.448 481.807 270.448 480.816C270.448 479.825 269.646 479.021 268.657 479.021C267.668 479.021 266.866 479.825 266.866 480.816C266.866 481.807 267.668 482.61 268.657 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 482.61C263.676 482.61 264.478 481.807 264.478 480.816C264.478 479.825 263.676 479.021 262.687 479.021C261.697 479.021 260.896 479.825 260.896 480.816C260.896 481.807 261.697 482.61 262.687 482.61Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 476.628C341.288 476.628 342.09 475.824 342.09 474.833C342.09 473.842 341.288 473.039 340.299 473.039C339.309 473.039 338.507 473.842 338.507 474.833C338.507 475.824 339.309 476.628 340.299 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 476.628C335.318 476.628 336.119 475.824 336.119 474.833C336.119 473.842 335.318 473.039 334.328 473.039C333.339 473.039 332.537 473.842 332.537 474.833C332.537 475.824 333.339 476.628 334.328 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 476.628C329.347 476.628 330.149 475.824 330.149 474.833C330.149 473.842 329.347 473.039 328.358 473.039C327.369 473.039 326.567 473.842 326.567 474.833C326.567 475.824 327.369 476.628 328.358 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 476.628C323.377 476.628 324.179 475.824 324.179 474.833C324.179 473.842 323.377 473.039 322.388 473.039C321.399 473.039 320.597 473.842 320.597 474.833C320.597 475.824 321.399 476.628 322.388 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 476.628C317.407 476.628 318.209 475.824 318.209 474.833C318.209 473.842 317.407 473.039 316.418 473.039C315.429 473.039 314.627 473.842 314.627 474.833C314.627 475.824 315.429 476.628 316.418 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 476.628C311.437 476.628 312.239 475.824 312.239 474.833C312.239 473.842 311.437 473.039 310.448 473.039C309.459 473.039 308.657 473.842 308.657 474.833C308.657 475.824 309.459 476.628 310.448 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 476.628C305.467 476.628 306.269 475.824 306.269 474.833C306.269 473.842 305.467 473.039 304.478 473.039C303.488 473.039 302.687 473.842 302.687 474.833C302.687 475.824 303.488 476.628 304.478 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 476.628C299.497 476.628 300.299 475.824 300.299 474.833C300.299 473.842 299.497 473.039 298.507 473.039C297.518 473.039 296.716 473.842 296.716 474.833C296.716 475.824 297.518 476.628 298.507 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 476.628C293.526 476.628 294.328 475.824 294.328 474.833C294.328 473.842 293.526 473.039 292.537 473.039C291.548 473.039 290.746 473.842 290.746 474.833C290.746 475.824 291.548 476.628 292.537 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 476.628C287.556 476.628 288.358 475.824 288.358 474.833C288.358 473.842 287.556 473.039 286.567 473.039C285.578 473.039 284.776 473.842 284.776 474.833C284.776 475.824 285.578 476.628 286.567 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 476.628C281.586 476.628 282.388 475.824 282.388 474.833C282.388 473.842 281.586 473.039 280.597 473.039C279.608 473.039 278.806 473.842 278.806 474.833C278.806 475.824 279.608 476.628 280.597 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 476.628C275.616 476.628 276.418 475.824 276.418 474.833C276.418 473.842 275.616 473.039 274.627 473.039C273.638 473.039 272.836 473.842 272.836 474.833C272.836 475.824 273.638 476.628 274.627 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 476.628C269.646 476.628 270.448 475.824 270.448 474.833C270.448 473.842 269.646 473.039 268.657 473.039C267.668 473.039 266.866 473.842 266.866 474.833C266.866 475.824 267.668 476.628 268.657 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 476.628C263.676 476.628 264.478 475.824 264.478 474.833C264.478 473.842 263.676 473.039 262.687 473.039C261.697 473.039 260.896 473.842 260.896 474.833C260.896 475.824 261.697 476.628 262.687 476.628Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 470.646C341.288 470.646 342.09 469.842 342.09 468.851C342.09 467.86 341.288 467.056 340.299 467.056C339.309 467.056 338.507 467.86 338.507 468.851C338.507 469.842 339.309 470.646 340.299 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 470.646C335.318 470.646 336.119 469.842 336.119 468.851C336.119 467.86 335.318 467.056 334.328 467.056C333.339 467.056 332.537 467.86 332.537 468.851C332.537 469.842 333.339 470.646 334.328 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 470.646C329.347 470.646 330.149 469.842 330.149 468.851C330.149 467.86 329.347 467.056 328.358 467.056C327.369 467.056 326.567 467.86 326.567 468.851C326.567 469.842 327.369 470.646 328.358 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 470.646C323.377 470.646 324.179 469.842 324.179 468.851C324.179 467.86 323.377 467.056 322.388 467.056C321.399 467.056 320.597 467.86 320.597 468.851C320.597 469.842 321.399 470.646 322.388 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 470.646C317.407 470.646 318.209 469.842 318.209 468.851C318.209 467.86 317.407 467.056 316.418 467.056C315.429 467.056 314.627 467.86 314.627 468.851C314.627 469.842 315.429 470.646 316.418 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 470.646C311.437 470.646 312.239 469.842 312.239 468.851C312.239 467.86 311.437 467.056 310.448 467.056C309.459 467.056 308.657 467.86 308.657 468.851C308.657 469.842 309.459 470.646 310.448 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 470.646C305.467 470.646 306.269 469.842 306.269 468.851C306.269 467.86 305.467 467.056 304.478 467.056C303.488 467.056 302.687 467.86 302.687 468.851C302.687 469.842 303.488 470.646 304.478 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 470.646C299.497 470.646 300.299 469.842 300.299 468.851C300.299 467.86 299.497 467.056 298.507 467.056C297.518 467.056 296.716 467.86 296.716 468.851C296.716 469.842 297.518 470.646 298.507 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 470.646C293.526 470.646 294.328 469.842 294.328 468.851C294.328 467.86 293.526 467.056 292.537 467.056C291.548 467.056 290.746 467.86 290.746 468.851C290.746 469.842 291.548 470.646 292.537 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 470.646C287.556 470.646 288.358 469.842 288.358 468.851C288.358 467.86 287.556 467.056 286.567 467.056C285.578 467.056 284.776 467.86 284.776 468.851C284.776 469.842 285.578 470.646 286.567 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 470.646C281.586 470.646 282.388 469.842 282.388 468.851C282.388 467.86 281.586 467.056 280.597 467.056C279.608 467.056 278.806 467.86 278.806 468.851C278.806 469.842 279.608 470.646 280.597 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 470.646C275.616 470.646 276.418 469.842 276.418 468.851C276.418 467.86 275.616 467.056 274.627 467.056C273.638 467.056 272.836 467.86 272.836 468.851C272.836 469.842 273.638 470.646 274.627 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 470.646C269.646 470.646 270.448 469.842 270.448 468.851C270.448 467.86 269.646 467.056 268.657 467.056C267.668 467.056 266.866 467.86 266.866 468.851C266.866 469.842 267.668 470.646 268.657 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 470.646C263.676 470.646 264.478 469.842 264.478 468.851C264.478 467.86 263.676 467.056 262.687 467.056C261.697 467.056 260.896 467.86 260.896 468.851C260.896 469.842 261.697 470.646 262.687 470.646Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 464.664C347.258 464.664 348.06 463.86 348.06 462.869C348.06 461.878 347.258 461.074 346.269 461.074C345.279 461.074 344.478 461.878 344.478 462.869C344.478 463.86 345.279 464.664 346.269 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 464.664C341.288 464.664 342.09 463.86 342.09 462.869C342.09 461.878 341.288 461.074 340.299 461.074C339.309 461.074 338.507 461.878 338.507 462.869C338.507 463.86 339.309 464.664 340.299 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 464.664C335.318 464.664 336.119 463.86 336.119 462.869C336.119 461.878 335.318 461.074 334.328 461.074C333.339 461.074 332.537 461.878 332.537 462.869C332.537 463.86 333.339 464.664 334.328 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 464.664C329.347 464.664 330.149 463.86 330.149 462.869C330.149 461.878 329.347 461.074 328.358 461.074C327.369 461.074 326.567 461.878 326.567 462.869C326.567 463.86 327.369 464.664 328.358 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 464.664C323.377 464.664 324.179 463.86 324.179 462.869C324.179 461.878 323.377 461.074 322.388 461.074C321.399 461.074 320.597 461.878 320.597 462.869C320.597 463.86 321.399 464.664 322.388 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 464.664C317.407 464.664 318.209 463.86 318.209 462.869C318.209 461.878 317.407 461.074 316.418 461.074C315.429 461.074 314.627 461.878 314.627 462.869C314.627 463.86 315.429 464.664 316.418 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 464.664C311.437 464.664 312.239 463.86 312.239 462.869C312.239 461.878 311.437 461.074 310.448 461.074C309.459 461.074 308.657 461.878 308.657 462.869C308.657 463.86 309.459 464.664 310.448 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 464.664C305.467 464.664 306.269 463.86 306.269 462.869C306.269 461.878 305.467 461.074 304.478 461.074C303.488 461.074 302.687 461.878 302.687 462.869C302.687 463.86 303.488 464.664 304.478 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 464.664C299.497 464.664 300.299 463.86 300.299 462.869C300.299 461.878 299.497 461.074 298.507 461.074C297.518 461.074 296.716 461.878 296.716 462.869C296.716 463.86 297.518 464.664 298.507 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 464.664C293.526 464.664 294.328 463.86 294.328 462.869C294.328 461.878 293.526 461.074 292.537 461.074C291.548 461.074 290.746 461.878 290.746 462.869C290.746 463.86 291.548 464.664 292.537 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 464.664C287.556 464.664 288.358 463.86 288.358 462.869C288.358 461.878 287.556 461.074 286.567 461.074C285.578 461.074 284.776 461.878 284.776 462.869C284.776 463.86 285.578 464.664 286.567 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 464.664C281.586 464.664 282.388 463.86 282.388 462.869C282.388 461.878 281.586 461.074 280.597 461.074C279.608 461.074 278.806 461.878 278.806 462.869C278.806 463.86 279.608 464.664 280.597 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 464.664C275.616 464.664 276.418 463.86 276.418 462.869C276.418 461.878 275.616 461.074 274.627 461.074C273.638 461.074 272.836 461.878 272.836 462.869C272.836 463.86 273.638 464.664 274.627 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 464.664C269.646 464.664 270.448 463.86 270.448 462.869C270.448 461.878 269.646 461.074 268.657 461.074C267.668 461.074 266.866 461.878 266.866 462.869C266.866 463.86 267.668 464.664 268.657 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 464.664C263.676 464.664 264.478 463.86 264.478 462.869C264.478 461.878 263.676 461.074 262.687 461.074C261.697 461.074 260.896 461.878 260.896 462.869C260.896 463.86 261.697 464.664 262.687 464.664Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 458.681C347.258 458.681 348.06 457.878 348.06 456.886C348.06 455.895 347.258 455.092 346.269 455.092C345.279 455.092 344.478 455.895 344.478 456.886C344.478 457.878 345.279 458.681 346.269 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M340.298 458.681C341.288 458.681 342.09 457.878 342.09 456.886C342.09 455.895 341.288 455.092 340.298 455.092C339.309 455.092 338.507 455.895 338.507 456.886C338.507 457.878 339.309 458.681 340.298 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 458.681C335.318 458.681 336.119 457.878 336.119 456.886C336.119 455.895 335.318 455.092 334.328 455.092C333.339 455.092 332.537 455.895 332.537 456.886C332.537 457.878 333.339 458.681 334.328 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 458.681C329.347 458.681 330.149 457.878 330.149 456.886C330.149 455.895 329.347 455.092 328.358 455.092C327.369 455.092 326.567 455.895 326.567 456.886C326.567 457.878 327.369 458.681 328.358 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 458.681C323.377 458.681 324.179 457.878 324.179 456.886C324.179 455.895 323.377 455.092 322.388 455.092C321.399 455.092 320.597 455.895 320.597 456.886C320.597 457.878 321.399 458.681 322.388 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 458.681C317.407 458.681 318.209 457.878 318.209 456.886C318.209 455.895 317.407 455.092 316.418 455.092C315.429 455.092 314.627 455.895 314.627 456.886C314.627 457.878 315.429 458.681 316.418 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 458.681C311.437 458.681 312.239 457.878 312.239 456.886C312.239 455.895 311.437 455.092 310.448 455.092C309.459 455.092 308.657 455.895 308.657 456.886C308.657 457.878 309.459 458.681 310.448 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 458.681C305.467 458.681 306.269 457.878 306.269 456.886C306.269 455.895 305.467 455.092 304.478 455.092C303.488 455.092 302.687 455.895 302.687 456.886C302.687 457.878 303.488 458.681 304.478 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 458.681C299.497 458.681 300.299 457.878 300.299 456.886C300.299 455.895 299.497 455.092 298.507 455.092C297.518 455.092 296.716 455.895 296.716 456.886C296.716 457.878 297.518 458.681 298.507 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 458.681C293.526 458.681 294.328 457.878 294.328 456.886C294.328 455.895 293.526 455.092 292.537 455.092C291.548 455.092 290.746 455.895 290.746 456.886C290.746 457.878 291.548 458.681 292.537 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 458.681C287.556 458.681 288.358 457.878 288.358 456.886C288.358 455.895 287.556 455.092 286.567 455.092C285.578 455.092 284.776 455.895 284.776 456.886C284.776 457.878 285.578 458.681 286.567 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 458.681C281.586 458.681 282.388 457.878 282.388 456.886C282.388 455.895 281.586 455.092 280.597 455.092C279.608 455.092 278.806 455.895 278.806 456.886C278.806 457.878 279.608 458.681 280.597 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 458.681C275.616 458.681 276.418 457.878 276.418 456.886C276.418 455.895 275.616 455.092 274.627 455.092C273.638 455.092 272.836 455.895 272.836 456.886C272.836 457.878 273.638 458.681 274.627 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 458.681C269.646 458.681 270.448 457.878 270.448 456.886C270.448 455.895 269.646 455.092 268.657 455.092C267.668 455.092 266.866 455.895 266.866 456.886C266.866 457.878 267.668 458.681 268.657 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 458.681C263.676 458.681 264.478 457.878 264.478 456.886C264.478 455.895 263.676 455.092 262.687 455.092C261.697 455.092 260.896 455.895 260.896 456.886C260.896 457.878 261.697 458.681 262.687 458.681Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 452.699C341.288 452.699 342.09 451.895 342.09 450.904C342.09 449.913 341.288 449.109 340.299 449.109C339.309 449.109 338.507 449.913 338.507 450.904C338.507 451.895 339.309 452.699 340.299 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 452.699C335.318 452.699 336.119 451.895 336.119 450.904C336.119 449.913 335.318 449.109 334.328 449.109C333.339 449.109 332.537 449.913 332.537 450.904C332.537 451.895 333.339 452.699 334.328 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 452.699C329.347 452.699 330.149 451.895 330.149 450.904C330.149 449.913 329.347 449.109 328.358 449.109C327.369 449.109 326.567 449.913 326.567 450.904C326.567 451.895 327.369 452.699 328.358 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 452.699C323.377 452.699 324.179 451.895 324.179 450.904C324.179 449.913 323.377 449.109 322.388 449.109C321.399 449.109 320.597 449.913 320.597 450.904C320.597 451.895 321.399 452.699 322.388 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 452.699C317.407 452.699 318.209 451.895 318.209 450.904C318.209 449.913 317.407 449.109 316.418 449.109C315.429 449.109 314.627 449.913 314.627 450.904C314.627 451.895 315.429 452.699 316.418 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 452.699C311.437 452.699 312.239 451.895 312.239 450.904C312.239 449.913 311.437 449.109 310.448 449.109C309.459 449.109 308.657 449.913 308.657 450.904C308.657 451.895 309.459 452.699 310.448 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 452.699C305.467 452.699 306.269 451.895 306.269 450.904C306.269 449.913 305.467 449.109 304.478 449.109C303.488 449.109 302.687 449.913 302.687 450.904C302.687 451.895 303.488 452.699 304.478 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 452.699C299.497 452.699 300.299 451.895 300.299 450.904C300.299 449.913 299.497 449.109 298.507 449.109C297.518 449.109 296.716 449.913 296.716 450.904C296.716 451.895 297.518 452.699 298.507 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 452.699C293.526 452.699 294.328 451.895 294.328 450.904C294.328 449.913 293.526 449.109 292.537 449.109C291.548 449.109 290.746 449.913 290.746 450.904C290.746 451.895 291.548 452.699 292.537 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 452.699C287.556 452.699 288.358 451.895 288.358 450.904C288.358 449.913 287.556 449.109 286.567 449.109C285.578 449.109 284.776 449.913 284.776 450.904C284.776 451.895 285.578 452.699 286.567 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 452.699C281.586 452.699 282.388 451.895 282.388 450.904C282.388 449.913 281.586 449.109 280.597 449.109C279.608 449.109 278.806 449.913 278.806 450.904C278.806 451.895 279.608 452.699 280.597 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 452.699C275.616 452.699 276.418 451.895 276.418 450.904C276.418 449.913 275.616 449.109 274.627 449.109C273.638 449.109 272.836 449.913 272.836 450.904C272.836 451.895 273.638 452.699 274.627 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 452.699C269.646 452.699 270.448 451.895 270.448 450.904C270.448 449.913 269.646 449.109 268.657 449.109C267.668 449.109 266.866 449.913 266.866 450.904C266.866 451.895 267.668 452.699 268.657 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 452.699C263.676 452.699 264.478 451.895 264.478 450.904C264.478 449.913 263.676 449.109 262.687 449.109C261.697 449.109 260.896 449.913 260.896 450.904C260.896 451.895 261.697 452.699 262.687 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 446.717C353.228 446.717 354.03 445.913 354.03 444.922C354.03 443.931 353.228 443.127 352.239 443.127C351.25 443.127 350.448 443.931 350.448 444.922C350.448 445.913 351.25 446.717 352.239 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 446.717C347.258 446.717 348.06 445.913 348.06 444.922C348.06 443.931 347.258 443.127 346.269 443.127C345.279 443.127 344.478 443.931 344.478 444.922C344.478 445.913 345.279 446.717 346.269 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 446.717C341.288 446.717 342.09 445.913 342.09 444.922C342.09 443.931 341.288 443.127 340.299 443.127C339.309 443.127 338.507 443.931 338.507 444.922C338.507 445.913 339.309 446.717 340.299 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 446.717C335.318 446.717 336.119 445.913 336.119 444.922C336.119 443.931 335.318 443.127 334.328 443.127C333.339 443.127 332.537 443.931 332.537 444.922C332.537 445.913 333.339 446.717 334.328 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 446.717C329.347 446.717 330.149 445.913 330.149 444.922C330.149 443.931 329.347 443.127 328.358 443.127C327.369 443.127 326.567 443.931 326.567 444.922C326.567 445.913 327.369 446.717 328.358 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 446.717C323.377 446.717 324.179 445.913 324.179 444.922C324.179 443.931 323.377 443.127 322.388 443.127C321.399 443.127 320.597 443.931 320.597 444.922C320.597 445.913 321.399 446.717 322.388 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 446.717C317.407 446.717 318.209 445.913 318.209 444.922C318.209 443.931 317.407 443.127 316.418 443.127C315.429 443.127 314.627 443.931 314.627 444.922C314.627 445.913 315.429 446.717 316.418 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 446.717C311.437 446.717 312.239 445.913 312.239 444.922C312.239 443.931 311.437 443.127 310.448 443.127C309.459 443.127 308.657 443.931 308.657 444.922C308.657 445.913 309.459 446.717 310.448 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 446.717C305.467 446.717 306.269 445.913 306.269 444.922C306.269 443.931 305.467 443.127 304.478 443.127C303.488 443.127 302.687 443.931 302.687 444.922C302.687 445.913 303.488 446.717 304.478 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 446.717C299.497 446.717 300.299 445.913 300.299 444.922C300.299 443.931 299.497 443.127 298.507 443.127C297.518 443.127 296.716 443.931 296.716 444.922C296.716 445.913 297.518 446.717 298.507 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 446.717C293.526 446.717 294.328 445.913 294.328 444.922C294.328 443.931 293.526 443.127 292.537 443.127C291.548 443.127 290.746 443.931 290.746 444.922C290.746 445.913 291.548 446.717 292.537 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 446.717C287.556 446.717 288.358 445.913 288.358 444.922C288.358 443.931 287.556 443.127 286.567 443.127C285.578 443.127 284.776 443.931 284.776 444.922C284.776 445.913 285.578 446.717 286.567 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 446.717C281.586 446.717 282.388 445.913 282.388 444.922C282.388 443.931 281.586 443.127 280.597 443.127C279.608 443.127 278.806 443.931 278.806 444.922C278.806 445.913 279.608 446.717 280.597 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 446.717C275.616 446.717 276.418 445.913 276.418 444.922C276.418 443.931 275.616 443.127 274.627 443.127C273.638 443.127 272.836 443.931 272.836 444.922C272.836 445.913 273.638 446.717 274.627 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 446.717C269.646 446.717 270.448 445.913 270.448 444.922C270.448 443.931 269.646 443.127 268.657 443.127C267.668 443.127 266.866 443.931 266.866 444.922C266.866 445.913 267.668 446.717 268.657 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 446.717C263.676 446.717 264.478 445.913 264.478 444.922C264.478 443.931 263.676 443.127 262.687 443.127C261.697 443.127 260.896 443.931 260.896 444.922C260.896 445.913 261.697 446.717 262.687 446.717Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 440.734C371.138 440.734 371.94 439.931 371.94 438.94C371.94 437.949 371.138 437.145 370.149 437.145C369.16 437.145 368.358 437.949 368.358 438.94C368.358 439.931 369.16 440.734 370.149 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 440.734C365.168 440.734 365.97 439.931 365.97 438.94C365.97 437.949 365.168 437.145 364.179 437.145C363.19 437.145 362.388 437.949 362.388 438.94C362.388 439.931 363.19 440.734 364.179 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 440.734C353.228 440.734 354.03 439.931 354.03 438.94C354.03 437.949 353.228 437.145 352.239 437.145C351.25 437.145 350.448 437.949 350.448 438.94C350.448 439.931 351.25 440.734 352.239 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 440.734C347.258 440.734 348.06 439.931 348.06 438.94C348.06 437.949 347.258 437.145 346.269 437.145C345.279 437.145 344.478 437.949 344.478 438.94C344.478 439.931 345.279 440.734 346.269 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 440.734C341.288 440.734 342.09 439.931 342.09 438.94C342.09 437.949 341.288 437.145 340.299 437.145C339.309 437.145 338.507 437.949 338.507 438.94C338.507 439.931 339.309 440.734 340.299 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 440.734C335.318 440.734 336.119 439.931 336.119 438.94C336.119 437.949 335.318 437.145 334.328 437.145C333.339 437.145 332.537 437.949 332.537 438.94C332.537 439.931 333.339 440.734 334.328 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 440.734C329.347 440.734 330.149 439.931 330.149 438.94C330.149 437.949 329.347 437.145 328.358 437.145C327.369 437.145 326.567 437.949 326.567 438.94C326.567 439.931 327.369 440.734 328.358 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 440.734C323.377 440.734 324.179 439.931 324.179 438.94C324.179 437.949 323.377 437.145 322.388 437.145C321.399 437.145 320.597 437.949 320.597 438.94C320.597 439.931 321.399 440.734 322.388 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 440.734C317.407 440.734 318.209 439.931 318.209 438.94C318.209 437.949 317.407 437.145 316.418 437.145C315.429 437.145 314.627 437.949 314.627 438.94C314.627 439.931 315.429 440.734 316.418 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 440.734C311.437 440.734 312.239 439.931 312.239 438.94C312.239 437.949 311.437 437.145 310.448 437.145C309.459 437.145 308.657 437.949 308.657 438.94C308.657 439.931 309.459 440.734 310.448 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 440.734C305.467 440.734 306.269 439.931 306.269 438.94C306.269 437.949 305.467 437.145 304.478 437.145C303.488 437.145 302.687 437.949 302.687 438.94C302.687 439.931 303.488 440.734 304.478 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 440.734C299.497 440.734 300.299 439.931 300.299 438.94C300.299 437.949 299.497 437.145 298.507 437.145C297.518 437.145 296.716 437.949 296.716 438.94C296.716 439.931 297.518 440.734 298.507 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 440.734C293.526 440.734 294.328 439.931 294.328 438.94C294.328 437.949 293.526 437.145 292.537 437.145C291.548 437.145 290.746 437.949 290.746 438.94C290.746 439.931 291.548 440.734 292.537 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 440.734C287.556 440.734 288.358 439.931 288.358 438.94C288.358 437.949 287.556 437.145 286.567 437.145C285.578 437.145 284.776 437.949 284.776 438.94C284.776 439.931 285.578 440.734 286.567 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 440.734C281.586 440.734 282.388 439.931 282.388 438.94C282.388 437.949 281.586 437.145 280.597 437.145C279.608 437.145 278.806 437.949 278.806 438.94C278.806 439.931 279.608 440.734 280.597 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 440.734C275.616 440.734 276.418 439.931 276.418 438.94C276.418 437.949 275.616 437.145 274.627 437.145C273.638 437.145 272.836 437.949 272.836 438.94C272.836 439.931 273.638 440.734 274.627 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 440.734C269.646 440.734 270.448 439.931 270.448 438.94C270.448 437.949 269.646 437.145 268.657 437.145C267.668 437.145 266.866 437.949 266.866 438.94C266.866 439.931 267.668 440.734 268.657 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 440.734C263.676 440.734 264.478 439.931 264.478 438.94C264.478 437.949 263.676 437.145 262.687 437.145C261.697 437.145 260.896 437.949 260.896 438.94C260.896 439.931 261.697 440.734 262.687 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 434.752C371.138 434.752 371.94 433.948 371.94 432.957C371.94 431.966 371.138 431.163 370.149 431.163C369.16 431.163 368.358 431.966 368.358 432.957C368.358 433.948 369.16 434.752 370.149 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 434.752C365.168 434.752 365.97 433.948 365.97 432.957C365.97 431.966 365.168 431.163 364.179 431.163C363.19 431.163 362.388 431.966 362.388 432.957C362.388 433.948 363.19 434.752 364.179 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 434.752C359.198 434.752 360 433.948 360 432.957C360 431.966 359.198 431.163 358.209 431.163C357.22 431.163 356.418 431.966 356.418 432.957C356.418 433.948 357.22 434.752 358.209 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 434.752C353.228 434.752 354.03 433.948 354.03 432.957C354.03 431.966 353.228 431.163 352.239 431.163C351.25 431.163 350.448 431.966 350.448 432.957C350.448 433.948 351.25 434.752 352.239 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 434.752C347.258 434.752 348.06 433.948 348.06 432.957C348.06 431.966 347.258 431.163 346.269 431.163C345.279 431.163 344.478 431.966 344.478 432.957C344.478 433.948 345.279 434.752 346.269 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 434.752C341.288 434.752 342.09 433.948 342.09 432.957C342.09 431.966 341.288 431.163 340.299 431.163C339.309 431.163 338.507 431.966 338.507 432.957C338.507 433.948 339.309 434.752 340.299 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 434.752C335.318 434.752 336.119 433.948 336.119 432.957C336.119 431.966 335.318 431.163 334.328 431.163C333.339 431.163 332.537 431.966 332.537 432.957C332.537 433.948 333.339 434.752 334.328 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 434.752C329.347 434.752 330.149 433.948 330.149 432.957C330.149 431.966 329.347 431.163 328.358 431.163C327.369 431.163 326.567 431.966 326.567 432.957C326.567 433.948 327.369 434.752 328.358 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 434.752C323.377 434.752 324.179 433.948 324.179 432.957C324.179 431.966 323.377 431.163 322.388 431.163C321.399 431.163 320.597 431.966 320.597 432.957C320.597 433.948 321.399 434.752 322.388 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 434.752C317.407 434.752 318.209 433.948 318.209 432.957C318.209 431.966 317.407 431.163 316.418 431.163C315.429 431.163 314.627 431.966 314.627 432.957C314.627 433.948 315.429 434.752 316.418 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 434.752C311.437 434.752 312.239 433.948 312.239 432.957C312.239 431.966 311.437 431.163 310.448 431.163C309.459 431.163 308.657 431.966 308.657 432.957C308.657 433.948 309.459 434.752 310.448 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 434.752C305.467 434.752 306.269 433.948 306.269 432.957C306.269 431.966 305.467 431.163 304.478 431.163C303.488 431.163 302.687 431.966 302.687 432.957C302.687 433.948 303.488 434.752 304.478 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 434.752C299.497 434.752 300.299 433.948 300.299 432.957C300.299 431.966 299.497 431.163 298.507 431.163C297.518 431.163 296.716 431.966 296.716 432.957C296.716 433.948 297.518 434.752 298.507 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 434.752C293.526 434.752 294.328 433.948 294.328 432.957C294.328 431.966 293.526 431.163 292.537 431.163C291.548 431.163 290.746 431.966 290.746 432.957C290.746 433.948 291.548 434.752 292.537 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 434.752C287.556 434.752 288.358 433.948 288.358 432.957C288.358 431.966 287.556 431.163 286.567 431.163C285.578 431.163 284.776 431.966 284.776 432.957C284.776 433.948 285.578 434.752 286.567 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 434.752C281.586 434.752 282.388 433.948 282.388 432.957C282.388 431.966 281.586 431.163 280.597 431.163C279.608 431.163 278.806 431.966 278.806 432.957C278.806 433.948 279.608 434.752 280.597 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 434.752C275.616 434.752 276.418 433.948 276.418 432.957C276.418 431.966 275.616 431.163 274.627 431.163C273.638 431.163 272.836 431.966 272.836 432.957C272.836 433.948 273.638 434.752 274.627 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 434.752C269.646 434.752 270.448 433.948 270.448 432.957C270.448 431.966 269.646 431.163 268.657 431.163C267.668 431.163 266.866 431.966 266.866 432.957C266.866 433.948 267.668 434.752 268.657 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 434.752C263.676 434.752 264.478 433.948 264.478 432.957C264.478 431.966 263.676 431.163 262.687 431.163C261.697 431.163 260.896 431.966 260.896 432.957C260.896 433.948 261.697 434.752 262.687 434.752Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 428.77C377.109 428.77 377.91 427.967 377.91 426.975C377.91 425.984 377.109 425.181 376.119 425.181C375.13 425.181 374.328 425.984 374.328 426.975C374.328 427.967 375.13 428.77 376.119 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 428.77C371.138 428.77 371.94 427.967 371.94 426.975C371.94 425.984 371.138 425.181 370.149 425.181C369.16 425.181 368.358 425.984 368.358 426.975C368.358 427.967 369.16 428.77 370.149 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 428.77C365.168 428.77 365.97 427.967 365.97 426.975C365.97 425.984 365.168 425.181 364.179 425.181C363.19 425.181 362.388 425.984 362.388 426.975C362.388 427.967 363.19 428.77 364.179 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 428.77C359.198 428.77 360 427.967 360 426.975C360 425.984 359.198 425.181 358.209 425.181C357.22 425.181 356.418 425.984 356.418 426.975C356.418 427.967 357.22 428.77 358.209 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 428.77C353.228 428.77 354.03 427.967 354.03 426.975C354.03 425.984 353.228 425.181 352.239 425.181C351.25 425.181 350.448 425.984 350.448 426.975C350.448 427.967 351.25 428.77 352.239 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 428.77C347.258 428.77 348.06 427.967 348.06 426.975C348.06 425.984 347.258 425.181 346.269 425.181C345.279 425.181 344.478 425.984 344.478 426.975C344.478 427.967 345.279 428.77 346.269 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 428.77C341.288 428.77 342.09 427.967 342.09 426.975C342.09 425.984 341.288 425.181 340.299 425.181C339.309 425.181 338.507 425.984 338.507 426.975C338.507 427.967 339.309 428.77 340.299 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 428.77C335.318 428.77 336.119 427.967 336.119 426.975C336.119 425.984 335.318 425.181 334.328 425.181C333.339 425.181 332.537 425.984 332.537 426.975C332.537 427.967 333.339 428.77 334.328 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 428.77C329.347 428.77 330.149 427.967 330.149 426.975C330.149 425.984 329.347 425.181 328.358 425.181C327.369 425.181 326.567 425.984 326.567 426.975C326.567 427.967 327.369 428.77 328.358 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 428.77C323.377 428.77 324.179 427.967 324.179 426.975C324.179 425.984 323.377 425.181 322.388 425.181C321.399 425.181 320.597 425.984 320.597 426.975C320.597 427.967 321.399 428.77 322.388 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 428.77C317.407 428.77 318.209 427.967 318.209 426.975C318.209 425.984 317.407 425.181 316.418 425.181C315.429 425.181 314.627 425.984 314.627 426.975C314.627 427.967 315.429 428.77 316.418 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 428.77C311.437 428.77 312.239 427.967 312.239 426.975C312.239 425.984 311.437 425.181 310.448 425.181C309.459 425.181 308.657 425.984 308.657 426.975C308.657 427.967 309.459 428.77 310.448 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 428.77C305.467 428.77 306.269 427.967 306.269 426.975C306.269 425.984 305.467 425.181 304.478 425.181C303.488 425.181 302.687 425.984 302.687 426.975C302.687 427.967 303.488 428.77 304.478 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 428.77C299.497 428.77 300.299 427.967 300.299 426.975C300.299 425.984 299.497 425.181 298.507 425.181C297.518 425.181 296.716 425.984 296.716 426.975C296.716 427.967 297.518 428.77 298.507 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 428.77C293.526 428.77 294.328 427.967 294.328 426.975C294.328 425.984 293.526 425.181 292.537 425.181C291.548 425.181 290.746 425.984 290.746 426.975C290.746 427.967 291.548 428.77 292.537 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 428.77C287.556 428.77 288.358 427.967 288.358 426.975C288.358 425.984 287.556 425.181 286.567 425.181C285.578 425.181 284.776 425.984 284.776 426.975C284.776 427.967 285.578 428.77 286.567 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 428.77C281.586 428.77 282.388 427.967 282.388 426.975C282.388 425.984 281.586 425.181 280.597 425.181C279.608 425.181 278.806 425.984 278.806 426.975C278.806 427.967 279.608 428.77 280.597 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 428.77C275.616 428.77 276.418 427.967 276.418 426.975C276.418 425.984 275.616 425.181 274.627 425.181C273.638 425.181 272.836 425.984 272.836 426.975C272.836 427.967 273.638 428.77 274.627 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 428.77C269.646 428.77 270.448 427.967 270.448 426.975C270.448 425.984 269.646 425.181 268.657 425.181C267.668 425.181 266.866 425.984 266.866 426.975C266.866 427.967 267.668 428.77 268.657 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 428.77C263.676 428.77 264.478 427.967 264.478 426.975C264.478 425.984 263.676 425.181 262.687 425.181C261.697 425.181 260.896 425.984 260.896 426.975C260.896 427.967 261.697 428.77 262.687 428.77Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 422.788C377.109 422.788 377.91 421.984 377.91 420.993C377.91 420.002 377.109 419.198 376.119 419.198C375.13 419.198 374.328 420.002 374.328 420.993C374.328 421.984 375.13 422.788 376.119 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 422.788C371.138 422.788 371.94 421.984 371.94 420.993C371.94 420.002 371.138 419.198 370.149 419.198C369.16 419.198 368.358 420.002 368.358 420.993C368.358 421.984 369.16 422.788 370.149 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 422.788C365.168 422.788 365.97 421.984 365.97 420.993C365.97 420.002 365.168 419.198 364.179 419.198C363.19 419.198 362.388 420.002 362.388 420.993C362.388 421.984 363.19 422.788 364.179 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 422.788C359.198 422.788 360 421.984 360 420.993C360 420.002 359.198 419.198 358.209 419.198C357.22 419.198 356.418 420.002 356.418 420.993C356.418 421.984 357.22 422.788 358.209 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 422.788C353.228 422.788 354.03 421.984 354.03 420.993C354.03 420.002 353.228 419.198 352.239 419.198C351.25 419.198 350.448 420.002 350.448 420.993C350.448 421.984 351.25 422.788 352.239 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 422.788C347.258 422.788 348.06 421.984 348.06 420.993C348.06 420.002 347.258 419.198 346.269 419.198C345.279 419.198 344.478 420.002 344.478 420.993C344.478 421.984 345.279 422.788 346.269 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 422.788C341.288 422.788 342.09 421.984 342.09 420.993C342.09 420.002 341.288 419.198 340.299 419.198C339.309 419.198 338.507 420.002 338.507 420.993C338.507 421.984 339.309 422.788 340.299 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 422.788C335.318 422.788 336.119 421.984 336.119 420.993C336.119 420.002 335.318 419.198 334.328 419.198C333.339 419.198 332.537 420.002 332.537 420.993C332.537 421.984 333.339 422.788 334.328 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 422.788C329.347 422.788 330.149 421.984 330.149 420.993C330.149 420.002 329.347 419.198 328.358 419.198C327.369 419.198 326.567 420.002 326.567 420.993C326.567 421.984 327.369 422.788 328.358 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 422.788C323.377 422.788 324.179 421.984 324.179 420.993C324.179 420.002 323.377 419.198 322.388 419.198C321.399 419.198 320.597 420.002 320.597 420.993C320.597 421.984 321.399 422.788 322.388 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 422.788C317.407 422.788 318.209 421.984 318.209 420.993C318.209 420.002 317.407 419.198 316.418 419.198C315.429 419.198 314.627 420.002 314.627 420.993C314.627 421.984 315.429 422.788 316.418 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 422.788C311.437 422.788 312.239 421.984 312.239 420.993C312.239 420.002 311.437 419.198 310.448 419.198C309.459 419.198 308.657 420.002 308.657 420.993C308.657 421.984 309.459 422.788 310.448 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 422.788C305.467 422.788 306.269 421.984 306.269 420.993C306.269 420.002 305.467 419.198 304.478 419.198C303.488 419.198 302.687 420.002 302.687 420.993C302.687 421.984 303.488 422.788 304.478 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 422.788C299.497 422.788 300.299 421.984 300.299 420.993C300.299 420.002 299.497 419.198 298.507 419.198C297.518 419.198 296.716 420.002 296.716 420.993C296.716 421.984 297.518 422.788 298.507 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 422.788C293.526 422.788 294.328 421.984 294.328 420.993C294.328 420.002 293.526 419.198 292.537 419.198C291.548 419.198 290.746 420.002 290.746 420.993C290.746 421.984 291.548 422.788 292.537 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 422.788C287.556 422.788 288.358 421.984 288.358 420.993C288.358 420.002 287.556 419.198 286.567 419.198C285.578 419.198 284.776 420.002 284.776 420.993C284.776 421.984 285.578 422.788 286.567 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 422.788C281.586 422.788 282.388 421.984 282.388 420.993C282.388 420.002 281.586 419.198 280.597 419.198C279.608 419.198 278.806 420.002 278.806 420.993C278.806 421.984 279.608 422.788 280.597 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 422.788C275.616 422.788 276.418 421.984 276.418 420.993C276.418 420.002 275.616 419.198 274.627 419.198C273.638 419.198 272.836 420.002 272.836 420.993C272.836 421.984 273.638 422.788 274.627 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 422.788C269.646 422.788 270.448 421.984 270.448 420.993C270.448 420.002 269.646 419.198 268.657 419.198C267.668 419.198 266.866 420.002 266.866 420.993C266.866 421.984 267.668 422.788 268.657 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 422.788C263.676 422.788 264.478 421.984 264.478 420.993C264.478 420.002 263.676 419.198 262.687 419.198C261.697 419.198 260.896 420.002 260.896 420.993C260.896 421.984 261.697 422.788 262.687 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 422.788C257.706 422.788 258.507 421.984 258.507 420.993C258.507 420.002 257.706 419.198 256.716 419.198C255.727 419.198 254.925 420.002 254.925 420.993C254.925 421.984 255.727 422.788 256.716 422.788Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 416.805C377.109 416.805 377.91 416.002 377.91 415.011C377.91 414.019 377.109 413.216 376.119 413.216C375.13 413.216 374.328 414.019 374.328 415.011C374.328 416.002 375.13 416.805 376.119 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 416.805C371.138 416.805 371.94 416.002 371.94 415.011C371.94 414.019 371.138 413.216 370.149 413.216C369.16 413.216 368.358 414.019 368.358 415.011C368.358 416.002 369.16 416.805 370.149 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 416.805C365.168 416.805 365.97 416.002 365.97 415.011C365.97 414.019 365.168 413.216 364.179 413.216C363.19 413.216 362.388 414.019 362.388 415.011C362.388 416.002 363.19 416.805 364.179 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 416.805C359.198 416.805 360 416.002 360 415.011C360 414.019 359.198 413.216 358.209 413.216C357.22 413.216 356.418 414.019 356.418 415.011C356.418 416.002 357.22 416.805 358.209 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 416.805C353.228 416.805 354.03 416.002 354.03 415.011C354.03 414.019 353.228 413.216 352.239 413.216C351.25 413.216 350.448 414.019 350.448 415.011C350.448 416.002 351.25 416.805 352.239 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 416.805C347.258 416.805 348.06 416.002 348.06 415.011C348.06 414.019 347.258 413.216 346.269 413.216C345.279 413.216 344.478 414.019 344.478 415.011C344.478 416.002 345.279 416.805 346.269 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 416.805C341.288 416.805 342.09 416.002 342.09 415.011C342.09 414.019 341.288 413.216 340.299 413.216C339.309 413.216 338.507 414.019 338.507 415.011C338.507 416.002 339.309 416.805 340.299 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 416.805C335.318 416.805 336.119 416.002 336.119 415.011C336.119 414.019 335.318 413.216 334.328 413.216C333.339 413.216 332.537 414.019 332.537 415.011C332.537 416.002 333.339 416.805 334.328 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 416.805C329.347 416.805 330.149 416.002 330.149 415.011C330.149 414.019 329.347 413.216 328.358 413.216C327.369 413.216 326.567 414.019 326.567 415.011C326.567 416.002 327.369 416.805 328.358 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 416.805C323.377 416.805 324.179 416.002 324.179 415.011C324.179 414.019 323.377 413.216 322.388 413.216C321.399 413.216 320.597 414.019 320.597 415.011C320.597 416.002 321.399 416.805 322.388 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 416.805C317.407 416.805 318.209 416.002 318.209 415.011C318.209 414.019 317.407 413.216 316.418 413.216C315.429 413.216 314.627 414.019 314.627 415.011C314.627 416.002 315.429 416.805 316.418 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 416.805C311.437 416.805 312.239 416.002 312.239 415.011C312.239 414.019 311.437 413.216 310.448 413.216C309.459 413.216 308.657 414.019 308.657 415.011C308.657 416.002 309.459 416.805 310.448 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 416.805C305.467 416.805 306.269 416.002 306.269 415.011C306.269 414.019 305.467 413.216 304.478 413.216C303.488 413.216 302.687 414.019 302.687 415.011C302.687 416.002 303.488 416.805 304.478 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 416.805C299.497 416.805 300.299 416.002 300.299 415.011C300.299 414.019 299.497 413.216 298.507 413.216C297.518 413.216 296.716 414.019 296.716 415.011C296.716 416.002 297.518 416.805 298.507 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 416.805C293.526 416.805 294.328 416.002 294.328 415.011C294.328 414.019 293.526 413.216 292.537 413.216C291.548 413.216 290.746 414.019 290.746 415.011C290.746 416.002 291.548 416.805 292.537 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 416.805C287.556 416.805 288.358 416.002 288.358 415.011C288.358 414.019 287.556 413.216 286.567 413.216C285.578 413.216 284.776 414.019 284.776 415.011C284.776 416.002 285.578 416.805 286.567 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 416.805C281.586 416.805 282.388 416.002 282.388 415.011C282.388 414.019 281.586 413.216 280.597 413.216C279.608 413.216 278.806 414.019 278.806 415.011C278.806 416.002 279.608 416.805 280.597 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 416.805C275.616 416.805 276.418 416.002 276.418 415.011C276.418 414.019 275.616 413.216 274.627 413.216C273.638 413.216 272.836 414.019 272.836 415.011C272.836 416.002 273.638 416.805 274.627 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 416.805C269.646 416.805 270.448 416.002 270.448 415.011C270.448 414.019 269.646 413.216 268.657 413.216C267.668 413.216 266.866 414.019 266.866 415.011C266.866 416.002 267.668 416.805 268.657 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 416.805C263.676 416.805 264.478 416.002 264.478 415.011C264.478 414.019 263.676 413.216 262.687 413.216C261.697 413.216 260.896 414.019 260.896 415.011C260.896 416.002 261.697 416.805 262.687 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 416.805C257.706 416.805 258.507 416.002 258.507 415.011C258.507 414.019 257.706 413.216 256.716 413.216C255.727 413.216 254.925 414.019 254.925 415.011C254.925 416.002 255.727 416.805 256.716 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 416.805C251.735 416.805 252.537 416.002 252.537 415.011C252.537 414.019 251.735 413.216 250.746 413.216C249.757 413.216 248.955 414.019 248.955 415.011C248.955 416.002 249.757 416.805 250.746 416.805Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 410.823C377.109 410.823 377.91 410.019 377.91 409.028C377.91 408.037 377.109 407.233 376.119 407.233C375.13 407.233 374.328 408.037 374.328 409.028C374.328 410.019 375.13 410.823 376.119 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 410.823C371.138 410.823 371.94 410.019 371.94 409.028C371.94 408.037 371.138 407.233 370.149 407.233C369.16 407.233 368.358 408.037 368.358 409.028C368.358 410.019 369.16 410.823 370.149 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 410.823C365.168 410.823 365.97 410.019 365.97 409.028C365.97 408.037 365.168 407.233 364.179 407.233C363.19 407.233 362.388 408.037 362.388 409.028C362.388 410.019 363.19 410.823 364.179 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 410.823C359.198 410.823 360 410.019 360 409.028C360 408.037 359.198 407.233 358.209 407.233C357.22 407.233 356.418 408.037 356.418 409.028C356.418 410.019 357.22 410.823 358.209 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 410.823C353.228 410.823 354.03 410.019 354.03 409.028C354.03 408.037 353.228 407.233 352.239 407.233C351.25 407.233 350.448 408.037 350.448 409.028C350.448 410.019 351.25 410.823 352.239 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 410.823C347.258 410.823 348.06 410.019 348.06 409.028C348.06 408.037 347.258 407.233 346.269 407.233C345.279 407.233 344.478 408.037 344.478 409.028C344.478 410.019 345.279 410.823 346.269 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 410.823C341.288 410.823 342.09 410.019 342.09 409.028C342.09 408.037 341.288 407.233 340.299 407.233C339.309 407.233 338.507 408.037 338.507 409.028C338.507 410.019 339.309 410.823 340.299 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 410.823C335.318 410.823 336.119 410.019 336.119 409.028C336.119 408.037 335.318 407.233 334.328 407.233C333.339 407.233 332.537 408.037 332.537 409.028C332.537 410.019 333.339 410.823 334.328 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 410.823C329.347 410.823 330.149 410.019 330.149 409.028C330.149 408.037 329.347 407.233 328.358 407.233C327.369 407.233 326.567 408.037 326.567 409.028C326.567 410.019 327.369 410.823 328.358 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 410.823C323.377 410.823 324.179 410.019 324.179 409.028C324.179 408.037 323.377 407.233 322.388 407.233C321.399 407.233 320.597 408.037 320.597 409.028C320.597 410.019 321.399 410.823 322.388 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 410.823C317.407 410.823 318.209 410.019 318.209 409.028C318.209 408.037 317.407 407.233 316.418 407.233C315.429 407.233 314.627 408.037 314.627 409.028C314.627 410.019 315.429 410.823 316.418 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 410.823C311.437 410.823 312.239 410.019 312.239 409.028C312.239 408.037 311.437 407.233 310.448 407.233C309.459 407.233 308.657 408.037 308.657 409.028C308.657 410.019 309.459 410.823 310.448 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 410.823C305.467 410.823 306.269 410.019 306.269 409.028C306.269 408.037 305.467 407.233 304.478 407.233C303.488 407.233 302.687 408.037 302.687 409.028C302.687 410.019 303.488 410.823 304.478 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 410.823C299.497 410.823 300.299 410.019 300.299 409.028C300.299 408.037 299.497 407.233 298.507 407.233C297.518 407.233 296.716 408.037 296.716 409.028C296.716 410.019 297.518 410.823 298.507 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 410.823C293.526 410.823 294.328 410.019 294.328 409.028C294.328 408.037 293.526 407.233 292.537 407.233C291.548 407.233 290.746 408.037 290.746 409.028C290.746 410.019 291.548 410.823 292.537 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 410.823C287.556 410.823 288.358 410.019 288.358 409.028C288.358 408.037 287.556 407.233 286.567 407.233C285.578 407.233 284.776 408.037 284.776 409.028C284.776 410.019 285.578 410.823 286.567 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 410.823C281.586 410.823 282.388 410.019 282.388 409.028C282.388 408.037 281.586 407.233 280.597 407.233C279.608 407.233 278.806 408.037 278.806 409.028C278.806 410.019 279.608 410.823 280.597 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 410.823C275.616 410.823 276.418 410.019 276.418 409.028C276.418 408.037 275.616 407.233 274.627 407.233C273.638 407.233 272.836 408.037 272.836 409.028C272.836 410.019 273.638 410.823 274.627 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 410.823C269.646 410.823 270.448 410.019 270.448 409.028C270.448 408.037 269.646 407.233 268.657 407.233C267.668 407.233 266.866 408.037 266.866 409.028C266.866 410.019 267.668 410.823 268.657 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 410.823C263.676 410.823 264.478 410.019 264.478 409.028C264.478 408.037 263.676 407.233 262.687 407.233C261.697 407.233 260.896 408.037 260.896 409.028C260.896 410.019 261.697 410.823 262.687 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 410.823C257.706 410.823 258.507 410.019 258.507 409.028C258.507 408.037 257.706 407.233 256.716 407.233C255.727 407.233 254.925 408.037 254.925 409.028C254.925 410.019 255.727 410.823 256.716 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 410.823C251.735 410.823 252.537 410.019 252.537 409.028C252.537 408.037 251.735 407.233 250.746 407.233C249.757 407.233 248.955 408.037 248.955 409.028C248.955 410.019 249.757 410.823 250.746 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 410.823C245.765 410.823 246.567 410.019 246.567 409.028C246.567 408.037 245.765 407.233 244.776 407.233C243.787 407.233 242.985 408.037 242.985 409.028C242.985 410.019 243.787 410.823 244.776 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 410.823C239.795 410.823 240.597 410.019 240.597 409.028C240.597 408.037 239.795 407.233 238.806 407.233C237.817 407.233 237.015 408.037 237.015 409.028C237.015 410.019 237.817 410.823 238.806 410.823Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 404.841C377.109 404.841 377.91 404.037 377.91 403.046C377.91 402.055 377.109 401.251 376.119 401.251C375.13 401.251 374.328 402.055 374.328 403.046C374.328 404.037 375.13 404.841 376.119 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 404.841C371.138 404.841 371.94 404.037 371.94 403.046C371.94 402.055 371.138 401.251 370.149 401.251C369.16 401.251 368.358 402.055 368.358 403.046C368.358 404.037 369.16 404.841 370.149 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 404.841C365.168 404.841 365.97 404.037 365.97 403.046C365.97 402.055 365.168 401.251 364.179 401.251C363.19 401.251 362.388 402.055 362.388 403.046C362.388 404.037 363.19 404.841 364.179 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 404.841C359.198 404.841 360 404.037 360 403.046C360 402.055 359.198 401.251 358.209 401.251C357.22 401.251 356.418 402.055 356.418 403.046C356.418 404.037 357.22 404.841 358.209 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 404.841C353.228 404.841 354.03 404.037 354.03 403.046C354.03 402.055 353.228 401.251 352.239 401.251C351.25 401.251 350.448 402.055 350.448 403.046C350.448 404.037 351.25 404.841 352.239 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 404.841C347.258 404.841 348.06 404.037 348.06 403.046C348.06 402.055 347.258 401.251 346.269 401.251C345.279 401.251 344.478 402.055 344.478 403.046C344.478 404.037 345.279 404.841 346.269 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 404.841C341.288 404.841 342.09 404.037 342.09 403.046C342.09 402.055 341.288 401.251 340.299 401.251C339.309 401.251 338.507 402.055 338.507 403.046C338.507 404.037 339.309 404.841 340.299 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 404.841C335.318 404.841 336.119 404.037 336.119 403.046C336.119 402.055 335.318 401.251 334.328 401.251C333.339 401.251 332.537 402.055 332.537 403.046C332.537 404.037 333.339 404.841 334.328 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 404.841C329.347 404.841 330.149 404.037 330.149 403.046C330.149 402.055 329.347 401.251 328.358 401.251C327.369 401.251 326.567 402.055 326.567 403.046C326.567 404.037 327.369 404.841 328.358 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 404.841C323.377 404.841 324.179 404.037 324.179 403.046C324.179 402.055 323.377 401.251 322.388 401.251C321.399 401.251 320.597 402.055 320.597 403.046C320.597 404.037 321.399 404.841 322.388 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 404.841C317.407 404.841 318.209 404.037 318.209 403.046C318.209 402.055 317.407 401.251 316.418 401.251C315.429 401.251 314.627 402.055 314.627 403.046C314.627 404.037 315.429 404.841 316.418 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 404.841C311.437 404.841 312.239 404.037 312.239 403.046C312.239 402.055 311.437 401.251 310.448 401.251C309.459 401.251 308.657 402.055 308.657 403.046C308.657 404.037 309.459 404.841 310.448 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 404.841C305.467 404.841 306.269 404.037 306.269 403.046C306.269 402.055 305.467 401.251 304.478 401.251C303.488 401.251 302.687 402.055 302.687 403.046C302.687 404.037 303.488 404.841 304.478 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 404.841C299.497 404.841 300.299 404.037 300.299 403.046C300.299 402.055 299.497 401.251 298.507 401.251C297.518 401.251 296.716 402.055 296.716 403.046C296.716 404.037 297.518 404.841 298.507 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 404.841C293.526 404.841 294.328 404.037 294.328 403.046C294.328 402.055 293.526 401.251 292.537 401.251C291.548 401.251 290.746 402.055 290.746 403.046C290.746 404.037 291.548 404.841 292.537 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 404.841C287.556 404.841 288.358 404.037 288.358 403.046C288.358 402.055 287.556 401.251 286.567 401.251C285.578 401.251 284.776 402.055 284.776 403.046C284.776 404.037 285.578 404.841 286.567 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 404.841C281.586 404.841 282.388 404.037 282.388 403.046C282.388 402.055 281.586 401.251 280.597 401.251C279.608 401.251 278.806 402.055 278.806 403.046C278.806 404.037 279.608 404.841 280.597 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 404.841C275.616 404.841 276.418 404.037 276.418 403.046C276.418 402.055 275.616 401.251 274.627 401.251C273.638 401.251 272.836 402.055 272.836 403.046C272.836 404.037 273.638 404.841 274.627 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 404.841C269.646 404.841 270.448 404.037 270.448 403.046C270.448 402.055 269.646 401.251 268.657 401.251C267.668 401.251 266.866 402.055 266.866 403.046C266.866 404.037 267.668 404.841 268.657 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 404.841C263.676 404.841 264.478 404.037 264.478 403.046C264.478 402.055 263.676 401.251 262.687 401.251C261.697 401.251 260.896 402.055 260.896 403.046C260.896 404.037 261.697 404.841 262.687 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 404.841C257.706 404.841 258.507 404.037 258.507 403.046C258.507 402.055 257.706 401.251 256.716 401.251C255.727 401.251 254.925 402.055 254.925 403.046C254.925 404.037 255.727 404.841 256.716 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 404.841C251.735 404.841 252.537 404.037 252.537 403.046C252.537 402.055 251.735 401.251 250.746 401.251C249.757 401.251 248.955 402.055 248.955 403.046C248.955 404.037 249.757 404.841 250.746 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 404.841C245.765 404.841 246.567 404.037 246.567 403.046C246.567 402.055 245.765 401.251 244.776 401.251C243.787 401.251 242.985 402.055 242.985 403.046C242.985 404.037 243.787 404.841 244.776 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 404.841C239.795 404.841 240.597 404.037 240.597 403.046C240.597 402.055 239.795 401.251 238.806 401.251C237.817 401.251 237.015 402.055 237.015 403.046C237.015 404.037 237.817 404.841 238.806 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 404.841C233.825 404.841 234.627 404.037 234.627 403.046C234.627 402.055 233.825 401.251 232.836 401.251C231.847 401.251 231.045 402.055 231.045 403.046C231.045 404.037 231.847 404.841 232.836 404.841Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 398.859C383.079 398.859 383.881 398.055 383.881 397.064C383.881 396.073 383.079 395.27 382.09 395.27C381.1 395.27 380.298 396.073 380.298 397.064C380.298 398.055 381.1 398.859 382.09 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 398.859C377.109 398.859 377.91 398.055 377.91 397.064C377.91 396.073 377.109 395.27 376.119 395.27C375.13 395.27 374.328 396.073 374.328 397.064C374.328 398.055 375.13 398.859 376.119 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 398.859C371.138 398.859 371.94 398.055 371.94 397.064C371.94 396.073 371.138 395.27 370.149 395.27C369.16 395.27 368.358 396.073 368.358 397.064C368.358 398.055 369.16 398.859 370.149 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 398.859C365.168 398.859 365.97 398.055 365.97 397.064C365.97 396.073 365.168 395.27 364.179 395.27C363.19 395.27 362.388 396.073 362.388 397.064C362.388 398.055 363.19 398.859 364.179 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 398.859C359.198 398.859 360 398.055 360 397.064C360 396.073 359.198 395.27 358.209 395.27C357.22 395.27 356.418 396.073 356.418 397.064C356.418 398.055 357.22 398.859 358.209 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 398.859C353.228 398.859 354.03 398.055 354.03 397.064C354.03 396.073 353.228 395.27 352.239 395.27C351.25 395.27 350.448 396.073 350.448 397.064C350.448 398.055 351.25 398.859 352.239 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 398.859C347.258 398.859 348.06 398.055 348.06 397.064C348.06 396.073 347.258 395.27 346.269 395.27C345.279 395.27 344.478 396.073 344.478 397.064C344.478 398.055 345.279 398.859 346.269 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M340.298 398.859C341.288 398.859 342.09 398.055 342.09 397.064C342.09 396.073 341.288 395.27 340.298 395.27C339.309 395.27 338.507 396.073 338.507 397.064C338.507 398.055 339.309 398.859 340.298 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 398.859C335.318 398.859 336.119 398.055 336.119 397.064C336.119 396.073 335.318 395.27 334.328 395.27C333.339 395.27 332.537 396.073 332.537 397.064C332.537 398.055 333.339 398.859 334.328 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 398.859C329.347 398.859 330.149 398.055 330.149 397.064C330.149 396.073 329.347 395.27 328.358 395.27C327.369 395.27 326.567 396.073 326.567 397.064C326.567 398.055 327.369 398.859 328.358 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 398.859C323.377 398.859 324.179 398.055 324.179 397.064C324.179 396.073 323.377 395.27 322.388 395.27C321.399 395.27 320.597 396.073 320.597 397.064C320.597 398.055 321.399 398.859 322.388 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 398.859C317.407 398.859 318.209 398.055 318.209 397.064C318.209 396.073 317.407 395.27 316.418 395.27C315.429 395.27 314.627 396.073 314.627 397.064C314.627 398.055 315.429 398.859 316.418 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 398.859C311.437 398.859 312.239 398.055 312.239 397.064C312.239 396.073 311.437 395.27 310.448 395.27C309.459 395.27 308.657 396.073 308.657 397.064C308.657 398.055 309.459 398.859 310.448 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 398.859C305.467 398.859 306.269 398.055 306.269 397.064C306.269 396.073 305.467 395.27 304.478 395.27C303.488 395.27 302.687 396.073 302.687 397.064C302.687 398.055 303.488 398.859 304.478 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 398.859C299.497 398.859 300.299 398.055 300.299 397.064C300.299 396.073 299.497 395.27 298.507 395.27C297.518 395.27 296.716 396.073 296.716 397.064C296.716 398.055 297.518 398.859 298.507 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 398.859C293.526 398.859 294.328 398.055 294.328 397.064C294.328 396.073 293.526 395.27 292.537 395.27C291.548 395.27 290.746 396.073 290.746 397.064C290.746 398.055 291.548 398.859 292.537 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 398.859C287.556 398.859 288.358 398.055 288.358 397.064C288.358 396.073 287.556 395.27 286.567 395.27C285.578 395.27 284.776 396.073 284.776 397.064C284.776 398.055 285.578 398.859 286.567 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 398.859C281.586 398.859 282.388 398.055 282.388 397.064C282.388 396.073 281.586 395.27 280.597 395.27C279.608 395.27 278.806 396.073 278.806 397.064C278.806 398.055 279.608 398.859 280.597 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 398.859C275.616 398.859 276.418 398.055 276.418 397.064C276.418 396.073 275.616 395.27 274.627 395.27C273.638 395.27 272.836 396.073 272.836 397.064C272.836 398.055 273.638 398.859 274.627 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 398.859C269.646 398.859 270.448 398.055 270.448 397.064C270.448 396.073 269.646 395.27 268.657 395.27C267.668 395.27 266.866 396.073 266.866 397.064C266.866 398.055 267.668 398.859 268.657 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 398.859C263.676 398.859 264.478 398.055 264.478 397.064C264.478 396.073 263.676 395.27 262.687 395.27C261.697 395.27 260.896 396.073 260.896 397.064C260.896 398.055 261.697 398.859 262.687 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 398.859C257.706 398.859 258.507 398.055 258.507 397.064C258.507 396.073 257.706 395.27 256.716 395.27C255.727 395.27 254.925 396.073 254.925 397.064C254.925 398.055 255.727 398.859 256.716 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 398.859C251.735 398.859 252.537 398.055 252.537 397.064C252.537 396.073 251.735 395.27 250.746 395.27C249.757 395.27 248.955 396.073 248.955 397.064C248.955 398.055 249.757 398.859 250.746 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 398.859C245.765 398.859 246.567 398.055 246.567 397.064C246.567 396.073 245.765 395.27 244.776 395.27C243.787 395.27 242.985 396.073 242.985 397.064C242.985 398.055 243.787 398.859 244.776 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 398.859C239.795 398.859 240.597 398.055 240.597 397.064C240.597 396.073 239.795 395.27 238.806 395.27C237.817 395.27 237.015 396.073 237.015 397.064C237.015 398.055 237.817 398.859 238.806 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 398.859C233.825 398.859 234.627 398.055 234.627 397.064C234.627 396.073 233.825 395.27 232.836 395.27C231.847 395.27 231.045 396.073 231.045 397.064C231.045 398.055 231.847 398.859 232.836 398.859Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 392.876C383.079 392.876 383.881 392.072 383.881 391.081C383.881 390.09 383.079 389.287 382.09 389.287C381.1 389.287 380.298 390.09 380.298 391.081C380.298 392.072 381.1 392.876 382.09 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 392.876C377.109 392.876 377.91 392.072 377.91 391.081C377.91 390.09 377.109 389.287 376.119 389.287C375.13 389.287 374.328 390.09 374.328 391.081C374.328 392.072 375.13 392.876 376.119 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 392.876C371.138 392.876 371.94 392.072 371.94 391.081C371.94 390.09 371.138 389.287 370.149 389.287C369.16 389.287 368.358 390.09 368.358 391.081C368.358 392.072 369.16 392.876 370.149 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 392.876C365.168 392.876 365.97 392.072 365.97 391.081C365.97 390.09 365.168 389.287 364.179 389.287C363.19 389.287 362.388 390.09 362.388 391.081C362.388 392.072 363.19 392.876 364.179 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 392.876C359.198 392.876 360 392.072 360 391.081C360 390.09 359.198 389.287 358.209 389.287C357.22 389.287 356.418 390.09 356.418 391.081C356.418 392.072 357.22 392.876 358.209 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 392.876C353.228 392.876 354.03 392.072 354.03 391.081C354.03 390.09 353.228 389.287 352.239 389.287C351.25 389.287 350.448 390.09 350.448 391.081C350.448 392.072 351.25 392.876 352.239 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 392.876C347.258 392.876 348.06 392.072 348.06 391.081C348.06 390.09 347.258 389.287 346.269 389.287C345.279 389.287 344.478 390.09 344.478 391.081C344.478 392.072 345.279 392.876 346.269 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 392.876C341.288 392.876 342.09 392.072 342.09 391.081C342.09 390.09 341.288 389.287 340.299 389.287C339.309 389.287 338.507 390.09 338.507 391.081C338.507 392.072 339.309 392.876 340.299 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 392.876C335.318 392.876 336.119 392.072 336.119 391.081C336.119 390.09 335.318 389.287 334.328 389.287C333.339 389.287 332.537 390.09 332.537 391.081C332.537 392.072 333.339 392.876 334.328 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 392.876C329.347 392.876 330.149 392.072 330.149 391.081C330.149 390.09 329.347 389.287 328.358 389.287C327.369 389.287 326.567 390.09 326.567 391.081C326.567 392.072 327.369 392.876 328.358 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 392.876C323.377 392.876 324.179 392.072 324.179 391.081C324.179 390.09 323.377 389.287 322.388 389.287C321.399 389.287 320.597 390.09 320.597 391.081C320.597 392.072 321.399 392.876 322.388 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 392.876C317.407 392.876 318.209 392.072 318.209 391.081C318.209 390.09 317.407 389.287 316.418 389.287C315.429 389.287 314.627 390.09 314.627 391.081C314.627 392.072 315.429 392.876 316.418 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 392.876C311.437 392.876 312.239 392.072 312.239 391.081C312.239 390.09 311.437 389.287 310.448 389.287C309.459 389.287 308.657 390.09 308.657 391.081C308.657 392.072 309.459 392.876 310.448 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 392.876C305.467 392.876 306.269 392.072 306.269 391.081C306.269 390.09 305.467 389.287 304.478 389.287C303.488 389.287 302.687 390.09 302.687 391.081C302.687 392.072 303.488 392.876 304.478 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 392.876C299.497 392.876 300.299 392.072 300.299 391.081C300.299 390.09 299.497 389.287 298.507 389.287C297.518 389.287 296.716 390.09 296.716 391.081C296.716 392.072 297.518 392.876 298.507 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 392.876C293.526 392.876 294.328 392.072 294.328 391.081C294.328 390.09 293.526 389.287 292.537 389.287C291.548 389.287 290.746 390.09 290.746 391.081C290.746 392.072 291.548 392.876 292.537 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 392.876C287.556 392.876 288.358 392.072 288.358 391.081C288.358 390.09 287.556 389.287 286.567 389.287C285.578 389.287 284.776 390.09 284.776 391.081C284.776 392.072 285.578 392.876 286.567 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 392.876C281.586 392.876 282.388 392.072 282.388 391.081C282.388 390.09 281.586 389.287 280.597 389.287C279.608 389.287 278.806 390.09 278.806 391.081C278.806 392.072 279.608 392.876 280.597 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 392.876C275.616 392.876 276.418 392.072 276.418 391.081C276.418 390.09 275.616 389.287 274.627 389.287C273.638 389.287 272.836 390.09 272.836 391.081C272.836 392.072 273.638 392.876 274.627 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 392.876C269.646 392.876 270.448 392.072 270.448 391.081C270.448 390.09 269.646 389.287 268.657 389.287C267.668 389.287 266.866 390.09 266.866 391.081C266.866 392.072 267.668 392.876 268.657 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 392.876C263.676 392.876 264.478 392.072 264.478 391.081C264.478 390.09 263.676 389.287 262.687 389.287C261.697 389.287 260.896 390.09 260.896 391.081C260.896 392.072 261.697 392.876 262.687 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 392.876C257.706 392.876 258.507 392.072 258.507 391.081C258.507 390.09 257.706 389.287 256.716 389.287C255.727 389.287 254.925 390.09 254.925 391.081C254.925 392.072 255.727 392.876 256.716 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 392.876C251.735 392.876 252.537 392.072 252.537 391.081C252.537 390.09 251.735 389.287 250.746 389.287C249.757 389.287 248.955 390.09 248.955 391.081C248.955 392.072 249.757 392.876 250.746 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 392.876C245.765 392.876 246.567 392.072 246.567 391.081C246.567 390.09 245.765 389.287 244.776 389.287C243.787 389.287 242.985 390.09 242.985 391.081C242.985 392.072 243.787 392.876 244.776 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 392.876C239.795 392.876 240.597 392.072 240.597 391.081C240.597 390.09 239.795 389.287 238.806 389.287C237.817 389.287 237.015 390.09 237.015 391.081C237.015 392.072 237.817 392.876 238.806 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 392.876C233.825 392.876 234.627 392.072 234.627 391.081C234.627 390.09 233.825 389.287 232.836 389.287C231.847 389.287 231.045 390.09 231.045 391.081C231.045 392.072 231.847 392.876 232.836 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 392.876C227.855 392.876 228.657 392.072 228.657 391.081C228.657 390.09 227.855 389.287 226.866 389.287C225.877 389.287 225.075 390.09 225.075 391.081C225.075 392.072 225.877 392.876 226.866 392.876Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 386.894C389.049 386.894 389.851 386.091 389.851 385.099C389.851 384.108 389.049 383.305 388.06 383.305C387.071 383.305 386.269 384.108 386.269 385.099C386.269 386.091 387.071 386.894 388.06 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 386.894C383.079 386.894 383.881 386.091 383.881 385.099C383.881 384.108 383.079 383.305 382.09 383.305C381.1 383.305 380.298 384.108 380.298 385.099C380.298 386.091 381.1 386.894 382.09 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 386.894C377.109 386.894 377.91 386.091 377.91 385.099C377.91 384.108 377.109 383.305 376.119 383.305C375.13 383.305 374.328 384.108 374.328 385.099C374.328 386.091 375.13 386.894 376.119 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 386.894C371.138 386.894 371.94 386.091 371.94 385.099C371.94 384.108 371.138 383.305 370.149 383.305C369.16 383.305 368.358 384.108 368.358 385.099C368.358 386.091 369.16 386.894 370.149 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 386.894C365.168 386.894 365.97 386.091 365.97 385.099C365.97 384.108 365.168 383.305 364.179 383.305C363.19 383.305 362.388 384.108 362.388 385.099C362.388 386.091 363.19 386.894 364.179 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 386.894C359.198 386.894 360 386.091 360 385.099C360 384.108 359.198 383.305 358.209 383.305C357.22 383.305 356.418 384.108 356.418 385.099C356.418 386.091 357.22 386.894 358.209 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 386.894C353.228 386.894 354.03 386.091 354.03 385.099C354.03 384.108 353.228 383.305 352.239 383.305C351.25 383.305 350.448 384.108 350.448 385.099C350.448 386.091 351.25 386.894 352.239 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 386.894C347.258 386.894 348.06 386.091 348.06 385.099C348.06 384.108 347.258 383.305 346.269 383.305C345.279 383.305 344.478 384.108 344.478 385.099C344.478 386.091 345.279 386.894 346.269 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 386.894C341.288 386.894 342.09 386.091 342.09 385.099C342.09 384.108 341.288 383.305 340.299 383.305C339.309 383.305 338.507 384.108 338.507 385.099C338.507 386.091 339.309 386.894 340.299 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 386.894C335.318 386.894 336.119 386.091 336.119 385.099C336.119 384.108 335.318 383.305 334.328 383.305C333.339 383.305 332.537 384.108 332.537 385.099C332.537 386.091 333.339 386.894 334.328 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 386.894C329.347 386.894 330.149 386.091 330.149 385.099C330.149 384.108 329.347 383.305 328.358 383.305C327.369 383.305 326.567 384.108 326.567 385.099C326.567 386.091 327.369 386.894 328.358 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 386.894C323.377 386.894 324.179 386.091 324.179 385.099C324.179 384.108 323.377 383.305 322.388 383.305C321.399 383.305 320.597 384.108 320.597 385.099C320.597 386.091 321.399 386.894 322.388 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 386.894C317.407 386.894 318.209 386.091 318.209 385.099C318.209 384.108 317.407 383.305 316.418 383.305C315.429 383.305 314.627 384.108 314.627 385.099C314.627 386.091 315.429 386.894 316.418 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 386.894C311.437 386.894 312.239 386.091 312.239 385.099C312.239 384.108 311.437 383.305 310.448 383.305C309.459 383.305 308.657 384.108 308.657 385.099C308.657 386.091 309.459 386.894 310.448 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 386.894C305.467 386.894 306.269 386.091 306.269 385.099C306.269 384.108 305.467 383.305 304.478 383.305C303.488 383.305 302.687 384.108 302.687 385.099C302.687 386.091 303.488 386.894 304.478 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 386.894C299.497 386.894 300.299 386.091 300.299 385.099C300.299 384.108 299.497 383.305 298.507 383.305C297.518 383.305 296.716 384.108 296.716 385.099C296.716 386.091 297.518 386.894 298.507 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 386.894C293.526 386.894 294.328 386.091 294.328 385.099C294.328 384.108 293.526 383.305 292.537 383.305C291.548 383.305 290.746 384.108 290.746 385.099C290.746 386.091 291.548 386.894 292.537 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 386.894C287.556 386.894 288.358 386.091 288.358 385.099C288.358 384.108 287.556 383.305 286.567 383.305C285.578 383.305 284.776 384.108 284.776 385.099C284.776 386.091 285.578 386.894 286.567 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 386.894C281.586 386.894 282.388 386.091 282.388 385.099C282.388 384.108 281.586 383.305 280.597 383.305C279.608 383.305 278.806 384.108 278.806 385.099C278.806 386.091 279.608 386.894 280.597 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 386.894C275.616 386.894 276.418 386.091 276.418 385.099C276.418 384.108 275.616 383.305 274.627 383.305C273.638 383.305 272.836 384.108 272.836 385.099C272.836 386.091 273.638 386.894 274.627 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 386.894C269.646 386.894 270.448 386.091 270.448 385.099C270.448 384.108 269.646 383.305 268.657 383.305C267.668 383.305 266.866 384.108 266.866 385.099C266.866 386.091 267.668 386.894 268.657 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 386.894C263.676 386.894 264.478 386.091 264.478 385.099C264.478 384.108 263.676 383.305 262.687 383.305C261.697 383.305 260.896 384.108 260.896 385.099C260.896 386.091 261.697 386.894 262.687 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 386.894C257.706 386.894 258.507 386.091 258.507 385.099C258.507 384.108 257.706 383.305 256.716 383.305C255.727 383.305 254.925 384.108 254.925 385.099C254.925 386.091 255.727 386.894 256.716 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 386.894C251.735 386.894 252.537 386.091 252.537 385.099C252.537 384.108 251.735 383.305 250.746 383.305C249.757 383.305 248.955 384.108 248.955 385.099C248.955 386.091 249.757 386.894 250.746 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 386.894C245.765 386.894 246.567 386.091 246.567 385.099C246.567 384.108 245.765 383.305 244.776 383.305C243.787 383.305 242.985 384.108 242.985 385.099C242.985 386.091 243.787 386.894 244.776 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 386.894C239.795 386.894 240.597 386.091 240.597 385.099C240.597 384.108 239.795 383.305 238.806 383.305C237.817 383.305 237.015 384.108 237.015 385.099C237.015 386.091 237.817 386.894 238.806 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 386.894C233.825 386.894 234.627 386.091 234.627 385.099C234.627 384.108 233.825 383.305 232.836 383.305C231.847 383.305 231.045 384.108 231.045 385.099C231.045 386.091 231.847 386.894 232.836 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 386.894C227.855 386.894 228.657 386.091 228.657 385.099C228.657 384.108 227.855 383.305 226.866 383.305C225.877 383.305 225.075 384.108 225.075 385.099C225.075 386.091 225.877 386.894 226.866 386.894Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 380.912C395.019 380.912 395.821 380.108 395.821 379.117C395.821 378.126 395.019 377.322 394.03 377.322C393.041 377.322 392.239 378.126 392.239 379.117C392.239 380.108 393.041 380.912 394.03 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 380.912C389.049 380.912 389.851 380.108 389.851 379.117C389.851 378.126 389.049 377.322 388.06 377.322C387.071 377.322 386.269 378.126 386.269 379.117C386.269 380.108 387.071 380.912 388.06 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 380.912C383.079 380.912 383.881 380.108 383.881 379.117C383.881 378.126 383.079 377.322 382.09 377.322C381.1 377.322 380.298 378.126 380.298 379.117C380.298 380.108 381.1 380.912 382.09 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 380.912C377.109 380.912 377.91 380.108 377.91 379.117C377.91 378.126 377.109 377.322 376.119 377.322C375.13 377.322 374.328 378.126 374.328 379.117C374.328 380.108 375.13 380.912 376.119 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 380.912C371.138 380.912 371.94 380.108 371.94 379.117C371.94 378.126 371.138 377.322 370.149 377.322C369.16 377.322 368.358 378.126 368.358 379.117C368.358 380.108 369.16 380.912 370.149 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 380.912C365.168 380.912 365.97 380.108 365.97 379.117C365.97 378.126 365.168 377.322 364.179 377.322C363.19 377.322 362.388 378.126 362.388 379.117C362.388 380.108 363.19 380.912 364.179 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 380.912C359.198 380.912 360 380.108 360 379.117C360 378.126 359.198 377.322 358.209 377.322C357.22 377.322 356.418 378.126 356.418 379.117C356.418 380.108 357.22 380.912 358.209 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 380.912C353.228 380.912 354.03 380.108 354.03 379.117C354.03 378.126 353.228 377.322 352.239 377.322C351.25 377.322 350.448 378.126 350.448 379.117C350.448 380.108 351.25 380.912 352.239 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 380.912C347.258 380.912 348.06 380.108 348.06 379.117C348.06 378.126 347.258 377.322 346.269 377.322C345.279 377.322 344.478 378.126 344.478 379.117C344.478 380.108 345.279 380.912 346.269 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 380.912C341.288 380.912 342.09 380.108 342.09 379.117C342.09 378.126 341.288 377.322 340.299 377.322C339.309 377.322 338.507 378.126 338.507 379.117C338.507 380.108 339.309 380.912 340.299 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 380.912C335.318 380.912 336.119 380.108 336.119 379.117C336.119 378.126 335.318 377.322 334.328 377.322C333.339 377.322 332.537 378.126 332.537 379.117C332.537 380.108 333.339 380.912 334.328 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 380.912C329.347 380.912 330.149 380.108 330.149 379.117C330.149 378.126 329.347 377.322 328.358 377.322C327.369 377.322 326.567 378.126 326.567 379.117C326.567 380.108 327.369 380.912 328.358 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 380.912C323.377 380.912 324.179 380.108 324.179 379.117C324.179 378.126 323.377 377.322 322.388 377.322C321.399 377.322 320.597 378.126 320.597 379.117C320.597 380.108 321.399 380.912 322.388 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 380.912C317.407 380.912 318.209 380.108 318.209 379.117C318.209 378.126 317.407 377.322 316.418 377.322C315.429 377.322 314.627 378.126 314.627 379.117C314.627 380.108 315.429 380.912 316.418 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 380.912C311.437 380.912 312.239 380.108 312.239 379.117C312.239 378.126 311.437 377.322 310.448 377.322C309.459 377.322 308.657 378.126 308.657 379.117C308.657 380.108 309.459 380.912 310.448 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 380.912C305.467 380.912 306.269 380.108 306.269 379.117C306.269 378.126 305.467 377.322 304.478 377.322C303.488 377.322 302.687 378.126 302.687 379.117C302.687 380.108 303.488 380.912 304.478 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 380.912C299.497 380.912 300.299 380.108 300.299 379.117C300.299 378.126 299.497 377.322 298.507 377.322C297.518 377.322 296.716 378.126 296.716 379.117C296.716 380.108 297.518 380.912 298.507 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 380.912C293.526 380.912 294.328 380.108 294.328 379.117C294.328 378.126 293.526 377.322 292.537 377.322C291.548 377.322 290.746 378.126 290.746 379.117C290.746 380.108 291.548 380.912 292.537 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 380.912C287.556 380.912 288.358 380.108 288.358 379.117C288.358 378.126 287.556 377.322 286.567 377.322C285.578 377.322 284.776 378.126 284.776 379.117C284.776 380.108 285.578 380.912 286.567 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 380.912C281.586 380.912 282.388 380.108 282.388 379.117C282.388 378.126 281.586 377.322 280.597 377.322C279.608 377.322 278.806 378.126 278.806 379.117C278.806 380.108 279.608 380.912 280.597 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 380.912C275.616 380.912 276.418 380.108 276.418 379.117C276.418 378.126 275.616 377.322 274.627 377.322C273.638 377.322 272.836 378.126 272.836 379.117C272.836 380.108 273.638 380.912 274.627 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 380.912C269.646 380.912 270.448 380.108 270.448 379.117C270.448 378.126 269.646 377.322 268.657 377.322C267.668 377.322 266.866 378.126 266.866 379.117C266.866 380.108 267.668 380.912 268.657 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 380.912C263.676 380.912 264.478 380.108 264.478 379.117C264.478 378.126 263.676 377.322 262.687 377.322C261.697 377.322 260.896 378.126 260.896 379.117C260.896 380.108 261.697 380.912 262.687 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 380.912C257.706 380.912 258.507 380.108 258.507 379.117C258.507 378.126 257.706 377.322 256.716 377.322C255.727 377.322 254.925 378.126 254.925 379.117C254.925 380.108 255.727 380.912 256.716 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 380.912C251.735 380.912 252.537 380.108 252.537 379.117C252.537 378.126 251.735 377.322 250.746 377.322C249.757 377.322 248.955 378.126 248.955 379.117C248.955 380.108 249.757 380.912 250.746 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 380.912C245.765 380.912 246.567 380.108 246.567 379.117C246.567 378.126 245.765 377.322 244.776 377.322C243.787 377.322 242.985 378.126 242.985 379.117C242.985 380.108 243.787 380.912 244.776 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 380.912C239.795 380.912 240.597 380.108 240.597 379.117C240.597 378.126 239.795 377.322 238.806 377.322C237.817 377.322 237.015 378.126 237.015 379.117C237.015 380.108 237.817 380.912 238.806 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 380.912C233.825 380.912 234.627 380.108 234.627 379.117C234.627 378.126 233.825 377.322 232.836 377.322C231.847 377.322 231.045 378.126 231.045 379.117C231.045 380.108 231.847 380.912 232.836 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 380.912C227.855 380.912 228.657 380.108 228.657 379.117C228.657 378.126 227.855 377.322 226.866 377.322C225.877 377.322 225.075 378.126 225.075 379.117C225.075 380.108 225.877 380.912 226.866 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 380.912C221.885 380.912 222.687 380.108 222.687 379.117C222.687 378.126 221.885 377.322 220.896 377.322C219.906 377.322 219.104 378.126 219.104 379.117C219.104 380.108 219.906 380.912 220.896 380.912Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 374.929C395.019 374.929 395.821 374.126 395.821 373.135C395.821 372.143 395.019 371.34 394.03 371.34C393.041 371.34 392.239 372.143 392.239 373.135C392.239 374.126 393.041 374.929 394.03 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 374.929C389.049 374.929 389.851 374.126 389.851 373.135C389.851 372.143 389.049 371.34 388.06 371.34C387.071 371.34 386.269 372.143 386.269 373.135C386.269 374.126 387.071 374.929 388.06 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 374.929C383.079 374.929 383.881 374.126 383.881 373.135C383.881 372.143 383.079 371.34 382.09 371.34C381.1 371.34 380.298 372.143 380.298 373.135C380.298 374.126 381.1 374.929 382.09 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 374.929C377.109 374.929 377.91 374.126 377.91 373.135C377.91 372.143 377.109 371.34 376.119 371.34C375.13 371.34 374.328 372.143 374.328 373.135C374.328 374.126 375.13 374.929 376.119 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 374.929C371.138 374.929 371.94 374.126 371.94 373.135C371.94 372.143 371.138 371.34 370.149 371.34C369.16 371.34 368.358 372.143 368.358 373.135C368.358 374.126 369.16 374.929 370.149 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 374.929C365.168 374.929 365.97 374.126 365.97 373.135C365.97 372.143 365.168 371.34 364.179 371.34C363.19 371.34 362.388 372.143 362.388 373.135C362.388 374.126 363.19 374.929 364.179 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 374.929C359.198 374.929 360 374.126 360 373.135C360 372.143 359.198 371.34 358.209 371.34C357.22 371.34 356.418 372.143 356.418 373.135C356.418 374.126 357.22 374.929 358.209 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 374.929C353.228 374.929 354.03 374.126 354.03 373.135C354.03 372.143 353.228 371.34 352.239 371.34C351.25 371.34 350.448 372.143 350.448 373.135C350.448 374.126 351.25 374.929 352.239 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 374.929C347.258 374.929 348.06 374.126 348.06 373.135C348.06 372.143 347.258 371.34 346.269 371.34C345.279 371.34 344.478 372.143 344.478 373.135C344.478 374.126 345.279 374.929 346.269 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 374.929C341.288 374.929 342.09 374.126 342.09 373.135C342.09 372.143 341.288 371.34 340.299 371.34C339.309 371.34 338.507 372.143 338.507 373.135C338.507 374.126 339.309 374.929 340.299 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 374.929C335.318 374.929 336.119 374.126 336.119 373.135C336.119 372.143 335.318 371.34 334.328 371.34C333.339 371.34 332.537 372.143 332.537 373.135C332.537 374.126 333.339 374.929 334.328 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 374.929C329.347 374.929 330.149 374.126 330.149 373.135C330.149 372.143 329.347 371.34 328.358 371.34C327.369 371.34 326.567 372.143 326.567 373.135C326.567 374.126 327.369 374.929 328.358 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 374.929C323.377 374.929 324.179 374.126 324.179 373.135C324.179 372.143 323.377 371.34 322.388 371.34C321.399 371.34 320.597 372.143 320.597 373.135C320.597 374.126 321.399 374.929 322.388 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 374.929C317.407 374.929 318.209 374.126 318.209 373.135C318.209 372.143 317.407 371.34 316.418 371.34C315.429 371.34 314.627 372.143 314.627 373.135C314.627 374.126 315.429 374.929 316.418 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 374.929C311.437 374.929 312.239 374.126 312.239 373.135C312.239 372.143 311.437 371.34 310.448 371.34C309.459 371.34 308.657 372.143 308.657 373.135C308.657 374.126 309.459 374.929 310.448 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 374.929C305.467 374.929 306.269 374.126 306.269 373.135C306.269 372.143 305.467 371.34 304.478 371.34C303.488 371.34 302.687 372.143 302.687 373.135C302.687 374.126 303.488 374.929 304.478 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 374.929C299.497 374.929 300.299 374.126 300.299 373.135C300.299 372.143 299.497 371.34 298.507 371.34C297.518 371.34 296.716 372.143 296.716 373.135C296.716 374.126 297.518 374.929 298.507 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 374.929C293.526 374.929 294.328 374.126 294.328 373.135C294.328 372.143 293.526 371.34 292.537 371.34C291.548 371.34 290.746 372.143 290.746 373.135C290.746 374.126 291.548 374.929 292.537 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 374.929C287.556 374.929 288.358 374.126 288.358 373.135C288.358 372.143 287.556 371.34 286.567 371.34C285.578 371.34 284.776 372.143 284.776 373.135C284.776 374.126 285.578 374.929 286.567 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 374.929C281.586 374.929 282.388 374.126 282.388 373.135C282.388 372.143 281.586 371.34 280.597 371.34C279.608 371.34 278.806 372.143 278.806 373.135C278.806 374.126 279.608 374.929 280.597 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 374.929C275.616 374.929 276.418 374.126 276.418 373.135C276.418 372.143 275.616 371.34 274.627 371.34C273.638 371.34 272.836 372.143 272.836 373.135C272.836 374.126 273.638 374.929 274.627 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 374.929C269.646 374.929 270.448 374.126 270.448 373.135C270.448 372.143 269.646 371.34 268.657 371.34C267.668 371.34 266.866 372.143 266.866 373.135C266.866 374.126 267.668 374.929 268.657 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 374.929C263.676 374.929 264.478 374.126 264.478 373.135C264.478 372.143 263.676 371.34 262.687 371.34C261.697 371.34 260.896 372.143 260.896 373.135C260.896 374.126 261.697 374.929 262.687 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 374.929C257.706 374.929 258.507 374.126 258.507 373.135C258.507 372.143 257.706 371.34 256.716 371.34C255.727 371.34 254.925 372.143 254.925 373.135C254.925 374.126 255.727 374.929 256.716 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 374.929C251.735 374.929 252.537 374.126 252.537 373.135C252.537 372.143 251.735 371.34 250.746 371.34C249.757 371.34 248.955 372.143 248.955 373.135C248.955 374.126 249.757 374.929 250.746 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 374.929C245.765 374.929 246.567 374.126 246.567 373.135C246.567 372.143 245.765 371.34 244.776 371.34C243.787 371.34 242.985 372.143 242.985 373.135C242.985 374.126 243.787 374.929 244.776 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 374.929C239.795 374.929 240.597 374.126 240.597 373.135C240.597 372.143 239.795 371.34 238.806 371.34C237.817 371.34 237.015 372.143 237.015 373.135C237.015 374.126 237.817 374.929 238.806 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 374.929C233.825 374.929 234.627 374.126 234.627 373.135C234.627 372.143 233.825 371.34 232.836 371.34C231.847 371.34 231.045 372.143 231.045 373.135C231.045 374.126 231.847 374.929 232.836 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 374.929C227.855 374.929 228.657 374.126 228.657 373.135C228.657 372.143 227.855 371.34 226.866 371.34C225.877 371.34 225.075 372.143 225.075 373.135C225.075 374.126 225.877 374.929 226.866 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 374.929C221.885 374.929 222.687 374.126 222.687 373.135C222.687 372.143 221.885 371.34 220.896 371.34C219.906 371.34 219.104 372.143 219.104 373.135C219.104 374.126 219.906 374.929 220.896 374.929Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 368.947C389.049 368.947 389.851 368.144 389.851 367.153C389.851 366.161 389.049 365.358 388.06 365.358C387.071 365.358 386.269 366.161 386.269 367.153C386.269 368.144 387.071 368.947 388.06 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 368.947C383.079 368.947 383.881 368.144 383.881 367.153C383.881 366.161 383.079 365.358 382.09 365.358C381.1 365.358 380.298 366.161 380.298 367.153C380.298 368.144 381.1 368.947 382.09 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 368.947C377.109 368.947 377.91 368.144 377.91 367.153C377.91 366.161 377.109 365.358 376.119 365.358C375.13 365.358 374.328 366.161 374.328 367.153C374.328 368.144 375.13 368.947 376.119 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 368.947C371.138 368.947 371.94 368.144 371.94 367.153C371.94 366.161 371.138 365.358 370.149 365.358C369.16 365.358 368.358 366.161 368.358 367.153C368.358 368.144 369.16 368.947 370.149 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 368.947C365.168 368.947 365.97 368.144 365.97 367.153C365.97 366.161 365.168 365.358 364.179 365.358C363.19 365.358 362.388 366.161 362.388 367.153C362.388 368.144 363.19 368.947 364.179 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 368.947C359.198 368.947 360 368.144 360 367.153C360 366.161 359.198 365.358 358.209 365.358C357.22 365.358 356.418 366.161 356.418 367.153C356.418 368.144 357.22 368.947 358.209 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 368.947C353.228 368.947 354.03 368.144 354.03 367.153C354.03 366.161 353.228 365.358 352.239 365.358C351.25 365.358 350.448 366.161 350.448 367.153C350.448 368.144 351.25 368.947 352.239 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 368.947C347.258 368.947 348.06 368.144 348.06 367.153C348.06 366.161 347.258 365.358 346.269 365.358C345.279 365.358 344.478 366.161 344.478 367.153C344.478 368.144 345.279 368.947 346.269 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 368.947C341.288 368.947 342.09 368.144 342.09 367.153C342.09 366.161 341.288 365.358 340.299 365.358C339.309 365.358 338.507 366.161 338.507 367.153C338.507 368.144 339.309 368.947 340.299 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 368.947C335.318 368.947 336.119 368.144 336.119 367.153C336.119 366.161 335.318 365.358 334.328 365.358C333.339 365.358 332.537 366.161 332.537 367.153C332.537 368.144 333.339 368.947 334.328 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 368.947C329.347 368.947 330.149 368.144 330.149 367.153C330.149 366.161 329.347 365.358 328.358 365.358C327.369 365.358 326.567 366.161 326.567 367.153C326.567 368.144 327.369 368.947 328.358 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 368.947C323.377 368.947 324.179 368.144 324.179 367.153C324.179 366.161 323.377 365.358 322.388 365.358C321.399 365.358 320.597 366.161 320.597 367.153C320.597 368.144 321.399 368.947 322.388 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 368.947C317.407 368.947 318.209 368.144 318.209 367.153C318.209 366.161 317.407 365.358 316.418 365.358C315.429 365.358 314.627 366.161 314.627 367.153C314.627 368.144 315.429 368.947 316.418 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 368.947C311.437 368.947 312.239 368.144 312.239 367.153C312.239 366.161 311.437 365.358 310.448 365.358C309.459 365.358 308.657 366.161 308.657 367.153C308.657 368.144 309.459 368.947 310.448 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 368.947C305.467 368.947 306.269 368.144 306.269 367.153C306.269 366.161 305.467 365.358 304.478 365.358C303.488 365.358 302.687 366.161 302.687 367.153C302.687 368.144 303.488 368.947 304.478 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 368.947C299.497 368.947 300.299 368.144 300.299 367.153C300.299 366.161 299.497 365.358 298.507 365.358C297.518 365.358 296.716 366.161 296.716 367.153C296.716 368.144 297.518 368.947 298.507 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 368.947C293.526 368.947 294.328 368.144 294.328 367.153C294.328 366.161 293.526 365.358 292.537 365.358C291.548 365.358 290.746 366.161 290.746 367.153C290.746 368.144 291.548 368.947 292.537 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 368.947C287.556 368.947 288.358 368.144 288.358 367.153C288.358 366.161 287.556 365.358 286.567 365.358C285.578 365.358 284.776 366.161 284.776 367.153C284.776 368.144 285.578 368.947 286.567 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 368.947C281.586 368.947 282.388 368.144 282.388 367.153C282.388 366.161 281.586 365.358 280.597 365.358C279.608 365.358 278.806 366.161 278.806 367.153C278.806 368.144 279.608 368.947 280.597 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 368.947C275.616 368.947 276.418 368.144 276.418 367.153C276.418 366.161 275.616 365.358 274.627 365.358C273.638 365.358 272.836 366.161 272.836 367.153C272.836 368.144 273.638 368.947 274.627 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 368.947C269.646 368.947 270.448 368.144 270.448 367.153C270.448 366.161 269.646 365.358 268.657 365.358C267.668 365.358 266.866 366.161 266.866 367.153C266.866 368.144 267.668 368.947 268.657 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 368.947C263.676 368.947 264.478 368.144 264.478 367.153C264.478 366.161 263.676 365.358 262.687 365.358C261.697 365.358 260.896 366.161 260.896 367.153C260.896 368.144 261.697 368.947 262.687 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 368.947C257.706 368.947 258.507 368.144 258.507 367.153C258.507 366.161 257.706 365.358 256.716 365.358C255.727 365.358 254.925 366.161 254.925 367.153C254.925 368.144 255.727 368.947 256.716 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 368.947C251.735 368.947 252.537 368.144 252.537 367.153C252.537 366.161 251.735 365.358 250.746 365.358C249.757 365.358 248.955 366.161 248.955 367.153C248.955 368.144 249.757 368.947 250.746 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 368.947C245.765 368.947 246.567 368.144 246.567 367.153C246.567 366.161 245.765 365.358 244.776 365.358C243.787 365.358 242.985 366.161 242.985 367.153C242.985 368.144 243.787 368.947 244.776 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 368.947C239.795 368.947 240.597 368.144 240.597 367.153C240.597 366.161 239.795 365.358 238.806 365.358C237.817 365.358 237.015 366.161 237.015 367.153C237.015 368.144 237.817 368.947 238.806 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 368.947C233.825 368.947 234.627 368.144 234.627 367.153C234.627 366.161 233.825 365.358 232.836 365.358C231.847 365.358 231.045 366.161 231.045 367.153C231.045 368.144 231.847 368.947 232.836 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 368.947C227.855 368.947 228.657 368.144 228.657 367.153C228.657 366.161 227.855 365.358 226.866 365.358C225.877 365.358 225.075 366.161 225.075 367.153C225.075 368.144 225.877 368.947 226.866 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 368.947C221.885 368.947 222.687 368.144 222.687 367.153C222.687 366.161 221.885 365.358 220.896 365.358C219.906 365.358 219.104 366.161 219.104 367.153C219.104 368.144 219.906 368.947 220.896 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 368.947C215.915 368.947 216.716 368.144 216.716 367.153C216.716 366.161 215.915 365.358 214.925 365.358C213.936 365.358 213.134 366.161 213.134 367.153C213.134 368.144 213.936 368.947 214.925 368.947Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 362.965C377.109 362.965 377.91 362.161 377.91 361.17C377.91 360.179 377.109 359.375 376.119 359.375C375.13 359.375 374.328 360.179 374.328 361.17C374.328 362.161 375.13 362.965 376.119 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 362.965C371.138 362.965 371.94 362.161 371.94 361.17C371.94 360.179 371.138 359.375 370.149 359.375C369.16 359.375 368.358 360.179 368.358 361.17C368.358 362.161 369.16 362.965 370.149 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 362.965C365.168 362.965 365.97 362.161 365.97 361.17C365.97 360.179 365.168 359.375 364.179 359.375C363.19 359.375 362.388 360.179 362.388 361.17C362.388 362.161 363.19 362.965 364.179 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 362.965C359.198 362.965 360 362.161 360 361.17C360 360.179 359.198 359.375 358.209 359.375C357.22 359.375 356.418 360.179 356.418 361.17C356.418 362.161 357.22 362.965 358.209 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 362.965C353.228 362.965 354.03 362.161 354.03 361.17C354.03 360.179 353.228 359.375 352.239 359.375C351.25 359.375 350.448 360.179 350.448 361.17C350.448 362.161 351.25 362.965 352.239 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 362.965C347.258 362.965 348.06 362.161 348.06 361.17C348.06 360.179 347.258 359.375 346.269 359.375C345.279 359.375 344.478 360.179 344.478 361.17C344.478 362.161 345.279 362.965 346.269 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 362.965C341.288 362.965 342.09 362.161 342.09 361.17C342.09 360.179 341.288 359.375 340.299 359.375C339.309 359.375 338.507 360.179 338.507 361.17C338.507 362.161 339.309 362.965 340.299 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 362.965C335.318 362.965 336.119 362.161 336.119 361.17C336.119 360.179 335.318 359.375 334.328 359.375C333.339 359.375 332.537 360.179 332.537 361.17C332.537 362.161 333.339 362.965 334.328 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 362.965C329.347 362.965 330.149 362.161 330.149 361.17C330.149 360.179 329.347 359.375 328.358 359.375C327.369 359.375 326.567 360.179 326.567 361.17C326.567 362.161 327.369 362.965 328.358 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 362.965C323.377 362.965 324.179 362.161 324.179 361.17C324.179 360.179 323.377 359.375 322.388 359.375C321.399 359.375 320.597 360.179 320.597 361.17C320.597 362.161 321.399 362.965 322.388 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 362.965C317.407 362.965 318.209 362.161 318.209 361.17C318.209 360.179 317.407 359.375 316.418 359.375C315.429 359.375 314.627 360.179 314.627 361.17C314.627 362.161 315.429 362.965 316.418 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 362.965C311.437 362.965 312.239 362.161 312.239 361.17C312.239 360.179 311.437 359.375 310.448 359.375C309.459 359.375 308.657 360.179 308.657 361.17C308.657 362.161 309.459 362.965 310.448 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 362.965C305.467 362.965 306.269 362.161 306.269 361.17C306.269 360.179 305.467 359.375 304.478 359.375C303.488 359.375 302.687 360.179 302.687 361.17C302.687 362.161 303.488 362.965 304.478 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 362.965C299.497 362.965 300.299 362.161 300.299 361.17C300.299 360.179 299.497 359.375 298.507 359.375C297.518 359.375 296.716 360.179 296.716 361.17C296.716 362.161 297.518 362.965 298.507 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 362.965C293.526 362.965 294.328 362.161 294.328 361.17C294.328 360.179 293.526 359.375 292.537 359.375C291.548 359.375 290.746 360.179 290.746 361.17C290.746 362.161 291.548 362.965 292.537 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 362.965C287.556 362.965 288.358 362.161 288.358 361.17C288.358 360.179 287.556 359.375 286.567 359.375C285.578 359.375 284.776 360.179 284.776 361.17C284.776 362.161 285.578 362.965 286.567 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 362.965C281.586 362.965 282.388 362.161 282.388 361.17C282.388 360.179 281.586 359.375 280.597 359.375C279.608 359.375 278.806 360.179 278.806 361.17C278.806 362.161 279.608 362.965 280.597 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 362.965C275.616 362.965 276.418 362.161 276.418 361.17C276.418 360.179 275.616 359.375 274.627 359.375C273.638 359.375 272.836 360.179 272.836 361.17C272.836 362.161 273.638 362.965 274.627 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 362.965C269.646 362.965 270.448 362.161 270.448 361.17C270.448 360.179 269.646 359.375 268.657 359.375C267.668 359.375 266.866 360.179 266.866 361.17C266.866 362.161 267.668 362.965 268.657 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 362.965C263.676 362.965 264.478 362.161 264.478 361.17C264.478 360.179 263.676 359.375 262.687 359.375C261.697 359.375 260.896 360.179 260.896 361.17C260.896 362.161 261.697 362.965 262.687 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 362.965C257.706 362.965 258.507 362.161 258.507 361.17C258.507 360.179 257.706 359.375 256.716 359.375C255.727 359.375 254.925 360.179 254.925 361.17C254.925 362.161 255.727 362.965 256.716 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 362.965C251.735 362.965 252.537 362.161 252.537 361.17C252.537 360.179 251.735 359.375 250.746 359.375C249.757 359.375 248.955 360.179 248.955 361.17C248.955 362.161 249.757 362.965 250.746 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 362.965C245.765 362.965 246.567 362.161 246.567 361.17C246.567 360.179 245.765 359.375 244.776 359.375C243.787 359.375 242.985 360.179 242.985 361.17C242.985 362.161 243.787 362.965 244.776 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 362.965C239.795 362.965 240.597 362.161 240.597 361.17C240.597 360.179 239.795 359.375 238.806 359.375C237.817 359.375 237.015 360.179 237.015 361.17C237.015 362.161 237.817 362.965 238.806 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 362.965C233.825 362.965 234.627 362.161 234.627 361.17C234.627 360.179 233.825 359.375 232.836 359.375C231.847 359.375 231.045 360.179 231.045 361.17C231.045 362.161 231.847 362.965 232.836 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 362.965C227.855 362.965 228.657 362.161 228.657 361.17C228.657 360.179 227.855 359.375 226.866 359.375C225.877 359.375 225.075 360.179 225.075 361.17C225.075 362.161 225.877 362.965 226.866 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 362.965C221.885 362.965 222.687 362.161 222.687 361.17C222.687 360.179 221.885 359.375 220.896 359.375C219.906 359.375 219.104 360.179 219.104 361.17C219.104 362.161 219.906 362.965 220.896 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 362.965C215.915 362.965 216.716 362.161 216.716 361.17C216.716 360.179 215.915 359.375 214.925 359.375C213.936 359.375 213.134 360.179 213.134 361.17C213.134 362.161 213.936 362.965 214.925 362.965Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 356.982C371.138 356.982 371.94 356.179 371.94 355.188C371.94 354.197 371.138 353.393 370.149 353.393C369.16 353.393 368.358 354.197 368.358 355.188C368.358 356.179 369.16 356.982 370.149 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 356.982C365.168 356.982 365.97 356.179 365.97 355.188C365.97 354.197 365.168 353.393 364.179 353.393C363.19 353.393 362.388 354.197 362.388 355.188C362.388 356.179 363.19 356.982 364.179 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 356.982C359.198 356.982 360 356.179 360 355.188C360 354.197 359.198 353.393 358.209 353.393C357.22 353.393 356.418 354.197 356.418 355.188C356.418 356.179 357.22 356.982 358.209 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 356.982C353.228 356.982 354.03 356.179 354.03 355.188C354.03 354.197 353.228 353.393 352.239 353.393C351.25 353.393 350.448 354.197 350.448 355.188C350.448 356.179 351.25 356.982 352.239 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 356.982C347.258 356.982 348.06 356.179 348.06 355.188C348.06 354.197 347.258 353.393 346.269 353.393C345.279 353.393 344.478 354.197 344.478 355.188C344.478 356.179 345.279 356.982 346.269 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 356.982C341.288 356.982 342.09 356.179 342.09 355.188C342.09 354.197 341.288 353.393 340.299 353.393C339.309 353.393 338.507 354.197 338.507 355.188C338.507 356.179 339.309 356.982 340.299 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 356.982C335.318 356.982 336.119 356.179 336.119 355.188C336.119 354.197 335.318 353.393 334.328 353.393C333.339 353.393 332.537 354.197 332.537 355.188C332.537 356.179 333.339 356.982 334.328 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 356.982C329.347 356.982 330.149 356.179 330.149 355.188C330.149 354.197 329.347 353.393 328.358 353.393C327.369 353.393 326.567 354.197 326.567 355.188C326.567 356.179 327.369 356.982 328.358 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 356.982C323.377 356.982 324.179 356.179 324.179 355.188C324.179 354.197 323.377 353.393 322.388 353.393C321.399 353.393 320.597 354.197 320.597 355.188C320.597 356.179 321.399 356.982 322.388 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 356.982C317.407 356.982 318.209 356.179 318.209 355.188C318.209 354.197 317.407 353.393 316.418 353.393C315.429 353.393 314.627 354.197 314.627 355.188C314.627 356.179 315.429 356.982 316.418 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 356.982C311.437 356.982 312.239 356.179 312.239 355.188C312.239 354.197 311.437 353.393 310.448 353.393C309.459 353.393 308.657 354.197 308.657 355.188C308.657 356.179 309.459 356.982 310.448 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 356.982C305.467 356.982 306.269 356.179 306.269 355.188C306.269 354.197 305.467 353.393 304.478 353.393C303.488 353.393 302.687 354.197 302.687 355.188C302.687 356.179 303.488 356.982 304.478 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 356.982C299.497 356.982 300.299 356.179 300.299 355.188C300.299 354.197 299.497 353.393 298.507 353.393C297.518 353.393 296.716 354.197 296.716 355.188C296.716 356.179 297.518 356.982 298.507 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 356.982C293.526 356.982 294.328 356.179 294.328 355.188C294.328 354.197 293.526 353.393 292.537 353.393C291.548 353.393 290.746 354.197 290.746 355.188C290.746 356.179 291.548 356.982 292.537 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 356.982C287.556 356.982 288.358 356.179 288.358 355.188C288.358 354.197 287.556 353.393 286.567 353.393C285.578 353.393 284.776 354.197 284.776 355.188C284.776 356.179 285.578 356.982 286.567 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 356.982C281.586 356.982 282.388 356.179 282.388 355.188C282.388 354.197 281.586 353.393 280.597 353.393C279.608 353.393 278.806 354.197 278.806 355.188C278.806 356.179 279.608 356.982 280.597 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 356.982C275.616 356.982 276.418 356.179 276.418 355.188C276.418 354.197 275.616 353.393 274.627 353.393C273.638 353.393 272.836 354.197 272.836 355.188C272.836 356.179 273.638 356.982 274.627 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 356.982C269.646 356.982 270.448 356.179 270.448 355.188C270.448 354.197 269.646 353.393 268.657 353.393C267.668 353.393 266.866 354.197 266.866 355.188C266.866 356.179 267.668 356.982 268.657 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 356.982C263.676 356.982 264.478 356.179 264.478 355.188C264.478 354.197 263.676 353.393 262.687 353.393C261.697 353.393 260.896 354.197 260.896 355.188C260.896 356.179 261.697 356.982 262.687 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 356.982C257.706 356.982 258.507 356.179 258.507 355.188C258.507 354.197 257.706 353.393 256.716 353.393C255.727 353.393 254.925 354.197 254.925 355.188C254.925 356.179 255.727 356.982 256.716 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 356.982C251.735 356.982 252.537 356.179 252.537 355.188C252.537 354.197 251.735 353.393 250.746 353.393C249.757 353.393 248.955 354.197 248.955 355.188C248.955 356.179 249.757 356.982 250.746 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 356.982C245.765 356.982 246.567 356.179 246.567 355.188C246.567 354.197 245.765 353.393 244.776 353.393C243.787 353.393 242.985 354.197 242.985 355.188C242.985 356.179 243.787 356.982 244.776 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 356.982C239.795 356.982 240.597 356.179 240.597 355.188C240.597 354.197 239.795 353.393 238.806 353.393C237.817 353.393 237.015 354.197 237.015 355.188C237.015 356.179 237.817 356.982 238.806 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 356.982C233.825 356.982 234.627 356.179 234.627 355.188C234.627 354.197 233.825 353.393 232.836 353.393C231.847 353.393 231.045 354.197 231.045 355.188C231.045 356.179 231.847 356.982 232.836 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 356.982C227.855 356.982 228.657 356.179 228.657 355.188C228.657 354.197 227.855 353.393 226.866 353.393C225.877 353.393 225.075 354.197 225.075 355.188C225.075 356.179 225.877 356.982 226.866 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 356.982C221.885 356.982 222.687 356.179 222.687 355.188C222.687 354.197 221.885 353.393 220.896 353.393C219.906 353.393 219.104 354.197 219.104 355.188C219.104 356.179 219.906 356.982 220.896 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 356.982C215.915 356.982 216.716 356.179 216.716 355.188C216.716 354.197 215.915 353.393 214.925 353.393C213.936 353.393 213.134 354.197 213.134 355.188C213.134 356.179 213.936 356.982 214.925 356.982Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 351C353.228 351 354.03 350.196 354.03 349.205C354.03 348.214 353.228 347.411 352.239 347.411C351.25 347.411 350.448 348.214 350.448 349.205C350.448 350.196 351.25 351 352.239 351Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 351C347.258 351 348.06 350.196 348.06 349.205C348.06 348.214 347.258 347.411 346.269 347.411C345.279 347.411 344.478 348.214 344.478 349.205C344.478 350.196 345.279 351 346.269 351Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 351C341.288 351 342.09 350.196 342.09 349.205C342.09 348.214 341.288 347.411 340.299 347.411C339.309 347.411 338.507 348.214 338.507 349.205C338.507 350.196 339.309 351 340.299 351Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 351C329.347 351 330.149 350.196 330.149 349.205C330.149 348.214 329.347 347.411 328.358 347.411C327.369 347.411 326.567 348.214 326.567 349.205C326.567 350.196 327.369 351 328.358 351Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 351C323.377 351 324.179 350.196 324.179 349.205C324.179 348.214 323.377 347.411 322.388 347.411C321.399 347.411 320.597 348.214 320.597 349.205C320.597 350.196 321.399 351 322.388 351Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 351C317.407 351 318.209 350.196 318.209 349.205C318.209 348.214 317.407 347.411 316.418 347.411C315.429 347.411 314.627 348.214 314.627 349.205C314.627 350.196 315.429 351 316.418 351Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 351C311.437 351 312.239 350.196 312.239 349.205C312.239 348.214 311.437 347.411 310.448 347.411C309.459 347.411 308.657 348.214 308.657 349.205C308.657 350.196 309.459 351 310.448 351Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 351C305.467 351 306.269 350.196 306.269 349.205C306.269 348.214 305.467 347.411 304.478 347.411C303.488 347.411 302.687 348.214 302.687 349.205C302.687 350.196 303.488 351 304.478 351Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 351C299.497 351 300.299 350.196 300.299 349.205C300.299 348.214 299.497 347.411 298.507 347.411C297.518 347.411 296.716 348.214 296.716 349.205C296.716 350.196 297.518 351 298.507 351Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 351C293.526 351 294.328 350.196 294.328 349.205C294.328 348.214 293.526 347.411 292.537 347.411C291.548 347.411 290.746 348.214 290.746 349.205C290.746 350.196 291.548 351 292.537 351Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 351C287.556 351 288.358 350.196 288.358 349.205C288.358 348.214 287.556 347.411 286.567 347.411C285.578 347.411 284.776 348.214 284.776 349.205C284.776 350.196 285.578 351 286.567 351Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 351C281.586 351 282.388 350.196 282.388 349.205C282.388 348.214 281.586 347.411 280.597 347.411C279.608 347.411 278.806 348.214 278.806 349.205C278.806 350.196 279.608 351 280.597 351Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 351C275.616 351 276.418 350.196 276.418 349.205C276.418 348.214 275.616 347.411 274.627 347.411C273.638 347.411 272.836 348.214 272.836 349.205C272.836 350.196 273.638 351 274.627 351Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 351C269.646 351 270.448 350.196 270.448 349.205C270.448 348.214 269.646 347.411 268.657 347.411C267.668 347.411 266.866 348.214 266.866 349.205C266.866 350.196 267.668 351 268.657 351Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 351C263.676 351 264.478 350.196 264.478 349.205C264.478 348.214 263.676 347.411 262.687 347.411C261.697 347.411 260.896 348.214 260.896 349.205C260.896 350.196 261.697 351 262.687 351Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 351C257.706 351 258.507 350.196 258.507 349.205C258.507 348.214 257.706 347.411 256.716 347.411C255.727 347.411 254.925 348.214 254.925 349.205C254.925 350.196 255.727 351 256.716 351Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 351C251.735 351 252.537 350.196 252.537 349.205C252.537 348.214 251.735 347.411 250.746 347.411C249.757 347.411 248.955 348.214 248.955 349.205C248.955 350.196 249.757 351 250.746 351Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 351C245.765 351 246.567 350.196 246.567 349.205C246.567 348.214 245.765 347.411 244.776 347.411C243.787 347.411 242.985 348.214 242.985 349.205C242.985 350.196 243.787 351 244.776 351Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 351C239.795 351 240.597 350.196 240.597 349.205C240.597 348.214 239.795 347.411 238.806 347.411C237.817 347.411 237.015 348.214 237.015 349.205C237.015 350.196 237.817 351 238.806 351Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 351C233.825 351 234.627 350.196 234.627 349.205C234.627 348.214 233.825 347.411 232.836 347.411C231.847 347.411 231.045 348.214 231.045 349.205C231.045 350.196 231.847 351 232.836 351Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 351C227.855 351 228.657 350.196 228.657 349.205C228.657 348.214 227.855 347.411 226.866 347.411C225.877 347.411 225.075 348.214 225.075 349.205C225.075 350.196 225.877 351 226.866 351Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 351C221.885 351 222.687 350.196 222.687 349.205C222.687 348.214 221.885 347.411 220.896 347.411C219.906 347.411 219.104 348.214 219.104 349.205C219.104 350.196 219.906 351 220.896 351Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 351C215.915 351 216.716 350.196 216.716 349.205C216.716 348.214 215.915 347.411 214.925 347.411C213.936 347.411 213.134 348.214 213.134 349.205C213.134 350.196 213.936 351 214.925 351Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 345.018C335.318 345.018 336.119 344.215 336.119 343.223C336.119 342.232 335.318 341.429 334.328 341.429C333.339 341.429 332.537 342.232 332.537 343.223C332.537 344.215 333.339 345.018 334.328 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 345.018C329.347 345.018 330.149 344.215 330.149 343.223C330.149 342.232 329.347 341.429 328.358 341.429C327.369 341.429 326.567 342.232 326.567 343.223C326.567 344.215 327.369 345.018 328.358 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 345.018C323.377 345.018 324.179 344.215 324.179 343.223C324.179 342.232 323.377 341.429 322.388 341.429C321.399 341.429 320.597 342.232 320.597 343.223C320.597 344.215 321.399 345.018 322.388 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 345.018C317.407 345.018 318.209 344.215 318.209 343.223C318.209 342.232 317.407 341.429 316.418 341.429C315.429 341.429 314.627 342.232 314.627 343.223C314.627 344.215 315.429 345.018 316.418 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 345.018C311.437 345.018 312.239 344.215 312.239 343.223C312.239 342.232 311.437 341.429 310.448 341.429C309.459 341.429 308.657 342.232 308.657 343.223C308.657 344.215 309.459 345.018 310.448 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 345.018C305.467 345.018 306.269 344.215 306.269 343.223C306.269 342.232 305.467 341.429 304.478 341.429C303.488 341.429 302.687 342.232 302.687 343.223C302.687 344.215 303.488 345.018 304.478 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 345.018C299.497 345.018 300.299 344.215 300.299 343.223C300.299 342.232 299.497 341.429 298.507 341.429C297.518 341.429 296.716 342.232 296.716 343.223C296.716 344.215 297.518 345.018 298.507 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 345.018C293.526 345.018 294.328 344.215 294.328 343.223C294.328 342.232 293.526 341.429 292.537 341.429C291.548 341.429 290.746 342.232 290.746 343.223C290.746 344.215 291.548 345.018 292.537 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 345.018C287.556 345.018 288.358 344.215 288.358 343.223C288.358 342.232 287.556 341.429 286.567 341.429C285.578 341.429 284.776 342.232 284.776 343.223C284.776 344.215 285.578 345.018 286.567 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 345.018C281.586 345.018 282.388 344.215 282.388 343.223C282.388 342.232 281.586 341.429 280.597 341.429C279.608 341.429 278.806 342.232 278.806 343.223C278.806 344.215 279.608 345.018 280.597 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 345.018C275.616 345.018 276.418 344.215 276.418 343.223C276.418 342.232 275.616 341.429 274.627 341.429C273.638 341.429 272.836 342.232 272.836 343.223C272.836 344.215 273.638 345.018 274.627 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 345.018C269.646 345.018 270.448 344.215 270.448 343.223C270.448 342.232 269.646 341.429 268.657 341.429C267.668 341.429 266.866 342.232 266.866 343.223C266.866 344.215 267.668 345.018 268.657 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 345.018C263.676 345.018 264.478 344.215 264.478 343.223C264.478 342.232 263.676 341.429 262.687 341.429C261.697 341.429 260.896 342.232 260.896 343.223C260.896 344.215 261.697 345.018 262.687 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 345.018C257.706 345.018 258.507 344.215 258.507 343.223C258.507 342.232 257.706 341.429 256.716 341.429C255.727 341.429 254.925 342.232 254.925 343.223C254.925 344.215 255.727 345.018 256.716 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 345.018C251.735 345.018 252.537 344.215 252.537 343.223C252.537 342.232 251.735 341.429 250.746 341.429C249.757 341.429 248.955 342.232 248.955 343.223C248.955 344.215 249.757 345.018 250.746 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 345.018C245.765 345.018 246.567 344.215 246.567 343.223C246.567 342.232 245.765 341.429 244.776 341.429C243.787 341.429 242.985 342.232 242.985 343.223C242.985 344.215 243.787 345.018 244.776 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 345.018C239.795 345.018 240.597 344.215 240.597 343.223C240.597 342.232 239.795 341.429 238.806 341.429C237.817 341.429 237.015 342.232 237.015 343.223C237.015 344.215 237.817 345.018 238.806 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 345.018C233.825 345.018 234.627 344.215 234.627 343.223C234.627 342.232 233.825 341.429 232.836 341.429C231.847 341.429 231.045 342.232 231.045 343.223C231.045 344.215 231.847 345.018 232.836 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 345.018C227.855 345.018 228.657 344.215 228.657 343.223C228.657 342.232 227.855 341.429 226.866 341.429C225.877 341.429 225.075 342.232 225.075 343.223C225.075 344.215 225.877 345.018 226.866 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 345.018C221.885 345.018 222.687 344.215 222.687 343.223C222.687 342.232 221.885 341.429 220.896 341.429C219.906 341.429 219.104 342.232 219.104 343.223C219.104 344.215 219.906 345.018 220.896 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 345.018C215.915 345.018 216.716 344.215 216.716 343.223C216.716 342.232 215.915 341.429 214.925 341.429C213.936 341.429 213.134 342.232 213.134 343.223C213.134 344.215 213.936 345.018 214.925 345.018Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 339.036C329.347 339.036 330.149 338.232 330.149 337.241C330.149 336.25 329.347 335.446 328.358 335.446C327.369 335.446 326.567 336.25 326.567 337.241C326.567 338.232 327.369 339.036 328.358 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 339.036C323.377 339.036 324.179 338.232 324.179 337.241C324.179 336.25 323.377 335.446 322.388 335.446C321.399 335.446 320.597 336.25 320.597 337.241C320.597 338.232 321.399 339.036 322.388 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 339.036C317.407 339.036 318.209 338.232 318.209 337.241C318.209 336.25 317.407 335.446 316.418 335.446C315.429 335.446 314.627 336.25 314.627 337.241C314.627 338.232 315.429 339.036 316.418 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 339.036C311.437 339.036 312.239 338.232 312.239 337.241C312.239 336.25 311.437 335.446 310.448 335.446C309.459 335.446 308.657 336.25 308.657 337.241C308.657 338.232 309.459 339.036 310.448 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 339.036C305.467 339.036 306.269 338.232 306.269 337.241C306.269 336.25 305.467 335.446 304.478 335.446C303.488 335.446 302.687 336.25 302.687 337.241C302.687 338.232 303.488 339.036 304.478 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 339.036C299.497 339.036 300.299 338.232 300.299 337.241C300.299 336.25 299.497 335.446 298.507 335.446C297.518 335.446 296.716 336.25 296.716 337.241C296.716 338.232 297.518 339.036 298.507 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 339.036C293.526 339.036 294.328 338.232 294.328 337.241C294.328 336.25 293.526 335.446 292.537 335.446C291.548 335.446 290.746 336.25 290.746 337.241C290.746 338.232 291.548 339.036 292.537 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 339.036C287.556 339.036 288.358 338.232 288.358 337.241C288.358 336.25 287.556 335.446 286.567 335.446C285.578 335.446 284.776 336.25 284.776 337.241C284.776 338.232 285.578 339.036 286.567 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 339.036C281.586 339.036 282.388 338.232 282.388 337.241C282.388 336.25 281.586 335.446 280.597 335.446C279.608 335.446 278.806 336.25 278.806 337.241C278.806 338.232 279.608 339.036 280.597 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 339.036C275.616 339.036 276.418 338.232 276.418 337.241C276.418 336.25 275.616 335.446 274.627 335.446C273.638 335.446 272.836 336.25 272.836 337.241C272.836 338.232 273.638 339.036 274.627 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 339.036C269.646 339.036 270.448 338.232 270.448 337.241C270.448 336.25 269.646 335.446 268.657 335.446C267.668 335.446 266.866 336.25 266.866 337.241C266.866 338.232 267.668 339.036 268.657 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 339.036C263.676 339.036 264.478 338.232 264.478 337.241C264.478 336.25 263.676 335.446 262.687 335.446C261.697 335.446 260.896 336.25 260.896 337.241C260.896 338.232 261.697 339.036 262.687 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 339.036C257.706 339.036 258.507 338.232 258.507 337.241C258.507 336.25 257.706 335.446 256.716 335.446C255.727 335.446 254.925 336.25 254.925 337.241C254.925 338.232 255.727 339.036 256.716 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 339.036C251.735 339.036 252.537 338.232 252.537 337.241C252.537 336.25 251.735 335.446 250.746 335.446C249.757 335.446 248.955 336.25 248.955 337.241C248.955 338.232 249.757 339.036 250.746 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 339.036C245.765 339.036 246.567 338.232 246.567 337.241C246.567 336.25 245.765 335.446 244.776 335.446C243.787 335.446 242.985 336.25 242.985 337.241C242.985 338.232 243.787 339.036 244.776 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 339.036C239.795 339.036 240.597 338.232 240.597 337.241C240.597 336.25 239.795 335.446 238.806 335.446C237.817 335.446 237.015 336.25 237.015 337.241C237.015 338.232 237.817 339.036 238.806 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 339.036C233.825 339.036 234.627 338.232 234.627 337.241C234.627 336.25 233.825 335.446 232.836 335.446C231.847 335.446 231.045 336.25 231.045 337.241C231.045 338.232 231.847 339.036 232.836 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 339.036C227.855 339.036 228.657 338.232 228.657 337.241C228.657 336.25 227.855 335.446 226.866 335.446C225.877 335.446 225.075 336.25 225.075 337.241C225.075 338.232 225.877 339.036 226.866 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 339.036C221.885 339.036 222.687 338.232 222.687 337.241C222.687 336.25 221.885 335.446 220.896 335.446C219.906 335.446 219.104 336.25 219.104 337.241C219.104 338.232 219.906 339.036 220.896 339.036Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 333.053C329.347 333.053 330.149 332.25 330.149 331.259C330.149 330.267 329.347 329.464 328.358 329.464C327.369 329.464 326.567 330.267 326.567 331.259C326.567 332.25 327.369 333.053 328.358 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 333.053C323.377 333.053 324.179 332.25 324.179 331.259C324.179 330.267 323.377 329.464 322.388 329.464C321.399 329.464 320.597 330.267 320.597 331.259C320.597 332.25 321.399 333.053 322.388 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 333.053C317.407 333.053 318.209 332.25 318.209 331.259C318.209 330.267 317.407 329.464 316.418 329.464C315.429 329.464 314.627 330.267 314.627 331.259C314.627 332.25 315.429 333.053 316.418 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 333.053C311.437 333.053 312.239 332.25 312.239 331.259C312.239 330.267 311.437 329.464 310.448 329.464C309.459 329.464 308.657 330.267 308.657 331.259C308.657 332.25 309.459 333.053 310.448 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 333.053C305.467 333.053 306.269 332.25 306.269 331.259C306.269 330.267 305.467 329.464 304.478 329.464C303.488 329.464 302.687 330.267 302.687 331.259C302.687 332.25 303.488 333.053 304.478 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 333.053C299.497 333.053 300.299 332.25 300.299 331.259C300.299 330.267 299.497 329.464 298.507 329.464C297.518 329.464 296.716 330.267 296.716 331.259C296.716 332.25 297.518 333.053 298.507 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 333.053C293.526 333.053 294.328 332.25 294.328 331.259C294.328 330.267 293.526 329.464 292.537 329.464C291.548 329.464 290.746 330.267 290.746 331.259C290.746 332.25 291.548 333.053 292.537 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 333.053C287.556 333.053 288.358 332.25 288.358 331.259C288.358 330.267 287.556 329.464 286.567 329.464C285.578 329.464 284.776 330.267 284.776 331.259C284.776 332.25 285.578 333.053 286.567 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 333.053C281.586 333.053 282.388 332.25 282.388 331.259C282.388 330.267 281.586 329.464 280.597 329.464C279.608 329.464 278.806 330.267 278.806 331.259C278.806 332.25 279.608 333.053 280.597 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 333.053C275.616 333.053 276.418 332.25 276.418 331.259C276.418 330.267 275.616 329.464 274.627 329.464C273.638 329.464 272.836 330.267 272.836 331.259C272.836 332.25 273.638 333.053 274.627 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 333.053C269.646 333.053 270.448 332.25 270.448 331.259C270.448 330.267 269.646 329.464 268.657 329.464C267.668 329.464 266.866 330.267 266.866 331.259C266.866 332.25 267.668 333.053 268.657 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 333.053C263.676 333.053 264.478 332.25 264.478 331.259C264.478 330.267 263.676 329.464 262.687 329.464C261.697 329.464 260.896 330.267 260.896 331.259C260.896 332.25 261.697 333.053 262.687 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 333.053C257.706 333.053 258.507 332.25 258.507 331.259C258.507 330.267 257.706 329.464 256.716 329.464C255.727 329.464 254.925 330.267 254.925 331.259C254.925 332.25 255.727 333.053 256.716 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 333.053C251.735 333.053 252.537 332.25 252.537 331.259C252.537 330.267 251.735 329.464 250.746 329.464C249.757 329.464 248.955 330.267 248.955 331.259C248.955 332.25 249.757 333.053 250.746 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 333.053C245.765 333.053 246.567 332.25 246.567 331.259C246.567 330.267 245.765 329.464 244.776 329.464C243.787 329.464 242.985 330.267 242.985 331.259C242.985 332.25 243.787 333.053 244.776 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 333.053C239.795 333.053 240.597 332.25 240.597 331.259C240.597 330.267 239.795 329.464 238.806 329.464C237.817 329.464 237.015 330.267 237.015 331.259C237.015 332.25 237.817 333.053 238.806 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 333.053C233.825 333.053 234.627 332.25 234.627 331.259C234.627 330.267 233.825 329.464 232.836 329.464C231.847 329.464 231.045 330.267 231.045 331.259C231.045 332.25 231.847 333.053 232.836 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 333.053C227.855 333.053 228.657 332.25 228.657 331.259C228.657 330.267 227.855 329.464 226.866 329.464C225.877 329.464 225.075 330.267 225.075 331.259C225.075 332.25 225.877 333.053 226.866 333.053Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 327.071C329.347 327.071 330.149 326.268 330.149 325.277C330.149 324.285 329.347 323.482 328.358 323.482C327.369 323.482 326.567 324.285 326.567 325.277C326.567 326.268 327.369 327.071 328.358 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 327.071C323.377 327.071 324.179 326.268 324.179 325.277C324.179 324.285 323.377 323.482 322.388 323.482C321.399 323.482 320.597 324.285 320.597 325.277C320.597 326.268 321.399 327.071 322.388 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 327.071C317.407 327.071 318.209 326.268 318.209 325.277C318.209 324.285 317.407 323.482 316.418 323.482C315.429 323.482 314.627 324.285 314.627 325.277C314.627 326.268 315.429 327.071 316.418 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 327.071C311.437 327.071 312.239 326.268 312.239 325.277C312.239 324.285 311.437 323.482 310.448 323.482C309.459 323.482 308.657 324.285 308.657 325.277C308.657 326.268 309.459 327.071 310.448 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 327.071C305.467 327.071 306.269 326.268 306.269 325.277C306.269 324.285 305.467 323.482 304.478 323.482C303.488 323.482 302.687 324.285 302.687 325.277C302.687 326.268 303.488 327.071 304.478 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 327.071C299.497 327.071 300.299 326.268 300.299 325.277C300.299 324.285 299.497 323.482 298.507 323.482C297.518 323.482 296.716 324.285 296.716 325.277C296.716 326.268 297.518 327.071 298.507 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 327.071C293.526 327.071 294.328 326.268 294.328 325.277C294.328 324.285 293.526 323.482 292.537 323.482C291.548 323.482 290.746 324.285 290.746 325.277C290.746 326.268 291.548 327.071 292.537 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 327.071C287.556 327.071 288.358 326.268 288.358 325.277C288.358 324.285 287.556 323.482 286.567 323.482C285.578 323.482 284.776 324.285 284.776 325.277C284.776 326.268 285.578 327.071 286.567 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 327.071C281.586 327.071 282.388 326.268 282.388 325.277C282.388 324.285 281.586 323.482 280.597 323.482C279.608 323.482 278.806 324.285 278.806 325.277C278.806 326.268 279.608 327.071 280.597 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 327.071C275.616 327.071 276.418 326.268 276.418 325.277C276.418 324.285 275.616 323.482 274.627 323.482C273.638 323.482 272.836 324.285 272.836 325.277C272.836 326.268 273.638 327.071 274.627 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 327.071C269.646 327.071 270.448 326.268 270.448 325.277C270.448 324.285 269.646 323.482 268.657 323.482C267.668 323.482 266.866 324.285 266.866 325.277C266.866 326.268 267.668 327.071 268.657 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 327.071C263.676 327.071 264.478 326.268 264.478 325.277C264.478 324.285 263.676 323.482 262.687 323.482C261.697 323.482 260.896 324.285 260.896 325.277C260.896 326.268 261.697 327.071 262.687 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 327.071C257.706 327.071 258.507 326.268 258.507 325.277C258.507 324.285 257.706 323.482 256.716 323.482C255.727 323.482 254.925 324.285 254.925 325.277C254.925 326.268 255.727 327.071 256.716 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 327.071C251.735 327.071 252.537 326.268 252.537 325.277C252.537 324.285 251.735 323.482 250.746 323.482C249.757 323.482 248.955 324.285 248.955 325.277C248.955 326.268 249.757 327.071 250.746 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 327.071C245.765 327.071 246.567 326.268 246.567 325.277C246.567 324.285 245.765 323.482 244.776 323.482C243.787 323.482 242.985 324.285 242.985 325.277C242.985 326.268 243.787 327.071 244.776 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 327.071C239.795 327.071 240.597 326.268 240.597 325.277C240.597 324.285 239.795 323.482 238.806 323.482C237.817 323.482 237.015 324.285 237.015 325.277C237.015 326.268 237.817 327.071 238.806 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 327.071C233.825 327.071 234.627 326.268 234.627 325.277C234.627 324.285 233.825 323.482 232.836 323.482C231.847 323.482 231.045 324.285 231.045 325.277C231.045 326.268 231.847 327.071 232.836 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 327.071C227.855 327.071 228.657 326.268 228.657 325.277C228.657 324.285 227.855 323.482 226.866 323.482C225.877 323.482 225.075 324.285 225.075 325.277C225.075 326.268 225.877 327.071 226.866 327.071Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 321.089C323.377 321.089 324.179 320.285 324.179 319.294C324.179 318.303 323.377 317.5 322.388 317.5C321.399 317.5 320.597 318.303 320.597 319.294C320.597 320.285 321.399 321.089 322.388 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 321.089C317.407 321.089 318.209 320.285 318.209 319.294C318.209 318.303 317.407 317.5 316.418 317.5C315.429 317.5 314.627 318.303 314.627 319.294C314.627 320.285 315.429 321.089 316.418 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 321.089C311.437 321.089 312.239 320.285 312.239 319.294C312.239 318.303 311.437 317.5 310.448 317.5C309.459 317.5 308.657 318.303 308.657 319.294C308.657 320.285 309.459 321.089 310.448 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 321.089C305.467 321.089 306.269 320.285 306.269 319.294C306.269 318.303 305.467 317.5 304.478 317.5C303.488 317.5 302.687 318.303 302.687 319.294C302.687 320.285 303.488 321.089 304.478 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 321.089C299.497 321.089 300.299 320.285 300.299 319.294C300.299 318.303 299.497 317.5 298.507 317.5C297.518 317.5 296.716 318.303 296.716 319.294C296.716 320.285 297.518 321.089 298.507 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 321.089C293.526 321.089 294.328 320.285 294.328 319.294C294.328 318.303 293.526 317.5 292.537 317.5C291.548 317.5 290.746 318.303 290.746 319.294C290.746 320.285 291.548 321.089 292.537 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 321.089C287.556 321.089 288.358 320.285 288.358 319.294C288.358 318.303 287.556 317.5 286.567 317.5C285.578 317.5 284.776 318.303 284.776 319.294C284.776 320.285 285.578 321.089 286.567 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 321.089C281.586 321.089 282.388 320.285 282.388 319.294C282.388 318.303 281.586 317.5 280.597 317.5C279.608 317.5 278.806 318.303 278.806 319.294C278.806 320.285 279.608 321.089 280.597 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 321.089C275.616 321.089 276.418 320.285 276.418 319.294C276.418 318.303 275.616 317.5 274.627 317.5C273.638 317.5 272.836 318.303 272.836 319.294C272.836 320.285 273.638 321.089 274.627 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 321.089C269.646 321.089 270.448 320.285 270.448 319.294C270.448 318.303 269.646 317.5 268.657 317.5C267.668 317.5 266.866 318.303 266.866 319.294C266.866 320.285 267.668 321.089 268.657 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 321.089C263.676 321.089 264.478 320.285 264.478 319.294C264.478 318.303 263.676 317.5 262.687 317.5C261.697 317.5 260.896 318.303 260.896 319.294C260.896 320.285 261.697 321.089 262.687 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 321.089C257.706 321.089 258.507 320.285 258.507 319.294C258.507 318.303 257.706 317.5 256.716 317.5C255.727 317.5 254.925 318.303 254.925 319.294C254.925 320.285 255.727 321.089 256.716 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 321.089C251.735 321.089 252.537 320.285 252.537 319.294C252.537 318.303 251.735 317.5 250.746 317.5C249.757 317.5 248.955 318.303 248.955 319.294C248.955 320.285 249.757 321.089 250.746 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 321.089C245.765 321.089 246.567 320.285 246.567 319.294C246.567 318.303 245.765 317.5 244.776 317.5C243.787 317.5 242.985 318.303 242.985 319.294C242.985 320.285 243.787 321.089 244.776 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 321.089C239.795 321.089 240.597 320.285 240.597 319.294C240.597 318.303 239.795 317.5 238.806 317.5C237.817 317.5 237.015 318.303 237.015 319.294C237.015 320.285 237.817 321.089 238.806 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 321.089C233.825 321.089 234.627 320.285 234.627 319.294C234.627 318.303 233.825 317.5 232.836 317.5C231.847 317.5 231.045 318.303 231.045 319.294C231.045 320.285 231.847 321.089 232.836 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 321.089C227.855 321.089 228.657 320.285 228.657 319.294C228.657 318.303 227.855 317.5 226.866 317.5C225.877 317.5 225.075 318.303 225.075 319.294C225.075 320.285 225.877 321.089 226.866 321.089Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 315.106C299.497 315.106 300.299 314.303 300.299 313.312C300.299 312.321 299.497 311.517 298.507 311.517C297.518 311.517 296.716 312.321 296.716 313.312C296.716 314.303 297.518 315.106 298.507 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 315.106C293.526 315.106 294.328 314.303 294.328 313.312C294.328 312.321 293.526 311.517 292.537 311.517C291.548 311.517 290.746 312.321 290.746 313.312C290.746 314.303 291.548 315.106 292.537 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 315.106C287.556 315.106 288.358 314.303 288.358 313.312C288.358 312.321 287.556 311.517 286.567 311.517C285.578 311.517 284.776 312.321 284.776 313.312C284.776 314.303 285.578 315.106 286.567 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 315.106C281.586 315.106 282.388 314.303 282.388 313.312C282.388 312.321 281.586 311.517 280.597 311.517C279.608 311.517 278.806 312.321 278.806 313.312C278.806 314.303 279.608 315.106 280.597 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 315.106C275.616 315.106 276.418 314.303 276.418 313.312C276.418 312.321 275.616 311.517 274.627 311.517C273.638 311.517 272.836 312.321 272.836 313.312C272.836 314.303 273.638 315.106 274.627 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 315.106C269.646 315.106 270.448 314.303 270.448 313.312C270.448 312.321 269.646 311.517 268.657 311.517C267.668 311.517 266.866 312.321 266.866 313.312C266.866 314.303 267.668 315.106 268.657 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 315.106C263.676 315.106 264.478 314.303 264.478 313.312C264.478 312.321 263.676 311.517 262.687 311.517C261.697 311.517 260.896 312.321 260.896 313.312C260.896 314.303 261.697 315.106 262.687 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 315.106C257.706 315.106 258.507 314.303 258.507 313.312C258.507 312.321 257.706 311.517 256.716 311.517C255.727 311.517 254.925 312.321 254.925 313.312C254.925 314.303 255.727 315.106 256.716 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 315.106C251.735 315.106 252.537 314.303 252.537 313.312C252.537 312.321 251.735 311.517 250.746 311.517C249.757 311.517 248.955 312.321 248.955 313.312C248.955 314.303 249.757 315.106 250.746 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 315.106C245.765 315.106 246.567 314.303 246.567 313.312C246.567 312.321 245.765 311.517 244.776 311.517C243.787 311.517 242.985 312.321 242.985 313.312C242.985 314.303 243.787 315.106 244.776 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 315.106C239.795 315.106 240.597 314.303 240.597 313.312C240.597 312.321 239.795 311.517 238.806 311.517C237.817 311.517 237.015 312.321 237.015 313.312C237.015 314.303 237.817 315.106 238.806 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 315.106C233.825 315.106 234.627 314.303 234.627 313.312C234.627 312.321 233.825 311.517 232.836 311.517C231.847 311.517 231.045 312.321 231.045 313.312C231.045 314.303 231.847 315.106 232.836 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 315.106C227.855 315.106 228.657 314.303 228.657 313.312C228.657 312.321 227.855 311.517 226.866 311.517C225.877 311.517 225.075 312.321 225.075 313.312C225.075 314.303 225.877 315.106 226.866 315.106Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 309.125C293.526 309.125 294.328 308.321 294.328 307.33C294.328 306.339 293.526 305.535 292.537 305.535C291.548 305.535 290.746 306.339 290.746 307.33C290.746 308.321 291.548 309.125 292.537 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 309.125C287.556 309.125 288.358 308.321 288.358 307.33C288.358 306.339 287.556 305.535 286.567 305.535C285.578 305.535 284.776 306.339 284.776 307.33C284.776 308.321 285.578 309.125 286.567 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 309.125C281.586 309.125 282.388 308.321 282.388 307.33C282.388 306.339 281.586 305.535 280.597 305.535C279.608 305.535 278.806 306.339 278.806 307.33C278.806 308.321 279.608 309.125 280.597 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 309.125C275.616 309.125 276.418 308.321 276.418 307.33C276.418 306.339 275.616 305.535 274.627 305.535C273.638 305.535 272.836 306.339 272.836 307.33C272.836 308.321 273.638 309.125 274.627 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 309.125C269.646 309.125 270.448 308.321 270.448 307.33C270.448 306.339 269.646 305.535 268.657 305.535C267.668 305.535 266.866 306.339 266.866 307.33C266.866 308.321 267.668 309.125 268.657 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 309.125C263.676 309.125 264.478 308.321 264.478 307.33C264.478 306.339 263.676 305.535 262.687 305.535C261.697 305.535 260.896 306.339 260.896 307.33C260.896 308.321 261.697 309.125 262.687 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 309.125C257.706 309.125 258.507 308.321 258.507 307.33C258.507 306.339 257.706 305.535 256.716 305.535C255.727 305.535 254.925 306.339 254.925 307.33C254.925 308.321 255.727 309.125 256.716 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 309.125C251.735 309.125 252.537 308.321 252.537 307.33C252.537 306.339 251.735 305.535 250.746 305.535C249.757 305.535 248.955 306.339 248.955 307.33C248.955 308.321 249.757 309.125 250.746 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 309.125C245.765 309.125 246.567 308.321 246.567 307.33C246.567 306.339 245.765 305.535 244.776 305.535C243.787 305.535 242.985 306.339 242.985 307.33C242.985 308.321 243.787 309.125 244.776 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 309.125C239.795 309.125 240.597 308.321 240.597 307.33C240.597 306.339 239.795 305.535 238.806 305.535C237.817 305.535 237.015 306.339 237.015 307.33C237.015 308.321 237.817 309.125 238.806 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 309.125C233.825 309.125 234.627 308.321 234.627 307.33C234.627 306.339 233.825 305.535 232.836 305.535C231.847 305.535 231.045 306.339 231.045 307.33C231.045 308.321 231.847 309.125 232.836 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 309.125C227.855 309.125 228.657 308.321 228.657 307.33C228.657 306.339 227.855 305.535 226.866 305.535C225.877 305.535 225.075 306.339 225.075 307.33C225.075 308.321 225.877 309.125 226.866 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 309.125C221.885 309.125 222.687 308.321 222.687 307.33C222.687 306.339 221.885 305.535 220.896 305.535C219.906 305.535 219.104 306.339 219.104 307.33C219.104 308.321 219.906 309.125 220.896 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 309.125C215.915 309.125 216.716 308.321 216.716 307.33C216.716 306.339 215.915 305.535 214.925 305.535C213.936 305.535 213.134 306.339 213.134 307.33C213.134 308.321 213.936 309.125 214.925 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 309.125C209.944 309.125 210.746 308.321 210.746 307.33C210.746 306.339 209.944 305.535 208.955 305.535C207.966 305.535 207.164 306.339 207.164 307.33C207.164 308.321 207.966 309.125 208.955 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 309.125C203.974 309.125 204.776 308.321 204.776 307.33C204.776 306.339 203.974 305.535 202.985 305.535C201.996 305.535 201.194 306.339 201.194 307.33C201.194 308.321 201.996 309.125 202.985 309.125Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 303.142C287.556 303.142 288.358 302.339 288.358 301.347C288.358 300.356 287.556 299.553 286.567 299.553C285.578 299.553 284.776 300.356 284.776 301.347C284.776 302.339 285.578 303.142 286.567 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 303.142C281.586 303.142 282.388 302.339 282.388 301.347C282.388 300.356 281.586 299.553 280.597 299.553C279.608 299.553 278.806 300.356 278.806 301.347C278.806 302.339 279.608 303.142 280.597 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 303.142C269.646 303.142 270.448 302.339 270.448 301.347C270.448 300.356 269.646 299.553 268.657 299.553C267.668 299.553 266.866 300.356 266.866 301.347C266.866 302.339 267.668 303.142 268.657 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 303.142C263.676 303.142 264.478 302.339 264.478 301.347C264.478 300.356 263.676 299.553 262.687 299.553C261.697 299.553 260.896 300.356 260.896 301.347C260.896 302.339 261.697 303.142 262.687 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 303.142C257.706 303.142 258.507 302.339 258.507 301.347C258.507 300.356 257.706 299.553 256.716 299.553C255.727 299.553 254.925 300.356 254.925 301.347C254.925 302.339 255.727 303.142 256.716 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 303.142C251.735 303.142 252.537 302.339 252.537 301.347C252.537 300.356 251.735 299.553 250.746 299.553C249.757 299.553 248.955 300.356 248.955 301.347C248.955 302.339 249.757 303.142 250.746 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 303.142C245.765 303.142 246.567 302.339 246.567 301.347C246.567 300.356 245.765 299.553 244.776 299.553C243.787 299.553 242.985 300.356 242.985 301.347C242.985 302.339 243.787 303.142 244.776 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 303.142C239.795 303.142 240.597 302.339 240.597 301.347C240.597 300.356 239.795 299.553 238.806 299.553C237.817 299.553 237.015 300.356 237.015 301.347C237.015 302.339 237.817 303.142 238.806 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 303.142C233.825 303.142 234.627 302.339 234.627 301.347C234.627 300.356 233.825 299.553 232.836 299.553C231.847 299.553 231.045 300.356 231.045 301.347C231.045 302.339 231.847 303.142 232.836 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 303.142C203.974 303.142 204.776 302.339 204.776 301.347C204.776 300.356 203.974 299.553 202.985 299.553C201.996 299.553 201.194 300.356 201.194 301.347C201.194 302.339 201.996 303.142 202.985 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 303.142C198.004 303.142 198.806 302.339 198.806 301.347C198.806 300.356 198.004 299.553 197.015 299.553C196.026 299.553 195.224 300.356 195.224 301.347C195.224 302.339 196.026 303.142 197.015 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 297.16C263.676 297.16 264.478 296.356 264.478 295.365C264.478 294.374 263.676 293.57 262.687 293.57C261.697 293.57 260.896 294.374 260.896 295.365C260.896 296.356 261.697 297.16 262.687 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 297.16C257.706 297.16 258.507 296.356 258.507 295.365C258.507 294.374 257.706 293.57 256.716 293.57C255.727 293.57 254.925 294.374 254.925 295.365C254.925 296.356 255.727 297.16 256.716 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 297.16C245.765 297.16 246.567 296.356 246.567 295.365C246.567 294.374 245.765 293.57 244.776 293.57C243.787 293.57 242.985 294.374 242.985 295.365C242.985 296.356 243.787 297.16 244.776 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 297.16C239.795 297.16 240.597 296.356 240.597 295.365C240.597 294.374 239.795 293.57 238.806 293.57C237.817 293.57 237.015 294.374 237.015 295.365C237.015 296.356 237.817 297.16 238.806 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 297.16C203.974 297.16 204.776 296.356 204.776 295.365C204.776 294.374 203.974 293.57 202.985 293.57C201.996 293.57 201.194 294.374 201.194 295.365C201.194 296.356 201.996 297.16 202.985 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 297.16C198.004 297.16 198.806 296.356 198.806 295.365C198.806 294.374 198.004 293.57 197.015 293.57C196.026 293.57 195.224 294.374 195.224 295.365C195.224 296.356 196.026 297.16 197.015 297.16Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 291.177C203.974 291.177 204.776 290.374 204.776 289.383C204.776 288.391 203.974 287.588 202.985 287.588C201.996 287.588 201.194 288.391 201.194 289.383C201.194 290.374 201.996 291.177 202.985 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 291.177C198.004 291.177 198.806 290.374 198.806 289.383C198.806 288.391 198.004 287.588 197.015 287.588C196.026 287.588 195.224 288.391 195.224 289.383C195.224 290.374 196.026 291.177 197.015 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 291.177C192.034 291.177 192.836 290.374 192.836 289.383C192.836 288.391 192.034 287.588 191.045 287.588C190.056 287.588 189.254 288.391 189.254 289.383C189.254 290.374 190.056 291.177 191.045 291.177Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 285.195C203.974 285.195 204.776 284.392 204.776 283.401C204.776 282.409 203.974 281.606 202.985 281.606C201.996 281.606 201.194 282.409 201.194 283.401C201.194 284.392 201.996 285.195 202.985 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 285.195C198.004 285.195 198.806 284.392 198.806 283.401C198.806 282.409 198.004 281.606 197.015 281.606C196.026 281.606 195.224 282.409 195.224 283.401C195.224 284.392 196.026 285.195 197.015 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 285.195C192.034 285.195 192.836 284.392 192.836 283.401C192.836 282.409 192.034 281.606 191.045 281.606C190.056 281.606 189.254 282.409 189.254 283.401C189.254 284.392 190.056 285.195 191.045 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 285.195C186.064 285.195 186.866 284.392 186.866 283.401C186.866 282.409 186.064 281.606 185.075 281.606C184.085 281.606 183.284 282.409 183.284 283.401C183.284 284.392 184.085 285.195 185.075 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 285.195C180.094 285.195 180.895 284.392 180.895 283.401C180.895 282.409 180.094 281.606 179.104 281.606C178.115 281.606 177.313 282.409 177.313 283.401C177.313 284.392 178.115 285.195 179.104 285.195Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 279.213C203.974 279.213 204.776 278.409 204.776 277.418C204.776 276.427 203.974 275.624 202.985 275.624C201.996 275.624 201.194 276.427 201.194 277.418C201.194 278.409 201.996 279.213 202.985 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 279.213C198.004 279.213 198.806 278.409 198.806 277.418C198.806 276.427 198.004 275.624 197.015 275.624C196.026 275.624 195.224 276.427 195.224 277.418C195.224 278.409 196.026 279.213 197.015 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 279.213C192.034 279.213 192.836 278.409 192.836 277.418C192.836 276.427 192.034 275.624 191.045 275.624C190.056 275.624 189.254 276.427 189.254 277.418C189.254 278.409 190.056 279.213 191.045 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 279.213C186.064 279.213 186.866 278.409 186.866 277.418C186.866 276.427 186.064 275.624 185.075 275.624C184.085 275.624 183.284 276.427 183.284 277.418C183.284 278.409 184.085 279.213 185.075 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 279.213C180.094 279.213 180.895 278.409 180.895 277.418C180.895 276.427 180.094 275.624 179.104 275.624C178.115 275.624 177.313 276.427 177.313 277.418C177.313 278.409 178.115 279.213 179.104 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 279.213C174.123 279.213 174.925 278.409 174.925 277.418C174.925 276.427 174.123 275.624 173.134 275.624C172.145 275.624 171.343 276.427 171.343 277.418C171.343 278.409 172.145 279.213 173.134 279.213Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 273.23C186.064 273.23 186.866 272.427 186.866 271.436C186.866 270.445 186.064 269.641 185.075 269.641C184.085 269.641 183.284 270.445 183.284 271.436C183.284 272.427 184.085 273.23 185.075 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 273.23C180.094 273.23 180.895 272.427 180.895 271.436C180.895 270.445 180.094 269.641 179.104 269.641C178.115 269.641 177.313 270.445 177.313 271.436C177.313 272.427 178.115 273.23 179.104 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 273.23C174.123 273.23 174.925 272.427 174.925 271.436C174.925 270.445 174.123 269.641 173.134 269.641C172.145 269.641 171.343 270.445 171.343 271.436C171.343 272.427 172.145 273.23 173.134 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 273.23C168.153 273.23 168.955 272.427 168.955 271.436C168.955 270.445 168.153 269.641 167.164 269.641C166.175 269.641 165.373 270.445 165.373 271.436C165.373 272.427 166.175 273.23 167.164 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 273.23C162.183 273.23 162.985 272.427 162.985 271.436C162.985 270.445 162.183 269.641 161.194 269.641C160.205 269.641 159.403 270.445 159.403 271.436C159.403 272.427 160.205 273.23 161.194 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 273.23C156.213 273.23 157.015 272.427 157.015 271.436C157.015 270.445 156.213 269.641 155.224 269.641C154.235 269.641 153.433 270.445 153.433 271.436C153.433 272.427 154.235 273.23 155.224 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 273.23C150.243 273.23 151.045 272.427 151.045 271.436C151.045 270.445 150.243 269.641 149.254 269.641C148.265 269.641 147.463 270.445 147.463 271.436C147.463 272.427 148.265 273.23 149.254 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 273.23C144.273 273.23 145.075 272.427 145.075 271.436C145.075 270.445 144.273 269.641 143.284 269.641C142.294 269.641 141.493 270.445 141.493 271.436C141.493 272.427 142.294 273.23 143.284 273.23Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 267.249C275.616 267.249 276.418 266.445 276.418 265.454C276.418 264.463 275.616 263.659 274.627 263.659C273.638 263.659 272.836 264.463 272.836 265.454C272.836 266.445 273.638 267.249 274.627 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 267.249C257.706 267.249 258.507 266.445 258.507 265.454C258.507 264.463 257.706 263.659 256.716 263.659C255.727 263.659 254.925 264.463 254.925 265.454C254.925 266.445 255.727 267.249 256.716 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 267.249C251.735 267.249 252.537 266.445 252.537 265.454C252.537 264.463 251.735 263.659 250.746 263.659C249.757 263.659 248.955 264.463 248.955 265.454C248.955 266.445 249.757 267.249 250.746 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 267.249C245.765 267.249 246.567 266.445 246.567 265.454C246.567 264.463 245.765 263.659 244.776 263.659C243.787 263.659 242.985 264.463 242.985 265.454C242.985 266.445 243.787 267.249 244.776 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 267.249C227.855 267.249 228.657 266.445 228.657 265.454C228.657 264.463 227.855 263.659 226.866 263.659C225.877 263.659 225.075 264.463 225.075 265.454C225.075 266.445 225.877 267.249 226.866 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 267.249C186.064 267.249 186.866 266.445 186.866 265.454C186.866 264.463 186.064 263.659 185.075 263.659C184.085 263.659 183.284 264.463 183.284 265.454C183.284 266.445 184.085 267.249 185.075 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 267.249C180.094 267.249 180.895 266.445 180.895 265.454C180.895 264.463 180.094 263.659 179.104 263.659C178.115 263.659 177.313 264.463 177.313 265.454C177.313 266.445 178.115 267.249 179.104 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 267.249C174.123 267.249 174.925 266.445 174.925 265.454C174.925 264.463 174.123 263.659 173.134 263.659C172.145 263.659 171.343 264.463 171.343 265.454C171.343 266.445 172.145 267.249 173.134 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 267.249C168.153 267.249 168.955 266.445 168.955 265.454C168.955 264.463 168.153 263.659 167.164 263.659C166.175 263.659 165.373 264.463 165.373 265.454C165.373 266.445 166.175 267.249 167.164 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 267.249C162.183 267.249 162.985 266.445 162.985 265.454C162.985 264.463 162.183 263.659 161.194 263.659C160.205 263.659 159.403 264.463 159.403 265.454C159.403 266.445 160.205 267.249 161.194 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 267.249C156.213 267.249 157.015 266.445 157.015 265.454C157.015 264.463 156.213 263.659 155.224 263.659C154.235 263.659 153.433 264.463 153.433 265.454C153.433 266.445 154.235 267.249 155.224 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 267.249C150.243 267.249 151.045 266.445 151.045 265.454C151.045 264.463 150.243 263.659 149.254 263.659C148.265 263.659 147.463 264.463 147.463 265.454C147.463 266.445 148.265 267.249 149.254 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 267.249C144.273 267.249 145.075 266.445 145.075 265.454C145.075 264.463 144.273 263.659 143.284 263.659C142.294 263.659 141.493 264.463 141.493 265.454C141.493 266.445 142.294 267.249 143.284 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 267.249C138.303 267.249 139.104 266.445 139.104 265.454C139.104 264.463 138.303 263.659 137.313 263.659C136.324 263.659 135.522 264.463 135.522 265.454C135.522 266.445 136.324 267.249 137.313 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 267.249C132.332 267.249 133.134 266.445 133.134 265.454C133.134 264.463 132.332 263.659 131.343 263.659C130.354 263.659 129.552 264.463 129.552 265.454C129.552 266.445 130.354 267.249 131.343 267.249Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 261.266C257.706 261.266 258.507 260.463 258.507 259.471C258.507 258.48 257.706 257.677 256.716 257.677C255.727 257.677 254.925 258.48 254.925 259.471C254.925 260.463 255.727 261.266 256.716 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 261.266C251.735 261.266 252.537 260.463 252.537 259.471C252.537 258.48 251.735 257.677 250.746 257.677C249.757 257.677 248.955 258.48 248.955 259.471C248.955 260.463 249.757 261.266 250.746 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 261.266C192.034 261.266 192.836 260.463 192.836 259.471C192.836 258.48 192.034 257.677 191.045 257.677C190.056 257.677 189.254 258.48 189.254 259.471C189.254 260.463 190.056 261.266 191.045 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 261.266C186.064 261.266 186.866 260.463 186.866 259.471C186.866 258.48 186.064 257.677 185.075 257.677C184.085 257.677 183.284 258.48 183.284 259.471C183.284 260.463 184.085 261.266 185.075 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 261.266C180.094 261.266 180.895 260.463 180.895 259.471C180.895 258.48 180.094 257.677 179.104 257.677C178.115 257.677 177.313 258.48 177.313 259.471C177.313 260.463 178.115 261.266 179.104 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 261.266C156.213 261.266 157.015 260.463 157.015 259.471C157.015 258.48 156.213 257.677 155.224 257.677C154.235 257.677 153.433 258.48 153.433 259.471C153.433 260.463 154.235 261.266 155.224 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 261.266C150.243 261.266 151.045 260.463 151.045 259.471C151.045 258.48 150.243 257.677 149.254 257.677C148.265 257.677 147.463 258.48 147.463 259.471C147.463 260.463 148.265 261.266 149.254 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 261.266C144.273 261.266 145.075 260.463 145.075 259.471C145.075 258.48 144.273 257.677 143.284 257.677C142.294 257.677 141.493 258.48 141.493 259.471C141.493 260.463 142.294 261.266 143.284 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 261.266C138.303 261.266 139.104 260.463 139.104 259.471C139.104 258.48 138.303 257.677 137.313 257.677C136.324 257.677 135.522 258.48 135.522 259.471C135.522 260.463 136.324 261.266 137.313 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 261.266C132.332 261.266 133.134 260.463 133.134 259.471C133.134 258.48 132.332 257.677 131.343 257.677C130.354 257.677 129.552 258.48 129.552 259.471C129.552 260.463 130.354 261.266 131.343 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 261.266C126.362 261.266 127.164 260.463 127.164 259.471C127.164 258.48 126.362 257.677 125.373 257.677C124.384 257.677 123.582 258.48 123.582 259.471C123.582 260.463 124.384 261.266 125.373 261.266Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 255.284C239.795 255.284 240.597 254.48 240.597 253.489C240.597 252.498 239.795 251.694 238.806 251.694C237.817 251.694 237.015 252.498 237.015 253.489C237.015 254.48 237.817 255.284 238.806 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 255.284C233.825 255.284 234.627 254.48 234.627 253.489C234.627 252.498 233.825 251.694 232.836 251.694C231.847 251.694 231.045 252.498 231.045 253.489C231.045 254.48 231.847 255.284 232.836 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 255.284C192.034 255.284 192.836 254.48 192.836 253.489C192.836 252.498 192.034 251.694 191.045 251.694C190.056 251.694 189.254 252.498 189.254 253.489C189.254 254.48 190.056 255.284 191.045 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 255.284C186.064 255.284 186.866 254.48 186.866 253.489C186.866 252.498 186.064 251.694 185.075 251.694C184.085 251.694 183.284 252.498 183.284 253.489C183.284 254.48 184.085 255.284 185.075 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 255.284C156.213 255.284 157.015 254.48 157.015 253.489C157.015 252.498 156.213 251.694 155.224 251.694C154.235 251.694 153.433 252.498 153.433 253.489C153.433 254.48 154.235 255.284 155.224 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 255.284C150.243 255.284 151.045 254.48 151.045 253.489C151.045 252.498 150.243 251.694 149.254 251.694C148.265 251.694 147.463 252.498 147.463 253.489C147.463 254.48 148.265 255.284 149.254 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 255.284C138.303 255.284 139.104 254.48 139.104 253.489C139.104 252.498 138.303 251.694 137.313 251.694C136.324 251.694 135.522 252.498 135.522 253.489C135.522 254.48 136.324 255.284 137.313 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 255.284C132.332 255.284 133.134 254.48 133.134 253.489C133.134 252.498 132.332 251.694 131.343 251.694C130.354 251.694 129.552 252.498 129.552 253.489C129.552 254.48 130.354 255.284 131.343 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 255.284C126.362 255.284 127.164 254.48 127.164 253.489C127.164 252.498 126.362 251.694 125.373 251.694C124.384 251.694 123.582 252.498 123.582 253.489C123.582 254.48 124.384 255.284 125.373 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 249.302C233.825 249.302 234.627 248.498 234.627 247.507C234.627 246.516 233.825 245.712 232.836 245.712C231.847 245.712 231.045 246.516 231.045 247.507C231.045 248.498 231.847 249.302 232.836 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 249.302C227.855 249.302 228.657 248.498 228.657 247.507C228.657 246.516 227.855 245.712 226.866 245.712C225.877 245.712 225.075 246.516 225.075 247.507C225.075 248.498 225.877 249.302 226.866 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 249.302C221.885 249.302 222.687 248.498 222.687 247.507C222.687 246.516 221.885 245.712 220.896 245.712C219.906 245.712 219.104 246.516 219.104 247.507C219.104 248.498 219.906 249.302 220.896 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 249.302C215.915 249.302 216.716 248.498 216.716 247.507C216.716 246.516 215.915 245.712 214.925 245.712C213.936 245.712 213.134 246.516 213.134 247.507C213.134 248.498 213.936 249.302 214.925 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 249.302C209.944 249.302 210.746 248.498 210.746 247.507C210.746 246.516 209.944 245.712 208.955 245.712C207.966 245.712 207.164 246.516 207.164 247.507C207.164 248.498 207.966 249.302 208.955 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 249.302C150.243 249.302 151.045 248.498 151.045 247.507C151.045 246.516 150.243 245.712 149.254 245.712C148.265 245.712 147.463 246.516 147.463 247.507C147.463 248.498 148.265 249.302 149.254 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 249.302C144.273 249.302 145.075 248.498 145.075 247.507C145.075 246.516 144.273 245.712 143.284 245.712C142.294 245.712 141.493 246.516 141.493 247.507C141.493 248.498 142.294 249.302 143.284 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 249.302C138.303 249.302 139.104 248.498 139.104 247.507C139.104 246.516 138.303 245.712 137.313 245.712C136.324 245.712 135.522 246.516 135.522 247.507C135.522 248.498 136.324 249.302 137.313 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 249.302C132.332 249.302 133.134 248.498 133.134 247.507C133.134 246.516 132.332 245.712 131.343 245.712C130.354 245.712 129.552 246.516 129.552 247.507C129.552 248.498 130.354 249.302 131.343 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 249.302C126.362 249.302 127.164 248.498 127.164 247.507C127.164 246.516 126.362 245.712 125.373 245.712C124.384 245.712 123.582 246.516 123.582 247.507C123.582 248.498 124.384 249.302 125.373 249.302Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 243.319C150.243 243.319 151.045 242.516 151.045 241.525C151.045 240.533 150.243 239.73 149.254 239.73C148.265 239.73 147.463 240.533 147.463 241.525C147.463 242.516 148.265 243.319 149.254 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 243.319C144.273 243.319 145.075 242.516 145.075 241.525C145.075 240.533 144.273 239.73 143.284 239.73C142.294 239.73 141.493 240.533 141.493 241.525C141.493 242.516 142.294 243.319 143.284 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 243.319C138.303 243.319 139.104 242.516 139.104 241.525C139.104 240.533 138.303 239.73 137.313 239.73C136.324 239.73 135.522 240.533 135.522 241.525C135.522 242.516 136.324 243.319 137.313 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 243.319C132.332 243.319 133.134 242.516 133.134 241.525C133.134 240.533 132.332 239.73 131.343 239.73C130.354 239.73 129.552 240.533 129.552 241.525C129.552 242.516 130.354 243.319 131.343 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 243.319C126.362 243.319 127.164 242.516 127.164 241.525C127.164 240.533 126.362 239.73 125.373 239.73C124.384 239.73 123.582 240.533 123.582 241.525C123.582 242.516 124.384 243.319 125.373 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 243.319C120.392 243.319 121.194 242.516 121.194 241.525C121.194 240.533 120.392 239.73 119.403 239.73C118.414 239.73 117.612 240.533 117.612 241.525C117.612 242.516 118.414 243.319 119.403 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 243.319C108.452 243.319 109.254 242.516 109.254 241.525C109.254 240.533 108.452 239.73 107.463 239.73C106.474 239.73 105.672 240.533 105.672 241.525C105.672 242.516 106.474 243.319 107.463 243.319Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 237.337C221.885 237.337 222.687 236.533 222.687 235.542C222.687 234.551 221.885 233.748 220.896 233.748C219.906 233.748 219.104 234.551 219.104 235.542C219.104 236.533 219.906 237.337 220.896 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 237.337C156.213 237.337 157.015 236.533 157.015 235.542C157.015 234.551 156.213 233.748 155.224 233.748C154.235 233.748 153.433 234.551 153.433 235.542C153.433 236.533 154.235 237.337 155.224 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 237.337C150.243 237.337 151.045 236.533 151.045 235.542C151.045 234.551 150.243 233.748 149.254 233.748C148.265 233.748 147.463 234.551 147.463 235.542C147.463 236.533 148.265 237.337 149.254 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 237.337C144.273 237.337 145.075 236.533 145.075 235.542C145.075 234.551 144.273 233.748 143.284 233.748C142.294 233.748 141.493 234.551 141.493 235.542C141.493 236.533 142.294 237.337 143.284 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 237.337C138.303 237.337 139.104 236.533 139.104 235.542C139.104 234.551 138.303 233.748 137.313 233.748C136.324 233.748 135.522 234.551 135.522 235.542C135.522 236.533 136.324 237.337 137.313 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 237.337C132.332 237.337 133.134 236.533 133.134 235.542C133.134 234.551 132.332 233.748 131.343 233.748C130.354 233.748 129.552 234.551 129.552 235.542C129.552 236.533 130.354 237.337 131.343 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 237.337C126.362 237.337 127.164 236.533 127.164 235.542C127.164 234.551 126.362 233.748 125.373 233.748C124.384 233.748 123.582 234.551 123.582 235.542C123.582 236.533 124.384 237.337 125.373 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 237.337C120.392 237.337 121.194 236.533 121.194 235.542C121.194 234.551 120.392 233.748 119.403 233.748C118.414 233.748 117.612 234.551 117.612 235.542C117.612 236.533 118.414 237.337 119.403 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 237.337C102.482 237.337 103.284 236.533 103.284 235.542C103.284 234.551 102.482 233.748 101.493 233.748C100.503 233.748 99.7015 234.551 99.7015 235.542C99.7015 236.533 100.503 237.337 101.493 237.337Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 231.354C221.885 231.354 222.687 230.551 222.687 229.56C222.687 228.569 221.885 227.765 220.896 227.765C219.906 227.765 219.104 228.569 219.104 229.56C219.104 230.551 219.906 231.354 220.896 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 231.354C156.213 231.354 157.015 230.551 157.015 229.56C157.015 228.569 156.213 227.765 155.224 227.765C154.235 227.765 153.433 228.569 153.433 229.56C153.433 230.551 154.235 231.354 155.224 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 231.354C150.243 231.354 151.045 230.551 151.045 229.56C151.045 228.569 150.243 227.765 149.254 227.765C148.265 227.765 147.463 228.569 147.463 229.56C147.463 230.551 148.265 231.354 149.254 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 231.354C144.273 231.354 145.075 230.551 145.075 229.56C145.075 228.569 144.273 227.765 143.284 227.765C142.294 227.765 141.493 228.569 141.493 229.56C141.493 230.551 142.294 231.354 143.284 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 231.354C138.303 231.354 139.104 230.551 139.104 229.56C139.104 228.569 138.303 227.765 137.313 227.765C136.324 227.765 135.522 228.569 135.522 229.56C135.522 230.551 136.324 231.354 137.313 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 231.354C132.332 231.354 133.134 230.551 133.134 229.56C133.134 228.569 132.332 227.765 131.343 227.765C130.354 227.765 129.552 228.569 129.552 229.56C129.552 230.551 130.354 231.354 131.343 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 231.354C126.362 231.354 127.164 230.551 127.164 229.56C127.164 228.569 126.362 227.765 125.373 227.765C124.384 227.765 123.582 228.569 123.582 229.56C123.582 230.551 124.384 231.354 125.373 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 231.354C120.392 231.354 121.194 230.551 121.194 229.56C121.194 228.569 120.392 227.765 119.403 227.765C118.414 227.765 117.612 228.569 117.612 229.56C117.612 230.551 118.414 231.354 119.403 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 231.354C114.422 231.354 115.224 230.551 115.224 229.56C115.224 228.569 114.422 227.765 113.433 227.765C112.444 227.765 111.642 228.569 111.642 229.56C111.642 230.551 112.444 231.354 113.433 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 231.354C102.482 231.354 103.284 230.551 103.284 229.56C103.284 228.569 102.482 227.765 101.493 227.765C100.503 227.765 99.7015 228.569 99.7015 229.56C99.7015 230.551 100.503 231.354 101.493 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 225.373C227.855 225.373 228.657 224.569 228.657 223.578C228.657 222.587 227.855 221.783 226.866 221.783C225.877 221.783 225.075 222.587 225.075 223.578C225.075 224.569 225.877 225.373 226.866 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 225.373C221.885 225.373 222.687 224.569 222.687 223.578C222.687 222.587 221.885 221.783 220.896 221.783C219.906 221.783 219.104 222.587 219.104 223.578C219.104 224.569 219.906 225.373 220.896 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 225.373C162.183 225.373 162.985 224.569 162.985 223.578C162.985 222.587 162.183 221.783 161.194 221.783C160.205 221.783 159.403 222.587 159.403 223.578C159.403 224.569 160.205 225.373 161.194 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 225.373C156.213 225.373 157.015 224.569 157.015 223.578C157.015 222.587 156.213 221.783 155.224 221.783C154.235 221.783 153.433 222.587 153.433 223.578C153.433 224.569 154.235 225.373 155.224 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 225.373C150.243 225.373 151.045 224.569 151.045 223.578C151.045 222.587 150.243 221.783 149.254 221.783C148.265 221.783 147.463 222.587 147.463 223.578C147.463 224.569 148.265 225.373 149.254 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 225.373C144.273 225.373 145.075 224.569 145.075 223.578C145.075 222.587 144.273 221.783 143.284 221.783C142.294 221.783 141.493 222.587 141.493 223.578C141.493 224.569 142.294 225.373 143.284 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 225.373C138.303 225.373 139.104 224.569 139.104 223.578C139.104 222.587 138.303 221.783 137.313 221.783C136.324 221.783 135.522 222.587 135.522 223.578C135.522 224.569 136.324 225.373 137.313 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 225.373C132.332 225.373 133.134 224.569 133.134 223.578C133.134 222.587 132.332 221.783 131.343 221.783C130.354 221.783 129.552 222.587 129.552 223.578C129.552 224.569 130.354 225.373 131.343 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 225.373C126.362 225.373 127.164 224.569 127.164 223.578C127.164 222.587 126.362 221.783 125.373 221.783C124.384 221.783 123.582 222.587 123.582 223.578C123.582 224.569 124.384 225.373 125.373 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 225.373C120.392 225.373 121.194 224.569 121.194 223.578C121.194 222.587 120.392 221.783 119.403 221.783C118.414 221.783 117.612 222.587 117.612 223.578C117.612 224.569 118.414 225.373 119.403 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 225.373C114.422 225.373 115.224 224.569 115.224 223.578C115.224 222.587 114.422 221.783 113.433 221.783C112.444 221.783 111.642 222.587 111.642 223.578C111.642 224.569 112.444 225.373 113.433 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 225.373C102.482 225.373 103.284 224.569 103.284 223.578C103.284 222.587 102.482 221.783 101.493 221.783C100.503 221.783 99.7015 222.587 99.7015 223.578C99.7015 224.569 100.503 225.373 101.493 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 225.373C96.5116 225.373 97.3134 224.569 97.3134 223.578C97.3134 222.587 96.5116 221.783 95.5224 221.783C94.5332 221.783 93.7314 222.587 93.7314 223.578C93.7314 224.569 94.5332 225.373 95.5224 225.373Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 219.39C221.885 219.39 222.687 218.587 222.687 217.595C222.687 216.604 221.885 215.801 220.896 215.801C219.906 215.801 219.104 216.604 219.104 217.595C219.104 218.587 219.906 219.39 220.896 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 219.39C168.153 219.39 168.955 218.587 168.955 217.595C168.955 216.604 168.153 215.801 167.164 215.801C166.175 215.801 165.373 216.604 165.373 217.595C165.373 218.587 166.175 219.39 167.164 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 219.39C162.183 219.39 162.985 218.587 162.985 217.595C162.985 216.604 162.183 215.801 161.194 215.801C160.205 215.801 159.403 216.604 159.403 217.595C159.403 218.587 160.205 219.39 161.194 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 219.39C156.213 219.39 157.015 218.587 157.015 217.595C157.015 216.604 156.213 215.801 155.224 215.801C154.235 215.801 153.433 216.604 153.433 217.595C153.433 218.587 154.235 219.39 155.224 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 219.39C150.243 219.39 151.045 218.587 151.045 217.595C151.045 216.604 150.243 215.801 149.254 215.801C148.265 215.801 147.463 216.604 147.463 217.595C147.463 218.587 148.265 219.39 149.254 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 219.39C144.273 219.39 145.075 218.587 145.075 217.595C145.075 216.604 144.273 215.801 143.284 215.801C142.294 215.801 141.493 216.604 141.493 217.595C141.493 218.587 142.294 219.39 143.284 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 219.39C138.303 219.39 139.104 218.587 139.104 217.595C139.104 216.604 138.303 215.801 137.313 215.801C136.324 215.801 135.522 216.604 135.522 217.595C135.522 218.587 136.324 219.39 137.313 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 219.39C132.332 219.39 133.134 218.587 133.134 217.595C133.134 216.604 132.332 215.801 131.343 215.801C130.354 215.801 129.552 216.604 129.552 217.595C129.552 218.587 130.354 219.39 131.343 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 219.39C126.362 219.39 127.164 218.587 127.164 217.595C127.164 216.604 126.362 215.801 125.373 215.801C124.384 215.801 123.582 216.604 123.582 217.595C123.582 218.587 124.384 219.39 125.373 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 219.39C120.392 219.39 121.194 218.587 121.194 217.595C121.194 216.604 120.392 215.801 119.403 215.801C118.414 215.801 117.612 216.604 117.612 217.595C117.612 218.587 118.414 219.39 119.403 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 219.39C114.422 219.39 115.224 218.587 115.224 217.595C115.224 216.604 114.422 215.801 113.433 215.801C112.444 215.801 111.642 216.604 111.642 217.595C111.642 218.587 112.444 219.39 113.433 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 219.39C108.452 219.39 109.254 218.587 109.254 217.595C109.254 216.604 108.452 215.801 107.463 215.801C106.474 215.801 105.672 216.604 105.672 217.595C105.672 218.587 106.474 219.39 107.463 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 219.39C96.5116 219.39 97.3134 218.587 97.3134 217.595C97.3134 216.604 96.5116 215.801 95.5224 215.801C94.5332 215.801 93.7314 216.604 93.7314 217.595C93.7314 218.587 94.5332 219.39 95.5224 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 213.408C221.885 213.408 222.687 212.604 222.687 211.613C222.687 210.622 221.885 209.818 220.896 209.818C219.906 209.818 219.104 210.622 219.104 211.613C219.104 212.604 219.906 213.408 220.896 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 213.408C215.915 213.408 216.716 212.604 216.716 211.613C216.716 210.622 215.915 209.818 214.925 209.818C213.936 209.818 213.134 210.622 213.134 211.613C213.134 212.604 213.936 213.408 214.925 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 213.408C209.944 213.408 210.746 212.604 210.746 211.613C210.746 210.622 209.944 209.818 208.955 209.818C207.966 209.818 207.164 210.622 207.164 211.613C207.164 212.604 207.966 213.408 208.955 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 213.408C203.974 213.408 204.776 212.604 204.776 211.613C204.776 210.622 203.974 209.818 202.985 209.818C201.996 209.818 201.194 210.622 201.194 211.613C201.194 212.604 201.996 213.408 202.985 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 213.408C198.004 213.408 198.806 212.604 198.806 211.613C198.806 210.622 198.004 209.818 197.015 209.818C196.026 209.818 195.224 210.622 195.224 211.613C195.224 212.604 196.026 213.408 197.015 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 213.408C192.034 213.408 192.836 212.604 192.836 211.613C192.836 210.622 192.034 209.818 191.045 209.818C190.056 209.818 189.254 210.622 189.254 211.613C189.254 212.604 190.056 213.408 191.045 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 213.408C186.064 213.408 186.866 212.604 186.866 211.613C186.866 210.622 186.064 209.818 185.075 209.818C184.085 209.818 183.284 210.622 183.284 211.613C183.284 212.604 184.085 213.408 185.075 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 213.408C180.094 213.408 180.895 212.604 180.895 211.613C180.895 210.622 180.094 209.818 179.104 209.818C178.115 209.818 177.313 210.622 177.313 211.613C177.313 212.604 178.115 213.408 179.104 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 213.408C174.123 213.408 174.925 212.604 174.925 211.613C174.925 210.622 174.123 209.818 173.134 209.818C172.145 209.818 171.343 210.622 171.343 211.613C171.343 212.604 172.145 213.408 173.134 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 213.408C162.183 213.408 162.985 212.604 162.985 211.613C162.985 210.622 162.183 209.818 161.194 209.818C160.205 209.818 159.403 210.622 159.403 211.613C159.403 212.604 160.205 213.408 161.194 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 213.408C156.213 213.408 157.015 212.604 157.015 211.613C157.015 210.622 156.213 209.818 155.224 209.818C154.235 209.818 153.433 210.622 153.433 211.613C153.433 212.604 154.235 213.408 155.224 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 213.408C150.243 213.408 151.045 212.604 151.045 211.613C151.045 210.622 150.243 209.818 149.254 209.818C148.265 209.818 147.463 210.622 147.463 211.613C147.463 212.604 148.265 213.408 149.254 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 213.408C144.273 213.408 145.075 212.604 145.075 211.613C145.075 210.622 144.273 209.818 143.284 209.818C142.294 209.818 141.493 210.622 141.493 211.613C141.493 212.604 142.294 213.408 143.284 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 213.408C138.303 213.408 139.104 212.604 139.104 211.613C139.104 210.622 138.303 209.818 137.313 209.818C136.324 209.818 135.522 210.622 135.522 211.613C135.522 212.604 136.324 213.408 137.313 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 213.408C132.332 213.408 133.134 212.604 133.134 211.613C133.134 210.622 132.332 209.818 131.343 209.818C130.354 209.818 129.552 210.622 129.552 211.613C129.552 212.604 130.354 213.408 131.343 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 213.408C126.362 213.408 127.164 212.604 127.164 211.613C127.164 210.622 126.362 209.818 125.373 209.818C124.384 209.818 123.582 210.622 123.582 211.613C123.582 212.604 124.384 213.408 125.373 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 213.408C120.392 213.408 121.194 212.604 121.194 211.613C121.194 210.622 120.392 209.818 119.403 209.818C118.414 209.818 117.612 210.622 117.612 211.613C117.612 212.604 118.414 213.408 119.403 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 213.408C114.422 213.408 115.224 212.604 115.224 211.613C115.224 210.622 114.422 209.818 113.433 209.818C112.444 209.818 111.642 210.622 111.642 211.613C111.642 212.604 112.444 213.408 113.433 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 213.408C108.452 213.408 109.254 212.604 109.254 211.613C109.254 210.622 108.452 209.818 107.463 209.818C106.474 209.818 105.672 210.622 105.672 211.613C105.672 212.604 106.474 213.408 107.463 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 213.408C96.5116 213.408 97.3134 212.604 97.3134 211.613C97.3134 210.622 96.5116 209.818 95.5224 209.818C94.5332 209.818 93.7314 210.622 93.7314 211.613C93.7314 212.604 94.5332 213.408 95.5224 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 207.426C227.855 207.426 228.657 206.622 228.657 205.631C228.657 204.64 227.855 203.836 226.866 203.836C225.877 203.836 225.075 204.64 225.075 205.631C225.075 206.622 225.877 207.426 226.866 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 207.426C221.885 207.426 222.687 206.622 222.687 205.631C222.687 204.64 221.885 203.836 220.896 203.836C219.906 203.836 219.104 204.64 219.104 205.631C219.104 206.622 219.906 207.426 220.896 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 207.426C215.915 207.426 216.716 206.622 216.716 205.631C216.716 204.64 215.915 203.836 214.925 203.836C213.936 203.836 213.134 204.64 213.134 205.631C213.134 206.622 213.936 207.426 214.925 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 207.426C209.944 207.426 210.746 206.622 210.746 205.631C210.746 204.64 209.944 203.836 208.955 203.836C207.966 203.836 207.164 204.64 207.164 205.631C207.164 206.622 207.966 207.426 208.955 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 207.426C203.974 207.426 204.776 206.622 204.776 205.631C204.776 204.64 203.974 203.836 202.985 203.836C201.996 203.836 201.194 204.64 201.194 205.631C201.194 206.622 201.996 207.426 202.985 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 207.426C198.004 207.426 198.806 206.622 198.806 205.631C198.806 204.64 198.004 203.836 197.015 203.836C196.026 203.836 195.224 204.64 195.224 205.631C195.224 206.622 196.026 207.426 197.015 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 207.426C192.034 207.426 192.836 206.622 192.836 205.631C192.836 204.64 192.034 203.836 191.045 203.836C190.056 203.836 189.254 204.64 189.254 205.631C189.254 206.622 190.056 207.426 191.045 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 207.426C186.064 207.426 186.866 206.622 186.866 205.631C186.866 204.64 186.064 203.836 185.075 203.836C184.085 203.836 183.284 204.64 183.284 205.631C183.284 206.622 184.085 207.426 185.075 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 207.426C180.094 207.426 180.895 206.622 180.895 205.631C180.895 204.64 180.094 203.836 179.104 203.836C178.115 203.836 177.313 204.64 177.313 205.631C177.313 206.622 178.115 207.426 179.104 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 207.426C174.123 207.426 174.925 206.622 174.925 205.631C174.925 204.64 174.123 203.836 173.134 203.836C172.145 203.836 171.343 204.64 171.343 205.631C171.343 206.622 172.145 207.426 173.134 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 207.426C168.153 207.426 168.955 206.622 168.955 205.631C168.955 204.64 168.153 203.836 167.164 203.836C166.175 203.836 165.373 204.64 165.373 205.631C165.373 206.622 166.175 207.426 167.164 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 207.426C162.183 207.426 162.985 206.622 162.985 205.631C162.985 204.64 162.183 203.836 161.194 203.836C160.205 203.836 159.403 204.64 159.403 205.631C159.403 206.622 160.205 207.426 161.194 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 207.426C156.213 207.426 157.015 206.622 157.015 205.631C157.015 204.64 156.213 203.836 155.224 203.836C154.235 203.836 153.433 204.64 153.433 205.631C153.433 206.622 154.235 207.426 155.224 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 207.426C150.243 207.426 151.045 206.622 151.045 205.631C151.045 204.64 150.243 203.836 149.254 203.836C148.265 203.836 147.463 204.64 147.463 205.631C147.463 206.622 148.265 207.426 149.254 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 207.426C144.273 207.426 145.075 206.622 145.075 205.631C145.075 204.64 144.273 203.836 143.284 203.836C142.294 203.836 141.493 204.64 141.493 205.631C141.493 206.622 142.294 207.426 143.284 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 207.426C138.303 207.426 139.104 206.622 139.104 205.631C139.104 204.64 138.303 203.836 137.313 203.836C136.324 203.836 135.522 204.64 135.522 205.631C135.522 206.622 136.324 207.426 137.313 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 207.426C132.332 207.426 133.134 206.622 133.134 205.631C133.134 204.64 132.332 203.836 131.343 203.836C130.354 203.836 129.552 204.64 129.552 205.631C129.552 206.622 130.354 207.426 131.343 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 207.426C126.362 207.426 127.164 206.622 127.164 205.631C127.164 204.64 126.362 203.836 125.373 203.836C124.384 203.836 123.582 204.64 123.582 205.631C123.582 206.622 124.384 207.426 125.373 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 207.426C120.392 207.426 121.194 206.622 121.194 205.631C121.194 204.64 120.392 203.836 119.403 203.836C118.414 203.836 117.612 204.64 117.612 205.631C117.612 206.622 118.414 207.426 119.403 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 207.426C114.422 207.426 115.224 206.622 115.224 205.631C115.224 204.64 114.422 203.836 113.433 203.836C112.444 203.836 111.642 204.64 111.642 205.631C111.642 206.622 112.444 207.426 113.433 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 207.426C108.452 207.426 109.254 206.622 109.254 205.631C109.254 204.64 108.452 203.836 107.463 203.836C106.474 203.836 105.672 204.64 105.672 205.631C105.672 206.622 106.474 207.426 107.463 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 207.426C102.482 207.426 103.284 206.622 103.284 205.631C103.284 204.64 102.482 203.836 101.493 203.836C100.503 203.836 99.7015 204.64 99.7015 205.631C99.7015 206.622 100.503 207.426 101.493 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 207.426C96.5116 207.426 97.3134 206.622 97.3134 205.631C97.3134 204.64 96.5116 203.836 95.5224 203.836C94.5332 203.836 93.7314 204.64 93.7314 205.631C93.7314 206.622 94.5332 207.426 95.5224 207.426Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 201.443C233.825 201.443 234.627 200.64 234.627 199.649C234.627 198.658 233.825 197.854 232.836 197.854C231.847 197.854 231.045 198.658 231.045 199.649C231.045 200.64 231.847 201.443 232.836 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 201.443C227.855 201.443 228.657 200.64 228.657 199.649C228.657 198.658 227.855 197.854 226.866 197.854C225.877 197.854 225.075 198.658 225.075 199.649C225.075 200.64 225.877 201.443 226.866 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 201.443C221.885 201.443 222.687 200.64 222.687 199.649C222.687 198.658 221.885 197.854 220.896 197.854C219.906 197.854 219.104 198.658 219.104 199.649C219.104 200.64 219.906 201.443 220.896 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 201.443C215.915 201.443 216.716 200.64 216.716 199.649C216.716 198.658 215.915 197.854 214.925 197.854C213.936 197.854 213.134 198.658 213.134 199.649C213.134 200.64 213.936 201.443 214.925 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 201.443C209.944 201.443 210.746 200.64 210.746 199.649C210.746 198.658 209.944 197.854 208.955 197.854C207.966 197.854 207.164 198.658 207.164 199.649C207.164 200.64 207.966 201.443 208.955 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 201.443C203.974 201.443 204.776 200.64 204.776 199.649C204.776 198.658 203.974 197.854 202.985 197.854C201.996 197.854 201.194 198.658 201.194 199.649C201.194 200.64 201.996 201.443 202.985 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 201.443C198.004 201.443 198.806 200.64 198.806 199.649C198.806 198.658 198.004 197.854 197.015 197.854C196.026 197.854 195.224 198.658 195.224 199.649C195.224 200.64 196.026 201.443 197.015 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 201.443C192.034 201.443 192.836 200.64 192.836 199.649C192.836 198.658 192.034 197.854 191.045 197.854C190.056 197.854 189.254 198.658 189.254 199.649C189.254 200.64 190.056 201.443 191.045 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 201.443C186.064 201.443 186.866 200.64 186.866 199.649C186.866 198.658 186.064 197.854 185.075 197.854C184.085 197.854 183.284 198.658 183.284 199.649C183.284 200.64 184.085 201.443 185.075 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 201.443C180.094 201.443 180.895 200.64 180.895 199.649C180.895 198.658 180.094 197.854 179.104 197.854C178.115 197.854 177.313 198.658 177.313 199.649C177.313 200.64 178.115 201.443 179.104 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 201.443C174.123 201.443 174.925 200.64 174.925 199.649C174.925 198.658 174.123 197.854 173.134 197.854C172.145 197.854 171.343 198.658 171.343 199.649C171.343 200.64 172.145 201.443 173.134 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 201.443C168.153 201.443 168.955 200.64 168.955 199.649C168.955 198.658 168.153 197.854 167.164 197.854C166.175 197.854 165.373 198.658 165.373 199.649C165.373 200.64 166.175 201.443 167.164 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 201.443C156.213 201.443 157.015 200.64 157.015 199.649C157.015 198.658 156.213 197.854 155.224 197.854C154.235 197.854 153.433 198.658 153.433 199.649C153.433 200.64 154.235 201.443 155.224 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 201.443C150.243 201.443 151.045 200.64 151.045 199.649C151.045 198.658 150.243 197.854 149.254 197.854C148.265 197.854 147.463 198.658 147.463 199.649C147.463 200.64 148.265 201.443 149.254 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 201.443C144.273 201.443 145.075 200.64 145.075 199.649C145.075 198.658 144.273 197.854 143.284 197.854C142.294 197.854 141.493 198.658 141.493 199.649C141.493 200.64 142.294 201.443 143.284 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 201.443C138.303 201.443 139.104 200.64 139.104 199.649C139.104 198.658 138.303 197.854 137.313 197.854C136.324 197.854 135.522 198.658 135.522 199.649C135.522 200.64 136.324 201.443 137.313 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 201.443C132.332 201.443 133.134 200.64 133.134 199.649C133.134 198.658 132.332 197.854 131.343 197.854C130.354 197.854 129.552 198.658 129.552 199.649C129.552 200.64 130.354 201.443 131.343 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 201.443C126.362 201.443 127.164 200.64 127.164 199.649C127.164 198.658 126.362 197.854 125.373 197.854C124.384 197.854 123.582 198.658 123.582 199.649C123.582 200.64 124.384 201.443 125.373 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 201.443C120.392 201.443 121.194 200.64 121.194 199.649C121.194 198.658 120.392 197.854 119.403 197.854C118.414 197.854 117.612 198.658 117.612 199.649C117.612 200.64 118.414 201.443 119.403 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 201.443C114.422 201.443 115.224 200.64 115.224 199.649C115.224 198.658 114.422 197.854 113.433 197.854C112.444 197.854 111.642 198.658 111.642 199.649C111.642 200.64 112.444 201.443 113.433 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 201.443C108.452 201.443 109.254 200.64 109.254 199.649C109.254 198.658 108.452 197.854 107.463 197.854C106.474 197.854 105.672 198.658 105.672 199.649C105.672 200.64 106.474 201.443 107.463 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 201.443C102.482 201.443 103.284 200.64 103.284 199.649C103.284 198.658 102.482 197.854 101.493 197.854C100.503 197.854 99.7015 198.658 99.7015 199.649C99.7015 200.64 100.503 201.443 101.493 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 201.443C96.5116 201.443 97.3134 200.64 97.3134 199.649C97.3134 198.658 96.5116 197.854 95.5224 197.854C94.5332 197.854 93.7314 198.658 93.7314 199.649C93.7314 200.64 94.5332 201.443 95.5224 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 195.461C245.765 195.461 246.567 194.657 246.567 193.666C246.567 192.675 245.765 191.872 244.776 191.872C243.787 191.872 242.985 192.675 242.985 193.666C242.985 194.657 243.787 195.461 244.776 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 195.461C239.795 195.461 240.597 194.657 240.597 193.666C240.597 192.675 239.795 191.872 238.806 191.872C237.817 191.872 237.015 192.675 237.015 193.666C237.015 194.657 237.817 195.461 238.806 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 195.461C233.825 195.461 234.627 194.657 234.627 193.666C234.627 192.675 233.825 191.872 232.836 191.872C231.847 191.872 231.045 192.675 231.045 193.666C231.045 194.657 231.847 195.461 232.836 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 195.461C227.855 195.461 228.657 194.657 228.657 193.666C228.657 192.675 227.855 191.872 226.866 191.872C225.877 191.872 225.075 192.675 225.075 193.666C225.075 194.657 225.877 195.461 226.866 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 195.461C215.915 195.461 216.716 194.657 216.716 193.666C216.716 192.675 215.915 191.872 214.925 191.872C213.936 191.872 213.134 192.675 213.134 193.666C213.134 194.657 213.936 195.461 214.925 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 195.461C209.944 195.461 210.746 194.657 210.746 193.666C210.746 192.675 209.944 191.872 208.955 191.872C207.966 191.872 207.164 192.675 207.164 193.666C207.164 194.657 207.966 195.461 208.955 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 195.461C203.974 195.461 204.776 194.657 204.776 193.666C204.776 192.675 203.974 191.872 202.985 191.872C201.996 191.872 201.194 192.675 201.194 193.666C201.194 194.657 201.996 195.461 202.985 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 195.461C198.004 195.461 198.806 194.657 198.806 193.666C198.806 192.675 198.004 191.872 197.015 191.872C196.026 191.872 195.224 192.675 195.224 193.666C195.224 194.657 196.026 195.461 197.015 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 195.461C192.034 195.461 192.836 194.657 192.836 193.666C192.836 192.675 192.034 191.872 191.045 191.872C190.056 191.872 189.254 192.675 189.254 193.666C189.254 194.657 190.056 195.461 191.045 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 195.461C186.064 195.461 186.866 194.657 186.866 193.666C186.866 192.675 186.064 191.872 185.075 191.872C184.085 191.872 183.284 192.675 183.284 193.666C183.284 194.657 184.085 195.461 185.075 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 195.461C180.094 195.461 180.895 194.657 180.895 193.666C180.895 192.675 180.094 191.872 179.104 191.872C178.115 191.872 177.313 192.675 177.313 193.666C177.313 194.657 178.115 195.461 179.104 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 195.461C174.123 195.461 174.925 194.657 174.925 193.666C174.925 192.675 174.123 191.872 173.134 191.872C172.145 191.872 171.343 192.675 171.343 193.666C171.343 194.657 172.145 195.461 173.134 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 195.461C168.153 195.461 168.955 194.657 168.955 193.666C168.955 192.675 168.153 191.872 167.164 191.872C166.175 191.872 165.373 192.675 165.373 193.666C165.373 194.657 166.175 195.461 167.164 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 195.461C162.183 195.461 162.985 194.657 162.985 193.666C162.985 192.675 162.183 191.872 161.194 191.872C160.205 191.872 159.403 192.675 159.403 193.666C159.403 194.657 160.205 195.461 161.194 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 195.461C156.213 195.461 157.015 194.657 157.015 193.666C157.015 192.675 156.213 191.872 155.224 191.872C154.235 191.872 153.433 192.675 153.433 193.666C153.433 194.657 154.235 195.461 155.224 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 195.461C150.243 195.461 151.045 194.657 151.045 193.666C151.045 192.675 150.243 191.872 149.254 191.872C148.265 191.872 147.463 192.675 147.463 193.666C147.463 194.657 148.265 195.461 149.254 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 195.461C144.273 195.461 145.075 194.657 145.075 193.666C145.075 192.675 144.273 191.872 143.284 191.872C142.294 191.872 141.493 192.675 141.493 193.666C141.493 194.657 142.294 195.461 143.284 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 195.461C138.303 195.461 139.104 194.657 139.104 193.666C139.104 192.675 138.303 191.872 137.313 191.872C136.324 191.872 135.522 192.675 135.522 193.666C135.522 194.657 136.324 195.461 137.313 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 195.461C132.332 195.461 133.134 194.657 133.134 193.666C133.134 192.675 132.332 191.872 131.343 191.872C130.354 191.872 129.552 192.675 129.552 193.666C129.552 194.657 130.354 195.461 131.343 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 195.461C126.362 195.461 127.164 194.657 127.164 193.666C127.164 192.675 126.362 191.872 125.373 191.872C124.384 191.872 123.582 192.675 123.582 193.666C123.582 194.657 124.384 195.461 125.373 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 195.461C120.392 195.461 121.194 194.657 121.194 193.666C121.194 192.675 120.392 191.872 119.403 191.872C118.414 191.872 117.612 192.675 117.612 193.666C117.612 194.657 118.414 195.461 119.403 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 195.461C114.422 195.461 115.224 194.657 115.224 193.666C115.224 192.675 114.422 191.872 113.433 191.872C112.444 191.872 111.642 192.675 111.642 193.666C111.642 194.657 112.444 195.461 113.433 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 195.461C108.452 195.461 109.254 194.657 109.254 193.666C109.254 192.675 108.452 191.872 107.463 191.872C106.474 191.872 105.672 192.675 105.672 193.666C105.672 194.657 106.474 195.461 107.463 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 195.461C102.482 195.461 103.284 194.657 103.284 193.666C103.284 192.675 102.482 191.872 101.493 191.872C100.503 191.872 99.7015 192.675 99.7015 193.666C99.7015 194.657 100.503 195.461 101.493 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 195.461C96.5116 195.461 97.3134 194.657 97.3134 193.666C97.3134 192.675 96.5116 191.872 95.5224 191.872C94.5332 191.872 93.7314 192.675 93.7314 193.666C93.7314 194.657 94.5332 195.461 95.5224 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 195.461C90.5414 195.461 91.3433 194.657 91.3433 193.666C91.3433 192.675 90.5414 191.872 89.5522 191.872C88.563 191.872 87.7612 192.675 87.7612 193.666C87.7612 194.657 88.563 195.461 89.5522 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 189.479C251.735 189.479 252.537 188.675 252.537 187.684C252.537 186.693 251.735 185.889 250.746 185.889C249.757 185.889 248.955 186.693 248.955 187.684C248.955 188.675 249.757 189.479 250.746 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 189.479C245.765 189.479 246.567 188.675 246.567 187.684C246.567 186.693 245.765 185.889 244.776 185.889C243.787 185.889 242.985 186.693 242.985 187.684C242.985 188.675 243.787 189.479 244.776 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 189.479C239.795 189.479 240.597 188.675 240.597 187.684C240.597 186.693 239.795 185.889 238.806 185.889C237.817 185.889 237.015 186.693 237.015 187.684C237.015 188.675 237.817 189.479 238.806 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 189.479C233.825 189.479 234.627 188.675 234.627 187.684C234.627 186.693 233.825 185.889 232.836 185.889C231.847 185.889 231.045 186.693 231.045 187.684C231.045 188.675 231.847 189.479 232.836 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 189.479C227.855 189.479 228.657 188.675 228.657 187.684C228.657 186.693 227.855 185.889 226.866 185.889C225.877 185.889 225.075 186.693 225.075 187.684C225.075 188.675 225.877 189.479 226.866 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 189.479C221.885 189.479 222.687 188.675 222.687 187.684C222.687 186.693 221.885 185.889 220.896 185.889C219.906 185.889 219.104 186.693 219.104 187.684C219.104 188.675 219.906 189.479 220.896 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 189.479C215.915 189.479 216.716 188.675 216.716 187.684C216.716 186.693 215.915 185.889 214.925 185.889C213.936 185.889 213.134 186.693 213.134 187.684C213.134 188.675 213.936 189.479 214.925 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 189.479C209.944 189.479 210.746 188.675 210.746 187.684C210.746 186.693 209.944 185.889 208.955 185.889C207.966 185.889 207.164 186.693 207.164 187.684C207.164 188.675 207.966 189.479 208.955 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 189.479C203.974 189.479 204.776 188.675 204.776 187.684C204.776 186.693 203.974 185.889 202.985 185.889C201.996 185.889 201.194 186.693 201.194 187.684C201.194 188.675 201.996 189.479 202.985 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 189.479C198.004 189.479 198.806 188.675 198.806 187.684C198.806 186.693 198.004 185.889 197.015 185.889C196.026 185.889 195.224 186.693 195.224 187.684C195.224 188.675 196.026 189.479 197.015 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 189.479C192.034 189.479 192.836 188.675 192.836 187.684C192.836 186.693 192.034 185.889 191.045 185.889C190.056 185.889 189.254 186.693 189.254 187.684C189.254 188.675 190.056 189.479 191.045 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 189.479C186.064 189.479 186.866 188.675 186.866 187.684C186.866 186.693 186.064 185.889 185.075 185.889C184.085 185.889 183.284 186.693 183.284 187.684C183.284 188.675 184.085 189.479 185.075 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 189.479C180.094 189.479 180.895 188.675 180.895 187.684C180.895 186.693 180.094 185.889 179.104 185.889C178.115 185.889 177.313 186.693 177.313 187.684C177.313 188.675 178.115 189.479 179.104 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 189.479C174.123 189.479 174.925 188.675 174.925 187.684C174.925 186.693 174.123 185.889 173.134 185.889C172.145 185.889 171.343 186.693 171.343 187.684C171.343 188.675 172.145 189.479 173.134 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 189.479C168.153 189.479 168.955 188.675 168.955 187.684C168.955 186.693 168.153 185.889 167.164 185.889C166.175 185.889 165.373 186.693 165.373 187.684C165.373 188.675 166.175 189.479 167.164 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 189.479C162.183 189.479 162.985 188.675 162.985 187.684C162.985 186.693 162.183 185.889 161.194 185.889C160.205 185.889 159.403 186.693 159.403 187.684C159.403 188.675 160.205 189.479 161.194 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 189.479C156.213 189.479 157.015 188.675 157.015 187.684C157.015 186.693 156.213 185.889 155.224 185.889C154.235 185.889 153.433 186.693 153.433 187.684C153.433 188.675 154.235 189.479 155.224 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 189.479C150.243 189.479 151.045 188.675 151.045 187.684C151.045 186.693 150.243 185.889 149.254 185.889C148.265 185.889 147.463 186.693 147.463 187.684C147.463 188.675 148.265 189.479 149.254 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 189.479C144.273 189.479 145.075 188.675 145.075 187.684C145.075 186.693 144.273 185.889 143.284 185.889C142.294 185.889 141.493 186.693 141.493 187.684C141.493 188.675 142.294 189.479 143.284 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 189.479C138.303 189.479 139.104 188.675 139.104 187.684C139.104 186.693 138.303 185.889 137.313 185.889C136.324 185.889 135.522 186.693 135.522 187.684C135.522 188.675 136.324 189.479 137.313 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 189.479C132.332 189.479 133.134 188.675 133.134 187.684C133.134 186.693 132.332 185.889 131.343 185.889C130.354 185.889 129.552 186.693 129.552 187.684C129.552 188.675 130.354 189.479 131.343 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 189.479C126.362 189.479 127.164 188.675 127.164 187.684C127.164 186.693 126.362 185.889 125.373 185.889C124.384 185.889 123.582 186.693 123.582 187.684C123.582 188.675 124.384 189.479 125.373 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 189.479C120.392 189.479 121.194 188.675 121.194 187.684C121.194 186.693 120.392 185.889 119.403 185.889C118.414 185.889 117.612 186.693 117.612 187.684C117.612 188.675 118.414 189.479 119.403 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 189.479C114.422 189.479 115.224 188.675 115.224 187.684C115.224 186.693 114.422 185.889 113.433 185.889C112.444 185.889 111.642 186.693 111.642 187.684C111.642 188.675 112.444 189.479 113.433 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 189.479C108.452 189.479 109.254 188.675 109.254 187.684C109.254 186.693 108.452 185.889 107.463 185.889C106.474 185.889 105.672 186.693 105.672 187.684C105.672 188.675 106.474 189.479 107.463 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 189.479C102.482 189.479 103.284 188.675 103.284 187.684C103.284 186.693 102.482 185.889 101.493 185.889C100.503 185.889 99.7015 186.693 99.7015 187.684C99.7015 188.675 100.503 189.479 101.493 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 189.479C96.5116 189.479 97.3134 188.675 97.3134 187.684C97.3134 186.693 96.5116 185.889 95.5224 185.889C94.5332 185.889 93.7314 186.693 93.7314 187.684C93.7314 188.675 94.5332 189.479 95.5224 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 189.479C90.5414 189.479 91.3433 188.675 91.3433 187.684C91.3433 186.693 90.5414 185.889 89.5522 185.889C88.563 185.889 87.7612 186.693 87.7612 187.684C87.7612 188.675 88.563 189.479 89.5522 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 189.479C84.5713 189.479 85.3732 188.675 85.3732 187.684C85.3732 186.693 84.5713 185.889 83.5821 185.889C82.593 185.889 81.7911 186.693 81.7911 187.684C81.7911 188.675 82.593 189.479 83.5821 189.479Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 183.497C251.735 183.497 252.537 182.693 252.537 181.702C252.537 180.711 251.735 179.907 250.746 179.907C249.757 179.907 248.955 180.711 248.955 181.702C248.955 182.693 249.757 183.497 250.746 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 183.497C245.765 183.497 246.567 182.693 246.567 181.702C246.567 180.711 245.765 179.907 244.776 179.907C243.787 179.907 242.985 180.711 242.985 181.702C242.985 182.693 243.787 183.497 244.776 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 183.497C239.795 183.497 240.597 182.693 240.597 181.702C240.597 180.711 239.795 179.907 238.806 179.907C237.817 179.907 237.015 180.711 237.015 181.702C237.015 182.693 237.817 183.497 238.806 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 183.497C233.825 183.497 234.627 182.693 234.627 181.702C234.627 180.711 233.825 179.907 232.836 179.907C231.847 179.907 231.045 180.711 231.045 181.702C231.045 182.693 231.847 183.497 232.836 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 183.497C227.855 183.497 228.657 182.693 228.657 181.702C228.657 180.711 227.855 179.907 226.866 179.907C225.877 179.907 225.075 180.711 225.075 181.702C225.075 182.693 225.877 183.497 226.866 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 183.497C221.885 183.497 222.687 182.693 222.687 181.702C222.687 180.711 221.885 179.907 220.896 179.907C219.906 179.907 219.104 180.711 219.104 181.702C219.104 182.693 219.906 183.497 220.896 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 183.497C215.915 183.497 216.716 182.693 216.716 181.702C216.716 180.711 215.915 179.907 214.925 179.907C213.936 179.907 213.134 180.711 213.134 181.702C213.134 182.693 213.936 183.497 214.925 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 183.497C209.944 183.497 210.746 182.693 210.746 181.702C210.746 180.711 209.944 179.907 208.955 179.907C207.966 179.907 207.164 180.711 207.164 181.702C207.164 182.693 207.966 183.497 208.955 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 183.497C203.974 183.497 204.776 182.693 204.776 181.702C204.776 180.711 203.974 179.907 202.985 179.907C201.996 179.907 201.194 180.711 201.194 181.702C201.194 182.693 201.996 183.497 202.985 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 183.497C198.004 183.497 198.806 182.693 198.806 181.702C198.806 180.711 198.004 179.907 197.015 179.907C196.026 179.907 195.224 180.711 195.224 181.702C195.224 182.693 196.026 183.497 197.015 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 183.497C192.034 183.497 192.836 182.693 192.836 181.702C192.836 180.711 192.034 179.907 191.045 179.907C190.056 179.907 189.254 180.711 189.254 181.702C189.254 182.693 190.056 183.497 191.045 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 183.497C186.064 183.497 186.866 182.693 186.866 181.702C186.866 180.711 186.064 179.907 185.075 179.907C184.085 179.907 183.284 180.711 183.284 181.702C183.284 182.693 184.085 183.497 185.075 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 183.497C180.094 183.497 180.895 182.693 180.895 181.702C180.895 180.711 180.094 179.907 179.104 179.907C178.115 179.907 177.313 180.711 177.313 181.702C177.313 182.693 178.115 183.497 179.104 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 183.497C174.123 183.497 174.925 182.693 174.925 181.702C174.925 180.711 174.123 179.907 173.134 179.907C172.145 179.907 171.343 180.711 171.343 181.702C171.343 182.693 172.145 183.497 173.134 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 183.497C168.153 183.497 168.955 182.693 168.955 181.702C168.955 180.711 168.153 179.907 167.164 179.907C166.175 179.907 165.373 180.711 165.373 181.702C165.373 182.693 166.175 183.497 167.164 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 183.497C162.183 183.497 162.985 182.693 162.985 181.702C162.985 180.711 162.183 179.907 161.194 179.907C160.205 179.907 159.403 180.711 159.403 181.702C159.403 182.693 160.205 183.497 161.194 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 183.497C156.213 183.497 157.015 182.693 157.015 181.702C157.015 180.711 156.213 179.907 155.224 179.907C154.235 179.907 153.433 180.711 153.433 181.702C153.433 182.693 154.235 183.497 155.224 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 183.497C150.243 183.497 151.045 182.693 151.045 181.702C151.045 180.711 150.243 179.907 149.254 179.907C148.265 179.907 147.463 180.711 147.463 181.702C147.463 182.693 148.265 183.497 149.254 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 183.497C144.273 183.497 145.075 182.693 145.075 181.702C145.075 180.711 144.273 179.907 143.284 179.907C142.294 179.907 141.493 180.711 141.493 181.702C141.493 182.693 142.294 183.497 143.284 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 183.497C138.303 183.497 139.104 182.693 139.104 181.702C139.104 180.711 138.303 179.907 137.313 179.907C136.324 179.907 135.522 180.711 135.522 181.702C135.522 182.693 136.324 183.497 137.313 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 183.497C132.332 183.497 133.134 182.693 133.134 181.702C133.134 180.711 132.332 179.907 131.343 179.907C130.354 179.907 129.552 180.711 129.552 181.702C129.552 182.693 130.354 183.497 131.343 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 183.497C126.362 183.497 127.164 182.693 127.164 181.702C127.164 180.711 126.362 179.907 125.373 179.907C124.384 179.907 123.582 180.711 123.582 181.702C123.582 182.693 124.384 183.497 125.373 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 183.497C120.392 183.497 121.194 182.693 121.194 181.702C121.194 180.711 120.392 179.907 119.403 179.907C118.414 179.907 117.612 180.711 117.612 181.702C117.612 182.693 118.414 183.497 119.403 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 183.497C114.422 183.497 115.224 182.693 115.224 181.702C115.224 180.711 114.422 179.907 113.433 179.907C112.444 179.907 111.642 180.711 111.642 181.702C111.642 182.693 112.444 183.497 113.433 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 183.497C108.452 183.497 109.254 182.693 109.254 181.702C109.254 180.711 108.452 179.907 107.463 179.907C106.474 179.907 105.672 180.711 105.672 181.702C105.672 182.693 106.474 183.497 107.463 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 183.497C102.482 183.497 103.284 182.693 103.284 181.702C103.284 180.711 102.482 179.907 101.493 179.907C100.503 179.907 99.7015 180.711 99.7015 181.702C99.7015 182.693 100.503 183.497 101.493 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 183.497C96.5116 183.497 97.3134 182.693 97.3134 181.702C97.3134 180.711 96.5116 179.907 95.5224 179.907C94.5332 179.907 93.7314 180.711 93.7314 181.702C93.7314 182.693 94.5332 183.497 95.5224 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 183.497C90.5414 183.497 91.3433 182.693 91.3433 181.702C91.3433 180.711 90.5414 179.907 89.5522 179.907C88.563 179.907 87.7612 180.711 87.7612 181.702C87.7612 182.693 88.563 183.497 89.5522 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 183.497C84.5713 183.497 85.3732 182.693 85.3732 181.702C85.3732 180.711 84.5713 179.907 83.5821 179.907C82.593 179.907 81.7911 180.711 81.7911 181.702C81.7911 182.693 82.593 183.497 83.5821 183.497Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 177.514C257.706 177.514 258.507 176.711 258.507 175.719C258.507 174.728 257.706 173.925 256.716 173.925C255.727 173.925 254.925 174.728 254.925 175.719C254.925 176.711 255.727 177.514 256.716 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 177.514C251.735 177.514 252.537 176.711 252.537 175.719C252.537 174.728 251.735 173.925 250.746 173.925C249.757 173.925 248.955 174.728 248.955 175.719C248.955 176.711 249.757 177.514 250.746 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 177.514C239.795 177.514 240.597 176.711 240.597 175.719C240.597 174.728 239.795 173.925 238.806 173.925C237.817 173.925 237.015 174.728 237.015 175.719C237.015 176.711 237.817 177.514 238.806 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 177.514C233.825 177.514 234.627 176.711 234.627 175.719C234.627 174.728 233.825 173.925 232.836 173.925C231.847 173.925 231.045 174.728 231.045 175.719C231.045 176.711 231.847 177.514 232.836 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 177.514C227.855 177.514 228.657 176.711 228.657 175.719C228.657 174.728 227.855 173.925 226.866 173.925C225.877 173.925 225.075 174.728 225.075 175.719C225.075 176.711 225.877 177.514 226.866 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 177.514C221.885 177.514 222.687 176.711 222.687 175.719C222.687 174.728 221.885 173.925 220.896 173.925C219.906 173.925 219.104 174.728 219.104 175.719C219.104 176.711 219.906 177.514 220.896 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 177.514C215.915 177.514 216.716 176.711 216.716 175.719C216.716 174.728 215.915 173.925 214.925 173.925C213.936 173.925 213.134 174.728 213.134 175.719C213.134 176.711 213.936 177.514 214.925 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 177.514C209.944 177.514 210.746 176.711 210.746 175.719C210.746 174.728 209.944 173.925 208.955 173.925C207.966 173.925 207.164 174.728 207.164 175.719C207.164 176.711 207.966 177.514 208.955 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 177.514C203.974 177.514 204.776 176.711 204.776 175.719C204.776 174.728 203.974 173.925 202.985 173.925C201.996 173.925 201.194 174.728 201.194 175.719C201.194 176.711 201.996 177.514 202.985 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 177.514C198.004 177.514 198.806 176.711 198.806 175.719C198.806 174.728 198.004 173.925 197.015 173.925C196.026 173.925 195.224 174.728 195.224 175.719C195.224 176.711 196.026 177.514 197.015 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 177.514C192.034 177.514 192.836 176.711 192.836 175.719C192.836 174.728 192.034 173.925 191.045 173.925C190.056 173.925 189.254 174.728 189.254 175.719C189.254 176.711 190.056 177.514 191.045 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 177.514C186.064 177.514 186.866 176.711 186.866 175.719C186.866 174.728 186.064 173.925 185.075 173.925C184.085 173.925 183.284 174.728 183.284 175.719C183.284 176.711 184.085 177.514 185.075 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 177.514C180.094 177.514 180.895 176.711 180.895 175.719C180.895 174.728 180.094 173.925 179.104 173.925C178.115 173.925 177.313 174.728 177.313 175.719C177.313 176.711 178.115 177.514 179.104 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 177.514C174.123 177.514 174.925 176.711 174.925 175.719C174.925 174.728 174.123 173.925 173.134 173.925C172.145 173.925 171.343 174.728 171.343 175.719C171.343 176.711 172.145 177.514 173.134 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 177.514C168.153 177.514 168.955 176.711 168.955 175.719C168.955 174.728 168.153 173.925 167.164 173.925C166.175 173.925 165.373 174.728 165.373 175.719C165.373 176.711 166.175 177.514 167.164 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 177.514C162.183 177.514 162.985 176.711 162.985 175.719C162.985 174.728 162.183 173.925 161.194 173.925C160.205 173.925 159.403 174.728 159.403 175.719C159.403 176.711 160.205 177.514 161.194 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 177.514C156.213 177.514 157.015 176.711 157.015 175.719C157.015 174.728 156.213 173.925 155.224 173.925C154.235 173.925 153.433 174.728 153.433 175.719C153.433 176.711 154.235 177.514 155.224 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 177.514C150.243 177.514 151.045 176.711 151.045 175.719C151.045 174.728 150.243 173.925 149.254 173.925C148.265 173.925 147.463 174.728 147.463 175.719C147.463 176.711 148.265 177.514 149.254 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 177.514C144.273 177.514 145.075 176.711 145.075 175.719C145.075 174.728 144.273 173.925 143.284 173.925C142.294 173.925 141.493 174.728 141.493 175.719C141.493 176.711 142.294 177.514 143.284 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 177.514C138.303 177.514 139.104 176.711 139.104 175.719C139.104 174.728 138.303 173.925 137.313 173.925C136.324 173.925 135.522 174.728 135.522 175.719C135.522 176.711 136.324 177.514 137.313 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 177.514C132.332 177.514 133.134 176.711 133.134 175.719C133.134 174.728 132.332 173.925 131.343 173.925C130.354 173.925 129.552 174.728 129.552 175.719C129.552 176.711 130.354 177.514 131.343 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 177.514C126.362 177.514 127.164 176.711 127.164 175.719C127.164 174.728 126.362 173.925 125.373 173.925C124.384 173.925 123.582 174.728 123.582 175.719C123.582 176.711 124.384 177.514 125.373 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 177.514C120.392 177.514 121.194 176.711 121.194 175.719C121.194 174.728 120.392 173.925 119.403 173.925C118.414 173.925 117.612 174.728 117.612 175.719C117.612 176.711 118.414 177.514 119.403 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 177.514C114.422 177.514 115.224 176.711 115.224 175.719C115.224 174.728 114.422 173.925 113.433 173.925C112.444 173.925 111.642 174.728 111.642 175.719C111.642 176.711 112.444 177.514 113.433 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 177.514C108.452 177.514 109.254 176.711 109.254 175.719C109.254 174.728 108.452 173.925 107.463 173.925C106.474 173.925 105.672 174.728 105.672 175.719C105.672 176.711 106.474 177.514 107.463 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 177.514C102.482 177.514 103.284 176.711 103.284 175.719C103.284 174.728 102.482 173.925 101.493 173.925C100.503 173.925 99.7015 174.728 99.7015 175.719C99.7015 176.711 100.503 177.514 101.493 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 177.514C96.5116 177.514 97.3134 176.711 97.3134 175.719C97.3134 174.728 96.5116 173.925 95.5224 173.925C94.5332 173.925 93.7314 174.728 93.7314 175.719C93.7314 176.711 94.5332 177.514 95.5224 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 177.514C90.5414 177.514 91.3433 176.711 91.3433 175.719C91.3433 174.728 90.5414 173.925 89.5522 173.925C88.563 173.925 87.7612 174.728 87.7612 175.719C87.7612 176.711 88.563 177.514 89.5522 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 171.532C251.735 171.532 252.537 170.728 252.537 169.737C252.537 168.746 251.735 167.943 250.746 167.943C249.757 167.943 248.955 168.746 248.955 169.737C248.955 170.728 249.757 171.532 250.746 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 171.532C245.765 171.532 246.567 170.728 246.567 169.737C246.567 168.746 245.765 167.943 244.776 167.943C243.787 167.943 242.985 168.746 242.985 169.737C242.985 170.728 243.787 171.532 244.776 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 171.532C239.795 171.532 240.597 170.728 240.597 169.737C240.597 168.746 239.795 167.943 238.806 167.943C237.817 167.943 237.015 168.746 237.015 169.737C237.015 170.728 237.817 171.532 238.806 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 171.532C233.825 171.532 234.627 170.728 234.627 169.737C234.627 168.746 233.825 167.943 232.836 167.943C231.847 167.943 231.045 168.746 231.045 169.737C231.045 170.728 231.847 171.532 232.836 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 171.532C227.855 171.532 228.657 170.728 228.657 169.737C228.657 168.746 227.855 167.943 226.866 167.943C225.877 167.943 225.075 168.746 225.075 169.737C225.075 170.728 225.877 171.532 226.866 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 171.532C221.885 171.532 222.687 170.728 222.687 169.737C222.687 168.746 221.885 167.943 220.896 167.943C219.906 167.943 219.104 168.746 219.104 169.737C219.104 170.728 219.906 171.532 220.896 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 171.532C215.915 171.532 216.716 170.728 216.716 169.737C216.716 168.746 215.915 167.943 214.925 167.943C213.936 167.943 213.134 168.746 213.134 169.737C213.134 170.728 213.936 171.532 214.925 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 171.532C209.944 171.532 210.746 170.728 210.746 169.737C210.746 168.746 209.944 167.943 208.955 167.943C207.966 167.943 207.164 168.746 207.164 169.737C207.164 170.728 207.966 171.532 208.955 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 171.532C203.974 171.532 204.776 170.728 204.776 169.737C204.776 168.746 203.974 167.943 202.985 167.943C201.996 167.943 201.194 168.746 201.194 169.737C201.194 170.728 201.996 171.532 202.985 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 171.532C198.004 171.532 198.806 170.728 198.806 169.737C198.806 168.746 198.004 167.943 197.015 167.943C196.026 167.943 195.224 168.746 195.224 169.737C195.224 170.728 196.026 171.532 197.015 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 171.532C192.034 171.532 192.836 170.728 192.836 169.737C192.836 168.746 192.034 167.943 191.045 167.943C190.056 167.943 189.254 168.746 189.254 169.737C189.254 170.728 190.056 171.532 191.045 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 171.532C186.064 171.532 186.866 170.728 186.866 169.737C186.866 168.746 186.064 167.943 185.075 167.943C184.085 167.943 183.284 168.746 183.284 169.737C183.284 170.728 184.085 171.532 185.075 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 171.532C180.094 171.532 180.895 170.728 180.895 169.737C180.895 168.746 180.094 167.943 179.104 167.943C178.115 167.943 177.313 168.746 177.313 169.737C177.313 170.728 178.115 171.532 179.104 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 171.532C174.123 171.532 174.925 170.728 174.925 169.737C174.925 168.746 174.123 167.943 173.134 167.943C172.145 167.943 171.343 168.746 171.343 169.737C171.343 170.728 172.145 171.532 173.134 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 171.532C168.153 171.532 168.955 170.728 168.955 169.737C168.955 168.746 168.153 167.943 167.164 167.943C166.175 167.943 165.373 168.746 165.373 169.737C165.373 170.728 166.175 171.532 167.164 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 171.532C162.183 171.532 162.985 170.728 162.985 169.737C162.985 168.746 162.183 167.943 161.194 167.943C160.205 167.943 159.403 168.746 159.403 169.737C159.403 170.728 160.205 171.532 161.194 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 171.532C156.213 171.532 157.015 170.728 157.015 169.737C157.015 168.746 156.213 167.943 155.224 167.943C154.235 167.943 153.433 168.746 153.433 169.737C153.433 170.728 154.235 171.532 155.224 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 171.532C150.243 171.532 151.045 170.728 151.045 169.737C151.045 168.746 150.243 167.943 149.254 167.943C148.265 167.943 147.463 168.746 147.463 169.737C147.463 170.728 148.265 171.532 149.254 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 171.532C144.273 171.532 145.075 170.728 145.075 169.737C145.075 168.746 144.273 167.943 143.284 167.943C142.294 167.943 141.493 168.746 141.493 169.737C141.493 170.728 142.294 171.532 143.284 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 171.532C138.303 171.532 139.104 170.728 139.104 169.737C139.104 168.746 138.303 167.943 137.313 167.943C136.324 167.943 135.522 168.746 135.522 169.737C135.522 170.728 136.324 171.532 137.313 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 171.532C132.332 171.532 133.134 170.728 133.134 169.737C133.134 168.746 132.332 167.943 131.343 167.943C130.354 167.943 129.552 168.746 129.552 169.737C129.552 170.728 130.354 171.532 131.343 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 171.532C126.362 171.532 127.164 170.728 127.164 169.737C127.164 168.746 126.362 167.943 125.373 167.943C124.384 167.943 123.582 168.746 123.582 169.737C123.582 170.728 124.384 171.532 125.373 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 171.532C120.392 171.532 121.194 170.728 121.194 169.737C121.194 168.746 120.392 167.943 119.403 167.943C118.414 167.943 117.612 168.746 117.612 169.737C117.612 170.728 118.414 171.532 119.403 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 171.532C114.422 171.532 115.224 170.728 115.224 169.737C115.224 168.746 114.422 167.943 113.433 167.943C112.444 167.943 111.642 168.746 111.642 169.737C111.642 170.728 112.444 171.532 113.433 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 171.532C108.452 171.532 109.254 170.728 109.254 169.737C109.254 168.746 108.452 167.943 107.463 167.943C106.474 167.943 105.672 168.746 105.672 169.737C105.672 170.728 106.474 171.532 107.463 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 171.532C102.482 171.532 103.284 170.728 103.284 169.737C103.284 168.746 102.482 167.943 101.493 167.943C100.503 167.943 99.7015 168.746 99.7015 169.737C99.7015 170.728 100.503 171.532 101.493 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 171.532C96.5116 171.532 97.3134 170.728 97.3134 169.737C97.3134 168.746 96.5116 167.943 95.5224 167.943C94.5332 167.943 93.7314 168.746 93.7314 169.737C93.7314 170.728 94.5332 171.532 95.5224 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 171.532C90.5414 171.532 91.3433 170.728 91.3433 169.737C91.3433 168.746 90.5414 167.943 89.5522 167.943C88.563 167.943 87.7612 168.746 87.7612 169.737C87.7612 170.728 88.563 171.532 89.5522 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 171.532C84.5713 171.532 85.3732 170.728 85.3732 169.737C85.3732 168.746 84.5713 167.943 83.5821 167.943C82.593 167.943 81.7911 168.746 81.7911 169.737C81.7911 170.728 82.593 171.532 83.5821 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 165.55C275.616 165.55 276.418 164.746 276.418 163.755C276.418 162.764 275.616 161.96 274.627 161.96C273.638 161.96 272.836 162.764 272.836 163.755C272.836 164.746 273.638 165.55 274.627 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 165.55C269.646 165.55 270.448 164.746 270.448 163.755C270.448 162.764 269.646 161.96 268.657 161.96C267.668 161.96 266.866 162.764 266.866 163.755C266.866 164.746 267.668 165.55 268.657 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 165.55C263.676 165.55 264.478 164.746 264.478 163.755C264.478 162.764 263.676 161.96 262.687 161.96C261.697 161.96 260.896 162.764 260.896 163.755C260.896 164.746 261.697 165.55 262.687 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 165.55C257.706 165.55 258.507 164.746 258.507 163.755C258.507 162.764 257.706 161.96 256.716 161.96C255.727 161.96 254.925 162.764 254.925 163.755C254.925 164.746 255.727 165.55 256.716 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 165.55C251.735 165.55 252.537 164.746 252.537 163.755C252.537 162.764 251.735 161.96 250.746 161.96C249.757 161.96 248.955 162.764 248.955 163.755C248.955 164.746 249.757 165.55 250.746 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 165.55C245.765 165.55 246.567 164.746 246.567 163.755C246.567 162.764 245.765 161.96 244.776 161.96C243.787 161.96 242.985 162.764 242.985 163.755C242.985 164.746 243.787 165.55 244.776 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 165.55C233.825 165.55 234.627 164.746 234.627 163.755C234.627 162.764 233.825 161.96 232.836 161.96C231.847 161.96 231.045 162.764 231.045 163.755C231.045 164.746 231.847 165.55 232.836 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 165.55C227.855 165.55 228.657 164.746 228.657 163.755C228.657 162.764 227.855 161.96 226.866 161.96C225.877 161.96 225.075 162.764 225.075 163.755C225.075 164.746 225.877 165.55 226.866 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 165.55C221.885 165.55 222.687 164.746 222.687 163.755C222.687 162.764 221.885 161.96 220.896 161.96C219.906 161.96 219.104 162.764 219.104 163.755C219.104 164.746 219.906 165.55 220.896 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 165.55C209.944 165.55 210.746 164.746 210.746 163.755C210.746 162.764 209.944 161.96 208.955 161.96C207.966 161.96 207.164 162.764 207.164 163.755C207.164 164.746 207.966 165.55 208.955 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 165.55C203.974 165.55 204.776 164.746 204.776 163.755C204.776 162.764 203.974 161.96 202.985 161.96C201.996 161.96 201.194 162.764 201.194 163.755C201.194 164.746 201.996 165.55 202.985 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 165.55C198.004 165.55 198.806 164.746 198.806 163.755C198.806 162.764 198.004 161.96 197.015 161.96C196.026 161.96 195.224 162.764 195.224 163.755C195.224 164.746 196.026 165.55 197.015 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 165.55C192.034 165.55 192.836 164.746 192.836 163.755C192.836 162.764 192.034 161.96 191.045 161.96C190.056 161.96 189.254 162.764 189.254 163.755C189.254 164.746 190.056 165.55 191.045 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 165.55C186.064 165.55 186.866 164.746 186.866 163.755C186.866 162.764 186.064 161.96 185.075 161.96C184.085 161.96 183.284 162.764 183.284 163.755C183.284 164.746 184.085 165.55 185.075 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 165.55C180.094 165.55 180.895 164.746 180.895 163.755C180.895 162.764 180.094 161.96 179.104 161.96C178.115 161.96 177.313 162.764 177.313 163.755C177.313 164.746 178.115 165.55 179.104 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 165.55C174.123 165.55 174.925 164.746 174.925 163.755C174.925 162.764 174.123 161.96 173.134 161.96C172.145 161.96 171.343 162.764 171.343 163.755C171.343 164.746 172.145 165.55 173.134 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 165.55C168.153 165.55 168.955 164.746 168.955 163.755C168.955 162.764 168.153 161.96 167.164 161.96C166.175 161.96 165.373 162.764 165.373 163.755C165.373 164.746 166.175 165.55 167.164 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 165.55C162.183 165.55 162.985 164.746 162.985 163.755C162.985 162.764 162.183 161.96 161.194 161.96C160.205 161.96 159.403 162.764 159.403 163.755C159.403 164.746 160.205 165.55 161.194 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 165.55C156.213 165.55 157.015 164.746 157.015 163.755C157.015 162.764 156.213 161.96 155.224 161.96C154.235 161.96 153.433 162.764 153.433 163.755C153.433 164.746 154.235 165.55 155.224 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 165.55C150.243 165.55 151.045 164.746 151.045 163.755C151.045 162.764 150.243 161.96 149.254 161.96C148.265 161.96 147.463 162.764 147.463 163.755C147.463 164.746 148.265 165.55 149.254 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 165.55C144.273 165.55 145.075 164.746 145.075 163.755C145.075 162.764 144.273 161.96 143.284 161.96C142.294 161.96 141.493 162.764 141.493 163.755C141.493 164.746 142.294 165.55 143.284 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 165.55C132.332 165.55 133.134 164.746 133.134 163.755C133.134 162.764 132.332 161.96 131.343 161.96C130.354 161.96 129.552 162.764 129.552 163.755C129.552 164.746 130.354 165.55 131.343 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 165.55C126.362 165.55 127.164 164.746 127.164 163.755C127.164 162.764 126.362 161.96 125.373 161.96C124.384 161.96 123.582 162.764 123.582 163.755C123.582 164.746 124.384 165.55 125.373 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 165.55C120.392 165.55 121.194 164.746 121.194 163.755C121.194 162.764 120.392 161.96 119.403 161.96C118.414 161.96 117.612 162.764 117.612 163.755C117.612 164.746 118.414 165.55 119.403 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 165.55C114.422 165.55 115.224 164.746 115.224 163.755C115.224 162.764 114.422 161.96 113.433 161.96C112.444 161.96 111.642 162.764 111.642 163.755C111.642 164.746 112.444 165.55 113.433 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 165.55C108.452 165.55 109.254 164.746 109.254 163.755C109.254 162.764 108.452 161.96 107.463 161.96C106.474 161.96 105.672 162.764 105.672 163.755C105.672 164.746 106.474 165.55 107.463 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 165.55C102.482 165.55 103.284 164.746 103.284 163.755C103.284 162.764 102.482 161.96 101.493 161.96C100.503 161.96 99.7015 162.764 99.7015 163.755C99.7015 164.746 100.503 165.55 101.493 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 165.55C96.5116 165.55 97.3134 164.746 97.3134 163.755C97.3134 162.764 96.5116 161.96 95.5224 161.96C94.5332 161.96 93.7314 162.764 93.7314 163.755C93.7314 164.746 94.5332 165.55 95.5224 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 165.55C90.5414 165.55 91.3433 164.746 91.3433 163.755C91.3433 162.764 90.5414 161.96 89.5522 161.96C88.563 161.96 87.7612 162.764 87.7612 163.755C87.7612 164.746 88.563 165.55 89.5522 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 159.567C275.616 159.567 276.418 158.764 276.418 157.773C276.418 156.782 275.616 155.978 274.627 155.978C273.638 155.978 272.836 156.782 272.836 157.773C272.836 158.764 273.638 159.567 274.627 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 159.567C269.646 159.567 270.448 158.764 270.448 157.773C270.448 156.782 269.646 155.978 268.657 155.978C267.668 155.978 266.866 156.782 266.866 157.773C266.866 158.764 267.668 159.567 268.657 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 159.567C263.676 159.567 264.478 158.764 264.478 157.773C264.478 156.782 263.676 155.978 262.687 155.978C261.697 155.978 260.896 156.782 260.896 157.773C260.896 158.764 261.697 159.567 262.687 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 159.567C257.706 159.567 258.507 158.764 258.507 157.773C258.507 156.782 257.706 155.978 256.716 155.978C255.727 155.978 254.925 156.782 254.925 157.773C254.925 158.764 255.727 159.567 256.716 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 159.567C251.735 159.567 252.537 158.764 252.537 157.773C252.537 156.782 251.735 155.978 250.746 155.978C249.757 155.978 248.955 156.782 248.955 157.773C248.955 158.764 249.757 159.567 250.746 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 159.567C245.765 159.567 246.567 158.764 246.567 157.773C246.567 156.782 245.765 155.978 244.776 155.978C243.787 155.978 242.985 156.782 242.985 157.773C242.985 158.764 243.787 159.567 244.776 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 159.567C239.795 159.567 240.597 158.764 240.597 157.773C240.597 156.782 239.795 155.978 238.806 155.978C237.817 155.978 237.015 156.782 237.015 157.773C237.015 158.764 237.817 159.567 238.806 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 159.567C233.825 159.567 234.627 158.764 234.627 157.773C234.627 156.782 233.825 155.978 232.836 155.978C231.847 155.978 231.045 156.782 231.045 157.773C231.045 158.764 231.847 159.567 232.836 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 159.567C227.855 159.567 228.657 158.764 228.657 157.773C228.657 156.782 227.855 155.978 226.866 155.978C225.877 155.978 225.075 156.782 225.075 157.773C225.075 158.764 225.877 159.567 226.866 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 159.567C215.915 159.567 216.716 158.764 216.716 157.773C216.716 156.782 215.915 155.978 214.925 155.978C213.936 155.978 213.134 156.782 213.134 157.773C213.134 158.764 213.936 159.567 214.925 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 159.567C209.944 159.567 210.746 158.764 210.746 157.773C210.746 156.782 209.944 155.978 208.955 155.978C207.966 155.978 207.164 156.782 207.164 157.773C207.164 158.764 207.966 159.567 208.955 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 159.567C203.974 159.567 204.776 158.764 204.776 157.773C204.776 156.782 203.974 155.978 202.985 155.978C201.996 155.978 201.194 156.782 201.194 157.773C201.194 158.764 201.996 159.567 202.985 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 159.567C198.004 159.567 198.806 158.764 198.806 157.773C198.806 156.782 198.004 155.978 197.015 155.978C196.026 155.978 195.224 156.782 195.224 157.773C195.224 158.764 196.026 159.567 197.015 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 159.567C192.034 159.567 192.836 158.764 192.836 157.773C192.836 156.782 192.034 155.978 191.045 155.978C190.056 155.978 189.254 156.782 189.254 157.773C189.254 158.764 190.056 159.567 191.045 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 159.567C186.064 159.567 186.866 158.764 186.866 157.773C186.866 156.782 186.064 155.978 185.075 155.978C184.085 155.978 183.284 156.782 183.284 157.773C183.284 158.764 184.085 159.567 185.075 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 159.567C180.094 159.567 180.895 158.764 180.895 157.773C180.895 156.782 180.094 155.978 179.104 155.978C178.115 155.978 177.313 156.782 177.313 157.773C177.313 158.764 178.115 159.567 179.104 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 159.567C174.123 159.567 174.925 158.764 174.925 157.773C174.925 156.782 174.123 155.978 173.134 155.978C172.145 155.978 171.343 156.782 171.343 157.773C171.343 158.764 172.145 159.567 173.134 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 159.567C168.153 159.567 168.955 158.764 168.955 157.773C168.955 156.782 168.153 155.978 167.164 155.978C166.175 155.978 165.373 156.782 165.373 157.773C165.373 158.764 166.175 159.567 167.164 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 159.567C162.183 159.567 162.985 158.764 162.985 157.773C162.985 156.782 162.183 155.978 161.194 155.978C160.205 155.978 159.403 156.782 159.403 157.773C159.403 158.764 160.205 159.567 161.194 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 159.567C156.213 159.567 157.015 158.764 157.015 157.773C157.015 156.782 156.213 155.978 155.224 155.978C154.235 155.978 153.433 156.782 153.433 157.773C153.433 158.764 154.235 159.567 155.224 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 159.567C150.243 159.567 151.045 158.764 151.045 157.773C151.045 156.782 150.243 155.978 149.254 155.978C148.265 155.978 147.463 156.782 147.463 157.773C147.463 158.764 148.265 159.567 149.254 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 159.567C144.273 159.567 145.075 158.764 145.075 157.773C145.075 156.782 144.273 155.978 143.284 155.978C142.294 155.978 141.493 156.782 141.493 157.773C141.493 158.764 142.294 159.567 143.284 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 159.567C138.303 159.567 139.104 158.764 139.104 157.773C139.104 156.782 138.303 155.978 137.313 155.978C136.324 155.978 135.522 156.782 135.522 157.773C135.522 158.764 136.324 159.567 137.313 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 159.567C132.332 159.567 133.134 158.764 133.134 157.773C133.134 156.782 132.332 155.978 131.343 155.978C130.354 155.978 129.552 156.782 129.552 157.773C129.552 158.764 130.354 159.567 131.343 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 159.567C126.362 159.567 127.164 158.764 127.164 157.773C127.164 156.782 126.362 155.978 125.373 155.978C124.384 155.978 123.582 156.782 123.582 157.773C123.582 158.764 124.384 159.567 125.373 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 159.567C120.392 159.567 121.194 158.764 121.194 157.773C121.194 156.782 120.392 155.978 119.403 155.978C118.414 155.978 117.612 156.782 117.612 157.773C117.612 158.764 118.414 159.567 119.403 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 159.567C114.422 159.567 115.224 158.764 115.224 157.773C115.224 156.782 114.422 155.978 113.433 155.978C112.444 155.978 111.642 156.782 111.642 157.773C111.642 158.764 112.444 159.567 113.433 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 159.567C108.452 159.567 109.254 158.764 109.254 157.773C109.254 156.782 108.452 155.978 107.463 155.978C106.474 155.978 105.672 156.782 105.672 157.773C105.672 158.764 106.474 159.567 107.463 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 159.567C102.482 159.567 103.284 158.764 103.284 157.773C103.284 156.782 102.482 155.978 101.493 155.978C100.503 155.978 99.7015 156.782 99.7015 157.773C99.7015 158.764 100.503 159.567 101.493 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 159.567C96.5116 159.567 97.3134 158.764 97.3134 157.773C97.3134 156.782 96.5116 155.978 95.5224 155.978C94.5332 155.978 93.7314 156.782 93.7314 157.773C93.7314 158.764 94.5332 159.567 95.5224 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 159.567C90.5414 159.567 91.3433 158.764 91.3433 157.773C91.3433 156.782 90.5414 155.978 89.5522 155.978C88.563 155.978 87.7612 156.782 87.7612 157.773C87.7612 158.764 88.563 159.567 89.5522 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 153.585C311.437 153.585 312.239 152.782 312.239 151.791C312.239 150.799 311.437 149.996 310.448 149.996C309.459 149.996 308.657 150.799 308.657 151.791C308.657 152.782 309.459 153.585 310.448 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 153.585C305.467 153.585 306.269 152.782 306.269 151.791C306.269 150.799 305.467 149.996 304.478 149.996C303.488 149.996 302.687 150.799 302.687 151.791C302.687 152.782 303.488 153.585 304.478 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 153.585C293.526 153.585 294.328 152.782 294.328 151.791C294.328 150.799 293.526 149.996 292.537 149.996C291.548 149.996 290.746 150.799 290.746 151.791C290.746 152.782 291.548 153.585 292.537 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 153.585C287.556 153.585 288.358 152.782 288.358 151.791C288.358 150.799 287.556 149.996 286.567 149.996C285.578 149.996 284.776 150.799 284.776 151.791C284.776 152.782 285.578 153.585 286.567 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 153.585C281.586 153.585 282.388 152.782 282.388 151.791C282.388 150.799 281.586 149.996 280.597 149.996C279.608 149.996 278.806 150.799 278.806 151.791C278.806 152.782 279.608 153.585 280.597 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 153.585C275.616 153.585 276.418 152.782 276.418 151.791C276.418 150.799 275.616 149.996 274.627 149.996C273.638 149.996 272.836 150.799 272.836 151.791C272.836 152.782 273.638 153.585 274.627 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 153.585C269.646 153.585 270.448 152.782 270.448 151.791C270.448 150.799 269.646 149.996 268.657 149.996C267.668 149.996 266.866 150.799 266.866 151.791C266.866 152.782 267.668 153.585 268.657 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 153.585C263.676 153.585 264.478 152.782 264.478 151.791C264.478 150.799 263.676 149.996 262.687 149.996C261.697 149.996 260.896 150.799 260.896 151.791C260.896 152.782 261.697 153.585 262.687 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 153.585C257.706 153.585 258.507 152.782 258.507 151.791C258.507 150.799 257.706 149.996 256.716 149.996C255.727 149.996 254.925 150.799 254.925 151.791C254.925 152.782 255.727 153.585 256.716 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 153.585C233.825 153.585 234.627 152.782 234.627 151.791C234.627 150.799 233.825 149.996 232.836 149.996C231.847 149.996 231.045 150.799 231.045 151.791C231.045 152.782 231.847 153.585 232.836 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 153.585C221.885 153.585 222.687 152.782 222.687 151.791C222.687 150.799 221.885 149.996 220.896 149.996C219.906 149.996 219.104 150.799 219.104 151.791C219.104 152.782 219.906 153.585 220.896 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 153.585C215.915 153.585 216.716 152.782 216.716 151.791C216.716 150.799 215.915 149.996 214.925 149.996C213.936 149.996 213.134 150.799 213.134 151.791C213.134 152.782 213.936 153.585 214.925 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 153.585C209.944 153.585 210.746 152.782 210.746 151.791C210.746 150.799 209.944 149.996 208.955 149.996C207.966 149.996 207.164 150.799 207.164 151.791C207.164 152.782 207.966 153.585 208.955 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 153.585C203.974 153.585 204.776 152.782 204.776 151.791C204.776 150.799 203.974 149.996 202.985 149.996C201.996 149.996 201.194 150.799 201.194 151.791C201.194 152.782 201.996 153.585 202.985 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 153.585C198.004 153.585 198.806 152.782 198.806 151.791C198.806 150.799 198.004 149.996 197.015 149.996C196.026 149.996 195.224 150.799 195.224 151.791C195.224 152.782 196.026 153.585 197.015 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 153.585C192.034 153.585 192.836 152.782 192.836 151.791C192.836 150.799 192.034 149.996 191.045 149.996C190.056 149.996 189.254 150.799 189.254 151.791C189.254 152.782 190.056 153.585 191.045 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 153.585C186.064 153.585 186.866 152.782 186.866 151.791C186.866 150.799 186.064 149.996 185.075 149.996C184.085 149.996 183.284 150.799 183.284 151.791C183.284 152.782 184.085 153.585 185.075 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 153.585C180.094 153.585 180.895 152.782 180.895 151.791C180.895 150.799 180.094 149.996 179.104 149.996C178.115 149.996 177.313 150.799 177.313 151.791C177.313 152.782 178.115 153.585 179.104 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 153.585C174.123 153.585 174.925 152.782 174.925 151.791C174.925 150.799 174.123 149.996 173.134 149.996C172.145 149.996 171.343 150.799 171.343 151.791C171.343 152.782 172.145 153.585 173.134 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 153.585C168.153 153.585 168.955 152.782 168.955 151.791C168.955 150.799 168.153 149.996 167.164 149.996C166.175 149.996 165.373 150.799 165.373 151.791C165.373 152.782 166.175 153.585 167.164 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 153.585C162.183 153.585 162.985 152.782 162.985 151.791C162.985 150.799 162.183 149.996 161.194 149.996C160.205 149.996 159.403 150.799 159.403 151.791C159.403 152.782 160.205 153.585 161.194 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 153.585C156.213 153.585 157.015 152.782 157.015 151.791C157.015 150.799 156.213 149.996 155.224 149.996C154.235 149.996 153.433 150.799 153.433 151.791C153.433 152.782 154.235 153.585 155.224 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 153.585C150.243 153.585 151.045 152.782 151.045 151.791C151.045 150.799 150.243 149.996 149.254 149.996C148.265 149.996 147.463 150.799 147.463 151.791C147.463 152.782 148.265 153.585 149.254 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 153.585C144.273 153.585 145.075 152.782 145.075 151.791C145.075 150.799 144.273 149.996 143.284 149.996C142.294 149.996 141.493 150.799 141.493 151.791C141.493 152.782 142.294 153.585 143.284 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 153.585C138.303 153.585 139.104 152.782 139.104 151.791C139.104 150.799 138.303 149.996 137.313 149.996C136.324 149.996 135.522 150.799 135.522 151.791C135.522 152.782 136.324 153.585 137.313 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 153.585C132.332 153.585 133.134 152.782 133.134 151.791C133.134 150.799 132.332 149.996 131.343 149.996C130.354 149.996 129.552 150.799 129.552 151.791C129.552 152.782 130.354 153.585 131.343 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 153.585C126.362 153.585 127.164 152.782 127.164 151.791C127.164 150.799 126.362 149.996 125.373 149.996C124.384 149.996 123.582 150.799 123.582 151.791C123.582 152.782 124.384 153.585 125.373 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 153.585C120.392 153.585 121.194 152.782 121.194 151.791C121.194 150.799 120.392 149.996 119.403 149.996C118.414 149.996 117.612 150.799 117.612 151.791C117.612 152.782 118.414 153.585 119.403 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 153.585C114.422 153.585 115.224 152.782 115.224 151.791C115.224 150.799 114.422 149.996 113.433 149.996C112.444 149.996 111.642 150.799 111.642 151.791C111.642 152.782 112.444 153.585 113.433 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 153.585C108.452 153.585 109.254 152.782 109.254 151.791C109.254 150.799 108.452 149.996 107.463 149.996C106.474 149.996 105.672 150.799 105.672 151.791C105.672 152.782 106.474 153.585 107.463 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 153.585C102.482 153.585 103.284 152.782 103.284 151.791C103.284 150.799 102.482 149.996 101.493 149.996C100.503 149.996 99.7015 150.799 99.7015 151.791C99.7015 152.782 100.503 153.585 101.493 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 153.585C96.5116 153.585 97.3134 152.782 97.3134 151.791C97.3134 150.799 96.5116 149.996 95.5224 149.996C94.5332 149.996 93.7314 150.799 93.7314 151.791C93.7314 152.782 94.5332 153.585 95.5224 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 147.603C317.407 147.603 318.209 146.799 318.209 145.808C318.209 144.817 317.407 144.013 316.418 144.013C315.429 144.013 314.627 144.817 314.627 145.808C314.627 146.799 315.429 147.603 316.418 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 147.603C311.437 147.603 312.239 146.799 312.239 145.808C312.239 144.817 311.437 144.013 310.448 144.013C309.459 144.013 308.657 144.817 308.657 145.808C308.657 146.799 309.459 147.603 310.448 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 147.603C305.467 147.603 306.269 146.799 306.269 145.808C306.269 144.817 305.467 144.013 304.478 144.013C303.488 144.013 302.687 144.817 302.687 145.808C302.687 146.799 303.488 147.603 304.478 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 147.603C299.497 147.603 300.299 146.799 300.299 145.808C300.299 144.817 299.497 144.013 298.507 144.013C297.518 144.013 296.716 144.817 296.716 145.808C296.716 146.799 297.518 147.603 298.507 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 147.603C293.526 147.603 294.328 146.799 294.328 145.808C294.328 144.817 293.526 144.013 292.537 144.013C291.548 144.013 290.746 144.817 290.746 145.808C290.746 146.799 291.548 147.603 292.537 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 147.603C287.556 147.603 288.358 146.799 288.358 145.808C288.358 144.817 287.556 144.013 286.567 144.013C285.578 144.013 284.776 144.817 284.776 145.808C284.776 146.799 285.578 147.603 286.567 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 147.603C281.586 147.603 282.388 146.799 282.388 145.808C282.388 144.817 281.586 144.013 280.597 144.013C279.608 144.013 278.806 144.817 278.806 145.808C278.806 146.799 279.608 147.603 280.597 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 147.603C275.616 147.603 276.418 146.799 276.418 145.808C276.418 144.817 275.616 144.013 274.627 144.013C273.638 144.013 272.836 144.817 272.836 145.808C272.836 146.799 273.638 147.603 274.627 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 147.603C269.646 147.603 270.448 146.799 270.448 145.808C270.448 144.817 269.646 144.013 268.657 144.013C267.668 144.013 266.866 144.817 266.866 145.808C266.866 146.799 267.668 147.603 268.657 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 147.603C263.676 147.603 264.478 146.799 264.478 145.808C264.478 144.817 263.676 144.013 262.687 144.013C261.697 144.013 260.896 144.817 260.896 145.808C260.896 146.799 261.697 147.603 262.687 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 147.603C257.706 147.603 258.507 146.799 258.507 145.808C258.507 144.817 257.706 144.013 256.716 144.013C255.727 144.013 254.925 144.817 254.925 145.808C254.925 146.799 255.727 147.603 256.716 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 147.603C251.735 147.603 252.537 146.799 252.537 145.808C252.537 144.817 251.735 144.013 250.746 144.013C249.757 144.013 248.955 144.817 248.955 145.808C248.955 146.799 249.757 147.603 250.746 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 147.603C227.855 147.603 228.657 146.799 228.657 145.808C228.657 144.817 227.855 144.013 226.866 144.013C225.877 144.013 225.075 144.817 225.075 145.808C225.075 146.799 225.877 147.603 226.866 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 147.603C221.885 147.603 222.687 146.799 222.687 145.808C222.687 144.817 221.885 144.013 220.896 144.013C219.906 144.013 219.104 144.817 219.104 145.808C219.104 146.799 219.906 147.603 220.896 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 147.603C215.915 147.603 216.716 146.799 216.716 145.808C216.716 144.817 215.915 144.013 214.925 144.013C213.936 144.013 213.134 144.817 213.134 145.808C213.134 146.799 213.936 147.603 214.925 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 147.603C209.944 147.603 210.746 146.799 210.746 145.808C210.746 144.817 209.944 144.013 208.955 144.013C207.966 144.013 207.164 144.817 207.164 145.808C207.164 146.799 207.966 147.603 208.955 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 147.603C203.974 147.603 204.776 146.799 204.776 145.808C204.776 144.817 203.974 144.013 202.985 144.013C201.996 144.013 201.194 144.817 201.194 145.808C201.194 146.799 201.996 147.603 202.985 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 147.603C198.004 147.603 198.806 146.799 198.806 145.808C198.806 144.817 198.004 144.013 197.015 144.013C196.026 144.013 195.224 144.817 195.224 145.808C195.224 146.799 196.026 147.603 197.015 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 147.603C192.034 147.603 192.836 146.799 192.836 145.808C192.836 144.817 192.034 144.013 191.045 144.013C190.056 144.013 189.254 144.817 189.254 145.808C189.254 146.799 190.056 147.603 191.045 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 147.603C186.064 147.603 186.866 146.799 186.866 145.808C186.866 144.817 186.064 144.013 185.075 144.013C184.085 144.013 183.284 144.817 183.284 145.808C183.284 146.799 184.085 147.603 185.075 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 147.603C180.094 147.603 180.895 146.799 180.895 145.808C180.895 144.817 180.094 144.013 179.104 144.013C178.115 144.013 177.313 144.817 177.313 145.808C177.313 146.799 178.115 147.603 179.104 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 147.603C174.123 147.603 174.925 146.799 174.925 145.808C174.925 144.817 174.123 144.013 173.134 144.013C172.145 144.013 171.343 144.817 171.343 145.808C171.343 146.799 172.145 147.603 173.134 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 147.603C168.153 147.603 168.955 146.799 168.955 145.808C168.955 144.817 168.153 144.013 167.164 144.013C166.175 144.013 165.373 144.817 165.373 145.808C165.373 146.799 166.175 147.603 167.164 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 147.603C162.183 147.603 162.985 146.799 162.985 145.808C162.985 144.817 162.183 144.013 161.194 144.013C160.205 144.013 159.403 144.817 159.403 145.808C159.403 146.799 160.205 147.603 161.194 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 147.603C156.213 147.603 157.015 146.799 157.015 145.808C157.015 144.817 156.213 144.013 155.224 144.013C154.235 144.013 153.433 144.817 153.433 145.808C153.433 146.799 154.235 147.603 155.224 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 147.603C150.243 147.603 151.045 146.799 151.045 145.808C151.045 144.817 150.243 144.013 149.254 144.013C148.265 144.013 147.463 144.817 147.463 145.808C147.463 146.799 148.265 147.603 149.254 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 147.603C144.273 147.603 145.075 146.799 145.075 145.808C145.075 144.817 144.273 144.013 143.284 144.013C142.294 144.013 141.493 144.817 141.493 145.808C141.493 146.799 142.294 147.603 143.284 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 147.603C138.303 147.603 139.104 146.799 139.104 145.808C139.104 144.817 138.303 144.013 137.313 144.013C136.324 144.013 135.522 144.817 135.522 145.808C135.522 146.799 136.324 147.603 137.313 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 147.603C132.332 147.603 133.134 146.799 133.134 145.808C133.134 144.817 132.332 144.013 131.343 144.013C130.354 144.013 129.552 144.817 129.552 145.808C129.552 146.799 130.354 147.603 131.343 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 147.603C126.362 147.603 127.164 146.799 127.164 145.808C127.164 144.817 126.362 144.013 125.373 144.013C124.384 144.013 123.582 144.817 123.582 145.808C123.582 146.799 124.384 147.603 125.373 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 147.603C120.392 147.603 121.194 146.799 121.194 145.808C121.194 144.817 120.392 144.013 119.403 144.013C118.414 144.013 117.612 144.817 117.612 145.808C117.612 146.799 118.414 147.603 119.403 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 147.603C114.422 147.603 115.224 146.799 115.224 145.808C115.224 144.817 114.422 144.013 113.433 144.013C112.444 144.013 111.642 144.817 111.642 145.808C111.642 146.799 112.444 147.603 113.433 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 147.603C108.452 147.603 109.254 146.799 109.254 145.808C109.254 144.817 108.452 144.013 107.463 144.013C106.474 144.013 105.672 144.817 105.672 145.808C105.672 146.799 106.474 147.603 107.463 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 147.603C102.482 147.603 103.284 146.799 103.284 145.808C103.284 144.817 102.482 144.013 101.493 144.013C100.503 144.013 99.7015 144.817 99.7015 145.808C99.7015 146.799 100.503 147.603 101.493 147.603Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 141.621C347.258 141.621 348.06 140.817 348.06 139.826C348.06 138.835 347.258 138.031 346.269 138.031C345.279 138.031 344.478 138.835 344.478 139.826C344.478 140.817 345.279 141.621 346.269 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 141.621C341.288 141.621 342.09 140.817 342.09 139.826C342.09 138.835 341.288 138.031 340.299 138.031C339.309 138.031 338.507 138.835 338.507 139.826C338.507 140.817 339.309 141.621 340.299 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 141.621C335.318 141.621 336.119 140.817 336.119 139.826C336.119 138.835 335.318 138.031 334.328 138.031C333.339 138.031 332.537 138.835 332.537 139.826C332.537 140.817 333.339 141.621 334.328 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 141.621C305.467 141.621 306.269 140.817 306.269 139.826C306.269 138.835 305.467 138.031 304.478 138.031C303.488 138.031 302.687 138.835 302.687 139.826C302.687 140.817 303.488 141.621 304.478 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 141.621C299.497 141.621 300.299 140.817 300.299 139.826C300.299 138.835 299.497 138.031 298.507 138.031C297.518 138.031 296.716 138.835 296.716 139.826C296.716 140.817 297.518 141.621 298.507 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 141.621C293.526 141.621 294.328 140.817 294.328 139.826C294.328 138.835 293.526 138.031 292.537 138.031C291.548 138.031 290.746 138.835 290.746 139.826C290.746 140.817 291.548 141.621 292.537 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 141.621C287.556 141.621 288.358 140.817 288.358 139.826C288.358 138.835 287.556 138.031 286.567 138.031C285.578 138.031 284.776 138.835 284.776 139.826C284.776 140.817 285.578 141.621 286.567 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 141.621C281.586 141.621 282.388 140.817 282.388 139.826C282.388 138.835 281.586 138.031 280.597 138.031C279.608 138.031 278.806 138.835 278.806 139.826C278.806 140.817 279.608 141.621 280.597 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 141.621C275.616 141.621 276.418 140.817 276.418 139.826C276.418 138.835 275.616 138.031 274.627 138.031C273.638 138.031 272.836 138.835 272.836 139.826C272.836 140.817 273.638 141.621 274.627 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 141.621C269.646 141.621 270.448 140.817 270.448 139.826C270.448 138.835 269.646 138.031 268.657 138.031C267.668 138.031 266.866 138.835 266.866 139.826C266.866 140.817 267.668 141.621 268.657 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 141.621C263.676 141.621 264.478 140.817 264.478 139.826C264.478 138.835 263.676 138.031 262.687 138.031C261.697 138.031 260.896 138.835 260.896 139.826C260.896 140.817 261.697 141.621 262.687 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 141.621C257.706 141.621 258.507 140.817 258.507 139.826C258.507 138.835 257.706 138.031 256.716 138.031C255.727 138.031 254.925 138.835 254.925 139.826C254.925 140.817 255.727 141.621 256.716 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 141.621C251.735 141.621 252.537 140.817 252.537 139.826C252.537 138.835 251.735 138.031 250.746 138.031C249.757 138.031 248.955 138.835 248.955 139.826C248.955 140.817 249.757 141.621 250.746 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 141.621C245.765 141.621 246.567 140.817 246.567 139.826C246.567 138.835 245.765 138.031 244.776 138.031C243.787 138.031 242.985 138.835 242.985 139.826C242.985 140.817 243.787 141.621 244.776 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 141.621C239.795 141.621 240.597 140.817 240.597 139.826C240.597 138.835 239.795 138.031 238.806 138.031C237.817 138.031 237.015 138.835 237.015 139.826C237.015 140.817 237.817 141.621 238.806 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 141.621C215.915 141.621 216.716 140.817 216.716 139.826C216.716 138.835 215.915 138.031 214.925 138.031C213.936 138.031 213.134 138.835 213.134 139.826C213.134 140.817 213.936 141.621 214.925 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 141.621C209.944 141.621 210.746 140.817 210.746 139.826C210.746 138.835 209.944 138.031 208.955 138.031C207.966 138.031 207.164 138.835 207.164 139.826C207.164 140.817 207.966 141.621 208.955 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 141.621C203.974 141.621 204.776 140.817 204.776 139.826C204.776 138.835 203.974 138.031 202.985 138.031C201.996 138.031 201.194 138.835 201.194 139.826C201.194 140.817 201.996 141.621 202.985 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 141.621C198.004 141.621 198.806 140.817 198.806 139.826C198.806 138.835 198.004 138.031 197.015 138.031C196.026 138.031 195.224 138.835 195.224 139.826C195.224 140.817 196.026 141.621 197.015 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 141.621C192.034 141.621 192.836 140.817 192.836 139.826C192.836 138.835 192.034 138.031 191.045 138.031C190.056 138.031 189.254 138.835 189.254 139.826C189.254 140.817 190.056 141.621 191.045 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 141.621C186.064 141.621 186.866 140.817 186.866 139.826C186.866 138.835 186.064 138.031 185.075 138.031C184.085 138.031 183.284 138.835 183.284 139.826C183.284 140.817 184.085 141.621 185.075 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 141.621C180.094 141.621 180.895 140.817 180.895 139.826C180.895 138.835 180.094 138.031 179.104 138.031C178.115 138.031 177.313 138.835 177.313 139.826C177.313 140.817 178.115 141.621 179.104 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 141.621C174.123 141.621 174.925 140.817 174.925 139.826C174.925 138.835 174.123 138.031 173.134 138.031C172.145 138.031 171.343 138.835 171.343 139.826C171.343 140.817 172.145 141.621 173.134 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 141.621C168.153 141.621 168.955 140.817 168.955 139.826C168.955 138.835 168.153 138.031 167.164 138.031C166.175 138.031 165.373 138.835 165.373 139.826C165.373 140.817 166.175 141.621 167.164 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 141.621C162.183 141.621 162.985 140.817 162.985 139.826C162.985 138.835 162.183 138.031 161.194 138.031C160.205 138.031 159.403 138.835 159.403 139.826C159.403 140.817 160.205 141.621 161.194 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 141.621C156.213 141.621 157.015 140.817 157.015 139.826C157.015 138.835 156.213 138.031 155.224 138.031C154.235 138.031 153.433 138.835 153.433 139.826C153.433 140.817 154.235 141.621 155.224 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 141.621C150.243 141.621 151.045 140.817 151.045 139.826C151.045 138.835 150.243 138.031 149.254 138.031C148.265 138.031 147.463 138.835 147.463 139.826C147.463 140.817 148.265 141.621 149.254 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 141.621C144.273 141.621 145.075 140.817 145.075 139.826C145.075 138.835 144.273 138.031 143.284 138.031C142.294 138.031 141.493 138.835 141.493 139.826C141.493 140.817 142.294 141.621 143.284 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 141.621C138.303 141.621 139.104 140.817 139.104 139.826C139.104 138.835 138.303 138.031 137.313 138.031C136.324 138.031 135.522 138.835 135.522 139.826C135.522 140.817 136.324 141.621 137.313 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 141.621C132.332 141.621 133.134 140.817 133.134 139.826C133.134 138.835 132.332 138.031 131.343 138.031C130.354 138.031 129.552 138.835 129.552 139.826C129.552 140.817 130.354 141.621 131.343 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 141.621C126.362 141.621 127.164 140.817 127.164 139.826C127.164 138.835 126.362 138.031 125.373 138.031C124.384 138.031 123.582 138.835 123.582 139.826C123.582 140.817 124.384 141.621 125.373 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 141.621C120.392 141.621 121.194 140.817 121.194 139.826C121.194 138.835 120.392 138.031 119.403 138.031C118.414 138.031 117.612 138.835 117.612 139.826C117.612 140.817 118.414 141.621 119.403 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 141.621C114.422 141.621 115.224 140.817 115.224 139.826C115.224 138.835 114.422 138.031 113.433 138.031C112.444 138.031 111.642 138.835 111.642 139.826C111.642 140.817 112.444 141.621 113.433 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 141.621C108.452 141.621 109.254 140.817 109.254 139.826C109.254 138.835 108.452 138.031 107.463 138.031C106.474 138.031 105.672 138.835 105.672 139.826C105.672 140.817 106.474 141.621 107.463 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 141.621C102.482 141.621 103.284 140.817 103.284 139.826C103.284 138.835 102.482 138.031 101.493 138.031C100.503 138.031 99.7015 138.835 99.7015 139.826C99.7015 140.817 100.503 141.621 101.493 141.621Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 135.638C341.288 135.638 342.09 134.835 342.09 133.844C342.09 132.852 341.288 132.049 340.299 132.049C339.309 132.049 338.507 132.852 338.507 133.844C338.507 134.835 339.309 135.638 340.299 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 135.638C311.437 135.638 312.239 134.835 312.239 133.844C312.239 132.852 311.437 132.049 310.448 132.049C309.459 132.049 308.657 132.852 308.657 133.844C308.657 134.835 309.459 135.638 310.448 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 135.638C305.467 135.638 306.269 134.835 306.269 133.844C306.269 132.852 305.467 132.049 304.478 132.049C303.488 132.049 302.687 132.852 302.687 133.844C302.687 134.835 303.488 135.638 304.478 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 135.638C293.526 135.638 294.328 134.835 294.328 133.844C294.328 132.852 293.526 132.049 292.537 132.049C291.548 132.049 290.746 132.852 290.746 133.844C290.746 134.835 291.548 135.638 292.537 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 135.638C287.556 135.638 288.358 134.835 288.358 133.844C288.358 132.852 287.556 132.049 286.567 132.049C285.578 132.049 284.776 132.852 284.776 133.844C284.776 134.835 285.578 135.638 286.567 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 135.638C281.586 135.638 282.388 134.835 282.388 133.844C282.388 132.852 281.586 132.049 280.597 132.049C279.608 132.049 278.806 132.852 278.806 133.844C278.806 134.835 279.608 135.638 280.597 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 135.638C275.616 135.638 276.418 134.835 276.418 133.844C276.418 132.852 275.616 132.049 274.627 132.049C273.638 132.049 272.836 132.852 272.836 133.844C272.836 134.835 273.638 135.638 274.627 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 135.638C269.646 135.638 270.448 134.835 270.448 133.844C270.448 132.852 269.646 132.049 268.657 132.049C267.668 132.049 266.866 132.852 266.866 133.844C266.866 134.835 267.668 135.638 268.657 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 135.638C263.676 135.638 264.478 134.835 264.478 133.844C264.478 132.852 263.676 132.049 262.687 132.049C261.697 132.049 260.896 132.852 260.896 133.844C260.896 134.835 261.697 135.638 262.687 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 135.638C257.706 135.638 258.507 134.835 258.507 133.844C258.507 132.852 257.706 132.049 256.716 132.049C255.727 132.049 254.925 132.852 254.925 133.844C254.925 134.835 255.727 135.638 256.716 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 135.638C251.735 135.638 252.537 134.835 252.537 133.844C252.537 132.852 251.735 132.049 250.746 132.049C249.757 132.049 248.955 132.852 248.955 133.844C248.955 134.835 249.757 135.638 250.746 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 135.638C245.765 135.638 246.567 134.835 246.567 133.844C246.567 132.852 245.765 132.049 244.776 132.049C243.787 132.049 242.985 132.852 242.985 133.844C242.985 134.835 243.787 135.638 244.776 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 135.638C239.795 135.638 240.597 134.835 240.597 133.844C240.597 132.852 239.795 132.049 238.806 132.049C237.817 132.049 237.015 132.852 237.015 133.844C237.015 134.835 237.817 135.638 238.806 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 135.638C233.825 135.638 234.627 134.835 234.627 133.844C234.627 132.852 233.825 132.049 232.836 132.049C231.847 132.049 231.045 132.852 231.045 133.844C231.045 134.835 231.847 135.638 232.836 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 135.638C227.855 135.638 228.657 134.835 228.657 133.844C228.657 132.852 227.855 132.049 226.866 132.049C225.877 132.049 225.075 132.852 225.075 133.844C225.075 134.835 225.877 135.638 226.866 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 135.638C221.885 135.638 222.687 134.835 222.687 133.844C222.687 132.852 221.885 132.049 220.896 132.049C219.906 132.049 219.104 132.852 219.104 133.844C219.104 134.835 219.906 135.638 220.896 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 135.638C215.915 135.638 216.716 134.835 216.716 133.844C216.716 132.852 215.915 132.049 214.925 132.049C213.936 132.049 213.134 132.852 213.134 133.844C213.134 134.835 213.936 135.638 214.925 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 135.638C209.944 135.638 210.746 134.835 210.746 133.844C210.746 132.852 209.944 132.049 208.955 132.049C207.966 132.049 207.164 132.852 207.164 133.844C207.164 134.835 207.966 135.638 208.955 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 135.638C203.974 135.638 204.776 134.835 204.776 133.844C204.776 132.852 203.974 132.049 202.985 132.049C201.996 132.049 201.194 132.852 201.194 133.844C201.194 134.835 201.996 135.638 202.985 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 135.638C198.004 135.638 198.806 134.835 198.806 133.844C198.806 132.852 198.004 132.049 197.015 132.049C196.026 132.049 195.224 132.852 195.224 133.844C195.224 134.835 196.026 135.638 197.015 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 135.638C192.034 135.638 192.836 134.835 192.836 133.844C192.836 132.852 192.034 132.049 191.045 132.049C190.056 132.049 189.254 132.852 189.254 133.844C189.254 134.835 190.056 135.638 191.045 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 135.638C186.064 135.638 186.866 134.835 186.866 133.844C186.866 132.852 186.064 132.049 185.075 132.049C184.085 132.049 183.284 132.852 183.284 133.844C183.284 134.835 184.085 135.638 185.075 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 135.638C180.094 135.638 180.895 134.835 180.895 133.844C180.895 132.852 180.094 132.049 179.104 132.049C178.115 132.049 177.313 132.852 177.313 133.844C177.313 134.835 178.115 135.638 179.104 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 135.638C174.123 135.638 174.925 134.835 174.925 133.844C174.925 132.852 174.123 132.049 173.134 132.049C172.145 132.049 171.343 132.852 171.343 133.844C171.343 134.835 172.145 135.638 173.134 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 135.638C168.153 135.638 168.955 134.835 168.955 133.844C168.955 132.852 168.153 132.049 167.164 132.049C166.175 132.049 165.373 132.852 165.373 133.844C165.373 134.835 166.175 135.638 167.164 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 135.638C162.183 135.638 162.985 134.835 162.985 133.844C162.985 132.852 162.183 132.049 161.194 132.049C160.205 132.049 159.403 132.852 159.403 133.844C159.403 134.835 160.205 135.638 161.194 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 135.638C156.213 135.638 157.015 134.835 157.015 133.844C157.015 132.852 156.213 132.049 155.224 132.049C154.235 132.049 153.433 132.852 153.433 133.844C153.433 134.835 154.235 135.638 155.224 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 135.638C150.243 135.638 151.045 134.835 151.045 133.844C151.045 132.852 150.243 132.049 149.254 132.049C148.265 132.049 147.463 132.852 147.463 133.844C147.463 134.835 148.265 135.638 149.254 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 135.638C144.273 135.638 145.075 134.835 145.075 133.844C145.075 132.852 144.273 132.049 143.284 132.049C142.294 132.049 141.493 132.852 141.493 133.844C141.493 134.835 142.294 135.638 143.284 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 135.638C138.303 135.638 139.104 134.835 139.104 133.844C139.104 132.852 138.303 132.049 137.313 132.049C136.324 132.049 135.522 132.852 135.522 133.844C135.522 134.835 136.324 135.638 137.313 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 135.638C132.332 135.638 133.134 134.835 133.134 133.844C133.134 132.852 132.332 132.049 131.343 132.049C130.354 132.049 129.552 132.852 129.552 133.844C129.552 134.835 130.354 135.638 131.343 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 135.638C126.362 135.638 127.164 134.835 127.164 133.844C127.164 132.852 126.362 132.049 125.373 132.049C124.384 132.049 123.582 132.852 123.582 133.844C123.582 134.835 124.384 135.638 125.373 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 135.638C120.392 135.638 121.194 134.835 121.194 133.844C121.194 132.852 120.392 132.049 119.403 132.049C118.414 132.049 117.612 132.852 117.612 133.844C117.612 134.835 118.414 135.638 119.403 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 135.638C114.422 135.638 115.224 134.835 115.224 133.844C115.224 132.852 114.422 132.049 113.433 132.049C112.444 132.049 111.642 132.852 111.642 133.844C111.642 134.835 112.444 135.638 113.433 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 135.638C108.452 135.638 109.254 134.835 109.254 133.844C109.254 132.852 108.452 132.049 107.463 132.049C106.474 132.049 105.672 132.852 105.672 133.844C105.672 134.835 106.474 135.638 107.463 135.638Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 129.656C305.467 129.656 306.269 128.853 306.269 127.861C306.269 126.87 305.467 126.067 304.478 126.067C303.488 126.067 302.687 126.87 302.687 127.861C302.687 128.853 303.488 129.656 304.478 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 129.656C299.497 129.656 300.299 128.853 300.299 127.861C300.299 126.87 299.497 126.067 298.507 126.067C297.518 126.067 296.716 126.87 296.716 127.861C296.716 128.853 297.518 129.656 298.507 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 129.656C293.526 129.656 294.328 128.853 294.328 127.861C294.328 126.87 293.526 126.067 292.537 126.067C291.548 126.067 290.746 126.87 290.746 127.861C290.746 128.853 291.548 129.656 292.537 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 129.656C287.556 129.656 288.358 128.853 288.358 127.861C288.358 126.87 287.556 126.067 286.567 126.067C285.578 126.067 284.776 126.87 284.776 127.861C284.776 128.853 285.578 129.656 286.567 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 129.656C281.586 129.656 282.388 128.853 282.388 127.861C282.388 126.87 281.586 126.067 280.597 126.067C279.608 126.067 278.806 126.87 278.806 127.861C278.806 128.853 279.608 129.656 280.597 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 129.656C275.616 129.656 276.418 128.853 276.418 127.861C276.418 126.87 275.616 126.067 274.627 126.067C273.638 126.067 272.836 126.87 272.836 127.861C272.836 128.853 273.638 129.656 274.627 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 129.656C269.646 129.656 270.448 128.853 270.448 127.861C270.448 126.87 269.646 126.067 268.657 126.067C267.668 126.067 266.866 126.87 266.866 127.861C266.866 128.853 267.668 129.656 268.657 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 129.656C263.676 129.656 264.478 128.853 264.478 127.861C264.478 126.87 263.676 126.067 262.687 126.067C261.697 126.067 260.896 126.87 260.896 127.861C260.896 128.853 261.697 129.656 262.687 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 129.656C257.706 129.656 258.507 128.853 258.507 127.861C258.507 126.87 257.706 126.067 256.716 126.067C255.727 126.067 254.925 126.87 254.925 127.861C254.925 128.853 255.727 129.656 256.716 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 129.656C251.735 129.656 252.537 128.853 252.537 127.861C252.537 126.87 251.735 126.067 250.746 126.067C249.757 126.067 248.955 126.87 248.955 127.861C248.955 128.853 249.757 129.656 250.746 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 129.656C245.765 129.656 246.567 128.853 246.567 127.861C246.567 126.87 245.765 126.067 244.776 126.067C243.787 126.067 242.985 126.87 242.985 127.861C242.985 128.853 243.787 129.656 244.776 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 129.656C239.795 129.656 240.597 128.853 240.597 127.861C240.597 126.87 239.795 126.067 238.806 126.067C237.817 126.067 237.015 126.87 237.015 127.861C237.015 128.853 237.817 129.656 238.806 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 129.656C233.825 129.656 234.627 128.853 234.627 127.861C234.627 126.87 233.825 126.067 232.836 126.067C231.847 126.067 231.045 126.87 231.045 127.861C231.045 128.853 231.847 129.656 232.836 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 129.656C227.855 129.656 228.657 128.853 228.657 127.861C228.657 126.87 227.855 126.067 226.866 126.067C225.877 126.067 225.075 126.87 225.075 127.861C225.075 128.853 225.877 129.656 226.866 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 129.656C221.885 129.656 222.687 128.853 222.687 127.861C222.687 126.87 221.885 126.067 220.896 126.067C219.906 126.067 219.104 126.87 219.104 127.861C219.104 128.853 219.906 129.656 220.896 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 129.656C215.915 129.656 216.716 128.853 216.716 127.861C216.716 126.87 215.915 126.067 214.925 126.067C213.936 126.067 213.134 126.87 213.134 127.861C213.134 128.853 213.936 129.656 214.925 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 129.656C209.944 129.656 210.746 128.853 210.746 127.861C210.746 126.87 209.944 126.067 208.955 126.067C207.966 126.067 207.164 126.87 207.164 127.861C207.164 128.853 207.966 129.656 208.955 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 129.656C203.974 129.656 204.776 128.853 204.776 127.861C204.776 126.87 203.974 126.067 202.985 126.067C201.996 126.067 201.194 126.87 201.194 127.861C201.194 128.853 201.996 129.656 202.985 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 129.656C198.004 129.656 198.806 128.853 198.806 127.861C198.806 126.87 198.004 126.067 197.015 126.067C196.026 126.067 195.224 126.87 195.224 127.861C195.224 128.853 196.026 129.656 197.015 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 129.656C192.034 129.656 192.836 128.853 192.836 127.861C192.836 126.87 192.034 126.067 191.045 126.067C190.056 126.067 189.254 126.87 189.254 127.861C189.254 128.853 190.056 129.656 191.045 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 129.656C186.064 129.656 186.866 128.853 186.866 127.861C186.866 126.87 186.064 126.067 185.075 126.067C184.085 126.067 183.284 126.87 183.284 127.861C183.284 128.853 184.085 129.656 185.075 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 129.656C180.094 129.656 180.895 128.853 180.895 127.861C180.895 126.87 180.094 126.067 179.104 126.067C178.115 126.067 177.313 126.87 177.313 127.861C177.313 128.853 178.115 129.656 179.104 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 129.656C174.123 129.656 174.925 128.853 174.925 127.861C174.925 126.87 174.123 126.067 173.134 126.067C172.145 126.067 171.343 126.87 171.343 127.861C171.343 128.853 172.145 129.656 173.134 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 129.656C168.153 129.656 168.955 128.853 168.955 127.861C168.955 126.87 168.153 126.067 167.164 126.067C166.175 126.067 165.373 126.87 165.373 127.861C165.373 128.853 166.175 129.656 167.164 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 129.656C162.183 129.656 162.985 128.853 162.985 127.861C162.985 126.87 162.183 126.067 161.194 126.067C160.205 126.067 159.403 126.87 159.403 127.861C159.403 128.853 160.205 129.656 161.194 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 129.656C156.213 129.656 157.015 128.853 157.015 127.861C157.015 126.87 156.213 126.067 155.224 126.067C154.235 126.067 153.433 126.87 153.433 127.861C153.433 128.853 154.235 129.656 155.224 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 129.656C150.243 129.656 151.045 128.853 151.045 127.861C151.045 126.87 150.243 126.067 149.254 126.067C148.265 126.067 147.463 126.87 147.463 127.861C147.463 128.853 148.265 129.656 149.254 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 129.656C144.273 129.656 145.075 128.853 145.075 127.861C145.075 126.87 144.273 126.067 143.284 126.067C142.294 126.067 141.493 126.87 141.493 127.861C141.493 128.853 142.294 129.656 143.284 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 129.656C138.303 129.656 139.104 128.853 139.104 127.861C139.104 126.87 138.303 126.067 137.313 126.067C136.324 126.067 135.522 126.87 135.522 127.861C135.522 128.853 136.324 129.656 137.313 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 129.656C132.332 129.656 133.134 128.853 133.134 127.861C133.134 126.87 132.332 126.067 131.343 126.067C130.354 126.067 129.552 126.87 129.552 127.861C129.552 128.853 130.354 129.656 131.343 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 129.656C126.362 129.656 127.164 128.853 127.164 127.861C127.164 126.87 126.362 126.067 125.373 126.067C124.384 126.067 123.582 126.87 123.582 127.861C123.582 128.853 124.384 129.656 125.373 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 129.656C120.392 129.656 121.194 128.853 121.194 127.861C121.194 126.87 120.392 126.067 119.403 126.067C118.414 126.067 117.612 126.87 117.612 127.861C117.612 128.853 118.414 129.656 119.403 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 129.656C114.422 129.656 115.224 128.853 115.224 127.861C115.224 126.87 114.422 126.067 113.433 126.067C112.444 126.067 111.642 126.87 111.642 127.861C111.642 128.853 112.444 129.656 113.433 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 129.656C102.482 129.656 103.284 128.853 103.284 127.861C103.284 126.87 102.482 126.067 101.493 126.067C100.503 126.067 99.7015 126.87 99.7015 127.861C99.7015 128.853 100.503 129.656 101.493 129.656Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 123.674C341.288 123.674 342.09 122.87 342.09 121.879C342.09 120.888 341.288 120.084 340.299 120.084C339.309 120.084 338.507 120.888 338.507 121.879C338.507 122.87 339.309 123.674 340.299 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 123.674C335.318 123.674 336.119 122.87 336.119 121.879C336.119 120.888 335.318 120.084 334.328 120.084C333.339 120.084 332.537 120.888 332.537 121.879C332.537 122.87 333.339 123.674 334.328 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 123.674C329.347 123.674 330.149 122.87 330.149 121.879C330.149 120.888 329.347 120.084 328.358 120.084C327.369 120.084 326.567 120.888 326.567 121.879C326.567 122.87 327.369 123.674 328.358 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 123.674C323.377 123.674 324.179 122.87 324.179 121.879C324.179 120.888 323.377 120.084 322.388 120.084C321.399 120.084 320.597 120.888 320.597 121.879C320.597 122.87 321.399 123.674 322.388 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 123.674C317.407 123.674 318.209 122.87 318.209 121.879C318.209 120.888 317.407 120.084 316.418 120.084C315.429 120.084 314.627 120.888 314.627 121.879C314.627 122.87 315.429 123.674 316.418 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 123.674C311.437 123.674 312.239 122.87 312.239 121.879C312.239 120.888 311.437 120.084 310.448 120.084C309.459 120.084 308.657 120.888 308.657 121.879C308.657 122.87 309.459 123.674 310.448 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 123.674C305.467 123.674 306.269 122.87 306.269 121.879C306.269 120.888 305.467 120.084 304.478 120.084C303.488 120.084 302.687 120.888 302.687 121.879C302.687 122.87 303.488 123.674 304.478 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 123.674C299.497 123.674 300.299 122.87 300.299 121.879C300.299 120.888 299.497 120.084 298.507 120.084C297.518 120.084 296.716 120.888 296.716 121.879C296.716 122.87 297.518 123.674 298.507 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 123.674C293.526 123.674 294.328 122.87 294.328 121.879C294.328 120.888 293.526 120.084 292.537 120.084C291.548 120.084 290.746 120.888 290.746 121.879C290.746 122.87 291.548 123.674 292.537 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 123.674C287.556 123.674 288.358 122.87 288.358 121.879C288.358 120.888 287.556 120.084 286.567 120.084C285.578 120.084 284.776 120.888 284.776 121.879C284.776 122.87 285.578 123.674 286.567 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 123.674C281.586 123.674 282.388 122.87 282.388 121.879C282.388 120.888 281.586 120.084 280.597 120.084C279.608 120.084 278.806 120.888 278.806 121.879C278.806 122.87 279.608 123.674 280.597 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 123.674C275.616 123.674 276.418 122.87 276.418 121.879C276.418 120.888 275.616 120.084 274.627 120.084C273.638 120.084 272.836 120.888 272.836 121.879C272.836 122.87 273.638 123.674 274.627 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 123.674C269.646 123.674 270.448 122.87 270.448 121.879C270.448 120.888 269.646 120.084 268.657 120.084C267.668 120.084 266.866 120.888 266.866 121.879C266.866 122.87 267.668 123.674 268.657 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 123.674C263.676 123.674 264.478 122.87 264.478 121.879C264.478 120.888 263.676 120.084 262.687 120.084C261.697 120.084 260.896 120.888 260.896 121.879C260.896 122.87 261.697 123.674 262.687 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 123.674C257.706 123.674 258.507 122.87 258.507 121.879C258.507 120.888 257.706 120.084 256.716 120.084C255.727 120.084 254.925 120.888 254.925 121.879C254.925 122.87 255.727 123.674 256.716 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 123.674C251.735 123.674 252.537 122.87 252.537 121.879C252.537 120.888 251.735 120.084 250.746 120.084C249.757 120.084 248.955 120.888 248.955 121.879C248.955 122.87 249.757 123.674 250.746 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 123.674C245.765 123.674 246.567 122.87 246.567 121.879C246.567 120.888 245.765 120.084 244.776 120.084C243.787 120.084 242.985 120.888 242.985 121.879C242.985 122.87 243.787 123.674 244.776 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 123.674C239.795 123.674 240.597 122.87 240.597 121.879C240.597 120.888 239.795 120.084 238.806 120.084C237.817 120.084 237.015 120.888 237.015 121.879C237.015 122.87 237.817 123.674 238.806 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 123.674C233.825 123.674 234.627 122.87 234.627 121.879C234.627 120.888 233.825 120.084 232.836 120.084C231.847 120.084 231.045 120.888 231.045 121.879C231.045 122.87 231.847 123.674 232.836 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 123.674C227.855 123.674 228.657 122.87 228.657 121.879C228.657 120.888 227.855 120.084 226.866 120.084C225.877 120.084 225.075 120.888 225.075 121.879C225.075 122.87 225.877 123.674 226.866 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 123.674C221.885 123.674 222.687 122.87 222.687 121.879C222.687 120.888 221.885 120.084 220.896 120.084C219.906 120.084 219.104 120.888 219.104 121.879C219.104 122.87 219.906 123.674 220.896 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 123.674C215.915 123.674 216.716 122.87 216.716 121.879C216.716 120.888 215.915 120.084 214.925 120.084C213.936 120.084 213.134 120.888 213.134 121.879C213.134 122.87 213.936 123.674 214.925 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 123.674C209.944 123.674 210.746 122.87 210.746 121.879C210.746 120.888 209.944 120.084 208.955 120.084C207.966 120.084 207.164 120.888 207.164 121.879C207.164 122.87 207.966 123.674 208.955 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 123.674C203.974 123.674 204.776 122.87 204.776 121.879C204.776 120.888 203.974 120.084 202.985 120.084C201.996 120.084 201.194 120.888 201.194 121.879C201.194 122.87 201.996 123.674 202.985 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 123.674C198.004 123.674 198.806 122.87 198.806 121.879C198.806 120.888 198.004 120.084 197.015 120.084C196.026 120.084 195.224 120.888 195.224 121.879C195.224 122.87 196.026 123.674 197.015 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 123.674C192.034 123.674 192.836 122.87 192.836 121.879C192.836 120.888 192.034 120.084 191.045 120.084C190.056 120.084 189.254 120.888 189.254 121.879C189.254 122.87 190.056 123.674 191.045 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 123.674C186.064 123.674 186.866 122.87 186.866 121.879C186.866 120.888 186.064 120.084 185.075 120.084C184.085 120.084 183.284 120.888 183.284 121.879C183.284 122.87 184.085 123.674 185.075 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 123.674C180.094 123.674 180.895 122.87 180.895 121.879C180.895 120.888 180.094 120.084 179.104 120.084C178.115 120.084 177.313 120.888 177.313 121.879C177.313 122.87 178.115 123.674 179.104 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 123.674C174.123 123.674 174.925 122.87 174.925 121.879C174.925 120.888 174.123 120.084 173.134 120.084C172.145 120.084 171.343 120.888 171.343 121.879C171.343 122.87 172.145 123.674 173.134 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 123.674C168.153 123.674 168.955 122.87 168.955 121.879C168.955 120.888 168.153 120.084 167.164 120.084C166.175 120.084 165.373 120.888 165.373 121.879C165.373 122.87 166.175 123.674 167.164 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 123.674C162.183 123.674 162.985 122.87 162.985 121.879C162.985 120.888 162.183 120.084 161.194 120.084C160.205 120.084 159.403 120.888 159.403 121.879C159.403 122.87 160.205 123.674 161.194 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 123.674C156.213 123.674 157.015 122.87 157.015 121.879C157.015 120.888 156.213 120.084 155.224 120.084C154.235 120.084 153.433 120.888 153.433 121.879C153.433 122.87 154.235 123.674 155.224 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 123.674C150.243 123.674 151.045 122.87 151.045 121.879C151.045 120.888 150.243 120.084 149.254 120.084C148.265 120.084 147.463 120.888 147.463 121.879C147.463 122.87 148.265 123.674 149.254 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 123.674C144.273 123.674 145.075 122.87 145.075 121.879C145.075 120.888 144.273 120.084 143.284 120.084C142.294 120.084 141.493 120.888 141.493 121.879C141.493 122.87 142.294 123.674 143.284 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 123.674C138.303 123.674 139.104 122.87 139.104 121.879C139.104 120.888 138.303 120.084 137.313 120.084C136.324 120.084 135.522 120.888 135.522 121.879C135.522 122.87 136.324 123.674 137.313 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 123.674C132.332 123.674 133.134 122.87 133.134 121.879C133.134 120.888 132.332 120.084 131.343 120.084C130.354 120.084 129.552 120.888 129.552 121.879C129.552 122.87 130.354 123.674 131.343 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 123.674C126.362 123.674 127.164 122.87 127.164 121.879C127.164 120.888 126.362 120.084 125.373 120.084C124.384 120.084 123.582 120.888 123.582 121.879C123.582 122.87 124.384 123.674 125.373 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 123.674C120.392 123.674 121.194 122.87 121.194 121.879C121.194 120.888 120.392 120.084 119.403 120.084C118.414 120.084 117.612 120.888 117.612 121.879C117.612 122.87 118.414 123.674 119.403 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 123.674C114.422 123.674 115.224 122.87 115.224 121.879C115.224 120.888 114.422 120.084 113.433 120.084C112.444 120.084 111.642 120.888 111.642 121.879C111.642 122.87 112.444 123.674 113.433 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 123.674C108.452 123.674 109.254 122.87 109.254 121.879C109.254 120.888 108.452 120.084 107.463 120.084C106.474 120.084 105.672 120.888 105.672 121.879C105.672 122.87 106.474 123.674 107.463 123.674Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 117.691C347.258 117.691 348.06 116.888 348.06 115.897C348.06 114.906 347.258 114.102 346.269 114.102C345.279 114.102 344.478 114.906 344.478 115.897C344.478 116.888 345.279 117.691 346.269 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 117.691C341.288 117.691 342.09 116.888 342.09 115.897C342.09 114.906 341.288 114.102 340.299 114.102C339.309 114.102 338.507 114.906 338.507 115.897C338.507 116.888 339.309 117.691 340.299 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 117.691C335.318 117.691 336.119 116.888 336.119 115.897C336.119 114.906 335.318 114.102 334.328 114.102C333.339 114.102 332.537 114.906 332.537 115.897C332.537 116.888 333.339 117.691 334.328 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 117.691C329.347 117.691 330.149 116.888 330.149 115.897C330.149 114.906 329.347 114.102 328.358 114.102C327.369 114.102 326.567 114.906 326.567 115.897C326.567 116.888 327.369 117.691 328.358 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 117.691C323.377 117.691 324.179 116.888 324.179 115.897C324.179 114.906 323.377 114.102 322.388 114.102C321.399 114.102 320.597 114.906 320.597 115.897C320.597 116.888 321.399 117.691 322.388 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 117.691C317.407 117.691 318.209 116.888 318.209 115.897C318.209 114.906 317.407 114.102 316.418 114.102C315.429 114.102 314.627 114.906 314.627 115.897C314.627 116.888 315.429 117.691 316.418 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 117.691C311.437 117.691 312.239 116.888 312.239 115.897C312.239 114.906 311.437 114.102 310.448 114.102C309.459 114.102 308.657 114.906 308.657 115.897C308.657 116.888 309.459 117.691 310.448 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 117.691C305.467 117.691 306.269 116.888 306.269 115.897C306.269 114.906 305.467 114.102 304.478 114.102C303.488 114.102 302.687 114.906 302.687 115.897C302.687 116.888 303.488 117.691 304.478 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 117.691C299.497 117.691 300.299 116.888 300.299 115.897C300.299 114.906 299.497 114.102 298.507 114.102C297.518 114.102 296.716 114.906 296.716 115.897C296.716 116.888 297.518 117.691 298.507 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 117.691C293.526 117.691 294.328 116.888 294.328 115.897C294.328 114.906 293.526 114.102 292.537 114.102C291.548 114.102 290.746 114.906 290.746 115.897C290.746 116.888 291.548 117.691 292.537 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 117.691C287.556 117.691 288.358 116.888 288.358 115.897C288.358 114.906 287.556 114.102 286.567 114.102C285.578 114.102 284.776 114.906 284.776 115.897C284.776 116.888 285.578 117.691 286.567 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 117.691C281.586 117.691 282.388 116.888 282.388 115.897C282.388 114.906 281.586 114.102 280.597 114.102C279.608 114.102 278.806 114.906 278.806 115.897C278.806 116.888 279.608 117.691 280.597 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 117.691C275.616 117.691 276.418 116.888 276.418 115.897C276.418 114.906 275.616 114.102 274.627 114.102C273.638 114.102 272.836 114.906 272.836 115.897C272.836 116.888 273.638 117.691 274.627 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 117.691C263.676 117.691 264.478 116.888 264.478 115.897C264.478 114.906 263.676 114.102 262.687 114.102C261.697 114.102 260.896 114.906 260.896 115.897C260.896 116.888 261.697 117.691 262.687 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 117.691C257.706 117.691 258.507 116.888 258.507 115.897C258.507 114.906 257.706 114.102 256.716 114.102C255.727 114.102 254.925 114.906 254.925 115.897C254.925 116.888 255.727 117.691 256.716 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 117.691C251.735 117.691 252.537 116.888 252.537 115.897C252.537 114.906 251.735 114.102 250.746 114.102C249.757 114.102 248.955 114.906 248.955 115.897C248.955 116.888 249.757 117.691 250.746 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 117.691C245.765 117.691 246.567 116.888 246.567 115.897C246.567 114.906 245.765 114.102 244.776 114.102C243.787 114.102 242.985 114.906 242.985 115.897C242.985 116.888 243.787 117.691 244.776 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 117.691C239.795 117.691 240.597 116.888 240.597 115.897C240.597 114.906 239.795 114.102 238.806 114.102C237.817 114.102 237.015 114.906 237.015 115.897C237.015 116.888 237.817 117.691 238.806 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 117.691C233.825 117.691 234.627 116.888 234.627 115.897C234.627 114.906 233.825 114.102 232.836 114.102C231.847 114.102 231.045 114.906 231.045 115.897C231.045 116.888 231.847 117.691 232.836 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 117.691C227.855 117.691 228.657 116.888 228.657 115.897C228.657 114.906 227.855 114.102 226.866 114.102C225.877 114.102 225.075 114.906 225.075 115.897C225.075 116.888 225.877 117.691 226.866 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 117.691C221.885 117.691 222.687 116.888 222.687 115.897C222.687 114.906 221.885 114.102 220.896 114.102C219.906 114.102 219.104 114.906 219.104 115.897C219.104 116.888 219.906 117.691 220.896 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 117.691C215.915 117.691 216.716 116.888 216.716 115.897C216.716 114.906 215.915 114.102 214.925 114.102C213.936 114.102 213.134 114.906 213.134 115.897C213.134 116.888 213.936 117.691 214.925 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 117.691C209.944 117.691 210.746 116.888 210.746 115.897C210.746 114.906 209.944 114.102 208.955 114.102C207.966 114.102 207.164 114.906 207.164 115.897C207.164 116.888 207.966 117.691 208.955 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 117.691C203.974 117.691 204.776 116.888 204.776 115.897C204.776 114.906 203.974 114.102 202.985 114.102C201.996 114.102 201.194 114.906 201.194 115.897C201.194 116.888 201.996 117.691 202.985 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 117.691C198.004 117.691 198.806 116.888 198.806 115.897C198.806 114.906 198.004 114.102 197.015 114.102C196.026 114.102 195.224 114.906 195.224 115.897C195.224 116.888 196.026 117.691 197.015 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 117.691C192.034 117.691 192.836 116.888 192.836 115.897C192.836 114.906 192.034 114.102 191.045 114.102C190.056 114.102 189.254 114.906 189.254 115.897C189.254 116.888 190.056 117.691 191.045 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 117.691C186.064 117.691 186.866 116.888 186.866 115.897C186.866 114.906 186.064 114.102 185.075 114.102C184.085 114.102 183.284 114.906 183.284 115.897C183.284 116.888 184.085 117.691 185.075 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 117.691C180.094 117.691 180.895 116.888 180.895 115.897C180.895 114.906 180.094 114.102 179.104 114.102C178.115 114.102 177.313 114.906 177.313 115.897C177.313 116.888 178.115 117.691 179.104 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 117.691C174.123 117.691 174.925 116.888 174.925 115.897C174.925 114.906 174.123 114.102 173.134 114.102C172.145 114.102 171.343 114.906 171.343 115.897C171.343 116.888 172.145 117.691 173.134 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 117.691C168.153 117.691 168.955 116.888 168.955 115.897C168.955 114.906 168.153 114.102 167.164 114.102C166.175 114.102 165.373 114.906 165.373 115.897C165.373 116.888 166.175 117.691 167.164 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 117.691C162.183 117.691 162.985 116.888 162.985 115.897C162.985 114.906 162.183 114.102 161.194 114.102C160.205 114.102 159.403 114.906 159.403 115.897C159.403 116.888 160.205 117.691 161.194 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 117.691C156.213 117.691 157.015 116.888 157.015 115.897C157.015 114.906 156.213 114.102 155.224 114.102C154.235 114.102 153.433 114.906 153.433 115.897C153.433 116.888 154.235 117.691 155.224 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 117.691C150.243 117.691 151.045 116.888 151.045 115.897C151.045 114.906 150.243 114.102 149.254 114.102C148.265 114.102 147.463 114.906 147.463 115.897C147.463 116.888 148.265 117.691 149.254 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 117.691C144.273 117.691 145.075 116.888 145.075 115.897C145.075 114.906 144.273 114.102 143.284 114.102C142.294 114.102 141.493 114.906 141.493 115.897C141.493 116.888 142.294 117.691 143.284 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 117.691C138.303 117.691 139.104 116.888 139.104 115.897C139.104 114.906 138.303 114.102 137.313 114.102C136.324 114.102 135.522 114.906 135.522 115.897C135.522 116.888 136.324 117.691 137.313 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 117.691C132.332 117.691 133.134 116.888 133.134 115.897C133.134 114.906 132.332 114.102 131.343 114.102C130.354 114.102 129.552 114.906 129.552 115.897C129.552 116.888 130.354 117.691 131.343 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 117.691C126.362 117.691 127.164 116.888 127.164 115.897C127.164 114.906 126.362 114.102 125.373 114.102C124.384 114.102 123.582 114.906 123.582 115.897C123.582 116.888 124.384 117.691 125.373 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 117.691C120.392 117.691 121.194 116.888 121.194 115.897C121.194 114.906 120.392 114.102 119.403 114.102C118.414 114.102 117.612 114.906 117.612 115.897C117.612 116.888 118.414 117.691 119.403 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 117.691C114.422 117.691 115.224 116.888 115.224 115.897C115.224 114.906 114.422 114.102 113.433 114.102C112.444 114.102 111.642 114.906 111.642 115.897C111.642 116.888 112.444 117.691 113.433 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 117.691C108.452 117.691 109.254 116.888 109.254 115.897C109.254 114.906 108.452 114.102 107.463 114.102C106.474 114.102 105.672 114.906 105.672 115.897C105.672 116.888 106.474 117.691 107.463 117.691Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 111.709C335.318 111.709 336.119 110.906 336.119 109.915C336.119 108.923 335.318 108.12 334.328 108.12C333.339 108.12 332.537 108.923 332.537 109.915C332.537 110.906 333.339 111.709 334.328 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 111.709C329.347 111.709 330.149 110.906 330.149 109.915C330.149 108.923 329.347 108.12 328.358 108.12C327.369 108.12 326.567 108.923 326.567 109.915C326.567 110.906 327.369 111.709 328.358 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 111.709C323.377 111.709 324.179 110.906 324.179 109.915C324.179 108.923 323.377 108.12 322.388 108.12C321.399 108.12 320.597 108.923 320.597 109.915C320.597 110.906 321.399 111.709 322.388 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 111.709C317.407 111.709 318.209 110.906 318.209 109.915C318.209 108.923 317.407 108.12 316.418 108.12C315.429 108.12 314.627 108.923 314.627 109.915C314.627 110.906 315.429 111.709 316.418 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 111.709C311.437 111.709 312.239 110.906 312.239 109.915C312.239 108.923 311.437 108.12 310.448 108.12C309.459 108.12 308.657 108.923 308.657 109.915C308.657 110.906 309.459 111.709 310.448 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 111.709C305.467 111.709 306.269 110.906 306.269 109.915C306.269 108.923 305.467 108.12 304.478 108.12C303.488 108.12 302.687 108.923 302.687 109.915C302.687 110.906 303.488 111.709 304.478 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 111.709C299.497 111.709 300.299 110.906 300.299 109.915C300.299 108.923 299.497 108.12 298.507 108.12C297.518 108.12 296.716 108.923 296.716 109.915C296.716 110.906 297.518 111.709 298.507 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 111.709C293.526 111.709 294.328 110.906 294.328 109.915C294.328 108.923 293.526 108.12 292.537 108.12C291.548 108.12 290.746 108.923 290.746 109.915C290.746 110.906 291.548 111.709 292.537 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 111.709C287.556 111.709 288.358 110.906 288.358 109.915C288.358 108.923 287.556 108.12 286.567 108.12C285.578 108.12 284.776 108.923 284.776 109.915C284.776 110.906 285.578 111.709 286.567 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 111.709C281.586 111.709 282.388 110.906 282.388 109.915C282.388 108.923 281.586 108.12 280.597 108.12C279.608 108.12 278.806 108.923 278.806 109.915C278.806 110.906 279.608 111.709 280.597 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 111.709C275.616 111.709 276.418 110.906 276.418 109.915C276.418 108.923 275.616 108.12 274.627 108.12C273.638 108.12 272.836 108.923 272.836 109.915C272.836 110.906 273.638 111.709 274.627 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 111.709C263.676 111.709 264.478 110.906 264.478 109.915C264.478 108.923 263.676 108.12 262.687 108.12C261.697 108.12 260.896 108.923 260.896 109.915C260.896 110.906 261.697 111.709 262.687 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 111.709C257.706 111.709 258.507 110.906 258.507 109.915C258.507 108.923 257.706 108.12 256.716 108.12C255.727 108.12 254.925 108.923 254.925 109.915C254.925 110.906 255.727 111.709 256.716 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 111.709C251.735 111.709 252.537 110.906 252.537 109.915C252.537 108.923 251.735 108.12 250.746 108.12C249.757 108.12 248.955 108.923 248.955 109.915C248.955 110.906 249.757 111.709 250.746 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 111.709C245.765 111.709 246.567 110.906 246.567 109.915C246.567 108.923 245.765 108.12 244.776 108.12C243.787 108.12 242.985 108.923 242.985 109.915C242.985 110.906 243.787 111.709 244.776 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 111.709C239.795 111.709 240.597 110.906 240.597 109.915C240.597 108.923 239.795 108.12 238.806 108.12C237.817 108.12 237.015 108.923 237.015 109.915C237.015 110.906 237.817 111.709 238.806 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 111.709C233.825 111.709 234.627 110.906 234.627 109.915C234.627 108.923 233.825 108.12 232.836 108.12C231.847 108.12 231.045 108.923 231.045 109.915C231.045 110.906 231.847 111.709 232.836 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 111.709C227.855 111.709 228.657 110.906 228.657 109.915C228.657 108.923 227.855 108.12 226.866 108.12C225.877 108.12 225.075 108.923 225.075 109.915C225.075 110.906 225.877 111.709 226.866 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 111.709C221.885 111.709 222.687 110.906 222.687 109.915C222.687 108.923 221.885 108.12 220.896 108.12C219.906 108.12 219.104 108.923 219.104 109.915C219.104 110.906 219.906 111.709 220.896 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 111.709C215.915 111.709 216.716 110.906 216.716 109.915C216.716 108.923 215.915 108.12 214.925 108.12C213.936 108.12 213.134 108.923 213.134 109.915C213.134 110.906 213.936 111.709 214.925 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 111.709C209.944 111.709 210.746 110.906 210.746 109.915C210.746 108.923 209.944 108.12 208.955 108.12C207.966 108.12 207.164 108.923 207.164 109.915C207.164 110.906 207.966 111.709 208.955 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 111.709C203.974 111.709 204.776 110.906 204.776 109.915C204.776 108.923 203.974 108.12 202.985 108.12C201.996 108.12 201.194 108.923 201.194 109.915C201.194 110.906 201.996 111.709 202.985 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 111.709C198.004 111.709 198.806 110.906 198.806 109.915C198.806 108.923 198.004 108.12 197.015 108.12C196.026 108.12 195.224 108.923 195.224 109.915C195.224 110.906 196.026 111.709 197.015 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 111.709C192.034 111.709 192.836 110.906 192.836 109.915C192.836 108.923 192.034 108.12 191.045 108.12C190.056 108.12 189.254 108.923 189.254 109.915C189.254 110.906 190.056 111.709 191.045 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 111.709C186.064 111.709 186.866 110.906 186.866 109.915C186.866 108.923 186.064 108.12 185.075 108.12C184.085 108.12 183.284 108.923 183.284 109.915C183.284 110.906 184.085 111.709 185.075 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 111.709C180.094 111.709 180.895 110.906 180.895 109.915C180.895 108.923 180.094 108.12 179.104 108.12C178.115 108.12 177.313 108.923 177.313 109.915C177.313 110.906 178.115 111.709 179.104 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 111.709C174.123 111.709 174.925 110.906 174.925 109.915C174.925 108.923 174.123 108.12 173.134 108.12C172.145 108.12 171.343 108.923 171.343 109.915C171.343 110.906 172.145 111.709 173.134 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 111.709C168.153 111.709 168.955 110.906 168.955 109.915C168.955 108.923 168.153 108.12 167.164 108.12C166.175 108.12 165.373 108.923 165.373 109.915C165.373 110.906 166.175 111.709 167.164 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 111.709C162.183 111.709 162.985 110.906 162.985 109.915C162.985 108.923 162.183 108.12 161.194 108.12C160.205 108.12 159.403 108.923 159.403 109.915C159.403 110.906 160.205 111.709 161.194 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 111.709C156.213 111.709 157.015 110.906 157.015 109.915C157.015 108.923 156.213 108.12 155.224 108.12C154.235 108.12 153.433 108.923 153.433 109.915C153.433 110.906 154.235 111.709 155.224 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 111.709C150.243 111.709 151.045 110.906 151.045 109.915C151.045 108.923 150.243 108.12 149.254 108.12C148.265 108.12 147.463 108.923 147.463 109.915C147.463 110.906 148.265 111.709 149.254 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 111.709C144.273 111.709 145.075 110.906 145.075 109.915C145.075 108.923 144.273 108.12 143.284 108.12C142.294 108.12 141.493 108.923 141.493 109.915C141.493 110.906 142.294 111.709 143.284 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 111.709C138.303 111.709 139.104 110.906 139.104 109.915C139.104 108.923 138.303 108.12 137.313 108.12C136.324 108.12 135.522 108.923 135.522 109.915C135.522 110.906 136.324 111.709 137.313 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 111.709C132.332 111.709 133.134 110.906 133.134 109.915C133.134 108.923 132.332 108.12 131.343 108.12C130.354 108.12 129.552 108.923 129.552 109.915C129.552 110.906 130.354 111.709 131.343 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 111.709C126.362 111.709 127.164 110.906 127.164 109.915C127.164 108.923 126.362 108.12 125.373 108.12C124.384 108.12 123.582 108.923 123.582 109.915C123.582 110.906 124.384 111.709 125.373 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 111.709C120.392 111.709 121.194 110.906 121.194 109.915C121.194 108.923 120.392 108.12 119.403 108.12C118.414 108.12 117.612 108.923 117.612 109.915C117.612 110.906 118.414 111.709 119.403 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 111.709C114.422 111.709 115.224 110.906 115.224 109.915C115.224 108.923 114.422 108.12 113.433 108.12C112.444 108.12 111.642 108.923 111.642 109.915C111.642 110.906 112.444 111.709 113.433 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 111.709C108.452 111.709 109.254 110.906 109.254 109.915C109.254 108.923 108.452 108.12 107.463 108.12C106.474 108.12 105.672 108.923 105.672 109.915C105.672 110.906 106.474 111.709 107.463 111.709Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 105.727C335.318 105.727 336.119 104.923 336.119 103.932C336.119 102.941 335.318 102.137 334.328 102.137C333.339 102.137 332.537 102.941 332.537 103.932C332.537 104.923 333.339 105.727 334.328 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 105.727C329.347 105.727 330.149 104.923 330.149 103.932C330.149 102.941 329.347 102.137 328.358 102.137C327.369 102.137 326.567 102.941 326.567 103.932C326.567 104.923 327.369 105.727 328.358 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 105.727C323.377 105.727 324.179 104.923 324.179 103.932C324.179 102.941 323.377 102.137 322.388 102.137C321.399 102.137 320.597 102.941 320.597 103.932C320.597 104.923 321.399 105.727 322.388 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 105.727C317.407 105.727 318.209 104.923 318.209 103.932C318.209 102.941 317.407 102.137 316.418 102.137C315.429 102.137 314.627 102.941 314.627 103.932C314.627 104.923 315.429 105.727 316.418 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 105.727C311.437 105.727 312.239 104.923 312.239 103.932C312.239 102.941 311.437 102.137 310.448 102.137C309.459 102.137 308.657 102.941 308.657 103.932C308.657 104.923 309.459 105.727 310.448 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 105.727C305.467 105.727 306.269 104.923 306.269 103.932C306.269 102.941 305.467 102.137 304.478 102.137C303.488 102.137 302.687 102.941 302.687 103.932C302.687 104.923 303.488 105.727 304.478 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 105.727C299.497 105.727 300.299 104.923 300.299 103.932C300.299 102.941 299.497 102.137 298.507 102.137C297.518 102.137 296.716 102.941 296.716 103.932C296.716 104.923 297.518 105.727 298.507 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 105.727C293.526 105.727 294.328 104.923 294.328 103.932C294.328 102.941 293.526 102.137 292.537 102.137C291.548 102.137 290.746 102.941 290.746 103.932C290.746 104.923 291.548 105.727 292.537 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 105.727C287.556 105.727 288.358 104.923 288.358 103.932C288.358 102.941 287.556 102.137 286.567 102.137C285.578 102.137 284.776 102.941 284.776 103.932C284.776 104.923 285.578 105.727 286.567 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 105.727C251.735 105.727 252.537 104.923 252.537 103.932C252.537 102.941 251.735 102.137 250.746 102.137C249.757 102.137 248.955 102.941 248.955 103.932C248.955 104.923 249.757 105.727 250.746 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 105.727C245.765 105.727 246.567 104.923 246.567 103.932C246.567 102.941 245.765 102.137 244.776 102.137C243.787 102.137 242.985 102.941 242.985 103.932C242.985 104.923 243.787 105.727 244.776 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 105.727C239.795 105.727 240.597 104.923 240.597 103.932C240.597 102.941 239.795 102.137 238.806 102.137C237.817 102.137 237.015 102.941 237.015 103.932C237.015 104.923 237.817 105.727 238.806 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 105.727C233.825 105.727 234.627 104.923 234.627 103.932C234.627 102.941 233.825 102.137 232.836 102.137C231.847 102.137 231.045 102.941 231.045 103.932C231.045 104.923 231.847 105.727 232.836 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 105.727C227.855 105.727 228.657 104.923 228.657 103.932C228.657 102.941 227.855 102.137 226.866 102.137C225.877 102.137 225.075 102.941 225.075 103.932C225.075 104.923 225.877 105.727 226.866 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 105.727C221.885 105.727 222.687 104.923 222.687 103.932C222.687 102.941 221.885 102.137 220.896 102.137C219.906 102.137 219.104 102.941 219.104 103.932C219.104 104.923 219.906 105.727 220.896 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 105.727C215.915 105.727 216.716 104.923 216.716 103.932C216.716 102.941 215.915 102.137 214.925 102.137C213.936 102.137 213.134 102.941 213.134 103.932C213.134 104.923 213.936 105.727 214.925 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 105.727C209.944 105.727 210.746 104.923 210.746 103.932C210.746 102.941 209.944 102.137 208.955 102.137C207.966 102.137 207.164 102.941 207.164 103.932C207.164 104.923 207.966 105.727 208.955 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 105.727C203.974 105.727 204.776 104.923 204.776 103.932C204.776 102.941 203.974 102.137 202.985 102.137C201.996 102.137 201.194 102.941 201.194 103.932C201.194 104.923 201.996 105.727 202.985 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 105.727C198.004 105.727 198.806 104.923 198.806 103.932C198.806 102.941 198.004 102.137 197.015 102.137C196.026 102.137 195.224 102.941 195.224 103.932C195.224 104.923 196.026 105.727 197.015 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 105.727C192.034 105.727 192.836 104.923 192.836 103.932C192.836 102.941 192.034 102.137 191.045 102.137C190.056 102.137 189.254 102.941 189.254 103.932C189.254 104.923 190.056 105.727 191.045 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 105.727C186.064 105.727 186.866 104.923 186.866 103.932C186.866 102.941 186.064 102.137 185.075 102.137C184.085 102.137 183.284 102.941 183.284 103.932C183.284 104.923 184.085 105.727 185.075 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 105.727C180.094 105.727 180.895 104.923 180.895 103.932C180.895 102.941 180.094 102.137 179.104 102.137C178.115 102.137 177.313 102.941 177.313 103.932C177.313 104.923 178.115 105.727 179.104 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 105.727C174.123 105.727 174.925 104.923 174.925 103.932C174.925 102.941 174.123 102.137 173.134 102.137C172.145 102.137 171.343 102.941 171.343 103.932C171.343 104.923 172.145 105.727 173.134 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 105.727C168.153 105.727 168.955 104.923 168.955 103.932C168.955 102.941 168.153 102.137 167.164 102.137C166.175 102.137 165.373 102.941 165.373 103.932C165.373 104.923 166.175 105.727 167.164 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 105.727C162.183 105.727 162.985 104.923 162.985 103.932C162.985 102.941 162.183 102.137 161.194 102.137C160.205 102.137 159.403 102.941 159.403 103.932C159.403 104.923 160.205 105.727 161.194 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 105.727C156.213 105.727 157.015 104.923 157.015 103.932C157.015 102.941 156.213 102.137 155.224 102.137C154.235 102.137 153.433 102.941 153.433 103.932C153.433 104.923 154.235 105.727 155.224 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 105.727C150.243 105.727 151.045 104.923 151.045 103.932C151.045 102.941 150.243 102.137 149.254 102.137C148.265 102.137 147.463 102.941 147.463 103.932C147.463 104.923 148.265 105.727 149.254 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 105.727C144.273 105.727 145.075 104.923 145.075 103.932C145.075 102.941 144.273 102.137 143.284 102.137C142.294 102.137 141.493 102.941 141.493 103.932C141.493 104.923 142.294 105.727 143.284 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 105.727C138.303 105.727 139.104 104.923 139.104 103.932C139.104 102.941 138.303 102.137 137.313 102.137C136.324 102.137 135.522 102.941 135.522 103.932C135.522 104.923 136.324 105.727 137.313 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 105.727C132.332 105.727 133.134 104.923 133.134 103.932C133.134 102.941 132.332 102.137 131.343 102.137C130.354 102.137 129.552 102.941 129.552 103.932C129.552 104.923 130.354 105.727 131.343 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 105.727C126.362 105.727 127.164 104.923 127.164 103.932C127.164 102.941 126.362 102.137 125.373 102.137C124.384 102.137 123.582 102.941 123.582 103.932C123.582 104.923 124.384 105.727 125.373 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 105.727C120.392 105.727 121.194 104.923 121.194 103.932C121.194 102.941 120.392 102.137 119.403 102.137C118.414 102.137 117.612 102.941 117.612 103.932C117.612 104.923 118.414 105.727 119.403 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 105.727C114.422 105.727 115.224 104.923 115.224 103.932C115.224 102.941 114.422 102.137 113.433 102.137C112.444 102.137 111.642 102.941 111.642 103.932C111.642 104.923 112.444 105.727 113.433 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 105.727C108.452 105.727 109.254 104.923 109.254 103.932C109.254 102.941 108.452 102.137 107.463 102.137C106.474 102.137 105.672 102.941 105.672 103.932C105.672 104.923 106.474 105.727 107.463 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 105.727C102.482 105.727 103.284 104.923 103.284 103.932C103.284 102.941 102.482 102.137 101.493 102.137C100.503 102.137 99.7015 102.941 99.7015 103.932C99.7015 104.923 100.503 105.727 101.493 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M17.3134 105.727C18.3026 105.727 19.1045 104.923 19.1045 103.932C19.1045 102.941 18.3026 102.137 17.3134 102.137C16.3243 102.137 15.5224 102.941 15.5224 103.932C15.5224 104.923 16.3243 105.727 17.3134 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M11.3433 105.727C12.3325 105.727 13.1343 104.923 13.1343 103.932C13.1343 102.941 12.3325 102.137 11.3433 102.137C10.3541 102.137 9.55225 102.941 9.55225 103.932C9.55225 104.923 10.3541 105.727 11.3433 105.727Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 99.7446C335.318 99.7446 336.119 98.9411 336.119 97.95C336.119 96.9588 335.318 96.1553 334.328 96.1553C333.339 96.1553 332.537 96.9588 332.537 97.95C332.537 98.9411 333.339 99.7446 334.328 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 99.7446C329.347 99.7446 330.149 98.9411 330.149 97.95C330.149 96.9588 329.347 96.1553 328.358 96.1553C327.369 96.1553 326.567 96.9588 326.567 97.95C326.567 98.9411 327.369 99.7446 328.358 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 99.7446C323.377 99.7446 324.179 98.9411 324.179 97.95C324.179 96.9588 323.377 96.1553 322.388 96.1553C321.399 96.1553 320.597 96.9588 320.597 97.95C320.597 98.9411 321.399 99.7446 322.388 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 99.7446C317.407 99.7446 318.209 98.9411 318.209 97.95C318.209 96.9588 317.407 96.1553 316.418 96.1553C315.429 96.1553 314.627 96.9588 314.627 97.95C314.627 98.9411 315.429 99.7446 316.418 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 99.7446C311.437 99.7446 312.239 98.9411 312.239 97.95C312.239 96.9588 311.437 96.1553 310.448 96.1553C309.459 96.1553 308.657 96.9588 308.657 97.95C308.657 98.9411 309.459 99.7446 310.448 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 99.7446C305.467 99.7446 306.269 98.9411 306.269 97.95C306.269 96.9588 305.467 96.1553 304.478 96.1553C303.488 96.1553 302.687 96.9588 302.687 97.95C302.687 98.9411 303.488 99.7446 304.478 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 99.7446C299.497 99.7446 300.299 98.9411 300.299 97.95C300.299 96.9588 299.497 96.1553 298.507 96.1553C297.518 96.1553 296.716 96.9588 296.716 97.95C296.716 98.9411 297.518 99.7446 298.507 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 99.7446C293.526 99.7446 294.328 98.9411 294.328 97.95C294.328 96.9588 293.526 96.1553 292.537 96.1553C291.548 96.1553 290.746 96.9588 290.746 97.95C290.746 98.9411 291.548 99.7446 292.537 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 99.7446C239.795 99.7446 240.597 98.9411 240.597 97.95C240.597 96.9588 239.795 96.1553 238.806 96.1553C237.817 96.1553 237.015 96.9588 237.015 97.95C237.015 98.9411 237.817 99.7446 238.806 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 99.7446C233.825 99.7446 234.627 98.9411 234.627 97.95C234.627 96.9588 233.825 96.1553 232.836 96.1553C231.847 96.1553 231.045 96.9588 231.045 97.95C231.045 98.9411 231.847 99.7446 232.836 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 99.7446C227.855 99.7446 228.657 98.9411 228.657 97.95C228.657 96.9588 227.855 96.1553 226.866 96.1553C225.877 96.1553 225.075 96.9588 225.075 97.95C225.075 98.9411 225.877 99.7446 226.866 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 99.7446C221.885 99.7446 222.687 98.9411 222.687 97.95C222.687 96.9588 221.885 96.1553 220.896 96.1553C219.906 96.1553 219.104 96.9588 219.104 97.95C219.104 98.9411 219.906 99.7446 220.896 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 99.7446C215.915 99.7446 216.716 98.9411 216.716 97.95C216.716 96.9588 215.915 96.1553 214.925 96.1553C213.936 96.1553 213.134 96.9588 213.134 97.95C213.134 98.9411 213.936 99.7446 214.925 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 99.7446C209.944 99.7446 210.746 98.9411 210.746 97.95C210.746 96.9588 209.944 96.1553 208.955 96.1553C207.966 96.1553 207.164 96.9588 207.164 97.95C207.164 98.9411 207.966 99.7446 208.955 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 99.7446C203.974 99.7446 204.776 98.9411 204.776 97.95C204.776 96.9588 203.974 96.1553 202.985 96.1553C201.996 96.1553 201.194 96.9588 201.194 97.95C201.194 98.9411 201.996 99.7446 202.985 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 99.7446C198.004 99.7446 198.806 98.9411 198.806 97.95C198.806 96.9588 198.004 96.1553 197.015 96.1553C196.026 96.1553 195.224 96.9588 195.224 97.95C195.224 98.9411 196.026 99.7446 197.015 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 99.7446C192.034 99.7446 192.836 98.9411 192.836 97.95C192.836 96.9588 192.034 96.1553 191.045 96.1553C190.056 96.1553 189.254 96.9588 189.254 97.95C189.254 98.9411 190.056 99.7446 191.045 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 99.7446C186.064 99.7446 186.866 98.9411 186.866 97.95C186.866 96.9588 186.064 96.1553 185.075 96.1553C184.085 96.1553 183.284 96.9588 183.284 97.95C183.284 98.9411 184.085 99.7446 185.075 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 99.7446C180.094 99.7446 180.895 98.9411 180.895 97.95C180.895 96.9588 180.094 96.1553 179.104 96.1553C178.115 96.1553 177.313 96.9588 177.313 97.95C177.313 98.9411 178.115 99.7446 179.104 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 99.7446C174.123 99.7446 174.925 98.9411 174.925 97.95C174.925 96.9588 174.123 96.1553 173.134 96.1553C172.145 96.1553 171.343 96.9588 171.343 97.95C171.343 98.9411 172.145 99.7446 173.134 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 99.7446C168.153 99.7446 168.955 98.9411 168.955 97.95C168.955 96.9588 168.153 96.1553 167.164 96.1553C166.175 96.1553 165.373 96.9588 165.373 97.95C165.373 98.9411 166.175 99.7446 167.164 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 99.7446C162.183 99.7446 162.985 98.9411 162.985 97.95C162.985 96.9588 162.183 96.1553 161.194 96.1553C160.205 96.1553 159.403 96.9588 159.403 97.95C159.403 98.9411 160.205 99.7446 161.194 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 99.7446C156.213 99.7446 157.015 98.9411 157.015 97.95C157.015 96.9588 156.213 96.1553 155.224 96.1553C154.235 96.1553 153.433 96.9588 153.433 97.95C153.433 98.9411 154.235 99.7446 155.224 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 99.7446C150.243 99.7446 151.045 98.9411 151.045 97.95C151.045 96.9588 150.243 96.1553 149.254 96.1553C148.265 96.1553 147.463 96.9588 147.463 97.95C147.463 98.9411 148.265 99.7446 149.254 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 99.7446C144.273 99.7446 145.075 98.9411 145.075 97.95C145.075 96.9588 144.273 96.1553 143.284 96.1553C142.294 96.1553 141.493 96.9588 141.493 97.95C141.493 98.9411 142.294 99.7446 143.284 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 99.7446C138.303 99.7446 139.104 98.9411 139.104 97.95C139.104 96.9588 138.303 96.1553 137.313 96.1553C136.324 96.1553 135.522 96.9588 135.522 97.95C135.522 98.9411 136.324 99.7446 137.313 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 99.7446C132.332 99.7446 133.134 98.9411 133.134 97.95C133.134 96.9588 132.332 96.1553 131.343 96.1553C130.354 96.1553 129.552 96.9588 129.552 97.95C129.552 98.9411 130.354 99.7446 131.343 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 99.7446C126.362 99.7446 127.164 98.9411 127.164 97.95C127.164 96.9588 126.362 96.1553 125.373 96.1553C124.384 96.1553 123.582 96.9588 123.582 97.95C123.582 98.9411 124.384 99.7446 125.373 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 99.7446C120.392 99.7446 121.194 98.9411 121.194 97.95C121.194 96.9588 120.392 96.1553 119.403 96.1553C118.414 96.1553 117.612 96.9588 117.612 97.95C117.612 98.9411 118.414 99.7446 119.403 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 99.7446C114.422 99.7446 115.224 98.9411 115.224 97.95C115.224 96.9588 114.422 96.1553 113.433 96.1553C112.444 96.1553 111.642 96.9588 111.642 97.95C111.642 98.9411 112.444 99.7446 113.433 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 99.7446C108.452 99.7446 109.254 98.9411 109.254 97.95C109.254 96.9588 108.452 96.1553 107.463 96.1553C106.474 96.1553 105.672 96.9588 105.672 97.95C105.672 98.9411 106.474 99.7446 107.463 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 99.7446C102.482 99.7446 103.284 98.9411 103.284 97.95C103.284 96.9588 102.482 96.1553 101.493 96.1553C100.503 96.1553 99.7015 96.9588 99.7015 97.95C99.7015 98.9411 100.503 99.7446 101.493 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M41.791 99.7446C42.7802 99.7446 43.5821 98.9411 43.5821 97.95C43.5821 96.9588 42.7802 96.1553 41.791 96.1553C40.8019 96.1553 40 96.9588 40 97.95C40 98.9411 40.8019 99.7446 41.791 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M29.2538 99.7446C30.2429 99.7446 31.0448 98.9411 31.0448 97.95C31.0448 96.9588 30.2429 96.1553 29.2538 96.1553C28.2646 96.1553 27.4627 96.9588 27.4627 97.95C27.4627 98.9411 28.2646 99.7446 29.2538 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M23.2836 99.7446C24.2728 99.7446 25.0746 98.9411 25.0746 97.95C25.0746 96.9588 24.2728 96.1553 23.2836 96.1553C22.2944 96.1553 21.4926 96.9588 21.4926 97.95C21.4926 98.9411 22.2944 99.7446 23.2836 99.7446Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 93.7625C335.318 93.7625 336.119 92.959 336.119 91.9678C336.119 90.9766 335.318 90.1731 334.328 90.1731C333.339 90.1731 332.537 90.9766 332.537 91.9678C332.537 92.959 333.339 93.7625 334.328 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 93.7625C329.347 93.7625 330.149 92.959 330.149 91.9678C330.149 90.9766 329.347 90.1731 328.358 90.1731C327.369 90.1731 326.567 90.9766 326.567 91.9678C326.567 92.959 327.369 93.7625 328.358 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 93.7625C317.407 93.7625 318.209 92.959 318.209 91.9678C318.209 90.9766 317.407 90.1731 316.418 90.1731C315.429 90.1731 314.627 90.9766 314.627 91.9678C314.627 92.959 315.429 93.7625 316.418 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 93.7625C311.437 93.7625 312.239 92.959 312.239 91.9678C312.239 90.9766 311.437 90.1731 310.448 90.1731C309.459 90.1731 308.657 90.9766 308.657 91.9678C308.657 92.959 309.459 93.7625 310.448 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 93.7625C305.467 93.7625 306.269 92.959 306.269 91.9678C306.269 90.9766 305.467 90.1731 304.478 90.1731C303.488 90.1731 302.687 90.9766 302.687 91.9678C302.687 92.959 303.488 93.7625 304.478 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 93.7625C299.497 93.7625 300.299 92.959 300.299 91.9678C300.299 90.9766 299.497 90.1731 298.507 90.1731C297.518 90.1731 296.716 90.9766 296.716 91.9678C296.716 92.959 297.518 93.7625 298.507 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 93.7625C293.526 93.7625 294.328 92.959 294.328 91.9678C294.328 90.9766 293.526 90.1731 292.537 90.1731C291.548 90.1731 290.746 90.9766 290.746 91.9678C290.746 92.959 291.548 93.7625 292.537 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 93.7625C233.825 93.7625 234.627 92.959 234.627 91.9678C234.627 90.9766 233.825 90.1731 232.836 90.1731C231.847 90.1731 231.045 90.9766 231.045 91.9678C231.045 92.959 231.847 93.7625 232.836 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 93.7625C227.855 93.7625 228.657 92.959 228.657 91.9678C228.657 90.9766 227.855 90.1731 226.866 90.1731C225.877 90.1731 225.075 90.9766 225.075 91.9678C225.075 92.959 225.877 93.7625 226.866 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 93.7625C221.885 93.7625 222.687 92.959 222.687 91.9678C222.687 90.9766 221.885 90.1731 220.896 90.1731C219.906 90.1731 219.104 90.9766 219.104 91.9678C219.104 92.959 219.906 93.7625 220.896 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 93.7625C215.915 93.7625 216.716 92.959 216.716 91.9678C216.716 90.9766 215.915 90.1731 214.925 90.1731C213.936 90.1731 213.134 90.9766 213.134 91.9678C213.134 92.959 213.936 93.7625 214.925 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 93.7625C209.944 93.7625 210.746 92.959 210.746 91.9678C210.746 90.9766 209.944 90.1731 208.955 90.1731C207.966 90.1731 207.164 90.9766 207.164 91.9678C207.164 92.959 207.966 93.7625 208.955 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 93.7625C203.974 93.7625 204.776 92.959 204.776 91.9678C204.776 90.9766 203.974 90.1731 202.985 90.1731C201.996 90.1731 201.194 90.9766 201.194 91.9678C201.194 92.959 201.996 93.7625 202.985 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 93.7625C198.004 93.7625 198.806 92.959 198.806 91.9678C198.806 90.9766 198.004 90.1731 197.015 90.1731C196.026 90.1731 195.224 90.9766 195.224 91.9678C195.224 92.959 196.026 93.7625 197.015 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 93.7625C192.034 93.7625 192.836 92.959 192.836 91.9678C192.836 90.9766 192.034 90.1731 191.045 90.1731C190.056 90.1731 189.254 90.9766 189.254 91.9678C189.254 92.959 190.056 93.7625 191.045 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 93.7625C186.064 93.7625 186.866 92.959 186.866 91.9678C186.866 90.9766 186.064 90.1731 185.075 90.1731C184.085 90.1731 183.284 90.9766 183.284 91.9678C183.284 92.959 184.085 93.7625 185.075 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 93.7625C180.094 93.7625 180.895 92.959 180.895 91.9678C180.895 90.9766 180.094 90.1731 179.104 90.1731C178.115 90.1731 177.313 90.9766 177.313 91.9678C177.313 92.959 178.115 93.7625 179.104 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 93.7625C174.123 93.7625 174.925 92.959 174.925 91.9678C174.925 90.9766 174.123 90.1731 173.134 90.1731C172.145 90.1731 171.343 90.9766 171.343 91.9678C171.343 92.959 172.145 93.7625 173.134 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 93.7625C168.153 93.7625 168.955 92.959 168.955 91.9678C168.955 90.9766 168.153 90.1731 167.164 90.1731C166.175 90.1731 165.373 90.9766 165.373 91.9678C165.373 92.959 166.175 93.7625 167.164 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 93.7625C162.183 93.7625 162.985 92.959 162.985 91.9678C162.985 90.9766 162.183 90.1731 161.194 90.1731C160.205 90.1731 159.403 90.9766 159.403 91.9678C159.403 92.959 160.205 93.7625 161.194 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 93.7625C156.213 93.7625 157.015 92.959 157.015 91.9678C157.015 90.9766 156.213 90.1731 155.224 90.1731C154.235 90.1731 153.433 90.9766 153.433 91.9678C153.433 92.959 154.235 93.7625 155.224 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 93.7625C150.243 93.7625 151.045 92.959 151.045 91.9678C151.045 90.9766 150.243 90.1731 149.254 90.1731C148.265 90.1731 147.463 90.9766 147.463 91.9678C147.463 92.959 148.265 93.7625 149.254 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 93.7625C144.273 93.7625 145.075 92.959 145.075 91.9678C145.075 90.9766 144.273 90.1731 143.284 90.1731C142.294 90.1731 141.493 90.9766 141.493 91.9678C141.493 92.959 142.294 93.7625 143.284 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 93.7625C138.303 93.7625 139.104 92.959 139.104 91.9678C139.104 90.9766 138.303 90.1731 137.313 90.1731C136.324 90.1731 135.522 90.9766 135.522 91.9678C135.522 92.959 136.324 93.7625 137.313 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 93.7625C132.332 93.7625 133.134 92.959 133.134 91.9678C133.134 90.9766 132.332 90.1731 131.343 90.1731C130.354 90.1731 129.552 90.9766 129.552 91.9678C129.552 92.959 130.354 93.7625 131.343 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 93.7625C126.362 93.7625 127.164 92.959 127.164 91.9678C127.164 90.9766 126.362 90.1731 125.373 90.1731C124.384 90.1731 123.582 90.9766 123.582 91.9678C123.582 92.959 124.384 93.7625 125.373 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 93.7625C120.392 93.7625 121.194 92.959 121.194 91.9678C121.194 90.9766 120.392 90.1731 119.403 90.1731C118.414 90.1731 117.612 90.9766 117.612 91.9678C117.612 92.959 118.414 93.7625 119.403 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 93.7625C114.422 93.7625 115.224 92.959 115.224 91.9678C115.224 90.9766 114.422 90.1731 113.433 90.1731C112.444 90.1731 111.642 90.9766 111.642 91.9678C111.642 92.959 112.444 93.7625 113.433 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 93.7625C108.452 93.7625 109.254 92.959 109.254 91.9678C109.254 90.9766 108.452 90.1731 107.463 90.1731C106.474 90.1731 105.672 90.9766 105.672 91.9678C105.672 92.959 106.474 93.7625 107.463 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 93.7625C102.482 93.7625 103.284 92.959 103.284 91.9678C103.284 90.9766 102.482 90.1731 101.493 90.1731C100.503 90.1731 99.7015 90.9766 99.7015 91.9678C99.7015 92.959 100.503 93.7625 101.493 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M47.7612 93.7625C48.7504 93.7625 49.5522 92.959 49.5522 91.9678C49.5522 90.9766 48.7504 90.1731 47.7612 90.1731C46.772 90.1731 45.9702 90.9766 45.9702 91.9678C45.9702 92.959 46.772 93.7625 47.7612 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M41.791 93.7625C42.7802 93.7625 43.5821 92.959 43.5821 91.9678C43.5821 90.9766 42.7802 90.1731 41.791 90.1731C40.8019 90.1731 40 90.9766 40 91.9678C40 92.959 40.8019 93.7625 41.791 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M35.2239 93.7625C36.2131 93.7625 37.015 92.959 37.015 91.9678C37.015 90.9766 36.2131 90.1731 35.2239 90.1731C34.2347 90.1731 33.4329 90.9766 33.4329 91.9678C33.4329 92.959 34.2347 93.7625 35.2239 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M29.2538 93.7625C30.2429 93.7625 31.0448 92.959 31.0448 91.9678C31.0448 90.9766 30.2429 90.1731 29.2538 90.1731C28.2646 90.1731 27.4627 90.9766 27.4627 91.9678C27.4627 92.959 28.2646 93.7625 29.2538 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M23.2836 93.7625C24.2728 93.7625 25.0746 92.959 25.0746 91.9678C25.0746 90.9766 24.2728 90.1731 23.2836 90.1731C22.2944 90.1731 21.4926 90.9766 21.4926 91.9678C21.4926 92.959 22.2944 93.7625 23.2836 93.7625Z"
                fill="#FBC98E"
              />
              <path
                d="M400 87.78C400.989 87.78 401.791 86.9765 401.791 85.9854C401.791 84.9942 400.989 84.1907 400 84.1907C399.011 84.1907 398.209 84.9942 398.209 85.9854C398.209 86.9765 399.011 87.78 400 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 87.78C395.019 87.78 395.821 86.9765 395.821 85.9854C395.821 84.9942 395.019 84.1907 394.03 84.1907C393.041 84.1907 392.239 84.9942 392.239 85.9854C392.239 86.9765 393.041 87.78 394.03 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 87.78C317.407 87.78 318.209 86.9765 318.209 85.9854C318.209 84.9942 317.407 84.1907 316.418 84.1907C315.429 84.1907 314.627 84.9942 314.627 85.9854C314.627 86.9765 315.429 87.78 316.418 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 87.78C311.437 87.78 312.239 86.9765 312.239 85.9854C312.239 84.9942 311.437 84.1907 310.448 84.1907C309.459 84.1907 308.657 84.9942 308.657 85.9854C308.657 86.9765 309.459 87.78 310.448 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 87.78C305.467 87.78 306.269 86.9765 306.269 85.9854C306.269 84.9942 305.467 84.1907 304.478 84.1907C303.488 84.1907 302.687 84.9942 302.687 85.9854C302.687 86.9765 303.488 87.78 304.478 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 87.78C299.497 87.78 300.299 86.9765 300.299 85.9854C300.299 84.9942 299.497 84.1907 298.507 84.1907C297.518 84.1907 296.716 84.9942 296.716 85.9854C296.716 86.9765 297.518 87.78 298.507 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 87.78C239.795 87.78 240.597 86.9765 240.597 85.9854C240.597 84.9942 239.795 84.1907 238.806 84.1907C237.817 84.1907 237.015 84.9942 237.015 85.9854C237.015 86.9765 237.817 87.78 238.806 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 87.78C233.825 87.78 234.627 86.9765 234.627 85.9854C234.627 84.9942 233.825 84.1907 232.836 84.1907C231.847 84.1907 231.045 84.9942 231.045 85.9854C231.045 86.9765 231.847 87.78 232.836 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 87.78C227.855 87.78 228.657 86.9765 228.657 85.9854C228.657 84.9942 227.855 84.1907 226.866 84.1907C225.877 84.1907 225.075 84.9942 225.075 85.9854C225.075 86.9765 225.877 87.78 226.866 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 87.78C221.885 87.78 222.687 86.9765 222.687 85.9854C222.687 84.9942 221.885 84.1907 220.896 84.1907C219.906 84.1907 219.104 84.9942 219.104 85.9854C219.104 86.9765 219.906 87.78 220.896 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 87.78C215.915 87.78 216.716 86.9765 216.716 85.9854C216.716 84.9942 215.915 84.1907 214.925 84.1907C213.936 84.1907 213.134 84.9942 213.134 85.9854C213.134 86.9765 213.936 87.78 214.925 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 87.78C209.944 87.78 210.746 86.9765 210.746 85.9854C210.746 84.9942 209.944 84.1907 208.955 84.1907C207.966 84.1907 207.164 84.9942 207.164 85.9854C207.164 86.9765 207.966 87.78 208.955 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 87.78C203.974 87.78 204.776 86.9765 204.776 85.9854C204.776 84.9942 203.974 84.1907 202.985 84.1907C201.996 84.1907 201.194 84.9942 201.194 85.9854C201.194 86.9765 201.996 87.78 202.985 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 87.78C198.004 87.78 198.806 86.9765 198.806 85.9854C198.806 84.9942 198.004 84.1907 197.015 84.1907C196.026 84.1907 195.224 84.9942 195.224 85.9854C195.224 86.9765 196.026 87.78 197.015 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 87.78C192.034 87.78 192.836 86.9765 192.836 85.9854C192.836 84.9942 192.034 84.1907 191.045 84.1907C190.056 84.1907 189.254 84.9942 189.254 85.9854C189.254 86.9765 190.056 87.78 191.045 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 87.78C186.064 87.78 186.866 86.9765 186.866 85.9854C186.866 84.9942 186.064 84.1907 185.075 84.1907C184.085 84.1907 183.284 84.9942 183.284 85.9854C183.284 86.9765 184.085 87.78 185.075 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 87.78C180.094 87.78 180.895 86.9765 180.895 85.9854C180.895 84.9942 180.094 84.1907 179.104 84.1907C178.115 84.1907 177.313 84.9942 177.313 85.9854C177.313 86.9765 178.115 87.78 179.104 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 87.78C174.123 87.78 174.925 86.9765 174.925 85.9854C174.925 84.9942 174.123 84.1907 173.134 84.1907C172.145 84.1907 171.343 84.9942 171.343 85.9854C171.343 86.9765 172.145 87.78 173.134 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 87.78C168.153 87.78 168.955 86.9765 168.955 85.9854C168.955 84.9942 168.153 84.1907 167.164 84.1907C166.175 84.1907 165.373 84.9942 165.373 85.9854C165.373 86.9765 166.175 87.78 167.164 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 87.78C162.183 87.78 162.985 86.9765 162.985 85.9854C162.985 84.9942 162.183 84.1907 161.194 84.1907C160.205 84.1907 159.403 84.9942 159.403 85.9854C159.403 86.9765 160.205 87.78 161.194 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 87.78C156.213 87.78 157.015 86.9765 157.015 85.9854C157.015 84.9942 156.213 84.1907 155.224 84.1907C154.235 84.1907 153.433 84.9942 153.433 85.9854C153.433 86.9765 154.235 87.78 155.224 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 87.78C150.243 87.78 151.045 86.9765 151.045 85.9854C151.045 84.9942 150.243 84.1907 149.254 84.1907C148.265 84.1907 147.463 84.9942 147.463 85.9854C147.463 86.9765 148.265 87.78 149.254 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 87.78C144.273 87.78 145.075 86.9765 145.075 85.9854C145.075 84.9942 144.273 84.1907 143.284 84.1907C142.294 84.1907 141.493 84.9942 141.493 85.9854C141.493 86.9765 142.294 87.78 143.284 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 87.78C138.303 87.78 139.104 86.9765 139.104 85.9854C139.104 84.9942 138.303 84.1907 137.313 84.1907C136.324 84.1907 135.522 84.9942 135.522 85.9854C135.522 86.9765 136.324 87.78 137.313 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 87.78C132.332 87.78 133.134 86.9765 133.134 85.9854C133.134 84.9942 132.332 84.1907 131.343 84.1907C130.354 84.1907 129.552 84.9942 129.552 85.9854C129.552 86.9765 130.354 87.78 131.343 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 87.78C126.362 87.78 127.164 86.9765 127.164 85.9854C127.164 84.9942 126.362 84.1907 125.373 84.1907C124.384 84.1907 123.582 84.9942 123.582 85.9854C123.582 86.9765 124.384 87.78 125.373 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 87.78C120.392 87.78 121.194 86.9765 121.194 85.9854C121.194 84.9942 120.392 84.1907 119.403 84.1907C118.414 84.1907 117.612 84.9942 117.612 85.9854C117.612 86.9765 118.414 87.78 119.403 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 87.78C114.422 87.78 115.224 86.9765 115.224 85.9854C115.224 84.9942 114.422 84.1907 113.433 84.1907C112.444 84.1907 111.642 84.9942 111.642 85.9854C111.642 86.9765 112.444 87.78 113.433 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 87.78C108.452 87.78 109.254 86.9765 109.254 85.9854C109.254 84.9942 108.452 84.1907 107.463 84.1907C106.474 84.1907 105.672 84.9942 105.672 85.9854C105.672 86.9765 106.474 87.78 107.463 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 87.78C102.482 87.78 103.284 86.9765 103.284 85.9854C103.284 84.9942 102.482 84.1907 101.493 84.1907C100.503 84.1907 99.7015 84.9942 99.7015 85.9854C99.7015 86.9765 100.503 87.78 101.493 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 87.78C96.5116 87.78 97.3134 86.9765 97.3134 85.9854C97.3134 84.9942 96.5116 84.1907 95.5224 84.1907C94.5332 84.1907 93.7314 84.9942 93.7314 85.9854C93.7314 86.9765 94.5332 87.78 95.5224 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 87.78C90.5414 87.78 91.3433 86.9765 91.3433 85.9854C91.3433 84.9942 90.5414 84.1907 89.5522 84.1907C88.563 84.1907 87.7612 84.9942 87.7612 85.9854C87.7612 86.9765 88.563 87.78 89.5522 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 87.78C84.5713 87.78 85.3732 86.9765 85.3732 85.9854C85.3732 84.9942 84.5713 84.1907 83.5821 84.1907C82.593 84.1907 81.7911 84.9942 81.7911 85.9854C81.7911 86.9765 82.593 87.78 83.5821 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M65.6717 87.78C66.6608 87.78 67.4627 86.9765 67.4627 85.9854C67.4627 84.9942 66.6608 84.1907 65.6717 84.1907C64.6825 84.1907 63.8806 84.9942 63.8806 85.9854C63.8806 86.9765 64.6825 87.78 65.6717 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M59.7015 87.78C60.6907 87.78 61.4926 86.9765 61.4926 85.9854C61.4926 84.9942 60.6907 84.1907 59.7015 84.1907C58.7123 84.1907 57.9105 84.9942 57.9105 85.9854C57.9105 86.9765 58.7123 87.78 59.7015 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M53.7314 87.78C54.7205 87.78 55.5224 86.9765 55.5224 85.9854C55.5224 84.9942 54.7205 84.1907 53.7314 84.1907C52.7422 84.1907 51.9403 84.9942 51.9403 85.9854C51.9403 86.9765 52.7422 87.78 53.7314 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M47.7612 87.78C48.7504 87.78 49.5522 86.9765 49.5522 85.9854C49.5522 84.9942 48.7504 84.1907 47.7612 84.1907C46.772 84.1907 45.9702 84.9942 45.9702 85.9854C45.9702 86.9765 46.772 87.78 47.7612 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M41.791 87.78C42.7802 87.78 43.5821 86.9765 43.5821 85.9854C43.5821 84.9942 42.7802 84.1907 41.791 84.1907C40.8019 84.1907 40 84.9942 40 85.9854C40 86.9765 40.8019 87.78 41.791 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M35.2239 87.78C36.2131 87.78 37.015 86.9765 37.015 85.9854C37.015 84.9942 36.2131 84.1907 35.2239 84.1907C34.2347 84.1907 33.4329 84.9942 33.4329 85.9854C33.4329 86.9765 34.2347 87.78 35.2239 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M29.2538 87.78C30.2429 87.78 31.0448 86.9765 31.0448 85.9854C31.0448 84.9942 30.2429 84.1907 29.2538 84.1907C28.2646 84.1907 27.4627 84.9942 27.4627 85.9854C27.4627 86.9765 28.2646 87.78 29.2538 87.78Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 81.7979C406.959 81.7979 407.761 80.9944 407.761 80.0032C407.761 79.012 406.959 78.2085 405.97 78.2085C404.981 78.2085 404.179 79.012 404.179 80.0032C404.179 80.9944 404.981 81.7979 405.97 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M400 81.7979C400.989 81.7979 401.791 80.9944 401.791 80.0032C401.791 79.012 400.989 78.2085 400 78.2085C399.011 78.2085 398.209 79.012 398.209 80.0032C398.209 80.9944 399.011 81.7979 400 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 81.7979C395.019 81.7979 395.821 80.9944 395.821 80.0032C395.821 79.012 395.019 78.2085 394.03 78.2085C393.041 78.2085 392.239 79.012 392.239 80.0032C392.239 80.9944 393.041 81.7979 394.03 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 81.7979C389.049 81.7979 389.851 80.9944 389.851 80.0032C389.851 79.012 389.049 78.2085 388.06 78.2085C387.071 78.2085 386.269 79.012 386.269 80.0032C386.269 80.9944 387.071 81.7979 388.06 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 81.7979C335.318 81.7979 336.119 80.9944 336.119 80.0032C336.119 79.012 335.318 78.2085 334.328 78.2085C333.339 78.2085 332.537 79.012 332.537 80.0032C332.537 80.9944 333.339 81.7979 334.328 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 81.7979C329.347 81.7979 330.149 80.9944 330.149 80.0032C330.149 79.012 329.347 78.2085 328.358 78.2085C327.369 78.2085 326.567 79.012 326.567 80.0032C326.567 80.9944 327.369 81.7979 328.358 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 81.7979C311.437 81.7979 312.239 80.9944 312.239 80.0032C312.239 79.012 311.437 78.2085 310.448 78.2085C309.459 78.2085 308.657 79.012 308.657 80.0032C308.657 80.9944 309.459 81.7979 310.448 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 81.7979C305.467 81.7979 306.269 80.9944 306.269 80.0032C306.269 79.012 305.467 78.2085 304.478 78.2085C303.488 78.2085 302.687 79.012 302.687 80.0032C302.687 80.9944 303.488 81.7979 304.478 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 81.7979C251.735 81.7979 252.537 80.9944 252.537 80.0032C252.537 79.012 251.735 78.2085 250.746 78.2085C249.757 78.2085 248.955 79.012 248.955 80.0032C248.955 80.9944 249.757 81.7979 250.746 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 81.7979C245.765 81.7979 246.567 80.9944 246.567 80.0032C246.567 79.012 245.765 78.2085 244.776 78.2085C243.787 78.2085 242.985 79.012 242.985 80.0032C242.985 80.9944 243.787 81.7979 244.776 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 81.7979C239.795 81.7979 240.597 80.9944 240.597 80.0032C240.597 79.012 239.795 78.2085 238.806 78.2085C237.817 78.2085 237.015 79.012 237.015 80.0032C237.015 80.9944 237.817 81.7979 238.806 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 81.7979C233.825 81.7979 234.627 80.9944 234.627 80.0032C234.627 79.012 233.825 78.2085 232.836 78.2085C231.847 78.2085 231.045 79.012 231.045 80.0032C231.045 80.9944 231.847 81.7979 232.836 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 81.7979C227.855 81.7979 228.657 80.9944 228.657 80.0032C228.657 79.012 227.855 78.2085 226.866 78.2085C225.877 78.2085 225.075 79.012 225.075 80.0032C225.075 80.9944 225.877 81.7979 226.866 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 81.7979C221.885 81.7979 222.687 80.9944 222.687 80.0032C222.687 79.012 221.885 78.2085 220.896 78.2085C219.906 78.2085 219.104 79.012 219.104 80.0032C219.104 80.9944 219.906 81.7979 220.896 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 81.7979C215.915 81.7979 216.716 80.9944 216.716 80.0032C216.716 79.012 215.915 78.2085 214.925 78.2085C213.936 78.2085 213.134 79.012 213.134 80.0032C213.134 80.9944 213.936 81.7979 214.925 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 81.7979C209.944 81.7979 210.746 80.9944 210.746 80.0032C210.746 79.012 209.944 78.2085 208.955 78.2085C207.966 78.2085 207.164 79.012 207.164 80.0032C207.164 80.9944 207.966 81.7979 208.955 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 81.7979C203.974 81.7979 204.776 80.9944 204.776 80.0032C204.776 79.012 203.974 78.2085 202.985 78.2085C201.996 78.2085 201.194 79.012 201.194 80.0032C201.194 80.9944 201.996 81.7979 202.985 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 81.7979C198.004 81.7979 198.806 80.9944 198.806 80.0032C198.806 79.012 198.004 78.2085 197.015 78.2085C196.026 78.2085 195.224 79.012 195.224 80.0032C195.224 80.9944 196.026 81.7979 197.015 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 81.7979C192.034 81.7979 192.836 80.9944 192.836 80.0032C192.836 79.012 192.034 78.2085 191.045 78.2085C190.056 78.2085 189.254 79.012 189.254 80.0032C189.254 80.9944 190.056 81.7979 191.045 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 81.7979C186.064 81.7979 186.866 80.9944 186.866 80.0032C186.866 79.012 186.064 78.2085 185.075 78.2085C184.085 78.2085 183.284 79.012 183.284 80.0032C183.284 80.9944 184.085 81.7979 185.075 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 81.7979C180.094 81.7979 180.895 80.9944 180.895 80.0032C180.895 79.012 180.094 78.2085 179.104 78.2085C178.115 78.2085 177.313 79.012 177.313 80.0032C177.313 80.9944 178.115 81.7979 179.104 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 81.7979C174.123 81.7979 174.925 80.9944 174.925 80.0032C174.925 79.012 174.123 78.2085 173.134 78.2085C172.145 78.2085 171.343 79.012 171.343 80.0032C171.343 80.9944 172.145 81.7979 173.134 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 81.7979C168.153 81.7979 168.955 80.9944 168.955 80.0032C168.955 79.012 168.153 78.2085 167.164 78.2085C166.175 78.2085 165.373 79.012 165.373 80.0032C165.373 80.9944 166.175 81.7979 167.164 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 81.7979C162.183 81.7979 162.985 80.9944 162.985 80.0032C162.985 79.012 162.183 78.2085 161.194 78.2085C160.205 78.2085 159.403 79.012 159.403 80.0032C159.403 80.9944 160.205 81.7979 161.194 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 81.7979C156.213 81.7979 157.015 80.9944 157.015 80.0032C157.015 79.012 156.213 78.2085 155.224 78.2085C154.235 78.2085 153.433 79.012 153.433 80.0032C153.433 80.9944 154.235 81.7979 155.224 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 81.7979C150.243 81.7979 151.045 80.9944 151.045 80.0032C151.045 79.012 150.243 78.2085 149.254 78.2085C148.265 78.2085 147.463 79.012 147.463 80.0032C147.463 80.9944 148.265 81.7979 149.254 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 81.7979C144.273 81.7979 145.075 80.9944 145.075 80.0032C145.075 79.012 144.273 78.2085 143.284 78.2085C142.294 78.2085 141.493 79.012 141.493 80.0032C141.493 80.9944 142.294 81.7979 143.284 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 81.7979C138.303 81.7979 139.104 80.9944 139.104 80.0032C139.104 79.012 138.303 78.2085 137.313 78.2085C136.324 78.2085 135.522 79.012 135.522 80.0032C135.522 80.9944 136.324 81.7979 137.313 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 81.7979C132.332 81.7979 133.134 80.9944 133.134 80.0032C133.134 79.012 132.332 78.2085 131.343 78.2085C130.354 78.2085 129.552 79.012 129.552 80.0032C129.552 80.9944 130.354 81.7979 131.343 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 81.7979C126.362 81.7979 127.164 80.9944 127.164 80.0032C127.164 79.012 126.362 78.2085 125.373 78.2085C124.384 78.2085 123.582 79.012 123.582 80.0032C123.582 80.9944 124.384 81.7979 125.373 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 81.7979C120.392 81.7979 121.194 80.9944 121.194 80.0032C121.194 79.012 120.392 78.2085 119.403 78.2085C118.414 78.2085 117.612 79.012 117.612 80.0032C117.612 80.9944 118.414 81.7979 119.403 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 81.7979C114.422 81.7979 115.224 80.9944 115.224 80.0032C115.224 79.012 114.422 78.2085 113.433 78.2085C112.444 78.2085 111.642 79.012 111.642 80.0032C111.642 80.9944 112.444 81.7979 113.433 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 81.7979C108.452 81.7979 109.254 80.9944 109.254 80.0032C109.254 79.012 108.452 78.2085 107.463 78.2085C106.474 78.2085 105.672 79.012 105.672 80.0032C105.672 80.9944 106.474 81.7979 107.463 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 81.7979C102.482 81.7979 103.284 80.9944 103.284 80.0032C103.284 79.012 102.482 78.2085 101.493 78.2085C100.503 78.2085 99.7015 79.012 99.7015 80.0032C99.7015 80.9944 100.503 81.7979 101.493 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 81.7979C96.5116 81.7979 97.3134 80.9944 97.3134 80.0032C97.3134 79.012 96.5116 78.2085 95.5224 78.2085C94.5332 78.2085 93.7314 79.012 93.7314 80.0032C93.7314 80.9944 94.5332 81.7979 95.5224 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 81.7979C90.5414 81.7979 91.3433 80.9944 91.3433 80.0032C91.3433 79.012 90.5414 78.2085 89.5522 78.2085C88.563 78.2085 87.7612 79.012 87.7612 80.0032C87.7612 80.9944 88.563 81.7979 89.5522 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 81.7979C84.5713 81.7979 85.3732 80.9944 85.3732 80.0032C85.3732 79.012 84.5713 78.2085 83.5821 78.2085C82.593 78.2085 81.7911 79.012 81.7911 80.0032C81.7911 80.9944 82.593 81.7979 83.5821 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M77.612 81.7979C78.6011 81.7979 79.403 80.9944 79.403 80.0032C79.403 79.012 78.6011 78.2085 77.612 78.2085C76.6228 78.2085 75.8209 79.012 75.8209 80.0032C75.8209 80.9944 76.6228 81.7979 77.612 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M71.6418 81.7979C72.631 81.7979 73.4329 80.9944 73.4329 80.0032C73.4329 79.012 72.631 78.2085 71.6418 78.2085C70.6526 78.2085 69.8508 79.012 69.8508 80.0032C69.8508 80.9944 70.6526 81.7979 71.6418 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M65.6717 81.7979C66.6608 81.7979 67.4627 80.9944 67.4627 80.0032C67.4627 79.012 66.6608 78.2085 65.6717 78.2085C64.6825 78.2085 63.8806 79.012 63.8806 80.0032C63.8806 80.9944 64.6825 81.7979 65.6717 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M59.7015 81.7979C60.6907 81.7979 61.4926 80.9944 61.4926 80.0032C61.4926 79.012 60.6907 78.2085 59.7015 78.2085C58.7123 78.2085 57.9105 79.012 57.9105 80.0032C57.9105 80.9944 58.7123 81.7979 59.7015 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M53.7314 81.7979C54.7205 81.7979 55.5224 80.9944 55.5224 80.0032C55.5224 79.012 54.7205 78.2085 53.7314 78.2085C52.7422 78.2085 51.9403 79.012 51.9403 80.0032C51.9403 80.9944 52.7422 81.7979 53.7314 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M47.7612 81.7979C48.7504 81.7979 49.5522 80.9944 49.5522 80.0032C49.5522 79.012 48.7504 78.2085 47.7612 78.2085C46.772 78.2085 45.9702 79.012 45.9702 80.0032C45.9702 80.9944 46.772 81.7979 47.7612 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M41.791 81.7979C42.7802 81.7979 43.5821 80.9944 43.5821 80.0032C43.5821 79.012 42.7802 78.2085 41.791 78.2085C40.8019 78.2085 40 79.012 40 80.0032C40 80.9944 40.8019 81.7979 41.791 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M35.2239 81.7979C36.2131 81.7979 37.015 80.9944 37.015 80.0032C37.015 79.012 36.2131 78.2085 35.2239 78.2085C34.2347 78.2085 33.4329 79.012 33.4329 80.0032C33.4329 80.9944 34.2347 81.7979 35.2239 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M29.2538 81.7979C30.2429 81.7979 31.0448 80.9944 31.0448 80.0032C31.0448 79.012 30.2429 78.2085 29.2538 78.2085C28.2646 78.2085 27.4627 79.012 27.4627 80.0032C27.4627 80.9944 28.2646 81.7979 29.2538 81.7979Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 75.8154C412.929 75.8154 413.731 75.0119 413.731 74.0208C413.731 73.0296 412.929 72.2261 411.94 72.2261C410.951 72.2261 410.149 73.0296 410.149 74.0208C410.149 75.0119 410.951 75.8154 411.94 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 75.8154C406.959 75.8154 407.761 75.0119 407.761 74.0208C407.761 73.0296 406.959 72.2261 405.97 72.2261C404.981 72.2261 404.179 73.0296 404.179 74.0208C404.179 75.0119 404.981 75.8154 405.97 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M400 75.8154C400.989 75.8154 401.791 75.0119 401.791 74.0208C401.791 73.0296 400.989 72.2261 400 72.2261C399.011 72.2261 398.209 73.0296 398.209 74.0208C398.209 75.0119 399.011 75.8154 400 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 75.8154C395.019 75.8154 395.821 75.0119 395.821 74.0208C395.821 73.0296 395.019 72.2261 394.03 72.2261C393.041 72.2261 392.239 73.0296 392.239 74.0208C392.239 75.0119 393.041 75.8154 394.03 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 75.8154C389.049 75.8154 389.851 75.0119 389.851 74.0208C389.851 73.0296 389.049 72.2261 388.06 72.2261C387.071 72.2261 386.269 73.0296 386.269 74.0208C386.269 75.0119 387.071 75.8154 388.06 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 75.8154C341.288 75.8154 342.09 75.0119 342.09 74.0208C342.09 73.0296 341.288 72.2261 340.299 72.2261C339.309 72.2261 338.507 73.0296 338.507 74.0208C338.507 75.0119 339.309 75.8154 340.299 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 75.8154C335.318 75.8154 336.119 75.0119 336.119 74.0208C336.119 73.0296 335.318 72.2261 334.328 72.2261C333.339 72.2261 332.537 73.0296 332.537 74.0208C332.537 75.0119 333.339 75.8154 334.328 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 75.8154C329.347 75.8154 330.149 75.0119 330.149 74.0208C330.149 73.0296 329.347 72.2261 328.358 72.2261C327.369 72.2261 326.567 73.0296 326.567 74.0208C326.567 75.0119 327.369 75.8154 328.358 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 75.8154C323.377 75.8154 324.179 75.0119 324.179 74.0208C324.179 73.0296 323.377 72.2261 322.388 72.2261C321.399 72.2261 320.597 73.0296 320.597 74.0208C320.597 75.0119 321.399 75.8154 322.388 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 75.8154C293.526 75.8154 294.328 75.0119 294.328 74.0208C294.328 73.0296 293.526 72.2261 292.537 72.2261C291.548 72.2261 290.746 73.0296 290.746 74.0208C290.746 75.0119 291.548 75.8154 292.537 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 75.8154C287.556 75.8154 288.358 75.0119 288.358 74.0208C288.358 73.0296 287.556 72.2261 286.567 72.2261C285.578 72.2261 284.776 73.0296 284.776 74.0208C284.776 75.0119 285.578 75.8154 286.567 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 75.8154C281.586 75.8154 282.388 75.0119 282.388 74.0208C282.388 73.0296 281.586 72.2261 280.597 72.2261C279.608 72.2261 278.806 73.0296 278.806 74.0208C278.806 75.0119 279.608 75.8154 280.597 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 75.8154C263.676 75.8154 264.478 75.0119 264.478 74.0208C264.478 73.0296 263.676 72.2261 262.687 72.2261C261.697 72.2261 260.896 73.0296 260.896 74.0208C260.896 75.0119 261.697 75.8154 262.687 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 75.8154C257.706 75.8154 258.507 75.0119 258.507 74.0208C258.507 73.0296 257.706 72.2261 256.716 72.2261C255.727 72.2261 254.925 73.0296 254.925 74.0208C254.925 75.0119 255.727 75.8154 256.716 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 75.8154C251.735 75.8154 252.537 75.0119 252.537 74.0208C252.537 73.0296 251.735 72.2261 250.746 72.2261C249.757 72.2261 248.955 73.0296 248.955 74.0208C248.955 75.0119 249.757 75.8154 250.746 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 75.8154C245.765 75.8154 246.567 75.0119 246.567 74.0208C246.567 73.0296 245.765 72.2261 244.776 72.2261C243.787 72.2261 242.985 73.0296 242.985 74.0208C242.985 75.0119 243.787 75.8154 244.776 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 75.8154C239.795 75.8154 240.597 75.0119 240.597 74.0208C240.597 73.0296 239.795 72.2261 238.806 72.2261C237.817 72.2261 237.015 73.0296 237.015 74.0208C237.015 75.0119 237.817 75.8154 238.806 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 75.8154C233.825 75.8154 234.627 75.0119 234.627 74.0208C234.627 73.0296 233.825 72.2261 232.836 72.2261C231.847 72.2261 231.045 73.0296 231.045 74.0208C231.045 75.0119 231.847 75.8154 232.836 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 75.8154C227.855 75.8154 228.657 75.0119 228.657 74.0208C228.657 73.0296 227.855 72.2261 226.866 72.2261C225.877 72.2261 225.075 73.0296 225.075 74.0208C225.075 75.0119 225.877 75.8154 226.866 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 75.8154C221.885 75.8154 222.687 75.0119 222.687 74.0208C222.687 73.0296 221.885 72.2261 220.896 72.2261C219.906 72.2261 219.104 73.0296 219.104 74.0208C219.104 75.0119 219.906 75.8154 220.896 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 75.8154C215.915 75.8154 216.716 75.0119 216.716 74.0208C216.716 73.0296 215.915 72.2261 214.925 72.2261C213.936 72.2261 213.134 73.0296 213.134 74.0208C213.134 75.0119 213.936 75.8154 214.925 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 75.8154C209.944 75.8154 210.746 75.0119 210.746 74.0208C210.746 73.0296 209.944 72.2261 208.955 72.2261C207.966 72.2261 207.164 73.0296 207.164 74.0208C207.164 75.0119 207.966 75.8154 208.955 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 75.8154C203.974 75.8154 204.776 75.0119 204.776 74.0208C204.776 73.0296 203.974 72.2261 202.985 72.2261C201.996 72.2261 201.194 73.0296 201.194 74.0208C201.194 75.0119 201.996 75.8154 202.985 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 75.8154C198.004 75.8154 198.806 75.0119 198.806 74.0208C198.806 73.0296 198.004 72.2261 197.015 72.2261C196.026 72.2261 195.224 73.0296 195.224 74.0208C195.224 75.0119 196.026 75.8154 197.015 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 75.8154C192.034 75.8154 192.836 75.0119 192.836 74.0208C192.836 73.0296 192.034 72.2261 191.045 72.2261C190.056 72.2261 189.254 73.0296 189.254 74.0208C189.254 75.0119 190.056 75.8154 191.045 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 75.8154C186.064 75.8154 186.866 75.0119 186.866 74.0208C186.866 73.0296 186.064 72.2261 185.075 72.2261C184.085 72.2261 183.284 73.0296 183.284 74.0208C183.284 75.0119 184.085 75.8154 185.075 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 75.8154C180.094 75.8154 180.895 75.0119 180.895 74.0208C180.895 73.0296 180.094 72.2261 179.104 72.2261C178.115 72.2261 177.313 73.0296 177.313 74.0208C177.313 75.0119 178.115 75.8154 179.104 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 75.8154C174.123 75.8154 174.925 75.0119 174.925 74.0208C174.925 73.0296 174.123 72.2261 173.134 72.2261C172.145 72.2261 171.343 73.0296 171.343 74.0208C171.343 75.0119 172.145 75.8154 173.134 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 75.8154C168.153 75.8154 168.955 75.0119 168.955 74.0208C168.955 73.0296 168.153 72.2261 167.164 72.2261C166.175 72.2261 165.373 73.0296 165.373 74.0208C165.373 75.0119 166.175 75.8154 167.164 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 75.8154C162.183 75.8154 162.985 75.0119 162.985 74.0208C162.985 73.0296 162.183 72.2261 161.194 72.2261C160.205 72.2261 159.403 73.0296 159.403 74.0208C159.403 75.0119 160.205 75.8154 161.194 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 75.8154C156.213 75.8154 157.015 75.0119 157.015 74.0208C157.015 73.0296 156.213 72.2261 155.224 72.2261C154.235 72.2261 153.433 73.0296 153.433 74.0208C153.433 75.0119 154.235 75.8154 155.224 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 75.8154C150.243 75.8154 151.045 75.0119 151.045 74.0208C151.045 73.0296 150.243 72.2261 149.254 72.2261C148.265 72.2261 147.463 73.0296 147.463 74.0208C147.463 75.0119 148.265 75.8154 149.254 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 75.8154C144.273 75.8154 145.075 75.0119 145.075 74.0208C145.075 73.0296 144.273 72.2261 143.284 72.2261C142.294 72.2261 141.493 73.0296 141.493 74.0208C141.493 75.0119 142.294 75.8154 143.284 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 75.8154C138.303 75.8154 139.104 75.0119 139.104 74.0208C139.104 73.0296 138.303 72.2261 137.313 72.2261C136.324 72.2261 135.522 73.0296 135.522 74.0208C135.522 75.0119 136.324 75.8154 137.313 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 75.8154C132.332 75.8154 133.134 75.0119 133.134 74.0208C133.134 73.0296 132.332 72.2261 131.343 72.2261C130.354 72.2261 129.552 73.0296 129.552 74.0208C129.552 75.0119 130.354 75.8154 131.343 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 75.8154C126.362 75.8154 127.164 75.0119 127.164 74.0208C127.164 73.0296 126.362 72.2261 125.373 72.2261C124.384 72.2261 123.582 73.0296 123.582 74.0208C123.582 75.0119 124.384 75.8154 125.373 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 75.8154C120.392 75.8154 121.194 75.0119 121.194 74.0208C121.194 73.0296 120.392 72.2261 119.403 72.2261C118.414 72.2261 117.612 73.0296 117.612 74.0208C117.612 75.0119 118.414 75.8154 119.403 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 75.8154C114.422 75.8154 115.224 75.0119 115.224 74.0208C115.224 73.0296 114.422 72.2261 113.433 72.2261C112.444 72.2261 111.642 73.0296 111.642 74.0208C111.642 75.0119 112.444 75.8154 113.433 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 75.8154C108.452 75.8154 109.254 75.0119 109.254 74.0208C109.254 73.0296 108.452 72.2261 107.463 72.2261C106.474 72.2261 105.672 73.0296 105.672 74.0208C105.672 75.0119 106.474 75.8154 107.463 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 75.8154C102.482 75.8154 103.284 75.0119 103.284 74.0208C103.284 73.0296 102.482 72.2261 101.493 72.2261C100.503 72.2261 99.7015 73.0296 99.7015 74.0208C99.7015 75.0119 100.503 75.8154 101.493 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 75.8154C96.5116 75.8154 97.3134 75.0119 97.3134 74.0208C97.3134 73.0296 96.5116 72.2261 95.5224 72.2261C94.5332 72.2261 93.7314 73.0296 93.7314 74.0208C93.7314 75.0119 94.5332 75.8154 95.5224 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 75.8154C90.5414 75.8154 91.3433 75.0119 91.3433 74.0208C91.3433 73.0296 90.5414 72.2261 89.5522 72.2261C88.563 72.2261 87.7612 73.0296 87.7612 74.0208C87.7612 75.0119 88.563 75.8154 89.5522 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 75.8154C84.5713 75.8154 85.3732 75.0119 85.3732 74.0208C85.3732 73.0296 84.5713 72.2261 83.5821 72.2261C82.593 72.2261 81.7911 73.0296 81.7911 74.0208C81.7911 75.0119 82.593 75.8154 83.5821 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M77.612 75.8154C78.6011 75.8154 79.403 75.0119 79.403 74.0208C79.403 73.0296 78.6011 72.2261 77.612 72.2261C76.6228 72.2261 75.8209 73.0296 75.8209 74.0208C75.8209 75.0119 76.6228 75.8154 77.612 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M71.6418 75.8154C72.631 75.8154 73.4329 75.0119 73.4329 74.0208C73.4329 73.0296 72.631 72.2261 71.6418 72.2261C70.6526 72.2261 69.8508 73.0296 69.8508 74.0208C69.8508 75.0119 70.6526 75.8154 71.6418 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M65.6717 75.8154C66.6608 75.8154 67.4627 75.0119 67.4627 74.0208C67.4627 73.0296 66.6608 72.2261 65.6717 72.2261C64.6825 72.2261 63.8806 73.0296 63.8806 74.0208C63.8806 75.0119 64.6825 75.8154 65.6717 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M59.7015 75.8154C60.6907 75.8154 61.4926 75.0119 61.4926 74.0208C61.4926 73.0296 60.6907 72.2261 59.7015 72.2261C58.7123 72.2261 57.9105 73.0296 57.9105 74.0208C57.9105 75.0119 58.7123 75.8154 59.7015 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M53.7314 75.8154C54.7205 75.8154 55.5224 75.0119 55.5224 74.0208C55.5224 73.0296 54.7205 72.2261 53.7314 72.2261C52.7422 72.2261 51.9403 73.0296 51.9403 74.0208C51.9403 75.0119 52.7422 75.8154 53.7314 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M47.7612 75.8154C48.7504 75.8154 49.5522 75.0119 49.5522 74.0208C49.5522 73.0296 48.7504 72.2261 47.7612 72.2261C46.772 72.2261 45.9702 73.0296 45.9702 74.0208C45.9702 75.0119 46.772 75.8154 47.7612 75.8154Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 69.8333C418.9 69.8333 419.701 69.0298 419.701 68.0386C419.701 67.0474 418.9 66.2439 417.91 66.2439C416.921 66.2439 416.119 67.0474 416.119 68.0386C416.119 69.0298 416.921 69.8333 417.91 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 69.8333C412.929 69.8333 413.731 69.0298 413.731 68.0386C413.731 67.0474 412.929 66.2439 411.94 66.2439C410.951 66.2439 410.149 67.0474 410.149 68.0386C410.149 69.0298 410.951 69.8333 411.94 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 69.8333C406.959 69.8333 407.761 69.0298 407.761 68.0386C407.761 67.0474 406.959 66.2439 405.97 66.2439C404.981 66.2439 404.179 67.0474 404.179 68.0386C404.179 69.0298 404.981 69.8333 405.97 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M400 69.8333C400.989 69.8333 401.791 69.0298 401.791 68.0386C401.791 67.0474 400.989 66.2439 400 66.2439C399.011 66.2439 398.209 67.0474 398.209 68.0386C398.209 69.0298 399.011 69.8333 400 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 69.8333C395.019 69.8333 395.821 69.0298 395.821 68.0386C395.821 67.0474 395.019 66.2439 394.03 66.2439C393.041 66.2439 392.239 67.0474 392.239 68.0386C392.239 69.0298 393.041 69.8333 394.03 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 69.8333C389.049 69.8333 389.851 69.0298 389.851 68.0386C389.851 67.0474 389.049 66.2439 388.06 66.2439C387.071 66.2439 386.269 67.0474 386.269 68.0386C386.269 69.0298 387.071 69.8333 388.06 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 69.8333C341.288 69.8333 342.09 69.0298 342.09 68.0386C342.09 67.0474 341.288 66.2439 340.299 66.2439C339.309 66.2439 338.507 67.0474 338.507 68.0386C338.507 69.0298 339.309 69.8333 340.299 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 69.8333C335.318 69.8333 336.119 69.0298 336.119 68.0386C336.119 67.0474 335.318 66.2439 334.328 66.2439C333.339 66.2439 332.537 67.0474 332.537 68.0386C332.537 69.0298 333.339 69.8333 334.328 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 69.8333C329.347 69.8333 330.149 69.0298 330.149 68.0386C330.149 67.0474 329.347 66.2439 328.358 66.2439C327.369 66.2439 326.567 67.0474 326.567 68.0386C326.567 69.0298 327.369 69.8333 328.358 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 69.8333C323.377 69.8333 324.179 69.0298 324.179 68.0386C324.179 67.0474 323.377 66.2439 322.388 66.2439C321.399 66.2439 320.597 67.0474 320.597 68.0386C320.597 69.0298 321.399 69.8333 322.388 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 69.8333C317.407 69.8333 318.209 69.0298 318.209 68.0386C318.209 67.0474 317.407 66.2439 316.418 66.2439C315.429 66.2439 314.627 67.0474 314.627 68.0386C314.627 69.0298 315.429 69.8333 316.418 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 69.8333C311.437 69.8333 312.239 69.0298 312.239 68.0386C312.239 67.0474 311.437 66.2439 310.448 66.2439C309.459 66.2439 308.657 67.0474 308.657 68.0386C308.657 69.0298 309.459 69.8333 310.448 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 69.8333C275.616 69.8333 276.418 69.0298 276.418 68.0386C276.418 67.0474 275.616 66.2439 274.627 66.2439C273.638 66.2439 272.836 67.0474 272.836 68.0386C272.836 69.0298 273.638 69.8333 274.627 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 69.8333C269.646 69.8333 270.448 69.0298 270.448 68.0386C270.448 67.0474 269.646 66.2439 268.657 66.2439C267.668 66.2439 266.866 67.0474 266.866 68.0386C266.866 69.0298 267.668 69.8333 268.657 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 69.8333C263.676 69.8333 264.478 69.0298 264.478 68.0386C264.478 67.0474 263.676 66.2439 262.687 66.2439C261.697 66.2439 260.896 67.0474 260.896 68.0386C260.896 69.0298 261.697 69.8333 262.687 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 69.8333C257.706 69.8333 258.507 69.0298 258.507 68.0386C258.507 67.0474 257.706 66.2439 256.716 66.2439C255.727 66.2439 254.925 67.0474 254.925 68.0386C254.925 69.0298 255.727 69.8333 256.716 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 69.8333C251.735 69.8333 252.537 69.0298 252.537 68.0386C252.537 67.0474 251.735 66.2439 250.746 66.2439C249.757 66.2439 248.955 67.0474 248.955 68.0386C248.955 69.0298 249.757 69.8333 250.746 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 69.8333C245.765 69.8333 246.567 69.0298 246.567 68.0386C246.567 67.0474 245.765 66.2439 244.776 66.2439C243.787 66.2439 242.985 67.0474 242.985 68.0386C242.985 69.0298 243.787 69.8333 244.776 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 69.8333C239.795 69.8333 240.597 69.0298 240.597 68.0386C240.597 67.0474 239.795 66.2439 238.806 66.2439C237.817 66.2439 237.015 67.0474 237.015 68.0386C237.015 69.0298 237.817 69.8333 238.806 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 69.8333C233.825 69.8333 234.627 69.0298 234.627 68.0386C234.627 67.0474 233.825 66.2439 232.836 66.2439C231.847 66.2439 231.045 67.0474 231.045 68.0386C231.045 69.0298 231.847 69.8333 232.836 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 69.8333C227.855 69.8333 228.657 69.0298 228.657 68.0386C228.657 67.0474 227.855 66.2439 226.866 66.2439C225.877 66.2439 225.075 67.0474 225.075 68.0386C225.075 69.0298 225.877 69.8333 226.866 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 69.8333C221.885 69.8333 222.687 69.0298 222.687 68.0386C222.687 67.0474 221.885 66.2439 220.896 66.2439C219.906 66.2439 219.104 67.0474 219.104 68.0386C219.104 69.0298 219.906 69.8333 220.896 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 69.8333C215.915 69.8333 216.716 69.0298 216.716 68.0386C216.716 67.0474 215.915 66.2439 214.925 66.2439C213.936 66.2439 213.134 67.0474 213.134 68.0386C213.134 69.0298 213.936 69.8333 214.925 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 69.8333C209.944 69.8333 210.746 69.0298 210.746 68.0386C210.746 67.0474 209.944 66.2439 208.955 66.2439C207.966 66.2439 207.164 67.0474 207.164 68.0386C207.164 69.0298 207.966 69.8333 208.955 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 69.8333C203.974 69.8333 204.776 69.0298 204.776 68.0386C204.776 67.0474 203.974 66.2439 202.985 66.2439C201.996 66.2439 201.194 67.0474 201.194 68.0386C201.194 69.0298 201.996 69.8333 202.985 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 69.8333C198.004 69.8333 198.806 69.0298 198.806 68.0386C198.806 67.0474 198.004 66.2439 197.015 66.2439C196.026 66.2439 195.224 67.0474 195.224 68.0386C195.224 69.0298 196.026 69.8333 197.015 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 69.8333C192.034 69.8333 192.836 69.0298 192.836 68.0386C192.836 67.0474 192.034 66.2439 191.045 66.2439C190.056 66.2439 189.254 67.0474 189.254 68.0386C189.254 69.0298 190.056 69.8333 191.045 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 69.8333C186.064 69.8333 186.866 69.0298 186.866 68.0386C186.866 67.0474 186.064 66.2439 185.075 66.2439C184.085 66.2439 183.284 67.0474 183.284 68.0386C183.284 69.0298 184.085 69.8333 185.075 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 69.8333C180.094 69.8333 180.895 69.0298 180.895 68.0386C180.895 67.0474 180.094 66.2439 179.104 66.2439C178.115 66.2439 177.313 67.0474 177.313 68.0386C177.313 69.0298 178.115 69.8333 179.104 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 69.8333C174.123 69.8333 174.925 69.0298 174.925 68.0386C174.925 67.0474 174.123 66.2439 173.134 66.2439C172.145 66.2439 171.343 67.0474 171.343 68.0386C171.343 69.0298 172.145 69.8333 173.134 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 69.8333C168.153 69.8333 168.955 69.0298 168.955 68.0386C168.955 67.0474 168.153 66.2439 167.164 66.2439C166.175 66.2439 165.373 67.0474 165.373 68.0386C165.373 69.0298 166.175 69.8333 167.164 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 69.8333C162.183 69.8333 162.985 69.0298 162.985 68.0386C162.985 67.0474 162.183 66.2439 161.194 66.2439C160.205 66.2439 159.403 67.0474 159.403 68.0386C159.403 69.0298 160.205 69.8333 161.194 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 69.8333C156.213 69.8333 157.015 69.0298 157.015 68.0386C157.015 67.0474 156.213 66.2439 155.224 66.2439C154.235 66.2439 153.433 67.0474 153.433 68.0386C153.433 69.0298 154.235 69.8333 155.224 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 69.8333C150.243 69.8333 151.045 69.0298 151.045 68.0386C151.045 67.0474 150.243 66.2439 149.254 66.2439C148.265 66.2439 147.463 67.0474 147.463 68.0386C147.463 69.0298 148.265 69.8333 149.254 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 69.8333C144.273 69.8333 145.075 69.0298 145.075 68.0386C145.075 67.0474 144.273 66.2439 143.284 66.2439C142.294 66.2439 141.493 67.0474 141.493 68.0386C141.493 69.0298 142.294 69.8333 143.284 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 69.8333C138.303 69.8333 139.104 69.0298 139.104 68.0386C139.104 67.0474 138.303 66.2439 137.313 66.2439C136.324 66.2439 135.522 67.0474 135.522 68.0386C135.522 69.0298 136.324 69.8333 137.313 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 69.8333C132.332 69.8333 133.134 69.0298 133.134 68.0386C133.134 67.0474 132.332 66.2439 131.343 66.2439C130.354 66.2439 129.552 67.0474 129.552 68.0386C129.552 69.0298 130.354 69.8333 131.343 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 69.8333C126.362 69.8333 127.164 69.0298 127.164 68.0386C127.164 67.0474 126.362 66.2439 125.373 66.2439C124.384 66.2439 123.582 67.0474 123.582 68.0386C123.582 69.0298 124.384 69.8333 125.373 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 69.8333C120.392 69.8333 121.194 69.0298 121.194 68.0386C121.194 67.0474 120.392 66.2439 119.403 66.2439C118.414 66.2439 117.612 67.0474 117.612 68.0386C117.612 69.0298 118.414 69.8333 119.403 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 69.8333C114.422 69.8333 115.224 69.0298 115.224 68.0386C115.224 67.0474 114.422 66.2439 113.433 66.2439C112.444 66.2439 111.642 67.0474 111.642 68.0386C111.642 69.0298 112.444 69.8333 113.433 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 69.8333C108.452 69.8333 109.254 69.0298 109.254 68.0386C109.254 67.0474 108.452 66.2439 107.463 66.2439C106.474 66.2439 105.672 67.0474 105.672 68.0386C105.672 69.0298 106.474 69.8333 107.463 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 69.8333C102.482 69.8333 103.284 69.0298 103.284 68.0386C103.284 67.0474 102.482 66.2439 101.493 66.2439C100.503 66.2439 99.7015 67.0474 99.7015 68.0386C99.7015 69.0298 100.503 69.8333 101.493 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 69.8333C96.5116 69.8333 97.3134 69.0298 97.3134 68.0386C97.3134 67.0474 96.5116 66.2439 95.5224 66.2439C94.5332 66.2439 93.7314 67.0474 93.7314 68.0386C93.7314 69.0298 94.5332 69.8333 95.5224 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 69.8333C90.5414 69.8333 91.3433 69.0298 91.3433 68.0386C91.3433 67.0474 90.5414 66.2439 89.5522 66.2439C88.563 66.2439 87.7612 67.0474 87.7612 68.0386C87.7612 69.0298 88.563 69.8333 89.5522 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 69.8333C84.5713 69.8333 85.3732 69.0298 85.3732 68.0386C85.3732 67.0474 84.5713 66.2439 83.5821 66.2439C82.593 66.2439 81.7911 67.0474 81.7911 68.0386C81.7911 69.0298 82.593 69.8333 83.5821 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M77.612 69.8333C78.6011 69.8333 79.403 69.0298 79.403 68.0386C79.403 67.0474 78.6011 66.2439 77.612 66.2439C76.6228 66.2439 75.8209 67.0474 75.8209 68.0386C75.8209 69.0298 76.6228 69.8333 77.612 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M71.6418 69.8333C72.631 69.8333 73.4329 69.0298 73.4329 68.0386C73.4329 67.0474 72.631 66.2439 71.6418 66.2439C70.6526 66.2439 69.8508 67.0474 69.8508 68.0386C69.8508 69.0298 70.6526 69.8333 71.6418 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M65.6717 69.8333C66.6608 69.8333 67.4627 69.0298 67.4627 68.0386C67.4627 67.0474 66.6608 66.2439 65.6717 66.2439C64.6825 66.2439 63.8806 67.0474 63.8806 68.0386C63.8806 69.0298 64.6825 69.8333 65.6717 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M59.7015 69.8333C60.6907 69.8333 61.4926 69.0298 61.4926 68.0386C61.4926 67.0474 60.6907 66.2439 59.7015 66.2439C58.7123 66.2439 57.9105 67.0474 57.9105 68.0386C57.9105 69.0298 58.7123 69.8333 59.7015 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M53.7314 69.8333C54.7205 69.8333 55.5224 69.0298 55.5224 68.0386C55.5224 67.0474 54.7205 66.2439 53.7314 66.2439C52.7422 66.2439 51.9403 67.0474 51.9403 68.0386C51.9403 69.0298 52.7422 69.8333 53.7314 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M47.7612 69.8333C48.7504 69.8333 49.5522 69.0298 49.5522 68.0386C49.5522 67.0474 48.7504 66.2439 47.7612 66.2439C46.772 66.2439 45.9702 67.0474 45.9702 68.0386C45.9702 69.0298 46.772 69.8333 47.7612 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M41.791 69.8333C42.7802 69.8333 43.5821 69.0298 43.5821 68.0386C43.5821 67.0474 42.7802 66.2439 41.791 66.2439C40.8019 66.2439 40 67.0474 40 68.0386C40 69.0298 40.8019 69.8333 41.791 69.8333Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 63.8511C436.81 63.8511 437.612 63.0476 437.612 62.0564C437.612 61.0652 436.81 60.2617 435.821 60.2617C434.832 60.2617 434.03 61.0652 434.03 62.0564C434.03 63.0476 434.832 63.8511 435.821 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 63.8511C430.84 63.8511 431.642 63.0476 431.642 62.0564C431.642 61.0652 430.84 60.2617 429.851 60.2617C428.862 60.2617 428.06 61.0652 428.06 62.0564C428.06 63.0476 428.862 63.8511 429.851 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 63.8511C424.87 63.8511 425.672 63.0476 425.672 62.0564C425.672 61.0652 424.87 60.2617 423.881 60.2617C422.891 60.2617 422.09 61.0652 422.09 62.0564C422.09 63.0476 422.891 63.8511 423.881 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 63.8511C418.9 63.8511 419.701 63.0476 419.701 62.0564C419.701 61.0652 418.9 60.2617 417.91 60.2617C416.921 60.2617 416.119 61.0652 416.119 62.0564C416.119 63.0476 416.921 63.8511 417.91 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 63.8511C412.929 63.8511 413.731 63.0476 413.731 62.0564C413.731 61.0652 412.929 60.2617 411.94 60.2617C410.951 60.2617 410.149 61.0652 410.149 62.0564C410.149 63.0476 410.951 63.8511 411.94 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 63.8511C406.959 63.8511 407.761 63.0476 407.761 62.0564C407.761 61.0652 406.959 60.2617 405.97 60.2617C404.981 60.2617 404.179 61.0652 404.179 62.0564C404.179 63.0476 404.981 63.8511 405.97 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M400 63.8511C400.989 63.8511 401.791 63.0476 401.791 62.0564C401.791 61.0652 400.989 60.2617 400 60.2617C399.011 60.2617 398.209 61.0652 398.209 62.0564C398.209 63.0476 399.011 63.8511 400 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 63.8511C395.019 63.8511 395.821 63.0476 395.821 62.0564C395.821 61.0652 395.019 60.2617 394.03 60.2617C393.041 60.2617 392.239 61.0652 392.239 62.0564C392.239 63.0476 393.041 63.8511 394.03 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 63.8511C389.049 63.8511 389.851 63.0476 389.851 62.0564C389.851 61.0652 389.049 60.2617 388.06 60.2617C387.071 60.2617 386.269 61.0652 386.269 62.0564C386.269 63.0476 387.071 63.8511 388.06 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 63.8511C353.228 63.8511 354.03 63.0476 354.03 62.0564C354.03 61.0652 353.228 60.2617 352.239 60.2617C351.25 60.2617 350.448 61.0652 350.448 62.0564C350.448 63.0476 351.25 63.8511 352.239 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 63.8511C347.258 63.8511 348.06 63.0476 348.06 62.0564C348.06 61.0652 347.258 60.2617 346.269 60.2617C345.279 60.2617 344.478 61.0652 344.478 62.0564C344.478 63.0476 345.279 63.8511 346.269 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 63.8511C341.288 63.8511 342.09 63.0476 342.09 62.0564C342.09 61.0652 341.288 60.2617 340.299 60.2617C339.309 60.2617 338.507 61.0652 338.507 62.0564C338.507 63.0476 339.309 63.8511 340.299 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 63.8511C335.318 63.8511 336.119 63.0476 336.119 62.0564C336.119 61.0652 335.318 60.2617 334.328 60.2617C333.339 60.2617 332.537 61.0652 332.537 62.0564C332.537 63.0476 333.339 63.8511 334.328 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 63.8511C329.347 63.8511 330.149 63.0476 330.149 62.0564C330.149 61.0652 329.347 60.2617 328.358 60.2617C327.369 60.2617 326.567 61.0652 326.567 62.0564C326.567 63.0476 327.369 63.8511 328.358 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 63.8511C299.497 63.8511 300.299 63.0476 300.299 62.0564C300.299 61.0652 299.497 60.2617 298.507 60.2617C297.518 60.2617 296.716 61.0652 296.716 62.0564C296.716 63.0476 297.518 63.8511 298.507 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 63.8511C293.526 63.8511 294.328 63.0476 294.328 62.0564C294.328 61.0652 293.526 60.2617 292.537 60.2617C291.548 60.2617 290.746 61.0652 290.746 62.0564C290.746 63.0476 291.548 63.8511 292.537 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 63.8511C287.556 63.8511 288.358 63.0476 288.358 62.0564C288.358 61.0652 287.556 60.2617 286.567 60.2617C285.578 60.2617 284.776 61.0652 284.776 62.0564C284.776 63.0476 285.578 63.8511 286.567 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 63.8511C281.586 63.8511 282.388 63.0476 282.388 62.0564C282.388 61.0652 281.586 60.2617 280.597 60.2617C279.608 60.2617 278.806 61.0652 278.806 62.0564C278.806 63.0476 279.608 63.8511 280.597 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 63.8511C275.616 63.8511 276.418 63.0476 276.418 62.0564C276.418 61.0652 275.616 60.2617 274.627 60.2617C273.638 60.2617 272.836 61.0652 272.836 62.0564C272.836 63.0476 273.638 63.8511 274.627 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 63.8511C269.646 63.8511 270.448 63.0476 270.448 62.0564C270.448 61.0652 269.646 60.2617 268.657 60.2617C267.668 60.2617 266.866 61.0652 266.866 62.0564C266.866 63.0476 267.668 63.8511 268.657 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 63.8511C263.676 63.8511 264.478 63.0476 264.478 62.0564C264.478 61.0652 263.676 60.2617 262.687 60.2617C261.697 60.2617 260.896 61.0652 260.896 62.0564C260.896 63.0476 261.697 63.8511 262.687 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 63.8511C257.706 63.8511 258.507 63.0476 258.507 62.0564C258.507 61.0652 257.706 60.2617 256.716 60.2617C255.727 60.2617 254.925 61.0652 254.925 62.0564C254.925 63.0476 255.727 63.8511 256.716 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 63.8511C251.735 63.8511 252.537 63.0476 252.537 62.0564C252.537 61.0652 251.735 60.2617 250.746 60.2617C249.757 60.2617 248.955 61.0652 248.955 62.0564C248.955 63.0476 249.757 63.8511 250.746 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 63.8511C245.765 63.8511 246.567 63.0476 246.567 62.0564C246.567 61.0652 245.765 60.2617 244.776 60.2617C243.787 60.2617 242.985 61.0652 242.985 62.0564C242.985 63.0476 243.787 63.8511 244.776 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 63.8511C239.795 63.8511 240.597 63.0476 240.597 62.0564C240.597 61.0652 239.795 60.2617 238.806 60.2617C237.817 60.2617 237.015 61.0652 237.015 62.0564C237.015 63.0476 237.817 63.8511 238.806 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 63.8511C233.825 63.8511 234.627 63.0476 234.627 62.0564C234.627 61.0652 233.825 60.2617 232.836 60.2617C231.847 60.2617 231.045 61.0652 231.045 62.0564C231.045 63.0476 231.847 63.8511 232.836 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 63.8511C227.855 63.8511 228.657 63.0476 228.657 62.0564C228.657 61.0652 227.855 60.2617 226.866 60.2617C225.877 60.2617 225.075 61.0652 225.075 62.0564C225.075 63.0476 225.877 63.8511 226.866 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 63.8511C221.885 63.8511 222.687 63.0476 222.687 62.0564C222.687 61.0652 221.885 60.2617 220.896 60.2617C219.906 60.2617 219.104 61.0652 219.104 62.0564C219.104 63.0476 219.906 63.8511 220.896 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 63.8511C215.915 63.8511 216.716 63.0476 216.716 62.0564C216.716 61.0652 215.915 60.2617 214.925 60.2617C213.936 60.2617 213.134 61.0652 213.134 62.0564C213.134 63.0476 213.936 63.8511 214.925 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 63.8511C209.944 63.8511 210.746 63.0476 210.746 62.0564C210.746 61.0652 209.944 60.2617 208.955 60.2617C207.966 60.2617 207.164 61.0652 207.164 62.0564C207.164 63.0476 207.966 63.8511 208.955 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 63.8511C203.974 63.8511 204.776 63.0476 204.776 62.0564C204.776 61.0652 203.974 60.2617 202.985 60.2617C201.996 60.2617 201.194 61.0652 201.194 62.0564C201.194 63.0476 201.996 63.8511 202.985 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 63.8511C198.004 63.8511 198.806 63.0476 198.806 62.0564C198.806 61.0652 198.004 60.2617 197.015 60.2617C196.026 60.2617 195.224 61.0652 195.224 62.0564C195.224 63.0476 196.026 63.8511 197.015 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 63.8511C192.034 63.8511 192.836 63.0476 192.836 62.0564C192.836 61.0652 192.034 60.2617 191.045 60.2617C190.056 60.2617 189.254 61.0652 189.254 62.0564C189.254 63.0476 190.056 63.8511 191.045 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 63.8511C186.064 63.8511 186.866 63.0476 186.866 62.0564C186.866 61.0652 186.064 60.2617 185.075 60.2617C184.085 60.2617 183.284 61.0652 183.284 62.0564C183.284 63.0476 184.085 63.8511 185.075 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 63.8511C180.094 63.8511 180.895 63.0476 180.895 62.0564C180.895 61.0652 180.094 60.2617 179.104 60.2617C178.115 60.2617 177.313 61.0652 177.313 62.0564C177.313 63.0476 178.115 63.8511 179.104 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 63.8511C174.123 63.8511 174.925 63.0476 174.925 62.0564C174.925 61.0652 174.123 60.2617 173.134 60.2617C172.145 60.2617 171.343 61.0652 171.343 62.0564C171.343 63.0476 172.145 63.8511 173.134 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 63.8511C168.153 63.8511 168.955 63.0476 168.955 62.0564C168.955 61.0652 168.153 60.2617 167.164 60.2617C166.175 60.2617 165.373 61.0652 165.373 62.0564C165.373 63.0476 166.175 63.8511 167.164 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 63.8511C162.183 63.8511 162.985 63.0476 162.985 62.0564C162.985 61.0652 162.183 60.2617 161.194 60.2617C160.205 60.2617 159.403 61.0652 159.403 62.0564C159.403 63.0476 160.205 63.8511 161.194 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 63.8511C156.213 63.8511 157.015 63.0476 157.015 62.0564C157.015 61.0652 156.213 60.2617 155.224 60.2617C154.235 60.2617 153.433 61.0652 153.433 62.0564C153.433 63.0476 154.235 63.8511 155.224 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 63.8511C150.243 63.8511 151.045 63.0476 151.045 62.0564C151.045 61.0652 150.243 60.2617 149.254 60.2617C148.265 60.2617 147.463 61.0652 147.463 62.0564C147.463 63.0476 148.265 63.8511 149.254 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 63.8511C144.273 63.8511 145.075 63.0476 145.075 62.0564C145.075 61.0652 144.273 60.2617 143.284 60.2617C142.294 60.2617 141.493 61.0652 141.493 62.0564C141.493 63.0476 142.294 63.8511 143.284 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 63.8511C138.303 63.8511 139.104 63.0476 139.104 62.0564C139.104 61.0652 138.303 60.2617 137.313 60.2617C136.324 60.2617 135.522 61.0652 135.522 62.0564C135.522 63.0476 136.324 63.8511 137.313 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 63.8511C132.332 63.8511 133.134 63.0476 133.134 62.0564C133.134 61.0652 132.332 60.2617 131.343 60.2617C130.354 60.2617 129.552 61.0652 129.552 62.0564C129.552 63.0476 130.354 63.8511 131.343 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 63.8511C126.362 63.8511 127.164 63.0476 127.164 62.0564C127.164 61.0652 126.362 60.2617 125.373 60.2617C124.384 60.2617 123.582 61.0652 123.582 62.0564C123.582 63.0476 124.384 63.8511 125.373 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 63.8511C120.392 63.8511 121.194 63.0476 121.194 62.0564C121.194 61.0652 120.392 60.2617 119.403 60.2617C118.414 60.2617 117.612 61.0652 117.612 62.0564C117.612 63.0476 118.414 63.8511 119.403 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 63.8511C114.422 63.8511 115.224 63.0476 115.224 62.0564C115.224 61.0652 114.422 60.2617 113.433 60.2617C112.444 60.2617 111.642 61.0652 111.642 62.0564C111.642 63.0476 112.444 63.8511 113.433 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 63.8511C108.452 63.8511 109.254 63.0476 109.254 62.0564C109.254 61.0652 108.452 60.2617 107.463 60.2617C106.474 60.2617 105.672 61.0652 105.672 62.0564C105.672 63.0476 106.474 63.8511 107.463 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 63.8511C102.482 63.8511 103.284 63.0476 103.284 62.0564C103.284 61.0652 102.482 60.2617 101.493 60.2617C100.503 60.2617 99.7015 61.0652 99.7015 62.0564C99.7015 63.0476 100.503 63.8511 101.493 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 63.8511C96.5116 63.8511 97.3134 63.0476 97.3134 62.0564C97.3134 61.0652 96.5116 60.2617 95.5224 60.2617C94.5332 60.2617 93.7314 61.0652 93.7314 62.0564C93.7314 63.0476 94.5332 63.8511 95.5224 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 63.8511C90.5414 63.8511 91.3433 63.0476 91.3433 62.0564C91.3433 61.0652 90.5414 60.2617 89.5522 60.2617C88.563 60.2617 87.7612 61.0652 87.7612 62.0564C87.7612 63.0476 88.563 63.8511 89.5522 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 63.8511C84.5713 63.8511 85.3732 63.0476 85.3732 62.0564C85.3732 61.0652 84.5713 60.2617 83.5821 60.2617C82.593 60.2617 81.7911 61.0652 81.7911 62.0564C81.7911 63.0476 82.593 63.8511 83.5821 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M77.612 63.8511C78.6011 63.8511 79.403 63.0476 79.403 62.0564C79.403 61.0652 78.6011 60.2617 77.612 60.2617C76.6228 60.2617 75.8209 61.0652 75.8209 62.0564C75.8209 63.0476 76.6228 63.8511 77.612 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M71.6418 63.8511C72.631 63.8511 73.4329 63.0476 73.4329 62.0564C73.4329 61.0652 72.631 60.2617 71.6418 60.2617C70.6526 60.2617 69.8508 61.0652 69.8508 62.0564C69.8508 63.0476 70.6526 63.8511 71.6418 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M65.6717 63.8511C66.6608 63.8511 67.4627 63.0476 67.4627 62.0564C67.4627 61.0652 66.6608 60.2617 65.6717 60.2617C64.6825 60.2617 63.8806 61.0652 63.8806 62.0564C63.8806 63.0476 64.6825 63.8511 65.6717 63.8511Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 57.8687C460.691 57.8687 461.493 57.0652 461.493 56.074C461.493 55.0828 460.691 54.2793 459.701 54.2793C458.712 54.2793 457.91 55.0828 457.91 56.074C457.91 57.0652 458.712 57.8687 459.701 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 57.8687C454.72 57.8687 455.522 57.0652 455.522 56.074C455.522 55.0828 454.72 54.2793 453.731 54.2793C452.742 54.2793 451.94 55.0828 451.94 56.074C451.94 57.0652 452.742 57.8687 453.731 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 57.8687C448.75 57.8687 449.552 57.0652 449.552 56.074C449.552 55.0828 448.75 54.2793 447.761 54.2793C446.772 54.2793 445.97 55.0828 445.97 56.074C445.97 57.0652 446.772 57.8687 447.761 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 57.8687C442.78 57.8687 443.582 57.0652 443.582 56.074C443.582 55.0828 442.78 54.2793 441.791 54.2793C440.802 54.2793 440 55.0828 440 56.074C440 57.0652 440.802 57.8687 441.791 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 57.8687C436.81 57.8687 437.612 57.0652 437.612 56.074C437.612 55.0828 436.81 54.2793 435.821 54.2793C434.832 54.2793 434.03 55.0828 434.03 56.074C434.03 57.0652 434.832 57.8687 435.821 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 57.8687C430.84 57.8687 431.642 57.0652 431.642 56.074C431.642 55.0828 430.84 54.2793 429.851 54.2793C428.862 54.2793 428.06 55.0828 428.06 56.074C428.06 57.0652 428.862 57.8687 429.851 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 57.8687C424.87 57.8687 425.672 57.0652 425.672 56.074C425.672 55.0828 424.87 54.2793 423.881 54.2793C422.891 54.2793 422.09 55.0828 422.09 56.074C422.09 57.0652 422.891 57.8687 423.881 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 57.8687C418.9 57.8687 419.701 57.0652 419.701 56.074C419.701 55.0828 418.9 54.2793 417.91 54.2793C416.921 54.2793 416.119 55.0828 416.119 56.074C416.119 57.0652 416.921 57.8687 417.91 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 57.8687C412.929 57.8687 413.731 57.0652 413.731 56.074C413.731 55.0828 412.929 54.2793 411.94 54.2793C410.951 54.2793 410.149 55.0828 410.149 56.074C410.149 57.0652 410.951 57.8687 411.94 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 57.8687C406.959 57.8687 407.761 57.0652 407.761 56.074C407.761 55.0828 406.959 54.2793 405.97 54.2793C404.981 54.2793 404.179 55.0828 404.179 56.074C404.179 57.0652 404.981 57.8687 405.97 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M400 57.8687C400.989 57.8687 401.791 57.0652 401.791 56.074C401.791 55.0828 400.989 54.2793 400 54.2793C399.011 54.2793 398.209 55.0828 398.209 56.074C398.209 57.0652 399.011 57.8687 400 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 57.8687C395.019 57.8687 395.821 57.0652 395.821 56.074C395.821 55.0828 395.019 54.2793 394.03 54.2793C393.041 54.2793 392.239 55.0828 392.239 56.074C392.239 57.0652 393.041 57.8687 394.03 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 57.8687C347.258 57.8687 348.06 57.0652 348.06 56.074C348.06 55.0828 347.258 54.2793 346.269 54.2793C345.279 54.2793 344.478 55.0828 344.478 56.074C344.478 57.0652 345.279 57.8687 346.269 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 57.8687C341.288 57.8687 342.09 57.0652 342.09 56.074C342.09 55.0828 341.288 54.2793 340.299 54.2793C339.309 54.2793 338.507 55.0828 338.507 56.074C338.507 57.0652 339.309 57.8687 340.299 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 57.8687C335.318 57.8687 336.119 57.0652 336.119 56.074C336.119 55.0828 335.318 54.2793 334.328 54.2793C333.339 54.2793 332.537 55.0828 332.537 56.074C332.537 57.0652 333.339 57.8687 334.328 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 57.8687C329.347 57.8687 330.149 57.0652 330.149 56.074C330.149 55.0828 329.347 54.2793 328.358 54.2793C327.369 54.2793 326.567 55.0828 326.567 56.074C326.567 57.0652 327.369 57.8687 328.358 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 57.8687C305.467 57.8687 306.269 57.0652 306.269 56.074C306.269 55.0828 305.467 54.2793 304.478 54.2793C303.488 54.2793 302.687 55.0828 302.687 56.074C302.687 57.0652 303.488 57.8687 304.478 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 57.8687C299.497 57.8687 300.299 57.0652 300.299 56.074C300.299 55.0828 299.497 54.2793 298.507 54.2793C297.518 54.2793 296.716 55.0828 296.716 56.074C296.716 57.0652 297.518 57.8687 298.507 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 57.8687C287.556 57.8687 288.358 57.0652 288.358 56.074C288.358 55.0828 287.556 54.2793 286.567 54.2793C285.578 54.2793 284.776 55.0828 284.776 56.074C284.776 57.0652 285.578 57.8687 286.567 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 57.8687C281.586 57.8687 282.388 57.0652 282.388 56.074C282.388 55.0828 281.586 54.2793 280.597 54.2793C279.608 54.2793 278.806 55.0828 278.806 56.074C278.806 57.0652 279.608 57.8687 280.597 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 57.8687C275.616 57.8687 276.418 57.0652 276.418 56.074C276.418 55.0828 275.616 54.2793 274.627 54.2793C273.638 54.2793 272.836 55.0828 272.836 56.074C272.836 57.0652 273.638 57.8687 274.627 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 57.8687C269.646 57.8687 270.448 57.0652 270.448 56.074C270.448 55.0828 269.646 54.2793 268.657 54.2793C267.668 54.2793 266.866 55.0828 266.866 56.074C266.866 57.0652 267.668 57.8687 268.657 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 57.8687C263.676 57.8687 264.478 57.0652 264.478 56.074C264.478 55.0828 263.676 54.2793 262.687 54.2793C261.697 54.2793 260.896 55.0828 260.896 56.074C260.896 57.0652 261.697 57.8687 262.687 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 57.8687C227.855 57.8687 228.657 57.0652 228.657 56.074C228.657 55.0828 227.855 54.2793 226.866 54.2793C225.877 54.2793 225.075 55.0828 225.075 56.074C225.075 57.0652 225.877 57.8687 226.866 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 57.8687C221.885 57.8687 222.687 57.0652 222.687 56.074C222.687 55.0828 221.885 54.2793 220.896 54.2793C219.906 54.2793 219.104 55.0828 219.104 56.074C219.104 57.0652 219.906 57.8687 220.896 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 57.8687C209.944 57.8687 210.746 57.0652 210.746 56.074C210.746 55.0828 209.944 54.2793 208.955 54.2793C207.966 54.2793 207.164 55.0828 207.164 56.074C207.164 57.0652 207.966 57.8687 208.955 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 57.8687C203.974 57.8687 204.776 57.0652 204.776 56.074C204.776 55.0828 203.974 54.2793 202.985 54.2793C201.996 54.2793 201.194 55.0828 201.194 56.074C201.194 57.0652 201.996 57.8687 202.985 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 57.8687C198.004 57.8687 198.806 57.0652 198.806 56.074C198.806 55.0828 198.004 54.2793 197.015 54.2793C196.026 54.2793 195.224 55.0828 195.224 56.074C195.224 57.0652 196.026 57.8687 197.015 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M191.045 57.8687C192.034 57.8687 192.836 57.0652 192.836 56.074C192.836 55.0828 192.034 54.2793 191.045 54.2793C190.056 54.2793 189.254 55.0828 189.254 56.074C189.254 57.0652 190.056 57.8687 191.045 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M185.075 57.8687C186.064 57.8687 186.866 57.0652 186.866 56.074C186.866 55.0828 186.064 54.2793 185.075 54.2793C184.085 54.2793 183.284 55.0828 183.284 56.074C183.284 57.0652 184.085 57.8687 185.075 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 57.8687C180.094 57.8687 180.895 57.0652 180.895 56.074C180.895 55.0828 180.094 54.2793 179.104 54.2793C178.115 54.2793 177.313 55.0828 177.313 56.074C177.313 57.0652 178.115 57.8687 179.104 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M173.134 57.8687C174.123 57.8687 174.925 57.0652 174.925 56.074C174.925 55.0828 174.123 54.2793 173.134 54.2793C172.145 54.2793 171.343 55.0828 171.343 56.074C171.343 57.0652 172.145 57.8687 173.134 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 57.8687C168.153 57.8687 168.955 57.0652 168.955 56.074C168.955 55.0828 168.153 54.2793 167.164 54.2793C166.175 54.2793 165.373 55.0828 165.373 56.074C165.373 57.0652 166.175 57.8687 167.164 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 57.8687C162.183 57.8687 162.985 57.0652 162.985 56.074C162.985 55.0828 162.183 54.2793 161.194 54.2793C160.205 54.2793 159.403 55.0828 159.403 56.074C159.403 57.0652 160.205 57.8687 161.194 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M155.224 57.8687C156.213 57.8687 157.015 57.0652 157.015 56.074C157.015 55.0828 156.213 54.2793 155.224 54.2793C154.235 54.2793 153.433 55.0828 153.433 56.074C153.433 57.0652 154.235 57.8687 155.224 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M149.254 57.8687C150.243 57.8687 151.045 57.0652 151.045 56.074C151.045 55.0828 150.243 54.2793 149.254 54.2793C148.265 54.2793 147.463 55.0828 147.463 56.074C147.463 57.0652 148.265 57.8687 149.254 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 57.8687C144.273 57.8687 145.075 57.0652 145.075 56.074C145.075 55.0828 144.273 54.2793 143.284 54.2793C142.294 54.2793 141.493 55.0828 141.493 56.074C141.493 57.0652 142.294 57.8687 143.284 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 57.8687C138.303 57.8687 139.104 57.0652 139.104 56.074C139.104 55.0828 138.303 54.2793 137.313 54.2793C136.324 54.2793 135.522 55.0828 135.522 56.074C135.522 57.0652 136.324 57.8687 137.313 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 57.8687C132.332 57.8687 133.134 57.0652 133.134 56.074C133.134 55.0828 132.332 54.2793 131.343 54.2793C130.354 54.2793 129.552 55.0828 129.552 56.074C129.552 57.0652 130.354 57.8687 131.343 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 57.8687C126.362 57.8687 127.164 57.0652 127.164 56.074C127.164 55.0828 126.362 54.2793 125.373 54.2793C124.384 54.2793 123.582 55.0828 123.582 56.074C123.582 57.0652 124.384 57.8687 125.373 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 57.8687C120.392 57.8687 121.194 57.0652 121.194 56.074C121.194 55.0828 120.392 54.2793 119.403 54.2793C118.414 54.2793 117.612 55.0828 117.612 56.074C117.612 57.0652 118.414 57.8687 119.403 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 57.8687C114.422 57.8687 115.224 57.0652 115.224 56.074C115.224 55.0828 114.422 54.2793 113.433 54.2793C112.444 54.2793 111.642 55.0828 111.642 56.074C111.642 57.0652 112.444 57.8687 113.433 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 57.8687C108.452 57.8687 109.254 57.0652 109.254 56.074C109.254 55.0828 108.452 54.2793 107.463 54.2793C106.474 54.2793 105.672 55.0828 105.672 56.074C105.672 57.0652 106.474 57.8687 107.463 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 57.8687C102.482 57.8687 103.284 57.0652 103.284 56.074C103.284 55.0828 102.482 54.2793 101.493 54.2793C100.503 54.2793 99.7015 55.0828 99.7015 56.074C99.7015 57.0652 100.503 57.8687 101.493 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 57.8687C96.5116 57.8687 97.3134 57.0652 97.3134 56.074C97.3134 55.0828 96.5116 54.2793 95.5224 54.2793C94.5332 54.2793 93.7314 55.0828 93.7314 56.074C93.7314 57.0652 94.5332 57.8687 95.5224 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 57.8687C90.5414 57.8687 91.3433 57.0652 91.3433 56.074C91.3433 55.0828 90.5414 54.2793 89.5522 54.2793C88.563 54.2793 87.7612 55.0828 87.7612 56.074C87.7612 57.0652 88.563 57.8687 89.5522 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 57.8687C84.5713 57.8687 85.3732 57.0652 85.3732 56.074C85.3732 55.0828 84.5713 54.2793 83.5821 54.2793C82.593 54.2793 81.7911 55.0828 81.7911 56.074C81.7911 57.0652 82.593 57.8687 83.5821 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M77.612 57.8687C78.6011 57.8687 79.403 57.0652 79.403 56.074C79.403 55.0828 78.6011 54.2793 77.612 54.2793C76.6228 54.2793 75.8209 55.0828 75.8209 56.074C75.8209 57.0652 76.6228 57.8687 77.612 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M71.6418 57.8687C72.631 57.8687 73.4329 57.0652 73.4329 56.074C73.4329 55.0828 72.631 54.2793 71.6418 54.2793C70.6526 54.2793 69.8508 55.0828 69.8508 56.074C69.8508 57.0652 70.6526 57.8687 71.6418 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M65.6717 57.8687C66.6608 57.8687 67.4627 57.0652 67.4627 56.074C67.4627 55.0828 66.6608 54.2793 65.6717 54.2793C64.6825 54.2793 63.8806 55.0828 63.8806 56.074C63.8806 57.0652 64.6825 57.8687 65.6717 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M59.7015 57.8687C60.6907 57.8687 61.4926 57.0652 61.4926 56.074C61.4926 55.0828 60.6907 54.2793 59.7015 54.2793C58.7123 54.2793 57.9105 55.0828 57.9105 56.074C57.9105 57.0652 58.7123 57.8687 59.7015 57.8687Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 51.8865C472.631 51.8865 473.433 51.083 473.433 50.0918C473.433 49.1006 472.631 48.2971 471.642 48.2971C470.653 48.2971 469.851 49.1006 469.851 50.0918C469.851 51.083 470.653 51.8865 471.642 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 51.8865C466.661 51.8865 467.463 51.083 467.463 50.0918C467.463 49.1006 466.661 48.2971 465.672 48.2971C464.682 48.2971 463.881 49.1006 463.881 50.0918C463.881 51.083 464.682 51.8865 465.672 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 51.8865C460.691 51.8865 461.493 51.083 461.493 50.0918C461.493 49.1006 460.691 48.2971 459.701 48.2971C458.712 48.2971 457.91 49.1006 457.91 50.0918C457.91 51.083 458.712 51.8865 459.701 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 51.8865C454.72 51.8865 455.522 51.083 455.522 50.0918C455.522 49.1006 454.72 48.2971 453.731 48.2971C452.742 48.2971 451.94 49.1006 451.94 50.0918C451.94 51.083 452.742 51.8865 453.731 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 51.8865C448.75 51.8865 449.552 51.083 449.552 50.0918C449.552 49.1006 448.75 48.2971 447.761 48.2971C446.772 48.2971 445.97 49.1006 445.97 50.0918C445.97 51.083 446.772 51.8865 447.761 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 51.8865C442.78 51.8865 443.582 51.083 443.582 50.0918C443.582 49.1006 442.78 48.2971 441.791 48.2971C440.802 48.2971 440 49.1006 440 50.0918C440 51.083 440.802 51.8865 441.791 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 51.8865C436.81 51.8865 437.612 51.083 437.612 50.0918C437.612 49.1006 436.81 48.2971 435.821 48.2971C434.832 48.2971 434.03 49.1006 434.03 50.0918C434.03 51.083 434.832 51.8865 435.821 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 51.8865C430.84 51.8865 431.642 51.083 431.642 50.0918C431.642 49.1006 430.84 48.2971 429.851 48.2971C428.862 48.2971 428.06 49.1006 428.06 50.0918C428.06 51.083 428.862 51.8865 429.851 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 51.8865C424.87 51.8865 425.672 51.083 425.672 50.0918C425.672 49.1006 424.87 48.2971 423.881 48.2971C422.891 48.2971 422.09 49.1006 422.09 50.0918C422.09 51.083 422.891 51.8865 423.881 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 51.8865C418.9 51.8865 419.701 51.083 419.701 50.0918C419.701 49.1006 418.9 48.2971 417.91 48.2971C416.921 48.2971 416.119 49.1006 416.119 50.0918C416.119 51.083 416.921 51.8865 417.91 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 51.8865C412.929 51.8865 413.731 51.083 413.731 50.0918C413.731 49.1006 412.929 48.2971 411.94 48.2971C410.951 48.2971 410.149 49.1006 410.149 50.0918C410.149 51.083 410.951 51.8865 411.94 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 51.8865C406.959 51.8865 407.761 51.083 407.761 50.0918C407.761 49.1006 406.959 48.2971 405.97 48.2971C404.981 48.2971 404.179 49.1006 404.179 50.0918C404.179 51.083 404.981 51.8865 405.97 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M400 51.8865C400.989 51.8865 401.791 51.083 401.791 50.0918C401.791 49.1006 400.989 48.2971 400 48.2971C399.011 48.2971 398.209 49.1006 398.209 50.0918C398.209 51.083 399.011 51.8865 400 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 51.8865C347.258 51.8865 348.06 51.083 348.06 50.0918C348.06 49.1006 347.258 48.2971 346.269 48.2971C345.279 48.2971 344.478 49.1006 344.478 50.0918C344.478 51.083 345.279 51.8865 346.269 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 51.8865C341.288 51.8865 342.09 51.083 342.09 50.0918C342.09 49.1006 341.288 48.2971 340.299 48.2971C339.309 48.2971 338.507 49.1006 338.507 50.0918C338.507 51.083 339.309 51.8865 340.299 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 51.8865C335.318 51.8865 336.119 51.083 336.119 50.0918C336.119 49.1006 335.318 48.2971 334.328 48.2971C333.339 48.2971 332.537 49.1006 332.537 50.0918C332.537 51.083 333.339 51.8865 334.328 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 51.8865C329.347 51.8865 330.149 51.083 330.149 50.0918C330.149 49.1006 329.347 48.2971 328.358 48.2971C327.369 48.2971 326.567 49.1006 326.567 50.0918C326.567 51.083 327.369 51.8865 328.358 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 51.8865C323.377 51.8865 324.179 51.083 324.179 50.0918C324.179 49.1006 323.377 48.2971 322.388 48.2971C321.399 48.2971 320.597 49.1006 320.597 50.0918C320.597 51.083 321.399 51.8865 322.388 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 51.8865C317.407 51.8865 318.209 51.083 318.209 50.0918C318.209 49.1006 317.407 48.2971 316.418 48.2971C315.429 48.2971 314.627 49.1006 314.627 50.0918C314.627 51.083 315.429 51.8865 316.418 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 51.8865C311.437 51.8865 312.239 51.083 312.239 50.0918C312.239 49.1006 311.437 48.2971 310.448 48.2971C309.459 48.2971 308.657 49.1006 308.657 50.0918C308.657 51.083 309.459 51.8865 310.448 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 51.8865C281.586 51.8865 282.388 51.083 282.388 50.0918C282.388 49.1006 281.586 48.2971 280.597 48.2971C279.608 48.2971 278.806 49.1006 278.806 50.0918C278.806 51.083 279.608 51.8865 280.597 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 51.8865C275.616 51.8865 276.418 51.083 276.418 50.0918C276.418 49.1006 275.616 48.2971 274.627 48.2971C273.638 48.2971 272.836 49.1006 272.836 50.0918C272.836 51.083 273.638 51.8865 274.627 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 51.8865C251.735 51.8865 252.537 51.083 252.537 50.0918C252.537 49.1006 251.735 48.2971 250.746 48.2971C249.757 48.2971 248.955 49.1006 248.955 50.0918C248.955 51.083 249.757 51.8865 250.746 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 51.8865C245.765 51.8865 246.567 51.083 246.567 50.0918C246.567 49.1006 245.765 48.2971 244.776 48.2971C243.787 48.2971 242.985 49.1006 242.985 50.0918C242.985 51.083 243.787 51.8865 244.776 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 51.8865C239.795 51.8865 240.597 51.083 240.597 50.0918C240.597 49.1006 239.795 48.2971 238.806 48.2971C237.817 48.2971 237.015 49.1006 237.015 50.0918C237.015 51.083 237.817 51.8865 238.806 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 51.8865C233.825 51.8865 234.627 51.083 234.627 50.0918C234.627 49.1006 233.825 48.2971 232.836 48.2971C231.847 48.2971 231.045 49.1006 231.045 50.0918C231.045 51.083 231.847 51.8865 232.836 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 51.8865C227.855 51.8865 228.657 51.083 228.657 50.0918C228.657 49.1006 227.855 48.2971 226.866 48.2971C225.877 48.2971 225.075 49.1006 225.075 50.0918C225.075 51.083 225.877 51.8865 226.866 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 51.8865C221.885 51.8865 222.687 51.083 222.687 50.0918C222.687 49.1006 221.885 48.2971 220.896 48.2971C219.906 48.2971 219.104 49.1006 219.104 50.0918C219.104 51.083 219.906 51.8865 220.896 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 51.8865C215.915 51.8865 216.716 51.083 216.716 50.0918C216.716 49.1006 215.915 48.2971 214.925 48.2971C213.936 48.2971 213.134 49.1006 213.134 50.0918C213.134 51.083 213.936 51.8865 214.925 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 51.8865C209.944 51.8865 210.746 51.083 210.746 50.0918C210.746 49.1006 209.944 48.2971 208.955 48.2971C207.966 48.2971 207.164 49.1006 207.164 50.0918C207.164 51.083 207.966 51.8865 208.955 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M179.104 51.8865C180.094 51.8865 180.895 51.083 180.895 50.0918C180.895 49.1006 180.094 48.2971 179.104 48.2971C178.115 48.2971 177.313 49.1006 177.313 50.0918C177.313 51.083 178.115 51.8865 179.104 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M131.343 51.8865C132.332 51.8865 133.134 51.083 133.134 50.0918C133.134 49.1006 132.332 48.2971 131.343 48.2971C130.354 48.2971 129.552 49.1006 129.552 50.0918C129.552 51.083 130.354 51.8865 131.343 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M125.373 51.8865C126.362 51.8865 127.164 51.083 127.164 50.0918C127.164 49.1006 126.362 48.2971 125.373 48.2971C124.384 48.2971 123.582 49.1006 123.582 50.0918C123.582 51.083 124.384 51.8865 125.373 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M119.403 51.8865C120.392 51.8865 121.194 51.083 121.194 50.0918C121.194 49.1006 120.392 48.2971 119.403 48.2971C118.414 48.2971 117.612 49.1006 117.612 50.0918C117.612 51.083 118.414 51.8865 119.403 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M113.433 51.8865C114.422 51.8865 115.224 51.083 115.224 50.0918C115.224 49.1006 114.422 48.2971 113.433 48.2971C112.444 48.2971 111.642 49.1006 111.642 50.0918C111.642 51.083 112.444 51.8865 113.433 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M107.463 51.8865C108.452 51.8865 109.254 51.083 109.254 50.0918C109.254 49.1006 108.452 48.2971 107.463 48.2971C106.474 48.2971 105.672 49.1006 105.672 50.0918C105.672 51.083 106.474 51.8865 107.463 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M101.493 51.8865C102.482 51.8865 103.284 51.083 103.284 50.0918C103.284 49.1006 102.482 48.2971 101.493 48.2971C100.503 48.2971 99.7015 49.1006 99.7015 50.0918C99.7015 51.083 100.503 51.8865 101.493 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M95.5224 51.8865C96.5116 51.8865 97.3134 51.083 97.3134 50.0918C97.3134 49.1006 96.5116 48.2971 95.5224 48.2971C94.5332 48.2971 93.7314 49.1006 93.7314 50.0918C93.7314 51.083 94.5332 51.8865 95.5224 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M89.5522 51.8865C90.5414 51.8865 91.3433 51.083 91.3433 50.0918C91.3433 49.1006 90.5414 48.2971 89.5522 48.2971C88.563 48.2971 87.7612 49.1006 87.7612 50.0918C87.7612 51.083 88.563 51.8865 89.5522 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 51.8865C84.5713 51.8865 85.3732 51.083 85.3732 50.0918C85.3732 49.1006 84.5713 48.2971 83.5821 48.2971C82.593 48.2971 81.7911 49.1006 81.7911 50.0918C81.7911 51.083 82.593 51.8865 83.5821 51.8865Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 45.9041C478.601 45.9041 479.403 45.1006 479.403 44.1094C479.403 43.1182 478.601 42.3147 477.612 42.3147C476.623 42.3147 475.821 43.1182 475.821 44.1094C475.821 45.1006 476.623 45.9041 477.612 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 45.9041C472.631 45.9041 473.433 45.1006 473.433 44.1094C473.433 43.1182 472.631 42.3147 471.642 42.3147C470.653 42.3147 469.851 43.1182 469.851 44.1094C469.851 45.1006 470.653 45.9041 471.642 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 45.9041C466.661 45.9041 467.463 45.1006 467.463 44.1094C467.463 43.1182 466.661 42.3147 465.672 42.3147C464.682 42.3147 463.881 43.1182 463.881 44.1094C463.881 45.1006 464.682 45.9041 465.672 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 45.9041C460.691 45.9041 461.493 45.1006 461.493 44.1094C461.493 43.1182 460.691 42.3147 459.701 42.3147C458.712 42.3147 457.91 43.1182 457.91 44.1094C457.91 45.1006 458.712 45.9041 459.701 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 45.9041C454.72 45.9041 455.522 45.1006 455.522 44.1094C455.522 43.1182 454.72 42.3147 453.731 42.3147C452.742 42.3147 451.94 43.1182 451.94 44.1094C451.94 45.1006 452.742 45.9041 453.731 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 45.9041C448.75 45.9041 449.552 45.1006 449.552 44.1094C449.552 43.1182 448.75 42.3147 447.761 42.3147C446.772 42.3147 445.97 43.1182 445.97 44.1094C445.97 45.1006 446.772 45.9041 447.761 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 45.9041C442.78 45.9041 443.582 45.1006 443.582 44.1094C443.582 43.1182 442.78 42.3147 441.791 42.3147C440.802 42.3147 440 43.1182 440 44.1094C440 45.1006 440.802 45.9041 441.791 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 45.9041C436.81 45.9041 437.612 45.1006 437.612 44.1094C437.612 43.1182 436.81 42.3147 435.821 42.3147C434.832 42.3147 434.03 43.1182 434.03 44.1094C434.03 45.1006 434.832 45.9041 435.821 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 45.9041C430.84 45.9041 431.642 45.1006 431.642 44.1094C431.642 43.1182 430.84 42.3147 429.851 42.3147C428.862 42.3147 428.06 43.1182 428.06 44.1094C428.06 45.1006 428.862 45.9041 429.851 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 45.9041C424.87 45.9041 425.672 45.1006 425.672 44.1094C425.672 43.1182 424.87 42.3147 423.881 42.3147C422.891 42.3147 422.09 43.1182 422.09 44.1094C422.09 45.1006 422.891 45.9041 423.881 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 45.9041C418.9 45.9041 419.701 45.1006 419.701 44.1094C419.701 43.1182 418.9 42.3147 417.91 42.3147C416.921 42.3147 416.119 43.1182 416.119 44.1094C416.119 45.1006 416.921 45.9041 417.91 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 45.9041C412.929 45.9041 413.731 45.1006 413.731 44.1094C413.731 43.1182 412.929 42.3147 411.94 42.3147C410.951 42.3147 410.149 43.1182 410.149 44.1094C410.149 45.1006 410.951 45.9041 411.94 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 45.9041C406.959 45.9041 407.761 45.1006 407.761 44.1094C407.761 43.1182 406.959 42.3147 405.97 42.3147C404.981 42.3147 404.179 43.1182 404.179 44.1094C404.179 45.1006 404.981 45.9041 405.97 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M400 45.9041C400.989 45.9041 401.791 45.1006 401.791 44.1094C401.791 43.1182 400.989 42.3147 400 42.3147C399.011 42.3147 398.209 43.1182 398.209 44.1094C398.209 45.1006 399.011 45.9041 400 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 45.9041C395.019 45.9041 395.821 45.1006 395.821 44.1094C395.821 43.1182 395.019 42.3147 394.03 42.3147C393.041 42.3147 392.239 43.1182 392.239 44.1094C392.239 45.1006 393.041 45.9041 394.03 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 45.9041C335.318 45.9041 336.119 45.1006 336.119 44.1094C336.119 43.1182 335.318 42.3147 334.328 42.3147C333.339 42.3147 332.537 43.1182 332.537 44.1094C332.537 45.1006 333.339 45.9041 334.328 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 45.9041C329.347 45.9041 330.149 45.1006 330.149 44.1094C330.149 43.1182 329.347 42.3147 328.358 42.3147C327.369 42.3147 326.567 43.1182 326.567 44.1094C326.567 45.1006 327.369 45.9041 328.358 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 45.9041C323.377 45.9041 324.179 45.1006 324.179 44.1094C324.179 43.1182 323.377 42.3147 322.388 42.3147C321.399 42.3147 320.597 43.1182 320.597 44.1094C320.597 45.1006 321.399 45.9041 322.388 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 45.9041C317.407 45.9041 318.209 45.1006 318.209 44.1094C318.209 43.1182 317.407 42.3147 316.418 42.3147C315.429 42.3147 314.627 43.1182 314.627 44.1094C314.627 45.1006 315.429 45.9041 316.418 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 45.9041C311.437 45.9041 312.239 45.1006 312.239 44.1094C312.239 43.1182 311.437 42.3147 310.448 42.3147C309.459 42.3147 308.657 43.1182 308.657 44.1094C308.657 45.1006 309.459 45.9041 310.448 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 45.9041C305.467 45.9041 306.269 45.1006 306.269 44.1094C306.269 43.1182 305.467 42.3147 304.478 42.3147C303.488 42.3147 302.687 43.1182 302.687 44.1094C302.687 45.1006 303.488 45.9041 304.478 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M298.507 45.9041C299.497 45.9041 300.299 45.1006 300.299 44.1094C300.299 43.1182 299.497 42.3147 298.507 42.3147C297.518 42.3147 296.716 43.1182 296.716 44.1094C296.716 45.1006 297.518 45.9041 298.507 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 45.9041C281.586 45.9041 282.388 45.1006 282.388 44.1094C282.388 43.1182 281.586 42.3147 280.597 42.3147C279.608 42.3147 278.806 43.1182 278.806 44.1094C278.806 45.1006 279.608 45.9041 280.597 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 45.9041C245.765 45.9041 246.567 45.1006 246.567 44.1094C246.567 43.1182 245.765 42.3147 244.776 42.3147C243.787 42.3147 242.985 43.1182 242.985 44.1094C242.985 45.1006 243.787 45.9041 244.776 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M238.806 45.9041C239.795 45.9041 240.597 45.1006 240.597 44.1094C240.597 43.1182 239.795 42.3147 238.806 42.3147C237.817 42.3147 237.015 43.1182 237.015 44.1094C237.015 45.1006 237.817 45.9041 238.806 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M232.836 45.9041C233.825 45.9041 234.627 45.1006 234.627 44.1094C234.627 43.1182 233.825 42.3147 232.836 42.3147C231.847 42.3147 231.045 43.1182 231.045 44.1094C231.045 45.1006 231.847 45.9041 232.836 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 45.9041C227.855 45.9041 228.657 45.1006 228.657 44.1094C228.657 43.1182 227.855 42.3147 226.866 42.3147C225.877 42.3147 225.075 43.1182 225.075 44.1094C225.075 45.1006 225.877 45.9041 226.866 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 45.9041C221.885 45.9041 222.687 45.1006 222.687 44.1094C222.687 43.1182 221.885 42.3147 220.896 42.3147C219.906 42.3147 219.104 43.1182 219.104 44.1094C219.104 45.1006 219.906 45.9041 220.896 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 45.9041C215.915 45.9041 216.716 45.1006 216.716 44.1094C216.716 43.1182 215.915 42.3147 214.925 42.3147C213.936 42.3147 213.134 43.1182 213.134 44.1094C213.134 45.1006 213.936 45.9041 214.925 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 45.9041C209.944 45.9041 210.746 45.1006 210.746 44.1094C210.746 43.1182 209.944 42.3147 208.955 42.3147C207.966 42.3147 207.164 43.1182 207.164 44.1094C207.164 45.1006 207.966 45.9041 208.955 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M202.985 45.9041C203.974 45.9041 204.776 45.1006 204.776 44.1094C204.776 43.1182 203.974 42.3147 202.985 42.3147C201.996 42.3147 201.194 43.1182 201.194 44.1094C201.194 45.1006 201.996 45.9041 202.985 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M197.015 45.9041C198.004 45.9041 198.806 45.1006 198.806 44.1094C198.806 43.1182 198.004 42.3147 197.015 42.3147C196.026 42.3147 195.224 43.1182 195.224 44.1094C195.224 45.1006 196.026 45.9041 197.015 45.9041Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 39.9219C478.601 39.9219 479.403 39.1184 479.403 38.1272C479.403 37.136 478.601 36.3325 477.612 36.3325C476.623 36.3325 475.821 37.136 475.821 38.1272C475.821 39.1184 476.623 39.9219 477.612 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 39.9219C472.631 39.9219 473.433 39.1184 473.433 38.1272C473.433 37.136 472.631 36.3325 471.642 36.3325C470.653 36.3325 469.851 37.136 469.851 38.1272C469.851 39.1184 470.653 39.9219 471.642 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 39.9219C466.661 39.9219 467.463 39.1184 467.463 38.1272C467.463 37.136 466.661 36.3325 465.672 36.3325C464.682 36.3325 463.881 37.136 463.881 38.1272C463.881 39.1184 464.682 39.9219 465.672 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 39.9219C460.691 39.9219 461.493 39.1184 461.493 38.1272C461.493 37.136 460.691 36.3325 459.701 36.3325C458.712 36.3325 457.91 37.136 457.91 38.1272C457.91 39.1184 458.712 39.9219 459.701 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 39.9219C454.72 39.9219 455.522 39.1184 455.522 38.1272C455.522 37.136 454.72 36.3325 453.731 36.3325C452.742 36.3325 451.94 37.136 451.94 38.1272C451.94 39.1184 452.742 39.9219 453.731 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 39.9219C448.75 39.9219 449.552 39.1184 449.552 38.1272C449.552 37.136 448.75 36.3325 447.761 36.3325C446.772 36.3325 445.97 37.136 445.97 38.1272C445.97 39.1184 446.772 39.9219 447.761 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 39.9219C442.78 39.9219 443.582 39.1184 443.582 38.1272C443.582 37.136 442.78 36.3325 441.791 36.3325C440.802 36.3325 440 37.136 440 38.1272C440 39.1184 440.802 39.9219 441.791 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 39.9219C436.81 39.9219 437.612 39.1184 437.612 38.1272C437.612 37.136 436.81 36.3325 435.821 36.3325C434.832 36.3325 434.03 37.136 434.03 38.1272C434.03 39.1184 434.832 39.9219 435.821 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 39.9219C430.84 39.9219 431.642 39.1184 431.642 38.1272C431.642 37.136 430.84 36.3325 429.851 36.3325C428.862 36.3325 428.06 37.136 428.06 38.1272C428.06 39.1184 428.862 39.9219 429.851 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 39.9219C424.87 39.9219 425.672 39.1184 425.672 38.1272C425.672 37.136 424.87 36.3325 423.881 36.3325C422.891 36.3325 422.09 37.136 422.09 38.1272C422.09 39.1184 422.891 39.9219 423.881 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 39.9219C418.9 39.9219 419.701 39.1184 419.701 38.1272C419.701 37.136 418.9 36.3325 417.91 36.3325C416.921 36.3325 416.119 37.136 416.119 38.1272C416.119 39.1184 416.921 39.9219 417.91 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 39.9219C412.929 39.9219 413.731 39.1184 413.731 38.1272C413.731 37.136 412.929 36.3325 411.94 36.3325C410.951 36.3325 410.149 37.136 410.149 38.1272C410.149 39.1184 410.951 39.9219 411.94 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 39.9219C406.959 39.9219 407.761 39.1184 407.761 38.1272C407.761 37.136 406.959 36.3325 405.97 36.3325C404.981 36.3325 404.179 37.136 404.179 38.1272C404.179 39.1184 404.981 39.9219 405.97 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M400 39.9219C400.989 39.9219 401.791 39.1184 401.791 38.1272C401.791 37.136 400.989 36.3325 400 36.3325C399.011 36.3325 398.209 37.136 398.209 38.1272C398.209 39.1184 399.011 39.9219 400 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 39.9219C395.019 39.9219 395.821 39.1184 395.821 38.1272C395.821 37.136 395.019 36.3325 394.03 36.3325C393.041 36.3325 392.239 37.136 392.239 38.1272C392.239 39.1184 393.041 39.9219 394.03 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 39.9219C317.407 39.9219 318.209 39.1184 318.209 38.1272C318.209 37.136 317.407 36.3325 316.418 36.3325C315.429 36.3325 314.627 37.136 314.627 38.1272C314.627 39.1184 315.429 39.9219 316.418 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 39.9219C305.467 39.9219 306.269 39.1184 306.269 38.1272C306.269 37.136 305.467 36.3325 304.478 36.3325C303.488 36.3325 302.687 37.136 302.687 38.1272C302.687 39.1184 303.488 39.9219 304.478 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M292.537 39.9219C293.526 39.9219 294.328 39.1184 294.328 38.1272C294.328 37.136 293.526 36.3325 292.537 36.3325C291.548 36.3325 290.746 37.136 290.746 38.1272C290.746 39.1184 291.548 39.9219 292.537 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M286.567 39.9219C287.556 39.9219 288.358 39.1184 288.358 38.1272C288.358 37.136 287.556 36.3325 286.567 36.3325C285.578 36.3325 284.776 37.136 284.776 38.1272C284.776 39.1184 285.578 39.9219 286.567 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 39.9219C275.616 39.9219 276.418 39.1184 276.418 38.1272C276.418 37.136 275.616 36.3325 274.627 36.3325C273.638 36.3325 272.836 37.136 272.836 38.1272C272.836 39.1184 273.638 39.9219 274.627 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 39.9219C269.646 39.9219 270.448 39.1184 270.448 38.1272C270.448 37.136 269.646 36.3325 268.657 36.3325C267.668 36.3325 266.866 37.136 266.866 38.1272C266.866 39.1184 267.668 39.9219 268.657 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 39.9219C251.735 39.9219 252.537 39.1184 252.537 38.1272C252.537 37.136 251.735 36.3325 250.746 36.3325C249.757 36.3325 248.955 37.136 248.955 38.1272C248.955 39.1184 249.757 39.9219 250.746 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 39.9219C227.855 39.9219 228.657 39.1184 228.657 38.1272C228.657 37.136 227.855 36.3325 226.866 36.3325C225.877 36.3325 225.075 37.136 225.075 38.1272C225.075 39.1184 225.877 39.9219 226.866 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 39.9219C221.885 39.9219 222.687 39.1184 222.687 38.1272C222.687 37.136 221.885 36.3325 220.896 36.3325C219.906 36.3325 219.104 37.136 219.104 38.1272C219.104 39.1184 219.906 39.9219 220.896 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 39.9219C215.915 39.9219 216.716 39.1184 216.716 38.1272C216.716 37.136 215.915 36.3325 214.925 36.3325C213.936 36.3325 213.134 37.136 213.134 38.1272C213.134 39.1184 213.936 39.9219 214.925 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M208.955 39.9219C209.944 39.9219 210.746 39.1184 210.746 38.1272C210.746 37.136 209.944 36.3325 208.955 36.3325C207.966 36.3325 207.164 37.136 207.164 38.1272C207.164 39.1184 207.966 39.9219 208.955 39.9219Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 33.9397C484.571 33.9397 485.373 33.1362 485.373 32.145C485.373 31.1538 484.571 30.3503 483.582 30.3503C482.593 30.3503 481.791 31.1538 481.791 32.145C481.791 33.1362 482.593 33.9397 483.582 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 33.9397C478.601 33.9397 479.403 33.1362 479.403 32.145C479.403 31.1538 478.601 30.3503 477.612 30.3503C476.623 30.3503 475.821 31.1538 475.821 32.145C475.821 33.1362 476.623 33.9397 477.612 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 33.9397C472.631 33.9397 473.433 33.1362 473.433 32.145C473.433 31.1538 472.631 30.3503 471.642 30.3503C470.653 30.3503 469.851 31.1538 469.851 32.145C469.851 33.1362 470.653 33.9397 471.642 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 33.9397C466.661 33.9397 467.463 33.1362 467.463 32.145C467.463 31.1538 466.661 30.3503 465.672 30.3503C464.682 30.3503 463.881 31.1538 463.881 32.145C463.881 33.1362 464.682 33.9397 465.672 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 33.9397C460.691 33.9397 461.493 33.1362 461.493 32.145C461.493 31.1538 460.691 30.3503 459.701 30.3503C458.712 30.3503 457.91 31.1538 457.91 32.145C457.91 33.1362 458.712 33.9397 459.701 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 33.9397C454.72 33.9397 455.522 33.1362 455.522 32.145C455.522 31.1538 454.72 30.3503 453.731 30.3503C452.742 30.3503 451.94 31.1538 451.94 32.145C451.94 33.1362 452.742 33.9397 453.731 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 33.9397C448.75 33.9397 449.552 33.1362 449.552 32.145C449.552 31.1538 448.75 30.3503 447.761 30.3503C446.772 30.3503 445.97 31.1538 445.97 32.145C445.97 33.1362 446.772 33.9397 447.761 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 33.9397C442.78 33.9397 443.582 33.1362 443.582 32.145C443.582 31.1538 442.78 30.3503 441.791 30.3503C440.802 30.3503 440 31.1538 440 32.145C440 33.1362 440.802 33.9397 441.791 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 33.9397C436.81 33.9397 437.612 33.1362 437.612 32.145C437.612 31.1538 436.81 30.3503 435.821 30.3503C434.832 30.3503 434.03 31.1538 434.03 32.145C434.03 33.1362 434.832 33.9397 435.821 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 33.9397C430.84 33.9397 431.642 33.1362 431.642 32.145C431.642 31.1538 430.84 30.3503 429.851 30.3503C428.862 30.3503 428.06 31.1538 428.06 32.145C428.06 33.1362 428.862 33.9397 429.851 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 33.9397C424.87 33.9397 425.672 33.1362 425.672 32.145C425.672 31.1538 424.87 30.3503 423.881 30.3503C422.891 30.3503 422.09 31.1538 422.09 32.145C422.09 33.1362 422.891 33.9397 423.881 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 33.9397C418.9 33.9397 419.701 33.1362 419.701 32.145C419.701 31.1538 418.9 30.3503 417.91 30.3503C416.921 30.3503 416.119 31.1538 416.119 32.145C416.119 33.1362 416.921 33.9397 417.91 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 33.9397C412.929 33.9397 413.731 33.1362 413.731 32.145C413.731 31.1538 412.929 30.3503 411.94 30.3503C410.951 30.3503 410.149 31.1538 410.149 32.145C410.149 33.1362 410.951 33.9397 411.94 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 33.9397C406.959 33.9397 407.761 33.1362 407.761 32.145C407.761 31.1538 406.959 30.3503 405.97 30.3503C404.981 30.3503 404.179 31.1538 404.179 32.145C404.179 33.1362 404.981 33.9397 405.97 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M400 33.9397C400.989 33.9397 401.791 33.1362 401.791 32.145C401.791 31.1538 400.989 30.3503 400 30.3503C399.011 30.3503 398.209 31.1538 398.209 32.145C398.209 33.1362 399.011 33.9397 400 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 33.9397C395.019 33.9397 395.821 33.1362 395.821 32.145C395.821 31.1538 395.019 30.3503 394.03 30.3503C393.041 30.3503 392.239 31.1538 392.239 32.145C392.239 33.1362 393.041 33.9397 394.03 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 33.9397C389.049 33.9397 389.851 33.1362 389.851 32.145C389.851 31.1538 389.049 30.3503 388.06 30.3503C387.071 30.3503 386.269 31.1538 386.269 32.145C386.269 33.1362 387.071 33.9397 388.06 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 33.9397C329.347 33.9397 330.149 33.1362 330.149 32.145C330.149 31.1538 329.347 30.3503 328.358 30.3503C327.369 30.3503 326.567 31.1538 326.567 32.145C326.567 33.1362 327.369 33.9397 328.358 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 33.9397C323.377 33.9397 324.179 33.1362 324.179 32.145C324.179 31.1538 323.377 30.3503 322.388 30.3503C321.399 30.3503 320.597 31.1538 320.597 32.145C320.597 33.1362 321.399 33.9397 322.388 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 33.9397C317.407 33.9397 318.209 33.1362 318.209 32.145C318.209 31.1538 317.407 30.3503 316.418 30.3503C315.429 30.3503 314.627 31.1538 314.627 32.145C314.627 33.1362 315.429 33.9397 316.418 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 33.9397C311.437 33.9397 312.239 33.1362 312.239 32.145C312.239 31.1538 311.437 30.3503 310.448 30.3503C309.459 30.3503 308.657 31.1538 308.657 32.145C308.657 33.1362 309.459 33.9397 310.448 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M304.478 33.9397C305.467 33.9397 306.269 33.1362 306.269 32.145C306.269 31.1538 305.467 30.3503 304.478 30.3503C303.488 30.3503 302.687 31.1538 302.687 32.145C302.687 33.1362 303.488 33.9397 304.478 33.9397Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 27.9573C490.541 27.9573 491.343 27.1538 491.343 26.1626C491.343 25.1714 490.541 24.3679 489.552 24.3679C488.563 24.3679 487.761 25.1714 487.761 26.1626C487.761 27.1538 488.563 27.9573 489.552 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 27.9573C484.571 27.9573 485.373 27.1538 485.373 26.1626C485.373 25.1714 484.571 24.3679 483.582 24.3679C482.593 24.3679 481.791 25.1714 481.791 26.1626C481.791 27.1538 482.593 27.9573 483.582 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 27.9573C478.601 27.9573 479.403 27.1538 479.403 26.1626C479.403 25.1714 478.601 24.3679 477.612 24.3679C476.623 24.3679 475.821 25.1714 475.821 26.1626C475.821 27.1538 476.623 27.9573 477.612 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 27.9573C472.631 27.9573 473.433 27.1538 473.433 26.1626C473.433 25.1714 472.631 24.3679 471.642 24.3679C470.653 24.3679 469.851 25.1714 469.851 26.1626C469.851 27.1538 470.653 27.9573 471.642 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 27.9573C466.661 27.9573 467.463 27.1538 467.463 26.1626C467.463 25.1714 466.661 24.3679 465.672 24.3679C464.682 24.3679 463.881 25.1714 463.881 26.1626C463.881 27.1538 464.682 27.9573 465.672 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 27.9573C460.691 27.9573 461.493 27.1538 461.493 26.1626C461.493 25.1714 460.691 24.3679 459.701 24.3679C458.712 24.3679 457.91 25.1714 457.91 26.1626C457.91 27.1538 458.712 27.9573 459.701 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 27.9573C454.72 27.9573 455.522 27.1538 455.522 26.1626C455.522 25.1714 454.72 24.3679 453.731 24.3679C452.742 24.3679 451.94 25.1714 451.94 26.1626C451.94 27.1538 452.742 27.9573 453.731 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 27.9573C448.75 27.9573 449.552 27.1538 449.552 26.1626C449.552 25.1714 448.75 24.3679 447.761 24.3679C446.772 24.3679 445.97 25.1714 445.97 26.1626C445.97 27.1538 446.772 27.9573 447.761 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 27.9573C442.78 27.9573 443.582 27.1538 443.582 26.1626C443.582 25.1714 442.78 24.3679 441.791 24.3679C440.802 24.3679 440 25.1714 440 26.1626C440 27.1538 440.802 27.9573 441.791 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 27.9573C436.81 27.9573 437.612 27.1538 437.612 26.1626C437.612 25.1714 436.81 24.3679 435.821 24.3679C434.832 24.3679 434.03 25.1714 434.03 26.1626C434.03 27.1538 434.832 27.9573 435.821 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 27.9573C430.84 27.9573 431.642 27.1538 431.642 26.1626C431.642 25.1714 430.84 24.3679 429.851 24.3679C428.862 24.3679 428.06 25.1714 428.06 26.1626C428.06 27.1538 428.862 27.9573 429.851 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 27.9573C424.87 27.9573 425.672 27.1538 425.672 26.1626C425.672 25.1714 424.87 24.3679 423.881 24.3679C422.891 24.3679 422.09 25.1714 422.09 26.1626C422.09 27.1538 422.891 27.9573 423.881 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 27.9573C418.9 27.9573 419.701 27.1538 419.701 26.1626C419.701 25.1714 418.9 24.3679 417.91 24.3679C416.921 24.3679 416.119 25.1714 416.119 26.1626C416.119 27.1538 416.921 27.9573 417.91 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 27.9573C412.929 27.9573 413.731 27.1538 413.731 26.1626C413.731 25.1714 412.929 24.3679 411.94 24.3679C410.951 24.3679 410.149 25.1714 410.149 26.1626C410.149 27.1538 410.951 27.9573 411.94 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 27.9573C406.959 27.9573 407.761 27.1538 407.761 26.1626C407.761 25.1714 406.959 24.3679 405.97 24.3679C404.981 24.3679 404.179 25.1714 404.179 26.1626C404.179 27.1538 404.981 27.9573 405.97 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M400 27.9573C400.989 27.9573 401.791 27.1538 401.791 26.1626C401.791 25.1714 400.989 24.3679 400 24.3679C399.011 24.3679 398.209 25.1714 398.209 26.1626C398.209 27.1538 399.011 27.9573 400 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 27.9573C395.019 27.9573 395.821 27.1538 395.821 26.1626C395.821 25.1714 395.019 24.3679 394.03 24.3679C393.041 24.3679 392.239 25.1714 392.239 26.1626C392.239 27.1538 393.041 27.9573 394.03 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 27.9573C389.049 27.9573 389.851 27.1538 389.851 26.1626C389.851 25.1714 389.049 24.3679 388.06 24.3679C387.071 24.3679 386.269 25.1714 386.269 26.1626C386.269 27.1538 387.071 27.9573 388.06 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 27.9573C383.079 27.9573 383.881 27.1538 383.881 26.1626C383.881 25.1714 383.079 24.3679 382.09 24.3679C381.1 24.3679 380.298 25.1714 380.298 26.1626C380.298 27.1538 381.1 27.9573 382.09 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 27.9573C377.109 27.9573 377.91 27.1538 377.91 26.1626C377.91 25.1714 377.109 24.3679 376.119 24.3679C375.13 24.3679 374.328 25.1714 374.328 26.1626C374.328 27.1538 375.13 27.9573 376.119 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 27.9573C371.138 27.9573 371.94 27.1538 371.94 26.1626C371.94 25.1714 371.138 24.3679 370.149 24.3679C369.16 24.3679 368.358 25.1714 368.358 26.1626C368.358 27.1538 369.16 27.9573 370.149 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 27.9573C365.168 27.9573 365.97 27.1538 365.97 26.1626C365.97 25.1714 365.168 24.3679 364.179 24.3679C363.19 24.3679 362.388 25.1714 362.388 26.1626C362.388 27.1538 363.19 27.9573 364.179 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 27.9573C341.288 27.9573 342.09 27.1538 342.09 26.1626C342.09 25.1714 341.288 24.3679 340.299 24.3679C339.309 24.3679 338.507 25.1714 338.507 26.1626C338.507 27.1538 339.309 27.9573 340.299 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 27.9573C335.318 27.9573 336.119 27.1538 336.119 26.1626C336.119 25.1714 335.318 24.3679 334.328 24.3679C333.339 24.3679 332.537 25.1714 332.537 26.1626C332.537 27.1538 333.339 27.9573 334.328 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 27.9573C329.347 27.9573 330.149 27.1538 330.149 26.1626C330.149 25.1714 329.347 24.3679 328.358 24.3679C327.369 24.3679 326.567 25.1714 326.567 26.1626C326.567 27.1538 327.369 27.9573 328.358 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 27.9573C323.377 27.9573 324.179 27.1538 324.179 26.1626C324.179 25.1714 323.377 24.3679 322.388 24.3679C321.399 24.3679 320.597 25.1714 320.597 26.1626C320.597 27.1538 321.399 27.9573 322.388 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 27.9573C317.407 27.9573 318.209 27.1538 318.209 26.1626C318.209 25.1714 317.407 24.3679 316.418 24.3679C315.429 24.3679 314.627 25.1714 314.627 26.1626C314.627 27.1538 315.429 27.9573 316.418 27.9573Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 21.9751C496.512 21.9751 497.313 21.1716 497.313 20.1804C497.313 19.1892 496.512 18.3857 495.522 18.3857C494.533 18.3857 493.731 19.1892 493.731 20.1804C493.731 21.1716 494.533 21.9751 495.522 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 21.9751C490.541 21.9751 491.343 21.1716 491.343 20.1804C491.343 19.1892 490.541 18.3857 489.552 18.3857C488.563 18.3857 487.761 19.1892 487.761 20.1804C487.761 21.1716 488.563 21.9751 489.552 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 21.9751C484.571 21.9751 485.373 21.1716 485.373 20.1804C485.373 19.1892 484.571 18.3857 483.582 18.3857C482.593 18.3857 481.791 19.1892 481.791 20.1804C481.791 21.1716 482.593 21.9751 483.582 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 21.9751C478.601 21.9751 479.403 21.1716 479.403 20.1804C479.403 19.1892 478.601 18.3857 477.612 18.3857C476.623 18.3857 475.821 19.1892 475.821 20.1804C475.821 21.1716 476.623 21.9751 477.612 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 21.9751C472.631 21.9751 473.433 21.1716 473.433 20.1804C473.433 19.1892 472.631 18.3857 471.642 18.3857C470.653 18.3857 469.851 19.1892 469.851 20.1804C469.851 21.1716 470.653 21.9751 471.642 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 21.9751C466.661 21.9751 467.463 21.1716 467.463 20.1804C467.463 19.1892 466.661 18.3857 465.672 18.3857C464.682 18.3857 463.881 19.1892 463.881 20.1804C463.881 21.1716 464.682 21.9751 465.672 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 21.9751C460.691 21.9751 461.493 21.1716 461.493 20.1804C461.493 19.1892 460.691 18.3857 459.701 18.3857C458.712 18.3857 457.91 19.1892 457.91 20.1804C457.91 21.1716 458.712 21.9751 459.701 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 21.9751C454.72 21.9751 455.522 21.1716 455.522 20.1804C455.522 19.1892 454.72 18.3857 453.731 18.3857C452.742 18.3857 451.94 19.1892 451.94 20.1804C451.94 21.1716 452.742 21.9751 453.731 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 21.9751C448.75 21.9751 449.552 21.1716 449.552 20.1804C449.552 19.1892 448.75 18.3857 447.761 18.3857C446.772 18.3857 445.97 19.1892 445.97 20.1804C445.97 21.1716 446.772 21.9751 447.761 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 21.9751C442.78 21.9751 443.582 21.1716 443.582 20.1804C443.582 19.1892 442.78 18.3857 441.791 18.3857C440.802 18.3857 440 19.1892 440 20.1804C440 21.1716 440.802 21.9751 441.791 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 21.9751C436.81 21.9751 437.612 21.1716 437.612 20.1804C437.612 19.1892 436.81 18.3857 435.821 18.3857C434.832 18.3857 434.03 19.1892 434.03 20.1804C434.03 21.1716 434.832 21.9751 435.821 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 21.9751C430.84 21.9751 431.642 21.1716 431.642 20.1804C431.642 19.1892 430.84 18.3857 429.851 18.3857C428.862 18.3857 428.06 19.1892 428.06 20.1804C428.06 21.1716 428.862 21.9751 429.851 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 21.9751C424.87 21.9751 425.672 21.1716 425.672 20.1804C425.672 19.1892 424.87 18.3857 423.881 18.3857C422.891 18.3857 422.09 19.1892 422.09 20.1804C422.09 21.1716 422.891 21.9751 423.881 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 21.9751C418.9 21.9751 419.701 21.1716 419.701 20.1804C419.701 19.1892 418.9 18.3857 417.91 18.3857C416.921 18.3857 416.119 19.1892 416.119 20.1804C416.119 21.1716 416.921 21.9751 417.91 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 21.9751C412.929 21.9751 413.731 21.1716 413.731 20.1804C413.731 19.1892 412.929 18.3857 411.94 18.3857C410.951 18.3857 410.149 19.1892 410.149 20.1804C410.149 21.1716 410.951 21.9751 411.94 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 21.9751C406.959 21.9751 407.761 21.1716 407.761 20.1804C407.761 19.1892 406.959 18.3857 405.97 18.3857C404.981 18.3857 404.179 19.1892 404.179 20.1804C404.179 21.1716 404.981 21.9751 405.97 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M400 21.9751C400.989 21.9751 401.791 21.1716 401.791 20.1804C401.791 19.1892 400.989 18.3857 400 18.3857C399.011 18.3857 398.209 19.1892 398.209 20.1804C398.209 21.1716 399.011 21.9751 400 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 21.9751C395.019 21.9751 395.821 21.1716 395.821 20.1804C395.821 19.1892 395.019 18.3857 394.03 18.3857C393.041 18.3857 392.239 19.1892 392.239 20.1804C392.239 21.1716 393.041 21.9751 394.03 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 21.9751C389.049 21.9751 389.851 21.1716 389.851 20.1804C389.851 19.1892 389.049 18.3857 388.06 18.3857C387.071 18.3857 386.269 19.1892 386.269 20.1804C386.269 21.1716 387.071 21.9751 388.06 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 21.9751C383.079 21.9751 383.881 21.1716 383.881 20.1804C383.881 19.1892 383.079 18.3857 382.09 18.3857C381.1 18.3857 380.298 19.1892 380.298 20.1804C380.298 21.1716 381.1 21.9751 382.09 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 21.9751C377.109 21.9751 377.91 21.1716 377.91 20.1804C377.91 19.1892 377.109 18.3857 376.119 18.3857C375.13 18.3857 374.328 19.1892 374.328 20.1804C374.328 21.1716 375.13 21.9751 376.119 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 21.9751C359.198 21.9751 360 21.1716 360 20.1804C360 19.1892 359.198 18.3857 358.209 18.3857C357.22 18.3857 356.418 19.1892 356.418 20.1804C356.418 21.1716 357.22 21.9751 358.209 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 21.9751C353.228 21.9751 354.03 21.1716 354.03 20.1804C354.03 19.1892 353.228 18.3857 352.239 18.3857C351.25 18.3857 350.448 19.1892 350.448 20.1804C350.448 21.1716 351.25 21.9751 352.239 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 21.9751C347.258 21.9751 348.06 21.1716 348.06 20.1804C348.06 19.1892 347.258 18.3857 346.269 18.3857C345.279 18.3857 344.478 19.1892 344.478 20.1804C344.478 21.1716 345.279 21.9751 346.269 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 21.9751C341.288 21.9751 342.09 21.1716 342.09 20.1804C342.09 19.1892 341.288 18.3857 340.299 18.3857C339.309 18.3857 338.507 19.1892 338.507 20.1804C338.507 21.1716 339.309 21.9751 340.299 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M334.328 21.9751C335.318 21.9751 336.119 21.1716 336.119 20.1804C336.119 19.1892 335.318 18.3857 334.328 18.3857C333.339 18.3857 332.537 19.1892 332.537 20.1804C332.537 21.1716 333.339 21.9751 334.328 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 21.9751C317.407 21.9751 318.209 21.1716 318.209 20.1804C318.209 19.1892 317.407 18.3857 316.418 18.3857C315.429 18.3857 314.627 19.1892 314.627 20.1804C314.627 21.1716 315.429 21.9751 316.418 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M310.448 21.9751C311.437 21.9751 312.239 21.1716 312.239 20.1804C312.239 19.1892 311.437 18.3857 310.448 18.3857C309.459 18.3857 308.657 19.1892 308.657 20.1804C308.657 21.1716 309.459 21.9751 310.448 21.9751Z"
                fill="#FBC98E"
              />
              <path
                d="M507.463 15.9927C508.452 15.9927 509.254 15.1892 509.254 14.198C509.254 13.2068 508.452 12.4033 507.463 12.4033C506.474 12.4033 505.672 13.2068 505.672 14.198C505.672 15.1892 506.474 15.9927 507.463 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M501.493 15.9927C502.482 15.9927 503.284 15.1892 503.284 14.198C503.284 13.2068 502.482 12.4033 501.493 12.4033C500.503 12.4033 499.702 13.2068 499.702 14.198C499.702 15.1892 500.503 15.9927 501.493 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M495.522 15.9927C496.512 15.9927 497.313 15.1892 497.313 14.198C497.313 13.2068 496.512 12.4033 495.522 12.4033C494.533 12.4033 493.731 13.2068 493.731 14.198C493.731 15.1892 494.533 15.9927 495.522 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M489.552 15.9927C490.541 15.9927 491.343 15.1892 491.343 14.198C491.343 13.2068 490.541 12.4033 489.552 12.4033C488.563 12.4033 487.761 13.2068 487.761 14.198C487.761 15.1892 488.563 15.9927 489.552 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 15.9927C484.571 15.9927 485.373 15.1892 485.373 14.198C485.373 13.2068 484.571 12.4033 483.582 12.4033C482.593 12.4033 481.791 13.2068 481.791 14.198C481.791 15.1892 482.593 15.9927 483.582 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 15.9927C478.601 15.9927 479.403 15.1892 479.403 14.198C479.403 13.2068 478.601 12.4033 477.612 12.4033C476.623 12.4033 475.821 13.2068 475.821 14.198C475.821 15.1892 476.623 15.9927 477.612 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M471.642 15.9927C472.631 15.9927 473.433 15.1892 473.433 14.198C473.433 13.2068 472.631 12.4033 471.642 12.4033C470.653 12.4033 469.851 13.2068 469.851 14.198C469.851 15.1892 470.653 15.9927 471.642 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M465.672 15.9927C466.661 15.9927 467.463 15.1892 467.463 14.198C467.463 13.2068 466.661 12.4033 465.672 12.4033C464.682 12.4033 463.881 13.2068 463.881 14.198C463.881 15.1892 464.682 15.9927 465.672 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M459.701 15.9927C460.691 15.9927 461.493 15.1892 461.493 14.198C461.493 13.2068 460.691 12.4033 459.701 12.4033C458.712 12.4033 457.91 13.2068 457.91 14.198C457.91 15.1892 458.712 15.9927 459.701 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M453.731 15.9927C454.72 15.9927 455.522 15.1892 455.522 14.198C455.522 13.2068 454.72 12.4033 453.731 12.4033C452.742 12.4033 451.94 13.2068 451.94 14.198C451.94 15.1892 452.742 15.9927 453.731 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M447.761 15.9927C448.75 15.9927 449.552 15.1892 449.552 14.198C449.552 13.2068 448.75 12.4033 447.761 12.4033C446.772 12.4033 445.97 13.2068 445.97 14.198C445.97 15.1892 446.772 15.9927 447.761 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M441.791 15.9927C442.78 15.9927 443.582 15.1892 443.582 14.198C443.582 13.2068 442.78 12.4033 441.791 12.4033C440.802 12.4033 440 13.2068 440 14.198C440 15.1892 440.802 15.9927 441.791 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M435.821 15.9927C436.81 15.9927 437.612 15.1892 437.612 14.198C437.612 13.2068 436.81 12.4033 435.821 12.4033C434.832 12.4033 434.03 13.2068 434.03 14.198C434.03 15.1892 434.832 15.9927 435.821 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M429.851 15.9927C430.84 15.9927 431.642 15.1892 431.642 14.198C431.642 13.2068 430.84 12.4033 429.851 12.4033C428.862 12.4033 428.06 13.2068 428.06 14.198C428.06 15.1892 428.862 15.9927 429.851 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M423.881 15.9927C424.87 15.9927 425.672 15.1892 425.672 14.198C425.672 13.2068 424.87 12.4033 423.881 12.4033C422.891 12.4033 422.09 13.2068 422.09 14.198C422.09 15.1892 422.891 15.9927 423.881 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M417.91 15.9927C418.9 15.9927 419.701 15.1892 419.701 14.198C419.701 13.2068 418.9 12.4033 417.91 12.4033C416.921 12.4033 416.119 13.2068 416.119 14.198C416.119 15.1892 416.921 15.9927 417.91 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M411.94 15.9927C412.929 15.9927 413.731 15.1892 413.731 14.198C413.731 13.2068 412.929 12.4033 411.94 12.4033C410.951 12.4033 410.149 13.2068 410.149 14.198C410.149 15.1892 410.951 15.9927 411.94 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M405.97 15.9927C406.959 15.9927 407.761 15.1892 407.761 14.198C407.761 13.2068 406.959 12.4033 405.97 12.4033C404.981 12.4033 404.179 13.2068 404.179 14.198C404.179 15.1892 404.981 15.9927 405.97 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M400 15.9927C400.989 15.9927 401.791 15.1892 401.791 14.198C401.791 13.2068 400.989 12.4033 400 12.4033C399.011 12.4033 398.209 13.2068 398.209 14.198C398.209 15.1892 399.011 15.9927 400 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M394.03 15.9927C395.019 15.9927 395.821 15.1892 395.821 14.198C395.821 13.2068 395.019 12.4033 394.03 12.4033C393.041 12.4033 392.239 13.2068 392.239 14.198C392.239 15.1892 393.041 15.9927 394.03 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M382.09 15.9927C383.079 15.9927 383.881 15.1892 383.881 14.198C383.881 13.2068 383.079 12.4033 382.09 12.4033C381.1 12.4033 380.298 13.2068 380.298 14.198C380.298 15.1892 381.1 15.9927 382.09 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M376.119 15.9927C377.109 15.9927 377.91 15.1892 377.91 14.198C377.91 13.2068 377.109 12.4033 376.119 12.4033C375.13 12.4033 374.328 13.2068 374.328 14.198C374.328 15.1892 375.13 15.9927 376.119 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M370.149 15.9927C371.138 15.9927 371.94 15.1892 371.94 14.198C371.94 13.2068 371.138 12.4033 370.149 12.4033C369.16 12.4033 368.358 13.2068 368.358 14.198C368.358 15.1892 369.16 15.9927 370.149 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M364.179 15.9927C365.168 15.9927 365.97 15.1892 365.97 14.198C365.97 13.2068 365.168 12.4033 364.179 12.4033C363.19 12.4033 362.388 13.2068 362.388 14.198C362.388 15.1892 363.19 15.9927 364.179 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 15.9927C359.198 15.9927 360 15.1892 360 14.198C360 13.2068 359.198 12.4033 358.209 12.4033C357.22 12.4033 356.418 13.2068 356.418 14.198C356.418 15.1892 357.22 15.9927 358.209 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M352.239 15.9927C353.228 15.9927 354.03 15.1892 354.03 14.198C354.03 13.2068 353.228 12.4033 352.239 12.4033C351.25 12.4033 350.448 13.2068 350.448 14.198C350.448 15.1892 351.25 15.9927 352.239 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 15.9927C347.258 15.9927 348.06 15.1892 348.06 14.198C348.06 13.2068 347.258 12.4033 346.269 12.4033C345.279 12.4033 344.478 13.2068 344.478 14.198C344.478 15.1892 345.279 15.9927 346.269 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M340.299 15.9927C341.288 15.9927 342.09 15.1892 342.09 14.198C342.09 13.2068 341.288 12.4033 340.299 12.4033C339.309 12.4033 338.507 13.2068 338.507 14.198C338.507 15.1892 339.309 15.9927 340.299 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M322.388 15.9927C323.377 15.9927 324.179 15.1892 324.179 14.198C324.179 13.2068 323.377 12.4033 322.388 12.4033C321.399 12.4033 320.597 13.2068 320.597 14.198C320.597 15.1892 321.399 15.9927 322.388 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 15.9927C317.407 15.9927 318.209 15.1892 318.209 14.198C318.209 13.2068 317.407 12.4033 316.418 12.4033C315.429 12.4033 314.627 13.2068 314.627 14.198C314.627 15.1892 315.429 15.9927 316.418 15.9927Z"
                fill="#FBC98E"
              />
              <path
                d="M483.582 10.0105C484.571 10.0105 485.373 9.207 485.373 8.21583C485.373 7.22465 484.571 6.42114 483.582 6.42114C482.593 6.42114 481.791 7.22465 481.791 8.21583C481.791 9.207 482.593 10.0105 483.582 10.0105Z"
                fill="#FBC98E"
              />
              <path
                d="M477.612 10.0105C478.601 10.0105 479.403 9.207 479.403 8.21583C479.403 7.22465 478.601 6.42114 477.612 6.42114C476.623 6.42114 475.821 7.22465 475.821 8.21583C475.821 9.207 476.623 10.0105 477.612 10.0105Z"
                fill="#FBC98E"
              />
              <path
                d="M388.06 10.0105C389.049 10.0105 389.851 9.207 389.851 8.21583C389.851 7.22465 389.049 6.42114 388.06 6.42114C387.071 6.42114 386.269 7.22465 386.269 8.21583C386.269 9.207 387.071 10.0105 388.06 10.0105Z"
                fill="#FBC98E"
              />
              <path
                d="M328.358 488.593C329.347 488.593 330.149 487.789 330.149 486.798C330.149 485.807 329.347 485.003 328.358 485.003C327.369 485.003 326.567 485.807 326.567 486.798C326.567 487.789 327.369 488.593 328.358 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M316.418 488.593C317.407 488.593 318.209 487.789 318.209 486.798C318.209 485.807 317.407 485.003 316.418 485.003C315.429 485.003 314.627 485.807 314.627 486.798C314.627 487.789 315.429 488.593 316.418 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M268.657 488.593C269.646 488.593 270.448 487.789 270.448 486.798C270.448 485.807 269.646 485.003 268.657 485.003C267.668 485.003 266.866 485.807 266.866 486.798C266.866 487.789 267.668 488.593 268.657 488.593Z"
                fill="#FBC98E"
              />
              <path
                d="M346.269 452.699C347.258 452.699 348.06 451.895 348.06 450.904C348.06 449.913 347.258 449.109 346.269 449.109C345.279 449.109 344.478 449.913 344.478 450.904C344.478 451.895 345.279 452.699 346.269 452.699Z"
                fill="#FBC98E"
              />
              <path
                d="M358.209 440.734C359.198 440.734 360 439.931 360 438.94C360 437.949 359.198 437.145 358.209 437.145C357.22 437.145 356.418 437.949 356.418 438.94C356.418 439.931 357.22 440.734 358.209 440.734Z"
                fill="#FBC98E"
              />
              <path
                d="M274.627 303.142C275.616 303.142 276.418 302.339 276.418 301.347C276.418 300.356 275.616 299.553 274.627 299.553C273.638 299.553 272.836 300.356 272.836 301.347C272.836 302.339 273.638 303.142 274.627 303.142Z"
                fill="#FBC98E"
              />
              <path
                d="M143.284 255.284C144.273 255.284 145.075 254.48 145.075 253.489C145.075 252.498 144.273 251.694 143.284 251.694C142.294 251.694 141.493 252.498 141.493 253.489C141.493 254.48 142.294 255.284 143.284 255.284Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 231.354C227.855 231.354 228.657 230.551 228.657 229.56C228.657 228.569 227.855 227.765 226.866 227.765C225.877 227.765 225.075 228.569 225.075 229.56C225.075 230.551 225.877 231.354 226.866 231.354Z"
                fill="#FBC98E"
              />
              <path
                d="M226.866 219.39C227.855 219.39 228.657 218.587 228.657 217.595C228.657 216.604 227.855 215.801 226.866 215.801C225.877 215.801 225.075 216.604 225.075 217.595C225.075 218.587 225.877 219.39 226.866 219.39Z"
                fill="#FBC98E"
              />
              <path
                d="M167.164 213.408C168.153 213.408 168.955 212.604 168.955 211.613C168.955 210.622 168.153 209.818 167.164 209.818C166.175 209.818 165.373 210.622 165.373 211.613C165.373 212.604 166.175 213.408 167.164 213.408Z"
                fill="#FBC98E"
              />
              <path
                d="M161.194 201.443C162.183 201.443 162.985 200.64 162.985 199.649C162.985 198.658 162.183 197.854 161.194 197.854C160.205 197.854 159.403 198.658 159.403 199.649C159.403 200.64 160.205 201.443 161.194 201.443Z"
                fill="#FBC98E"
              />
              <path
                d="M220.896 195.461C221.885 195.461 222.687 194.657 222.687 193.666C222.687 192.675 221.885 191.872 220.896 191.872C219.906 191.872 219.104 192.675 219.104 193.666C219.104 194.657 219.906 195.461 220.896 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 195.461C84.5713 195.461 85.3732 194.657 85.3732 193.666C85.3732 192.675 84.5713 191.872 83.5821 191.872C82.593 191.872 81.7911 192.675 81.7911 193.666C81.7911 194.657 82.593 195.461 83.5821 195.461Z"
                fill="#FBC98E"
              />
              <path
                d="M244.776 177.514C245.765 177.514 246.567 176.711 246.567 175.719C246.567 174.728 245.765 173.925 244.776 173.925C243.787 173.925 242.985 174.728 242.985 175.719C242.985 176.711 243.787 177.514 244.776 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M83.5821 177.514C84.5713 177.514 85.3732 176.711 85.3732 175.719C85.3732 174.728 84.5713 173.925 83.5821 173.925C82.593 173.925 81.7911 174.728 81.7911 175.719C81.7911 176.711 82.593 177.514 83.5821 177.514Z"
                fill="#FBC98E"
              />
              <path
                d="M262.687 171.532C263.676 171.532 264.478 170.728 264.478 169.737C264.478 168.746 263.676 167.943 262.687 167.943C261.697 167.943 260.896 168.746 260.896 169.737C260.896 170.728 261.697 171.532 262.687 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M256.716 171.532C257.706 171.532 258.507 170.728 258.507 169.737C258.507 168.746 257.706 167.943 256.716 167.943C255.727 167.943 254.925 168.746 254.925 169.737C254.925 170.728 255.727 171.532 256.716 171.532Z"
                fill="#FBC98E"
              />
              <path
                d="M214.925 165.55C215.915 165.55 216.716 164.746 216.716 163.755C216.716 162.764 215.915 161.96 214.925 161.96C213.936 161.96 213.134 162.764 213.134 163.755C213.134 164.746 213.936 165.55 214.925 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M137.313 165.55C138.303 165.55 139.104 164.746 139.104 163.755C139.104 162.764 138.303 161.96 137.313 161.96C136.324 161.96 135.522 162.764 135.522 163.755C135.522 164.746 136.324 165.55 137.313 165.55Z"
                fill="#FBC98E"
              />
              <path
                d="M280.597 159.567C281.586 159.567 282.388 158.764 282.388 157.773C282.388 156.782 281.586 155.978 280.597 155.978C279.608 155.978 278.806 156.782 278.806 157.773C278.806 158.764 279.608 159.567 280.597 159.567Z"
                fill="#FBC98E"
              />
              <path
                d="M250.746 153.585C251.735 153.585 252.537 152.782 252.537 151.791C252.537 150.799 251.735 149.996 250.746 149.996C249.757 149.996 248.955 150.799 248.955 151.791C248.955 152.782 249.757 153.585 250.746 153.585Z"
                fill="#FBC98E"
              />
              <path
                d="M567.164 129.656C568.153 129.656 568.955 128.853 568.955 127.861C568.955 126.87 568.153 126.067 567.164 126.067C566.175 126.067 565.373 126.87 565.373 127.861C565.373 128.853 566.175 129.656 567.164 129.656Z"
                fill="#FBC98E"
              />
              <g
                clipPath="url(#clip1)"
                onMouseOver={() => setTooltip1(true)}
                onMouseOut={() => setTooltip1(false)}
              >
                <circle
                  cx="560.5"
                  cy="292.374"
                  r="17.5"
                  fill="#F7941D"
                  fillOpacity="0.5"
                />
                <circle cx="560.5" cy="292.374" r="9.5" fill="#FDEAD2" />
                <circle cx="560.5" cy="292.374" r="3.5" fill="#F7941D" />
              </g>
              <g
                clipPath="url(#clip2)"
                onMouseOver={() => setTooltip2(true)}
                onMouseOut={() => setTooltip2(false)}
              >
                <circle
                  cx="727.5"
                  cy="237.374"
                  r="17.5"
                  fill="#F7941D"
                  fillOpacity="0.5"
                />
                <circle cx="727.5" cy="237.374" r="9.5" fill="#FDEAD2" />
                <circle cx="727.5" cy="237.374" r="3.5" fill="#F7941D" />
              </g>
              <defs>
                <clipPath id="clip1">
                  <rect
                    width="43"
                    height="43"
                    fill="white"
                    transform="translate(539 270.874)"
                  />
                </clipPath>
                <clipPath id="clip2">
                  <rect
                    width="43"
                    height="43"
                    fill="white"
                    transform="translate(706 215.874)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div
              className={tooltip1 ? "tooltip one show_tooltip" : "tooltip one"}
            >
              <p>Name of Location (25 Products)</p>
            </div>
            <div
              className={tooltip2 ? "tooltip two show_tooltip" : "tooltip two"}
            >
              <p>Name of Location (25 Products)</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
