import React from "react";
import "./Work.scss";

export const Work = () => {
  const reasons = [
    {
      num: "01",
      reason:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna.",
    },
    {
      num: "02",
      reason:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna.",
    },
    {
      num: "03",
      reason:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna.",
    },
    {
      num: "04",
      reason:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna.",
    },
    {
      num: "05",
      reason:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna.",
    },
  ];
  return (
    <div className="work_with_us_container">
      <section>
        <h1>Looking to become an ICart Operator?</h1>

        <div className="why_container">
          <div className="why_container-left">
            <h2>Why work with us?</h2>
            <p className="justify">
              Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit
              habitant posuere urna. Ullamcorper in hac scelerisque pellentesque
              lectus sit viverra. Sed diam natoque vulputate maecenas sed eget.
              Vitae nibh commodo neque at posuere sit. Id sociis nullam risus
              varius diam orci.
            </p>
          </div>

          <div className="why_container-right">
            {reasons.map((reason, uid) => (
              <div className="reason_card" key={uid}>
                <h2>{reason.num}</h2>
                <p className="justify">{reason.reason}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
