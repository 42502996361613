import { useState } from "react";
import useAPI from "./useAPI";
import urls from "api/urls";
import { toast } from "react-toastify";

const useBusiness = () => {
  const { get, post, isLoading, errorMessage, successMessage, signup } =
    useAPI();
  const [plans, setPlans] = useState([]);
  const [concepts, setConcepts] = useState([]);
  const [rates, setRates] = useState(null);

  const getConcepts = async () => {
    const data = await get(urls.business.concepts);
    if (!data) return;
    setConcepts(data.concepts);
  };

  const getPlans = async () => {
    const data = await get(urls.business.plans);
    if (data) setPlans(data.plans);
  };

  const subscribe = async (details, token) => {
    const response = await post(urls.business.subscribe, details, {
      headers: { "x-access-token": token },
    });
    if (!response.data) return;
    window.open(response.data.checkoutUrl, "_blank");
    return response.data.checkoutUrl;
  };
  const preorder = async (details, cb) => {
    const response = await post(urls.business.preorder, details);
    if (response) toast.info(response.message);
    if (typeof cb === "function") cb(response.data.checkoutUrl);
  };

  const getPreorderRate = async (details, cb) => {
    const data = await get(urls.business.preorderRate);
    if (data) return setRates(data.rates);
  };

  return {
    plans,
    rates,
    isLoading,
    subscribe,
    errorMessage,
    successMessage,
    signup,
    getPlans,
    getConcepts,
    concepts,
    preorder,
    getPreorderRate,
  };
};

export default useBusiness;
