import React from "react";
import "./Gallery.scss";
import { gallery_details } from "constants";

export const Gallery = () => {
  return (
    <div className="gallery_container">
      <section>
        <h1>See Some of Our Previous Projects </h1>

        <div className="grid">
          {gallery_details.map((item, uid) => {
            const three = item.three_fr,
              six = item.six_fr;

            return (
              <div className={three ? "three_fr" : "six_fr"} key={uid}>
                {three &&
                  three.map((card, uid) => (
                    <div className="gallery_img" key={uid}>
                      <img src={card.img} alt="Gallery" />
                      <div className="gallery_content">
                        <p>{card.date}</p>
                        <h4>{card.title}</h4>
                        <p className="justify">{card.description}</p>
                      </div>
                      <div className="gallery_btn">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M2.53366 18.0001L0.666992 16.1334L13.467 3.33341H2.00033V0.666748H18.0003V16.6667H15.3337V5.20008L2.53366 18.0001Z"
                            fill="var(--black-main)"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                {/* {six &&
                  six.map((card, uid) => (
                    <div className="gallery_img" key={uid}>
                      <img src={card.img} alt="Gallery" />
                      <div className="gallery_content">
                        <p>{card.date}</p>
                        <h4>{card.title}</h4>
                        <p className="justify">{card.description}</p>
                      </div>
                      <div className="gallery_btn">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M2.53366 18.0001L0.666992 16.1334L13.467 3.33341H2.00033V0.666748H18.0003V16.6667H15.3337V5.20008L2.53366 18.0001Z"
                            fill="var(--black-main)"
                          />
                        </svg>
                      </div>
                    </div>
                  ))} */}
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};
