/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import "./Form.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Country } from "country-state-city";
import useQuery from "hooks/useQuery";
import { products } from "constants";
import useBusiness from "hooks/api/useBusiness";
import { currencyFormatter } from "utils";

const concepts = [
  {
    name: "--Select concept--",
  },
  {
    name: "Waffles and Pancakes and Milkshakes",
  },
  {
    name: "Waffles and Pancakes and Milkshakes",
  },
];

const operations = [
  {
    name: "--Management--",
  },
  {
    name: "Onsite management",
  },
  {
    name: "Online management",
  },
];

const inputs = [
  {
    label: "First Name",
    placeholder: "Enter your first name",
    type: "text",
    name: "firstName",
  },
  {
    label: "Last Name",
    placeholder: "Enter your last name",
    type: "text",
    name: "lastName",
  },
  {
    label: "Email",
    placeholder: "username@email.com",
    type: "email",
    name: "email",
  },
  {
    label: "Phone number",
    phone: true,
    name: "phoneNumber",
  },
  {
    label: "NIN",
    placeholder: "0123456789",
    name: "nin",
  },
  {
    label: "Country",
    select: true,
    name: "country",
  },
  // {
  //   label: "Select Concept",
  //   select: true,
  //   options: [
  //     {
  //       name: "--Select concept--",
  //     },
  //     ...products.map((p) => ({ name: p.title })),
  //   ],
  //   concept: true,
  // },
  {
    label: "Plan",
    select: true,
    plan: true,
    name: "plan",
  },
  {
    label: "Management",
    select: true,
    options: operations,
    management: true,
    name: "management",
  },
  {
    label: "Create Password",
    type: "password",
    name: "password1",
  },
  {
    label: "Confirm Password",
    type: "password",
    name: "password2",
  },
];

export const PurchasePage = () => {
  const [selectedManagement, setSelectedManagement] = useState(null);
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [phoneValue, setPhoneValue] = useState(null);
  const [countries, setCountries] = useState([]);
  const [payUrl, setPayUrl] = useState(null);
  const query = useQuery();

  const { isLoading, getPlans, subscribe, plans, signup, errorMessage } =
    useBusiness();

  useEffect(() => {
    if (query.get("concept")) setSelectedConcept(query.get("concept"));
  }, []);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const handleChangePlan = (e) => {
    setSelectedPlan(e.target.value);
  };
  const handleManagementChange = (e) => {
    setSelectedManagement(e.target.value);
  };
  const handleConceptChange = (e) => {
    setSelectedConcept(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (!phoneValue) return alert("Please enter a valid phone number");
    e.preventDefault();
    const formData = new FormData(e.target);
    if (formData.get("password1") !== formData.get("password2"))
      return alert("Passwords not matching");
    const payload = {
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      email: formData.get("email"),
      nin: formData.get("nin"),
      phoneNumber: phoneValue,
      address: {
        country: selectedCountry,
      },
      password: formData.get("password1"),
    };

    const response = await signup(payload);
    if (response) {
      const url = await subscribe(
        { management: "online", plan: plans[0]._id },
        response.tokens.accessToken
      );
      setPayUrl(url);
    }
  };

  useEffect(() => {
    getPlans();
    const countryData = Object.values(Country.getAllCountries());
    setCountries(countryData);
    setSelectedCountry(countryData[160].name);
  }, []);

  useEffect(() => {
    if (errorMessage) alert(errorMessage);
  }, [errorMessage]);

  return (
    <div className="form_container">
      <div className="form_container-header">
        <h2>Want to Subscribe for an iCart?</h2>
        <p>Fill and send the file below and we will get in touch with you.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form_inputs">
          {inputs.map((value, _id) => (
            <div key={_id} className="form_control">
              <label>{value.label}</label>
              {value.phone ? (
                <PhoneInput
                  className="input_holder"
                  placeholder="+234 801 234 5678"
                  defaultCountry="NG"
                  value={phoneValue}
                  onChange={(e) => setPhoneValue(e)}
                />
              ) : value.select && countries.length > 0 ? (
                <select
                  className="input_holder"
                  value={
                    (value.plan
                      ? selectedPlan
                      : value.concept
                      ? selectedConcept
                      : value.management
                      ? selectedManagement
                      : selectedCountry) || ""
                  }
                  onChange={
                    value.plan
                      ? handleChangePlan
                      : value.concept
                      ? handleConceptChange
                      : value.management
                      ? handleManagementChange
                      : handleCountryChange
                  }
                >
                  {(value.plan
                    ? plans.map((p) => ({
                        name: `₦${currencyFormatter(p.amount)} (${
                          p.refundable ? "Cashback" : ""
                        } Security deposite after ${
                          p.durationInDays === 365
                            ? "1 year"
                            : `${p.durationInDays} days`
                        })`,
                        value: p._id,
                      }))
                    : value.options || countries
                  )?.map((country, _id) => (
                    <option key={_id} value={country.value || country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="input_holder">
                  <input
                    name={value.name}
                    required
                    type={value.type}
                    placeholder={value.placeholder}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <button disabled={isLoading}>
          {isLoading ? "Loading..." : "Send"}
        </button>
      </form>
      {payUrl && (
        <span style={{ fontSize: "1.5rem" }}>
          If you are not redirected please click{" "}
          <a href={payUrl} target="_blank" rel="noreferrer">
            here
          </a>{" "}
          to make your payment
        </span>
      )}
    </div>
  );
};
