import React from "react";
import "./Testimonials.scss";
import { tests } from "constants";

export const Testimonials = () => {
  return (
    <div className="testimonials_container">
      <section>
        <div className="content_header">
          <h2>Testimonials</h2>
          <p>
            Read what our clients, business partners and users have to say about
            our products.
          </p>
        </div>

        <div className="testimonials">
          {tests.map((test, uid) => (
            <div className="testimonial_card" key={uid}>
              <div className="testimonial">
                <div className="user_profile">
                  <div className="user_profile-img">
                    <img src={test.img} alt="user" />
                  </div>
                  <div className="user_profile-detail">
                    <p className="name">{test.name}</p>
                    <p className="role">{test.role}</p>
                  </div>
                </div>
                <div className="user_content">
                  <h4>{test.heading}</h4>
                  {test.description && (
                    <p className="justify">{test.description}</p>
                  )}
                  {test.video && (
                    <div className="video">
                      <img src={test.video} alt="video" />

                      <div className="video_overlay">
                        <div className="video_overlay-play">
                          <svg
                            width="68"
                            height="54"
                            viewBox="0 0 68 54"
                            fill="none"
                          >
                            <path
                              d="M34.813 0.333313C36.5933 0.343113 41.0477 0.38618 45.7803 0.575747L47.4583 0.648912C52.2233 0.874512 56.9843 1.25991 59.3457 1.91831C62.4953 2.80315 64.971 5.38498 65.8077 8.65771C67.14 13.8547 67.3067 23.998 67.3273 26.4526L67.3303 26.9613V26.997C67.3303 26.997 67.3303 27.0093 67.3303 27.033L67.3273 27.5416C67.3067 29.9963 67.14 40.1396 65.8077 45.3366C64.9593 48.6213 62.4837 51.2033 59.3457 52.076C56.9843 52.7343 52.2233 53.1197 47.4583 53.3453L45.7803 53.4183C41.0477 53.608 36.5933 53.651 34.813 53.661L34.0317 53.6636H33.997C33.997 53.6636 33.9853 53.6636 33.9623 53.6636L33.1817 53.661C29.4137 53.6403 13.6591 53.47 8.64837 52.076C5.49867 51.191 3.02307 48.6093 2.18623 45.3366C0.854001 40.1396 0.687468 29.9963 0.666668 27.5416V26.4526C0.687468 23.998 0.854001 13.8547 2.18623 8.65771C3.03467 5.37285 5.51027 2.79105 8.64837 1.91831C13.6591 0.52408 29.4137 0.354046 33.1817 0.333313H34.813ZM27.3304 15.3304V38.6636L47.3303 26.997L27.3304 15.3304Z"
                              fill="var(--white-main)"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
