import React from "react";
import "./About.scss";
import { whoWeAre, whoWeAre2 } from "assets";
import { abouts } from "constants";
import { services } from "constants";

export const About = () => {
  return (
    <div className="about_container" id="about">
      <section>
        <div className="about_card-1">
          <div className="card-1">
            <div className="card-1_top">
              <h2>Who we are.</h2>
              <p className="justify">
                We are a diverse team committed to working together to create
                and deliver sustainable value for food businesses.
              </p>
              <p className="justify">
                Through our innovative and forward-thinking approach, we aim to
                be the leading provider of sustainable infrastructure,
                empowering quick service restaurants to adopt greener
                alternatives without compromising on efficiency or quality. We
                envision a seamless integration of sustainable practices, from
                energy-efficient restaurant designs and renewable energy
                solutions to waste reduction initiatives and eco-friendly
                packaging,Join us on this transformative journey as we build a
                thriving, eco-conscious quick service restaurant industry that
                serves both its customers and the planet, one sustainable
                infrastructure at a time."
              </p>
            </div>
            <div className="about_card-img">
              <img src={whoWeAre2} alt="Who we are" />
            </div>
          </div>
          <div className="card-2">
            {abouts.map((about, uid) => (
              <div key={uid}>
                <div className="card_2-icon">
                  <img src={about.icon} alt={about.title} />
                </div>
                <h3>
                  <span>{about.title[0]}</span>
                  {about.title[1]}
                </h3>
                <p className="justify">{about.content}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="about_card-2">
          <div className="content_header">
            <h2>What we do</h2>
            <p>
              We offer a range of services all in the bid to make business easy
              for you.
            </p>
          </div>

          <div className="what_we_do">
            {services.map((service, uid) => (
              <div key={uid} className="what_we_do-card">
                <div className="what_we_do-card_top">
                  <div>
                    <img src={service.icon} alt="icon" />
                  </div>
                  <h3>{service.title}</h3>
                </div>
                <p className="justify">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
