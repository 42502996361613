import React from "react";
import "./Blog.scss";
import { news1, news2, news3 } from "assets";

export const Blog = () => {
  return (
    <div className="news_blog">
      <section>
        <div className="blog_left">
          <div className="content_header">
            <h2>News & Blogs</h2>
            <p>
              See our latest news on happenings and the use of our products
              around the world.
            </p>
          </div>
          <div className="blog_left-card">
            <a
              target="_blank"
              href="https://www.youtube.com/watch?v=nlqlJSWRejU"
              className="blog_btn"
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M2.53366 18.0001L0.666992 16.1334L13.467 3.33341H2.00033V0.666748H18.0003V16.6667H15.3337V5.20008L2.53366 18.0001Z"
                  fill="var(--black-main)"
                />
              </svg>
            </a>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/nlqlJSWRejU?si=A2wR7sVWrJ_9lf0S&amp;controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div className="left_card-content">
              <p className="date">Aug 18, 2023</p>
              <h4>Business chat with CEO</h4>
              <p className="content">
                An insightful business chat with Usman Dalhatu, an entrepreneur,
                innovator, and visionary leader, driving Dalsman Groups'
                success!
              </p>
              <p className="source">
                <span>Source:</span>
                Westford for Business
              </p>
            </div>
          </div>
        </div>
        {/* <div className="blog_right">
          <div className="blog_right-card">
            <div className="blog_btn">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M2.53366 18.0001L0.666992 16.1334L13.467 3.33341H2.00033V0.666748H18.0003V16.6667H15.3337V5.20008L2.53366 18.0001Z"
                  fill="var(--black-main)"
                />
              </svg>
            </div>
            <img src={news2} alt="news" />
            <div className="right_card-content">
              <p className="date">April 03, 2023</p>
              <h4>News Heading</h4>
              <p className="content">
                This paragraph/section will contain a brief description of the
                news article.
              </p>
              <p className="source">
                <span>Source:</span>
                Punch Newspaper
              </p>
            </div>
          </div>
          <div className="blog_right-card">
            <div className="blog_btn">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                <path
                  d="M2.53366 18.0001L0.666992 16.1334L13.467 3.33341H2.00033V0.666748H18.0003V16.6667H15.3337V5.20008L2.53366 18.0001Z"
                  fill="var(--black-main)"
                />
              </svg>
            </div>
            <img src={news3} alt="news" />
            <div className="right_card-content">
              <p className="date">April 03, 2023</p>
              <h4>News Heading</h4>
              <p className="content">
                This paragraph/section will contain a brief description of the
                news article.
              </p>
              <p className="source">
                <span>Source:</span>
                Punch Newspaper
              </p>
            </div>
          </div>
        </div> */}
      </section>
    </div>
  );
};
