import { useState } from "react";
import client from "api";
import urls from "api/urls";
import { toast } from "react-toastify";

const useAPI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const get = async (...params) => {
    try {
      setErrorMessage(null);
      setSuccessMessage(null);
      setIsLoading(true);
      const { data, status } = await client.get(...params);
      if (status === 200) return data.data;
      return null;
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response?.data?.message);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
      console.log(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const post = async (...params) => {
    try {
      setErrorMessage(null);
      setSuccessMessage(null);
      setIsLoading(true);
      const { data, status } = await client.post(...params);
      if (status === 201) return data;
      return null;
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error.response?.data?.message);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const signup = async (details) => {
    try {
      const { data, status } = await client.post(urls.auth.signup, details);
      if (status === 201) return data.data;
      return null;
    } catch (error) {
      if (error.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
        toast.error(error?.response?.data?.message || "Something went wrong!");
      }
      console.log(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    get,
    post,
    signup,
    isLoading,
    errorMessage,
    successMessage,
    setSuccessMessage,
    setErrorMessage,
  };
};

export default useAPI;
