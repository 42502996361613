import React, { useContext, useRef, useState } from "react";
import "./Product.scss";
import { products } from "constants";
import { NavLink } from "react-router-dom";
import { PURCHASE } from "constants";
import AppContext from "contexts/AppContext";
import { capitalizeFirstLtter, currencyFormatter } from "utils";
import urls from "api/urls";
import img01 from "../../assets/slides/01.jpg";
import img02 from "../../assets/slides/02.jpg";
import img03 from "../../assets/slides/03.jpg";
import img04 from "../../assets/slides/04.jpg";
import img05 from "../../assets/slides/05.jpg";

const images = [
  {
    img: img01,
    title: "Shine Box",
  },
  {
    img: img02,
    title: "Tele-Medecine",
  },
  {
    img: img03,
    title: "Play Pod",
  },
  {
    img: img04,
    title: "Qiuck-Hub",
  },
  {
    img: img05,
    title: "Style-Hub",
  },
];

export const Product = () => {
  // const [isChanging, setIsChanging] = useState(true);
  const [image, setImage] = useState(images[0]);
  // const { concepts, plans } = useContext(AppContext);
  // const timeoutRef = useRef(null);

  return (
    <div className="product_container">
      <section>
        <h1>Our Products & Services</h1>

        <div className="slide-show">
          <div
            // style={{ backgroundImage: `url(${img01})` }}
            className={`img-slide`}
          >
            <div className="desc-sli">
              <h2>{image.title}</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Corporis, aliquam?
              </p>
            </div>
            <img src={image.img} alt="" />
          </div>
          <div className="images-sml">
            {images.map((img, idx) => (
              <img
                onClick={() => {
                  // if (timeoutRef.current) clearTimeout(timeoutRef.current);
                  // setIsChanging(true);
                  // timeoutRef.current = setTimeout(() => {
                  //   setImage(img);
                  //   setIsChanging(false);
                  // }, 1000);
                  setImage(img);
                }}
                src={img.img}
              />
            ))}
          </div>
        </div>

        {/* <div className="products">
          {concepts.map((product, uid) => {
            const { name, description, imageUrl } = product;

            return (
              <div key={uid} className="single_product">
                <div className="product_content">
                  <h3 className="product_title">{name}</h3>
                  <p className="justify">{description}</p>
                  <br /> */}
        {/* <div className="product_price">
                    <h3>₦{currencyFormatter(plans[0]?.fee?.value)}</h3>
                    <p>
                      {capitalizeFirstLtter(plans[0]?.fee?.duration)}{" "}
                      subscription fee
                    </p>
                  </div> */}

        {/* <div className="product_buttons">
                    <button className="product_select">
                      <NavLink
                        to={PURCHASE + `?concept=${product._id}`}
                        // className="product_select"
                      >
                        Select Concept
                      </NavLink>
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          urls.baseUrl + urls.files.baseUrl + imageUrl
                        )
                      }
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM8 12L3 7L4.4 5.55L7 8.15V0H9V8.15L11.6 5.55L13 7L8 12Z"
                          fill="var(--black-tint-3)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="product_img">
                  <img
                    crossOrigin="anonymous"
                    src={urls.baseUrl + urls.files.baseUrl + imageUrl}
                    alt={name}
                  />
                </div>
              </div>
            );
          })}
        </div> */}
      </section>
    </div>
  );
};
