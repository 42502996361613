import { Footer, NavBar } from "components";
import AppContext from "contexts/AppContext";
import useBusiness from "hooks/api/useBusiness";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AppLayout = () => {
  const { plans, getConcepts, getPlans, concepts } = useBusiness();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getConcepts();
    getPlans();
  }, []);

  return (
    <AppContext.Provider value={{ plans, concepts }}>
      <div className="root-div">
        <NavBar />
        <Outlet />
        <Footer />
      </div>
      <ToastContainer />
    </AppContext.Provider>
  );
};
