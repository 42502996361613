import React from "react";
import "./Openings.scss";

export const Openings = () => {
  const openings = [
    {
      title: "Job Title Goes Here",
      location: "Job Location",
      role: "Full Time / Part Time",
      description:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna. Ullamcorper in hac scelerisque pellentesque lectus sit viverra. Sed diam natoque vulputate maecenas sed eget. Vitae nibh commodo neque at posuere sit. Id sociis nullam risus varius diam orci.",
    },
    {
      title: "Job Title Goes Here",
      location: "Job Location",
      role: "Full Time / Part Time",
      description:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna. Ullamcorper in hac scelerisque pellentesque lectus sit viverra. Sed diam natoque vulputate maecenas sed eget. Vitae nibh commodo neque at posuere sit. Id sociis nullam risus varius diam orci.",
    },
    {
      title: "Job Title Goes Here",
      location: "Job Location",
      role: "Full Time / Part Time",
      description:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna. Ullamcorper in hac scelerisque pellentesque lectus sit viverra. Sed diam natoque vulputate maecenas sed eget. Vitae nibh commodo neque at posuere sit. Id sociis nullam risus varius diam orci.",
    },
    {
      title: "Job Title Goes Here",
      location: "Job Location",
      role: "Full Time / Part Time",
      description:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna. Ullamcorper in hac scelerisque pellentesque lectus sit viverra. Sed diam natoque vulputate maecenas sed eget. Vitae nibh commodo neque at posuere sit. Id sociis nullam risus varius diam orci.",
    },
    {
      title: "Job Title Goes Here",
      location: "Job Location",
      role: "Full Time / Part Time",
      description:
        "Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit habitant posuere urna. Ullamcorper in hac scelerisque pellentesque lectus sit viverra. Sed diam natoque vulputate maecenas sed eget. Vitae nibh commodo neque at posuere sit. Id sociis nullam risus varius diam orci.",
    },
  ];

  return (
    <div className="openings_container">
      <section>
        <div className="content_header">
          <h2>Current Openings</h2>
          <p className="justify">
            Lorem ipsum dolor sit amet consectetur. At ipsum commodo sit
            habitant posuere urna.
          </p>
        </div>

        <div className="openings">
          {openings.map((opening, uid) => (
            <div className="openings_content" key={uid}>
              <div className="openings_content-top">
                <h4>{opening.title}</h4>
                <div>
                  <p>
                    <span>
                      <svg
                        width="16"
                        height="20"
                        viewBox="0 0 16 20"
                        fill="none"
                      >
                        <path
                          d="M8 10C8.55 10 9.02083 9.80417 9.4125 9.4125C9.80417 9.02083 10 8.55 10 8C10 7.45 9.80417 6.97917 9.4125 6.5875C9.02083 6.19583 8.55 6 8 6C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8C6 8.55 6.19583 9.02083 6.5875 9.4125C6.97917 9.80417 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7875 12.525 12.2625C13.5083 10.7375 14 9.38333 14 8.2C14 6.38333 13.4208 4.89583 12.2625 3.7375C11.1042 2.57917 9.68333 2 8 2C6.31667 2 4.89583 2.57917 3.7375 3.7375C2.57917 4.89583 2 6.38333 2 8.2C2 9.38333 2.49167 10.7375 3.475 12.2625C4.45833 13.7875 5.96667 15.4833 8 17.35ZM8 20C5.31667 17.7167 3.3125 15.5958 1.9875 13.6375C0.6625 11.6792 0 9.86667 0 8.2C0 5.7 0.804167 3.70833 2.4125 2.225C4.02083 0.741667 5.88333 0 8 0C10.1167 0 11.9792 0.741667 13.5875 2.225C15.1958 3.70833 16 5.7 16 8.2C16 9.86667 15.3375 11.6792 14.0125 13.6375C12.6875 15.5958 10.6833 17.7167 8 20Z"
                          fill="var(--yellow-main)"
                        />
                      </svg>
                    </span>
                    {opening.location}
                  </p>
                  <p>
                    <span>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3 14.7L14.7 13.3L11 9.6V5H9V10.4L13.3 14.7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2167 18 14.1042 17.2208 15.6625 15.6625C17.2208 14.1042 18 12.2167 18 10C18 7.78333 17.2208 5.89583 15.6625 4.3375C14.1042 2.77917 12.2167 2 10 2C7.78333 2 5.89583 2.77917 4.3375 4.3375C2.77917 5.89583 2 7.78333 2 10C2 12.2167 2.77917 14.1042 4.3375 15.6625C5.89583 17.2208 7.78333 18 10 18Z"
                          fill="var(--yellow-main)"
                        />
                      </svg>
                    </span>
                    {opening.role}
                  </p>
                </div>
              </div>
              <div className="openings_content-bottom">
                <p className="justify">{opening.description}</p>
                <button className="openings_btn">
                  Apply
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none">
                    <path
                      d="M1.9 13L0.5 11.6L10.1 2H1.5V0H13.5V12H11.5V3.4L1.9 13Z"
                      fill="var(--black-tint-3)"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
